import gql from 'graphql-tag'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  bigint: { input: any; output: any }
  date: { input: any; output: any }
  jsonb: { input: any; output: any }
  numeric: { input: any; output: any }
  timestamp: { input: any; output: any }
  timestamptz: { input: any; output: any }
  uuid: { input: any; output: any }
}

export type ApiCustomerCreateCustomerInput = {
  Address: Scalars['String']['input']
  City: Scalars['String']['input']
  Com_End_Series: Scalars['Boolean']['input']
  Country_Region_Code: Scalars['String']['input']
  County: Scalars['String']['input']
  Customer_Disc_Group: Scalars['String']['input']
  Customer_Discount_Base: Scalars['Int']['input']
  Customer_Price_Group: Scalars['String']['input']
  Diff_Address: Scalars['String']['input']
  Diff_City: Scalars['String']['input']
  Diff_Country_Region_Code: Scalars['String']['input']
  Diff_County: Scalars['String']['input']
  Diff_Goods_Destination: Scalars['Boolean']['input']
  Diff_Post_Code: Scalars['String']['input']
  Diff_Territory_Code: Scalars['String']['input']
  Dimension_Code: Scalars['String']['input']
  E_Mail: Scalars['String']['input']
  Fiscal_Code: Scalars['String']['input']
  IBAN: Scalars['String']['input']
  Name: Scalars['String']['input']
  Note: Scalars['String']['input']
  Office_Unique_Code: Scalars['String']['input']
  PEC_E_Mail: Scalars['String']['input']
  Payment_Method_Code: Scalars['String']['input']
  Payment_Terms_Code: Scalars['String']['input']
  Pec: Scalars['Boolean']['input']
  Phone_No: Scalars['String']['input']
  Platform: Scalars['String']['input']
  Post_Code: Scalars['String']['input']
  Remaining_Management: Scalars['Boolean']['input']
  Sell_On_WEB: Scalars['Boolean']['input']
  Shipment_Method_Code: Scalars['String']['input']
  Territory_Code: Scalars['String']['input']
  Unique_Code: Scalars['Boolean']['input']
  VAT_Registration_No: Scalars['String']['input']
}

export type ApiCustomerCreateOutput = {
  __typename?: 'ApiCustomerCreateOutput'
  result: Scalars['Boolean']['output']
}

export type ApiMailManagerAddressInput = {
  email: Scalars['String']['input']
  name?: InputMaybe<Scalars['String']['input']>
}

export type ApiMailManagerAttachmentInput = {
  content: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type ApiMailManagerContentInput = {
  attachments?: InputMaybe<Array<InputMaybe<ApiMailManagerAttachmentInput>>>
  charset?: InputMaybe<Scalars['String']['input']>
  context: Scalars['String']['input']
  html?: InputMaybe<Scalars['String']['input']>
  subject: Scalars['String']['input']
  text?: InputMaybe<Scalars['String']['input']>
}

export type ApiMailManagerMailInput = {
  bcc?: InputMaybe<Array<InputMaybe<ApiMailManagerAddressInput>>>
  cc?: InputMaybe<Array<InputMaybe<ApiMailManagerAddressInput>>>
  content: ApiMailManagerContentInput
  from?: InputMaybe<ApiMailManagerAddressInput>
  provider?: InputMaybe<Scalars['String']['input']>
  replyTo?: InputMaybe<ApiMailManagerAddressInput>
  to: Array<ApiMailManagerAddressInput>
}

export type ApiMailManagerRetryOutput = {
  __typename?: 'ApiMailManagerRetryOutput'
  result: Scalars['Boolean']['output']
}

export type ApiMailManagerSendOutput = {
  __typename?: 'ApiMailManagerSendOutput'
  result: Scalars['Boolean']['output']
}

export type ApiMailupClientEmail = {
  __typename?: 'ApiMailupClientEmail'
  Subject: Scalars['String']['output']
  Url: Scalars['String']['output']
  idList: Scalars['Int']['output']
  idMessage: Scalars['Int']['output']
}

export type ApiMailupClientEmailsOutput = {
  __typename?: 'ApiMailupClientEmailsOutput'
  limit: Scalars['Int']['output']
  offset: Scalars['Int']['output']
  results: Array<ApiMailupClientEmail>
  total: Scalars['Int']['output']
}

export type ApiMailupClientList = {
  __typename?: 'ApiMailupClientList'
  IdList: Scalars['Int']['output']
  Name: Scalars['String']['output']
}

export type ApiMailupClientListOutput = {
  __typename?: 'ApiMailupClientListOutput'
  limit: Scalars['Int']['output']
  offset: Scalars['Int']['output']
  results: Array<ApiMailupClientList>
  total: Scalars['Int']['output']
}

export type ApiMailupClientRecipientOutput = {
  __typename?: 'ApiMailupClientRecipientOutput'
  Email: Scalars['String']['output']
  Name: Scalars['String']['output']
  idRecipient: Scalars['Int']['output']
}

export type ApiSalesManagerAmountDataInput = {
  reference: Scalars['String']['input']
}

export type ApiSalesManagerAmountDataOutput = {
  __typename?: 'ApiSalesManagerAmountDataOutput'
  amount: Scalars['String']['output']
  amountInclTax: Scalars['String']['output']
  condition: Scalars['String']['output']
  currency: Scalars['String']['output']
  method: Scalars['String']['output']
  provider: Scalars['String']['output']
  taxRate: Scalars['String']['output']
}

export type ApiSalesManagerAmountOutput = {
  __typename?: 'ApiSalesManagerAmountOutput'
  result: ApiSalesManagerAmountDataOutput
}

export type ApiSalesManagerCancelOrderDataInput = {
  id: Scalars['String']['input']
}

export type ApiSalesManagerCancelOrderOutput = {
  __typename?: 'ApiSalesManagerCancelOrderOutput'
  result: Scalars['String']['output']
}

export type ApiSalesManagerCaptureOrderDataInput = {
  id: Scalars['String']['input']
}

export type ApiSalesManagerCaptureOrderOutput = {
  __typename?: 'ApiSalesManagerCaptureOrderOutput'
  result: Scalars['Boolean']['output']
}

export type ApiSalesManagerCartEnsureDataInput = {
  id?: InputMaybe<Scalars['Float']['input']>
  type?: InputMaybe<Scalars['String']['input']>
}

export type ApiSalesManagerCartEnsureResult = {
  __typename?: 'ApiSalesManagerCartEnsureResult'
  cart: Scalars['String']['output']
  payload: Scalars['String']['output']
  totals: Scalars['String']['output']
}

export type ApiSalesManagerCartItemRemoveDataInput = {
  ids: Array<Scalars['Float']['input']>
}

export type ApiSalesManagerCartItemRemoveOutput = {
  __typename?: 'ApiSalesManagerCartItemRemoveOutput'
  result: ApiSalesManagerCartItemRemoveResult
}

export type ApiSalesManagerCartItemRemoveResult = {
  __typename?: 'ApiSalesManagerCartItemRemoveResult'
  cart: Scalars['String']['output']
  ids: Array<Scalars['Float']['output']>
  payload: Scalars['String']['output']
  success: Scalars['Boolean']['output']
  totals: Scalars['String']['output']
}

export type ApiSalesManagerCartItemUpdateDataInput = {
  additionalInfo?: InputMaybe<Scalars['String']['input']>
  cartId?: InputMaybe<Scalars['Float']['input']>
  id?: InputMaybe<Scalars['Float']['input']>
  productErpId: Scalars['String']['input']
  qty: Scalars['Float']['input']
  type?: InputMaybe<Scalars['String']['input']>
}

export type ApiSalesManagerCartItemUpdateOutput = {
  __typename?: 'ApiSalesManagerCartItemUpdateOutput'
  result: ApiSalesManagerCartItemUpdateResult
}

export type ApiSalesManagerCartItemUpdateResult = {
  __typename?: 'ApiSalesManagerCartItemUpdateResult'
  cart: Scalars['String']['output']
  id: Scalars['Float']['output']
  item: Scalars['String']['output']
  payload: Scalars['String']['output']
  totals: Scalars['String']['output']
}

export type ApiSalesManagerCartReplacementRemoveDataInput = {
  ids: Array<Scalars['Float']['input']>
}

export type ApiSalesManagerCartReplacementRemoveOutput = {
  __typename?: 'ApiSalesManagerCartReplacementRemoveOutput'
  result: ApiSalesManagerCartReplacementRemoveResult
}

export type ApiSalesManagerCartReplacementRemoveResult = {
  __typename?: 'ApiSalesManagerCartReplacementRemoveResult'
  cart: Scalars['String']['output']
  ids: Array<Scalars['Float']['output']>
  payload: Scalars['String']['output']
  success: Scalars['Boolean']['output']
  totals: Scalars['String']['output']
}

export type ApiSalesManagerCartReplacementUpdateDataInput = {
  additionalInfo?: InputMaybe<Scalars['String']['input']>
  cartId?: InputMaybe<Scalars['Float']['input']>
  id?: InputMaybe<Scalars['Float']['input']>
  qty: Scalars['Float']['input']
  replacementErpId: Scalars['String']['input']
  type?: InputMaybe<Scalars['String']['input']>
}

export type ApiSalesManagerCartReplacementUpdateOutput = {
  __typename?: 'ApiSalesManagerCartReplacementUpdateOutput'
  result: ApiSalesManagerCartReplacementUpdateResult
}

export type ApiSalesManagerCartReplacementUpdateResult = {
  __typename?: 'ApiSalesManagerCartReplacementUpdateResult'
  cart: Scalars['String']['output']
  id: Scalars['Float']['output']
  item: Scalars['String']['output']
  payload: Scalars['String']['output']
  totals: Scalars['String']['output']
}

export type ApiSalesManagerCartToOrderDataInput = {
  id: Scalars['String']['input']
}

export type ApiSalesManagerCartToOrderOutput = {
  __typename?: 'ApiSalesManagerCartToOrderOutput'
  result: Scalars['String']['output']
}

export type ApiSalesManagerCartUpdateDataInput = {
  additionalInfo: Scalars['String']['input']
  id: Scalars['String']['input']
  items: Scalars['String']['input']
  orderId: Scalars['String']['input']
  replacements: Scalars['String']['input']
  status: Scalars['String']['input']
  type: Scalars['String']['input']
}

export type ApiSalesManagerCartUpdateOutput = {
  __typename?: 'ApiSalesManagerCartUpdateOutput'
  result: ApiSalesManagerCartUpdateResult
}

export type ApiSalesManagerCartUpdateResult = {
  __typename?: 'ApiSalesManagerCartUpdateResult'
  cart: Scalars['String']['output']
  id: Scalars['String']['output']
  payload: Scalars['String']['output']
  totals: Scalars['String']['output']
}

export type ApiSalesManagerCollectTotalsOutput = {
  __typename?: 'ApiSalesManagerCollectTotalsOutput'
  result: Scalars['String']['output']
}

export type ApiSalesManagerEnsureCartOutput = {
  __typename?: 'ApiSalesManagerEnsureCartOutput'
  result: ApiSalesManagerCartEnsureResult
}

export type ApiSalesManagerFormDataInput = {
  locale?: InputMaybe<Scalars['String']['input']>
  transactionId: Scalars['String']['input']
}

export type ApiSalesManagerFormOutput = {
  __typename?: 'ApiSalesManagerFormOutput'
  result: Scalars['String']['output']
}

export type ApiSalesManagerInvoiceToOrderDataInput = {
  ids: Array<Scalars['String']['input']>
  method: Scalars['String']['input']
  provider: Scalars['String']['input']
}

export type ApiSalesManagerInvoiceToOrderOutput = {
  __typename?: 'ApiSalesManagerInvoiceToOrderOutput'
  result: Scalars['String']['output']
}

export type ApiSalesManagerPayDataInput = {
  amount: Scalars['String']['input']
  currency: Scalars['String']['input']
  method: Scalars['String']['input']
  provider: Scalars['String']['input']
}

export type ApiSalesManagerPayOutput = {
  __typename?: 'ApiSalesManagerPayOutput'
  result: Scalars['String']['output']
}

export type ApiSalesManagerQuoteToEmailOutput = {
  __typename?: 'ApiSalesManagerQuoteToEmailOutput'
  result: Scalars['Boolean']['output']
}

export type ApiSalesManagerRegisterDataInput = {
  amount: Scalars['String']['input']
  currency: Scalars['String']['input']
  method: Scalars['String']['input']
  provider: Scalars['String']['input']
  reference?: InputMaybe<Scalars['String']['input']>
}

export type ApiSalesManagerRegisterOutput = {
  __typename?: 'ApiSalesManagerRegisterOutput'
  result: Scalars['String']['output']
}

export type ApiSalesManagerResetOrderDataInput = {
  condition: Scalars['String']['input']
  id: Scalars['String']['input']
  method: Scalars['String']['input']
  provider: Scalars['String']['input']
}

export type ApiSalesManagerResetOrderOutput = {
  __typename?: 'ApiSalesManagerResetOrderOutput'
  result: Scalars['String']['output']
}

export type ApiSalesManagerTransactionToOrderDataInput = {
  reference: Scalars['String']['input']
  transactionId: Scalars['String']['input']
}

export type ApiSalesManagerTransactionToOrderOutput = {
  __typename?: 'ApiSalesManagerTransactionToOrderOutput'
  result: Scalars['Boolean']['output']
}

export type ApiSalesManagerUpdateTransactionDataInput = {
  forceCancel?: InputMaybe<Scalars['Boolean']['input']>
  request: Scalars['String']['input']
  transactionId: Scalars['String']['input']
}

export type ApiSalesManagerUpdateTransactionOutput = {
  __typename?: 'ApiSalesManagerUpdateTransactionOutput'
  result: Scalars['Boolean']['output']
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>
  _gt?: InputMaybe<Scalars['Boolean']['input']>
  _gte?: InputMaybe<Scalars['Boolean']['input']>
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['Boolean']['input']>
  _lte?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<Scalars['Boolean']['input']>
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>
}

export type CustomerStatisticOutput = {
  __typename?: 'CustomerStatisticOutput'
  customer_erp_id: Scalars['String']['output']
  revenue_2_years_ago: Scalars['Float']['output']
  revenue_current_year: Scalars['Float']['output']
  revenue_last_year: Scalars['Float']['output']
}

export enum DocTypes {
  Fattura = 'Fattura',
  NotaCredito = 'NotaCredito',
  Ordine = 'Ordine',
  Spedizione = 'Spedizione',
}

export type FileDataOutput = {
  __typename?: 'FileDataOutput'
  content: Scalars['String']['output']
  mime: Scalars['String']['output']
}

export type FileDeleteDataInput = {
  name: Scalars['String']['input']
  type: Scalars['String']['input']
}

export type FileDeleteOutput = {
  __typename?: 'FileDeleteOutput'
  result: Scalars['Boolean']['output']
}

export type FileUploadDataInput = {
  content: Scalars['String']['input']
  mimeType: Scalars['String']['input']
  name: Scalars['String']['input']
  persist?: InputMaybe<Scalars['Boolean']['input']>
  type: Scalars['String']['input']
}

export type FileUploadOutput = {
  __typename?: 'FileUploadOutput'
  id: Scalars['String']['output']
}

export type InsertSubcustomerOutput = {
  __typename?: 'InsertSubcustomerOutput'
  id: Scalars['String']['output']
  user_id: Scalars['String']['output']
}

export type InsertUserOutput = {
  __typename?: 'InsertUserOutput'
  id: Scalars['String']['output']
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>
  _gt?: InputMaybe<Scalars['Int']['input']>
  _gte?: InputMaybe<Scalars['Int']['input']>
  _in?: InputMaybe<Array<Scalars['Int']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['Int']['input']>
  _lte?: InputMaybe<Scalars['Int']['input']>
  _neq?: InputMaybe<Scalars['Int']['input']>
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type LoginOutput = {
  __typename?: 'LoginOutput'
  accessToken: Scalars['String']['output']
}

export type SelectCustomerOutput = {
  __typename?: 'SelectCustomerOutput'
  accessToken: Scalars['String']['output']
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>
  _gt?: InputMaybe<Scalars['String']['input']>
  _gte?: InputMaybe<Scalars['String']['input']>
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>
  _in?: InputMaybe<Array<Scalars['String']['input']>>
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>
  _lt?: InputMaybe<Scalars['String']['input']>
  _lte?: InputMaybe<Scalars['String']['input']>
  _neq?: InputMaybe<Scalars['String']['input']>
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>
  _nin?: InputMaybe<Array<Scalars['String']['input']>>
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>
}

export type UpdateSubcustomerOutput = {
  __typename?: 'UpdateSubcustomerOutput'
  customer_erp_id: Scalars['String']['output']
  id: Scalars['String']['output']
}

export type UpdateUserOutput = {
  __typename?: 'UpdateUserOutput'
  id: Scalars['String']['output']
  login: Scalars['String']['output']
  role: Scalars['String']['output']
}

/** columns and relationships of "agent" */
export type Agent = {
  __typename?: 'agent'
  /** An array relationship */
  agent_bonus: Array<Agent_Bonus>
  /** An aggregate relationship */
  agent_bonus_aggregate: Agent_Bonus_Aggregate
  created_at: Scalars['timestamptz']['output']
  /** An array relationship */
  customer_address_dropshippings: Array<Customer_Address_Dropshipping>
  /** An aggregate relationship */
  customer_address_dropshippings_aggregate: Customer_Address_Dropshipping_Aggregate
  /** An array relationship */
  customer_addresses: Array<Customer_Address>
  /** An aggregate relationship */
  customer_addresses_aggregate: Customer_Address_Aggregate
  /** An array relationship */
  customers: Array<Customer>
  /** An aggregate relationship */
  customers_aggregate: Customer_Aggregate
  email: Scalars['String']['output']
  erp_id: Scalars['String']['output']
  id: Scalars['uuid']['output']
  name: Scalars['String']['output']
  /** An array relationship */
  reports: Array<Report>
  /** An aggregate relationship */
  reports_aggregate: Report_Aggregate
  rev: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** columns and relationships of "agent" */
export type AgentAgent_BonusArgs = {
  distinct_on?: InputMaybe<Array<Agent_Bonus_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Agent_Bonus_Order_By>>
  where?: InputMaybe<Agent_Bonus_Bool_Exp>
}

/** columns and relationships of "agent" */
export type AgentAgent_Bonus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agent_Bonus_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Agent_Bonus_Order_By>>
  where?: InputMaybe<Agent_Bonus_Bool_Exp>
}

/** columns and relationships of "agent" */
export type AgentCustomer_Address_DropshippingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Address_Dropshipping_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Address_Dropshipping_Order_By>>
  where?: InputMaybe<Customer_Address_Dropshipping_Bool_Exp>
}

/** columns and relationships of "agent" */
export type AgentCustomer_Address_Dropshippings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Address_Dropshipping_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Address_Dropshipping_Order_By>>
  where?: InputMaybe<Customer_Address_Dropshipping_Bool_Exp>
}

/** columns and relationships of "agent" */
export type AgentCustomer_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Address_Order_By>>
  where?: InputMaybe<Customer_Address_Bool_Exp>
}

/** columns and relationships of "agent" */
export type AgentCustomer_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Address_Order_By>>
  where?: InputMaybe<Customer_Address_Bool_Exp>
}

/** columns and relationships of "agent" */
export type AgentCustomersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

/** columns and relationships of "agent" */
export type AgentCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

/** columns and relationships of "agent" */
export type AgentReportsArgs = {
  distinct_on?: InputMaybe<Array<Report_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Report_Order_By>>
  where?: InputMaybe<Report_Bool_Exp>
}

/** columns and relationships of "agent" */
export type AgentReports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Report_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Report_Order_By>>
  where?: InputMaybe<Report_Bool_Exp>
}

/** aggregated selection of "agent" */
export type Agent_Aggregate = {
  __typename?: 'agent_aggregate'
  aggregate?: Maybe<Agent_Aggregate_Fields>
  nodes: Array<Agent>
}

/** aggregate fields of "agent" */
export type Agent_Aggregate_Fields = {
  __typename?: 'agent_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Agent_Max_Fields>
  min?: Maybe<Agent_Min_Fields>
}

/** aggregate fields of "agent" */
export type Agent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Agent_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** columns and relationships of "agent_bonus" */
export type Agent_Bonus = {
  __typename?: 'agent_bonus'
  /** An object relationship */
  agent: Agent
  created_at: Scalars['timestamptz']['output']
  erp_id: Scalars['String']['output']
  id: Scalars['uuid']['output']
  name: Scalars['String']['output']
  prize_current_revenue: Scalars['numeric']['output']
  prize_current_year: Scalars['bigint']['output']
  prize_return_rate_1: Scalars['numeric']['output']
  prize_return_rate_2: Scalars['numeric']['output']
  prize_return_rate_3: Scalars['numeric']['output']
  prize_target_revenue_1: Scalars['numeric']['output']
  prize_target_revenue_2: Scalars['numeric']['output']
  prize_target_revenue_3: Scalars['numeric']['output']
  rev: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** aggregated selection of "agent_bonus" */
export type Agent_Bonus_Aggregate = {
  __typename?: 'agent_bonus_aggregate'
  aggregate?: Maybe<Agent_Bonus_Aggregate_Fields>
  nodes: Array<Agent_Bonus>
}

export type Agent_Bonus_Aggregate_Bool_Exp = {
  count?: InputMaybe<Agent_Bonus_Aggregate_Bool_Exp_Count>
}

export type Agent_Bonus_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Agent_Bonus_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Agent_Bonus_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "agent_bonus" */
export type Agent_Bonus_Aggregate_Fields = {
  __typename?: 'agent_bonus_aggregate_fields'
  avg?: Maybe<Agent_Bonus_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Agent_Bonus_Max_Fields>
  min?: Maybe<Agent_Bonus_Min_Fields>
  stddev?: Maybe<Agent_Bonus_Stddev_Fields>
  stddev_pop?: Maybe<Agent_Bonus_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Agent_Bonus_Stddev_Samp_Fields>
  sum?: Maybe<Agent_Bonus_Sum_Fields>
  var_pop?: Maybe<Agent_Bonus_Var_Pop_Fields>
  var_samp?: Maybe<Agent_Bonus_Var_Samp_Fields>
  variance?: Maybe<Agent_Bonus_Variance_Fields>
}

/** aggregate fields of "agent_bonus" */
export type Agent_Bonus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Agent_Bonus_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "agent_bonus" */
export type Agent_Bonus_Aggregate_Order_By = {
  avg?: InputMaybe<Agent_Bonus_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Agent_Bonus_Max_Order_By>
  min?: InputMaybe<Agent_Bonus_Min_Order_By>
  stddev?: InputMaybe<Agent_Bonus_Stddev_Order_By>
  stddev_pop?: InputMaybe<Agent_Bonus_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Agent_Bonus_Stddev_Samp_Order_By>
  sum?: InputMaybe<Agent_Bonus_Sum_Order_By>
  var_pop?: InputMaybe<Agent_Bonus_Var_Pop_Order_By>
  var_samp?: InputMaybe<Agent_Bonus_Var_Samp_Order_By>
  variance?: InputMaybe<Agent_Bonus_Variance_Order_By>
}

/** input type for inserting array relation for remote table "agent_bonus" */
export type Agent_Bonus_Arr_Rel_Insert_Input = {
  data: Array<Agent_Bonus_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Agent_Bonus_On_Conflict>
}

/** aggregate avg on columns */
export type Agent_Bonus_Avg_Fields = {
  __typename?: 'agent_bonus_avg_fields'
  prize_current_revenue?: Maybe<Scalars['Float']['output']>
  prize_current_year?: Maybe<Scalars['Float']['output']>
  prize_return_rate_1?: Maybe<Scalars['Float']['output']>
  prize_return_rate_2?: Maybe<Scalars['Float']['output']>
  prize_return_rate_3?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_1?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_2?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_3?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "agent_bonus" */
export type Agent_Bonus_Avg_Order_By = {
  prize_current_revenue?: InputMaybe<Order_By>
  prize_current_year?: InputMaybe<Order_By>
  prize_return_rate_1?: InputMaybe<Order_By>
  prize_return_rate_2?: InputMaybe<Order_By>
  prize_return_rate_3?: InputMaybe<Order_By>
  prize_target_revenue_1?: InputMaybe<Order_By>
  prize_target_revenue_2?: InputMaybe<Order_By>
  prize_target_revenue_3?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "agent_bonus". All fields are combined with a logical 'AND'. */
export type Agent_Bonus_Bool_Exp = {
  _and?: InputMaybe<Array<Agent_Bonus_Bool_Exp>>
  _not?: InputMaybe<Agent_Bonus_Bool_Exp>
  _or?: InputMaybe<Array<Agent_Bonus_Bool_Exp>>
  agent?: InputMaybe<Agent_Bool_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  erp_id?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  prize_current_revenue?: InputMaybe<Numeric_Comparison_Exp>
  prize_current_year?: InputMaybe<Bigint_Comparison_Exp>
  prize_return_rate_1?: InputMaybe<Numeric_Comparison_Exp>
  prize_return_rate_2?: InputMaybe<Numeric_Comparison_Exp>
  prize_return_rate_3?: InputMaybe<Numeric_Comparison_Exp>
  prize_target_revenue_1?: InputMaybe<Numeric_Comparison_Exp>
  prize_target_revenue_2?: InputMaybe<Numeric_Comparison_Exp>
  prize_target_revenue_3?: InputMaybe<Numeric_Comparison_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "agent_bonus" */
export enum Agent_Bonus_Constraint {
  /** unique or primary key constraint on columns "erp_id", "prize_current_year" */
  AgentBonusErpIdPrizeCurrentYearKey = 'agent_bonus_erp_id_prize_current_year_key',
  /** unique or primary key constraint on columns "id" */
  AgentBonusPkey = 'agent_bonus_pkey',
}

/** input type for incrementing numeric columns in table "agent_bonus" */
export type Agent_Bonus_Inc_Input = {
  prize_current_revenue?: InputMaybe<Scalars['numeric']['input']>
  prize_current_year?: InputMaybe<Scalars['bigint']['input']>
  prize_return_rate_1?: InputMaybe<Scalars['numeric']['input']>
  prize_return_rate_2?: InputMaybe<Scalars['numeric']['input']>
  prize_return_rate_3?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_1?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_2?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_3?: InputMaybe<Scalars['numeric']['input']>
}

/** input type for inserting data into table "agent_bonus" */
export type Agent_Bonus_Insert_Input = {
  agent?: InputMaybe<Agent_Obj_Rel_Insert_Input>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  prize_current_revenue?: InputMaybe<Scalars['numeric']['input']>
  prize_current_year?: InputMaybe<Scalars['bigint']['input']>
  prize_return_rate_1?: InputMaybe<Scalars['numeric']['input']>
  prize_return_rate_2?: InputMaybe<Scalars['numeric']['input']>
  prize_return_rate_3?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_1?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_2?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_3?: InputMaybe<Scalars['numeric']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Agent_Bonus_Max_Fields = {
  __typename?: 'agent_bonus_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  prize_current_revenue?: Maybe<Scalars['numeric']['output']>
  prize_current_year?: Maybe<Scalars['bigint']['output']>
  prize_return_rate_1?: Maybe<Scalars['numeric']['output']>
  prize_return_rate_2?: Maybe<Scalars['numeric']['output']>
  prize_return_rate_3?: Maybe<Scalars['numeric']['output']>
  prize_target_revenue_1?: Maybe<Scalars['numeric']['output']>
  prize_target_revenue_2?: Maybe<Scalars['numeric']['output']>
  prize_target_revenue_3?: Maybe<Scalars['numeric']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "agent_bonus" */
export type Agent_Bonus_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  prize_current_revenue?: InputMaybe<Order_By>
  prize_current_year?: InputMaybe<Order_By>
  prize_return_rate_1?: InputMaybe<Order_By>
  prize_return_rate_2?: InputMaybe<Order_By>
  prize_return_rate_3?: InputMaybe<Order_By>
  prize_target_revenue_1?: InputMaybe<Order_By>
  prize_target_revenue_2?: InputMaybe<Order_By>
  prize_target_revenue_3?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Agent_Bonus_Min_Fields = {
  __typename?: 'agent_bonus_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  prize_current_revenue?: Maybe<Scalars['numeric']['output']>
  prize_current_year?: Maybe<Scalars['bigint']['output']>
  prize_return_rate_1?: Maybe<Scalars['numeric']['output']>
  prize_return_rate_2?: Maybe<Scalars['numeric']['output']>
  prize_return_rate_3?: Maybe<Scalars['numeric']['output']>
  prize_target_revenue_1?: Maybe<Scalars['numeric']['output']>
  prize_target_revenue_2?: Maybe<Scalars['numeric']['output']>
  prize_target_revenue_3?: Maybe<Scalars['numeric']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "agent_bonus" */
export type Agent_Bonus_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  prize_current_revenue?: InputMaybe<Order_By>
  prize_current_year?: InputMaybe<Order_By>
  prize_return_rate_1?: InputMaybe<Order_By>
  prize_return_rate_2?: InputMaybe<Order_By>
  prize_return_rate_3?: InputMaybe<Order_By>
  prize_target_revenue_1?: InputMaybe<Order_By>
  prize_target_revenue_2?: InputMaybe<Order_By>
  prize_target_revenue_3?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "agent_bonus" */
export type Agent_Bonus_Mutation_Response = {
  __typename?: 'agent_bonus_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Agent_Bonus>
}

/** on_conflict condition type for table "agent_bonus" */
export type Agent_Bonus_On_Conflict = {
  constraint: Agent_Bonus_Constraint
  update_columns?: Array<Agent_Bonus_Update_Column>
  where?: InputMaybe<Agent_Bonus_Bool_Exp>
}

/** Ordering options when selecting data from "agent_bonus". */
export type Agent_Bonus_Order_By = {
  agent?: InputMaybe<Agent_Order_By>
  created_at?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  prize_current_revenue?: InputMaybe<Order_By>
  prize_current_year?: InputMaybe<Order_By>
  prize_return_rate_1?: InputMaybe<Order_By>
  prize_return_rate_2?: InputMaybe<Order_By>
  prize_return_rate_3?: InputMaybe<Order_By>
  prize_target_revenue_1?: InputMaybe<Order_By>
  prize_target_revenue_2?: InputMaybe<Order_By>
  prize_target_revenue_3?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: agent_bonus */
export type Agent_Bonus_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "agent_bonus" */
export enum Agent_Bonus_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ErpId = 'erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PrizeCurrentRevenue = 'prize_current_revenue',
  /** column name */
  PrizeCurrentYear = 'prize_current_year',
  /** column name */
  PrizeReturnRate_1 = 'prize_return_rate_1',
  /** column name */
  PrizeReturnRate_2 = 'prize_return_rate_2',
  /** column name */
  PrizeReturnRate_3 = 'prize_return_rate_3',
  /** column name */
  PrizeTargetRevenue_1 = 'prize_target_revenue_1',
  /** column name */
  PrizeTargetRevenue_2 = 'prize_target_revenue_2',
  /** column name */
  PrizeTargetRevenue_3 = 'prize_target_revenue_3',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "agent_bonus" */
export type Agent_Bonus_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  prize_current_revenue?: InputMaybe<Scalars['numeric']['input']>
  prize_current_year?: InputMaybe<Scalars['bigint']['input']>
  prize_return_rate_1?: InputMaybe<Scalars['numeric']['input']>
  prize_return_rate_2?: InputMaybe<Scalars['numeric']['input']>
  prize_return_rate_3?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_1?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_2?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_3?: InputMaybe<Scalars['numeric']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate stddev on columns */
export type Agent_Bonus_Stddev_Fields = {
  __typename?: 'agent_bonus_stddev_fields'
  prize_current_revenue?: Maybe<Scalars['Float']['output']>
  prize_current_year?: Maybe<Scalars['Float']['output']>
  prize_return_rate_1?: Maybe<Scalars['Float']['output']>
  prize_return_rate_2?: Maybe<Scalars['Float']['output']>
  prize_return_rate_3?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_1?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_2?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_3?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "agent_bonus" */
export type Agent_Bonus_Stddev_Order_By = {
  prize_current_revenue?: InputMaybe<Order_By>
  prize_current_year?: InputMaybe<Order_By>
  prize_return_rate_1?: InputMaybe<Order_By>
  prize_return_rate_2?: InputMaybe<Order_By>
  prize_return_rate_3?: InputMaybe<Order_By>
  prize_target_revenue_1?: InputMaybe<Order_By>
  prize_target_revenue_2?: InputMaybe<Order_By>
  prize_target_revenue_3?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Agent_Bonus_Stddev_Pop_Fields = {
  __typename?: 'agent_bonus_stddev_pop_fields'
  prize_current_revenue?: Maybe<Scalars['Float']['output']>
  prize_current_year?: Maybe<Scalars['Float']['output']>
  prize_return_rate_1?: Maybe<Scalars['Float']['output']>
  prize_return_rate_2?: Maybe<Scalars['Float']['output']>
  prize_return_rate_3?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_1?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_2?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_3?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "agent_bonus" */
export type Agent_Bonus_Stddev_Pop_Order_By = {
  prize_current_revenue?: InputMaybe<Order_By>
  prize_current_year?: InputMaybe<Order_By>
  prize_return_rate_1?: InputMaybe<Order_By>
  prize_return_rate_2?: InputMaybe<Order_By>
  prize_return_rate_3?: InputMaybe<Order_By>
  prize_target_revenue_1?: InputMaybe<Order_By>
  prize_target_revenue_2?: InputMaybe<Order_By>
  prize_target_revenue_3?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Agent_Bonus_Stddev_Samp_Fields = {
  __typename?: 'agent_bonus_stddev_samp_fields'
  prize_current_revenue?: Maybe<Scalars['Float']['output']>
  prize_current_year?: Maybe<Scalars['Float']['output']>
  prize_return_rate_1?: Maybe<Scalars['Float']['output']>
  prize_return_rate_2?: Maybe<Scalars['Float']['output']>
  prize_return_rate_3?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_1?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_2?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_3?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "agent_bonus" */
export type Agent_Bonus_Stddev_Samp_Order_By = {
  prize_current_revenue?: InputMaybe<Order_By>
  prize_current_year?: InputMaybe<Order_By>
  prize_return_rate_1?: InputMaybe<Order_By>
  prize_return_rate_2?: InputMaybe<Order_By>
  prize_return_rate_3?: InputMaybe<Order_By>
  prize_target_revenue_1?: InputMaybe<Order_By>
  prize_target_revenue_2?: InputMaybe<Order_By>
  prize_target_revenue_3?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "agent_bonus" */
export type Agent_Bonus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Agent_Bonus_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Agent_Bonus_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  prize_current_revenue?: InputMaybe<Scalars['numeric']['input']>
  prize_current_year?: InputMaybe<Scalars['bigint']['input']>
  prize_return_rate_1?: InputMaybe<Scalars['numeric']['input']>
  prize_return_rate_2?: InputMaybe<Scalars['numeric']['input']>
  prize_return_rate_3?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_1?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_2?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_3?: InputMaybe<Scalars['numeric']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate sum on columns */
export type Agent_Bonus_Sum_Fields = {
  __typename?: 'agent_bonus_sum_fields'
  prize_current_revenue?: Maybe<Scalars['numeric']['output']>
  prize_current_year?: Maybe<Scalars['bigint']['output']>
  prize_return_rate_1?: Maybe<Scalars['numeric']['output']>
  prize_return_rate_2?: Maybe<Scalars['numeric']['output']>
  prize_return_rate_3?: Maybe<Scalars['numeric']['output']>
  prize_target_revenue_1?: Maybe<Scalars['numeric']['output']>
  prize_target_revenue_2?: Maybe<Scalars['numeric']['output']>
  prize_target_revenue_3?: Maybe<Scalars['numeric']['output']>
}

/** order by sum() on columns of table "agent_bonus" */
export type Agent_Bonus_Sum_Order_By = {
  prize_current_revenue?: InputMaybe<Order_By>
  prize_current_year?: InputMaybe<Order_By>
  prize_return_rate_1?: InputMaybe<Order_By>
  prize_return_rate_2?: InputMaybe<Order_By>
  prize_return_rate_3?: InputMaybe<Order_By>
  prize_target_revenue_1?: InputMaybe<Order_By>
  prize_target_revenue_2?: InputMaybe<Order_By>
  prize_target_revenue_3?: InputMaybe<Order_By>
}

/** update columns of table "agent_bonus" */
export enum Agent_Bonus_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ErpId = 'erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PrizeCurrentRevenue = 'prize_current_revenue',
  /** column name */
  PrizeCurrentYear = 'prize_current_year',
  /** column name */
  PrizeReturnRate_1 = 'prize_return_rate_1',
  /** column name */
  PrizeReturnRate_2 = 'prize_return_rate_2',
  /** column name */
  PrizeReturnRate_3 = 'prize_return_rate_3',
  /** column name */
  PrizeTargetRevenue_1 = 'prize_target_revenue_1',
  /** column name */
  PrizeTargetRevenue_2 = 'prize_target_revenue_2',
  /** column name */
  PrizeTargetRevenue_3 = 'prize_target_revenue_3',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Agent_Bonus_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Agent_Bonus_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Agent_Bonus_Set_Input>
  /** filter the rows which have to be updated */
  where: Agent_Bonus_Bool_Exp
}

/** aggregate var_pop on columns */
export type Agent_Bonus_Var_Pop_Fields = {
  __typename?: 'agent_bonus_var_pop_fields'
  prize_current_revenue?: Maybe<Scalars['Float']['output']>
  prize_current_year?: Maybe<Scalars['Float']['output']>
  prize_return_rate_1?: Maybe<Scalars['Float']['output']>
  prize_return_rate_2?: Maybe<Scalars['Float']['output']>
  prize_return_rate_3?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_1?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_2?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_3?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "agent_bonus" */
export type Agent_Bonus_Var_Pop_Order_By = {
  prize_current_revenue?: InputMaybe<Order_By>
  prize_current_year?: InputMaybe<Order_By>
  prize_return_rate_1?: InputMaybe<Order_By>
  prize_return_rate_2?: InputMaybe<Order_By>
  prize_return_rate_3?: InputMaybe<Order_By>
  prize_target_revenue_1?: InputMaybe<Order_By>
  prize_target_revenue_2?: InputMaybe<Order_By>
  prize_target_revenue_3?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Agent_Bonus_Var_Samp_Fields = {
  __typename?: 'agent_bonus_var_samp_fields'
  prize_current_revenue?: Maybe<Scalars['Float']['output']>
  prize_current_year?: Maybe<Scalars['Float']['output']>
  prize_return_rate_1?: Maybe<Scalars['Float']['output']>
  prize_return_rate_2?: Maybe<Scalars['Float']['output']>
  prize_return_rate_3?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_1?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_2?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_3?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "agent_bonus" */
export type Agent_Bonus_Var_Samp_Order_By = {
  prize_current_revenue?: InputMaybe<Order_By>
  prize_current_year?: InputMaybe<Order_By>
  prize_return_rate_1?: InputMaybe<Order_By>
  prize_return_rate_2?: InputMaybe<Order_By>
  prize_return_rate_3?: InputMaybe<Order_By>
  prize_target_revenue_1?: InputMaybe<Order_By>
  prize_target_revenue_2?: InputMaybe<Order_By>
  prize_target_revenue_3?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Agent_Bonus_Variance_Fields = {
  __typename?: 'agent_bonus_variance_fields'
  prize_current_revenue?: Maybe<Scalars['Float']['output']>
  prize_current_year?: Maybe<Scalars['Float']['output']>
  prize_return_rate_1?: Maybe<Scalars['Float']['output']>
  prize_return_rate_2?: Maybe<Scalars['Float']['output']>
  prize_return_rate_3?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_1?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_2?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_3?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "agent_bonus" */
export type Agent_Bonus_Variance_Order_By = {
  prize_current_revenue?: InputMaybe<Order_By>
  prize_current_year?: InputMaybe<Order_By>
  prize_return_rate_1?: InputMaybe<Order_By>
  prize_return_rate_2?: InputMaybe<Order_By>
  prize_return_rate_3?: InputMaybe<Order_By>
  prize_target_revenue_1?: InputMaybe<Order_By>
  prize_target_revenue_2?: InputMaybe<Order_By>
  prize_target_revenue_3?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "agent". All fields are combined with a logical 'AND'. */
export type Agent_Bool_Exp = {
  _and?: InputMaybe<Array<Agent_Bool_Exp>>
  _not?: InputMaybe<Agent_Bool_Exp>
  _or?: InputMaybe<Array<Agent_Bool_Exp>>
  agent_bonus?: InputMaybe<Agent_Bonus_Bool_Exp>
  agent_bonus_aggregate?: InputMaybe<Agent_Bonus_Aggregate_Bool_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customer_address_dropshippings?: InputMaybe<Customer_Address_Dropshipping_Bool_Exp>
  customer_address_dropshippings_aggregate?: InputMaybe<Customer_Address_Dropshipping_Aggregate_Bool_Exp>
  customer_addresses?: InputMaybe<Customer_Address_Bool_Exp>
  customer_addresses_aggregate?: InputMaybe<Customer_Address_Aggregate_Bool_Exp>
  customers?: InputMaybe<Customer_Bool_Exp>
  customers_aggregate?: InputMaybe<Customer_Aggregate_Bool_Exp>
  email?: InputMaybe<String_Comparison_Exp>
  erp_id?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  reports?: InputMaybe<Report_Bool_Exp>
  reports_aggregate?: InputMaybe<Report_Aggregate_Bool_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user?: InputMaybe<User_Bool_Exp>
  user_id?: InputMaybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "agent" */
export enum Agent_Constraint {
  /** unique or primary key constraint on columns "erp_id" */
  AgentErpIdKey = 'agent_erp_id_key',
  /** unique or primary key constraint on columns "id" */
  AgentPkey = 'agent_pkey',
  /** unique or primary key constraint on columns "user_id" */
  AgentUserIdKey = 'agent_user_id_key',
}

/** input type for inserting data into table "agent" */
export type Agent_Insert_Input = {
  agent_bonus?: InputMaybe<Agent_Bonus_Arr_Rel_Insert_Input>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_address_dropshippings?: InputMaybe<Customer_Address_Dropshipping_Arr_Rel_Insert_Input>
  customer_addresses?: InputMaybe<Customer_Address_Arr_Rel_Insert_Input>
  customers?: InputMaybe<Customer_Arr_Rel_Insert_Input>
  email?: InputMaybe<Scalars['String']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  reports?: InputMaybe<Report_Arr_Rel_Insert_Input>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user?: InputMaybe<User_Obj_Rel_Insert_Input>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate max on columns */
export type Agent_Max_Fields = {
  __typename?: 'agent_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  email?: Maybe<Scalars['String']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** aggregate min on columns */
export type Agent_Min_Fields = {
  __typename?: 'agent_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  email?: Maybe<Scalars['String']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** response of any mutation on the table "agent" */
export type Agent_Mutation_Response = {
  __typename?: 'agent_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Agent>
}

/** input type for inserting object relation for remote table "agent" */
export type Agent_Obj_Rel_Insert_Input = {
  data: Agent_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Agent_On_Conflict>
}

/** on_conflict condition type for table "agent" */
export type Agent_On_Conflict = {
  constraint: Agent_Constraint
  update_columns?: Array<Agent_Update_Column>
  where?: InputMaybe<Agent_Bool_Exp>
}

/** Ordering options when selecting data from "agent". */
export type Agent_Order_By = {
  agent_bonus_aggregate?: InputMaybe<Agent_Bonus_Aggregate_Order_By>
  created_at?: InputMaybe<Order_By>
  customer_address_dropshippings_aggregate?: InputMaybe<Customer_Address_Dropshipping_Aggregate_Order_By>
  customer_addresses_aggregate?: InputMaybe<Customer_Address_Aggregate_Order_By>
  customers_aggregate?: InputMaybe<Customer_Aggregate_Order_By>
  email?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  reports_aggregate?: InputMaybe<Report_Aggregate_Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user?: InputMaybe<User_Order_By>
  user_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: agent */
export type Agent_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "agent" */
export enum Agent_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  ErpId = 'erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "agent" */
export type Agent_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** Streaming cursor of the table "agent" */
export type Agent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Agent_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Agent_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** update columns of table "agent" */
export enum Agent_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  ErpId = 'erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Agent_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Agent_Set_Input>
  /** filter the rows which have to be updated */
  where: Agent_Bool_Exp
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>
  _gt?: InputMaybe<Scalars['bigint']['input']>
  _gte?: InputMaybe<Scalars['bigint']['input']>
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['bigint']['input']>
  _lte?: InputMaybe<Scalars['bigint']['input']>
  _neq?: InputMaybe<Scalars['bigint']['input']>
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>
}

/** columns and relationships of "business_sector" */
export type Business_Sector = {
  __typename?: 'business_sector'
  code: Scalars['String']['output']
  description: Scalars['String']['output']
  description_de: Scalars['String']['output']
  description_en: Scalars['String']['output']
  description_es: Scalars['String']['output']
  description_fr: Scalars['String']['output']
  description_it: Scalars['String']['output']
  description_ru: Scalars['String']['output']
}

/** aggregated selection of "business_sector" */
export type Business_Sector_Aggregate = {
  __typename?: 'business_sector_aggregate'
  aggregate?: Maybe<Business_Sector_Aggregate_Fields>
  nodes: Array<Business_Sector>
}

/** aggregate fields of "business_sector" */
export type Business_Sector_Aggregate_Fields = {
  __typename?: 'business_sector_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Business_Sector_Max_Fields>
  min?: Maybe<Business_Sector_Min_Fields>
}

/** aggregate fields of "business_sector" */
export type Business_Sector_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Business_Sector_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "business_sector". All fields are combined with a logical 'AND'. */
export type Business_Sector_Bool_Exp = {
  _and?: InputMaybe<Array<Business_Sector_Bool_Exp>>
  _not?: InputMaybe<Business_Sector_Bool_Exp>
  _or?: InputMaybe<Array<Business_Sector_Bool_Exp>>
  code?: InputMaybe<String_Comparison_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  description_de?: InputMaybe<String_Comparison_Exp>
  description_en?: InputMaybe<String_Comparison_Exp>
  description_es?: InputMaybe<String_Comparison_Exp>
  description_fr?: InputMaybe<String_Comparison_Exp>
  description_it?: InputMaybe<String_Comparison_Exp>
  description_ru?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "business_sector" */
export enum Business_Sector_Constraint {
  /** unique or primary key constraint on columns "code" */
  BusinessSectorPkey = 'business_sector_pkey',
}

/** input type for inserting data into table "business_sector" */
export type Business_Sector_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  description_de?: InputMaybe<Scalars['String']['input']>
  description_en?: InputMaybe<Scalars['String']['input']>
  description_es?: InputMaybe<Scalars['String']['input']>
  description_fr?: InputMaybe<Scalars['String']['input']>
  description_it?: InputMaybe<Scalars['String']['input']>
  description_ru?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Business_Sector_Max_Fields = {
  __typename?: 'business_sector_max_fields'
  code?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  description_de?: Maybe<Scalars['String']['output']>
  description_en?: Maybe<Scalars['String']['output']>
  description_es?: Maybe<Scalars['String']['output']>
  description_fr?: Maybe<Scalars['String']['output']>
  description_it?: Maybe<Scalars['String']['output']>
  description_ru?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Business_Sector_Min_Fields = {
  __typename?: 'business_sector_min_fields'
  code?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  description_de?: Maybe<Scalars['String']['output']>
  description_en?: Maybe<Scalars['String']['output']>
  description_es?: Maybe<Scalars['String']['output']>
  description_fr?: Maybe<Scalars['String']['output']>
  description_it?: Maybe<Scalars['String']['output']>
  description_ru?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "business_sector" */
export type Business_Sector_Mutation_Response = {
  __typename?: 'business_sector_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Business_Sector>
}

/** on_conflict condition type for table "business_sector" */
export type Business_Sector_On_Conflict = {
  constraint: Business_Sector_Constraint
  update_columns?: Array<Business_Sector_Update_Column>
  where?: InputMaybe<Business_Sector_Bool_Exp>
}

/** Ordering options when selecting data from "business_sector". */
export type Business_Sector_Order_By = {
  code?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  description_de?: InputMaybe<Order_By>
  description_en?: InputMaybe<Order_By>
  description_es?: InputMaybe<Order_By>
  description_fr?: InputMaybe<Order_By>
  description_it?: InputMaybe<Order_By>
  description_ru?: InputMaybe<Order_By>
}

/** primary key columns input for table: business_sector */
export type Business_Sector_Pk_Columns_Input = {
  code: Scalars['String']['input']
}

/** select columns of table "business_sector" */
export enum Business_Sector_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionDe = 'description_de',
  /** column name */
  DescriptionEn = 'description_en',
  /** column name */
  DescriptionEs = 'description_es',
  /** column name */
  DescriptionFr = 'description_fr',
  /** column name */
  DescriptionIt = 'description_it',
  /** column name */
  DescriptionRu = 'description_ru',
}

/** input type for updating data in table "business_sector" */
export type Business_Sector_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  description_de?: InputMaybe<Scalars['String']['input']>
  description_en?: InputMaybe<Scalars['String']['input']>
  description_es?: InputMaybe<Scalars['String']['input']>
  description_fr?: InputMaybe<Scalars['String']['input']>
  description_it?: InputMaybe<Scalars['String']['input']>
  description_ru?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "business_sector" */
export type Business_Sector_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Business_Sector_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Business_Sector_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  description_de?: InputMaybe<Scalars['String']['input']>
  description_en?: InputMaybe<Scalars['String']['input']>
  description_es?: InputMaybe<Scalars['String']['input']>
  description_fr?: InputMaybe<Scalars['String']['input']>
  description_it?: InputMaybe<Scalars['String']['input']>
  description_ru?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "business_sector" */
export enum Business_Sector_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionDe = 'description_de',
  /** column name */
  DescriptionEn = 'description_en',
  /** column name */
  DescriptionEs = 'description_es',
  /** column name */
  DescriptionFr = 'description_fr',
  /** column name */
  DescriptionIt = 'description_it',
  /** column name */
  DescriptionRu = 'description_ru',
}

export type Business_Sector_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Business_Sector_Set_Input>
  /** filter the rows which have to be updated */
  where: Business_Sector_Bool_Exp
}

/** columns and relationships of "cart" */
export type Cart = {
  __typename?: 'cart'
  additional_info?: Maybe<Scalars['jsonb']['output']>
  /** An object relationship */
  agent?: Maybe<Agent>
  agent_erp_id?: Maybe<Scalars['String']['output']>
  /** An array relationship */
  cart_items: Array<Cart_Item>
  /** An aggregate relationship */
  cart_items_aggregate: Cart_Item_Aggregate
  /** An array relationship */
  cart_replacements: Array<Cart_Replacement>
  /** An aggregate relationship */
  cart_replacements_aggregate: Cart_Replacement_Aggregate
  created_at: Scalars['timestamptz']['output']
  /** An object relationship */
  customer?: Maybe<Customer>
  customer_erp_id: Scalars['String']['output']
  id: Scalars['bigint']['output']
  /** An object relationship */
  order?: Maybe<Order>
  order_id?: Maybe<Scalars['Int']['output']>
  status: Scalars['String']['output']
  /** An object relationship */
  subcustomer?: Maybe<Subcustomer>
  subcustomer_id?: Maybe<Scalars['uuid']['output']>
  type: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "cart" */
export type CartAdditional_InfoArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** columns and relationships of "cart" */
export type CartCart_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Cart_Item_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Cart_Item_Order_By>>
  where?: InputMaybe<Cart_Item_Bool_Exp>
}

/** columns and relationships of "cart" */
export type CartCart_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cart_Item_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Cart_Item_Order_By>>
  where?: InputMaybe<Cart_Item_Bool_Exp>
}

/** columns and relationships of "cart" */
export type CartCart_ReplacementsArgs = {
  distinct_on?: InputMaybe<Array<Cart_Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Cart_Replacement_Order_By>>
  where?: InputMaybe<Cart_Replacement_Bool_Exp>
}

/** columns and relationships of "cart" */
export type CartCart_Replacements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cart_Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Cart_Replacement_Order_By>>
  where?: InputMaybe<Cart_Replacement_Bool_Exp>
}

/** aggregated selection of "cart" */
export type Cart_Aggregate = {
  __typename?: 'cart_aggregate'
  aggregate?: Maybe<Cart_Aggregate_Fields>
  nodes: Array<Cart>
}

/** aggregate fields of "cart" */
export type Cart_Aggregate_Fields = {
  __typename?: 'cart_aggregate_fields'
  avg?: Maybe<Cart_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Cart_Max_Fields>
  min?: Maybe<Cart_Min_Fields>
  stddev?: Maybe<Cart_Stddev_Fields>
  stddev_pop?: Maybe<Cart_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Cart_Stddev_Samp_Fields>
  sum?: Maybe<Cart_Sum_Fields>
  var_pop?: Maybe<Cart_Var_Pop_Fields>
  var_samp?: Maybe<Cart_Var_Samp_Fields>
  variance?: Maybe<Cart_Variance_Fields>
}

/** aggregate fields of "cart" */
export type Cart_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cart_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Cart_Append_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
}

/** aggregate avg on columns */
export type Cart_Avg_Fields = {
  __typename?: 'cart_avg_fields'
  id?: Maybe<Scalars['Float']['output']>
  order_id?: Maybe<Scalars['Float']['output']>
}

/** Boolean expression to filter rows from the table "cart". All fields are combined with a logical 'AND'. */
export type Cart_Bool_Exp = {
  _and?: InputMaybe<Array<Cart_Bool_Exp>>
  _not?: InputMaybe<Cart_Bool_Exp>
  _or?: InputMaybe<Array<Cart_Bool_Exp>>
  additional_info?: InputMaybe<Jsonb_Comparison_Exp>
  agent?: InputMaybe<Agent_Bool_Exp>
  agent_erp_id?: InputMaybe<String_Comparison_Exp>
  cart_items?: InputMaybe<Cart_Item_Bool_Exp>
  cart_items_aggregate?: InputMaybe<Cart_Item_Aggregate_Bool_Exp>
  cart_replacements?: InputMaybe<Cart_Replacement_Bool_Exp>
  cart_replacements_aggregate?: InputMaybe<Cart_Replacement_Aggregate_Bool_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customer?: InputMaybe<Customer_Bool_Exp>
  customer_erp_id?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Bigint_Comparison_Exp>
  order?: InputMaybe<Order_Bool_Exp>
  order_id?: InputMaybe<Int_Comparison_Exp>
  status?: InputMaybe<String_Comparison_Exp>
  subcustomer?: InputMaybe<Subcustomer_Bool_Exp>
  subcustomer_id?: InputMaybe<Uuid_Comparison_Exp>
  type?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "cart" */
export enum Cart_Constraint {
  /** unique or primary key constraint on columns "id" */
  CartPkey = 'cart_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Cart_Delete_At_Path_Input = {
  additional_info?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Cart_Delete_Elem_Input = {
  additional_info?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Cart_Delete_Key_Input = {
  additional_info?: InputMaybe<Scalars['String']['input']>
}

/** input type for incrementing numeric columns in table "cart" */
export type Cart_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>
  order_id?: InputMaybe<Scalars['Int']['input']>
}

/** input type for inserting data into table "cart" */
export type Cart_Insert_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  agent?: InputMaybe<Agent_Obj_Rel_Insert_Input>
  agent_erp_id?: InputMaybe<Scalars['String']['input']>
  cart_items?: InputMaybe<Cart_Item_Arr_Rel_Insert_Input>
  cart_replacements?: InputMaybe<Cart_Replacement_Arr_Rel_Insert_Input>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['bigint']['input']>
  order?: InputMaybe<Order_Obj_Rel_Insert_Input>
  order_id?: InputMaybe<Scalars['Int']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  subcustomer?: InputMaybe<Subcustomer_Obj_Rel_Insert_Input>
  subcustomer_id?: InputMaybe<Scalars['uuid']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** columns and relationships of "cart_item" */
export type Cart_Item = {
  __typename?: 'cart_item'
  additional_info?: Maybe<Scalars['jsonb']['output']>
  /** An object relationship */
  cart: Cart
  cart_id: Scalars['bigint']['output']
  created_at: Scalars['timestamptz']['output']
  id: Scalars['bigint']['output']
  /** An object relationship */
  product?: Maybe<Product>
  product_erp_id: Scalars['String']['output']
  qty: Scalars['Int']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "cart_item" */
export type Cart_ItemAdditional_InfoArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** aggregated selection of "cart_item" */
export type Cart_Item_Aggregate = {
  __typename?: 'cart_item_aggregate'
  aggregate?: Maybe<Cart_Item_Aggregate_Fields>
  nodes: Array<Cart_Item>
}

export type Cart_Item_Aggregate_Bool_Exp = {
  count?: InputMaybe<Cart_Item_Aggregate_Bool_Exp_Count>
}

export type Cart_Item_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Cart_Item_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Cart_Item_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "cart_item" */
export type Cart_Item_Aggregate_Fields = {
  __typename?: 'cart_item_aggregate_fields'
  avg?: Maybe<Cart_Item_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Cart_Item_Max_Fields>
  min?: Maybe<Cart_Item_Min_Fields>
  stddev?: Maybe<Cart_Item_Stddev_Fields>
  stddev_pop?: Maybe<Cart_Item_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Cart_Item_Stddev_Samp_Fields>
  sum?: Maybe<Cart_Item_Sum_Fields>
  var_pop?: Maybe<Cart_Item_Var_Pop_Fields>
  var_samp?: Maybe<Cart_Item_Var_Samp_Fields>
  variance?: Maybe<Cart_Item_Variance_Fields>
}

/** aggregate fields of "cart_item" */
export type Cart_Item_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cart_Item_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "cart_item" */
export type Cart_Item_Aggregate_Order_By = {
  avg?: InputMaybe<Cart_Item_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Cart_Item_Max_Order_By>
  min?: InputMaybe<Cart_Item_Min_Order_By>
  stddev?: InputMaybe<Cart_Item_Stddev_Order_By>
  stddev_pop?: InputMaybe<Cart_Item_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Cart_Item_Stddev_Samp_Order_By>
  sum?: InputMaybe<Cart_Item_Sum_Order_By>
  var_pop?: InputMaybe<Cart_Item_Var_Pop_Order_By>
  var_samp?: InputMaybe<Cart_Item_Var_Samp_Order_By>
  variance?: InputMaybe<Cart_Item_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Cart_Item_Append_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
}

/** input type for inserting array relation for remote table "cart_item" */
export type Cart_Item_Arr_Rel_Insert_Input = {
  data: Array<Cart_Item_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Cart_Item_On_Conflict>
}

/** aggregate avg on columns */
export type Cart_Item_Avg_Fields = {
  __typename?: 'cart_item_avg_fields'
  cart_id?: Maybe<Scalars['Float']['output']>
  id?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "cart_item" */
export type Cart_Item_Avg_Order_By = {
  cart_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "cart_item". All fields are combined with a logical 'AND'. */
export type Cart_Item_Bool_Exp = {
  _and?: InputMaybe<Array<Cart_Item_Bool_Exp>>
  _not?: InputMaybe<Cart_Item_Bool_Exp>
  _or?: InputMaybe<Array<Cart_Item_Bool_Exp>>
  additional_info?: InputMaybe<Jsonb_Comparison_Exp>
  cart?: InputMaybe<Cart_Bool_Exp>
  cart_id?: InputMaybe<Bigint_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Bigint_Comparison_Exp>
  product?: InputMaybe<Product_Bool_Exp>
  product_erp_id?: InputMaybe<String_Comparison_Exp>
  qty?: InputMaybe<Int_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "cart_item" */
export enum Cart_Item_Constraint {
  /** unique or primary key constraint on columns "cart_id", "product_erp_id", "id" */
  CartItemIdCartIdProductErpIdKey = 'cart_item_id_cart_id_product_erp_id_key',
  /** unique or primary key constraint on columns "id" */
  CartItemPkey = 'cart_item_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Cart_Item_Delete_At_Path_Input = {
  additional_info?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Cart_Item_Delete_Elem_Input = {
  additional_info?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Cart_Item_Delete_Key_Input = {
  additional_info?: InputMaybe<Scalars['String']['input']>
}

/** input type for incrementing numeric columns in table "cart_item" */
export type Cart_Item_Inc_Input = {
  cart_id?: InputMaybe<Scalars['bigint']['input']>
  id?: InputMaybe<Scalars['bigint']['input']>
  qty?: InputMaybe<Scalars['Int']['input']>
}

/** input type for inserting data into table "cart_item" */
export type Cart_Item_Insert_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  cart?: InputMaybe<Cart_Obj_Rel_Insert_Input>
  cart_id?: InputMaybe<Scalars['bigint']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['bigint']['input']>
  product?: InputMaybe<Product_Obj_Rel_Insert_Input>
  product_erp_id?: InputMaybe<Scalars['String']['input']>
  qty?: InputMaybe<Scalars['Int']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Cart_Item_Max_Fields = {
  __typename?: 'cart_item_max_fields'
  cart_id?: Maybe<Scalars['bigint']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['bigint']['output']>
  product_erp_id?: Maybe<Scalars['String']['output']>
  qty?: Maybe<Scalars['Int']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "cart_item" */
export type Cart_Item_Max_Order_By = {
  cart_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  product_erp_id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Cart_Item_Min_Fields = {
  __typename?: 'cart_item_min_fields'
  cart_id?: Maybe<Scalars['bigint']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['bigint']['output']>
  product_erp_id?: Maybe<Scalars['String']['output']>
  qty?: Maybe<Scalars['Int']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "cart_item" */
export type Cart_Item_Min_Order_By = {
  cart_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  product_erp_id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "cart_item" */
export type Cart_Item_Mutation_Response = {
  __typename?: 'cart_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Cart_Item>
}

/** on_conflict condition type for table "cart_item" */
export type Cart_Item_On_Conflict = {
  constraint: Cart_Item_Constraint
  update_columns?: Array<Cart_Item_Update_Column>
  where?: InputMaybe<Cart_Item_Bool_Exp>
}

/** Ordering options when selecting data from "cart_item". */
export type Cart_Item_Order_By = {
  additional_info?: InputMaybe<Order_By>
  cart?: InputMaybe<Cart_Order_By>
  cart_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  product?: InputMaybe<Product_Order_By>
  product_erp_id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: cart_item */
export type Cart_Item_Pk_Columns_Input = {
  id: Scalars['bigint']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Cart_Item_Prepend_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
}

/** select columns of table "cart_item" */
export enum Cart_Item_Select_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CartId = 'cart_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProductErpId = 'product_erp_id',
  /** column name */
  Qty = 'qty',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "cart_item" */
export type Cart_Item_Set_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  cart_id?: InputMaybe<Scalars['bigint']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['bigint']['input']>
  product_erp_id?: InputMaybe<Scalars['String']['input']>
  qty?: InputMaybe<Scalars['Int']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate stddev on columns */
export type Cart_Item_Stddev_Fields = {
  __typename?: 'cart_item_stddev_fields'
  cart_id?: Maybe<Scalars['Float']['output']>
  id?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "cart_item" */
export type Cart_Item_Stddev_Order_By = {
  cart_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Cart_Item_Stddev_Pop_Fields = {
  __typename?: 'cart_item_stddev_pop_fields'
  cart_id?: Maybe<Scalars['Float']['output']>
  id?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "cart_item" */
export type Cart_Item_Stddev_Pop_Order_By = {
  cart_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Cart_Item_Stddev_Samp_Fields = {
  __typename?: 'cart_item_stddev_samp_fields'
  cart_id?: Maybe<Scalars['Float']['output']>
  id?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "cart_item" */
export type Cart_Item_Stddev_Samp_Order_By = {
  cart_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "cart_item" */
export type Cart_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cart_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Cart_Item_Stream_Cursor_Value_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  cart_id?: InputMaybe<Scalars['bigint']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['bigint']['input']>
  product_erp_id?: InputMaybe<Scalars['String']['input']>
  qty?: InputMaybe<Scalars['Int']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate sum on columns */
export type Cart_Item_Sum_Fields = {
  __typename?: 'cart_item_sum_fields'
  cart_id?: Maybe<Scalars['bigint']['output']>
  id?: Maybe<Scalars['bigint']['output']>
  qty?: Maybe<Scalars['Int']['output']>
}

/** order by sum() on columns of table "cart_item" */
export type Cart_Item_Sum_Order_By = {
  cart_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** update columns of table "cart_item" */
export enum Cart_Item_Update_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CartId = 'cart_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProductErpId = 'product_erp_id',
  /** column name */
  Qty = 'qty',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Cart_Item_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Cart_Item_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Cart_Item_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Cart_Item_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Cart_Item_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Cart_Item_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Cart_Item_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cart_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Cart_Item_Bool_Exp
}

/** aggregate var_pop on columns */
export type Cart_Item_Var_Pop_Fields = {
  __typename?: 'cart_item_var_pop_fields'
  cart_id?: Maybe<Scalars['Float']['output']>
  id?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "cart_item" */
export type Cart_Item_Var_Pop_Order_By = {
  cart_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Cart_Item_Var_Samp_Fields = {
  __typename?: 'cart_item_var_samp_fields'
  cart_id?: Maybe<Scalars['Float']['output']>
  id?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "cart_item" */
export type Cart_Item_Var_Samp_Order_By = {
  cart_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Cart_Item_Variance_Fields = {
  __typename?: 'cart_item_variance_fields'
  cart_id?: Maybe<Scalars['Float']['output']>
  id?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "cart_item" */
export type Cart_Item_Variance_Order_By = {
  cart_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** aggregate max on columns */
export type Cart_Max_Fields = {
  __typename?: 'cart_max_fields'
  agent_erp_id?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['bigint']['output']>
  order_id?: Maybe<Scalars['Int']['output']>
  status?: Maybe<Scalars['String']['output']>
  subcustomer_id?: Maybe<Scalars['uuid']['output']>
  type?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** aggregate min on columns */
export type Cart_Min_Fields = {
  __typename?: 'cart_min_fields'
  agent_erp_id?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['bigint']['output']>
  order_id?: Maybe<Scalars['Int']['output']>
  status?: Maybe<Scalars['String']['output']>
  subcustomer_id?: Maybe<Scalars['uuid']['output']>
  type?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** response of any mutation on the table "cart" */
export type Cart_Mutation_Response = {
  __typename?: 'cart_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Cart>
}

/** input type for inserting object relation for remote table "cart" */
export type Cart_Obj_Rel_Insert_Input = {
  data: Cart_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Cart_On_Conflict>
}

/** on_conflict condition type for table "cart" */
export type Cart_On_Conflict = {
  constraint: Cart_Constraint
  update_columns?: Array<Cart_Update_Column>
  where?: InputMaybe<Cart_Bool_Exp>
}

/** Ordering options when selecting data from "cart". */
export type Cart_Order_By = {
  additional_info?: InputMaybe<Order_By>
  agent?: InputMaybe<Agent_Order_By>
  agent_erp_id?: InputMaybe<Order_By>
  cart_items_aggregate?: InputMaybe<Cart_Item_Aggregate_Order_By>
  cart_replacements_aggregate?: InputMaybe<Cart_Replacement_Aggregate_Order_By>
  created_at?: InputMaybe<Order_By>
  customer?: InputMaybe<Customer_Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  order?: InputMaybe<Order_Order_By>
  order_id?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  subcustomer?: InputMaybe<Subcustomer_Order_By>
  subcustomer_id?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: cart */
export type Cart_Pk_Columns_Input = {
  id: Scalars['bigint']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Cart_Prepend_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
}

/** columns and relationships of "cart_replacement" */
export type Cart_Replacement = {
  __typename?: 'cart_replacement'
  additional_info?: Maybe<Scalars['jsonb']['output']>
  /** An object relationship */
  cart: Cart
  cart_id: Scalars['bigint']['output']
  created_at: Scalars['timestamptz']['output']
  id: Scalars['bigint']['output']
  qty: Scalars['Int']['output']
  /** An object relationship */
  replacement?: Maybe<Replacement>
  replacement_erp_id: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "cart_replacement" */
export type Cart_ReplacementAdditional_InfoArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** aggregated selection of "cart_replacement" */
export type Cart_Replacement_Aggregate = {
  __typename?: 'cart_replacement_aggregate'
  aggregate?: Maybe<Cart_Replacement_Aggregate_Fields>
  nodes: Array<Cart_Replacement>
}

export type Cart_Replacement_Aggregate_Bool_Exp = {
  count?: InputMaybe<Cart_Replacement_Aggregate_Bool_Exp_Count>
}

export type Cart_Replacement_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Cart_Replacement_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Cart_Replacement_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "cart_replacement" */
export type Cart_Replacement_Aggregate_Fields = {
  __typename?: 'cart_replacement_aggregate_fields'
  avg?: Maybe<Cart_Replacement_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Cart_Replacement_Max_Fields>
  min?: Maybe<Cart_Replacement_Min_Fields>
  stddev?: Maybe<Cart_Replacement_Stddev_Fields>
  stddev_pop?: Maybe<Cart_Replacement_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Cart_Replacement_Stddev_Samp_Fields>
  sum?: Maybe<Cart_Replacement_Sum_Fields>
  var_pop?: Maybe<Cart_Replacement_Var_Pop_Fields>
  var_samp?: Maybe<Cart_Replacement_Var_Samp_Fields>
  variance?: Maybe<Cart_Replacement_Variance_Fields>
}

/** aggregate fields of "cart_replacement" */
export type Cart_Replacement_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cart_Replacement_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "cart_replacement" */
export type Cart_Replacement_Aggregate_Order_By = {
  avg?: InputMaybe<Cart_Replacement_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Cart_Replacement_Max_Order_By>
  min?: InputMaybe<Cart_Replacement_Min_Order_By>
  stddev?: InputMaybe<Cart_Replacement_Stddev_Order_By>
  stddev_pop?: InputMaybe<Cart_Replacement_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Cart_Replacement_Stddev_Samp_Order_By>
  sum?: InputMaybe<Cart_Replacement_Sum_Order_By>
  var_pop?: InputMaybe<Cart_Replacement_Var_Pop_Order_By>
  var_samp?: InputMaybe<Cart_Replacement_Var_Samp_Order_By>
  variance?: InputMaybe<Cart_Replacement_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Cart_Replacement_Append_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
}

/** input type for inserting array relation for remote table "cart_replacement" */
export type Cart_Replacement_Arr_Rel_Insert_Input = {
  data: Array<Cart_Replacement_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Cart_Replacement_On_Conflict>
}

/** aggregate avg on columns */
export type Cart_Replacement_Avg_Fields = {
  __typename?: 'cart_replacement_avg_fields'
  cart_id?: Maybe<Scalars['Float']['output']>
  id?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "cart_replacement" */
export type Cart_Replacement_Avg_Order_By = {
  cart_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "cart_replacement". All fields are combined with a logical 'AND'. */
export type Cart_Replacement_Bool_Exp = {
  _and?: InputMaybe<Array<Cart_Replacement_Bool_Exp>>
  _not?: InputMaybe<Cart_Replacement_Bool_Exp>
  _or?: InputMaybe<Array<Cart_Replacement_Bool_Exp>>
  additional_info?: InputMaybe<Jsonb_Comparison_Exp>
  cart?: InputMaybe<Cart_Bool_Exp>
  cart_id?: InputMaybe<Bigint_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Bigint_Comparison_Exp>
  qty?: InputMaybe<Int_Comparison_Exp>
  replacement?: InputMaybe<Replacement_Bool_Exp>
  replacement_erp_id?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "cart_replacement" */
export enum Cart_Replacement_Constraint {
  /** unique or primary key constraint on columns "cart_id", "id", "replacement_erp_id" */
  CartReplacementIdCartIdReplacementErpIdKey = 'cart_replacement_id_cart_id_replacement_erp_id_key',
  /** unique or primary key constraint on columns "id" */
  CartReplacementPkey = 'cart_replacement_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Cart_Replacement_Delete_At_Path_Input = {
  additional_info?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Cart_Replacement_Delete_Elem_Input = {
  additional_info?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Cart_Replacement_Delete_Key_Input = {
  additional_info?: InputMaybe<Scalars['String']['input']>
}

/** input type for incrementing numeric columns in table "cart_replacement" */
export type Cart_Replacement_Inc_Input = {
  cart_id?: InputMaybe<Scalars['bigint']['input']>
  id?: InputMaybe<Scalars['bigint']['input']>
  qty?: InputMaybe<Scalars['Int']['input']>
}

/** input type for inserting data into table "cart_replacement" */
export type Cart_Replacement_Insert_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  cart?: InputMaybe<Cart_Obj_Rel_Insert_Input>
  cart_id?: InputMaybe<Scalars['bigint']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['bigint']['input']>
  qty?: InputMaybe<Scalars['Int']['input']>
  replacement?: InputMaybe<Replacement_Obj_Rel_Insert_Input>
  replacement_erp_id?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Cart_Replacement_Max_Fields = {
  __typename?: 'cart_replacement_max_fields'
  cart_id?: Maybe<Scalars['bigint']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['bigint']['output']>
  qty?: Maybe<Scalars['Int']['output']>
  replacement_erp_id?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "cart_replacement" */
export type Cart_Replacement_Max_Order_By = {
  cart_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
  replacement_erp_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Cart_Replacement_Min_Fields = {
  __typename?: 'cart_replacement_min_fields'
  cart_id?: Maybe<Scalars['bigint']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['bigint']['output']>
  qty?: Maybe<Scalars['Int']['output']>
  replacement_erp_id?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "cart_replacement" */
export type Cart_Replacement_Min_Order_By = {
  cart_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
  replacement_erp_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "cart_replacement" */
export type Cart_Replacement_Mutation_Response = {
  __typename?: 'cart_replacement_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Cart_Replacement>
}

/** on_conflict condition type for table "cart_replacement" */
export type Cart_Replacement_On_Conflict = {
  constraint: Cart_Replacement_Constraint
  update_columns?: Array<Cart_Replacement_Update_Column>
  where?: InputMaybe<Cart_Replacement_Bool_Exp>
}

/** Ordering options when selecting data from "cart_replacement". */
export type Cart_Replacement_Order_By = {
  additional_info?: InputMaybe<Order_By>
  cart?: InputMaybe<Cart_Order_By>
  cart_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
  replacement?: InputMaybe<Replacement_Order_By>
  replacement_erp_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: cart_replacement */
export type Cart_Replacement_Pk_Columns_Input = {
  id: Scalars['bigint']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Cart_Replacement_Prepend_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
}

/** select columns of table "cart_replacement" */
export enum Cart_Replacement_Select_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CartId = 'cart_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Qty = 'qty',
  /** column name */
  ReplacementErpId = 'replacement_erp_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "cart_replacement" */
export type Cart_Replacement_Set_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  cart_id?: InputMaybe<Scalars['bigint']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['bigint']['input']>
  qty?: InputMaybe<Scalars['Int']['input']>
  replacement_erp_id?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate stddev on columns */
export type Cart_Replacement_Stddev_Fields = {
  __typename?: 'cart_replacement_stddev_fields'
  cart_id?: Maybe<Scalars['Float']['output']>
  id?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "cart_replacement" */
export type Cart_Replacement_Stddev_Order_By = {
  cart_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Cart_Replacement_Stddev_Pop_Fields = {
  __typename?: 'cart_replacement_stddev_pop_fields'
  cart_id?: Maybe<Scalars['Float']['output']>
  id?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "cart_replacement" */
export type Cart_Replacement_Stddev_Pop_Order_By = {
  cart_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Cart_Replacement_Stddev_Samp_Fields = {
  __typename?: 'cart_replacement_stddev_samp_fields'
  cart_id?: Maybe<Scalars['Float']['output']>
  id?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "cart_replacement" */
export type Cart_Replacement_Stddev_Samp_Order_By = {
  cart_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "cart_replacement" */
export type Cart_Replacement_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cart_Replacement_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Cart_Replacement_Stream_Cursor_Value_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  cart_id?: InputMaybe<Scalars['bigint']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['bigint']['input']>
  qty?: InputMaybe<Scalars['Int']['input']>
  replacement_erp_id?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate sum on columns */
export type Cart_Replacement_Sum_Fields = {
  __typename?: 'cart_replacement_sum_fields'
  cart_id?: Maybe<Scalars['bigint']['output']>
  id?: Maybe<Scalars['bigint']['output']>
  qty?: Maybe<Scalars['Int']['output']>
}

/** order by sum() on columns of table "cart_replacement" */
export type Cart_Replacement_Sum_Order_By = {
  cart_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** update columns of table "cart_replacement" */
export enum Cart_Replacement_Update_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CartId = 'cart_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Qty = 'qty',
  /** column name */
  ReplacementErpId = 'replacement_erp_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Cart_Replacement_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Cart_Replacement_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Cart_Replacement_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Cart_Replacement_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Cart_Replacement_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Cart_Replacement_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Cart_Replacement_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cart_Replacement_Set_Input>
  /** filter the rows which have to be updated */
  where: Cart_Replacement_Bool_Exp
}

/** aggregate var_pop on columns */
export type Cart_Replacement_Var_Pop_Fields = {
  __typename?: 'cart_replacement_var_pop_fields'
  cart_id?: Maybe<Scalars['Float']['output']>
  id?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "cart_replacement" */
export type Cart_Replacement_Var_Pop_Order_By = {
  cart_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Cart_Replacement_Var_Samp_Fields = {
  __typename?: 'cart_replacement_var_samp_fields'
  cart_id?: Maybe<Scalars['Float']['output']>
  id?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "cart_replacement" */
export type Cart_Replacement_Var_Samp_Order_By = {
  cart_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Cart_Replacement_Variance_Fields = {
  __typename?: 'cart_replacement_variance_fields'
  cart_id?: Maybe<Scalars['Float']['output']>
  id?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "cart_replacement" */
export type Cart_Replacement_Variance_Order_By = {
  cart_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** select columns of table "cart" */
export enum Cart_Select_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  AgentErpId = 'agent_erp_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Status = 'status',
  /** column name */
  SubcustomerId = 'subcustomer_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "cart" */
export type Cart_Set_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  agent_erp_id?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['bigint']['input']>
  order_id?: InputMaybe<Scalars['Int']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  subcustomer_id?: InputMaybe<Scalars['uuid']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate stddev on columns */
export type Cart_Stddev_Fields = {
  __typename?: 'cart_stddev_fields'
  id?: Maybe<Scalars['Float']['output']>
  order_id?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_pop on columns */
export type Cart_Stddev_Pop_Fields = {
  __typename?: 'cart_stddev_pop_fields'
  id?: Maybe<Scalars['Float']['output']>
  order_id?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_samp on columns */
export type Cart_Stddev_Samp_Fields = {
  __typename?: 'cart_stddev_samp_fields'
  id?: Maybe<Scalars['Float']['output']>
  order_id?: Maybe<Scalars['Float']['output']>
}

/** Streaming cursor of the table "cart" */
export type Cart_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cart_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Cart_Stream_Cursor_Value_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  agent_erp_id?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['bigint']['input']>
  order_id?: InputMaybe<Scalars['Int']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  subcustomer_id?: InputMaybe<Scalars['uuid']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate sum on columns */
export type Cart_Sum_Fields = {
  __typename?: 'cart_sum_fields'
  id?: Maybe<Scalars['bigint']['output']>
  order_id?: Maybe<Scalars['Int']['output']>
}

/** update columns of table "cart" */
export enum Cart_Update_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  AgentErpId = 'agent_erp_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Status = 'status',
  /** column name */
  SubcustomerId = 'subcustomer_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Cart_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Cart_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Cart_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Cart_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Cart_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Cart_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Cart_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cart_Set_Input>
  /** filter the rows which have to be updated */
  where: Cart_Bool_Exp
}

/** aggregate var_pop on columns */
export type Cart_Var_Pop_Fields = {
  __typename?: 'cart_var_pop_fields'
  id?: Maybe<Scalars['Float']['output']>
  order_id?: Maybe<Scalars['Float']['output']>
}

/** aggregate var_samp on columns */
export type Cart_Var_Samp_Fields = {
  __typename?: 'cart_var_samp_fields'
  id?: Maybe<Scalars['Float']['output']>
  order_id?: Maybe<Scalars['Float']['output']>
}

/** aggregate variance on columns */
export type Cart_Variance_Fields = {
  __typename?: 'cart_variance_fields'
  id?: Maybe<Scalars['Float']['output']>
  order_id?: Maybe<Scalars['Float']['output']>
}

/** columns and relationships of "communication" */
export type Communication = {
  __typename?: 'communication'
  code: Scalars['String']['output']
  /** An object relationship */
  communication_code: Communication_Code
  /** An object relationship */
  communication_external_mail?: Maybe<Communication_External_Mail>
  created_at: Scalars['timestamptz']['output']
  /** An object relationship */
  customer?: Maybe<Customer>
  customer_erp_id: Scalars['String']['output']
  external_mail_id?: Maybe<Scalars['uuid']['output']>
  id: Scalars['uuid']['output']
  is_archived: Scalars['Boolean']['output']
  is_deleted: Scalars['Boolean']['output']
  /** An object relationship */
  mail?: Maybe<Mail>
  mail_id?: Maybe<Scalars['uuid']['output']>
  updated_at: Scalars['timestamptz']['output']
}

/** aggregated selection of "communication" */
export type Communication_Aggregate = {
  __typename?: 'communication_aggregate'
  aggregate?: Maybe<Communication_Aggregate_Fields>
  nodes: Array<Communication>
}

export type Communication_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Communication_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<Communication_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<Communication_Aggregate_Bool_Exp_Count>
}

export type Communication_Aggregate_Bool_Exp_Bool_And = {
  arguments: Communication_Select_Column_Communication_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Communication_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Communication_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Communication_Select_Column_Communication_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Communication_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Communication_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Communication_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Communication_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "communication" */
export type Communication_Aggregate_Fields = {
  __typename?: 'communication_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Communication_Max_Fields>
  min?: Maybe<Communication_Min_Fields>
}

/** aggregate fields of "communication" */
export type Communication_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Communication_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "communication" */
export type Communication_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Communication_Max_Order_By>
  min?: InputMaybe<Communication_Min_Order_By>
}

/** input type for inserting array relation for remote table "communication" */
export type Communication_Arr_Rel_Insert_Input = {
  data: Array<Communication_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Communication_On_Conflict>
}

/** Boolean expression to filter rows from the table "communication". All fields are combined with a logical 'AND'. */
export type Communication_Bool_Exp = {
  _and?: InputMaybe<Array<Communication_Bool_Exp>>
  _not?: InputMaybe<Communication_Bool_Exp>
  _or?: InputMaybe<Array<Communication_Bool_Exp>>
  code?: InputMaybe<String_Comparison_Exp>
  communication_code?: InputMaybe<Communication_Code_Bool_Exp>
  communication_external_mail?: InputMaybe<Communication_External_Mail_Bool_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customer?: InputMaybe<Customer_Bool_Exp>
  customer_erp_id?: InputMaybe<String_Comparison_Exp>
  external_mail_id?: InputMaybe<Uuid_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  is_archived?: InputMaybe<Boolean_Comparison_Exp>
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>
  mail?: InputMaybe<Mail_Bool_Exp>
  mail_id?: InputMaybe<Uuid_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** columns and relationships of "communication_changelog" */
export type Communication_Changelog = {
  __typename?: 'communication_changelog'
  created_at: Scalars['timestamptz']['output']
  id: Scalars['uuid']['output']
  is_processed: Scalars['Boolean']['output']
  new_val?: Maybe<Scalars['jsonb']['output']>
  old_val?: Maybe<Scalars['jsonb']['output']>
  operation: Scalars['String']['output']
  type: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "communication_changelog" */
export type Communication_ChangelogNew_ValArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** columns and relationships of "communication_changelog" */
export type Communication_ChangelogOld_ValArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** aggregated selection of "communication_changelog" */
export type Communication_Changelog_Aggregate = {
  __typename?: 'communication_changelog_aggregate'
  aggregate?: Maybe<Communication_Changelog_Aggregate_Fields>
  nodes: Array<Communication_Changelog>
}

/** aggregate fields of "communication_changelog" */
export type Communication_Changelog_Aggregate_Fields = {
  __typename?: 'communication_changelog_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Communication_Changelog_Max_Fields>
  min?: Maybe<Communication_Changelog_Min_Fields>
}

/** aggregate fields of "communication_changelog" */
export type Communication_Changelog_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Communication_Changelog_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Communication_Changelog_Append_Input = {
  new_val?: InputMaybe<Scalars['jsonb']['input']>
  old_val?: InputMaybe<Scalars['jsonb']['input']>
}

/** Boolean expression to filter rows from the table "communication_changelog". All fields are combined with a logical 'AND'. */
export type Communication_Changelog_Bool_Exp = {
  _and?: InputMaybe<Array<Communication_Changelog_Bool_Exp>>
  _not?: InputMaybe<Communication_Changelog_Bool_Exp>
  _or?: InputMaybe<Array<Communication_Changelog_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  is_processed?: InputMaybe<Boolean_Comparison_Exp>
  new_val?: InputMaybe<Jsonb_Comparison_Exp>
  old_val?: InputMaybe<Jsonb_Comparison_Exp>
  operation?: InputMaybe<String_Comparison_Exp>
  type?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "communication_changelog" */
export enum Communication_Changelog_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommunicationChangelogPkey = 'communication_changelog_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Communication_Changelog_Delete_At_Path_Input = {
  new_val?: InputMaybe<Array<Scalars['String']['input']>>
  old_val?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Communication_Changelog_Delete_Elem_Input = {
  new_val?: InputMaybe<Scalars['Int']['input']>
  old_val?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Communication_Changelog_Delete_Key_Input = {
  new_val?: InputMaybe<Scalars['String']['input']>
  old_val?: InputMaybe<Scalars['String']['input']>
}

/** input type for inserting data into table "communication_changelog" */
export type Communication_Changelog_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  is_processed?: InputMaybe<Scalars['Boolean']['input']>
  new_val?: InputMaybe<Scalars['jsonb']['input']>
  old_val?: InputMaybe<Scalars['jsonb']['input']>
  operation?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Communication_Changelog_Max_Fields = {
  __typename?: 'communication_changelog_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  operation?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** aggregate min on columns */
export type Communication_Changelog_Min_Fields = {
  __typename?: 'communication_changelog_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  operation?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** response of any mutation on the table "communication_changelog" */
export type Communication_Changelog_Mutation_Response = {
  __typename?: 'communication_changelog_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Communication_Changelog>
}

/** on_conflict condition type for table "communication_changelog" */
export type Communication_Changelog_On_Conflict = {
  constraint: Communication_Changelog_Constraint
  update_columns?: Array<Communication_Changelog_Update_Column>
  where?: InputMaybe<Communication_Changelog_Bool_Exp>
}

/** Ordering options when selecting data from "communication_changelog". */
export type Communication_Changelog_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  is_processed?: InputMaybe<Order_By>
  new_val?: InputMaybe<Order_By>
  old_val?: InputMaybe<Order_By>
  operation?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: communication_changelog */
export type Communication_Changelog_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Communication_Changelog_Prepend_Input = {
  new_val?: InputMaybe<Scalars['jsonb']['input']>
  old_val?: InputMaybe<Scalars['jsonb']['input']>
}

/** select columns of table "communication_changelog" */
export enum Communication_Changelog_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsProcessed = 'is_processed',
  /** column name */
  NewVal = 'new_val',
  /** column name */
  OldVal = 'old_val',
  /** column name */
  Operation = 'operation',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "communication_changelog" */
export type Communication_Changelog_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  is_processed?: InputMaybe<Scalars['Boolean']['input']>
  new_val?: InputMaybe<Scalars['jsonb']['input']>
  old_val?: InputMaybe<Scalars['jsonb']['input']>
  operation?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "communication_changelog" */
export type Communication_Changelog_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Communication_Changelog_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Communication_Changelog_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  is_processed?: InputMaybe<Scalars['Boolean']['input']>
  new_val?: InputMaybe<Scalars['jsonb']['input']>
  old_val?: InputMaybe<Scalars['jsonb']['input']>
  operation?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "communication_changelog" */
export enum Communication_Changelog_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsProcessed = 'is_processed',
  /** column name */
  NewVal = 'new_val',
  /** column name */
  OldVal = 'old_val',
  /** column name */
  Operation = 'operation',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Communication_Changelog_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Communication_Changelog_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Communication_Changelog_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Communication_Changelog_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Communication_Changelog_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Communication_Changelog_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Communication_Changelog_Set_Input>
  /** filter the rows which have to be updated */
  where: Communication_Changelog_Bool_Exp
}

/** columns and relationships of "communication_code" */
export type Communication_Code = {
  __typename?: 'communication_code'
  code: Scalars['String']['output']
  /** An array relationship */
  communication_external_mails: Array<Communication_External_Mail>
  /** An aggregate relationship */
  communication_external_mails_aggregate: Communication_External_Mail_Aggregate
  /** An array relationship */
  communication_lists: Array<Communication_List>
  /** An aggregate relationship */
  communication_lists_aggregate: Communication_List_Aggregate
  /** An array relationship */
  communications: Array<Communication>
  /** An aggregate relationship */
  communications_aggregate: Communication_Aggregate
  created_at: Scalars['timestamptz']['output']
  is_enabled: Scalars['Boolean']['output']
  is_external: Scalars['Boolean']['output']
  is_list: Scalars['Boolean']['output']
  provider: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "communication_code" */
export type Communication_CodeCommunication_External_MailsArgs = {
  distinct_on?: InputMaybe<Array<Communication_External_Mail_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_External_Mail_Order_By>>
  where?: InputMaybe<Communication_External_Mail_Bool_Exp>
}

/** columns and relationships of "communication_code" */
export type Communication_CodeCommunication_External_Mails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_External_Mail_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_External_Mail_Order_By>>
  where?: InputMaybe<Communication_External_Mail_Bool_Exp>
}

/** columns and relationships of "communication_code" */
export type Communication_CodeCommunication_ListsArgs = {
  distinct_on?: InputMaybe<Array<Communication_List_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_List_Order_By>>
  where?: InputMaybe<Communication_List_Bool_Exp>
}

/** columns and relationships of "communication_code" */
export type Communication_CodeCommunication_Lists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_List_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_List_Order_By>>
  where?: InputMaybe<Communication_List_Bool_Exp>
}

/** columns and relationships of "communication_code" */
export type Communication_CodeCommunicationsArgs = {
  distinct_on?: InputMaybe<Array<Communication_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_Order_By>>
  where?: InputMaybe<Communication_Bool_Exp>
}

/** columns and relationships of "communication_code" */
export type Communication_CodeCommunications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_Order_By>>
  where?: InputMaybe<Communication_Bool_Exp>
}

/** aggregated selection of "communication_code" */
export type Communication_Code_Aggregate = {
  __typename?: 'communication_code_aggregate'
  aggregate?: Maybe<Communication_Code_Aggregate_Fields>
  nodes: Array<Communication_Code>
}

/** aggregate fields of "communication_code" */
export type Communication_Code_Aggregate_Fields = {
  __typename?: 'communication_code_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Communication_Code_Max_Fields>
  min?: Maybe<Communication_Code_Min_Fields>
}

/** aggregate fields of "communication_code" */
export type Communication_Code_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Communication_Code_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "communication_code". All fields are combined with a logical 'AND'. */
export type Communication_Code_Bool_Exp = {
  _and?: InputMaybe<Array<Communication_Code_Bool_Exp>>
  _not?: InputMaybe<Communication_Code_Bool_Exp>
  _or?: InputMaybe<Array<Communication_Code_Bool_Exp>>
  code?: InputMaybe<String_Comparison_Exp>
  communication_external_mails?: InputMaybe<Communication_External_Mail_Bool_Exp>
  communication_external_mails_aggregate?: InputMaybe<Communication_External_Mail_Aggregate_Bool_Exp>
  communication_lists?: InputMaybe<Communication_List_Bool_Exp>
  communication_lists_aggregate?: InputMaybe<Communication_List_Aggregate_Bool_Exp>
  communications?: InputMaybe<Communication_Bool_Exp>
  communications_aggregate?: InputMaybe<Communication_Aggregate_Bool_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  is_enabled?: InputMaybe<Boolean_Comparison_Exp>
  is_external?: InputMaybe<Boolean_Comparison_Exp>
  is_list?: InputMaybe<Boolean_Comparison_Exp>
  provider?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "communication_code" */
export enum Communication_Code_Constraint {
  /** unique or primary key constraint on columns "code" */
  CommunicationCodePkey = 'communication_code_pkey',
}

/** input type for inserting data into table "communication_code" */
export type Communication_Code_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  communication_external_mails?: InputMaybe<Communication_External_Mail_Arr_Rel_Insert_Input>
  communication_lists?: InputMaybe<Communication_List_Arr_Rel_Insert_Input>
  communications?: InputMaybe<Communication_Arr_Rel_Insert_Input>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  is_enabled?: InputMaybe<Scalars['Boolean']['input']>
  is_external?: InputMaybe<Scalars['Boolean']['input']>
  is_list?: InputMaybe<Scalars['Boolean']['input']>
  provider?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Communication_Code_Max_Fields = {
  __typename?: 'communication_code_max_fields'
  code?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  provider?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** aggregate min on columns */
export type Communication_Code_Min_Fields = {
  __typename?: 'communication_code_min_fields'
  code?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  provider?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** response of any mutation on the table "communication_code" */
export type Communication_Code_Mutation_Response = {
  __typename?: 'communication_code_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Communication_Code>
}

/** input type for inserting object relation for remote table "communication_code" */
export type Communication_Code_Obj_Rel_Insert_Input = {
  data: Communication_Code_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Communication_Code_On_Conflict>
}

/** on_conflict condition type for table "communication_code" */
export type Communication_Code_On_Conflict = {
  constraint: Communication_Code_Constraint
  update_columns?: Array<Communication_Code_Update_Column>
  where?: InputMaybe<Communication_Code_Bool_Exp>
}

/** Ordering options when selecting data from "communication_code". */
export type Communication_Code_Order_By = {
  code?: InputMaybe<Order_By>
  communication_external_mails_aggregate?: InputMaybe<Communication_External_Mail_Aggregate_Order_By>
  communication_lists_aggregate?: InputMaybe<Communication_List_Aggregate_Order_By>
  communications_aggregate?: InputMaybe<Communication_Aggregate_Order_By>
  created_at?: InputMaybe<Order_By>
  is_enabled?: InputMaybe<Order_By>
  is_external?: InputMaybe<Order_By>
  is_list?: InputMaybe<Order_By>
  provider?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: communication_code */
export type Communication_Code_Pk_Columns_Input = {
  code: Scalars['String']['input']
}

/** select columns of table "communication_code" */
export enum Communication_Code_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  IsExternal = 'is_external',
  /** column name */
  IsList = 'is_list',
  /** column name */
  Provider = 'provider',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "communication_code" */
export type Communication_Code_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  is_enabled?: InputMaybe<Scalars['Boolean']['input']>
  is_external?: InputMaybe<Scalars['Boolean']['input']>
  is_list?: InputMaybe<Scalars['Boolean']['input']>
  provider?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "communication_code" */
export type Communication_Code_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Communication_Code_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Communication_Code_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  is_enabled?: InputMaybe<Scalars['Boolean']['input']>
  is_external?: InputMaybe<Scalars['Boolean']['input']>
  is_list?: InputMaybe<Scalars['Boolean']['input']>
  provider?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "communication_code" */
export enum Communication_Code_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  IsExternal = 'is_external',
  /** column name */
  IsList = 'is_list',
  /** column name */
  Provider = 'provider',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Communication_Code_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Communication_Code_Set_Input>
  /** filter the rows which have to be updated */
  where: Communication_Code_Bool_Exp
}

/** unique or primary key constraints on table "communication" */
export enum Communication_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommunicationPkey = 'communication_pkey',
}

/** columns and relationships of "communication_external_mail" */
export type Communication_External_Mail = {
  __typename?: 'communication_external_mail'
  code: Scalars['String']['output']
  /** An object relationship */
  communication_code: Communication_Code
  /** An array relationship */
  communications: Array<Communication>
  /** An aggregate relationship */
  communications_aggregate: Communication_Aggregate
  created_at: Scalars['timestamptz']['output']
  id: Scalars['uuid']['output']
  identifier: Scalars['String']['output']
  is_processed: Scalars['Boolean']['output']
  search_criteria?: Maybe<Scalars['jsonb']['output']>
  title?: Maybe<Scalars['String']['output']>
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "communication_external_mail" */
export type Communication_External_MailCommunicationsArgs = {
  distinct_on?: InputMaybe<Array<Communication_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_Order_By>>
  where?: InputMaybe<Communication_Bool_Exp>
}

/** columns and relationships of "communication_external_mail" */
export type Communication_External_MailCommunications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_Order_By>>
  where?: InputMaybe<Communication_Bool_Exp>
}

/** columns and relationships of "communication_external_mail" */
export type Communication_External_MailSearch_CriteriaArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** aggregated selection of "communication_external_mail" */
export type Communication_External_Mail_Aggregate = {
  __typename?: 'communication_external_mail_aggregate'
  aggregate?: Maybe<Communication_External_Mail_Aggregate_Fields>
  nodes: Array<Communication_External_Mail>
}

export type Communication_External_Mail_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Communication_External_Mail_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<Communication_External_Mail_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<Communication_External_Mail_Aggregate_Bool_Exp_Count>
}

export type Communication_External_Mail_Aggregate_Bool_Exp_Bool_And = {
  arguments: Communication_External_Mail_Select_Column_Communication_External_Mail_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Communication_External_Mail_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Communication_External_Mail_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Communication_External_Mail_Select_Column_Communication_External_Mail_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Communication_External_Mail_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Communication_External_Mail_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Communication_External_Mail_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Communication_External_Mail_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "communication_external_mail" */
export type Communication_External_Mail_Aggregate_Fields = {
  __typename?: 'communication_external_mail_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Communication_External_Mail_Max_Fields>
  min?: Maybe<Communication_External_Mail_Min_Fields>
}

/** aggregate fields of "communication_external_mail" */
export type Communication_External_Mail_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Communication_External_Mail_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "communication_external_mail" */
export type Communication_External_Mail_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Communication_External_Mail_Max_Order_By>
  min?: InputMaybe<Communication_External_Mail_Min_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Communication_External_Mail_Append_Input = {
  search_criteria?: InputMaybe<Scalars['jsonb']['input']>
}

/** input type for inserting array relation for remote table "communication_external_mail" */
export type Communication_External_Mail_Arr_Rel_Insert_Input = {
  data: Array<Communication_External_Mail_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Communication_External_Mail_On_Conflict>
}

/** Boolean expression to filter rows from the table "communication_external_mail". All fields are combined with a logical 'AND'. */
export type Communication_External_Mail_Bool_Exp = {
  _and?: InputMaybe<Array<Communication_External_Mail_Bool_Exp>>
  _not?: InputMaybe<Communication_External_Mail_Bool_Exp>
  _or?: InputMaybe<Array<Communication_External_Mail_Bool_Exp>>
  code?: InputMaybe<String_Comparison_Exp>
  communication_code?: InputMaybe<Communication_Code_Bool_Exp>
  communications?: InputMaybe<Communication_Bool_Exp>
  communications_aggregate?: InputMaybe<Communication_Aggregate_Bool_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  identifier?: InputMaybe<String_Comparison_Exp>
  is_processed?: InputMaybe<Boolean_Comparison_Exp>
  search_criteria?: InputMaybe<Jsonb_Comparison_Exp>
  title?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "communication_external_mail" */
export enum Communication_External_Mail_Constraint {
  /** unique or primary key constraint on columns "code", "identifier" */
  CommunicationExternalMailCodeIdentifierKey = 'communication_external_mail_code_identifier_key',
  /** unique or primary key constraint on columns "id" */
  CommunicationExternalMailPkey = 'communication_external_mail_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Communication_External_Mail_Delete_At_Path_Input = {
  search_criteria?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Communication_External_Mail_Delete_Elem_Input = {
  search_criteria?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Communication_External_Mail_Delete_Key_Input = {
  search_criteria?: InputMaybe<Scalars['String']['input']>
}

/** input type for inserting data into table "communication_external_mail" */
export type Communication_External_Mail_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  communication_code?: InputMaybe<Communication_Code_Obj_Rel_Insert_Input>
  communications?: InputMaybe<Communication_Arr_Rel_Insert_Input>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  identifier?: InputMaybe<Scalars['String']['input']>
  is_processed?: InputMaybe<Scalars['Boolean']['input']>
  search_criteria?: InputMaybe<Scalars['jsonb']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Communication_External_Mail_Max_Fields = {
  __typename?: 'communication_external_mail_max_fields'
  code?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  identifier?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "communication_external_mail" */
export type Communication_External_Mail_Max_Order_By = {
  code?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  identifier?: InputMaybe<Order_By>
  title?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Communication_External_Mail_Min_Fields = {
  __typename?: 'communication_external_mail_min_fields'
  code?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  identifier?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "communication_external_mail" */
export type Communication_External_Mail_Min_Order_By = {
  code?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  identifier?: InputMaybe<Order_By>
  title?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "communication_external_mail" */
export type Communication_External_Mail_Mutation_Response = {
  __typename?: 'communication_external_mail_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Communication_External_Mail>
}

/** input type for inserting object relation for remote table "communication_external_mail" */
export type Communication_External_Mail_Obj_Rel_Insert_Input = {
  data: Communication_External_Mail_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Communication_External_Mail_On_Conflict>
}

/** on_conflict condition type for table "communication_external_mail" */
export type Communication_External_Mail_On_Conflict = {
  constraint: Communication_External_Mail_Constraint
  update_columns?: Array<Communication_External_Mail_Update_Column>
  where?: InputMaybe<Communication_External_Mail_Bool_Exp>
}

/** Ordering options when selecting data from "communication_external_mail". */
export type Communication_External_Mail_Order_By = {
  code?: InputMaybe<Order_By>
  communication_code?: InputMaybe<Communication_Code_Order_By>
  communications_aggregate?: InputMaybe<Communication_Aggregate_Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  identifier?: InputMaybe<Order_By>
  is_processed?: InputMaybe<Order_By>
  search_criteria?: InputMaybe<Order_By>
  title?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: communication_external_mail */
export type Communication_External_Mail_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Communication_External_Mail_Prepend_Input = {
  search_criteria?: InputMaybe<Scalars['jsonb']['input']>
}

/** select columns of table "communication_external_mail" */
export enum Communication_External_Mail_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  IsProcessed = 'is_processed',
  /** column name */
  SearchCriteria = 'search_criteria',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "communication_external_mail_aggregate_bool_exp_bool_and_arguments_columns" columns of table "communication_external_mail" */
export enum Communication_External_Mail_Select_Column_Communication_External_Mail_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsProcessed = 'is_processed',
}

/** select "communication_external_mail_aggregate_bool_exp_bool_or_arguments_columns" columns of table "communication_external_mail" */
export enum Communication_External_Mail_Select_Column_Communication_External_Mail_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsProcessed = 'is_processed',
}

/** input type for updating data in table "communication_external_mail" */
export type Communication_External_Mail_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  identifier?: InputMaybe<Scalars['String']['input']>
  is_processed?: InputMaybe<Scalars['Boolean']['input']>
  search_criteria?: InputMaybe<Scalars['jsonb']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "communication_external_mail" */
export type Communication_External_Mail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Communication_External_Mail_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Communication_External_Mail_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  identifier?: InputMaybe<Scalars['String']['input']>
  is_processed?: InputMaybe<Scalars['Boolean']['input']>
  search_criteria?: InputMaybe<Scalars['jsonb']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "communication_external_mail" */
export enum Communication_External_Mail_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  IsProcessed = 'is_processed',
  /** column name */
  SearchCriteria = 'search_criteria',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Communication_External_Mail_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Communication_External_Mail_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Communication_External_Mail_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Communication_External_Mail_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Communication_External_Mail_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Communication_External_Mail_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Communication_External_Mail_Set_Input>
  /** filter the rows which have to be updated */
  where: Communication_External_Mail_Bool_Exp
}

/** input type for inserting data into table "communication" */
export type Communication_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  communication_code?: InputMaybe<Communication_Code_Obj_Rel_Insert_Input>
  communication_external_mail?: InputMaybe<Communication_External_Mail_Obj_Rel_Insert_Input>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  external_mail_id?: InputMaybe<Scalars['uuid']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  is_archived?: InputMaybe<Scalars['Boolean']['input']>
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>
  mail?: InputMaybe<Mail_Obj_Rel_Insert_Input>
  mail_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** columns and relationships of "communication_list" */
export type Communication_List = {
  __typename?: 'communication_list'
  code: Scalars['String']['output']
  /** An object relationship */
  communication_code: Communication_Code
  created_at: Scalars['timestamptz']['output']
  /** An object relationship */
  customer?: Maybe<Customer>
  customer_erp_id: Scalars['String']['output']
  id: Scalars['uuid']['output']
  is_enabled_email: Scalars['Boolean']['output']
  is_enabled_site: Scalars['Boolean']['output']
  send_to_email: Scalars['jsonb']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "communication_list" */
export type Communication_ListSend_To_EmailArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** aggregated selection of "communication_list" */
export type Communication_List_Aggregate = {
  __typename?: 'communication_list_aggregate'
  aggregate?: Maybe<Communication_List_Aggregate_Fields>
  nodes: Array<Communication_List>
}

export type Communication_List_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Communication_List_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<Communication_List_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<Communication_List_Aggregate_Bool_Exp_Count>
}

export type Communication_List_Aggregate_Bool_Exp_Bool_And = {
  arguments: Communication_List_Select_Column_Communication_List_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Communication_List_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Communication_List_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Communication_List_Select_Column_Communication_List_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Communication_List_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Communication_List_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Communication_List_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Communication_List_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "communication_list" */
export type Communication_List_Aggregate_Fields = {
  __typename?: 'communication_list_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Communication_List_Max_Fields>
  min?: Maybe<Communication_List_Min_Fields>
}

/** aggregate fields of "communication_list" */
export type Communication_List_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Communication_List_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "communication_list" */
export type Communication_List_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Communication_List_Max_Order_By>
  min?: InputMaybe<Communication_List_Min_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Communication_List_Append_Input = {
  send_to_email?: InputMaybe<Scalars['jsonb']['input']>
}

/** input type for inserting array relation for remote table "communication_list" */
export type Communication_List_Arr_Rel_Insert_Input = {
  data: Array<Communication_List_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Communication_List_On_Conflict>
}

/** Boolean expression to filter rows from the table "communication_list". All fields are combined with a logical 'AND'. */
export type Communication_List_Bool_Exp = {
  _and?: InputMaybe<Array<Communication_List_Bool_Exp>>
  _not?: InputMaybe<Communication_List_Bool_Exp>
  _or?: InputMaybe<Array<Communication_List_Bool_Exp>>
  code?: InputMaybe<String_Comparison_Exp>
  communication_code?: InputMaybe<Communication_Code_Bool_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customer?: InputMaybe<Customer_Bool_Exp>
  customer_erp_id?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  is_enabled_email?: InputMaybe<Boolean_Comparison_Exp>
  is_enabled_site?: InputMaybe<Boolean_Comparison_Exp>
  send_to_email?: InputMaybe<Jsonb_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "communication_list" */
export enum Communication_List_Constraint {
  /** unique or primary key constraint on columns "customer_erp_id", "code" */
  CommunicationListCustomerErpIdCodeKey = 'communication_list_customer_erp_id_code_key',
  /** unique or primary key constraint on columns "id" */
  CommunicationListPkey = 'communication_list_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Communication_List_Delete_At_Path_Input = {
  send_to_email?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Communication_List_Delete_Elem_Input = {
  send_to_email?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Communication_List_Delete_Key_Input = {
  send_to_email?: InputMaybe<Scalars['String']['input']>
}

/** input type for inserting data into table "communication_list" */
export type Communication_List_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  communication_code?: InputMaybe<Communication_Code_Obj_Rel_Insert_Input>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  is_enabled_email?: InputMaybe<Scalars['Boolean']['input']>
  is_enabled_site?: InputMaybe<Scalars['Boolean']['input']>
  send_to_email?: InputMaybe<Scalars['jsonb']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Communication_List_Max_Fields = {
  __typename?: 'communication_list_max_fields'
  code?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "communication_list" */
export type Communication_List_Max_Order_By = {
  code?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Communication_List_Min_Fields = {
  __typename?: 'communication_list_min_fields'
  code?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "communication_list" */
export type Communication_List_Min_Order_By = {
  code?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

export type Communication_List_Missing_Customers_By_Code_Args = {
  _code?: InputMaybe<Scalars['String']['input']>
}

/** response of any mutation on the table "communication_list" */
export type Communication_List_Mutation_Response = {
  __typename?: 'communication_list_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Communication_List>
}

/** on_conflict condition type for table "communication_list" */
export type Communication_List_On_Conflict = {
  constraint: Communication_List_Constraint
  update_columns?: Array<Communication_List_Update_Column>
  where?: InputMaybe<Communication_List_Bool_Exp>
}

/** Ordering options when selecting data from "communication_list". */
export type Communication_List_Order_By = {
  code?: InputMaybe<Order_By>
  communication_code?: InputMaybe<Communication_Code_Order_By>
  created_at?: InputMaybe<Order_By>
  customer?: InputMaybe<Customer_Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  is_enabled_email?: InputMaybe<Order_By>
  is_enabled_site?: InputMaybe<Order_By>
  send_to_email?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: communication_list */
export type Communication_List_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Communication_List_Prepend_Input = {
  send_to_email?: InputMaybe<Scalars['jsonb']['input']>
}

/** select columns of table "communication_list" */
export enum Communication_List_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabledEmail = 'is_enabled_email',
  /** column name */
  IsEnabledSite = 'is_enabled_site',
  /** column name */
  SendToEmail = 'send_to_email',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "communication_list_aggregate_bool_exp_bool_and_arguments_columns" columns of table "communication_list" */
export enum Communication_List_Select_Column_Communication_List_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsEnabledEmail = 'is_enabled_email',
  /** column name */
  IsEnabledSite = 'is_enabled_site',
}

/** select "communication_list_aggregate_bool_exp_bool_or_arguments_columns" columns of table "communication_list" */
export enum Communication_List_Select_Column_Communication_List_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsEnabledEmail = 'is_enabled_email',
  /** column name */
  IsEnabledSite = 'is_enabled_site',
}

/** input type for updating data in table "communication_list" */
export type Communication_List_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  is_enabled_email?: InputMaybe<Scalars['Boolean']['input']>
  is_enabled_site?: InputMaybe<Scalars['Boolean']['input']>
  send_to_email?: InputMaybe<Scalars['jsonb']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "communication_list" */
export type Communication_List_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Communication_List_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Communication_List_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  is_enabled_email?: InputMaybe<Scalars['Boolean']['input']>
  is_enabled_site?: InputMaybe<Scalars['Boolean']['input']>
  send_to_email?: InputMaybe<Scalars['jsonb']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "communication_list" */
export enum Communication_List_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabledEmail = 'is_enabled_email',
  /** column name */
  IsEnabledSite = 'is_enabled_site',
  /** column name */
  SendToEmail = 'send_to_email',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Communication_List_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Communication_List_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Communication_List_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Communication_List_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Communication_List_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Communication_List_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Communication_List_Set_Input>
  /** filter the rows which have to be updated */
  where: Communication_List_Bool_Exp
}

/** aggregate max on columns */
export type Communication_Max_Fields = {
  __typename?: 'communication_max_fields'
  code?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  external_mail_id?: Maybe<Scalars['uuid']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  mail_id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "communication" */
export type Communication_Max_Order_By = {
  code?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  external_mail_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  mail_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Communication_Min_Fields = {
  __typename?: 'communication_min_fields'
  code?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  external_mail_id?: Maybe<Scalars['uuid']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  mail_id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "communication" */
export type Communication_Min_Order_By = {
  code?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  external_mail_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  mail_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "communication" */
export type Communication_Mutation_Response = {
  __typename?: 'communication_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Communication>
}

/** on_conflict condition type for table "communication" */
export type Communication_On_Conflict = {
  constraint: Communication_Constraint
  update_columns?: Array<Communication_Update_Column>
  where?: InputMaybe<Communication_Bool_Exp>
}

/** Ordering options when selecting data from "communication". */
export type Communication_Order_By = {
  code?: InputMaybe<Order_By>
  communication_code?: InputMaybe<Communication_Code_Order_By>
  communication_external_mail?: InputMaybe<Communication_External_Mail_Order_By>
  created_at?: InputMaybe<Order_By>
  customer?: InputMaybe<Customer_Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  external_mail_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  is_archived?: InputMaybe<Order_By>
  is_deleted?: InputMaybe<Order_By>
  mail?: InputMaybe<Mail_Order_By>
  mail_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: communication */
export type Communication_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "communication" */
export enum Communication_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  ExternalMailId = 'external_mail_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  MailId = 'mail_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "communication_aggregate_bool_exp_bool_and_arguments_columns" columns of table "communication" */
export enum Communication_Select_Column_Communication_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  IsDeleted = 'is_deleted',
}

/** select "communication_aggregate_bool_exp_bool_or_arguments_columns" columns of table "communication" */
export enum Communication_Select_Column_Communication_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  IsDeleted = 'is_deleted',
}

/** input type for updating data in table "communication" */
export type Communication_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  external_mail_id?: InputMaybe<Scalars['uuid']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  is_archived?: InputMaybe<Scalars['Boolean']['input']>
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>
  mail_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** columns and relationships of "communication_settings" */
export type Communication_Settings = {
  __typename?: 'communication_settings'
  created_at: Scalars['timestamptz']['output']
  /** An object relationship */
  customer?: Maybe<Customer>
  customer_erp_id: Scalars['String']['output']
  id: Scalars['uuid']['output']
  language: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** aggregated selection of "communication_settings" */
export type Communication_Settings_Aggregate = {
  __typename?: 'communication_settings_aggregate'
  aggregate?: Maybe<Communication_Settings_Aggregate_Fields>
  nodes: Array<Communication_Settings>
}

/** aggregate fields of "communication_settings" */
export type Communication_Settings_Aggregate_Fields = {
  __typename?: 'communication_settings_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Communication_Settings_Max_Fields>
  min?: Maybe<Communication_Settings_Min_Fields>
}

/** aggregate fields of "communication_settings" */
export type Communication_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Communication_Settings_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "communication_settings". All fields are combined with a logical 'AND'. */
export type Communication_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Communication_Settings_Bool_Exp>>
  _not?: InputMaybe<Communication_Settings_Bool_Exp>
  _or?: InputMaybe<Array<Communication_Settings_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customer?: InputMaybe<Customer_Bool_Exp>
  customer_erp_id?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  language?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "communication_settings" */
export enum Communication_Settings_Constraint {
  /** unique or primary key constraint on columns "customer_erp_id" */
  CommunicationSettingsCustomerErpIdKey = 'communication_settings_customer_erp_id_key',
  /** unique or primary key constraint on columns "id" */
  CommunicationSettingsPkey = 'communication_settings_pkey',
}

/** input type for inserting data into table "communication_settings" */
export type Communication_Settings_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  language?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Communication_Settings_Max_Fields = {
  __typename?: 'communication_settings_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  language?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** aggregate min on columns */
export type Communication_Settings_Min_Fields = {
  __typename?: 'communication_settings_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  language?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** response of any mutation on the table "communication_settings" */
export type Communication_Settings_Mutation_Response = {
  __typename?: 'communication_settings_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Communication_Settings>
}

/** on_conflict condition type for table "communication_settings" */
export type Communication_Settings_On_Conflict = {
  constraint: Communication_Settings_Constraint
  update_columns?: Array<Communication_Settings_Update_Column>
  where?: InputMaybe<Communication_Settings_Bool_Exp>
}

/** Ordering options when selecting data from "communication_settings". */
export type Communication_Settings_Order_By = {
  created_at?: InputMaybe<Order_By>
  customer?: InputMaybe<Customer_Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  language?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: communication_settings */
export type Communication_Settings_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "communication_settings" */
export enum Communication_Settings_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "communication_settings" */
export type Communication_Settings_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  language?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "communication_settings" */
export type Communication_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Communication_Settings_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Communication_Settings_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  language?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "communication_settings" */
export enum Communication_Settings_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Communication_Settings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Communication_Settings_Set_Input>
  /** filter the rows which have to be updated */
  where: Communication_Settings_Bool_Exp
}

/** Streaming cursor of the table "communication" */
export type Communication_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Communication_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Communication_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  external_mail_id?: InputMaybe<Scalars['uuid']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  is_archived?: InputMaybe<Scalars['Boolean']['input']>
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>
  mail_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "communication" */
export enum Communication_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  ExternalMailId = 'external_mail_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  MailId = 'mail_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Communication_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Communication_Set_Input>
  /** filter the rows which have to be updated */
  where: Communication_Bool_Exp
}

/** columns and relationships of "country_region" */
export type Country_Region = {
  __typename?: 'country_region'
  code: Scalars['String']['output']
  created_at: Scalars['timestamptz']['output']
  description: Scalars['String']['output']
  id: Scalars['uuid']['output']
  /** An array relationship */
  post_codes: Array<Post_Code>
  /** An aggregate relationship */
  post_codes_aggregate: Post_Code_Aggregate
  rev: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "country_region" */
export type Country_RegionPost_CodesArgs = {
  distinct_on?: InputMaybe<Array<Post_Code_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Post_Code_Order_By>>
  where?: InputMaybe<Post_Code_Bool_Exp>
}

/** columns and relationships of "country_region" */
export type Country_RegionPost_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Code_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Post_Code_Order_By>>
  where?: InputMaybe<Post_Code_Bool_Exp>
}

/** aggregated selection of "country_region" */
export type Country_Region_Aggregate = {
  __typename?: 'country_region_aggregate'
  aggregate?: Maybe<Country_Region_Aggregate_Fields>
  nodes: Array<Country_Region>
}

/** aggregate fields of "country_region" */
export type Country_Region_Aggregate_Fields = {
  __typename?: 'country_region_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Country_Region_Max_Fields>
  min?: Maybe<Country_Region_Min_Fields>
}

/** aggregate fields of "country_region" */
export type Country_Region_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Country_Region_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "country_region". All fields are combined with a logical 'AND'. */
export type Country_Region_Bool_Exp = {
  _and?: InputMaybe<Array<Country_Region_Bool_Exp>>
  _not?: InputMaybe<Country_Region_Bool_Exp>
  _or?: InputMaybe<Array<Country_Region_Bool_Exp>>
  code?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  post_codes?: InputMaybe<Post_Code_Bool_Exp>
  post_codes_aggregate?: InputMaybe<Post_Code_Aggregate_Bool_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "country_region" */
export enum Country_Region_Constraint {
  /** unique or primary key constraint on columns "code" */
  CountryRegionCodeKey = 'country_region_code_key',
  /** unique or primary key constraint on columns "id" */
  CountryRegionPkey = 'country_region_pkey',
}

/** input type for inserting data into table "country_region" */
export type Country_Region_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  post_codes?: InputMaybe<Post_Code_Arr_Rel_Insert_Input>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Country_Region_Max_Fields = {
  __typename?: 'country_region_max_fields'
  code?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** aggregate min on columns */
export type Country_Region_Min_Fields = {
  __typename?: 'country_region_min_fields'
  code?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** response of any mutation on the table "country_region" */
export type Country_Region_Mutation_Response = {
  __typename?: 'country_region_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Country_Region>
}

/** input type for inserting object relation for remote table "country_region" */
export type Country_Region_Obj_Rel_Insert_Input = {
  data: Country_Region_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Country_Region_On_Conflict>
}

/** on_conflict condition type for table "country_region" */
export type Country_Region_On_Conflict = {
  constraint: Country_Region_Constraint
  update_columns?: Array<Country_Region_Update_Column>
  where?: InputMaybe<Country_Region_Bool_Exp>
}

/** Ordering options when selecting data from "country_region". */
export type Country_Region_Order_By = {
  code?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  post_codes_aggregate?: InputMaybe<Post_Code_Aggregate_Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: country_region */
export type Country_Region_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "country_region" */
export enum Country_Region_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "country_region" */
export type Country_Region_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "country_region" */
export type Country_Region_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Country_Region_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Country_Region_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "country_region" */
export enum Country_Region_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Country_Region_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Country_Region_Set_Input>
  /** filter the rows which have to be updated */
  where: Country_Region_Bool_Exp
}

/** columns and relationships of "county" */
export type County = {
  __typename?: 'county'
  code: Scalars['String']['output']
  created_at: Scalars['timestamptz']['output']
  description: Scalars['String']['output']
  id: Scalars['uuid']['output']
  /** An array relationship */
  post_codes: Array<Post_Code>
  /** An aggregate relationship */
  post_codes_aggregate: Post_Code_Aggregate
  rev: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "county" */
export type CountyPost_CodesArgs = {
  distinct_on?: InputMaybe<Array<Post_Code_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Post_Code_Order_By>>
  where?: InputMaybe<Post_Code_Bool_Exp>
}

/** columns and relationships of "county" */
export type CountyPost_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Code_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Post_Code_Order_By>>
  where?: InputMaybe<Post_Code_Bool_Exp>
}

/** aggregated selection of "county" */
export type County_Aggregate = {
  __typename?: 'county_aggregate'
  aggregate?: Maybe<County_Aggregate_Fields>
  nodes: Array<County>
}

/** aggregate fields of "county" */
export type County_Aggregate_Fields = {
  __typename?: 'county_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<County_Max_Fields>
  min?: Maybe<County_Min_Fields>
}

/** aggregate fields of "county" */
export type County_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<County_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "county". All fields are combined with a logical 'AND'. */
export type County_Bool_Exp = {
  _and?: InputMaybe<Array<County_Bool_Exp>>
  _not?: InputMaybe<County_Bool_Exp>
  _or?: InputMaybe<Array<County_Bool_Exp>>
  code?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  post_codes?: InputMaybe<Post_Code_Bool_Exp>
  post_codes_aggregate?: InputMaybe<Post_Code_Aggregate_Bool_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "county" */
export enum County_Constraint {
  /** unique or primary key constraint on columns "code" */
  CountyCodeKey = 'county_code_key',
  /** unique or primary key constraint on columns "id" */
  CountyPkey = 'county_pkey',
}

/** input type for inserting data into table "county" */
export type County_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  post_codes?: InputMaybe<Post_Code_Arr_Rel_Insert_Input>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type County_Max_Fields = {
  __typename?: 'county_max_fields'
  code?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** aggregate min on columns */
export type County_Min_Fields = {
  __typename?: 'county_min_fields'
  code?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** response of any mutation on the table "county" */
export type County_Mutation_Response = {
  __typename?: 'county_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<County>
}

/** input type for inserting object relation for remote table "county" */
export type County_Obj_Rel_Insert_Input = {
  data: County_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<County_On_Conflict>
}

/** on_conflict condition type for table "county" */
export type County_On_Conflict = {
  constraint: County_Constraint
  update_columns?: Array<County_Update_Column>
  where?: InputMaybe<County_Bool_Exp>
}

/** Ordering options when selecting data from "county". */
export type County_Order_By = {
  code?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  post_codes_aggregate?: InputMaybe<Post_Code_Aggregate_Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: county */
export type County_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "county" */
export enum County_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "county" */
export type County_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "county" */
export type County_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: County_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type County_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "county" */
export enum County_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type County_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<County_Set_Input>
  /** filter the rows which have to be updated */
  where: County_Bool_Exp
}

/** columns and relationships of "credit_memo" */
export type Credit_Memo = {
  __typename?: 'credit_memo'
  amount: Scalars['numeric']['output']
  created_at: Scalars['timestamptz']['output']
  /** An object relationship */
  customer: Customer
  customer_erp_id: Scalars['String']['output']
  date: Scalars['date']['output']
  erp_id: Scalars['String']['output']
  id: Scalars['uuid']['output']
  pdf_created_at?: Maybe<Scalars['timestamptz']['output']>
  pdf_filename?: Maybe<Scalars['String']['output']>
  rev: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** aggregated selection of "credit_memo" */
export type Credit_Memo_Aggregate = {
  __typename?: 'credit_memo_aggregate'
  aggregate?: Maybe<Credit_Memo_Aggregate_Fields>
  nodes: Array<Credit_Memo>
}

export type Credit_Memo_Aggregate_Bool_Exp = {
  count?: InputMaybe<Credit_Memo_Aggregate_Bool_Exp_Count>
}

export type Credit_Memo_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Credit_Memo_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Credit_Memo_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "credit_memo" */
export type Credit_Memo_Aggregate_Fields = {
  __typename?: 'credit_memo_aggregate_fields'
  avg?: Maybe<Credit_Memo_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Credit_Memo_Max_Fields>
  min?: Maybe<Credit_Memo_Min_Fields>
  stddev?: Maybe<Credit_Memo_Stddev_Fields>
  stddev_pop?: Maybe<Credit_Memo_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Credit_Memo_Stddev_Samp_Fields>
  sum?: Maybe<Credit_Memo_Sum_Fields>
  var_pop?: Maybe<Credit_Memo_Var_Pop_Fields>
  var_samp?: Maybe<Credit_Memo_Var_Samp_Fields>
  variance?: Maybe<Credit_Memo_Variance_Fields>
}

/** aggregate fields of "credit_memo" */
export type Credit_Memo_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Credit_Memo_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "credit_memo" */
export type Credit_Memo_Aggregate_Order_By = {
  avg?: InputMaybe<Credit_Memo_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Credit_Memo_Max_Order_By>
  min?: InputMaybe<Credit_Memo_Min_Order_By>
  stddev?: InputMaybe<Credit_Memo_Stddev_Order_By>
  stddev_pop?: InputMaybe<Credit_Memo_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Credit_Memo_Stddev_Samp_Order_By>
  sum?: InputMaybe<Credit_Memo_Sum_Order_By>
  var_pop?: InputMaybe<Credit_Memo_Var_Pop_Order_By>
  var_samp?: InputMaybe<Credit_Memo_Var_Samp_Order_By>
  variance?: InputMaybe<Credit_Memo_Variance_Order_By>
}

/** input type for inserting array relation for remote table "credit_memo" */
export type Credit_Memo_Arr_Rel_Insert_Input = {
  data: Array<Credit_Memo_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Credit_Memo_On_Conflict>
}

/** aggregate avg on columns */
export type Credit_Memo_Avg_Fields = {
  __typename?: 'credit_memo_avg_fields'
  amount?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "credit_memo" */
export type Credit_Memo_Avg_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "credit_memo". All fields are combined with a logical 'AND'. */
export type Credit_Memo_Bool_Exp = {
  _and?: InputMaybe<Array<Credit_Memo_Bool_Exp>>
  _not?: InputMaybe<Credit_Memo_Bool_Exp>
  _or?: InputMaybe<Array<Credit_Memo_Bool_Exp>>
  amount?: InputMaybe<Numeric_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customer?: InputMaybe<Customer_Bool_Exp>
  customer_erp_id?: InputMaybe<String_Comparison_Exp>
  date?: InputMaybe<Date_Comparison_Exp>
  erp_id?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  pdf_created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  pdf_filename?: InputMaybe<String_Comparison_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "credit_memo" */
export enum Credit_Memo_Constraint {
  /** unique or primary key constraint on columns "erp_id" */
  CreditMemoErpIdKey = 'credit_memo_erp_id_key',
  /** unique or primary key constraint on columns "id" */
  CreditMemoPkey = 'credit_memo_pkey',
}

/** input type for incrementing numeric columns in table "credit_memo" */
export type Credit_Memo_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>
}

/** input type for inserting data into table "credit_memo" */
export type Credit_Memo_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  date?: InputMaybe<Scalars['date']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  pdf_created_at?: InputMaybe<Scalars['timestamptz']['input']>
  pdf_filename?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Credit_Memo_Max_Fields = {
  __typename?: 'credit_memo_max_fields'
  amount?: Maybe<Scalars['numeric']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  date?: Maybe<Scalars['date']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  pdf_created_at?: Maybe<Scalars['timestamptz']['output']>
  pdf_filename?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "credit_memo" */
export type Credit_Memo_Max_Order_By = {
  amount?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  date?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  pdf_created_at?: InputMaybe<Order_By>
  pdf_filename?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Credit_Memo_Min_Fields = {
  __typename?: 'credit_memo_min_fields'
  amount?: Maybe<Scalars['numeric']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  date?: Maybe<Scalars['date']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  pdf_created_at?: Maybe<Scalars['timestamptz']['output']>
  pdf_filename?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "credit_memo" */
export type Credit_Memo_Min_Order_By = {
  amount?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  date?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  pdf_created_at?: InputMaybe<Order_By>
  pdf_filename?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "credit_memo" */
export type Credit_Memo_Mutation_Response = {
  __typename?: 'credit_memo_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Credit_Memo>
}

/** on_conflict condition type for table "credit_memo" */
export type Credit_Memo_On_Conflict = {
  constraint: Credit_Memo_Constraint
  update_columns?: Array<Credit_Memo_Update_Column>
  where?: InputMaybe<Credit_Memo_Bool_Exp>
}

/** Ordering options when selecting data from "credit_memo". */
export type Credit_Memo_Order_By = {
  amount?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customer?: InputMaybe<Customer_Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  date?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  pdf_created_at?: InputMaybe<Order_By>
  pdf_filename?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: credit_memo */
export type Credit_Memo_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "credit_memo" */
export enum Credit_Memo_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Date = 'date',
  /** column name */
  ErpId = 'erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  PdfCreatedAt = 'pdf_created_at',
  /** column name */
  PdfFilename = 'pdf_filename',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "credit_memo" */
export type Credit_Memo_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  date?: InputMaybe<Scalars['date']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  pdf_created_at?: InputMaybe<Scalars['timestamptz']['input']>
  pdf_filename?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate stddev on columns */
export type Credit_Memo_Stddev_Fields = {
  __typename?: 'credit_memo_stddev_fields'
  amount?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "credit_memo" */
export type Credit_Memo_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Credit_Memo_Stddev_Pop_Fields = {
  __typename?: 'credit_memo_stddev_pop_fields'
  amount?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "credit_memo" */
export type Credit_Memo_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Credit_Memo_Stddev_Samp_Fields = {
  __typename?: 'credit_memo_stddev_samp_fields'
  amount?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "credit_memo" */
export type Credit_Memo_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "credit_memo" */
export type Credit_Memo_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Credit_Memo_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Credit_Memo_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  date?: InputMaybe<Scalars['date']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  pdf_created_at?: InputMaybe<Scalars['timestamptz']['input']>
  pdf_filename?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate sum on columns */
export type Credit_Memo_Sum_Fields = {
  __typename?: 'credit_memo_sum_fields'
  amount?: Maybe<Scalars['numeric']['output']>
}

/** order by sum() on columns of table "credit_memo" */
export type Credit_Memo_Sum_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** update columns of table "credit_memo" */
export enum Credit_Memo_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Date = 'date',
  /** column name */
  ErpId = 'erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  PdfCreatedAt = 'pdf_created_at',
  /** column name */
  PdfFilename = 'pdf_filename',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Credit_Memo_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Credit_Memo_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Credit_Memo_Set_Input>
  /** filter the rows which have to be updated */
  where: Credit_Memo_Bool_Exp
}

/** aggregate var_pop on columns */
export type Credit_Memo_Var_Pop_Fields = {
  __typename?: 'credit_memo_var_pop_fields'
  amount?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "credit_memo" */
export type Credit_Memo_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Credit_Memo_Var_Samp_Fields = {
  __typename?: 'credit_memo_var_samp_fields'
  amount?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "credit_memo" */
export type Credit_Memo_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Credit_Memo_Variance_Fields = {
  __typename?: 'credit_memo_variance_fields'
  amount?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "credit_memo" */
export type Credit_Memo_Variance_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** columns and relationships of "cron" */
export type Cron = {
  __typename?: 'cron'
  created_at: Scalars['timestamptz']['output']
  id: Scalars['uuid']['output']
  is_running: Scalars['Boolean']['output']
  jobs: Scalars['jsonb']['output']
  name: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "cron" */
export type CronJobsArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** aggregated selection of "cron" */
export type Cron_Aggregate = {
  __typename?: 'cron_aggregate'
  aggregate?: Maybe<Cron_Aggregate_Fields>
  nodes: Array<Cron>
}

/** aggregate fields of "cron" */
export type Cron_Aggregate_Fields = {
  __typename?: 'cron_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Cron_Max_Fields>
  min?: Maybe<Cron_Min_Fields>
}

/** aggregate fields of "cron" */
export type Cron_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cron_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Cron_Append_Input = {
  jobs?: InputMaybe<Scalars['jsonb']['input']>
}

/** Boolean expression to filter rows from the table "cron". All fields are combined with a logical 'AND'. */
export type Cron_Bool_Exp = {
  _and?: InputMaybe<Array<Cron_Bool_Exp>>
  _not?: InputMaybe<Cron_Bool_Exp>
  _or?: InputMaybe<Array<Cron_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  is_running?: InputMaybe<Boolean_Comparison_Exp>
  jobs?: InputMaybe<Jsonb_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "cron" */
export enum Cron_Constraint {
  /** unique or primary key constraint on columns "name" */
  CronNameKey = 'cron_name_key',
  /** unique or primary key constraint on columns "id" */
  CronPkey = 'cron_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Cron_Delete_At_Path_Input = {
  jobs?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Cron_Delete_Elem_Input = {
  jobs?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Cron_Delete_Key_Input = {
  jobs?: InputMaybe<Scalars['String']['input']>
}

/** input type for inserting data into table "cron" */
export type Cron_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  is_running?: InputMaybe<Scalars['Boolean']['input']>
  jobs?: InputMaybe<Scalars['jsonb']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Cron_Max_Fields = {
  __typename?: 'cron_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** aggregate min on columns */
export type Cron_Min_Fields = {
  __typename?: 'cron_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** response of any mutation on the table "cron" */
export type Cron_Mutation_Response = {
  __typename?: 'cron_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Cron>
}

/** on_conflict condition type for table "cron" */
export type Cron_On_Conflict = {
  constraint: Cron_Constraint
  update_columns?: Array<Cron_Update_Column>
  where?: InputMaybe<Cron_Bool_Exp>
}

/** Ordering options when selecting data from "cron". */
export type Cron_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  is_running?: InputMaybe<Order_By>
  jobs?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: cron */
export type Cron_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Cron_Prepend_Input = {
  jobs?: InputMaybe<Scalars['jsonb']['input']>
}

/** select columns of table "cron" */
export enum Cron_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsRunning = 'is_running',
  /** column name */
  Jobs = 'jobs',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "cron" */
export type Cron_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  is_running?: InputMaybe<Scalars['Boolean']['input']>
  jobs?: InputMaybe<Scalars['jsonb']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "cron" */
export type Cron_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cron_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Cron_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  is_running?: InputMaybe<Scalars['Boolean']['input']>
  jobs?: InputMaybe<Scalars['jsonb']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "cron" */
export enum Cron_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsRunning = 'is_running',
  /** column name */
  Jobs = 'jobs',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Cron_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Cron_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Cron_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Cron_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Cron_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Cron_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cron_Set_Input>
  /** filter the rows which have to be updated */
  where: Cron_Bool_Exp
}

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** columns and relationships of "customer" */
export type Customer = {
  __typename?: 'customer'
  /** An object relationship */
  additional_data?: Maybe<Customer_Additional_Data>
  address: Scalars['String']['output']
  address_2: Scalars['String']['output']
  /** An object relationship */
  agent: Agent
  agent_erp_id: Scalars['String']['output']
  city: Scalars['String']['output']
  country: Scalars['String']['output']
  created_at: Scalars['timestamptz']['output']
  /** An array relationship */
  credit_memos: Array<Credit_Memo>
  /** An aggregate relationship */
  credit_memos_aggregate: Credit_Memo_Aggregate
  /** An array relationship */
  customer_address_dropshippings: Array<Customer_Address_Dropshipping>
  /** An aggregate relationship */
  customer_address_dropshippings_aggregate: Customer_Address_Dropshipping_Aggregate
  /** An array relationship */
  customer_addresses: Array<Customer_Address>
  /** An aggregate relationship */
  customer_addresses_aggregate: Customer_Address_Aggregate
  /** An array relationship */
  customer_discounts: Array<Customer_Discount>
  /** An aggregate relationship */
  customer_discounts_aggregate: Customer_Discount_Aggregate
  email: Scalars['String']['output']
  employee_email: Scalars['String']['output']
  employee_id: Scalars['String']['output']
  erp_id: Scalars['String']['output']
  fiscal_code: Scalars['String']['output']
  has_dropshipping: Scalars['Boolean']['output']
  id: Scalars['uuid']['output']
  /** An object relationship */
  invoice_discount: Invoice_Discount
  invoice_discount_code: Scalars['String']['output']
  /** An array relationship */
  invoices: Array<Invoice>
  /** An aggregate relationship */
  invoices_aggregate: Invoice_Aggregate
  name: Scalars['String']['output']
  /** An array relationship */
  order_confirmeds: Array<Order_Confirmed>
  /** An aggregate relationship */
  order_confirmeds_aggregate: Order_Confirmed_Aggregate
  /** An array relationship */
  order_item_unshippeds: Array<Order_Item_Unshipped>
  /** An aggregate relationship */
  order_item_unshippeds_aggregate: Order_Item_Unshipped_Aggregate
  /** An array relationship */
  orders: Array<Order>
  /** An aggregate relationship */
  orders_aggregate: Order_Aggregate
  /** An object relationship */
  payment_condition: Payment_Condition
  payment_condition_code: Scalars['String']['output']
  /** An object relationship */
  payment_method: Payment_Method
  payment_method_code: Scalars['String']['output']
  phone: Scalars['String']['output']
  prize_current_revenue: Scalars['numeric']['output']
  prize_current_year: Scalars['bigint']['output']
  prize_return_rate_1: Scalars['numeric']['output']
  prize_return_rate_2: Scalars['numeric']['output']
  prize_return_rate_3: Scalars['numeric']['output']
  prize_target_revenue_1: Scalars['numeric']['output']
  prize_target_revenue_2: Scalars['numeric']['output']
  prize_target_revenue_3: Scalars['numeric']['output']
  /** An object relationship */
  product_price_list: Product_Price_List
  product_price_list_code: Scalars['String']['output']
  /** An array relationship */
  product_prices: Array<Product_Price>
  /** An aggregate relationship */
  product_prices_aggregate: Product_Price_Aggregate
  province: Scalars['String']['output']
  rev: Scalars['String']['output']
  /** An array relationship */
  shipment_documents: Array<Shipment_Document>
  /** An aggregate relationship */
  shipment_documents_aggregate: Shipment_Document_Aggregate
  shipping_extra_cost: Scalars['numeric']['output']
  /** An object relationship */
  shipping_method: Shipping_Method
  shipping_method_code: Scalars['String']['output']
  /** An object relationship */
  shipping_rate: Shipping_Rate
  shipping_rate_code: Scalars['String']['output']
  /** An array relationship */
  subcustomers: Array<Subcustomer>
  /** An aggregate relationship */
  subcustomers_aggregate: Subcustomer_Aggregate
  tax_rate: Scalars['numeric']['output']
  /** An array relationship */
  transactions: Array<Transaction>
  /** An aggregate relationship */
  transactions_aggregate: Transaction_Aggregate
  updated_at: Scalars['timestamptz']['output']
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['uuid']['output']>
  vat_number: Scalars['String']['output']
  zip_code: Scalars['String']['output']
}

/** columns and relationships of "customer" */
export type CustomerCredit_MemosArgs = {
  distinct_on?: InputMaybe<Array<Credit_Memo_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Credit_Memo_Order_By>>
  where?: InputMaybe<Credit_Memo_Bool_Exp>
}

/** columns and relationships of "customer" */
export type CustomerCredit_Memos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Memo_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Credit_Memo_Order_By>>
  where?: InputMaybe<Credit_Memo_Bool_Exp>
}

/** columns and relationships of "customer" */
export type CustomerCustomer_Address_DropshippingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Address_Dropshipping_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Address_Dropshipping_Order_By>>
  where?: InputMaybe<Customer_Address_Dropshipping_Bool_Exp>
}

/** columns and relationships of "customer" */
export type CustomerCustomer_Address_Dropshippings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Address_Dropshipping_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Address_Dropshipping_Order_By>>
  where?: InputMaybe<Customer_Address_Dropshipping_Bool_Exp>
}

/** columns and relationships of "customer" */
export type CustomerCustomer_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Address_Order_By>>
  where?: InputMaybe<Customer_Address_Bool_Exp>
}

/** columns and relationships of "customer" */
export type CustomerCustomer_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Address_Order_By>>
  where?: InputMaybe<Customer_Address_Bool_Exp>
}

/** columns and relationships of "customer" */
export type CustomerCustomer_DiscountsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Discount_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Discount_Order_By>>
  where?: InputMaybe<Customer_Discount_Bool_Exp>
}

/** columns and relationships of "customer" */
export type CustomerCustomer_Discounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Discount_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Discount_Order_By>>
  where?: InputMaybe<Customer_Discount_Bool_Exp>
}

/** columns and relationships of "customer" */
export type CustomerInvoicesArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Invoice_Order_By>>
  where?: InputMaybe<Invoice_Bool_Exp>
}

/** columns and relationships of "customer" */
export type CustomerInvoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Invoice_Order_By>>
  where?: InputMaybe<Invoice_Bool_Exp>
}

/** columns and relationships of "customer" */
export type CustomerOrder_ConfirmedsArgs = {
  distinct_on?: InputMaybe<Array<Order_Confirmed_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Confirmed_Order_By>>
  where?: InputMaybe<Order_Confirmed_Bool_Exp>
}

/** columns and relationships of "customer" */
export type CustomerOrder_Confirmeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Confirmed_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Confirmed_Order_By>>
  where?: InputMaybe<Order_Confirmed_Bool_Exp>
}

/** columns and relationships of "customer" */
export type CustomerOrder_Item_UnshippedsArgs = {
  distinct_on?: InputMaybe<Array<Order_Item_Unshipped_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Item_Unshipped_Order_By>>
  where?: InputMaybe<Order_Item_Unshipped_Bool_Exp>
}

/** columns and relationships of "customer" */
export type CustomerOrder_Item_Unshippeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Item_Unshipped_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Item_Unshipped_Order_By>>
  where?: InputMaybe<Order_Item_Unshipped_Bool_Exp>
}

/** columns and relationships of "customer" */
export type CustomerOrdersArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Order_By>>
  where?: InputMaybe<Order_Bool_Exp>
}

/** columns and relationships of "customer" */
export type CustomerOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Order_By>>
  where?: InputMaybe<Order_Bool_Exp>
}

/** columns and relationships of "customer" */
export type CustomerProduct_PricesArgs = {
  distinct_on?: InputMaybe<Array<Product_Price_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Price_Order_By>>
  where?: InputMaybe<Product_Price_Bool_Exp>
}

/** columns and relationships of "customer" */
export type CustomerProduct_Prices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Price_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Price_Order_By>>
  where?: InputMaybe<Product_Price_Bool_Exp>
}

/** columns and relationships of "customer" */
export type CustomerShipment_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Shipment_Document_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Shipment_Document_Order_By>>
  where?: InputMaybe<Shipment_Document_Bool_Exp>
}

/** columns and relationships of "customer" */
export type CustomerShipment_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shipment_Document_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Shipment_Document_Order_By>>
  where?: InputMaybe<Shipment_Document_Bool_Exp>
}

/** columns and relationships of "customer" */
export type CustomerSubcustomersArgs = {
  distinct_on?: InputMaybe<Array<Subcustomer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Subcustomer_Order_By>>
  where?: InputMaybe<Subcustomer_Bool_Exp>
}

/** columns and relationships of "customer" */
export type CustomerSubcustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subcustomer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Subcustomer_Order_By>>
  where?: InputMaybe<Subcustomer_Bool_Exp>
}

/** columns and relationships of "customer" */
export type CustomerTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Transaction_Order_By>>
  where?: InputMaybe<Transaction_Bool_Exp>
}

/** columns and relationships of "customer" */
export type CustomerTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Transaction_Order_By>>
  where?: InputMaybe<Transaction_Bool_Exp>
}

/** columns and relationships of "customer_additional_data" */
export type Customer_Additional_Data = {
  __typename?: 'customer_additional_data'
  /** An object relationship */
  customer: Customer
  customer_erp_id: Scalars['String']['output']
  data: Scalars['jsonb']['output']
}

/** columns and relationships of "customer_additional_data" */
export type Customer_Additional_DataDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** aggregated selection of "customer_additional_data" */
export type Customer_Additional_Data_Aggregate = {
  __typename?: 'customer_additional_data_aggregate'
  aggregate?: Maybe<Customer_Additional_Data_Aggregate_Fields>
  nodes: Array<Customer_Additional_Data>
}

/** aggregate fields of "customer_additional_data" */
export type Customer_Additional_Data_Aggregate_Fields = {
  __typename?: 'customer_additional_data_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Customer_Additional_Data_Max_Fields>
  min?: Maybe<Customer_Additional_Data_Min_Fields>
}

/** aggregate fields of "customer_additional_data" */
export type Customer_Additional_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Additional_Data_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Customer_Additional_Data_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>
}

/** Boolean expression to filter rows from the table "customer_additional_data". All fields are combined with a logical 'AND'. */
export type Customer_Additional_Data_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Additional_Data_Bool_Exp>>
  _not?: InputMaybe<Customer_Additional_Data_Bool_Exp>
  _or?: InputMaybe<Array<Customer_Additional_Data_Bool_Exp>>
  customer?: InputMaybe<Customer_Bool_Exp>
  customer_erp_id?: InputMaybe<String_Comparison_Exp>
  data?: InputMaybe<Jsonb_Comparison_Exp>
}

/** unique or primary key constraints on table "customer_additional_data" */
export enum Customer_Additional_Data_Constraint {
  /** unique or primary key constraint on columns "customer_erp_id" */
  CustomerAdditionalDataCusomerErpIdKey = 'customer_additional_data_cusomer_erp_id_key',
  /** unique or primary key constraint on columns "customer_erp_id" */
  CustomerAdditionalDataPkey = 'customer_additional_data_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Customer_Additional_Data_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Customer_Additional_Data_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Customer_Additional_Data_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']['input']>
}

/** input type for inserting data into table "customer_additional_data" */
export type Customer_Additional_Data_Insert_Input = {
  customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  data?: InputMaybe<Scalars['jsonb']['input']>
}

/** aggregate max on columns */
export type Customer_Additional_Data_Max_Fields = {
  __typename?: 'customer_additional_data_max_fields'
  customer_erp_id?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Customer_Additional_Data_Min_Fields = {
  __typename?: 'customer_additional_data_min_fields'
  customer_erp_id?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "customer_additional_data" */
export type Customer_Additional_Data_Mutation_Response = {
  __typename?: 'customer_additional_data_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Additional_Data>
}

/** input type for inserting object relation for remote table "customer_additional_data" */
export type Customer_Additional_Data_Obj_Rel_Insert_Input = {
  data: Customer_Additional_Data_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Additional_Data_On_Conflict>
}

/** on_conflict condition type for table "customer_additional_data" */
export type Customer_Additional_Data_On_Conflict = {
  constraint: Customer_Additional_Data_Constraint
  update_columns?: Array<Customer_Additional_Data_Update_Column>
  where?: InputMaybe<Customer_Additional_Data_Bool_Exp>
}

/** Ordering options when selecting data from "customer_additional_data". */
export type Customer_Additional_Data_Order_By = {
  customer?: InputMaybe<Customer_Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  data?: InputMaybe<Order_By>
}

/** primary key columns input for table: customer_additional_data */
export type Customer_Additional_Data_Pk_Columns_Input = {
  customer_erp_id: Scalars['String']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Customer_Additional_Data_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>
}

/** select columns of table "customer_additional_data" */
export enum Customer_Additional_Data_Select_Column {
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Data = 'data',
}

/** input type for updating data in table "customer_additional_data" */
export type Customer_Additional_Data_Set_Input = {
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  data?: InputMaybe<Scalars['jsonb']['input']>
}

/** Streaming cursor of the table "customer_additional_data" */
export type Customer_Additional_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Additional_Data_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Customer_Additional_Data_Stream_Cursor_Value_Input = {
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  data?: InputMaybe<Scalars['jsonb']['input']>
}

/** update columns of table "customer_additional_data" */
export enum Customer_Additional_Data_Update_Column {
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Data = 'data',
}

export type Customer_Additional_Data_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Customer_Additional_Data_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Customer_Additional_Data_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Customer_Additional_Data_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Customer_Additional_Data_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Customer_Additional_Data_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Additional_Data_Set_Input>
  /** filter the rows which have to be updated */
  where: Customer_Additional_Data_Bool_Exp
}

/** columns and relationships of "customer_address" */
export type Customer_Address = {
  __typename?: 'customer_address'
  address: Scalars['String']['output']
  address_2: Scalars['String']['output']
  /** An object relationship */
  agent?: Maybe<Agent>
  agent_erp_id?: Maybe<Scalars['String']['output']>
  city: Scalars['String']['output']
  code: Scalars['String']['output']
  country: Scalars['String']['output']
  created_at: Scalars['timestamptz']['output']
  /** An object relationship */
  customer?: Maybe<Customer>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  employee_email: Scalars['String']['output']
  employee_id: Scalars['String']['output']
  id: Scalars['uuid']['output']
  name: Scalars['String']['output']
  phone: Scalars['String']['output']
  province: Scalars['String']['output']
  rev: Scalars['String']['output']
  /** An object relationship */
  shipping_method?: Maybe<Shipping_Method>
  shipping_method_code?: Maybe<Scalars['String']['output']>
  updated_at: Scalars['timestamptz']['output']
  zip_code: Scalars['String']['output']
}

/** aggregated selection of "customer_address" */
export type Customer_Address_Aggregate = {
  __typename?: 'customer_address_aggregate'
  aggregate?: Maybe<Customer_Address_Aggregate_Fields>
  nodes: Array<Customer_Address>
}

export type Customer_Address_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Address_Aggregate_Bool_Exp_Count>
}

export type Customer_Address_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Address_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Customer_Address_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "customer_address" */
export type Customer_Address_Aggregate_Fields = {
  __typename?: 'customer_address_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Customer_Address_Max_Fields>
  min?: Maybe<Customer_Address_Min_Fields>
}

/** aggregate fields of "customer_address" */
export type Customer_Address_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Address_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "customer_address" */
export type Customer_Address_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Customer_Address_Max_Order_By>
  min?: InputMaybe<Customer_Address_Min_Order_By>
}

/** input type for inserting array relation for remote table "customer_address" */
export type Customer_Address_Arr_Rel_Insert_Input = {
  data: Array<Customer_Address_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Address_On_Conflict>
}

/** Boolean expression to filter rows from the table "customer_address". All fields are combined with a logical 'AND'. */
export type Customer_Address_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Address_Bool_Exp>>
  _not?: InputMaybe<Customer_Address_Bool_Exp>
  _or?: InputMaybe<Array<Customer_Address_Bool_Exp>>
  address?: InputMaybe<String_Comparison_Exp>
  address_2?: InputMaybe<String_Comparison_Exp>
  agent?: InputMaybe<Agent_Bool_Exp>
  agent_erp_id?: InputMaybe<String_Comparison_Exp>
  city?: InputMaybe<String_Comparison_Exp>
  code?: InputMaybe<String_Comparison_Exp>
  country?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customer?: InputMaybe<Customer_Bool_Exp>
  customer_erp_id?: InputMaybe<String_Comparison_Exp>
  employee_email?: InputMaybe<String_Comparison_Exp>
  employee_id?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  phone?: InputMaybe<String_Comparison_Exp>
  province?: InputMaybe<String_Comparison_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  shipping_method?: InputMaybe<Shipping_Method_Bool_Exp>
  shipping_method_code?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  zip_code?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "customer_address" */
export enum Customer_Address_Constraint {
  /** unique or primary key constraint on columns "customer_erp_id", "code" */
  CustomerAddressCodeCustomerErpIdKey = 'customer_address_code_customer_erp_id_key',
  /** unique or primary key constraint on columns "id" */
  CustomerAddressPkey = 'customer_address_pkey',
}

/** columns and relationships of "customer_address_dropshipping" */
export type Customer_Address_Dropshipping = {
  __typename?: 'customer_address_dropshipping'
  address: Scalars['String']['output']
  address_2: Scalars['String']['output']
  /** An object relationship */
  agent?: Maybe<Agent>
  agent_erp_id?: Maybe<Scalars['String']['output']>
  city: Scalars['String']['output']
  code: Scalars['Int']['output']
  country: Scalars['String']['output']
  created_at?: Maybe<Scalars['timestamptz']['output']>
  /** An object relationship */
  customer?: Maybe<Customer>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  firstname: Scalars['String']['output']
  id: Scalars['uuid']['output']
  lastname: Scalars['String']['output']
  name: Scalars['String']['output']
  phone: Scalars['String']['output']
  province: Scalars['String']['output']
  /** An object relationship */
  shipping_method?: Maybe<Shipping_Method>
  shipping_method_code?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  zip_code: Scalars['String']['output']
}

/** aggregated selection of "customer_address_dropshipping" */
export type Customer_Address_Dropshipping_Aggregate = {
  __typename?: 'customer_address_dropshipping_aggregate'
  aggregate?: Maybe<Customer_Address_Dropshipping_Aggregate_Fields>
  nodes: Array<Customer_Address_Dropshipping>
}

export type Customer_Address_Dropshipping_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Address_Dropshipping_Aggregate_Bool_Exp_Count>
}

export type Customer_Address_Dropshipping_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Address_Dropshipping_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Customer_Address_Dropshipping_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "customer_address_dropshipping" */
export type Customer_Address_Dropshipping_Aggregate_Fields = {
  __typename?: 'customer_address_dropshipping_aggregate_fields'
  avg?: Maybe<Customer_Address_Dropshipping_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Customer_Address_Dropshipping_Max_Fields>
  min?: Maybe<Customer_Address_Dropshipping_Min_Fields>
  stddev?: Maybe<Customer_Address_Dropshipping_Stddev_Fields>
  stddev_pop?: Maybe<Customer_Address_Dropshipping_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Customer_Address_Dropshipping_Stddev_Samp_Fields>
  sum?: Maybe<Customer_Address_Dropshipping_Sum_Fields>
  var_pop?: Maybe<Customer_Address_Dropshipping_Var_Pop_Fields>
  var_samp?: Maybe<Customer_Address_Dropshipping_Var_Samp_Fields>
  variance?: Maybe<Customer_Address_Dropshipping_Variance_Fields>
}

/** aggregate fields of "customer_address_dropshipping" */
export type Customer_Address_Dropshipping_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Address_Dropshipping_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "customer_address_dropshipping" */
export type Customer_Address_Dropshipping_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Address_Dropshipping_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Customer_Address_Dropshipping_Max_Order_By>
  min?: InputMaybe<Customer_Address_Dropshipping_Min_Order_By>
  stddev?: InputMaybe<Customer_Address_Dropshipping_Stddev_Order_By>
  stddev_pop?: InputMaybe<Customer_Address_Dropshipping_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Customer_Address_Dropshipping_Stddev_Samp_Order_By>
  sum?: InputMaybe<Customer_Address_Dropshipping_Sum_Order_By>
  var_pop?: InputMaybe<Customer_Address_Dropshipping_Var_Pop_Order_By>
  var_samp?: InputMaybe<Customer_Address_Dropshipping_Var_Samp_Order_By>
  variance?: InputMaybe<Customer_Address_Dropshipping_Variance_Order_By>
}

/** input type for inserting array relation for remote table "customer_address_dropshipping" */
export type Customer_Address_Dropshipping_Arr_Rel_Insert_Input = {
  data: Array<Customer_Address_Dropshipping_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Address_Dropshipping_On_Conflict>
}

/** aggregate avg on columns */
export type Customer_Address_Dropshipping_Avg_Fields = {
  __typename?: 'customer_address_dropshipping_avg_fields'
  code?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "customer_address_dropshipping" */
export type Customer_Address_Dropshipping_Avg_Order_By = {
  code?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "customer_address_dropshipping". All fields are combined with a logical 'AND'. */
export type Customer_Address_Dropshipping_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Address_Dropshipping_Bool_Exp>>
  _not?: InputMaybe<Customer_Address_Dropshipping_Bool_Exp>
  _or?: InputMaybe<Array<Customer_Address_Dropshipping_Bool_Exp>>
  address?: InputMaybe<String_Comparison_Exp>
  address_2?: InputMaybe<String_Comparison_Exp>
  agent?: InputMaybe<Agent_Bool_Exp>
  agent_erp_id?: InputMaybe<String_Comparison_Exp>
  city?: InputMaybe<String_Comparison_Exp>
  code?: InputMaybe<Int_Comparison_Exp>
  country?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customer?: InputMaybe<Customer_Bool_Exp>
  customer_erp_id?: InputMaybe<String_Comparison_Exp>
  email?: InputMaybe<String_Comparison_Exp>
  firstname?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  lastname?: InputMaybe<String_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  phone?: InputMaybe<String_Comparison_Exp>
  province?: InputMaybe<String_Comparison_Exp>
  shipping_method?: InputMaybe<Shipping_Method_Bool_Exp>
  shipping_method_code?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  zip_code?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "customer_address_dropshipping" */
export enum Customer_Address_Dropshipping_Constraint {
  /** unique or primary key constraint on columns "customer_erp_id", "code" */
  CustomerAddressDropshippingCustomerErpIdCodeKey = 'customer_address_dropshipping_customer_erp_id_code_key',
  /** unique or primary key constraint on columns "id" */
  CustomerAddressDropshippingPkey = 'customer_address_dropshipping_pkey',
}

/** input type for incrementing numeric columns in table "customer_address_dropshipping" */
export type Customer_Address_Dropshipping_Inc_Input = {
  code?: InputMaybe<Scalars['Int']['input']>
}

/** input type for inserting data into table "customer_address_dropshipping" */
export type Customer_Address_Dropshipping_Insert_Input = {
  address?: InputMaybe<Scalars['String']['input']>
  address_2?: InputMaybe<Scalars['String']['input']>
  agent?: InputMaybe<Agent_Obj_Rel_Insert_Input>
  agent_erp_id?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  code?: InputMaybe<Scalars['Int']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  lastname?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  province?: InputMaybe<Scalars['String']['input']>
  shipping_method?: InputMaybe<Shipping_Method_Obj_Rel_Insert_Input>
  shipping_method_code?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  zip_code?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Customer_Address_Dropshipping_Max_Fields = {
  __typename?: 'customer_address_dropshipping_max_fields'
  address?: Maybe<Scalars['String']['output']>
  address_2?: Maybe<Scalars['String']['output']>
  agent_erp_id?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  code?: Maybe<Scalars['Int']['output']>
  country?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  firstname?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  lastname?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  province?: Maybe<Scalars['String']['output']>
  shipping_method_code?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  zip_code?: Maybe<Scalars['String']['output']>
}

/** order by max() on columns of table "customer_address_dropshipping" */
export type Customer_Address_Dropshipping_Max_Order_By = {
  address?: InputMaybe<Order_By>
  address_2?: InputMaybe<Order_By>
  agent_erp_id?: InputMaybe<Order_By>
  city?: InputMaybe<Order_By>
  code?: InputMaybe<Order_By>
  country?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  firstname?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  lastname?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  phone?: InputMaybe<Order_By>
  province?: InputMaybe<Order_By>
  shipping_method_code?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  zip_code?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Customer_Address_Dropshipping_Min_Fields = {
  __typename?: 'customer_address_dropshipping_min_fields'
  address?: Maybe<Scalars['String']['output']>
  address_2?: Maybe<Scalars['String']['output']>
  agent_erp_id?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  code?: Maybe<Scalars['Int']['output']>
  country?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  firstname?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  lastname?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  province?: Maybe<Scalars['String']['output']>
  shipping_method_code?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  zip_code?: Maybe<Scalars['String']['output']>
}

/** order by min() on columns of table "customer_address_dropshipping" */
export type Customer_Address_Dropshipping_Min_Order_By = {
  address?: InputMaybe<Order_By>
  address_2?: InputMaybe<Order_By>
  agent_erp_id?: InputMaybe<Order_By>
  city?: InputMaybe<Order_By>
  code?: InputMaybe<Order_By>
  country?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  firstname?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  lastname?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  phone?: InputMaybe<Order_By>
  province?: InputMaybe<Order_By>
  shipping_method_code?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  zip_code?: InputMaybe<Order_By>
}

/** response of any mutation on the table "customer_address_dropshipping" */
export type Customer_Address_Dropshipping_Mutation_Response = {
  __typename?: 'customer_address_dropshipping_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Address_Dropshipping>
}

/** on_conflict condition type for table "customer_address_dropshipping" */
export type Customer_Address_Dropshipping_On_Conflict = {
  constraint: Customer_Address_Dropshipping_Constraint
  update_columns?: Array<Customer_Address_Dropshipping_Update_Column>
  where?: InputMaybe<Customer_Address_Dropshipping_Bool_Exp>
}

/** Ordering options when selecting data from "customer_address_dropshipping". */
export type Customer_Address_Dropshipping_Order_By = {
  address?: InputMaybe<Order_By>
  address_2?: InputMaybe<Order_By>
  agent?: InputMaybe<Agent_Order_By>
  agent_erp_id?: InputMaybe<Order_By>
  city?: InputMaybe<Order_By>
  code?: InputMaybe<Order_By>
  country?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customer?: InputMaybe<Customer_Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  firstname?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  lastname?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  phone?: InputMaybe<Order_By>
  province?: InputMaybe<Order_By>
  shipping_method?: InputMaybe<Shipping_Method_Order_By>
  shipping_method_code?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  zip_code?: InputMaybe<Order_By>
}

/** primary key columns input for table: customer_address_dropshipping */
export type Customer_Address_Dropshipping_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "customer_address_dropshipping" */
export enum Customer_Address_Dropshipping_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Address_2 = 'address_2',
  /** column name */
  AgentErpId = 'agent_erp_id',
  /** column name */
  City = 'city',
  /** column name */
  Code = 'code',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Email = 'email',
  /** column name */
  Firstname = 'firstname',
  /** column name */
  Id = 'id',
  /** column name */
  Lastname = 'lastname',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Province = 'province',
  /** column name */
  ShippingMethodCode = 'shipping_method_code',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zip_code',
}

/** input type for updating data in table "customer_address_dropshipping" */
export type Customer_Address_Dropshipping_Set_Input = {
  address?: InputMaybe<Scalars['String']['input']>
  address_2?: InputMaybe<Scalars['String']['input']>
  agent_erp_id?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  code?: InputMaybe<Scalars['Int']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  lastname?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  province?: InputMaybe<Scalars['String']['input']>
  shipping_method_code?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  zip_code?: InputMaybe<Scalars['String']['input']>
}

/** aggregate stddev on columns */
export type Customer_Address_Dropshipping_Stddev_Fields = {
  __typename?: 'customer_address_dropshipping_stddev_fields'
  code?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "customer_address_dropshipping" */
export type Customer_Address_Dropshipping_Stddev_Order_By = {
  code?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Customer_Address_Dropshipping_Stddev_Pop_Fields = {
  __typename?: 'customer_address_dropshipping_stddev_pop_fields'
  code?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "customer_address_dropshipping" */
export type Customer_Address_Dropshipping_Stddev_Pop_Order_By = {
  code?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Customer_Address_Dropshipping_Stddev_Samp_Fields = {
  __typename?: 'customer_address_dropshipping_stddev_samp_fields'
  code?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "customer_address_dropshipping" */
export type Customer_Address_Dropshipping_Stddev_Samp_Order_By = {
  code?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "customer_address_dropshipping" */
export type Customer_Address_Dropshipping_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Address_Dropshipping_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Customer_Address_Dropshipping_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']['input']>
  address_2?: InputMaybe<Scalars['String']['input']>
  agent_erp_id?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  code?: InputMaybe<Scalars['Int']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  lastname?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  province?: InputMaybe<Scalars['String']['input']>
  shipping_method_code?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  zip_code?: InputMaybe<Scalars['String']['input']>
}

/** aggregate sum on columns */
export type Customer_Address_Dropshipping_Sum_Fields = {
  __typename?: 'customer_address_dropshipping_sum_fields'
  code?: Maybe<Scalars['Int']['output']>
}

/** order by sum() on columns of table "customer_address_dropshipping" */
export type Customer_Address_Dropshipping_Sum_Order_By = {
  code?: InputMaybe<Order_By>
}

/** update columns of table "customer_address_dropshipping" */
export enum Customer_Address_Dropshipping_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Address_2 = 'address_2',
  /** column name */
  AgentErpId = 'agent_erp_id',
  /** column name */
  City = 'city',
  /** column name */
  Code = 'code',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Email = 'email',
  /** column name */
  Firstname = 'firstname',
  /** column name */
  Id = 'id',
  /** column name */
  Lastname = 'lastname',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Province = 'province',
  /** column name */
  ShippingMethodCode = 'shipping_method_code',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zip_code',
}

export type Customer_Address_Dropshipping_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Address_Dropshipping_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Address_Dropshipping_Set_Input>
  /** filter the rows which have to be updated */
  where: Customer_Address_Dropshipping_Bool_Exp
}

/** aggregate var_pop on columns */
export type Customer_Address_Dropshipping_Var_Pop_Fields = {
  __typename?: 'customer_address_dropshipping_var_pop_fields'
  code?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "customer_address_dropshipping" */
export type Customer_Address_Dropshipping_Var_Pop_Order_By = {
  code?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Customer_Address_Dropshipping_Var_Samp_Fields = {
  __typename?: 'customer_address_dropshipping_var_samp_fields'
  code?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "customer_address_dropshipping" */
export type Customer_Address_Dropshipping_Var_Samp_Order_By = {
  code?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Customer_Address_Dropshipping_Variance_Fields = {
  __typename?: 'customer_address_dropshipping_variance_fields'
  code?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "customer_address_dropshipping" */
export type Customer_Address_Dropshipping_Variance_Order_By = {
  code?: InputMaybe<Order_By>
}

/** input type for inserting data into table "customer_address" */
export type Customer_Address_Insert_Input = {
  address?: InputMaybe<Scalars['String']['input']>
  address_2?: InputMaybe<Scalars['String']['input']>
  agent?: InputMaybe<Agent_Obj_Rel_Insert_Input>
  agent_erp_id?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  code?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  employee_email?: InputMaybe<Scalars['String']['input']>
  employee_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  province?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  shipping_method?: InputMaybe<Shipping_Method_Obj_Rel_Insert_Input>
  shipping_method_code?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  zip_code?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Customer_Address_Max_Fields = {
  __typename?: 'customer_address_max_fields'
  address?: Maybe<Scalars['String']['output']>
  address_2?: Maybe<Scalars['String']['output']>
  agent_erp_id?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  code?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  employee_email?: Maybe<Scalars['String']['output']>
  employee_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  province?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  shipping_method_code?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  zip_code?: Maybe<Scalars['String']['output']>
}

/** order by max() on columns of table "customer_address" */
export type Customer_Address_Max_Order_By = {
  address?: InputMaybe<Order_By>
  address_2?: InputMaybe<Order_By>
  agent_erp_id?: InputMaybe<Order_By>
  city?: InputMaybe<Order_By>
  code?: InputMaybe<Order_By>
  country?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  employee_email?: InputMaybe<Order_By>
  employee_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  phone?: InputMaybe<Order_By>
  province?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  shipping_method_code?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  zip_code?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Customer_Address_Min_Fields = {
  __typename?: 'customer_address_min_fields'
  address?: Maybe<Scalars['String']['output']>
  address_2?: Maybe<Scalars['String']['output']>
  agent_erp_id?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  code?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  employee_email?: Maybe<Scalars['String']['output']>
  employee_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  province?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  shipping_method_code?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  zip_code?: Maybe<Scalars['String']['output']>
}

/** order by min() on columns of table "customer_address" */
export type Customer_Address_Min_Order_By = {
  address?: InputMaybe<Order_By>
  address_2?: InputMaybe<Order_By>
  agent_erp_id?: InputMaybe<Order_By>
  city?: InputMaybe<Order_By>
  code?: InputMaybe<Order_By>
  country?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  employee_email?: InputMaybe<Order_By>
  employee_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  phone?: InputMaybe<Order_By>
  province?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  shipping_method_code?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  zip_code?: InputMaybe<Order_By>
}

/** response of any mutation on the table "customer_address" */
export type Customer_Address_Mutation_Response = {
  __typename?: 'customer_address_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Address>
}

/** on_conflict condition type for table "customer_address" */
export type Customer_Address_On_Conflict = {
  constraint: Customer_Address_Constraint
  update_columns?: Array<Customer_Address_Update_Column>
  where?: InputMaybe<Customer_Address_Bool_Exp>
}

/** Ordering options when selecting data from "customer_address". */
export type Customer_Address_Order_By = {
  address?: InputMaybe<Order_By>
  address_2?: InputMaybe<Order_By>
  agent?: InputMaybe<Agent_Order_By>
  agent_erp_id?: InputMaybe<Order_By>
  city?: InputMaybe<Order_By>
  code?: InputMaybe<Order_By>
  country?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customer?: InputMaybe<Customer_Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  employee_email?: InputMaybe<Order_By>
  employee_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  phone?: InputMaybe<Order_By>
  province?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  shipping_method?: InputMaybe<Shipping_Method_Order_By>
  shipping_method_code?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  zip_code?: InputMaybe<Order_By>
}

/** primary key columns input for table: customer_address */
export type Customer_Address_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "customer_address" */
export enum Customer_Address_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Address_2 = 'address_2',
  /** column name */
  AgentErpId = 'agent_erp_id',
  /** column name */
  City = 'city',
  /** column name */
  Code = 'code',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  EmployeeEmail = 'employee_email',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Province = 'province',
  /** column name */
  Rev = 'rev',
  /** column name */
  ShippingMethodCode = 'shipping_method_code',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zip_code',
}

/** input type for updating data in table "customer_address" */
export type Customer_Address_Set_Input = {
  address?: InputMaybe<Scalars['String']['input']>
  address_2?: InputMaybe<Scalars['String']['input']>
  agent_erp_id?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  code?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  employee_email?: InputMaybe<Scalars['String']['input']>
  employee_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  province?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  shipping_method_code?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  zip_code?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "customer_address" */
export type Customer_Address_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Address_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Customer_Address_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']['input']>
  address_2?: InputMaybe<Scalars['String']['input']>
  agent_erp_id?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  code?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  employee_email?: InputMaybe<Scalars['String']['input']>
  employee_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  province?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  shipping_method_code?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  zip_code?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "customer_address" */
export enum Customer_Address_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Address_2 = 'address_2',
  /** column name */
  AgentErpId = 'agent_erp_id',
  /** column name */
  City = 'city',
  /** column name */
  Code = 'code',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  EmployeeEmail = 'employee_email',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Province = 'province',
  /** column name */
  Rev = 'rev',
  /** column name */
  ShippingMethodCode = 'shipping_method_code',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zip_code',
}

export type Customer_Address_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Address_Set_Input>
  /** filter the rows which have to be updated */
  where: Customer_Address_Bool_Exp
}

/** aggregated selection of "customer" */
export type Customer_Aggregate = {
  __typename?: 'customer_aggregate'
  aggregate?: Maybe<Customer_Aggregate_Fields>
  nodes: Array<Customer>
}

export type Customer_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Customer_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<Customer_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<Customer_Aggregate_Bool_Exp_Count>
}

export type Customer_Aggregate_Bool_Exp_Bool_And = {
  arguments: Customer_Select_Column_Customer_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Customer_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Customer_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Customer_Select_Column_Customer_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Customer_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Customer_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Customer_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "customer" */
export type Customer_Aggregate_Fields = {
  __typename?: 'customer_aggregate_fields'
  avg?: Maybe<Customer_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Customer_Max_Fields>
  min?: Maybe<Customer_Min_Fields>
  stddev?: Maybe<Customer_Stddev_Fields>
  stddev_pop?: Maybe<Customer_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Customer_Stddev_Samp_Fields>
  sum?: Maybe<Customer_Sum_Fields>
  var_pop?: Maybe<Customer_Var_Pop_Fields>
  var_samp?: Maybe<Customer_Var_Samp_Fields>
  variance?: Maybe<Customer_Variance_Fields>
}

/** aggregate fields of "customer" */
export type Customer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "customer" */
export type Customer_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Customer_Max_Order_By>
  min?: InputMaybe<Customer_Min_Order_By>
  stddev?: InputMaybe<Customer_Stddev_Order_By>
  stddev_pop?: InputMaybe<Customer_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Customer_Stddev_Samp_Order_By>
  sum?: InputMaybe<Customer_Sum_Order_By>
  var_pop?: InputMaybe<Customer_Var_Pop_Order_By>
  var_samp?: InputMaybe<Customer_Var_Samp_Order_By>
  variance?: InputMaybe<Customer_Variance_Order_By>
}

/** input type for inserting array relation for remote table "customer" */
export type Customer_Arr_Rel_Insert_Input = {
  data: Array<Customer_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_On_Conflict>
}

/** aggregate avg on columns */
export type Customer_Avg_Fields = {
  __typename?: 'customer_avg_fields'
  prize_current_revenue?: Maybe<Scalars['Float']['output']>
  prize_current_year?: Maybe<Scalars['Float']['output']>
  prize_return_rate_1?: Maybe<Scalars['Float']['output']>
  prize_return_rate_2?: Maybe<Scalars['Float']['output']>
  prize_return_rate_3?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_1?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_2?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_3?: Maybe<Scalars['Float']['output']>
  shipping_extra_cost?: Maybe<Scalars['Float']['output']>
  tax_rate?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "customer" */
export type Customer_Avg_Order_By = {
  prize_current_revenue?: InputMaybe<Order_By>
  prize_current_year?: InputMaybe<Order_By>
  prize_return_rate_1?: InputMaybe<Order_By>
  prize_return_rate_2?: InputMaybe<Order_By>
  prize_return_rate_3?: InputMaybe<Order_By>
  prize_target_revenue_1?: InputMaybe<Order_By>
  prize_target_revenue_2?: InputMaybe<Order_By>
  prize_target_revenue_3?: InputMaybe<Order_By>
  shipping_extra_cost?: InputMaybe<Order_By>
  tax_rate?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "customer". All fields are combined with a logical 'AND'. */
export type Customer_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Bool_Exp>>
  _not?: InputMaybe<Customer_Bool_Exp>
  _or?: InputMaybe<Array<Customer_Bool_Exp>>
  additional_data?: InputMaybe<Customer_Additional_Data_Bool_Exp>
  address?: InputMaybe<String_Comparison_Exp>
  address_2?: InputMaybe<String_Comparison_Exp>
  agent?: InputMaybe<Agent_Bool_Exp>
  agent_erp_id?: InputMaybe<String_Comparison_Exp>
  city?: InputMaybe<String_Comparison_Exp>
  country?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  credit_memos?: InputMaybe<Credit_Memo_Bool_Exp>
  credit_memos_aggregate?: InputMaybe<Credit_Memo_Aggregate_Bool_Exp>
  customer_address_dropshippings?: InputMaybe<Customer_Address_Dropshipping_Bool_Exp>
  customer_address_dropshippings_aggregate?: InputMaybe<Customer_Address_Dropshipping_Aggregate_Bool_Exp>
  customer_addresses?: InputMaybe<Customer_Address_Bool_Exp>
  customer_addresses_aggregate?: InputMaybe<Customer_Address_Aggregate_Bool_Exp>
  customer_discounts?: InputMaybe<Customer_Discount_Bool_Exp>
  customer_discounts_aggregate?: InputMaybe<Customer_Discount_Aggregate_Bool_Exp>
  email?: InputMaybe<String_Comparison_Exp>
  employee_email?: InputMaybe<String_Comparison_Exp>
  employee_id?: InputMaybe<String_Comparison_Exp>
  erp_id?: InputMaybe<String_Comparison_Exp>
  fiscal_code?: InputMaybe<String_Comparison_Exp>
  has_dropshipping?: InputMaybe<Boolean_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  invoice_discount?: InputMaybe<Invoice_Discount_Bool_Exp>
  invoice_discount_code?: InputMaybe<String_Comparison_Exp>
  invoices?: InputMaybe<Invoice_Bool_Exp>
  invoices_aggregate?: InputMaybe<Invoice_Aggregate_Bool_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  order_confirmeds?: InputMaybe<Order_Confirmed_Bool_Exp>
  order_confirmeds_aggregate?: InputMaybe<Order_Confirmed_Aggregate_Bool_Exp>
  order_item_unshippeds?: InputMaybe<Order_Item_Unshipped_Bool_Exp>
  order_item_unshippeds_aggregate?: InputMaybe<Order_Item_Unshipped_Aggregate_Bool_Exp>
  orders?: InputMaybe<Order_Bool_Exp>
  orders_aggregate?: InputMaybe<Order_Aggregate_Bool_Exp>
  payment_condition?: InputMaybe<Payment_Condition_Bool_Exp>
  payment_condition_code?: InputMaybe<String_Comparison_Exp>
  payment_method?: InputMaybe<Payment_Method_Bool_Exp>
  payment_method_code?: InputMaybe<String_Comparison_Exp>
  phone?: InputMaybe<String_Comparison_Exp>
  prize_current_revenue?: InputMaybe<Numeric_Comparison_Exp>
  prize_current_year?: InputMaybe<Bigint_Comparison_Exp>
  prize_return_rate_1?: InputMaybe<Numeric_Comparison_Exp>
  prize_return_rate_2?: InputMaybe<Numeric_Comparison_Exp>
  prize_return_rate_3?: InputMaybe<Numeric_Comparison_Exp>
  prize_target_revenue_1?: InputMaybe<Numeric_Comparison_Exp>
  prize_target_revenue_2?: InputMaybe<Numeric_Comparison_Exp>
  prize_target_revenue_3?: InputMaybe<Numeric_Comparison_Exp>
  product_price_list?: InputMaybe<Product_Price_List_Bool_Exp>
  product_price_list_code?: InputMaybe<String_Comparison_Exp>
  product_prices?: InputMaybe<Product_Price_Bool_Exp>
  product_prices_aggregate?: InputMaybe<Product_Price_Aggregate_Bool_Exp>
  province?: InputMaybe<String_Comparison_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  shipment_documents?: InputMaybe<Shipment_Document_Bool_Exp>
  shipment_documents_aggregate?: InputMaybe<Shipment_Document_Aggregate_Bool_Exp>
  shipping_extra_cost?: InputMaybe<Numeric_Comparison_Exp>
  shipping_method?: InputMaybe<Shipping_Method_Bool_Exp>
  shipping_method_code?: InputMaybe<String_Comparison_Exp>
  shipping_rate?: InputMaybe<Shipping_Rate_Bool_Exp>
  shipping_rate_code?: InputMaybe<String_Comparison_Exp>
  subcustomers?: InputMaybe<Subcustomer_Bool_Exp>
  subcustomers_aggregate?: InputMaybe<Subcustomer_Aggregate_Bool_Exp>
  tax_rate?: InputMaybe<Numeric_Comparison_Exp>
  transactions?: InputMaybe<Transaction_Bool_Exp>
  transactions_aggregate?: InputMaybe<Transaction_Aggregate_Bool_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user?: InputMaybe<User_Bool_Exp>
  user_id?: InputMaybe<Uuid_Comparison_Exp>
  vat_number?: InputMaybe<String_Comparison_Exp>
  zip_code?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "customer" */
export enum Customer_Constraint {
  /** unique or primary key constraint on columns "erp_id" */
  CustomerErpIdKey = 'customer_erp_id_key',
  /** unique or primary key constraint on columns "id" */
  CustomerPkey = 'customer_pkey',
  /** unique or primary key constraint on columns "user_id" */
  CustomerUserIdKey = 'customer_user_id_key',
}

/** columns and relationships of "customer_discount" */
export type Customer_Discount = {
  __typename?: 'customer_discount'
  created_at: Scalars['timestamptz']['output']
  /** An object relationship */
  customer?: Maybe<Customer>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  discount_percent: Scalars['numeric']['output']
  id: Scalars['uuid']['output']
  /** An object relationship */
  product_discount_group: Product_Discount_Group
  product_discount_group_code: Scalars['String']['output']
  rev: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** aggregated selection of "customer_discount" */
export type Customer_Discount_Aggregate = {
  __typename?: 'customer_discount_aggregate'
  aggregate?: Maybe<Customer_Discount_Aggregate_Fields>
  nodes: Array<Customer_Discount>
}

export type Customer_Discount_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Discount_Aggregate_Bool_Exp_Count>
}

export type Customer_Discount_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Discount_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Customer_Discount_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "customer_discount" */
export type Customer_Discount_Aggregate_Fields = {
  __typename?: 'customer_discount_aggregate_fields'
  avg?: Maybe<Customer_Discount_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Customer_Discount_Max_Fields>
  min?: Maybe<Customer_Discount_Min_Fields>
  stddev?: Maybe<Customer_Discount_Stddev_Fields>
  stddev_pop?: Maybe<Customer_Discount_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Customer_Discount_Stddev_Samp_Fields>
  sum?: Maybe<Customer_Discount_Sum_Fields>
  var_pop?: Maybe<Customer_Discount_Var_Pop_Fields>
  var_samp?: Maybe<Customer_Discount_Var_Samp_Fields>
  variance?: Maybe<Customer_Discount_Variance_Fields>
}

/** aggregate fields of "customer_discount" */
export type Customer_Discount_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Discount_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "customer_discount" */
export type Customer_Discount_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Discount_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Customer_Discount_Max_Order_By>
  min?: InputMaybe<Customer_Discount_Min_Order_By>
  stddev?: InputMaybe<Customer_Discount_Stddev_Order_By>
  stddev_pop?: InputMaybe<Customer_Discount_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Customer_Discount_Stddev_Samp_Order_By>
  sum?: InputMaybe<Customer_Discount_Sum_Order_By>
  var_pop?: InputMaybe<Customer_Discount_Var_Pop_Order_By>
  var_samp?: InputMaybe<Customer_Discount_Var_Samp_Order_By>
  variance?: InputMaybe<Customer_Discount_Variance_Order_By>
}

/** input type for inserting array relation for remote table "customer_discount" */
export type Customer_Discount_Arr_Rel_Insert_Input = {
  data: Array<Customer_Discount_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Discount_On_Conflict>
}

/** aggregate avg on columns */
export type Customer_Discount_Avg_Fields = {
  __typename?: 'customer_discount_avg_fields'
  discount_percent?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "customer_discount" */
export type Customer_Discount_Avg_Order_By = {
  discount_percent?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "customer_discount". All fields are combined with a logical 'AND'. */
export type Customer_Discount_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Discount_Bool_Exp>>
  _not?: InputMaybe<Customer_Discount_Bool_Exp>
  _or?: InputMaybe<Array<Customer_Discount_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customer?: InputMaybe<Customer_Bool_Exp>
  customer_erp_id?: InputMaybe<String_Comparison_Exp>
  discount_percent?: InputMaybe<Numeric_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  product_discount_group?: InputMaybe<Product_Discount_Group_Bool_Exp>
  product_discount_group_code?: InputMaybe<String_Comparison_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "customer_discount" */
export enum Customer_Discount_Constraint {
  /** unique or primary key constraint on columns "customer_erp_id", "product_discount_group_code" */
  CustomerDiscountCustomerErpIdProductDiscountGroupCoKey = 'customer_discount_customer_erp_id_product_discount_group_co_key',
  /** unique or primary key constraint on columns "id" */
  CustomerDiscountPkey = 'customer_discount_pkey',
}

/** input type for incrementing numeric columns in table "customer_discount" */
export type Customer_Discount_Inc_Input = {
  discount_percent?: InputMaybe<Scalars['numeric']['input']>
}

/** input type for inserting data into table "customer_discount" */
export type Customer_Discount_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  discount_percent?: InputMaybe<Scalars['numeric']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  product_discount_group?: InputMaybe<Product_Discount_Group_Obj_Rel_Insert_Input>
  product_discount_group_code?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Customer_Discount_Max_Fields = {
  __typename?: 'customer_discount_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  discount_percent?: Maybe<Scalars['numeric']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  product_discount_group_code?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "customer_discount" */
export type Customer_Discount_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  discount_percent?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  product_discount_group_code?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Customer_Discount_Min_Fields = {
  __typename?: 'customer_discount_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  discount_percent?: Maybe<Scalars['numeric']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  product_discount_group_code?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "customer_discount" */
export type Customer_Discount_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  discount_percent?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  product_discount_group_code?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "customer_discount" */
export type Customer_Discount_Mutation_Response = {
  __typename?: 'customer_discount_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Discount>
}

/** on_conflict condition type for table "customer_discount" */
export type Customer_Discount_On_Conflict = {
  constraint: Customer_Discount_Constraint
  update_columns?: Array<Customer_Discount_Update_Column>
  where?: InputMaybe<Customer_Discount_Bool_Exp>
}

/** Ordering options when selecting data from "customer_discount". */
export type Customer_Discount_Order_By = {
  created_at?: InputMaybe<Order_By>
  customer?: InputMaybe<Customer_Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  discount_percent?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  product_discount_group?: InputMaybe<Product_Discount_Group_Order_By>
  product_discount_group_code?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: customer_discount */
export type Customer_Discount_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "customer_discount" */
export enum Customer_Discount_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  DiscountPercent = 'discount_percent',
  /** column name */
  Id = 'id',
  /** column name */
  ProductDiscountGroupCode = 'product_discount_group_code',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "customer_discount" */
export type Customer_Discount_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  discount_percent?: InputMaybe<Scalars['numeric']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  product_discount_group_code?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate stddev on columns */
export type Customer_Discount_Stddev_Fields = {
  __typename?: 'customer_discount_stddev_fields'
  discount_percent?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "customer_discount" */
export type Customer_Discount_Stddev_Order_By = {
  discount_percent?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Customer_Discount_Stddev_Pop_Fields = {
  __typename?: 'customer_discount_stddev_pop_fields'
  discount_percent?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "customer_discount" */
export type Customer_Discount_Stddev_Pop_Order_By = {
  discount_percent?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Customer_Discount_Stddev_Samp_Fields = {
  __typename?: 'customer_discount_stddev_samp_fields'
  discount_percent?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "customer_discount" */
export type Customer_Discount_Stddev_Samp_Order_By = {
  discount_percent?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "customer_discount" */
export type Customer_Discount_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Discount_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Customer_Discount_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  discount_percent?: InputMaybe<Scalars['numeric']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  product_discount_group_code?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate sum on columns */
export type Customer_Discount_Sum_Fields = {
  __typename?: 'customer_discount_sum_fields'
  discount_percent?: Maybe<Scalars['numeric']['output']>
}

/** order by sum() on columns of table "customer_discount" */
export type Customer_Discount_Sum_Order_By = {
  discount_percent?: InputMaybe<Order_By>
}

/** update columns of table "customer_discount" */
export enum Customer_Discount_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  DiscountPercent = 'discount_percent',
  /** column name */
  Id = 'id',
  /** column name */
  ProductDiscountGroupCode = 'product_discount_group_code',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Customer_Discount_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Discount_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Discount_Set_Input>
  /** filter the rows which have to be updated */
  where: Customer_Discount_Bool_Exp
}

/** aggregate var_pop on columns */
export type Customer_Discount_Var_Pop_Fields = {
  __typename?: 'customer_discount_var_pop_fields'
  discount_percent?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "customer_discount" */
export type Customer_Discount_Var_Pop_Order_By = {
  discount_percent?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Customer_Discount_Var_Samp_Fields = {
  __typename?: 'customer_discount_var_samp_fields'
  discount_percent?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "customer_discount" */
export type Customer_Discount_Var_Samp_Order_By = {
  discount_percent?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Customer_Discount_Variance_Fields = {
  __typename?: 'customer_discount_variance_fields'
  discount_percent?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "customer_discount" */
export type Customer_Discount_Variance_Order_By = {
  discount_percent?: InputMaybe<Order_By>
}

/** input type for incrementing numeric columns in table "customer" */
export type Customer_Inc_Input = {
  prize_current_revenue?: InputMaybe<Scalars['numeric']['input']>
  prize_current_year?: InputMaybe<Scalars['bigint']['input']>
  prize_return_rate_1?: InputMaybe<Scalars['numeric']['input']>
  prize_return_rate_2?: InputMaybe<Scalars['numeric']['input']>
  prize_return_rate_3?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_1?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_2?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_3?: InputMaybe<Scalars['numeric']['input']>
  shipping_extra_cost?: InputMaybe<Scalars['numeric']['input']>
  tax_rate?: InputMaybe<Scalars['numeric']['input']>
}

/** input type for inserting data into table "customer" */
export type Customer_Insert_Input = {
  additional_data?: InputMaybe<Customer_Additional_Data_Obj_Rel_Insert_Input>
  address?: InputMaybe<Scalars['String']['input']>
  address_2?: InputMaybe<Scalars['String']['input']>
  agent?: InputMaybe<Agent_Obj_Rel_Insert_Input>
  agent_erp_id?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  credit_memos?: InputMaybe<Credit_Memo_Arr_Rel_Insert_Input>
  customer_address_dropshippings?: InputMaybe<Customer_Address_Dropshipping_Arr_Rel_Insert_Input>
  customer_addresses?: InputMaybe<Customer_Address_Arr_Rel_Insert_Input>
  customer_discounts?: InputMaybe<Customer_Discount_Arr_Rel_Insert_Input>
  email?: InputMaybe<Scalars['String']['input']>
  employee_email?: InputMaybe<Scalars['String']['input']>
  employee_id?: InputMaybe<Scalars['String']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  fiscal_code?: InputMaybe<Scalars['String']['input']>
  has_dropshipping?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  invoice_discount?: InputMaybe<Invoice_Discount_Obj_Rel_Insert_Input>
  invoice_discount_code?: InputMaybe<Scalars['String']['input']>
  invoices?: InputMaybe<Invoice_Arr_Rel_Insert_Input>
  name?: InputMaybe<Scalars['String']['input']>
  order_confirmeds?: InputMaybe<Order_Confirmed_Arr_Rel_Insert_Input>
  order_item_unshippeds?: InputMaybe<Order_Item_Unshipped_Arr_Rel_Insert_Input>
  orders?: InputMaybe<Order_Arr_Rel_Insert_Input>
  payment_condition?: InputMaybe<Payment_Condition_Obj_Rel_Insert_Input>
  payment_condition_code?: InputMaybe<Scalars['String']['input']>
  payment_method?: InputMaybe<Payment_Method_Obj_Rel_Insert_Input>
  payment_method_code?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  prize_current_revenue?: InputMaybe<Scalars['numeric']['input']>
  prize_current_year?: InputMaybe<Scalars['bigint']['input']>
  prize_return_rate_1?: InputMaybe<Scalars['numeric']['input']>
  prize_return_rate_2?: InputMaybe<Scalars['numeric']['input']>
  prize_return_rate_3?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_1?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_2?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_3?: InputMaybe<Scalars['numeric']['input']>
  product_price_list?: InputMaybe<Product_Price_List_Obj_Rel_Insert_Input>
  product_price_list_code?: InputMaybe<Scalars['String']['input']>
  product_prices?: InputMaybe<Product_Price_Arr_Rel_Insert_Input>
  province?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  shipment_documents?: InputMaybe<Shipment_Document_Arr_Rel_Insert_Input>
  shipping_extra_cost?: InputMaybe<Scalars['numeric']['input']>
  shipping_method?: InputMaybe<Shipping_Method_Obj_Rel_Insert_Input>
  shipping_method_code?: InputMaybe<Scalars['String']['input']>
  shipping_rate?: InputMaybe<Shipping_Rate_Obj_Rel_Insert_Input>
  shipping_rate_code?: InputMaybe<Scalars['String']['input']>
  subcustomers?: InputMaybe<Subcustomer_Arr_Rel_Insert_Input>
  tax_rate?: InputMaybe<Scalars['numeric']['input']>
  transactions?: InputMaybe<Transaction_Arr_Rel_Insert_Input>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user?: InputMaybe<User_Obj_Rel_Insert_Input>
  user_id?: InputMaybe<Scalars['uuid']['input']>
  vat_number?: InputMaybe<Scalars['String']['input']>
  zip_code?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Customer_Max_Fields = {
  __typename?: 'customer_max_fields'
  address?: Maybe<Scalars['String']['output']>
  address_2?: Maybe<Scalars['String']['output']>
  agent_erp_id?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  email?: Maybe<Scalars['String']['output']>
  employee_email?: Maybe<Scalars['String']['output']>
  employee_id?: Maybe<Scalars['String']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  fiscal_code?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  invoice_discount_code?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  payment_condition_code?: Maybe<Scalars['String']['output']>
  payment_method_code?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  prize_current_revenue?: Maybe<Scalars['numeric']['output']>
  prize_current_year?: Maybe<Scalars['bigint']['output']>
  prize_return_rate_1?: Maybe<Scalars['numeric']['output']>
  prize_return_rate_2?: Maybe<Scalars['numeric']['output']>
  prize_return_rate_3?: Maybe<Scalars['numeric']['output']>
  prize_target_revenue_1?: Maybe<Scalars['numeric']['output']>
  prize_target_revenue_2?: Maybe<Scalars['numeric']['output']>
  prize_target_revenue_3?: Maybe<Scalars['numeric']['output']>
  product_price_list_code?: Maybe<Scalars['String']['output']>
  province?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  shipping_extra_cost?: Maybe<Scalars['numeric']['output']>
  shipping_method_code?: Maybe<Scalars['String']['output']>
  shipping_rate_code?: Maybe<Scalars['String']['output']>
  tax_rate?: Maybe<Scalars['numeric']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
  vat_number?: Maybe<Scalars['String']['output']>
  zip_code?: Maybe<Scalars['String']['output']>
}

/** order by max() on columns of table "customer" */
export type Customer_Max_Order_By = {
  address?: InputMaybe<Order_By>
  address_2?: InputMaybe<Order_By>
  agent_erp_id?: InputMaybe<Order_By>
  city?: InputMaybe<Order_By>
  country?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  employee_email?: InputMaybe<Order_By>
  employee_id?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  fiscal_code?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  invoice_discount_code?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  payment_condition_code?: InputMaybe<Order_By>
  payment_method_code?: InputMaybe<Order_By>
  phone?: InputMaybe<Order_By>
  prize_current_revenue?: InputMaybe<Order_By>
  prize_current_year?: InputMaybe<Order_By>
  prize_return_rate_1?: InputMaybe<Order_By>
  prize_return_rate_2?: InputMaybe<Order_By>
  prize_return_rate_3?: InputMaybe<Order_By>
  prize_target_revenue_1?: InputMaybe<Order_By>
  prize_target_revenue_2?: InputMaybe<Order_By>
  prize_target_revenue_3?: InputMaybe<Order_By>
  product_price_list_code?: InputMaybe<Order_By>
  province?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  shipping_extra_cost?: InputMaybe<Order_By>
  shipping_method_code?: InputMaybe<Order_By>
  shipping_rate_code?: InputMaybe<Order_By>
  tax_rate?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
  vat_number?: InputMaybe<Order_By>
  zip_code?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Customer_Min_Fields = {
  __typename?: 'customer_min_fields'
  address?: Maybe<Scalars['String']['output']>
  address_2?: Maybe<Scalars['String']['output']>
  agent_erp_id?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  email?: Maybe<Scalars['String']['output']>
  employee_email?: Maybe<Scalars['String']['output']>
  employee_id?: Maybe<Scalars['String']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  fiscal_code?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  invoice_discount_code?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  payment_condition_code?: Maybe<Scalars['String']['output']>
  payment_method_code?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  prize_current_revenue?: Maybe<Scalars['numeric']['output']>
  prize_current_year?: Maybe<Scalars['bigint']['output']>
  prize_return_rate_1?: Maybe<Scalars['numeric']['output']>
  prize_return_rate_2?: Maybe<Scalars['numeric']['output']>
  prize_return_rate_3?: Maybe<Scalars['numeric']['output']>
  prize_target_revenue_1?: Maybe<Scalars['numeric']['output']>
  prize_target_revenue_2?: Maybe<Scalars['numeric']['output']>
  prize_target_revenue_3?: Maybe<Scalars['numeric']['output']>
  product_price_list_code?: Maybe<Scalars['String']['output']>
  province?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  shipping_extra_cost?: Maybe<Scalars['numeric']['output']>
  shipping_method_code?: Maybe<Scalars['String']['output']>
  shipping_rate_code?: Maybe<Scalars['String']['output']>
  tax_rate?: Maybe<Scalars['numeric']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
  vat_number?: Maybe<Scalars['String']['output']>
  zip_code?: Maybe<Scalars['String']['output']>
}

/** order by min() on columns of table "customer" */
export type Customer_Min_Order_By = {
  address?: InputMaybe<Order_By>
  address_2?: InputMaybe<Order_By>
  agent_erp_id?: InputMaybe<Order_By>
  city?: InputMaybe<Order_By>
  country?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  employee_email?: InputMaybe<Order_By>
  employee_id?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  fiscal_code?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  invoice_discount_code?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  payment_condition_code?: InputMaybe<Order_By>
  payment_method_code?: InputMaybe<Order_By>
  phone?: InputMaybe<Order_By>
  prize_current_revenue?: InputMaybe<Order_By>
  prize_current_year?: InputMaybe<Order_By>
  prize_return_rate_1?: InputMaybe<Order_By>
  prize_return_rate_2?: InputMaybe<Order_By>
  prize_return_rate_3?: InputMaybe<Order_By>
  prize_target_revenue_1?: InputMaybe<Order_By>
  prize_target_revenue_2?: InputMaybe<Order_By>
  prize_target_revenue_3?: InputMaybe<Order_By>
  product_price_list_code?: InputMaybe<Order_By>
  province?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  shipping_extra_cost?: InputMaybe<Order_By>
  shipping_method_code?: InputMaybe<Order_By>
  shipping_rate_code?: InputMaybe<Order_By>
  tax_rate?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
  vat_number?: InputMaybe<Order_By>
  zip_code?: InputMaybe<Order_By>
}

/** response of any mutation on the table "customer" */
export type Customer_Mutation_Response = {
  __typename?: 'customer_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Customer>
}

/** input type for inserting object relation for remote table "customer" */
export type Customer_Obj_Rel_Insert_Input = {
  data: Customer_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_On_Conflict>
}

/** on_conflict condition type for table "customer" */
export type Customer_On_Conflict = {
  constraint: Customer_Constraint
  update_columns?: Array<Customer_Update_Column>
  where?: InputMaybe<Customer_Bool_Exp>
}

/** Ordering options when selecting data from "customer". */
export type Customer_Order_By = {
  additional_data?: InputMaybe<Customer_Additional_Data_Order_By>
  address?: InputMaybe<Order_By>
  address_2?: InputMaybe<Order_By>
  agent?: InputMaybe<Agent_Order_By>
  agent_erp_id?: InputMaybe<Order_By>
  city?: InputMaybe<Order_By>
  country?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  credit_memos_aggregate?: InputMaybe<Credit_Memo_Aggregate_Order_By>
  customer_address_dropshippings_aggregate?: InputMaybe<Customer_Address_Dropshipping_Aggregate_Order_By>
  customer_addresses_aggregate?: InputMaybe<Customer_Address_Aggregate_Order_By>
  customer_discounts_aggregate?: InputMaybe<Customer_Discount_Aggregate_Order_By>
  email?: InputMaybe<Order_By>
  employee_email?: InputMaybe<Order_By>
  employee_id?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  fiscal_code?: InputMaybe<Order_By>
  has_dropshipping?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  invoice_discount?: InputMaybe<Invoice_Discount_Order_By>
  invoice_discount_code?: InputMaybe<Order_By>
  invoices_aggregate?: InputMaybe<Invoice_Aggregate_Order_By>
  name?: InputMaybe<Order_By>
  order_confirmeds_aggregate?: InputMaybe<Order_Confirmed_Aggregate_Order_By>
  order_item_unshippeds_aggregate?: InputMaybe<Order_Item_Unshipped_Aggregate_Order_By>
  orders_aggregate?: InputMaybe<Order_Aggregate_Order_By>
  payment_condition?: InputMaybe<Payment_Condition_Order_By>
  payment_condition_code?: InputMaybe<Order_By>
  payment_method?: InputMaybe<Payment_Method_Order_By>
  payment_method_code?: InputMaybe<Order_By>
  phone?: InputMaybe<Order_By>
  prize_current_revenue?: InputMaybe<Order_By>
  prize_current_year?: InputMaybe<Order_By>
  prize_return_rate_1?: InputMaybe<Order_By>
  prize_return_rate_2?: InputMaybe<Order_By>
  prize_return_rate_3?: InputMaybe<Order_By>
  prize_target_revenue_1?: InputMaybe<Order_By>
  prize_target_revenue_2?: InputMaybe<Order_By>
  prize_target_revenue_3?: InputMaybe<Order_By>
  product_price_list?: InputMaybe<Product_Price_List_Order_By>
  product_price_list_code?: InputMaybe<Order_By>
  product_prices_aggregate?: InputMaybe<Product_Price_Aggregate_Order_By>
  province?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  shipment_documents_aggregate?: InputMaybe<Shipment_Document_Aggregate_Order_By>
  shipping_extra_cost?: InputMaybe<Order_By>
  shipping_method?: InputMaybe<Shipping_Method_Order_By>
  shipping_method_code?: InputMaybe<Order_By>
  shipping_rate?: InputMaybe<Shipping_Rate_Order_By>
  shipping_rate_code?: InputMaybe<Order_By>
  subcustomers_aggregate?: InputMaybe<Subcustomer_Aggregate_Order_By>
  tax_rate?: InputMaybe<Order_By>
  transactions_aggregate?: InputMaybe<Transaction_Aggregate_Order_By>
  updated_at?: InputMaybe<Order_By>
  user?: InputMaybe<User_Order_By>
  user_id?: InputMaybe<Order_By>
  vat_number?: InputMaybe<Order_By>
  zip_code?: InputMaybe<Order_By>
}

/** primary key columns input for table: customer */
export type Customer_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "customer" */
export enum Customer_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Address_2 = 'address_2',
  /** column name */
  AgentErpId = 'agent_erp_id',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmployeeEmail = 'employee_email',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  ErpId = 'erp_id',
  /** column name */
  FiscalCode = 'fiscal_code',
  /** column name */
  HasDropshipping = 'has_dropshipping',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceDiscountCode = 'invoice_discount_code',
  /** column name */
  Name = 'name',
  /** column name */
  PaymentConditionCode = 'payment_condition_code',
  /** column name */
  PaymentMethodCode = 'payment_method_code',
  /** column name */
  Phone = 'phone',
  /** column name */
  PrizeCurrentRevenue = 'prize_current_revenue',
  /** column name */
  PrizeCurrentYear = 'prize_current_year',
  /** column name */
  PrizeReturnRate_1 = 'prize_return_rate_1',
  /** column name */
  PrizeReturnRate_2 = 'prize_return_rate_2',
  /** column name */
  PrizeReturnRate_3 = 'prize_return_rate_3',
  /** column name */
  PrizeTargetRevenue_1 = 'prize_target_revenue_1',
  /** column name */
  PrizeTargetRevenue_2 = 'prize_target_revenue_2',
  /** column name */
  PrizeTargetRevenue_3 = 'prize_target_revenue_3',
  /** column name */
  ProductPriceListCode = 'product_price_list_code',
  /** column name */
  Province = 'province',
  /** column name */
  Rev = 'rev',
  /** column name */
  ShippingExtraCost = 'shipping_extra_cost',
  /** column name */
  ShippingMethodCode = 'shipping_method_code',
  /** column name */
  ShippingRateCode = 'shipping_rate_code',
  /** column name */
  TaxRate = 'tax_rate',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VatNumber = 'vat_number',
  /** column name */
  ZipCode = 'zip_code',
}

/** select "customer_aggregate_bool_exp_bool_and_arguments_columns" columns of table "customer" */
export enum Customer_Select_Column_Customer_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasDropshipping = 'has_dropshipping',
}

/** select "customer_aggregate_bool_exp_bool_or_arguments_columns" columns of table "customer" */
export enum Customer_Select_Column_Customer_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasDropshipping = 'has_dropshipping',
}

/** input type for updating data in table "customer" */
export type Customer_Set_Input = {
  address?: InputMaybe<Scalars['String']['input']>
  address_2?: InputMaybe<Scalars['String']['input']>
  agent_erp_id?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  employee_email?: InputMaybe<Scalars['String']['input']>
  employee_id?: InputMaybe<Scalars['String']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  fiscal_code?: InputMaybe<Scalars['String']['input']>
  has_dropshipping?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  invoice_discount_code?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  payment_condition_code?: InputMaybe<Scalars['String']['input']>
  payment_method_code?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  prize_current_revenue?: InputMaybe<Scalars['numeric']['input']>
  prize_current_year?: InputMaybe<Scalars['bigint']['input']>
  prize_return_rate_1?: InputMaybe<Scalars['numeric']['input']>
  prize_return_rate_2?: InputMaybe<Scalars['numeric']['input']>
  prize_return_rate_3?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_1?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_2?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_3?: InputMaybe<Scalars['numeric']['input']>
  product_price_list_code?: InputMaybe<Scalars['String']['input']>
  province?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  shipping_extra_cost?: InputMaybe<Scalars['numeric']['input']>
  shipping_method_code?: InputMaybe<Scalars['String']['input']>
  shipping_rate_code?: InputMaybe<Scalars['String']['input']>
  tax_rate?: InputMaybe<Scalars['numeric']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
  vat_number?: InputMaybe<Scalars['String']['input']>
  zip_code?: InputMaybe<Scalars['String']['input']>
}

/** aggregate stddev on columns */
export type Customer_Stddev_Fields = {
  __typename?: 'customer_stddev_fields'
  prize_current_revenue?: Maybe<Scalars['Float']['output']>
  prize_current_year?: Maybe<Scalars['Float']['output']>
  prize_return_rate_1?: Maybe<Scalars['Float']['output']>
  prize_return_rate_2?: Maybe<Scalars['Float']['output']>
  prize_return_rate_3?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_1?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_2?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_3?: Maybe<Scalars['Float']['output']>
  shipping_extra_cost?: Maybe<Scalars['Float']['output']>
  tax_rate?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "customer" */
export type Customer_Stddev_Order_By = {
  prize_current_revenue?: InputMaybe<Order_By>
  prize_current_year?: InputMaybe<Order_By>
  prize_return_rate_1?: InputMaybe<Order_By>
  prize_return_rate_2?: InputMaybe<Order_By>
  prize_return_rate_3?: InputMaybe<Order_By>
  prize_target_revenue_1?: InputMaybe<Order_By>
  prize_target_revenue_2?: InputMaybe<Order_By>
  prize_target_revenue_3?: InputMaybe<Order_By>
  shipping_extra_cost?: InputMaybe<Order_By>
  tax_rate?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Customer_Stddev_Pop_Fields = {
  __typename?: 'customer_stddev_pop_fields'
  prize_current_revenue?: Maybe<Scalars['Float']['output']>
  prize_current_year?: Maybe<Scalars['Float']['output']>
  prize_return_rate_1?: Maybe<Scalars['Float']['output']>
  prize_return_rate_2?: Maybe<Scalars['Float']['output']>
  prize_return_rate_3?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_1?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_2?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_3?: Maybe<Scalars['Float']['output']>
  shipping_extra_cost?: Maybe<Scalars['Float']['output']>
  tax_rate?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "customer" */
export type Customer_Stddev_Pop_Order_By = {
  prize_current_revenue?: InputMaybe<Order_By>
  prize_current_year?: InputMaybe<Order_By>
  prize_return_rate_1?: InputMaybe<Order_By>
  prize_return_rate_2?: InputMaybe<Order_By>
  prize_return_rate_3?: InputMaybe<Order_By>
  prize_target_revenue_1?: InputMaybe<Order_By>
  prize_target_revenue_2?: InputMaybe<Order_By>
  prize_target_revenue_3?: InputMaybe<Order_By>
  shipping_extra_cost?: InputMaybe<Order_By>
  tax_rate?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Customer_Stddev_Samp_Fields = {
  __typename?: 'customer_stddev_samp_fields'
  prize_current_revenue?: Maybe<Scalars['Float']['output']>
  prize_current_year?: Maybe<Scalars['Float']['output']>
  prize_return_rate_1?: Maybe<Scalars['Float']['output']>
  prize_return_rate_2?: Maybe<Scalars['Float']['output']>
  prize_return_rate_3?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_1?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_2?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_3?: Maybe<Scalars['Float']['output']>
  shipping_extra_cost?: Maybe<Scalars['Float']['output']>
  tax_rate?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "customer" */
export type Customer_Stddev_Samp_Order_By = {
  prize_current_revenue?: InputMaybe<Order_By>
  prize_current_year?: InputMaybe<Order_By>
  prize_return_rate_1?: InputMaybe<Order_By>
  prize_return_rate_2?: InputMaybe<Order_By>
  prize_return_rate_3?: InputMaybe<Order_By>
  prize_target_revenue_1?: InputMaybe<Order_By>
  prize_target_revenue_2?: InputMaybe<Order_By>
  prize_target_revenue_3?: InputMaybe<Order_By>
  shipping_extra_cost?: InputMaybe<Order_By>
  tax_rate?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "customer" */
export type Customer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Customer_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']['input']>
  address_2?: InputMaybe<Scalars['String']['input']>
  agent_erp_id?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  employee_email?: InputMaybe<Scalars['String']['input']>
  employee_id?: InputMaybe<Scalars['String']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  fiscal_code?: InputMaybe<Scalars['String']['input']>
  has_dropshipping?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  invoice_discount_code?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  payment_condition_code?: InputMaybe<Scalars['String']['input']>
  payment_method_code?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  prize_current_revenue?: InputMaybe<Scalars['numeric']['input']>
  prize_current_year?: InputMaybe<Scalars['bigint']['input']>
  prize_return_rate_1?: InputMaybe<Scalars['numeric']['input']>
  prize_return_rate_2?: InputMaybe<Scalars['numeric']['input']>
  prize_return_rate_3?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_1?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_2?: InputMaybe<Scalars['numeric']['input']>
  prize_target_revenue_3?: InputMaybe<Scalars['numeric']['input']>
  product_price_list_code?: InputMaybe<Scalars['String']['input']>
  province?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  shipping_extra_cost?: InputMaybe<Scalars['numeric']['input']>
  shipping_method_code?: InputMaybe<Scalars['String']['input']>
  shipping_rate_code?: InputMaybe<Scalars['String']['input']>
  tax_rate?: InputMaybe<Scalars['numeric']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
  vat_number?: InputMaybe<Scalars['String']['input']>
  zip_code?: InputMaybe<Scalars['String']['input']>
}

/** aggregate sum on columns */
export type Customer_Sum_Fields = {
  __typename?: 'customer_sum_fields'
  prize_current_revenue?: Maybe<Scalars['numeric']['output']>
  prize_current_year?: Maybe<Scalars['bigint']['output']>
  prize_return_rate_1?: Maybe<Scalars['numeric']['output']>
  prize_return_rate_2?: Maybe<Scalars['numeric']['output']>
  prize_return_rate_3?: Maybe<Scalars['numeric']['output']>
  prize_target_revenue_1?: Maybe<Scalars['numeric']['output']>
  prize_target_revenue_2?: Maybe<Scalars['numeric']['output']>
  prize_target_revenue_3?: Maybe<Scalars['numeric']['output']>
  shipping_extra_cost?: Maybe<Scalars['numeric']['output']>
  tax_rate?: Maybe<Scalars['numeric']['output']>
}

/** order by sum() on columns of table "customer" */
export type Customer_Sum_Order_By = {
  prize_current_revenue?: InputMaybe<Order_By>
  prize_current_year?: InputMaybe<Order_By>
  prize_return_rate_1?: InputMaybe<Order_By>
  prize_return_rate_2?: InputMaybe<Order_By>
  prize_return_rate_3?: InputMaybe<Order_By>
  prize_target_revenue_1?: InputMaybe<Order_By>
  prize_target_revenue_2?: InputMaybe<Order_By>
  prize_target_revenue_3?: InputMaybe<Order_By>
  shipping_extra_cost?: InputMaybe<Order_By>
  tax_rate?: InputMaybe<Order_By>
}

/** update columns of table "customer" */
export enum Customer_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Address_2 = 'address_2',
  /** column name */
  AgentErpId = 'agent_erp_id',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmployeeEmail = 'employee_email',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  ErpId = 'erp_id',
  /** column name */
  FiscalCode = 'fiscal_code',
  /** column name */
  HasDropshipping = 'has_dropshipping',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceDiscountCode = 'invoice_discount_code',
  /** column name */
  Name = 'name',
  /** column name */
  PaymentConditionCode = 'payment_condition_code',
  /** column name */
  PaymentMethodCode = 'payment_method_code',
  /** column name */
  Phone = 'phone',
  /** column name */
  PrizeCurrentRevenue = 'prize_current_revenue',
  /** column name */
  PrizeCurrentYear = 'prize_current_year',
  /** column name */
  PrizeReturnRate_1 = 'prize_return_rate_1',
  /** column name */
  PrizeReturnRate_2 = 'prize_return_rate_2',
  /** column name */
  PrizeReturnRate_3 = 'prize_return_rate_3',
  /** column name */
  PrizeTargetRevenue_1 = 'prize_target_revenue_1',
  /** column name */
  PrizeTargetRevenue_2 = 'prize_target_revenue_2',
  /** column name */
  PrizeTargetRevenue_3 = 'prize_target_revenue_3',
  /** column name */
  ProductPriceListCode = 'product_price_list_code',
  /** column name */
  Province = 'province',
  /** column name */
  Rev = 'rev',
  /** column name */
  ShippingExtraCost = 'shipping_extra_cost',
  /** column name */
  ShippingMethodCode = 'shipping_method_code',
  /** column name */
  ShippingRateCode = 'shipping_rate_code',
  /** column name */
  TaxRate = 'tax_rate',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VatNumber = 'vat_number',
  /** column name */
  ZipCode = 'zip_code',
}

export type Customer_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Set_Input>
  /** filter the rows which have to be updated */
  where: Customer_Bool_Exp
}

/** aggregate var_pop on columns */
export type Customer_Var_Pop_Fields = {
  __typename?: 'customer_var_pop_fields'
  prize_current_revenue?: Maybe<Scalars['Float']['output']>
  prize_current_year?: Maybe<Scalars['Float']['output']>
  prize_return_rate_1?: Maybe<Scalars['Float']['output']>
  prize_return_rate_2?: Maybe<Scalars['Float']['output']>
  prize_return_rate_3?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_1?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_2?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_3?: Maybe<Scalars['Float']['output']>
  shipping_extra_cost?: Maybe<Scalars['Float']['output']>
  tax_rate?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "customer" */
export type Customer_Var_Pop_Order_By = {
  prize_current_revenue?: InputMaybe<Order_By>
  prize_current_year?: InputMaybe<Order_By>
  prize_return_rate_1?: InputMaybe<Order_By>
  prize_return_rate_2?: InputMaybe<Order_By>
  prize_return_rate_3?: InputMaybe<Order_By>
  prize_target_revenue_1?: InputMaybe<Order_By>
  prize_target_revenue_2?: InputMaybe<Order_By>
  prize_target_revenue_3?: InputMaybe<Order_By>
  shipping_extra_cost?: InputMaybe<Order_By>
  tax_rate?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Customer_Var_Samp_Fields = {
  __typename?: 'customer_var_samp_fields'
  prize_current_revenue?: Maybe<Scalars['Float']['output']>
  prize_current_year?: Maybe<Scalars['Float']['output']>
  prize_return_rate_1?: Maybe<Scalars['Float']['output']>
  prize_return_rate_2?: Maybe<Scalars['Float']['output']>
  prize_return_rate_3?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_1?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_2?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_3?: Maybe<Scalars['Float']['output']>
  shipping_extra_cost?: Maybe<Scalars['Float']['output']>
  tax_rate?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "customer" */
export type Customer_Var_Samp_Order_By = {
  prize_current_revenue?: InputMaybe<Order_By>
  prize_current_year?: InputMaybe<Order_By>
  prize_return_rate_1?: InputMaybe<Order_By>
  prize_return_rate_2?: InputMaybe<Order_By>
  prize_return_rate_3?: InputMaybe<Order_By>
  prize_target_revenue_1?: InputMaybe<Order_By>
  prize_target_revenue_2?: InputMaybe<Order_By>
  prize_target_revenue_3?: InputMaybe<Order_By>
  shipping_extra_cost?: InputMaybe<Order_By>
  tax_rate?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Customer_Variance_Fields = {
  __typename?: 'customer_variance_fields'
  prize_current_revenue?: Maybe<Scalars['Float']['output']>
  prize_current_year?: Maybe<Scalars['Float']['output']>
  prize_return_rate_1?: Maybe<Scalars['Float']['output']>
  prize_return_rate_2?: Maybe<Scalars['Float']['output']>
  prize_return_rate_3?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_1?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_2?: Maybe<Scalars['Float']['output']>
  prize_target_revenue_3?: Maybe<Scalars['Float']['output']>
  shipping_extra_cost?: Maybe<Scalars['Float']['output']>
  tax_rate?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "customer" */
export type Customer_Variance_Order_By = {
  prize_current_revenue?: InputMaybe<Order_By>
  prize_current_year?: InputMaybe<Order_By>
  prize_return_rate_1?: InputMaybe<Order_By>
  prize_return_rate_2?: InputMaybe<Order_By>
  prize_return_rate_3?: InputMaybe<Order_By>
  prize_target_revenue_1?: InputMaybe<Order_By>
  prize_target_revenue_2?: InputMaybe<Order_By>
  prize_target_revenue_3?: InputMaybe<Order_By>
  shipping_extra_cost?: InputMaybe<Order_By>
  tax_rate?: InputMaybe<Order_By>
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']['input']>
  _gt?: InputMaybe<Scalars['date']['input']>
  _gte?: InputMaybe<Scalars['date']['input']>
  _in?: InputMaybe<Array<Scalars['date']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['date']['input']>
  _lte?: InputMaybe<Scalars['date']['input']>
  _neq?: InputMaybe<Scalars['date']['input']>
  _nin?: InputMaybe<Array<Scalars['date']['input']>>
}

/** columns and relationships of "download" */
export type Download = {
  __typename?: 'download'
  active_de: Scalars['Boolean']['output']
  active_en: Scalars['Boolean']['output']
  active_es: Scalars['Boolean']['output']
  active_fr: Scalars['Boolean']['output']
  active_it: Scalars['Boolean']['output']
  active_ru: Scalars['Boolean']['output']
  created_at: Scalars['timestamptz']['output']
  /** An object relationship */
  file: File
  file_id: Scalars['uuid']['output']
  id: Scalars['uuid']['output']
  name_de: Scalars['String']['output']
  name_en: Scalars['String']['output']
  name_es: Scalars['String']['output']
  name_fr: Scalars['String']['output']
  name_it: Scalars['String']['output']
  name_ru: Scalars['String']['output']
  position: Scalars['Int']['output']
  role: Role_Enum
  /** An object relationship */
  roleByRole: Role
  updated_at: Scalars['timestamptz']['output']
}

/** aggregated selection of "download" */
export type Download_Aggregate = {
  __typename?: 'download_aggregate'
  aggregate?: Maybe<Download_Aggregate_Fields>
  nodes: Array<Download>
}

export type Download_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Download_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<Download_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<Download_Aggregate_Bool_Exp_Count>
}

export type Download_Aggregate_Bool_Exp_Bool_And = {
  arguments: Download_Select_Column_Download_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Download_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Download_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Download_Select_Column_Download_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Download_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Download_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Download_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Download_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "download" */
export type Download_Aggregate_Fields = {
  __typename?: 'download_aggregate_fields'
  avg?: Maybe<Download_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Download_Max_Fields>
  min?: Maybe<Download_Min_Fields>
  stddev?: Maybe<Download_Stddev_Fields>
  stddev_pop?: Maybe<Download_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Download_Stddev_Samp_Fields>
  sum?: Maybe<Download_Sum_Fields>
  var_pop?: Maybe<Download_Var_Pop_Fields>
  var_samp?: Maybe<Download_Var_Samp_Fields>
  variance?: Maybe<Download_Variance_Fields>
}

/** aggregate fields of "download" */
export type Download_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Download_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "download" */
export type Download_Aggregate_Order_By = {
  avg?: InputMaybe<Download_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Download_Max_Order_By>
  min?: InputMaybe<Download_Min_Order_By>
  stddev?: InputMaybe<Download_Stddev_Order_By>
  stddev_pop?: InputMaybe<Download_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Download_Stddev_Samp_Order_By>
  sum?: InputMaybe<Download_Sum_Order_By>
  var_pop?: InputMaybe<Download_Var_Pop_Order_By>
  var_samp?: InputMaybe<Download_Var_Samp_Order_By>
  variance?: InputMaybe<Download_Variance_Order_By>
}

/** input type for inserting array relation for remote table "download" */
export type Download_Arr_Rel_Insert_Input = {
  data: Array<Download_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Download_On_Conflict>
}

/** aggregate avg on columns */
export type Download_Avg_Fields = {
  __typename?: 'download_avg_fields'
  position?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "download" */
export type Download_Avg_Order_By = {
  position?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "download". All fields are combined with a logical 'AND'. */
export type Download_Bool_Exp = {
  _and?: InputMaybe<Array<Download_Bool_Exp>>
  _not?: InputMaybe<Download_Bool_Exp>
  _or?: InputMaybe<Array<Download_Bool_Exp>>
  active_de?: InputMaybe<Boolean_Comparison_Exp>
  active_en?: InputMaybe<Boolean_Comparison_Exp>
  active_es?: InputMaybe<Boolean_Comparison_Exp>
  active_fr?: InputMaybe<Boolean_Comparison_Exp>
  active_it?: InputMaybe<Boolean_Comparison_Exp>
  active_ru?: InputMaybe<Boolean_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  file?: InputMaybe<File_Bool_Exp>
  file_id?: InputMaybe<Uuid_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  name_de?: InputMaybe<String_Comparison_Exp>
  name_en?: InputMaybe<String_Comparison_Exp>
  name_es?: InputMaybe<String_Comparison_Exp>
  name_fr?: InputMaybe<String_Comparison_Exp>
  name_it?: InputMaybe<String_Comparison_Exp>
  name_ru?: InputMaybe<String_Comparison_Exp>
  position?: InputMaybe<Int_Comparison_Exp>
  role?: InputMaybe<Role_Enum_Comparison_Exp>
  roleByRole?: InputMaybe<Role_Bool_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "download" */
export enum Download_Constraint {
  /** unique or primary key constraint on columns "id" */
  DownloadPkey = 'download_pkey',
  /** unique or primary key constraint on columns "position", "role" */
  DownloadRolePositionKey = 'download_role_position_key',
}

/** input type for incrementing numeric columns in table "download" */
export type Download_Inc_Input = {
  position?: InputMaybe<Scalars['Int']['input']>
}

/** input type for inserting data into table "download" */
export type Download_Insert_Input = {
  active_de?: InputMaybe<Scalars['Boolean']['input']>
  active_en?: InputMaybe<Scalars['Boolean']['input']>
  active_es?: InputMaybe<Scalars['Boolean']['input']>
  active_fr?: InputMaybe<Scalars['Boolean']['input']>
  active_it?: InputMaybe<Scalars['Boolean']['input']>
  active_ru?: InputMaybe<Scalars['Boolean']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  file?: InputMaybe<File_Obj_Rel_Insert_Input>
  file_id?: InputMaybe<Scalars['uuid']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name_de?: InputMaybe<Scalars['String']['input']>
  name_en?: InputMaybe<Scalars['String']['input']>
  name_es?: InputMaybe<Scalars['String']['input']>
  name_fr?: InputMaybe<Scalars['String']['input']>
  name_it?: InputMaybe<Scalars['String']['input']>
  name_ru?: InputMaybe<Scalars['String']['input']>
  position?: InputMaybe<Scalars['Int']['input']>
  role?: InputMaybe<Role_Enum>
  roleByRole?: InputMaybe<Role_Obj_Rel_Insert_Input>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Download_Max_Fields = {
  __typename?: 'download_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  file_id?: Maybe<Scalars['uuid']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  name_de?: Maybe<Scalars['String']['output']>
  name_en?: Maybe<Scalars['String']['output']>
  name_es?: Maybe<Scalars['String']['output']>
  name_fr?: Maybe<Scalars['String']['output']>
  name_it?: Maybe<Scalars['String']['output']>
  name_ru?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['Int']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "download" */
export type Download_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  file_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name_de?: InputMaybe<Order_By>
  name_en?: InputMaybe<Order_By>
  name_es?: InputMaybe<Order_By>
  name_fr?: InputMaybe<Order_By>
  name_it?: InputMaybe<Order_By>
  name_ru?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Download_Min_Fields = {
  __typename?: 'download_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  file_id?: Maybe<Scalars['uuid']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  name_de?: Maybe<Scalars['String']['output']>
  name_en?: Maybe<Scalars['String']['output']>
  name_es?: Maybe<Scalars['String']['output']>
  name_fr?: Maybe<Scalars['String']['output']>
  name_it?: Maybe<Scalars['String']['output']>
  name_ru?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['Int']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "download" */
export type Download_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  file_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name_de?: InputMaybe<Order_By>
  name_en?: InputMaybe<Order_By>
  name_es?: InputMaybe<Order_By>
  name_fr?: InputMaybe<Order_By>
  name_it?: InputMaybe<Order_By>
  name_ru?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "download" */
export type Download_Mutation_Response = {
  __typename?: 'download_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Download>
}

/** on_conflict condition type for table "download" */
export type Download_On_Conflict = {
  constraint: Download_Constraint
  update_columns?: Array<Download_Update_Column>
  where?: InputMaybe<Download_Bool_Exp>
}

/** Ordering options when selecting data from "download". */
export type Download_Order_By = {
  active_de?: InputMaybe<Order_By>
  active_en?: InputMaybe<Order_By>
  active_es?: InputMaybe<Order_By>
  active_fr?: InputMaybe<Order_By>
  active_it?: InputMaybe<Order_By>
  active_ru?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  file?: InputMaybe<File_Order_By>
  file_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name_de?: InputMaybe<Order_By>
  name_en?: InputMaybe<Order_By>
  name_es?: InputMaybe<Order_By>
  name_fr?: InputMaybe<Order_By>
  name_it?: InputMaybe<Order_By>
  name_ru?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  role?: InputMaybe<Order_By>
  roleByRole?: InputMaybe<Role_Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: download */
export type Download_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "download" */
export enum Download_Select_Column {
  /** column name */
  ActiveDe = 'active_de',
  /** column name */
  ActiveEn = 'active_en',
  /** column name */
  ActiveEs = 'active_es',
  /** column name */
  ActiveFr = 'active_fr',
  /** column name */
  ActiveIt = 'active_it',
  /** column name */
  ActiveRu = 'active_ru',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  NameDe = 'name_de',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NameEs = 'name_es',
  /** column name */
  NameFr = 'name_fr',
  /** column name */
  NameIt = 'name_it',
  /** column name */
  NameRu = 'name_ru',
  /** column name */
  Position = 'position',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "download_aggregate_bool_exp_bool_and_arguments_columns" columns of table "download" */
export enum Download_Select_Column_Download_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ActiveDe = 'active_de',
  /** column name */
  ActiveEn = 'active_en',
  /** column name */
  ActiveEs = 'active_es',
  /** column name */
  ActiveFr = 'active_fr',
  /** column name */
  ActiveIt = 'active_it',
  /** column name */
  ActiveRu = 'active_ru',
}

/** select "download_aggregate_bool_exp_bool_or_arguments_columns" columns of table "download" */
export enum Download_Select_Column_Download_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ActiveDe = 'active_de',
  /** column name */
  ActiveEn = 'active_en',
  /** column name */
  ActiveEs = 'active_es',
  /** column name */
  ActiveFr = 'active_fr',
  /** column name */
  ActiveIt = 'active_it',
  /** column name */
  ActiveRu = 'active_ru',
}

/** input type for updating data in table "download" */
export type Download_Set_Input = {
  active_de?: InputMaybe<Scalars['Boolean']['input']>
  active_en?: InputMaybe<Scalars['Boolean']['input']>
  active_es?: InputMaybe<Scalars['Boolean']['input']>
  active_fr?: InputMaybe<Scalars['Boolean']['input']>
  active_it?: InputMaybe<Scalars['Boolean']['input']>
  active_ru?: InputMaybe<Scalars['Boolean']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  file_id?: InputMaybe<Scalars['uuid']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name_de?: InputMaybe<Scalars['String']['input']>
  name_en?: InputMaybe<Scalars['String']['input']>
  name_es?: InputMaybe<Scalars['String']['input']>
  name_fr?: InputMaybe<Scalars['String']['input']>
  name_it?: InputMaybe<Scalars['String']['input']>
  name_ru?: InputMaybe<Scalars['String']['input']>
  position?: InputMaybe<Scalars['Int']['input']>
  role?: InputMaybe<Role_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate stddev on columns */
export type Download_Stddev_Fields = {
  __typename?: 'download_stddev_fields'
  position?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "download" */
export type Download_Stddev_Order_By = {
  position?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Download_Stddev_Pop_Fields = {
  __typename?: 'download_stddev_pop_fields'
  position?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "download" */
export type Download_Stddev_Pop_Order_By = {
  position?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Download_Stddev_Samp_Fields = {
  __typename?: 'download_stddev_samp_fields'
  position?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "download" */
export type Download_Stddev_Samp_Order_By = {
  position?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "download" */
export type Download_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Download_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Download_Stream_Cursor_Value_Input = {
  active_de?: InputMaybe<Scalars['Boolean']['input']>
  active_en?: InputMaybe<Scalars['Boolean']['input']>
  active_es?: InputMaybe<Scalars['Boolean']['input']>
  active_fr?: InputMaybe<Scalars['Boolean']['input']>
  active_it?: InputMaybe<Scalars['Boolean']['input']>
  active_ru?: InputMaybe<Scalars['Boolean']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  file_id?: InputMaybe<Scalars['uuid']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name_de?: InputMaybe<Scalars['String']['input']>
  name_en?: InputMaybe<Scalars['String']['input']>
  name_es?: InputMaybe<Scalars['String']['input']>
  name_fr?: InputMaybe<Scalars['String']['input']>
  name_it?: InputMaybe<Scalars['String']['input']>
  name_ru?: InputMaybe<Scalars['String']['input']>
  position?: InputMaybe<Scalars['Int']['input']>
  role?: InputMaybe<Role_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate sum on columns */
export type Download_Sum_Fields = {
  __typename?: 'download_sum_fields'
  position?: Maybe<Scalars['Int']['output']>
}

/** order by sum() on columns of table "download" */
export type Download_Sum_Order_By = {
  position?: InputMaybe<Order_By>
}

/** update columns of table "download" */
export enum Download_Update_Column {
  /** column name */
  ActiveDe = 'active_de',
  /** column name */
  ActiveEn = 'active_en',
  /** column name */
  ActiveEs = 'active_es',
  /** column name */
  ActiveFr = 'active_fr',
  /** column name */
  ActiveIt = 'active_it',
  /** column name */
  ActiveRu = 'active_ru',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  NameDe = 'name_de',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NameEs = 'name_es',
  /** column name */
  NameFr = 'name_fr',
  /** column name */
  NameIt = 'name_it',
  /** column name */
  NameRu = 'name_ru',
  /** column name */
  Position = 'position',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Download_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Download_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Download_Set_Input>
  /** filter the rows which have to be updated */
  where: Download_Bool_Exp
}

/** aggregate var_pop on columns */
export type Download_Var_Pop_Fields = {
  __typename?: 'download_var_pop_fields'
  position?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "download" */
export type Download_Var_Pop_Order_By = {
  position?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Download_Var_Samp_Fields = {
  __typename?: 'download_var_samp_fields'
  position?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "download" */
export type Download_Var_Samp_Order_By = {
  position?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Download_Variance_Fields = {
  __typename?: 'download_variance_fields'
  position?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "download" */
export type Download_Variance_Order_By = {
  position?: InputMaybe<Order_By>
}

/** columns and relationships of "exchange_rate" */
export type Exchange_Rate = {
  __typename?: 'exchange_rate'
  created_at: Scalars['timestamptz']['output']
  currency: Scalars['String']['output']
  last_update_at: Scalars['timestamptz']['output']
  rate: Scalars['numeric']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** aggregated selection of "exchange_rate" */
export type Exchange_Rate_Aggregate = {
  __typename?: 'exchange_rate_aggregate'
  aggregate?: Maybe<Exchange_Rate_Aggregate_Fields>
  nodes: Array<Exchange_Rate>
}

/** aggregate fields of "exchange_rate" */
export type Exchange_Rate_Aggregate_Fields = {
  __typename?: 'exchange_rate_aggregate_fields'
  avg?: Maybe<Exchange_Rate_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Exchange_Rate_Max_Fields>
  min?: Maybe<Exchange_Rate_Min_Fields>
  stddev?: Maybe<Exchange_Rate_Stddev_Fields>
  stddev_pop?: Maybe<Exchange_Rate_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Exchange_Rate_Stddev_Samp_Fields>
  sum?: Maybe<Exchange_Rate_Sum_Fields>
  var_pop?: Maybe<Exchange_Rate_Var_Pop_Fields>
  var_samp?: Maybe<Exchange_Rate_Var_Samp_Fields>
  variance?: Maybe<Exchange_Rate_Variance_Fields>
}

/** aggregate fields of "exchange_rate" */
export type Exchange_Rate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Exchange_Rate_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** aggregate avg on columns */
export type Exchange_Rate_Avg_Fields = {
  __typename?: 'exchange_rate_avg_fields'
  rate?: Maybe<Scalars['Float']['output']>
}

/** Boolean expression to filter rows from the table "exchange_rate". All fields are combined with a logical 'AND'. */
export type Exchange_Rate_Bool_Exp = {
  _and?: InputMaybe<Array<Exchange_Rate_Bool_Exp>>
  _not?: InputMaybe<Exchange_Rate_Bool_Exp>
  _or?: InputMaybe<Array<Exchange_Rate_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  currency?: InputMaybe<String_Comparison_Exp>
  last_update_at?: InputMaybe<Timestamptz_Comparison_Exp>
  rate?: InputMaybe<Numeric_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "exchange_rate" */
export enum Exchange_Rate_Constraint {
  /** unique or primary key constraint on columns "currency" */
  ExchangeRatePkey = 'exchange_rate_pkey',
}

/** input type for incrementing numeric columns in table "exchange_rate" */
export type Exchange_Rate_Inc_Input = {
  rate?: InputMaybe<Scalars['numeric']['input']>
}

/** input type for inserting data into table "exchange_rate" */
export type Exchange_Rate_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  currency?: InputMaybe<Scalars['String']['input']>
  last_update_at?: InputMaybe<Scalars['timestamptz']['input']>
  rate?: InputMaybe<Scalars['numeric']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Exchange_Rate_Max_Fields = {
  __typename?: 'exchange_rate_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  currency?: Maybe<Scalars['String']['output']>
  last_update_at?: Maybe<Scalars['timestamptz']['output']>
  rate?: Maybe<Scalars['numeric']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** aggregate min on columns */
export type Exchange_Rate_Min_Fields = {
  __typename?: 'exchange_rate_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  currency?: Maybe<Scalars['String']['output']>
  last_update_at?: Maybe<Scalars['timestamptz']['output']>
  rate?: Maybe<Scalars['numeric']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** response of any mutation on the table "exchange_rate" */
export type Exchange_Rate_Mutation_Response = {
  __typename?: 'exchange_rate_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Exchange_Rate>
}

/** on_conflict condition type for table "exchange_rate" */
export type Exchange_Rate_On_Conflict = {
  constraint: Exchange_Rate_Constraint
  update_columns?: Array<Exchange_Rate_Update_Column>
  where?: InputMaybe<Exchange_Rate_Bool_Exp>
}

/** Ordering options when selecting data from "exchange_rate". */
export type Exchange_Rate_Order_By = {
  created_at?: InputMaybe<Order_By>
  currency?: InputMaybe<Order_By>
  last_update_at?: InputMaybe<Order_By>
  rate?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: exchange_rate */
export type Exchange_Rate_Pk_Columns_Input = {
  currency: Scalars['String']['input']
}

/** select columns of table "exchange_rate" */
export enum Exchange_Rate_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  LastUpdateAt = 'last_update_at',
  /** column name */
  Rate = 'rate',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "exchange_rate" */
export type Exchange_Rate_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  currency?: InputMaybe<Scalars['String']['input']>
  last_update_at?: InputMaybe<Scalars['timestamptz']['input']>
  rate?: InputMaybe<Scalars['numeric']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate stddev on columns */
export type Exchange_Rate_Stddev_Fields = {
  __typename?: 'exchange_rate_stddev_fields'
  rate?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_pop on columns */
export type Exchange_Rate_Stddev_Pop_Fields = {
  __typename?: 'exchange_rate_stddev_pop_fields'
  rate?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_samp on columns */
export type Exchange_Rate_Stddev_Samp_Fields = {
  __typename?: 'exchange_rate_stddev_samp_fields'
  rate?: Maybe<Scalars['Float']['output']>
}

/** Streaming cursor of the table "exchange_rate" */
export type Exchange_Rate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Exchange_Rate_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Exchange_Rate_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  currency?: InputMaybe<Scalars['String']['input']>
  last_update_at?: InputMaybe<Scalars['timestamptz']['input']>
  rate?: InputMaybe<Scalars['numeric']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate sum on columns */
export type Exchange_Rate_Sum_Fields = {
  __typename?: 'exchange_rate_sum_fields'
  rate?: Maybe<Scalars['numeric']['output']>
}

/** update columns of table "exchange_rate" */
export enum Exchange_Rate_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  LastUpdateAt = 'last_update_at',
  /** column name */
  Rate = 'rate',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Exchange_Rate_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Exchange_Rate_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Exchange_Rate_Set_Input>
  /** filter the rows which have to be updated */
  where: Exchange_Rate_Bool_Exp
}

/** aggregate var_pop on columns */
export type Exchange_Rate_Var_Pop_Fields = {
  __typename?: 'exchange_rate_var_pop_fields'
  rate?: Maybe<Scalars['Float']['output']>
}

/** aggregate var_samp on columns */
export type Exchange_Rate_Var_Samp_Fields = {
  __typename?: 'exchange_rate_var_samp_fields'
  rate?: Maybe<Scalars['Float']['output']>
}

/** aggregate variance on columns */
export type Exchange_Rate_Variance_Fields = {
  __typename?: 'exchange_rate_variance_fields'
  rate?: Maybe<Scalars['Float']['output']>
}

/** columns and relationships of "file" */
export type File = {
  __typename?: 'file'
  created_at: Scalars['timestamptz']['output']
  /** An array relationship */
  downloads: Array<Download>
  /** An aggregate relationship */
  downloads_aggregate: Download_Aggregate
  filename: Scalars['String']['output']
  id: Scalars['uuid']['output']
  type: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
  uploaded_at: Scalars['timestamptz']['output']
  uploaded_by: Scalars['uuid']['output']
  /** An object relationship */
  user: User
}

/** columns and relationships of "file" */
export type FileDownloadsArgs = {
  distinct_on?: InputMaybe<Array<Download_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Download_Order_By>>
  where?: InputMaybe<Download_Bool_Exp>
}

/** columns and relationships of "file" */
export type FileDownloads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Download_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Download_Order_By>>
  where?: InputMaybe<Download_Bool_Exp>
}

/** aggregated selection of "file" */
export type File_Aggregate = {
  __typename?: 'file_aggregate'
  aggregate?: Maybe<File_Aggregate_Fields>
  nodes: Array<File>
}

export type File_Aggregate_Bool_Exp = {
  count?: InputMaybe<File_Aggregate_Bool_Exp_Count>
}

export type File_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<File_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<File_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "file" */
export type File_Aggregate_Fields = {
  __typename?: 'file_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<File_Max_Fields>
  min?: Maybe<File_Min_Fields>
}

/** aggregate fields of "file" */
export type File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<File_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "file" */
export type File_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<File_Max_Order_By>
  min?: InputMaybe<File_Min_Order_By>
}

/** input type for inserting array relation for remote table "file" */
export type File_Arr_Rel_Insert_Input = {
  data: Array<File_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<File_On_Conflict>
}

/** Boolean expression to filter rows from the table "file". All fields are combined with a logical 'AND'. */
export type File_Bool_Exp = {
  _and?: InputMaybe<Array<File_Bool_Exp>>
  _not?: InputMaybe<File_Bool_Exp>
  _or?: InputMaybe<Array<File_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  downloads?: InputMaybe<Download_Bool_Exp>
  downloads_aggregate?: InputMaybe<Download_Aggregate_Bool_Exp>
  filename?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  type?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  uploaded_at?: InputMaybe<Timestamptz_Comparison_Exp>
  uploaded_by?: InputMaybe<Uuid_Comparison_Exp>
  user?: InputMaybe<User_Bool_Exp>
}

/** unique or primary key constraints on table "file" */
export enum File_Constraint {
  /** unique or primary key constraint on columns "id" */
  FilePkey = 'file_pkey',
}

/** input type for inserting data into table "file" */
export type File_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  downloads?: InputMaybe<Download_Arr_Rel_Insert_Input>
  filename?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  uploaded_at?: InputMaybe<Scalars['timestamptz']['input']>
  uploaded_by?: InputMaybe<Scalars['uuid']['input']>
  user?: InputMaybe<User_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type File_Max_Fields = {
  __typename?: 'file_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  filename?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  type?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  uploaded_at?: Maybe<Scalars['timestamptz']['output']>
  uploaded_by?: Maybe<Scalars['uuid']['output']>
}

/** order by max() on columns of table "file" */
export type File_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  filename?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  uploaded_at?: InputMaybe<Order_By>
  uploaded_by?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type File_Min_Fields = {
  __typename?: 'file_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  filename?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  type?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  uploaded_at?: Maybe<Scalars['timestamptz']['output']>
  uploaded_by?: Maybe<Scalars['uuid']['output']>
}

/** order by min() on columns of table "file" */
export type File_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  filename?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  uploaded_at?: InputMaybe<Order_By>
  uploaded_by?: InputMaybe<Order_By>
}

/** response of any mutation on the table "file" */
export type File_Mutation_Response = {
  __typename?: 'file_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<File>
}

/** input type for inserting object relation for remote table "file" */
export type File_Obj_Rel_Insert_Input = {
  data: File_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<File_On_Conflict>
}

/** on_conflict condition type for table "file" */
export type File_On_Conflict = {
  constraint: File_Constraint
  update_columns?: Array<File_Update_Column>
  where?: InputMaybe<File_Bool_Exp>
}

/** Ordering options when selecting data from "file". */
export type File_Order_By = {
  created_at?: InputMaybe<Order_By>
  downloads_aggregate?: InputMaybe<Download_Aggregate_Order_By>
  filename?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  uploaded_at?: InputMaybe<Order_By>
  uploaded_by?: InputMaybe<Order_By>
  user?: InputMaybe<User_Order_By>
}

/** primary key columns input for table: file */
export type File_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "file" */
export enum File_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadedAt = 'uploaded_at',
  /** column name */
  UploadedBy = 'uploaded_by',
}

/** input type for updating data in table "file" */
export type File_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  filename?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  uploaded_at?: InputMaybe<Scalars['timestamptz']['input']>
  uploaded_by?: InputMaybe<Scalars['uuid']['input']>
}

/** Streaming cursor of the table "file" */
export type File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: File_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type File_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  filename?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  uploaded_at?: InputMaybe<Scalars['timestamptz']['input']>
  uploaded_by?: InputMaybe<Scalars['uuid']['input']>
}

/** update columns of table "file" */
export enum File_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadedAt = 'uploaded_at',
  /** column name */
  UploadedBy = 'uploaded_by',
}

export type File_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<File_Set_Input>
  /** filter the rows which have to be updated */
  where: File_Bool_Exp
}

/** columns and relationships of "invoice" */
export type Invoice = {
  __typename?: 'invoice'
  amount: Scalars['numeric']['output']
  created_at: Scalars['timestamptz']['output']
  /** An object relationship */
  customer: Customer
  customer_erp_id: Scalars['String']['output']
  date_registered: Scalars['date']['output']
  erp_id: Scalars['String']['output']
  id: Scalars['uuid']['output']
  /** An array relationship */
  invoice_overdues: Array<Invoice_Overdue>
  /** An aggregate relationship */
  invoice_overdues_aggregate: Invoice_Overdue_Aggregate
  pdf_created_at?: Maybe<Scalars['timestamptz']['output']>
  pdf_filename?: Maybe<Scalars['String']['output']>
  rev: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "invoice" */
export type InvoiceInvoice_OverduesArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Overdue_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Invoice_Overdue_Order_By>>
  where?: InputMaybe<Invoice_Overdue_Bool_Exp>
}

/** columns and relationships of "invoice" */
export type InvoiceInvoice_Overdues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Overdue_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Invoice_Overdue_Order_By>>
  where?: InputMaybe<Invoice_Overdue_Bool_Exp>
}

/** aggregated selection of "invoice" */
export type Invoice_Aggregate = {
  __typename?: 'invoice_aggregate'
  aggregate?: Maybe<Invoice_Aggregate_Fields>
  nodes: Array<Invoice>
}

export type Invoice_Aggregate_Bool_Exp = {
  count?: InputMaybe<Invoice_Aggregate_Bool_Exp_Count>
}

export type Invoice_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Invoice_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Invoice_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "invoice" */
export type Invoice_Aggregate_Fields = {
  __typename?: 'invoice_aggregate_fields'
  avg?: Maybe<Invoice_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Invoice_Max_Fields>
  min?: Maybe<Invoice_Min_Fields>
  stddev?: Maybe<Invoice_Stddev_Fields>
  stddev_pop?: Maybe<Invoice_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Invoice_Stddev_Samp_Fields>
  sum?: Maybe<Invoice_Sum_Fields>
  var_pop?: Maybe<Invoice_Var_Pop_Fields>
  var_samp?: Maybe<Invoice_Var_Samp_Fields>
  variance?: Maybe<Invoice_Variance_Fields>
}

/** aggregate fields of "invoice" */
export type Invoice_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invoice_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "invoice" */
export type Invoice_Aggregate_Order_By = {
  avg?: InputMaybe<Invoice_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Invoice_Max_Order_By>
  min?: InputMaybe<Invoice_Min_Order_By>
  stddev?: InputMaybe<Invoice_Stddev_Order_By>
  stddev_pop?: InputMaybe<Invoice_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Invoice_Stddev_Samp_Order_By>
  sum?: InputMaybe<Invoice_Sum_Order_By>
  var_pop?: InputMaybe<Invoice_Var_Pop_Order_By>
  var_samp?: InputMaybe<Invoice_Var_Samp_Order_By>
  variance?: InputMaybe<Invoice_Variance_Order_By>
}

/** input type for inserting array relation for remote table "invoice" */
export type Invoice_Arr_Rel_Insert_Input = {
  data: Array<Invoice_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Invoice_On_Conflict>
}

/** aggregate avg on columns */
export type Invoice_Avg_Fields = {
  __typename?: 'invoice_avg_fields'
  amount?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "invoice" */
export type Invoice_Avg_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "invoice". All fields are combined with a logical 'AND'. */
export type Invoice_Bool_Exp = {
  _and?: InputMaybe<Array<Invoice_Bool_Exp>>
  _not?: InputMaybe<Invoice_Bool_Exp>
  _or?: InputMaybe<Array<Invoice_Bool_Exp>>
  amount?: InputMaybe<Numeric_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customer?: InputMaybe<Customer_Bool_Exp>
  customer_erp_id?: InputMaybe<String_Comparison_Exp>
  date_registered?: InputMaybe<Date_Comparison_Exp>
  erp_id?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  invoice_overdues?: InputMaybe<Invoice_Overdue_Bool_Exp>
  invoice_overdues_aggregate?: InputMaybe<Invoice_Overdue_Aggregate_Bool_Exp>
  pdf_created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  pdf_filename?: InputMaybe<String_Comparison_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "invoice" */
export enum Invoice_Constraint {
  /** unique or primary key constraint on columns "erp_id" */
  InvoiceErpIdKey = 'invoice_erp_id_key',
  /** unique or primary key constraint on columns "id" */
  InvoicePkey = 'invoice_pkey',
}

/** columns and relationships of "invoice_discount" */
export type Invoice_Discount = {
  __typename?: 'invoice_discount'
  code: Scalars['String']['output']
  /** An array relationship */
  customers: Array<Customer>
  /** An aggregate relationship */
  customers_aggregate: Customer_Aggregate
  discount_percent: Scalars['numeric']['output']
  rev: Scalars['String']['output']
}

/** columns and relationships of "invoice_discount" */
export type Invoice_DiscountCustomersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

/** columns and relationships of "invoice_discount" */
export type Invoice_DiscountCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

/** aggregated selection of "invoice_discount" */
export type Invoice_Discount_Aggregate = {
  __typename?: 'invoice_discount_aggregate'
  aggregate?: Maybe<Invoice_Discount_Aggregate_Fields>
  nodes: Array<Invoice_Discount>
}

/** aggregate fields of "invoice_discount" */
export type Invoice_Discount_Aggregate_Fields = {
  __typename?: 'invoice_discount_aggregate_fields'
  avg?: Maybe<Invoice_Discount_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Invoice_Discount_Max_Fields>
  min?: Maybe<Invoice_Discount_Min_Fields>
  stddev?: Maybe<Invoice_Discount_Stddev_Fields>
  stddev_pop?: Maybe<Invoice_Discount_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Invoice_Discount_Stddev_Samp_Fields>
  sum?: Maybe<Invoice_Discount_Sum_Fields>
  var_pop?: Maybe<Invoice_Discount_Var_Pop_Fields>
  var_samp?: Maybe<Invoice_Discount_Var_Samp_Fields>
  variance?: Maybe<Invoice_Discount_Variance_Fields>
}

/** aggregate fields of "invoice_discount" */
export type Invoice_Discount_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invoice_Discount_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** aggregate avg on columns */
export type Invoice_Discount_Avg_Fields = {
  __typename?: 'invoice_discount_avg_fields'
  discount_percent?: Maybe<Scalars['Float']['output']>
}

/** Boolean expression to filter rows from the table "invoice_discount". All fields are combined with a logical 'AND'. */
export type Invoice_Discount_Bool_Exp = {
  _and?: InputMaybe<Array<Invoice_Discount_Bool_Exp>>
  _not?: InputMaybe<Invoice_Discount_Bool_Exp>
  _or?: InputMaybe<Array<Invoice_Discount_Bool_Exp>>
  code?: InputMaybe<String_Comparison_Exp>
  customers?: InputMaybe<Customer_Bool_Exp>
  customers_aggregate?: InputMaybe<Customer_Aggregate_Bool_Exp>
  discount_percent?: InputMaybe<Numeric_Comparison_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "invoice_discount" */
export enum Invoice_Discount_Constraint {
  /** unique or primary key constraint on columns "code" */
  InvoiceDiscountPkey = 'invoice_discount_pkey',
}

/** input type for incrementing numeric columns in table "invoice_discount" */
export type Invoice_Discount_Inc_Input = {
  discount_percent?: InputMaybe<Scalars['numeric']['input']>
}

/** input type for inserting data into table "invoice_discount" */
export type Invoice_Discount_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  customers?: InputMaybe<Customer_Arr_Rel_Insert_Input>
  discount_percent?: InputMaybe<Scalars['numeric']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Invoice_Discount_Max_Fields = {
  __typename?: 'invoice_discount_max_fields'
  code?: Maybe<Scalars['String']['output']>
  discount_percent?: Maybe<Scalars['numeric']['output']>
  rev?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Invoice_Discount_Min_Fields = {
  __typename?: 'invoice_discount_min_fields'
  code?: Maybe<Scalars['String']['output']>
  discount_percent?: Maybe<Scalars['numeric']['output']>
  rev?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "invoice_discount" */
export type Invoice_Discount_Mutation_Response = {
  __typename?: 'invoice_discount_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Invoice_Discount>
}

/** input type for inserting object relation for remote table "invoice_discount" */
export type Invoice_Discount_Obj_Rel_Insert_Input = {
  data: Invoice_Discount_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Invoice_Discount_On_Conflict>
}

/** on_conflict condition type for table "invoice_discount" */
export type Invoice_Discount_On_Conflict = {
  constraint: Invoice_Discount_Constraint
  update_columns?: Array<Invoice_Discount_Update_Column>
  where?: InputMaybe<Invoice_Discount_Bool_Exp>
}

/** Ordering options when selecting data from "invoice_discount". */
export type Invoice_Discount_Order_By = {
  code?: InputMaybe<Order_By>
  customers_aggregate?: InputMaybe<Customer_Aggregate_Order_By>
  discount_percent?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
}

/** primary key columns input for table: invoice_discount */
export type Invoice_Discount_Pk_Columns_Input = {
  code: Scalars['String']['input']
}

/** select columns of table "invoice_discount" */
export enum Invoice_Discount_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  DiscountPercent = 'discount_percent',
  /** column name */
  Rev = 'rev',
}

/** input type for updating data in table "invoice_discount" */
export type Invoice_Discount_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  discount_percent?: InputMaybe<Scalars['numeric']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
}

/** aggregate stddev on columns */
export type Invoice_Discount_Stddev_Fields = {
  __typename?: 'invoice_discount_stddev_fields'
  discount_percent?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_pop on columns */
export type Invoice_Discount_Stddev_Pop_Fields = {
  __typename?: 'invoice_discount_stddev_pop_fields'
  discount_percent?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_samp on columns */
export type Invoice_Discount_Stddev_Samp_Fields = {
  __typename?: 'invoice_discount_stddev_samp_fields'
  discount_percent?: Maybe<Scalars['Float']['output']>
}

/** Streaming cursor of the table "invoice_discount" */
export type Invoice_Discount_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Discount_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Invoice_Discount_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  discount_percent?: InputMaybe<Scalars['numeric']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
}

/** aggregate sum on columns */
export type Invoice_Discount_Sum_Fields = {
  __typename?: 'invoice_discount_sum_fields'
  discount_percent?: Maybe<Scalars['numeric']['output']>
}

/** update columns of table "invoice_discount" */
export enum Invoice_Discount_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  DiscountPercent = 'discount_percent',
  /** column name */
  Rev = 'rev',
}

export type Invoice_Discount_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Invoice_Discount_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoice_Discount_Set_Input>
  /** filter the rows which have to be updated */
  where: Invoice_Discount_Bool_Exp
}

/** aggregate var_pop on columns */
export type Invoice_Discount_Var_Pop_Fields = {
  __typename?: 'invoice_discount_var_pop_fields'
  discount_percent?: Maybe<Scalars['Float']['output']>
}

/** aggregate var_samp on columns */
export type Invoice_Discount_Var_Samp_Fields = {
  __typename?: 'invoice_discount_var_samp_fields'
  discount_percent?: Maybe<Scalars['Float']['output']>
}

/** aggregate variance on columns */
export type Invoice_Discount_Variance_Fields = {
  __typename?: 'invoice_discount_variance_fields'
  discount_percent?: Maybe<Scalars['Float']['output']>
}

/** input type for incrementing numeric columns in table "invoice" */
export type Invoice_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>
}

/** input type for inserting data into table "invoice" */
export type Invoice_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  date_registered?: InputMaybe<Scalars['date']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  invoice_overdues?: InputMaybe<Invoice_Overdue_Arr_Rel_Insert_Input>
  pdf_created_at?: InputMaybe<Scalars['timestamptz']['input']>
  pdf_filename?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Invoice_Max_Fields = {
  __typename?: 'invoice_max_fields'
  amount?: Maybe<Scalars['numeric']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  date_registered?: Maybe<Scalars['date']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  pdf_created_at?: Maybe<Scalars['timestamptz']['output']>
  pdf_filename?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "invoice" */
export type Invoice_Max_Order_By = {
  amount?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  date_registered?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  pdf_created_at?: InputMaybe<Order_By>
  pdf_filename?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Invoice_Min_Fields = {
  __typename?: 'invoice_min_fields'
  amount?: Maybe<Scalars['numeric']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  date_registered?: Maybe<Scalars['date']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  pdf_created_at?: Maybe<Scalars['timestamptz']['output']>
  pdf_filename?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "invoice" */
export type Invoice_Min_Order_By = {
  amount?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  date_registered?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  pdf_created_at?: InputMaybe<Order_By>
  pdf_filename?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "invoice" */
export type Invoice_Mutation_Response = {
  __typename?: 'invoice_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Invoice>
}

/** input type for inserting object relation for remote table "invoice" */
export type Invoice_Obj_Rel_Insert_Input = {
  data: Invoice_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Invoice_On_Conflict>
}

/** on_conflict condition type for table "invoice" */
export type Invoice_On_Conflict = {
  constraint: Invoice_Constraint
  update_columns?: Array<Invoice_Update_Column>
  where?: InputMaybe<Invoice_Bool_Exp>
}

/** Ordering options when selecting data from "invoice". */
export type Invoice_Order_By = {
  amount?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customer?: InputMaybe<Customer_Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  date_registered?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  invoice_overdues_aggregate?: InputMaybe<Invoice_Overdue_Aggregate_Order_By>
  pdf_created_at?: InputMaybe<Order_By>
  pdf_filename?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** columns and relationships of "invoice_overdue" */
export type Invoice_Overdue = {
  __typename?: 'invoice_overdue'
  amount: Scalars['numeric']['output']
  amount_expired: Scalars['numeric']['output']
  created_at: Scalars['timestamptz']['output']
  date_expire: Scalars['date']['output']
  date_registered: Scalars['date']['output']
  erp_id: Scalars['String']['output']
  id: Scalars['uuid']['output']
  /** An object relationship */
  invoice: Invoice
  invoice_erp_id: Scalars['String']['output']
  occurrence?: Maybe<Scalars['numeric']['output']>
  /** An object relationship */
  order_invoice_item?: Maybe<Order_Invoice_Item>
  rev: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** aggregated selection of "invoice_overdue" */
export type Invoice_Overdue_Aggregate = {
  __typename?: 'invoice_overdue_aggregate'
  aggregate?: Maybe<Invoice_Overdue_Aggregate_Fields>
  nodes: Array<Invoice_Overdue>
}

export type Invoice_Overdue_Aggregate_Bool_Exp = {
  count?: InputMaybe<Invoice_Overdue_Aggregate_Bool_Exp_Count>
}

export type Invoice_Overdue_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Invoice_Overdue_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Invoice_Overdue_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "invoice_overdue" */
export type Invoice_Overdue_Aggregate_Fields = {
  __typename?: 'invoice_overdue_aggregate_fields'
  avg?: Maybe<Invoice_Overdue_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Invoice_Overdue_Max_Fields>
  min?: Maybe<Invoice_Overdue_Min_Fields>
  stddev?: Maybe<Invoice_Overdue_Stddev_Fields>
  stddev_pop?: Maybe<Invoice_Overdue_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Invoice_Overdue_Stddev_Samp_Fields>
  sum?: Maybe<Invoice_Overdue_Sum_Fields>
  var_pop?: Maybe<Invoice_Overdue_Var_Pop_Fields>
  var_samp?: Maybe<Invoice_Overdue_Var_Samp_Fields>
  variance?: Maybe<Invoice_Overdue_Variance_Fields>
}

/** aggregate fields of "invoice_overdue" */
export type Invoice_Overdue_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invoice_Overdue_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "invoice_overdue" */
export type Invoice_Overdue_Aggregate_Order_By = {
  avg?: InputMaybe<Invoice_Overdue_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Invoice_Overdue_Max_Order_By>
  min?: InputMaybe<Invoice_Overdue_Min_Order_By>
  stddev?: InputMaybe<Invoice_Overdue_Stddev_Order_By>
  stddev_pop?: InputMaybe<Invoice_Overdue_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Invoice_Overdue_Stddev_Samp_Order_By>
  sum?: InputMaybe<Invoice_Overdue_Sum_Order_By>
  var_pop?: InputMaybe<Invoice_Overdue_Var_Pop_Order_By>
  var_samp?: InputMaybe<Invoice_Overdue_Var_Samp_Order_By>
  variance?: InputMaybe<Invoice_Overdue_Variance_Order_By>
}

/** input type for inserting array relation for remote table "invoice_overdue" */
export type Invoice_Overdue_Arr_Rel_Insert_Input = {
  data: Array<Invoice_Overdue_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Invoice_Overdue_On_Conflict>
}

/** aggregate avg on columns */
export type Invoice_Overdue_Avg_Fields = {
  __typename?: 'invoice_overdue_avg_fields'
  amount?: Maybe<Scalars['Float']['output']>
  amount_expired?: Maybe<Scalars['Float']['output']>
  occurrence?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "invoice_overdue" */
export type Invoice_Overdue_Avg_Order_By = {
  amount?: InputMaybe<Order_By>
  amount_expired?: InputMaybe<Order_By>
  occurrence?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "invoice_overdue". All fields are combined with a logical 'AND'. */
export type Invoice_Overdue_Bool_Exp = {
  _and?: InputMaybe<Array<Invoice_Overdue_Bool_Exp>>
  _not?: InputMaybe<Invoice_Overdue_Bool_Exp>
  _or?: InputMaybe<Array<Invoice_Overdue_Bool_Exp>>
  amount?: InputMaybe<Numeric_Comparison_Exp>
  amount_expired?: InputMaybe<Numeric_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  date_expire?: InputMaybe<Date_Comparison_Exp>
  date_registered?: InputMaybe<Date_Comparison_Exp>
  erp_id?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  invoice?: InputMaybe<Invoice_Bool_Exp>
  invoice_erp_id?: InputMaybe<String_Comparison_Exp>
  occurrence?: InputMaybe<Numeric_Comparison_Exp>
  order_invoice_item?: InputMaybe<Order_Invoice_Item_Bool_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "invoice_overdue" */
export enum Invoice_Overdue_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvoiceOverduePkey = 'invoice_overdue_pkey',
}

/** input type for incrementing numeric columns in table "invoice_overdue" */
export type Invoice_Overdue_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>
  amount_expired?: InputMaybe<Scalars['numeric']['input']>
  occurrence?: InputMaybe<Scalars['numeric']['input']>
}

/** input type for inserting data into table "invoice_overdue" */
export type Invoice_Overdue_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>
  amount_expired?: InputMaybe<Scalars['numeric']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  date_expire?: InputMaybe<Scalars['date']['input']>
  date_registered?: InputMaybe<Scalars['date']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  invoice?: InputMaybe<Invoice_Obj_Rel_Insert_Input>
  invoice_erp_id?: InputMaybe<Scalars['String']['input']>
  occurrence?: InputMaybe<Scalars['numeric']['input']>
  order_invoice_item?: InputMaybe<Order_Invoice_Item_Obj_Rel_Insert_Input>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Invoice_Overdue_Max_Fields = {
  __typename?: 'invoice_overdue_max_fields'
  amount?: Maybe<Scalars['numeric']['output']>
  amount_expired?: Maybe<Scalars['numeric']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  date_expire?: Maybe<Scalars['date']['output']>
  date_registered?: Maybe<Scalars['date']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  invoice_erp_id?: Maybe<Scalars['String']['output']>
  occurrence?: Maybe<Scalars['numeric']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "invoice_overdue" */
export type Invoice_Overdue_Max_Order_By = {
  amount?: InputMaybe<Order_By>
  amount_expired?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  date_expire?: InputMaybe<Order_By>
  date_registered?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  invoice_erp_id?: InputMaybe<Order_By>
  occurrence?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Invoice_Overdue_Min_Fields = {
  __typename?: 'invoice_overdue_min_fields'
  amount?: Maybe<Scalars['numeric']['output']>
  amount_expired?: Maybe<Scalars['numeric']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  date_expire?: Maybe<Scalars['date']['output']>
  date_registered?: Maybe<Scalars['date']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  invoice_erp_id?: Maybe<Scalars['String']['output']>
  occurrence?: Maybe<Scalars['numeric']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "invoice_overdue" */
export type Invoice_Overdue_Min_Order_By = {
  amount?: InputMaybe<Order_By>
  amount_expired?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  date_expire?: InputMaybe<Order_By>
  date_registered?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  invoice_erp_id?: InputMaybe<Order_By>
  occurrence?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "invoice_overdue" */
export type Invoice_Overdue_Mutation_Response = {
  __typename?: 'invoice_overdue_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Invoice_Overdue>
}

/** input type for inserting object relation for remote table "invoice_overdue" */
export type Invoice_Overdue_Obj_Rel_Insert_Input = {
  data: Invoice_Overdue_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Invoice_Overdue_On_Conflict>
}

/** on_conflict condition type for table "invoice_overdue" */
export type Invoice_Overdue_On_Conflict = {
  constraint: Invoice_Overdue_Constraint
  update_columns?: Array<Invoice_Overdue_Update_Column>
  where?: InputMaybe<Invoice_Overdue_Bool_Exp>
}

/** Ordering options when selecting data from "invoice_overdue". */
export type Invoice_Overdue_Order_By = {
  amount?: InputMaybe<Order_By>
  amount_expired?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  date_expire?: InputMaybe<Order_By>
  date_registered?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  invoice?: InputMaybe<Invoice_Order_By>
  invoice_erp_id?: InputMaybe<Order_By>
  occurrence?: InputMaybe<Order_By>
  order_invoice_item?: InputMaybe<Order_Invoice_Item_Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: invoice_overdue */
export type Invoice_Overdue_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "invoice_overdue" */
export enum Invoice_Overdue_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  AmountExpired = 'amount_expired',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateExpire = 'date_expire',
  /** column name */
  DateRegistered = 'date_registered',
  /** column name */
  ErpId = 'erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceErpId = 'invoice_erp_id',
  /** column name */
  Occurrence = 'occurrence',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "invoice_overdue" */
export type Invoice_Overdue_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>
  amount_expired?: InputMaybe<Scalars['numeric']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  date_expire?: InputMaybe<Scalars['date']['input']>
  date_registered?: InputMaybe<Scalars['date']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  invoice_erp_id?: InputMaybe<Scalars['String']['input']>
  occurrence?: InputMaybe<Scalars['numeric']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate stddev on columns */
export type Invoice_Overdue_Stddev_Fields = {
  __typename?: 'invoice_overdue_stddev_fields'
  amount?: Maybe<Scalars['Float']['output']>
  amount_expired?: Maybe<Scalars['Float']['output']>
  occurrence?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "invoice_overdue" */
export type Invoice_Overdue_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>
  amount_expired?: InputMaybe<Order_By>
  occurrence?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Invoice_Overdue_Stddev_Pop_Fields = {
  __typename?: 'invoice_overdue_stddev_pop_fields'
  amount?: Maybe<Scalars['Float']['output']>
  amount_expired?: Maybe<Scalars['Float']['output']>
  occurrence?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "invoice_overdue" */
export type Invoice_Overdue_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>
  amount_expired?: InputMaybe<Order_By>
  occurrence?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Invoice_Overdue_Stddev_Samp_Fields = {
  __typename?: 'invoice_overdue_stddev_samp_fields'
  amount?: Maybe<Scalars['Float']['output']>
  amount_expired?: Maybe<Scalars['Float']['output']>
  occurrence?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "invoice_overdue" */
export type Invoice_Overdue_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>
  amount_expired?: InputMaybe<Order_By>
  occurrence?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "invoice_overdue" */
export type Invoice_Overdue_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Overdue_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Invoice_Overdue_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>
  amount_expired?: InputMaybe<Scalars['numeric']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  date_expire?: InputMaybe<Scalars['date']['input']>
  date_registered?: InputMaybe<Scalars['date']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  invoice_erp_id?: InputMaybe<Scalars['String']['input']>
  occurrence?: InputMaybe<Scalars['numeric']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate sum on columns */
export type Invoice_Overdue_Sum_Fields = {
  __typename?: 'invoice_overdue_sum_fields'
  amount?: Maybe<Scalars['numeric']['output']>
  amount_expired?: Maybe<Scalars['numeric']['output']>
  occurrence?: Maybe<Scalars['numeric']['output']>
}

/** order by sum() on columns of table "invoice_overdue" */
export type Invoice_Overdue_Sum_Order_By = {
  amount?: InputMaybe<Order_By>
  amount_expired?: InputMaybe<Order_By>
  occurrence?: InputMaybe<Order_By>
}

/** update columns of table "invoice_overdue" */
export enum Invoice_Overdue_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  AmountExpired = 'amount_expired',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateExpire = 'date_expire',
  /** column name */
  DateRegistered = 'date_registered',
  /** column name */
  ErpId = 'erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceErpId = 'invoice_erp_id',
  /** column name */
  Occurrence = 'occurrence',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Invoice_Overdue_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Invoice_Overdue_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoice_Overdue_Set_Input>
  /** filter the rows which have to be updated */
  where: Invoice_Overdue_Bool_Exp
}

/** aggregate var_pop on columns */
export type Invoice_Overdue_Var_Pop_Fields = {
  __typename?: 'invoice_overdue_var_pop_fields'
  amount?: Maybe<Scalars['Float']['output']>
  amount_expired?: Maybe<Scalars['Float']['output']>
  occurrence?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "invoice_overdue" */
export type Invoice_Overdue_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>
  amount_expired?: InputMaybe<Order_By>
  occurrence?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Invoice_Overdue_Var_Samp_Fields = {
  __typename?: 'invoice_overdue_var_samp_fields'
  amount?: Maybe<Scalars['Float']['output']>
  amount_expired?: Maybe<Scalars['Float']['output']>
  occurrence?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "invoice_overdue" */
export type Invoice_Overdue_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>
  amount_expired?: InputMaybe<Order_By>
  occurrence?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Invoice_Overdue_Variance_Fields = {
  __typename?: 'invoice_overdue_variance_fields'
  amount?: Maybe<Scalars['Float']['output']>
  amount_expired?: Maybe<Scalars['Float']['output']>
  occurrence?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "invoice_overdue" */
export type Invoice_Overdue_Variance_Order_By = {
  amount?: InputMaybe<Order_By>
  amount_expired?: InputMaybe<Order_By>
  occurrence?: InputMaybe<Order_By>
}

/** primary key columns input for table: invoice */
export type Invoice_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "invoice" */
export enum Invoice_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  DateRegistered = 'date_registered',
  /** column name */
  ErpId = 'erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  PdfCreatedAt = 'pdf_created_at',
  /** column name */
  PdfFilename = 'pdf_filename',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "invoice" */
export type Invoice_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  date_registered?: InputMaybe<Scalars['date']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  pdf_created_at?: InputMaybe<Scalars['timestamptz']['input']>
  pdf_filename?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate stddev on columns */
export type Invoice_Stddev_Fields = {
  __typename?: 'invoice_stddev_fields'
  amount?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "invoice" */
export type Invoice_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Invoice_Stddev_Pop_Fields = {
  __typename?: 'invoice_stddev_pop_fields'
  amount?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "invoice" */
export type Invoice_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Invoice_Stddev_Samp_Fields = {
  __typename?: 'invoice_stddev_samp_fields'
  amount?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "invoice" */
export type Invoice_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "invoice" */
export type Invoice_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Invoice_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  date_registered?: InputMaybe<Scalars['date']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  pdf_created_at?: InputMaybe<Scalars['timestamptz']['input']>
  pdf_filename?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate sum on columns */
export type Invoice_Sum_Fields = {
  __typename?: 'invoice_sum_fields'
  amount?: Maybe<Scalars['numeric']['output']>
}

/** order by sum() on columns of table "invoice" */
export type Invoice_Sum_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** update columns of table "invoice" */
export enum Invoice_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  DateRegistered = 'date_registered',
  /** column name */
  ErpId = 'erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  PdfCreatedAt = 'pdf_created_at',
  /** column name */
  PdfFilename = 'pdf_filename',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Invoice_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Invoice_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoice_Set_Input>
  /** filter the rows which have to be updated */
  where: Invoice_Bool_Exp
}

/** aggregate var_pop on columns */
export type Invoice_Var_Pop_Fields = {
  __typename?: 'invoice_var_pop_fields'
  amount?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "invoice" */
export type Invoice_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Invoice_Var_Samp_Fields = {
  __typename?: 'invoice_var_samp_fields'
  amount?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "invoice" */
export type Invoice_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Invoice_Variance_Fields = {
  __typename?: 'invoice_variance_fields'
  amount?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "invoice" */
export type Invoice_Variance_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** columns and relationships of "invoice_view" */
export type Invoice_View = {
  __typename?: 'invoice_view'
  amount?: Maybe<Scalars['numeric']['output']>
  amount_expired_paid?: Maybe<Scalars['numeric']['output']>
  amount_expired_unpaid?: Maybe<Scalars['numeric']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  /** An object relationship */
  customer?: Maybe<Customer>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  date_registered?: Maybe<Scalars['date']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  invoice_overdue_erp_id_paid?: Maybe<Scalars['String']['output']>
  invoice_overdue_erp_id_unpaid?: Maybe<Scalars['String']['output']>
  /** An array relationship */
  invoice_overdues: Array<Invoice_Overdue>
  /** An aggregate relationship */
  invoice_overdues_aggregate: Invoice_Overdue_Aggregate
  pdf_created_at?: Maybe<Scalars['timestamptz']['output']>
  pdf_filename?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** columns and relationships of "invoice_view" */
export type Invoice_ViewInvoice_OverduesArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Overdue_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Invoice_Overdue_Order_By>>
  where?: InputMaybe<Invoice_Overdue_Bool_Exp>
}

/** columns and relationships of "invoice_view" */
export type Invoice_ViewInvoice_Overdues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Overdue_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Invoice_Overdue_Order_By>>
  where?: InputMaybe<Invoice_Overdue_Bool_Exp>
}

/** aggregated selection of "invoice_view" */
export type Invoice_View_Aggregate = {
  __typename?: 'invoice_view_aggregate'
  aggregate?: Maybe<Invoice_View_Aggregate_Fields>
  nodes: Array<Invoice_View>
}

/** aggregate fields of "invoice_view" */
export type Invoice_View_Aggregate_Fields = {
  __typename?: 'invoice_view_aggregate_fields'
  avg?: Maybe<Invoice_View_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Invoice_View_Max_Fields>
  min?: Maybe<Invoice_View_Min_Fields>
  stddev?: Maybe<Invoice_View_Stddev_Fields>
  stddev_pop?: Maybe<Invoice_View_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Invoice_View_Stddev_Samp_Fields>
  sum?: Maybe<Invoice_View_Sum_Fields>
  var_pop?: Maybe<Invoice_View_Var_Pop_Fields>
  var_samp?: Maybe<Invoice_View_Var_Samp_Fields>
  variance?: Maybe<Invoice_View_Variance_Fields>
}

/** aggregate fields of "invoice_view" */
export type Invoice_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invoice_View_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** aggregate avg on columns */
export type Invoice_View_Avg_Fields = {
  __typename?: 'invoice_view_avg_fields'
  amount?: Maybe<Scalars['Float']['output']>
  amount_expired_paid?: Maybe<Scalars['Float']['output']>
  amount_expired_unpaid?: Maybe<Scalars['Float']['output']>
}

/** Boolean expression to filter rows from the table "invoice_view". All fields are combined with a logical 'AND'. */
export type Invoice_View_Bool_Exp = {
  _and?: InputMaybe<Array<Invoice_View_Bool_Exp>>
  _not?: InputMaybe<Invoice_View_Bool_Exp>
  _or?: InputMaybe<Array<Invoice_View_Bool_Exp>>
  amount?: InputMaybe<Numeric_Comparison_Exp>
  amount_expired_paid?: InputMaybe<Numeric_Comparison_Exp>
  amount_expired_unpaid?: InputMaybe<Numeric_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customer?: InputMaybe<Customer_Bool_Exp>
  customer_erp_id?: InputMaybe<String_Comparison_Exp>
  date_registered?: InputMaybe<Date_Comparison_Exp>
  erp_id?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  invoice_overdue_erp_id_paid?: InputMaybe<String_Comparison_Exp>
  invoice_overdue_erp_id_unpaid?: InputMaybe<String_Comparison_Exp>
  invoice_overdues?: InputMaybe<Invoice_Overdue_Bool_Exp>
  invoice_overdues_aggregate?: InputMaybe<Invoice_Overdue_Aggregate_Bool_Exp>
  pdf_created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  pdf_filename?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** aggregate max on columns */
export type Invoice_View_Max_Fields = {
  __typename?: 'invoice_view_max_fields'
  amount?: Maybe<Scalars['numeric']['output']>
  amount_expired_paid?: Maybe<Scalars['numeric']['output']>
  amount_expired_unpaid?: Maybe<Scalars['numeric']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  date_registered?: Maybe<Scalars['date']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  invoice_overdue_erp_id_paid?: Maybe<Scalars['String']['output']>
  invoice_overdue_erp_id_unpaid?: Maybe<Scalars['String']['output']>
  pdf_created_at?: Maybe<Scalars['timestamptz']['output']>
  pdf_filename?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** aggregate min on columns */
export type Invoice_View_Min_Fields = {
  __typename?: 'invoice_view_min_fields'
  amount?: Maybe<Scalars['numeric']['output']>
  amount_expired_paid?: Maybe<Scalars['numeric']['output']>
  amount_expired_unpaid?: Maybe<Scalars['numeric']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  date_registered?: Maybe<Scalars['date']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  invoice_overdue_erp_id_paid?: Maybe<Scalars['String']['output']>
  invoice_overdue_erp_id_unpaid?: Maybe<Scalars['String']['output']>
  pdf_created_at?: Maybe<Scalars['timestamptz']['output']>
  pdf_filename?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** Ordering options when selecting data from "invoice_view". */
export type Invoice_View_Order_By = {
  amount?: InputMaybe<Order_By>
  amount_expired_paid?: InputMaybe<Order_By>
  amount_expired_unpaid?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customer?: InputMaybe<Customer_Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  date_registered?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  invoice_overdue_erp_id_paid?: InputMaybe<Order_By>
  invoice_overdue_erp_id_unpaid?: InputMaybe<Order_By>
  invoice_overdues_aggregate?: InputMaybe<Invoice_Overdue_Aggregate_Order_By>
  pdf_created_at?: InputMaybe<Order_By>
  pdf_filename?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** select columns of table "invoice_view" */
export enum Invoice_View_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  AmountExpiredPaid = 'amount_expired_paid',
  /** column name */
  AmountExpiredUnpaid = 'amount_expired_unpaid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  DateRegistered = 'date_registered',
  /** column name */
  ErpId = 'erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceOverdueErpIdPaid = 'invoice_overdue_erp_id_paid',
  /** column name */
  InvoiceOverdueErpIdUnpaid = 'invoice_overdue_erp_id_unpaid',
  /** column name */
  PdfCreatedAt = 'pdf_created_at',
  /** column name */
  PdfFilename = 'pdf_filename',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** aggregate stddev on columns */
export type Invoice_View_Stddev_Fields = {
  __typename?: 'invoice_view_stddev_fields'
  amount?: Maybe<Scalars['Float']['output']>
  amount_expired_paid?: Maybe<Scalars['Float']['output']>
  amount_expired_unpaid?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_pop on columns */
export type Invoice_View_Stddev_Pop_Fields = {
  __typename?: 'invoice_view_stddev_pop_fields'
  amount?: Maybe<Scalars['Float']['output']>
  amount_expired_paid?: Maybe<Scalars['Float']['output']>
  amount_expired_unpaid?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_samp on columns */
export type Invoice_View_Stddev_Samp_Fields = {
  __typename?: 'invoice_view_stddev_samp_fields'
  amount?: Maybe<Scalars['Float']['output']>
  amount_expired_paid?: Maybe<Scalars['Float']['output']>
  amount_expired_unpaid?: Maybe<Scalars['Float']['output']>
}

/** Streaming cursor of the table "invoice_view" */
export type Invoice_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_View_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Invoice_View_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>
  amount_expired_paid?: InputMaybe<Scalars['numeric']['input']>
  amount_expired_unpaid?: InputMaybe<Scalars['numeric']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  date_registered?: InputMaybe<Scalars['date']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  invoice_overdue_erp_id_paid?: InputMaybe<Scalars['String']['input']>
  invoice_overdue_erp_id_unpaid?: InputMaybe<Scalars['String']['input']>
  pdf_created_at?: InputMaybe<Scalars['timestamptz']['input']>
  pdf_filename?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate sum on columns */
export type Invoice_View_Sum_Fields = {
  __typename?: 'invoice_view_sum_fields'
  amount?: Maybe<Scalars['numeric']['output']>
  amount_expired_paid?: Maybe<Scalars['numeric']['output']>
  amount_expired_unpaid?: Maybe<Scalars['numeric']['output']>
}

/** aggregate var_pop on columns */
export type Invoice_View_Var_Pop_Fields = {
  __typename?: 'invoice_view_var_pop_fields'
  amount?: Maybe<Scalars['Float']['output']>
  amount_expired_paid?: Maybe<Scalars['Float']['output']>
  amount_expired_unpaid?: Maybe<Scalars['Float']['output']>
}

/** aggregate var_samp on columns */
export type Invoice_View_Var_Samp_Fields = {
  __typename?: 'invoice_view_var_samp_fields'
  amount?: Maybe<Scalars['Float']['output']>
  amount_expired_paid?: Maybe<Scalars['Float']['output']>
  amount_expired_unpaid?: Maybe<Scalars['Float']['output']>
}

/** aggregate variance on columns */
export type Invoice_View_Variance_Fields = {
  __typename?: 'invoice_view_variance_fields'
  amount?: Maybe<Scalars['Float']['output']>
  amount_expired_paid?: Maybe<Scalars['Float']['output']>
  amount_expired_unpaid?: Maybe<Scalars['Float']['output']>
}

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>
  _eq?: InputMaybe<Scalars['jsonb']['input']>
  _gt?: InputMaybe<Scalars['jsonb']['input']>
  _gte?: InputMaybe<Scalars['jsonb']['input']>
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['jsonb']['input']>
  _lte?: InputMaybe<Scalars['jsonb']['input']>
  _neq?: InputMaybe<Scalars['jsonb']['input']>
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>
}

/** columns and relationships of "mail" */
export type Mail = {
  __typename?: 'mail'
  /** An array relationship */
  communications: Array<Communication>
  /** An aggregate relationship */
  communications_aggregate: Communication_Aggregate
  created_at: Scalars['timestamptz']['output']
  id: Scalars['uuid']['output']
  logs?: Maybe<Scalars['jsonb']['output']>
  mail: Scalars['jsonb']['output']
  status: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "mail" */
export type MailCommunicationsArgs = {
  distinct_on?: InputMaybe<Array<Communication_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_Order_By>>
  where?: InputMaybe<Communication_Bool_Exp>
}

/** columns and relationships of "mail" */
export type MailCommunications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_Order_By>>
  where?: InputMaybe<Communication_Bool_Exp>
}

/** columns and relationships of "mail" */
export type MailLogsArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** columns and relationships of "mail" */
export type MailMailArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** aggregated selection of "mail" */
export type Mail_Aggregate = {
  __typename?: 'mail_aggregate'
  aggregate?: Maybe<Mail_Aggregate_Fields>
  nodes: Array<Mail>
}

/** aggregate fields of "mail" */
export type Mail_Aggregate_Fields = {
  __typename?: 'mail_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Mail_Max_Fields>
  min?: Maybe<Mail_Min_Fields>
}

/** aggregate fields of "mail" */
export type Mail_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mail_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Mail_Append_Input = {
  logs?: InputMaybe<Scalars['jsonb']['input']>
  mail?: InputMaybe<Scalars['jsonb']['input']>
}

/** Boolean expression to filter rows from the table "mail". All fields are combined with a logical 'AND'. */
export type Mail_Bool_Exp = {
  _and?: InputMaybe<Array<Mail_Bool_Exp>>
  _not?: InputMaybe<Mail_Bool_Exp>
  _or?: InputMaybe<Array<Mail_Bool_Exp>>
  communications?: InputMaybe<Communication_Bool_Exp>
  communications_aggregate?: InputMaybe<Communication_Aggregate_Bool_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  logs?: InputMaybe<Jsonb_Comparison_Exp>
  mail?: InputMaybe<Jsonb_Comparison_Exp>
  status?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "mail" */
export enum Mail_Constraint {
  /** unique or primary key constraint on columns "id" */
  MailPkey = 'mail_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Mail_Delete_At_Path_Input = {
  logs?: InputMaybe<Array<Scalars['String']['input']>>
  mail?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Mail_Delete_Elem_Input = {
  logs?: InputMaybe<Scalars['Int']['input']>
  mail?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Mail_Delete_Key_Input = {
  logs?: InputMaybe<Scalars['String']['input']>
  mail?: InputMaybe<Scalars['String']['input']>
}

/** input type for inserting data into table "mail" */
export type Mail_Insert_Input = {
  communications?: InputMaybe<Communication_Arr_Rel_Insert_Input>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  logs?: InputMaybe<Scalars['jsonb']['input']>
  mail?: InputMaybe<Scalars['jsonb']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Mail_Max_Fields = {
  __typename?: 'mail_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  status?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** aggregate min on columns */
export type Mail_Min_Fields = {
  __typename?: 'mail_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  status?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** response of any mutation on the table "mail" */
export type Mail_Mutation_Response = {
  __typename?: 'mail_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Mail>
}

/** input type for inserting object relation for remote table "mail" */
export type Mail_Obj_Rel_Insert_Input = {
  data: Mail_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Mail_On_Conflict>
}

/** on_conflict condition type for table "mail" */
export type Mail_On_Conflict = {
  constraint: Mail_Constraint
  update_columns?: Array<Mail_Update_Column>
  where?: InputMaybe<Mail_Bool_Exp>
}

/** Ordering options when selecting data from "mail". */
export type Mail_Order_By = {
  communications_aggregate?: InputMaybe<Communication_Aggregate_Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  logs?: InputMaybe<Order_By>
  mail?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: mail */
export type Mail_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Mail_Prepend_Input = {
  logs?: InputMaybe<Scalars['jsonb']['input']>
  mail?: InputMaybe<Scalars['jsonb']['input']>
}

/** select columns of table "mail" */
export enum Mail_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Logs = 'logs',
  /** column name */
  Mail = 'mail',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "mail" */
export type Mail_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  logs?: InputMaybe<Scalars['jsonb']['input']>
  mail?: InputMaybe<Scalars['jsonb']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "mail" */
export type Mail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mail_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Mail_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  logs?: InputMaybe<Scalars['jsonb']['input']>
  mail?: InputMaybe<Scalars['jsonb']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "mail" */
export enum Mail_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Logs = 'logs',
  /** column name */
  Mail = 'mail',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Mail_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Mail_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Mail_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Mail_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Mail_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Mail_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mail_Set_Input>
  /** filter the rows which have to be updated */
  where: Mail_Bool_Exp
}

export type Move_Download_Args = {
  download_id?: InputMaybe<Scalars['uuid']['input']>
  target_position?: InputMaybe<Scalars['Int']['input']>
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root'
  api_customer_create: ApiCustomerCreateOutput
  api_file_delete: FileDeleteOutput
  api_file_upload: FileUploadOutput
  api_insert_subcustomer: InsertSubcustomerOutput
  api_insert_user: InsertUserOutput
  api_login: LoginOutput
  api_mail_manager_retry: ApiMailManagerRetryOutput
  api_mail_manager_send: ApiMailManagerSendOutput
  api_sales_manager_cancel_order: ApiSalesManagerCancelOrderOutput
  api_sales_manager_capture_order: ApiSalesManagerCaptureOrderOutput
  api_sales_manager_cart_item_remove: ApiSalesManagerCartItemRemoveOutput
  api_sales_manager_cart_item_update: ApiSalesManagerCartItemUpdateOutput
  api_sales_manager_cart_replacement_remove: ApiSalesManagerCartReplacementRemoveOutput
  api_sales_manager_cart_replacement_update: ApiSalesManagerCartReplacementUpdateOutput
  api_sales_manager_cart_to_order: ApiSalesManagerCartToOrderOutput
  api_sales_manager_cart_update: ApiSalesManagerCartUpdateOutput
  api_sales_manager_ensure_cart: ApiSalesManagerEnsureCartOutput
  api_sales_manager_form: ApiSalesManagerFormOutput
  api_sales_manager_invoice_to_order: ApiSalesManagerInvoiceToOrderOutput
  api_sales_manager_pay: ApiSalesManagerPayOutput
  api_sales_manager_quote_to_email: ApiSalesManagerQuoteToEmailOutput
  api_sales_manager_register: ApiSalesManagerRegisterOutput
  api_sales_manager_reset_order: ApiSalesManagerResetOrderOutput
  api_sales_manager_transaction_to_order: ApiSalesManagerTransactionToOrderOutput
  api_sales_manager_update_transaction: ApiSalesManagerUpdateTransactionOutput
  api_select_customer: SelectCustomerOutput
  api_update_subcustomer: UpdateSubcustomerOutput
  api_update_user: UpdateUserOutput
  /** delete data from the table: "agent" */
  delete_agent?: Maybe<Agent_Mutation_Response>
  /** delete data from the table: "agent_bonus" */
  delete_agent_bonus?: Maybe<Agent_Bonus_Mutation_Response>
  /** delete single row from the table: "agent_bonus" */
  delete_agent_bonus_by_pk?: Maybe<Agent_Bonus>
  /** delete single row from the table: "agent" */
  delete_agent_by_pk?: Maybe<Agent>
  /** delete data from the table: "business_sector" */
  delete_business_sector?: Maybe<Business_Sector_Mutation_Response>
  /** delete single row from the table: "business_sector" */
  delete_business_sector_by_pk?: Maybe<Business_Sector>
  /** delete data from the table: "cart" */
  delete_cart?: Maybe<Cart_Mutation_Response>
  /** delete single row from the table: "cart" */
  delete_cart_by_pk?: Maybe<Cart>
  /** delete data from the table: "cart_item" */
  delete_cart_item?: Maybe<Cart_Item_Mutation_Response>
  /** delete single row from the table: "cart_item" */
  delete_cart_item_by_pk?: Maybe<Cart_Item>
  /** delete data from the table: "cart_replacement" */
  delete_cart_replacement?: Maybe<Cart_Replacement_Mutation_Response>
  /** delete single row from the table: "cart_replacement" */
  delete_cart_replacement_by_pk?: Maybe<Cart_Replacement>
  /** delete data from the table: "communication" */
  delete_communication?: Maybe<Communication_Mutation_Response>
  /** delete single row from the table: "communication" */
  delete_communication_by_pk?: Maybe<Communication>
  /** delete data from the table: "communication_changelog" */
  delete_communication_changelog?: Maybe<Communication_Changelog_Mutation_Response>
  /** delete single row from the table: "communication_changelog" */
  delete_communication_changelog_by_pk?: Maybe<Communication_Changelog>
  /** delete data from the table: "communication_code" */
  delete_communication_code?: Maybe<Communication_Code_Mutation_Response>
  /** delete single row from the table: "communication_code" */
  delete_communication_code_by_pk?: Maybe<Communication_Code>
  /** delete data from the table: "communication_external_mail" */
  delete_communication_external_mail?: Maybe<Communication_External_Mail_Mutation_Response>
  /** delete single row from the table: "communication_external_mail" */
  delete_communication_external_mail_by_pk?: Maybe<Communication_External_Mail>
  /** delete data from the table: "communication_list" */
  delete_communication_list?: Maybe<Communication_List_Mutation_Response>
  /** delete single row from the table: "communication_list" */
  delete_communication_list_by_pk?: Maybe<Communication_List>
  /** delete data from the table: "communication_settings" */
  delete_communication_settings?: Maybe<Communication_Settings_Mutation_Response>
  /** delete single row from the table: "communication_settings" */
  delete_communication_settings_by_pk?: Maybe<Communication_Settings>
  /** delete data from the table: "country_region" */
  delete_country_region?: Maybe<Country_Region_Mutation_Response>
  /** delete single row from the table: "country_region" */
  delete_country_region_by_pk?: Maybe<Country_Region>
  /** delete data from the table: "county" */
  delete_county?: Maybe<County_Mutation_Response>
  /** delete single row from the table: "county" */
  delete_county_by_pk?: Maybe<County>
  /** delete data from the table: "credit_memo" */
  delete_credit_memo?: Maybe<Credit_Memo_Mutation_Response>
  /** delete single row from the table: "credit_memo" */
  delete_credit_memo_by_pk?: Maybe<Credit_Memo>
  /** delete data from the table: "cron" */
  delete_cron?: Maybe<Cron_Mutation_Response>
  /** delete single row from the table: "cron" */
  delete_cron_by_pk?: Maybe<Cron>
  /** delete data from the table: "customer" */
  delete_customer?: Maybe<Customer_Mutation_Response>
  /** delete data from the table: "customer_additional_data" */
  delete_customer_additional_data?: Maybe<Customer_Additional_Data_Mutation_Response>
  /** delete single row from the table: "customer_additional_data" */
  delete_customer_additional_data_by_pk?: Maybe<Customer_Additional_Data>
  /** delete data from the table: "customer_address" */
  delete_customer_address?: Maybe<Customer_Address_Mutation_Response>
  /** delete single row from the table: "customer_address" */
  delete_customer_address_by_pk?: Maybe<Customer_Address>
  /** delete data from the table: "customer_address_dropshipping" */
  delete_customer_address_dropshipping?: Maybe<Customer_Address_Dropshipping_Mutation_Response>
  /** delete single row from the table: "customer_address_dropshipping" */
  delete_customer_address_dropshipping_by_pk?: Maybe<Customer_Address_Dropshipping>
  /** delete single row from the table: "customer" */
  delete_customer_by_pk?: Maybe<Customer>
  /** delete data from the table: "customer_discount" */
  delete_customer_discount?: Maybe<Customer_Discount_Mutation_Response>
  /** delete single row from the table: "customer_discount" */
  delete_customer_discount_by_pk?: Maybe<Customer_Discount>
  /** delete data from the table: "download" */
  delete_download?: Maybe<Download_Mutation_Response>
  /** delete single row from the table: "download" */
  delete_download_by_pk?: Maybe<Download>
  /** delete data from the table: "exchange_rate" */
  delete_exchange_rate?: Maybe<Exchange_Rate_Mutation_Response>
  /** delete single row from the table: "exchange_rate" */
  delete_exchange_rate_by_pk?: Maybe<Exchange_Rate>
  /** delete data from the table: "file" */
  delete_file?: Maybe<File_Mutation_Response>
  /** delete single row from the table: "file" */
  delete_file_by_pk?: Maybe<File>
  /** delete data from the table: "invoice" */
  delete_invoice?: Maybe<Invoice_Mutation_Response>
  /** delete single row from the table: "invoice" */
  delete_invoice_by_pk?: Maybe<Invoice>
  /** delete data from the table: "invoice_discount" */
  delete_invoice_discount?: Maybe<Invoice_Discount_Mutation_Response>
  /** delete single row from the table: "invoice_discount" */
  delete_invoice_discount_by_pk?: Maybe<Invoice_Discount>
  /** delete data from the table: "invoice_overdue" */
  delete_invoice_overdue?: Maybe<Invoice_Overdue_Mutation_Response>
  /** delete single row from the table: "invoice_overdue" */
  delete_invoice_overdue_by_pk?: Maybe<Invoice_Overdue>
  /** delete data from the table: "mail" */
  delete_mail?: Maybe<Mail_Mutation_Response>
  /** delete single row from the table: "mail" */
  delete_mail_by_pk?: Maybe<Mail>
  /** delete data from the table: "order" */
  delete_order?: Maybe<Order_Mutation_Response>
  /** delete data from the table: "order_by_email" */
  delete_order_by_email?: Maybe<Order_By_Email_Mutation_Response>
  /** delete single row from the table: "order_by_email" */
  delete_order_by_email_by_pk?: Maybe<Order_By_Email>
  /** delete single row from the table: "order" */
  delete_order_by_pk?: Maybe<Order>
  /** delete data from the table: "order_confirmed" */
  delete_order_confirmed?: Maybe<Order_Confirmed_Mutation_Response>
  /** delete single row from the table: "order_confirmed" */
  delete_order_confirmed_by_pk?: Maybe<Order_Confirmed>
  /** delete data from the table: "order_invoice" */
  delete_order_invoice?: Maybe<Order_Invoice_Mutation_Response>
  /** delete single row from the table: "order_invoice" */
  delete_order_invoice_by_pk?: Maybe<Order_Invoice>
  /** delete data from the table: "order_invoice_item" */
  delete_order_invoice_item?: Maybe<Order_Invoice_Item_Mutation_Response>
  /** delete single row from the table: "order_invoice_item" */
  delete_order_invoice_item_by_pk?: Maybe<Order_Invoice_Item>
  /** delete data from the table: "order_item_unshipped" */
  delete_order_item_unshipped?: Maybe<Order_Item_Unshipped_Mutation_Response>
  /** delete single row from the table: "order_item_unshipped" */
  delete_order_item_unshipped_by_pk?: Maybe<Order_Item_Unshipped>
  /** delete data from the table: "order_product" */
  delete_order_product?: Maybe<Order_Product_Mutation_Response>
  /** delete single row from the table: "order_product" */
  delete_order_product_by_pk?: Maybe<Order_Product>
  /** delete data from the table: "order_product_item" */
  delete_order_product_item?: Maybe<Order_Product_Item_Mutation_Response>
  /** delete single row from the table: "order_product_item" */
  delete_order_product_item_by_pk?: Maybe<Order_Product_Item>
  /** delete data from the table: "order_product_replacement" */
  delete_order_product_replacement?: Maybe<Order_Product_Replacement_Mutation_Response>
  /** delete single row from the table: "order_product_replacement" */
  delete_order_product_replacement_by_pk?: Maybe<Order_Product_Replacement>
  /** delete data from the table: "order_transaction" */
  delete_order_transaction?: Maybe<Order_Transaction_Mutation_Response>
  /** delete single row from the table: "order_transaction" */
  delete_order_transaction_by_pk?: Maybe<Order_Transaction>
  /** delete data from the table: "payment_condition" */
  delete_payment_condition?: Maybe<Payment_Condition_Mutation_Response>
  /** delete single row from the table: "payment_condition" */
  delete_payment_condition_by_pk?: Maybe<Payment_Condition>
  /** delete data from the table: "payment_method" */
  delete_payment_method?: Maybe<Payment_Method_Mutation_Response>
  /** delete single row from the table: "payment_method" */
  delete_payment_method_by_pk?: Maybe<Payment_Method>
  /** delete data from the table: "post_code" */
  delete_post_code?: Maybe<Post_Code_Mutation_Response>
  /** delete single row from the table: "post_code" */
  delete_post_code_by_pk?: Maybe<Post_Code>
  /** delete data from the table: "product" */
  delete_product?: Maybe<Product_Mutation_Response>
  /** delete single row from the table: "product" */
  delete_product_by_pk?: Maybe<Product>
  /** delete data from the table: "product_discount_group" */
  delete_product_discount_group?: Maybe<Product_Discount_Group_Mutation_Response>
  /** delete single row from the table: "product_discount_group" */
  delete_product_discount_group_by_pk?: Maybe<Product_Discount_Group>
  /** delete data from the table: "product_file" */
  delete_product_file?: Maybe<Product_File_Mutation_Response>
  /** delete single row from the table: "product_file" */
  delete_product_file_by_pk?: Maybe<Product_File>
  /** delete data from the table: "product_price" */
  delete_product_price?: Maybe<Product_Price_Mutation_Response>
  /** delete single row from the table: "product_price" */
  delete_product_price_by_pk?: Maybe<Product_Price>
  /** delete data from the table: "product_price_list" */
  delete_product_price_list?: Maybe<Product_Price_List_Mutation_Response>
  /** delete single row from the table: "product_price_list" */
  delete_product_price_list_by_pk?: Maybe<Product_Price_List>
  /** delete data from the table: "product_replacement" */
  delete_product_replacement?: Maybe<Product_Replacement_Mutation_Response>
  /** delete single row from the table: "product_replacement" */
  delete_product_replacement_by_pk?: Maybe<Product_Replacement>
  /** delete data from the table: "product_replacement_map" */
  delete_product_replacement_map?: Maybe<Product_Replacement_Map_Mutation_Response>
  /** delete single row from the table: "product_replacement_map" */
  delete_product_replacement_map_by_pk?: Maybe<Product_Replacement_Map>
  /** delete data from the table: "replacement" */
  delete_replacement?: Maybe<Replacement_Mutation_Response>
  /** delete single row from the table: "replacement" */
  delete_replacement_by_pk?: Maybe<Replacement>
  /** delete data from the table: "report" */
  delete_report?: Maybe<Report_Mutation_Response>
  /** delete single row from the table: "report" */
  delete_report_by_pk?: Maybe<Report>
  /** delete data from the table: "role" */
  delete_role?: Maybe<Role_Mutation_Response>
  /** delete single row from the table: "role" */
  delete_role_by_pk?: Maybe<Role>
  /** delete data from the table: "setting" */
  delete_setting?: Maybe<Setting_Mutation_Response>
  /** delete single row from the table: "setting" */
  delete_setting_by_pk?: Maybe<Setting>
  /** delete data from the table: "shipment_document" */
  delete_shipment_document?: Maybe<Shipment_Document_Mutation_Response>
  /** delete single row from the table: "shipment_document" */
  delete_shipment_document_by_pk?: Maybe<Shipment_Document>
  /** delete data from the table: "shipping_method" */
  delete_shipping_method?: Maybe<Shipping_Method_Mutation_Response>
  /** delete single row from the table: "shipping_method" */
  delete_shipping_method_by_pk?: Maybe<Shipping_Method>
  /** delete data from the table: "shipping_rate" */
  delete_shipping_rate?: Maybe<Shipping_Rate_Mutation_Response>
  /** delete single row from the table: "shipping_rate" */
  delete_shipping_rate_by_pk?: Maybe<Shipping_Rate>
  /** delete data from the table: "subcustomer" */
  delete_subcustomer?: Maybe<Subcustomer_Mutation_Response>
  /** delete single row from the table: "subcustomer" */
  delete_subcustomer_by_pk?: Maybe<Subcustomer>
  /** delete data from the table: "territory" */
  delete_territory?: Maybe<Territory_Mutation_Response>
  /** delete single row from the table: "territory" */
  delete_territory_by_pk?: Maybe<Territory>
  /** delete data from the table: "transaction" */
  delete_transaction?: Maybe<Transaction_Mutation_Response>
  /** delete single row from the table: "transaction" */
  delete_transaction_by_pk?: Maybe<Transaction>
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>
  /** insert data into the table: "agent" */
  insert_agent?: Maybe<Agent_Mutation_Response>
  /** insert data into the table: "agent_bonus" */
  insert_agent_bonus?: Maybe<Agent_Bonus_Mutation_Response>
  /** insert a single row into the table: "agent_bonus" */
  insert_agent_bonus_one?: Maybe<Agent_Bonus>
  /** insert a single row into the table: "agent" */
  insert_agent_one?: Maybe<Agent>
  /** insert data into the table: "business_sector" */
  insert_business_sector?: Maybe<Business_Sector_Mutation_Response>
  /** insert a single row into the table: "business_sector" */
  insert_business_sector_one?: Maybe<Business_Sector>
  /** insert data into the table: "cart" */
  insert_cart?: Maybe<Cart_Mutation_Response>
  /** insert data into the table: "cart_item" */
  insert_cart_item?: Maybe<Cart_Item_Mutation_Response>
  /** insert a single row into the table: "cart_item" */
  insert_cart_item_one?: Maybe<Cart_Item>
  /** insert a single row into the table: "cart" */
  insert_cart_one?: Maybe<Cart>
  /** insert data into the table: "cart_replacement" */
  insert_cart_replacement?: Maybe<Cart_Replacement_Mutation_Response>
  /** insert a single row into the table: "cart_replacement" */
  insert_cart_replacement_one?: Maybe<Cart_Replacement>
  /** insert data into the table: "communication" */
  insert_communication?: Maybe<Communication_Mutation_Response>
  /** insert data into the table: "communication_changelog" */
  insert_communication_changelog?: Maybe<Communication_Changelog_Mutation_Response>
  /** insert a single row into the table: "communication_changelog" */
  insert_communication_changelog_one?: Maybe<Communication_Changelog>
  /** insert data into the table: "communication_code" */
  insert_communication_code?: Maybe<Communication_Code_Mutation_Response>
  /** insert a single row into the table: "communication_code" */
  insert_communication_code_one?: Maybe<Communication_Code>
  /** insert data into the table: "communication_external_mail" */
  insert_communication_external_mail?: Maybe<Communication_External_Mail_Mutation_Response>
  /** insert a single row into the table: "communication_external_mail" */
  insert_communication_external_mail_one?: Maybe<Communication_External_Mail>
  /** insert data into the table: "communication_list" */
  insert_communication_list?: Maybe<Communication_List_Mutation_Response>
  /** insert a single row into the table: "communication_list" */
  insert_communication_list_one?: Maybe<Communication_List>
  /** insert a single row into the table: "communication" */
  insert_communication_one?: Maybe<Communication>
  /** insert data into the table: "communication_settings" */
  insert_communication_settings?: Maybe<Communication_Settings_Mutation_Response>
  /** insert a single row into the table: "communication_settings" */
  insert_communication_settings_one?: Maybe<Communication_Settings>
  /** insert data into the table: "country_region" */
  insert_country_region?: Maybe<Country_Region_Mutation_Response>
  /** insert a single row into the table: "country_region" */
  insert_country_region_one?: Maybe<Country_Region>
  /** insert data into the table: "county" */
  insert_county?: Maybe<County_Mutation_Response>
  /** insert a single row into the table: "county" */
  insert_county_one?: Maybe<County>
  /** insert data into the table: "credit_memo" */
  insert_credit_memo?: Maybe<Credit_Memo_Mutation_Response>
  /** insert a single row into the table: "credit_memo" */
  insert_credit_memo_one?: Maybe<Credit_Memo>
  /** insert data into the table: "cron" */
  insert_cron?: Maybe<Cron_Mutation_Response>
  /** insert a single row into the table: "cron" */
  insert_cron_one?: Maybe<Cron>
  /** insert data into the table: "customer" */
  insert_customer?: Maybe<Customer_Mutation_Response>
  /** insert data into the table: "customer_additional_data" */
  insert_customer_additional_data?: Maybe<Customer_Additional_Data_Mutation_Response>
  /** insert a single row into the table: "customer_additional_data" */
  insert_customer_additional_data_one?: Maybe<Customer_Additional_Data>
  /** insert data into the table: "customer_address" */
  insert_customer_address?: Maybe<Customer_Address_Mutation_Response>
  /** insert data into the table: "customer_address_dropshipping" */
  insert_customer_address_dropshipping?: Maybe<Customer_Address_Dropshipping_Mutation_Response>
  /** insert a single row into the table: "customer_address_dropshipping" */
  insert_customer_address_dropshipping_one?: Maybe<Customer_Address_Dropshipping>
  /** insert a single row into the table: "customer_address" */
  insert_customer_address_one?: Maybe<Customer_Address>
  /** insert data into the table: "customer_discount" */
  insert_customer_discount?: Maybe<Customer_Discount_Mutation_Response>
  /** insert a single row into the table: "customer_discount" */
  insert_customer_discount_one?: Maybe<Customer_Discount>
  /** insert a single row into the table: "customer" */
  insert_customer_one?: Maybe<Customer>
  /** insert data into the table: "download" */
  insert_download?: Maybe<Download_Mutation_Response>
  /** insert a single row into the table: "download" */
  insert_download_one?: Maybe<Download>
  /** insert data into the table: "exchange_rate" */
  insert_exchange_rate?: Maybe<Exchange_Rate_Mutation_Response>
  /** insert a single row into the table: "exchange_rate" */
  insert_exchange_rate_one?: Maybe<Exchange_Rate>
  /** insert data into the table: "file" */
  insert_file?: Maybe<File_Mutation_Response>
  /** insert a single row into the table: "file" */
  insert_file_one?: Maybe<File>
  /** insert data into the table: "invoice" */
  insert_invoice?: Maybe<Invoice_Mutation_Response>
  /** insert data into the table: "invoice_discount" */
  insert_invoice_discount?: Maybe<Invoice_Discount_Mutation_Response>
  /** insert a single row into the table: "invoice_discount" */
  insert_invoice_discount_one?: Maybe<Invoice_Discount>
  /** insert a single row into the table: "invoice" */
  insert_invoice_one?: Maybe<Invoice>
  /** insert data into the table: "invoice_overdue" */
  insert_invoice_overdue?: Maybe<Invoice_Overdue_Mutation_Response>
  /** insert a single row into the table: "invoice_overdue" */
  insert_invoice_overdue_one?: Maybe<Invoice_Overdue>
  /** insert data into the table: "mail" */
  insert_mail?: Maybe<Mail_Mutation_Response>
  /** insert a single row into the table: "mail" */
  insert_mail_one?: Maybe<Mail>
  /** insert data into the table: "order" */
  insert_order?: Maybe<Order_Mutation_Response>
  /** insert data into the table: "order_by_email" */
  insert_order_by_email?: Maybe<Order_By_Email_Mutation_Response>
  /** insert a single row into the table: "order_by_email" */
  insert_order_by_email_one?: Maybe<Order_By_Email>
  /** insert data into the table: "order_confirmed" */
  insert_order_confirmed?: Maybe<Order_Confirmed_Mutation_Response>
  /** insert a single row into the table: "order_confirmed" */
  insert_order_confirmed_one?: Maybe<Order_Confirmed>
  /** insert data into the table: "order_invoice" */
  insert_order_invoice?: Maybe<Order_Invoice_Mutation_Response>
  /** insert data into the table: "order_invoice_item" */
  insert_order_invoice_item?: Maybe<Order_Invoice_Item_Mutation_Response>
  /** insert a single row into the table: "order_invoice_item" */
  insert_order_invoice_item_one?: Maybe<Order_Invoice_Item>
  /** insert a single row into the table: "order_invoice" */
  insert_order_invoice_one?: Maybe<Order_Invoice>
  /** insert data into the table: "order_item_unshipped" */
  insert_order_item_unshipped?: Maybe<Order_Item_Unshipped_Mutation_Response>
  /** insert a single row into the table: "order_item_unshipped" */
  insert_order_item_unshipped_one?: Maybe<Order_Item_Unshipped>
  /** insert a single row into the table: "order" */
  insert_order_one?: Maybe<Order>
  /** insert data into the table: "order_product" */
  insert_order_product?: Maybe<Order_Product_Mutation_Response>
  /** insert data into the table: "order_product_item" */
  insert_order_product_item?: Maybe<Order_Product_Item_Mutation_Response>
  /** insert a single row into the table: "order_product_item" */
  insert_order_product_item_one?: Maybe<Order_Product_Item>
  /** insert a single row into the table: "order_product" */
  insert_order_product_one?: Maybe<Order_Product>
  /** insert data into the table: "order_product_replacement" */
  insert_order_product_replacement?: Maybe<Order_Product_Replacement_Mutation_Response>
  /** insert a single row into the table: "order_product_replacement" */
  insert_order_product_replacement_one?: Maybe<Order_Product_Replacement>
  /** insert data into the table: "order_transaction" */
  insert_order_transaction?: Maybe<Order_Transaction_Mutation_Response>
  /** insert a single row into the table: "order_transaction" */
  insert_order_transaction_one?: Maybe<Order_Transaction>
  /** insert data into the table: "payment_condition" */
  insert_payment_condition?: Maybe<Payment_Condition_Mutation_Response>
  /** insert a single row into the table: "payment_condition" */
  insert_payment_condition_one?: Maybe<Payment_Condition>
  /** insert data into the table: "payment_method" */
  insert_payment_method?: Maybe<Payment_Method_Mutation_Response>
  /** insert a single row into the table: "payment_method" */
  insert_payment_method_one?: Maybe<Payment_Method>
  /** insert data into the table: "post_code" */
  insert_post_code?: Maybe<Post_Code_Mutation_Response>
  /** insert a single row into the table: "post_code" */
  insert_post_code_one?: Maybe<Post_Code>
  /** insert data into the table: "product" */
  insert_product?: Maybe<Product_Mutation_Response>
  /** insert data into the table: "product_discount_group" */
  insert_product_discount_group?: Maybe<Product_Discount_Group_Mutation_Response>
  /** insert a single row into the table: "product_discount_group" */
  insert_product_discount_group_one?: Maybe<Product_Discount_Group>
  /** insert data into the table: "product_file" */
  insert_product_file?: Maybe<Product_File_Mutation_Response>
  /** insert a single row into the table: "product_file" */
  insert_product_file_one?: Maybe<Product_File>
  /** insert a single row into the table: "product" */
  insert_product_one?: Maybe<Product>
  /** insert data into the table: "product_price" */
  insert_product_price?: Maybe<Product_Price_Mutation_Response>
  /** insert data into the table: "product_price_list" */
  insert_product_price_list?: Maybe<Product_Price_List_Mutation_Response>
  /** insert a single row into the table: "product_price_list" */
  insert_product_price_list_one?: Maybe<Product_Price_List>
  /** insert a single row into the table: "product_price" */
  insert_product_price_one?: Maybe<Product_Price>
  /** insert data into the table: "product_replacement" */
  insert_product_replacement?: Maybe<Product_Replacement_Mutation_Response>
  /** insert data into the table: "product_replacement_map" */
  insert_product_replacement_map?: Maybe<Product_Replacement_Map_Mutation_Response>
  /** insert a single row into the table: "product_replacement_map" */
  insert_product_replacement_map_one?: Maybe<Product_Replacement_Map>
  /** insert a single row into the table: "product_replacement" */
  insert_product_replacement_one?: Maybe<Product_Replacement>
  /** insert data into the table: "replacement" */
  insert_replacement?: Maybe<Replacement_Mutation_Response>
  /** insert a single row into the table: "replacement" */
  insert_replacement_one?: Maybe<Replacement>
  /** insert data into the table: "report" */
  insert_report?: Maybe<Report_Mutation_Response>
  /** insert a single row into the table: "report" */
  insert_report_one?: Maybe<Report>
  /** insert data into the table: "role" */
  insert_role?: Maybe<Role_Mutation_Response>
  /** insert a single row into the table: "role" */
  insert_role_one?: Maybe<Role>
  /** insert data into the table: "setting" */
  insert_setting?: Maybe<Setting_Mutation_Response>
  /** insert a single row into the table: "setting" */
  insert_setting_one?: Maybe<Setting>
  /** insert data into the table: "shipment_document" */
  insert_shipment_document?: Maybe<Shipment_Document_Mutation_Response>
  /** insert a single row into the table: "shipment_document" */
  insert_shipment_document_one?: Maybe<Shipment_Document>
  /** insert data into the table: "shipping_method" */
  insert_shipping_method?: Maybe<Shipping_Method_Mutation_Response>
  /** insert a single row into the table: "shipping_method" */
  insert_shipping_method_one?: Maybe<Shipping_Method>
  /** insert data into the table: "shipping_rate" */
  insert_shipping_rate?: Maybe<Shipping_Rate_Mutation_Response>
  /** insert a single row into the table: "shipping_rate" */
  insert_shipping_rate_one?: Maybe<Shipping_Rate>
  /** insert data into the table: "subcustomer" */
  insert_subcustomer?: Maybe<Subcustomer_Mutation_Response>
  /** insert a single row into the table: "subcustomer" */
  insert_subcustomer_one?: Maybe<Subcustomer>
  /** insert data into the table: "territory" */
  insert_territory?: Maybe<Territory_Mutation_Response>
  /** insert a single row into the table: "territory" */
  insert_territory_one?: Maybe<Territory>
  /** insert data into the table: "transaction" */
  insert_transaction?: Maybe<Transaction_Mutation_Response>
  /** insert a single row into the table: "transaction" */
  insert_transaction_one?: Maybe<Transaction>
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>
  /** execute VOLATILE function "move_download" which returns "download" */
  move_download: Array<Download>
  /** update data of the table: "agent" */
  update_agent?: Maybe<Agent_Mutation_Response>
  /** update data of the table: "agent_bonus" */
  update_agent_bonus?: Maybe<Agent_Bonus_Mutation_Response>
  /** update single row of the table: "agent_bonus" */
  update_agent_bonus_by_pk?: Maybe<Agent_Bonus>
  /** update multiples rows of table: "agent_bonus" */
  update_agent_bonus_many?: Maybe<Array<Maybe<Agent_Bonus_Mutation_Response>>>
  /** update single row of the table: "agent" */
  update_agent_by_pk?: Maybe<Agent>
  /** update multiples rows of table: "agent" */
  update_agent_many?: Maybe<Array<Maybe<Agent_Mutation_Response>>>
  /** update data of the table: "business_sector" */
  update_business_sector?: Maybe<Business_Sector_Mutation_Response>
  /** update single row of the table: "business_sector" */
  update_business_sector_by_pk?: Maybe<Business_Sector>
  /** update multiples rows of table: "business_sector" */
  update_business_sector_many?: Maybe<
    Array<Maybe<Business_Sector_Mutation_Response>>
  >
  /** update data of the table: "cart" */
  update_cart?: Maybe<Cart_Mutation_Response>
  /** update single row of the table: "cart" */
  update_cart_by_pk?: Maybe<Cart>
  /** update data of the table: "cart_item" */
  update_cart_item?: Maybe<Cart_Item_Mutation_Response>
  /** update single row of the table: "cart_item" */
  update_cart_item_by_pk?: Maybe<Cart_Item>
  /** update multiples rows of table: "cart_item" */
  update_cart_item_many?: Maybe<Array<Maybe<Cart_Item_Mutation_Response>>>
  /** update multiples rows of table: "cart" */
  update_cart_many?: Maybe<Array<Maybe<Cart_Mutation_Response>>>
  /** update data of the table: "cart_replacement" */
  update_cart_replacement?: Maybe<Cart_Replacement_Mutation_Response>
  /** update single row of the table: "cart_replacement" */
  update_cart_replacement_by_pk?: Maybe<Cart_Replacement>
  /** update multiples rows of table: "cart_replacement" */
  update_cart_replacement_many?: Maybe<
    Array<Maybe<Cart_Replacement_Mutation_Response>>
  >
  /** update data of the table: "communication" */
  update_communication?: Maybe<Communication_Mutation_Response>
  /** update single row of the table: "communication" */
  update_communication_by_pk?: Maybe<Communication>
  /** update data of the table: "communication_changelog" */
  update_communication_changelog?: Maybe<Communication_Changelog_Mutation_Response>
  /** update single row of the table: "communication_changelog" */
  update_communication_changelog_by_pk?: Maybe<Communication_Changelog>
  /** update multiples rows of table: "communication_changelog" */
  update_communication_changelog_many?: Maybe<
    Array<Maybe<Communication_Changelog_Mutation_Response>>
  >
  /** update data of the table: "communication_code" */
  update_communication_code?: Maybe<Communication_Code_Mutation_Response>
  /** update single row of the table: "communication_code" */
  update_communication_code_by_pk?: Maybe<Communication_Code>
  /** update multiples rows of table: "communication_code" */
  update_communication_code_many?: Maybe<
    Array<Maybe<Communication_Code_Mutation_Response>>
  >
  /** update data of the table: "communication_external_mail" */
  update_communication_external_mail?: Maybe<Communication_External_Mail_Mutation_Response>
  /** update single row of the table: "communication_external_mail" */
  update_communication_external_mail_by_pk?: Maybe<Communication_External_Mail>
  /** update multiples rows of table: "communication_external_mail" */
  update_communication_external_mail_many?: Maybe<
    Array<Maybe<Communication_External_Mail_Mutation_Response>>
  >
  /** update data of the table: "communication_list" */
  update_communication_list?: Maybe<Communication_List_Mutation_Response>
  /** update single row of the table: "communication_list" */
  update_communication_list_by_pk?: Maybe<Communication_List>
  /** update multiples rows of table: "communication_list" */
  update_communication_list_many?: Maybe<
    Array<Maybe<Communication_List_Mutation_Response>>
  >
  /** update multiples rows of table: "communication" */
  update_communication_many?: Maybe<
    Array<Maybe<Communication_Mutation_Response>>
  >
  /** update data of the table: "communication_settings" */
  update_communication_settings?: Maybe<Communication_Settings_Mutation_Response>
  /** update single row of the table: "communication_settings" */
  update_communication_settings_by_pk?: Maybe<Communication_Settings>
  /** update multiples rows of table: "communication_settings" */
  update_communication_settings_many?: Maybe<
    Array<Maybe<Communication_Settings_Mutation_Response>>
  >
  /** update data of the table: "country_region" */
  update_country_region?: Maybe<Country_Region_Mutation_Response>
  /** update single row of the table: "country_region" */
  update_country_region_by_pk?: Maybe<Country_Region>
  /** update multiples rows of table: "country_region" */
  update_country_region_many?: Maybe<
    Array<Maybe<Country_Region_Mutation_Response>>
  >
  /** update data of the table: "county" */
  update_county?: Maybe<County_Mutation_Response>
  /** update single row of the table: "county" */
  update_county_by_pk?: Maybe<County>
  /** update multiples rows of table: "county" */
  update_county_many?: Maybe<Array<Maybe<County_Mutation_Response>>>
  /** update data of the table: "credit_memo" */
  update_credit_memo?: Maybe<Credit_Memo_Mutation_Response>
  /** update single row of the table: "credit_memo" */
  update_credit_memo_by_pk?: Maybe<Credit_Memo>
  /** update multiples rows of table: "credit_memo" */
  update_credit_memo_many?: Maybe<Array<Maybe<Credit_Memo_Mutation_Response>>>
  /** update data of the table: "cron" */
  update_cron?: Maybe<Cron_Mutation_Response>
  /** update single row of the table: "cron" */
  update_cron_by_pk?: Maybe<Cron>
  /** update multiples rows of table: "cron" */
  update_cron_many?: Maybe<Array<Maybe<Cron_Mutation_Response>>>
  /** update data of the table: "customer" */
  update_customer?: Maybe<Customer_Mutation_Response>
  /** update data of the table: "customer_additional_data" */
  update_customer_additional_data?: Maybe<Customer_Additional_Data_Mutation_Response>
  /** update single row of the table: "customer_additional_data" */
  update_customer_additional_data_by_pk?: Maybe<Customer_Additional_Data>
  /** update multiples rows of table: "customer_additional_data" */
  update_customer_additional_data_many?: Maybe<
    Array<Maybe<Customer_Additional_Data_Mutation_Response>>
  >
  /** update data of the table: "customer_address" */
  update_customer_address?: Maybe<Customer_Address_Mutation_Response>
  /** update single row of the table: "customer_address" */
  update_customer_address_by_pk?: Maybe<Customer_Address>
  /** update data of the table: "customer_address_dropshipping" */
  update_customer_address_dropshipping?: Maybe<Customer_Address_Dropshipping_Mutation_Response>
  /** update single row of the table: "customer_address_dropshipping" */
  update_customer_address_dropshipping_by_pk?: Maybe<Customer_Address_Dropshipping>
  /** update multiples rows of table: "customer_address_dropshipping" */
  update_customer_address_dropshipping_many?: Maybe<
    Array<Maybe<Customer_Address_Dropshipping_Mutation_Response>>
  >
  /** update multiples rows of table: "customer_address" */
  update_customer_address_many?: Maybe<
    Array<Maybe<Customer_Address_Mutation_Response>>
  >
  /** update single row of the table: "customer" */
  update_customer_by_pk?: Maybe<Customer>
  /** update data of the table: "customer_discount" */
  update_customer_discount?: Maybe<Customer_Discount_Mutation_Response>
  /** update single row of the table: "customer_discount" */
  update_customer_discount_by_pk?: Maybe<Customer_Discount>
  /** update multiples rows of table: "customer_discount" */
  update_customer_discount_many?: Maybe<
    Array<Maybe<Customer_Discount_Mutation_Response>>
  >
  /** update multiples rows of table: "customer" */
  update_customer_many?: Maybe<Array<Maybe<Customer_Mutation_Response>>>
  /** update data of the table: "download" */
  update_download?: Maybe<Download_Mutation_Response>
  /** update single row of the table: "download" */
  update_download_by_pk?: Maybe<Download>
  /** update multiples rows of table: "download" */
  update_download_many?: Maybe<Array<Maybe<Download_Mutation_Response>>>
  /** update data of the table: "exchange_rate" */
  update_exchange_rate?: Maybe<Exchange_Rate_Mutation_Response>
  /** update single row of the table: "exchange_rate" */
  update_exchange_rate_by_pk?: Maybe<Exchange_Rate>
  /** update multiples rows of table: "exchange_rate" */
  update_exchange_rate_many?: Maybe<
    Array<Maybe<Exchange_Rate_Mutation_Response>>
  >
  /** update data of the table: "file" */
  update_file?: Maybe<File_Mutation_Response>
  /** update single row of the table: "file" */
  update_file_by_pk?: Maybe<File>
  /** update multiples rows of table: "file" */
  update_file_many?: Maybe<Array<Maybe<File_Mutation_Response>>>
  /** update data of the table: "invoice" */
  update_invoice?: Maybe<Invoice_Mutation_Response>
  /** update single row of the table: "invoice" */
  update_invoice_by_pk?: Maybe<Invoice>
  /** update data of the table: "invoice_discount" */
  update_invoice_discount?: Maybe<Invoice_Discount_Mutation_Response>
  /** update single row of the table: "invoice_discount" */
  update_invoice_discount_by_pk?: Maybe<Invoice_Discount>
  /** update multiples rows of table: "invoice_discount" */
  update_invoice_discount_many?: Maybe<
    Array<Maybe<Invoice_Discount_Mutation_Response>>
  >
  /** update multiples rows of table: "invoice" */
  update_invoice_many?: Maybe<Array<Maybe<Invoice_Mutation_Response>>>
  /** update data of the table: "invoice_overdue" */
  update_invoice_overdue?: Maybe<Invoice_Overdue_Mutation_Response>
  /** update single row of the table: "invoice_overdue" */
  update_invoice_overdue_by_pk?: Maybe<Invoice_Overdue>
  /** update multiples rows of table: "invoice_overdue" */
  update_invoice_overdue_many?: Maybe<
    Array<Maybe<Invoice_Overdue_Mutation_Response>>
  >
  /** update data of the table: "mail" */
  update_mail?: Maybe<Mail_Mutation_Response>
  /** update single row of the table: "mail" */
  update_mail_by_pk?: Maybe<Mail>
  /** update multiples rows of table: "mail" */
  update_mail_many?: Maybe<Array<Maybe<Mail_Mutation_Response>>>
  /** update data of the table: "order" */
  update_order?: Maybe<Order_Mutation_Response>
  /** update data of the table: "order_by_email" */
  update_order_by_email?: Maybe<Order_By_Email_Mutation_Response>
  /** update single row of the table: "order_by_email" */
  update_order_by_email_by_pk?: Maybe<Order_By_Email>
  /** update multiples rows of table: "order_by_email" */
  update_order_by_email_many?: Maybe<
    Array<Maybe<Order_By_Email_Mutation_Response>>
  >
  /** update single row of the table: "order" */
  update_order_by_pk?: Maybe<Order>
  /** update data of the table: "order_confirmed" */
  update_order_confirmed?: Maybe<Order_Confirmed_Mutation_Response>
  /** update single row of the table: "order_confirmed" */
  update_order_confirmed_by_pk?: Maybe<Order_Confirmed>
  /** update multiples rows of table: "order_confirmed" */
  update_order_confirmed_many?: Maybe<
    Array<Maybe<Order_Confirmed_Mutation_Response>>
  >
  /** update data of the table: "order_invoice" */
  update_order_invoice?: Maybe<Order_Invoice_Mutation_Response>
  /** update single row of the table: "order_invoice" */
  update_order_invoice_by_pk?: Maybe<Order_Invoice>
  /** update data of the table: "order_invoice_item" */
  update_order_invoice_item?: Maybe<Order_Invoice_Item_Mutation_Response>
  /** update single row of the table: "order_invoice_item" */
  update_order_invoice_item_by_pk?: Maybe<Order_Invoice_Item>
  /** update multiples rows of table: "order_invoice_item" */
  update_order_invoice_item_many?: Maybe<
    Array<Maybe<Order_Invoice_Item_Mutation_Response>>
  >
  /** update multiples rows of table: "order_invoice" */
  update_order_invoice_many?: Maybe<
    Array<Maybe<Order_Invoice_Mutation_Response>>
  >
  /** update data of the table: "order_item_unshipped" */
  update_order_item_unshipped?: Maybe<Order_Item_Unshipped_Mutation_Response>
  /** update single row of the table: "order_item_unshipped" */
  update_order_item_unshipped_by_pk?: Maybe<Order_Item_Unshipped>
  /** update multiples rows of table: "order_item_unshipped" */
  update_order_item_unshipped_many?: Maybe<
    Array<Maybe<Order_Item_Unshipped_Mutation_Response>>
  >
  /** update multiples rows of table: "order" */
  update_order_many?: Maybe<Array<Maybe<Order_Mutation_Response>>>
  /** update data of the table: "order_product" */
  update_order_product?: Maybe<Order_Product_Mutation_Response>
  /** update single row of the table: "order_product" */
  update_order_product_by_pk?: Maybe<Order_Product>
  /** update data of the table: "order_product_item" */
  update_order_product_item?: Maybe<Order_Product_Item_Mutation_Response>
  /** update single row of the table: "order_product_item" */
  update_order_product_item_by_pk?: Maybe<Order_Product_Item>
  /** update multiples rows of table: "order_product_item" */
  update_order_product_item_many?: Maybe<
    Array<Maybe<Order_Product_Item_Mutation_Response>>
  >
  /** update multiples rows of table: "order_product" */
  update_order_product_many?: Maybe<
    Array<Maybe<Order_Product_Mutation_Response>>
  >
  /** update data of the table: "order_product_replacement" */
  update_order_product_replacement?: Maybe<Order_Product_Replacement_Mutation_Response>
  /** update single row of the table: "order_product_replacement" */
  update_order_product_replacement_by_pk?: Maybe<Order_Product_Replacement>
  /** update multiples rows of table: "order_product_replacement" */
  update_order_product_replacement_many?: Maybe<
    Array<Maybe<Order_Product_Replacement_Mutation_Response>>
  >
  /** update data of the table: "order_transaction" */
  update_order_transaction?: Maybe<Order_Transaction_Mutation_Response>
  /** update single row of the table: "order_transaction" */
  update_order_transaction_by_pk?: Maybe<Order_Transaction>
  /** update multiples rows of table: "order_transaction" */
  update_order_transaction_many?: Maybe<
    Array<Maybe<Order_Transaction_Mutation_Response>>
  >
  /** update data of the table: "payment_condition" */
  update_payment_condition?: Maybe<Payment_Condition_Mutation_Response>
  /** update single row of the table: "payment_condition" */
  update_payment_condition_by_pk?: Maybe<Payment_Condition>
  /** update multiples rows of table: "payment_condition" */
  update_payment_condition_many?: Maybe<
    Array<Maybe<Payment_Condition_Mutation_Response>>
  >
  /** update data of the table: "payment_method" */
  update_payment_method?: Maybe<Payment_Method_Mutation_Response>
  /** update single row of the table: "payment_method" */
  update_payment_method_by_pk?: Maybe<Payment_Method>
  /** update multiples rows of table: "payment_method" */
  update_payment_method_many?: Maybe<
    Array<Maybe<Payment_Method_Mutation_Response>>
  >
  /** update data of the table: "post_code" */
  update_post_code?: Maybe<Post_Code_Mutation_Response>
  /** update single row of the table: "post_code" */
  update_post_code_by_pk?: Maybe<Post_Code>
  /** update multiples rows of table: "post_code" */
  update_post_code_many?: Maybe<Array<Maybe<Post_Code_Mutation_Response>>>
  /** update data of the table: "product" */
  update_product?: Maybe<Product_Mutation_Response>
  /** update single row of the table: "product" */
  update_product_by_pk?: Maybe<Product>
  /** update data of the table: "product_discount_group" */
  update_product_discount_group?: Maybe<Product_Discount_Group_Mutation_Response>
  /** update single row of the table: "product_discount_group" */
  update_product_discount_group_by_pk?: Maybe<Product_Discount_Group>
  /** update multiples rows of table: "product_discount_group" */
  update_product_discount_group_many?: Maybe<
    Array<Maybe<Product_Discount_Group_Mutation_Response>>
  >
  /** update data of the table: "product_file" */
  update_product_file?: Maybe<Product_File_Mutation_Response>
  /** update single row of the table: "product_file" */
  update_product_file_by_pk?: Maybe<Product_File>
  /** update multiples rows of table: "product_file" */
  update_product_file_many?: Maybe<Array<Maybe<Product_File_Mutation_Response>>>
  /** update multiples rows of table: "product" */
  update_product_many?: Maybe<Array<Maybe<Product_Mutation_Response>>>
  /** update data of the table: "product_price" */
  update_product_price?: Maybe<Product_Price_Mutation_Response>
  /** update single row of the table: "product_price" */
  update_product_price_by_pk?: Maybe<Product_Price>
  /** update data of the table: "product_price_list" */
  update_product_price_list?: Maybe<Product_Price_List_Mutation_Response>
  /** update single row of the table: "product_price_list" */
  update_product_price_list_by_pk?: Maybe<Product_Price_List>
  /** update multiples rows of table: "product_price_list" */
  update_product_price_list_many?: Maybe<
    Array<Maybe<Product_Price_List_Mutation_Response>>
  >
  /** update multiples rows of table: "product_price" */
  update_product_price_many?: Maybe<
    Array<Maybe<Product_Price_Mutation_Response>>
  >
  /** update data of the table: "product_replacement" */
  update_product_replacement?: Maybe<Product_Replacement_Mutation_Response>
  /** update single row of the table: "product_replacement" */
  update_product_replacement_by_pk?: Maybe<Product_Replacement>
  /** update multiples rows of table: "product_replacement" */
  update_product_replacement_many?: Maybe<
    Array<Maybe<Product_Replacement_Mutation_Response>>
  >
  /** update data of the table: "product_replacement_map" */
  update_product_replacement_map?: Maybe<Product_Replacement_Map_Mutation_Response>
  /** update single row of the table: "product_replacement_map" */
  update_product_replacement_map_by_pk?: Maybe<Product_Replacement_Map>
  /** update multiples rows of table: "product_replacement_map" */
  update_product_replacement_map_many?: Maybe<
    Array<Maybe<Product_Replacement_Map_Mutation_Response>>
  >
  /** update data of the table: "replacement" */
  update_replacement?: Maybe<Replacement_Mutation_Response>
  /** update single row of the table: "replacement" */
  update_replacement_by_pk?: Maybe<Replacement>
  /** update multiples rows of table: "replacement" */
  update_replacement_many?: Maybe<Array<Maybe<Replacement_Mutation_Response>>>
  /** update data of the table: "report" */
  update_report?: Maybe<Report_Mutation_Response>
  /** update single row of the table: "report" */
  update_report_by_pk?: Maybe<Report>
  /** update multiples rows of table: "report" */
  update_report_many?: Maybe<Array<Maybe<Report_Mutation_Response>>>
  /** update data of the table: "role" */
  update_role?: Maybe<Role_Mutation_Response>
  /** update single row of the table: "role" */
  update_role_by_pk?: Maybe<Role>
  /** update multiples rows of table: "role" */
  update_role_many?: Maybe<Array<Maybe<Role_Mutation_Response>>>
  /** update data of the table: "setting" */
  update_setting?: Maybe<Setting_Mutation_Response>
  /** update single row of the table: "setting" */
  update_setting_by_pk?: Maybe<Setting>
  /** update multiples rows of table: "setting" */
  update_setting_many?: Maybe<Array<Maybe<Setting_Mutation_Response>>>
  /** update data of the table: "shipment_document" */
  update_shipment_document?: Maybe<Shipment_Document_Mutation_Response>
  /** update single row of the table: "shipment_document" */
  update_shipment_document_by_pk?: Maybe<Shipment_Document>
  /** update multiples rows of table: "shipment_document" */
  update_shipment_document_many?: Maybe<
    Array<Maybe<Shipment_Document_Mutation_Response>>
  >
  /** update data of the table: "shipping_method" */
  update_shipping_method?: Maybe<Shipping_Method_Mutation_Response>
  /** update single row of the table: "shipping_method" */
  update_shipping_method_by_pk?: Maybe<Shipping_Method>
  /** update multiples rows of table: "shipping_method" */
  update_shipping_method_many?: Maybe<
    Array<Maybe<Shipping_Method_Mutation_Response>>
  >
  /** update data of the table: "shipping_rate" */
  update_shipping_rate?: Maybe<Shipping_Rate_Mutation_Response>
  /** update single row of the table: "shipping_rate" */
  update_shipping_rate_by_pk?: Maybe<Shipping_Rate>
  /** update multiples rows of table: "shipping_rate" */
  update_shipping_rate_many?: Maybe<
    Array<Maybe<Shipping_Rate_Mutation_Response>>
  >
  /** update data of the table: "subcustomer" */
  update_subcustomer?: Maybe<Subcustomer_Mutation_Response>
  /** update single row of the table: "subcustomer" */
  update_subcustomer_by_pk?: Maybe<Subcustomer>
  /** update multiples rows of table: "subcustomer" */
  update_subcustomer_many?: Maybe<Array<Maybe<Subcustomer_Mutation_Response>>>
  /** update data of the table: "territory" */
  update_territory?: Maybe<Territory_Mutation_Response>
  /** update single row of the table: "territory" */
  update_territory_by_pk?: Maybe<Territory>
  /** update multiples rows of table: "territory" */
  update_territory_many?: Maybe<Array<Maybe<Territory_Mutation_Response>>>
  /** update data of the table: "transaction" */
  update_transaction?: Maybe<Transaction_Mutation_Response>
  /** update single row of the table: "transaction" */
  update_transaction_by_pk?: Maybe<Transaction>
  /** update multiples rows of table: "transaction" */
  update_transaction_many?: Maybe<Array<Maybe<Transaction_Mutation_Response>>>
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>
}

/** mutation root */
export type Mutation_RootApi_Customer_CreateArgs = {
  customer: ApiCustomerCreateCustomerInput
}

/** mutation root */
export type Mutation_RootApi_File_DeleteArgs = {
  data: FileDeleteDataInput
}

/** mutation root */
export type Mutation_RootApi_File_UploadArgs = {
  data: FileUploadDataInput
}

/** mutation root */
export type Mutation_RootApi_Insert_SubcustomerArgs = {
  customer_erp_id: Scalars['String']['input']
  login: Scalars['String']['input']
  password: Scalars['String']['input']
  user_id?: InputMaybe<Scalars['String']['input']>
}

/** mutation root */
export type Mutation_RootApi_Insert_UserArgs = {
  login: Scalars['String']['input']
  password: Scalars['String']['input']
  role: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootApi_LoginArgs = {
  login: Scalars['String']['input']
  password: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootApi_Mail_Manager_RetryArgs = {
  id: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootApi_Mail_Manager_SendArgs = {
  mail: ApiMailManagerMailInput
}

/** mutation root */
export type Mutation_RootApi_Sales_Manager_Cancel_OrderArgs = {
  data: ApiSalesManagerCancelOrderDataInput
}

/** mutation root */
export type Mutation_RootApi_Sales_Manager_Capture_OrderArgs = {
  data: ApiSalesManagerCaptureOrderDataInput
}

/** mutation root */
export type Mutation_RootApi_Sales_Manager_Cart_Item_RemoveArgs = {
  data: ApiSalesManagerCartItemRemoveDataInput
}

/** mutation root */
export type Mutation_RootApi_Sales_Manager_Cart_Item_UpdateArgs = {
  data: ApiSalesManagerCartItemUpdateDataInput
}

/** mutation root */
export type Mutation_RootApi_Sales_Manager_Cart_Replacement_RemoveArgs = {
  data: ApiSalesManagerCartReplacementRemoveDataInput
}

/** mutation root */
export type Mutation_RootApi_Sales_Manager_Cart_Replacement_UpdateArgs = {
  data: ApiSalesManagerCartReplacementUpdateDataInput
}

/** mutation root */
export type Mutation_RootApi_Sales_Manager_Cart_To_OrderArgs = {
  data: ApiSalesManagerCartToOrderDataInput
}

/** mutation root */
export type Mutation_RootApi_Sales_Manager_Cart_UpdateArgs = {
  data: ApiSalesManagerCartUpdateDataInput
}

/** mutation root */
export type Mutation_RootApi_Sales_Manager_Ensure_CartArgs = {
  data: ApiSalesManagerCartEnsureDataInput
}

/** mutation root */
export type Mutation_RootApi_Sales_Manager_FormArgs = {
  data: ApiSalesManagerFormDataInput
}

/** mutation root */
export type Mutation_RootApi_Sales_Manager_Invoice_To_OrderArgs = {
  data: ApiSalesManagerInvoiceToOrderDataInput
}

/** mutation root */
export type Mutation_RootApi_Sales_Manager_PayArgs = {
  data: ApiSalesManagerPayDataInput
}

/** mutation root */
export type Mutation_RootApi_Sales_Manager_Quote_To_EmailArgs = {
  endCustomerMode: Scalars['Boolean']['input']
  id: Scalars['String']['input']
  locale: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootApi_Sales_Manager_RegisterArgs = {
  data: ApiSalesManagerRegisterDataInput
}

/** mutation root */
export type Mutation_RootApi_Sales_Manager_Reset_OrderArgs = {
  data: ApiSalesManagerResetOrderDataInput
}

/** mutation root */
export type Mutation_RootApi_Sales_Manager_Transaction_To_OrderArgs = {
  data: ApiSalesManagerTransactionToOrderDataInput
}

/** mutation root */
export type Mutation_RootApi_Sales_Manager_Update_TransactionArgs = {
  data: ApiSalesManagerUpdateTransactionDataInput
}

/** mutation root */
export type Mutation_RootApi_Select_CustomerArgs = {
  id: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootApi_Update_SubcustomerArgs = {
  customer_erp_id: Scalars['String']['input']
  id: Scalars['String']['input']
  login: Scalars['String']['input']
  password: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootApi_Update_UserArgs = {
  id: Scalars['String']['input']
  login: Scalars['String']['input']
  password: Scalars['String']['input']
  role: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_AgentArgs = {
  where: Agent_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Agent_BonusArgs = {
  where: Agent_Bonus_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Agent_Bonus_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Agent_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Business_SectorArgs = {
  where: Business_Sector_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Business_Sector_By_PkArgs = {
  code: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_CartArgs = {
  where: Cart_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Cart_By_PkArgs = {
  id: Scalars['bigint']['input']
}

/** mutation root */
export type Mutation_RootDelete_Cart_ItemArgs = {
  where: Cart_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Cart_Item_By_PkArgs = {
  id: Scalars['bigint']['input']
}

/** mutation root */
export type Mutation_RootDelete_Cart_ReplacementArgs = {
  where: Cart_Replacement_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Cart_Replacement_By_PkArgs = {
  id: Scalars['bigint']['input']
}

/** mutation root */
export type Mutation_RootDelete_CommunicationArgs = {
  where: Communication_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Communication_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Communication_ChangelogArgs = {
  where: Communication_Changelog_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Communication_Changelog_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Communication_CodeArgs = {
  where: Communication_Code_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Communication_Code_By_PkArgs = {
  code: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_Communication_External_MailArgs = {
  where: Communication_External_Mail_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Communication_External_Mail_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Communication_ListArgs = {
  where: Communication_List_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Communication_List_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Communication_SettingsArgs = {
  where: Communication_Settings_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Communication_Settings_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Country_RegionArgs = {
  where: Country_Region_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Country_Region_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_CountyArgs = {
  where: County_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_County_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Credit_MemoArgs = {
  where: Credit_Memo_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Credit_Memo_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_CronArgs = {
  where: Cron_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Cron_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_CustomerArgs = {
  where: Customer_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Customer_Additional_DataArgs = {
  where: Customer_Additional_Data_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Customer_Additional_Data_By_PkArgs = {
  customer_erp_id: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_Customer_AddressArgs = {
  where: Customer_Address_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Customer_Address_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Customer_Address_DropshippingArgs = {
  where: Customer_Address_Dropshipping_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Customer_Address_Dropshipping_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Customer_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Customer_DiscountArgs = {
  where: Customer_Discount_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Customer_Discount_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_DownloadArgs = {
  where: Download_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Download_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Exchange_RateArgs = {
  where: Exchange_Rate_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Exchange_Rate_By_PkArgs = {
  currency: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_FileArgs = {
  where: File_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_File_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_InvoiceArgs = {
  where: Invoice_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Invoice_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Invoice_DiscountArgs = {
  where: Invoice_Discount_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Invoice_Discount_By_PkArgs = {
  code: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_Invoice_OverdueArgs = {
  where: Invoice_Overdue_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Invoice_Overdue_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_MailArgs = {
  where: Mail_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Mail_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_OrderArgs = {
  where: Order_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Order_By_EmailArgs = {
  where: Order_By_Email_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Order_By_Email_By_PkArgs = {
  id: Scalars['Int']['input']
}

/** mutation root */
export type Mutation_RootDelete_Order_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Order_ConfirmedArgs = {
  where: Order_Confirmed_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Order_Confirmed_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Order_InvoiceArgs = {
  where: Order_Invoice_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Order_Invoice_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Order_Invoice_ItemArgs = {
  where: Order_Invoice_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Order_Invoice_Item_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Order_Item_UnshippedArgs = {
  where: Order_Item_Unshipped_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Order_Item_Unshipped_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Order_ProductArgs = {
  where: Order_Product_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Order_Product_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Order_Product_ItemArgs = {
  where: Order_Product_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Order_Product_Item_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Order_Product_ReplacementArgs = {
  where: Order_Product_Replacement_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Order_Product_Replacement_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Order_TransactionArgs = {
  where: Order_Transaction_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Order_Transaction_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Payment_ConditionArgs = {
  where: Payment_Condition_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Payment_Condition_By_PkArgs = {
  code: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_Payment_MethodArgs = {
  where: Payment_Method_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Payment_Method_By_PkArgs = {
  code: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_Post_CodeArgs = {
  where: Post_Code_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Post_Code_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_ProductArgs = {
  where: Product_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Product_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Product_Discount_GroupArgs = {
  where: Product_Discount_Group_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Product_Discount_Group_By_PkArgs = {
  code: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_Product_FileArgs = {
  where: Product_File_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Product_File_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Product_PriceArgs = {
  where: Product_Price_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Product_Price_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Product_Price_ListArgs = {
  where: Product_Price_List_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Product_Price_List_By_PkArgs = {
  code: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_Product_ReplacementArgs = {
  where: Product_Replacement_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Product_Replacement_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Product_Replacement_MapArgs = {
  where: Product_Replacement_Map_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Product_Replacement_Map_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_ReplacementArgs = {
  where: Replacement_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Replacement_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_ReportArgs = {
  where: Report_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Report_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_RoleArgs = {
  where: Role_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Role_By_PkArgs = {
  name: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_SettingArgs = {
  where: Setting_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Setting_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Shipment_DocumentArgs = {
  where: Shipment_Document_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shipment_Document_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Shipping_MethodArgs = {
  where: Shipping_Method_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shipping_Method_By_PkArgs = {
  code: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_Shipping_RateArgs = {
  where: Shipping_Rate_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shipping_Rate_By_PkArgs = {
  code: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_SubcustomerArgs = {
  where: Subcustomer_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Subcustomer_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_TerritoryArgs = {
  where: Territory_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Territory_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_TransactionArgs = {
  where: Transaction_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Transaction_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootInsert_AgentArgs = {
  objects: Array<Agent_Insert_Input>
  on_conflict?: InputMaybe<Agent_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Agent_BonusArgs = {
  objects: Array<Agent_Bonus_Insert_Input>
  on_conflict?: InputMaybe<Agent_Bonus_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Agent_Bonus_OneArgs = {
  object: Agent_Bonus_Insert_Input
  on_conflict?: InputMaybe<Agent_Bonus_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Agent_OneArgs = {
  object: Agent_Insert_Input
  on_conflict?: InputMaybe<Agent_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Business_SectorArgs = {
  objects: Array<Business_Sector_Insert_Input>
  on_conflict?: InputMaybe<Business_Sector_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Business_Sector_OneArgs = {
  object: Business_Sector_Insert_Input
  on_conflict?: InputMaybe<Business_Sector_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_CartArgs = {
  objects: Array<Cart_Insert_Input>
  on_conflict?: InputMaybe<Cart_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Cart_ItemArgs = {
  objects: Array<Cart_Item_Insert_Input>
  on_conflict?: InputMaybe<Cart_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Cart_Item_OneArgs = {
  object: Cart_Item_Insert_Input
  on_conflict?: InputMaybe<Cart_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Cart_OneArgs = {
  object: Cart_Insert_Input
  on_conflict?: InputMaybe<Cart_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Cart_ReplacementArgs = {
  objects: Array<Cart_Replacement_Insert_Input>
  on_conflict?: InputMaybe<Cart_Replacement_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Cart_Replacement_OneArgs = {
  object: Cart_Replacement_Insert_Input
  on_conflict?: InputMaybe<Cart_Replacement_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_CommunicationArgs = {
  objects: Array<Communication_Insert_Input>
  on_conflict?: InputMaybe<Communication_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Communication_ChangelogArgs = {
  objects: Array<Communication_Changelog_Insert_Input>
  on_conflict?: InputMaybe<Communication_Changelog_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Communication_Changelog_OneArgs = {
  object: Communication_Changelog_Insert_Input
  on_conflict?: InputMaybe<Communication_Changelog_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Communication_CodeArgs = {
  objects: Array<Communication_Code_Insert_Input>
  on_conflict?: InputMaybe<Communication_Code_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Communication_Code_OneArgs = {
  object: Communication_Code_Insert_Input
  on_conflict?: InputMaybe<Communication_Code_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Communication_External_MailArgs = {
  objects: Array<Communication_External_Mail_Insert_Input>
  on_conflict?: InputMaybe<Communication_External_Mail_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Communication_External_Mail_OneArgs = {
  object: Communication_External_Mail_Insert_Input
  on_conflict?: InputMaybe<Communication_External_Mail_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Communication_ListArgs = {
  objects: Array<Communication_List_Insert_Input>
  on_conflict?: InputMaybe<Communication_List_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Communication_List_OneArgs = {
  object: Communication_List_Insert_Input
  on_conflict?: InputMaybe<Communication_List_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Communication_OneArgs = {
  object: Communication_Insert_Input
  on_conflict?: InputMaybe<Communication_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Communication_SettingsArgs = {
  objects: Array<Communication_Settings_Insert_Input>
  on_conflict?: InputMaybe<Communication_Settings_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Communication_Settings_OneArgs = {
  object: Communication_Settings_Insert_Input
  on_conflict?: InputMaybe<Communication_Settings_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Country_RegionArgs = {
  objects: Array<Country_Region_Insert_Input>
  on_conflict?: InputMaybe<Country_Region_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Country_Region_OneArgs = {
  object: Country_Region_Insert_Input
  on_conflict?: InputMaybe<Country_Region_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_CountyArgs = {
  objects: Array<County_Insert_Input>
  on_conflict?: InputMaybe<County_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_County_OneArgs = {
  object: County_Insert_Input
  on_conflict?: InputMaybe<County_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Credit_MemoArgs = {
  objects: Array<Credit_Memo_Insert_Input>
  on_conflict?: InputMaybe<Credit_Memo_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Credit_Memo_OneArgs = {
  object: Credit_Memo_Insert_Input
  on_conflict?: InputMaybe<Credit_Memo_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_CronArgs = {
  objects: Array<Cron_Insert_Input>
  on_conflict?: InputMaybe<Cron_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Cron_OneArgs = {
  object: Cron_Insert_Input
  on_conflict?: InputMaybe<Cron_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_CustomerArgs = {
  objects: Array<Customer_Insert_Input>
  on_conflict?: InputMaybe<Customer_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Customer_Additional_DataArgs = {
  objects: Array<Customer_Additional_Data_Insert_Input>
  on_conflict?: InputMaybe<Customer_Additional_Data_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Customer_Additional_Data_OneArgs = {
  object: Customer_Additional_Data_Insert_Input
  on_conflict?: InputMaybe<Customer_Additional_Data_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Customer_AddressArgs = {
  objects: Array<Customer_Address_Insert_Input>
  on_conflict?: InputMaybe<Customer_Address_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Customer_Address_DropshippingArgs = {
  objects: Array<Customer_Address_Dropshipping_Insert_Input>
  on_conflict?: InputMaybe<Customer_Address_Dropshipping_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Customer_Address_Dropshipping_OneArgs = {
  object: Customer_Address_Dropshipping_Insert_Input
  on_conflict?: InputMaybe<Customer_Address_Dropshipping_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Customer_Address_OneArgs = {
  object: Customer_Address_Insert_Input
  on_conflict?: InputMaybe<Customer_Address_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Customer_DiscountArgs = {
  objects: Array<Customer_Discount_Insert_Input>
  on_conflict?: InputMaybe<Customer_Discount_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Customer_Discount_OneArgs = {
  object: Customer_Discount_Insert_Input
  on_conflict?: InputMaybe<Customer_Discount_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Customer_OneArgs = {
  object: Customer_Insert_Input
  on_conflict?: InputMaybe<Customer_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_DownloadArgs = {
  objects: Array<Download_Insert_Input>
  on_conflict?: InputMaybe<Download_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Download_OneArgs = {
  object: Download_Insert_Input
  on_conflict?: InputMaybe<Download_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Exchange_RateArgs = {
  objects: Array<Exchange_Rate_Insert_Input>
  on_conflict?: InputMaybe<Exchange_Rate_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Exchange_Rate_OneArgs = {
  object: Exchange_Rate_Insert_Input
  on_conflict?: InputMaybe<Exchange_Rate_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_FileArgs = {
  objects: Array<File_Insert_Input>
  on_conflict?: InputMaybe<File_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_File_OneArgs = {
  object: File_Insert_Input
  on_conflict?: InputMaybe<File_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_InvoiceArgs = {
  objects: Array<Invoice_Insert_Input>
  on_conflict?: InputMaybe<Invoice_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Invoice_DiscountArgs = {
  objects: Array<Invoice_Discount_Insert_Input>
  on_conflict?: InputMaybe<Invoice_Discount_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Invoice_Discount_OneArgs = {
  object: Invoice_Discount_Insert_Input
  on_conflict?: InputMaybe<Invoice_Discount_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Invoice_OneArgs = {
  object: Invoice_Insert_Input
  on_conflict?: InputMaybe<Invoice_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Invoice_OverdueArgs = {
  objects: Array<Invoice_Overdue_Insert_Input>
  on_conflict?: InputMaybe<Invoice_Overdue_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Invoice_Overdue_OneArgs = {
  object: Invoice_Overdue_Insert_Input
  on_conflict?: InputMaybe<Invoice_Overdue_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_MailArgs = {
  objects: Array<Mail_Insert_Input>
  on_conflict?: InputMaybe<Mail_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Mail_OneArgs = {
  object: Mail_Insert_Input
  on_conflict?: InputMaybe<Mail_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_OrderArgs = {
  objects: Array<Order_Insert_Input>
  on_conflict?: InputMaybe<Order_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Order_By_EmailArgs = {
  objects: Array<Order_By_Email_Insert_Input>
  on_conflict?: InputMaybe<Order_By_Email_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Order_By_Email_OneArgs = {
  object: Order_By_Email_Insert_Input
  on_conflict?: InputMaybe<Order_By_Email_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Order_ConfirmedArgs = {
  objects: Array<Order_Confirmed_Insert_Input>
  on_conflict?: InputMaybe<Order_Confirmed_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Order_Confirmed_OneArgs = {
  object: Order_Confirmed_Insert_Input
  on_conflict?: InputMaybe<Order_Confirmed_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Order_InvoiceArgs = {
  objects: Array<Order_Invoice_Insert_Input>
  on_conflict?: InputMaybe<Order_Invoice_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Order_Invoice_ItemArgs = {
  objects: Array<Order_Invoice_Item_Insert_Input>
  on_conflict?: InputMaybe<Order_Invoice_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Order_Invoice_Item_OneArgs = {
  object: Order_Invoice_Item_Insert_Input
  on_conflict?: InputMaybe<Order_Invoice_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Order_Invoice_OneArgs = {
  object: Order_Invoice_Insert_Input
  on_conflict?: InputMaybe<Order_Invoice_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Order_Item_UnshippedArgs = {
  objects: Array<Order_Item_Unshipped_Insert_Input>
  on_conflict?: InputMaybe<Order_Item_Unshipped_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Order_Item_Unshipped_OneArgs = {
  object: Order_Item_Unshipped_Insert_Input
  on_conflict?: InputMaybe<Order_Item_Unshipped_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Order_OneArgs = {
  object: Order_Insert_Input
  on_conflict?: InputMaybe<Order_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Order_ProductArgs = {
  objects: Array<Order_Product_Insert_Input>
  on_conflict?: InputMaybe<Order_Product_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Order_Product_ItemArgs = {
  objects: Array<Order_Product_Item_Insert_Input>
  on_conflict?: InputMaybe<Order_Product_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Order_Product_Item_OneArgs = {
  object: Order_Product_Item_Insert_Input
  on_conflict?: InputMaybe<Order_Product_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Order_Product_OneArgs = {
  object: Order_Product_Insert_Input
  on_conflict?: InputMaybe<Order_Product_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Order_Product_ReplacementArgs = {
  objects: Array<Order_Product_Replacement_Insert_Input>
  on_conflict?: InputMaybe<Order_Product_Replacement_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Order_Product_Replacement_OneArgs = {
  object: Order_Product_Replacement_Insert_Input
  on_conflict?: InputMaybe<Order_Product_Replacement_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Order_TransactionArgs = {
  objects: Array<Order_Transaction_Insert_Input>
  on_conflict?: InputMaybe<Order_Transaction_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Order_Transaction_OneArgs = {
  object: Order_Transaction_Insert_Input
  on_conflict?: InputMaybe<Order_Transaction_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Payment_ConditionArgs = {
  objects: Array<Payment_Condition_Insert_Input>
  on_conflict?: InputMaybe<Payment_Condition_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Payment_Condition_OneArgs = {
  object: Payment_Condition_Insert_Input
  on_conflict?: InputMaybe<Payment_Condition_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Payment_MethodArgs = {
  objects: Array<Payment_Method_Insert_Input>
  on_conflict?: InputMaybe<Payment_Method_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Payment_Method_OneArgs = {
  object: Payment_Method_Insert_Input
  on_conflict?: InputMaybe<Payment_Method_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Post_CodeArgs = {
  objects: Array<Post_Code_Insert_Input>
  on_conflict?: InputMaybe<Post_Code_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Post_Code_OneArgs = {
  object: Post_Code_Insert_Input
  on_conflict?: InputMaybe<Post_Code_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_ProductArgs = {
  objects: Array<Product_Insert_Input>
  on_conflict?: InputMaybe<Product_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Product_Discount_GroupArgs = {
  objects: Array<Product_Discount_Group_Insert_Input>
  on_conflict?: InputMaybe<Product_Discount_Group_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Product_Discount_Group_OneArgs = {
  object: Product_Discount_Group_Insert_Input
  on_conflict?: InputMaybe<Product_Discount_Group_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Product_FileArgs = {
  objects: Array<Product_File_Insert_Input>
  on_conflict?: InputMaybe<Product_File_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Product_File_OneArgs = {
  object: Product_File_Insert_Input
  on_conflict?: InputMaybe<Product_File_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Product_OneArgs = {
  object: Product_Insert_Input
  on_conflict?: InputMaybe<Product_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Product_PriceArgs = {
  objects: Array<Product_Price_Insert_Input>
  on_conflict?: InputMaybe<Product_Price_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Product_Price_ListArgs = {
  objects: Array<Product_Price_List_Insert_Input>
  on_conflict?: InputMaybe<Product_Price_List_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Product_Price_List_OneArgs = {
  object: Product_Price_List_Insert_Input
  on_conflict?: InputMaybe<Product_Price_List_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Product_Price_OneArgs = {
  object: Product_Price_Insert_Input
  on_conflict?: InputMaybe<Product_Price_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Product_ReplacementArgs = {
  objects: Array<Product_Replacement_Insert_Input>
  on_conflict?: InputMaybe<Product_Replacement_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Product_Replacement_MapArgs = {
  objects: Array<Product_Replacement_Map_Insert_Input>
  on_conflict?: InputMaybe<Product_Replacement_Map_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Product_Replacement_Map_OneArgs = {
  object: Product_Replacement_Map_Insert_Input
  on_conflict?: InputMaybe<Product_Replacement_Map_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Product_Replacement_OneArgs = {
  object: Product_Replacement_Insert_Input
  on_conflict?: InputMaybe<Product_Replacement_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_ReplacementArgs = {
  objects: Array<Replacement_Insert_Input>
  on_conflict?: InputMaybe<Replacement_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Replacement_OneArgs = {
  object: Replacement_Insert_Input
  on_conflict?: InputMaybe<Replacement_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_ReportArgs = {
  objects: Array<Report_Insert_Input>
  on_conflict?: InputMaybe<Report_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Report_OneArgs = {
  object: Report_Insert_Input
  on_conflict?: InputMaybe<Report_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_RoleArgs = {
  objects: Array<Role_Insert_Input>
  on_conflict?: InputMaybe<Role_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Role_OneArgs = {
  object: Role_Insert_Input
  on_conflict?: InputMaybe<Role_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_SettingArgs = {
  objects: Array<Setting_Insert_Input>
  on_conflict?: InputMaybe<Setting_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Setting_OneArgs = {
  object: Setting_Insert_Input
  on_conflict?: InputMaybe<Setting_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shipment_DocumentArgs = {
  objects: Array<Shipment_Document_Insert_Input>
  on_conflict?: InputMaybe<Shipment_Document_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shipment_Document_OneArgs = {
  object: Shipment_Document_Insert_Input
  on_conflict?: InputMaybe<Shipment_Document_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shipping_MethodArgs = {
  objects: Array<Shipping_Method_Insert_Input>
  on_conflict?: InputMaybe<Shipping_Method_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shipping_Method_OneArgs = {
  object: Shipping_Method_Insert_Input
  on_conflict?: InputMaybe<Shipping_Method_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shipping_RateArgs = {
  objects: Array<Shipping_Rate_Insert_Input>
  on_conflict?: InputMaybe<Shipping_Rate_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shipping_Rate_OneArgs = {
  object: Shipping_Rate_Insert_Input
  on_conflict?: InputMaybe<Shipping_Rate_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_SubcustomerArgs = {
  objects: Array<Subcustomer_Insert_Input>
  on_conflict?: InputMaybe<Subcustomer_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Subcustomer_OneArgs = {
  object: Subcustomer_Insert_Input
  on_conflict?: InputMaybe<Subcustomer_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_TerritoryArgs = {
  objects: Array<Territory_Insert_Input>
  on_conflict?: InputMaybe<Territory_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Territory_OneArgs = {
  object: Territory_Insert_Input
  on_conflict?: InputMaybe<Territory_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_TransactionArgs = {
  objects: Array<Transaction_Insert_Input>
  on_conflict?: InputMaybe<Transaction_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Transaction_OneArgs = {
  object: Transaction_Insert_Input
  on_conflict?: InputMaybe<Transaction_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>
  on_conflict?: InputMaybe<User_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input
  on_conflict?: InputMaybe<User_On_Conflict>
}

/** mutation root */
export type Mutation_RootMove_DownloadArgs = {
  args: Move_Download_Args
  distinct_on?: InputMaybe<Array<Download_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Download_Order_By>>
  where?: InputMaybe<Download_Bool_Exp>
}

/** mutation root */
export type Mutation_RootUpdate_AgentArgs = {
  _set?: InputMaybe<Agent_Set_Input>
  where: Agent_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Agent_BonusArgs = {
  _inc?: InputMaybe<Agent_Bonus_Inc_Input>
  _set?: InputMaybe<Agent_Bonus_Set_Input>
  where: Agent_Bonus_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Agent_Bonus_By_PkArgs = {
  _inc?: InputMaybe<Agent_Bonus_Inc_Input>
  _set?: InputMaybe<Agent_Bonus_Set_Input>
  pk_columns: Agent_Bonus_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Agent_Bonus_ManyArgs = {
  updates: Array<Agent_Bonus_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Agent_By_PkArgs = {
  _set?: InputMaybe<Agent_Set_Input>
  pk_columns: Agent_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Agent_ManyArgs = {
  updates: Array<Agent_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Business_SectorArgs = {
  _set?: InputMaybe<Business_Sector_Set_Input>
  where: Business_Sector_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Business_Sector_By_PkArgs = {
  _set?: InputMaybe<Business_Sector_Set_Input>
  pk_columns: Business_Sector_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Business_Sector_ManyArgs = {
  updates: Array<Business_Sector_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_CartArgs = {
  _append?: InputMaybe<Cart_Append_Input>
  _delete_at_path?: InputMaybe<Cart_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Cart_Delete_Elem_Input>
  _delete_key?: InputMaybe<Cart_Delete_Key_Input>
  _inc?: InputMaybe<Cart_Inc_Input>
  _prepend?: InputMaybe<Cart_Prepend_Input>
  _set?: InputMaybe<Cart_Set_Input>
  where: Cart_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Cart_By_PkArgs = {
  _append?: InputMaybe<Cart_Append_Input>
  _delete_at_path?: InputMaybe<Cart_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Cart_Delete_Elem_Input>
  _delete_key?: InputMaybe<Cart_Delete_Key_Input>
  _inc?: InputMaybe<Cart_Inc_Input>
  _prepend?: InputMaybe<Cart_Prepend_Input>
  _set?: InputMaybe<Cart_Set_Input>
  pk_columns: Cart_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Cart_ItemArgs = {
  _append?: InputMaybe<Cart_Item_Append_Input>
  _delete_at_path?: InputMaybe<Cart_Item_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Cart_Item_Delete_Elem_Input>
  _delete_key?: InputMaybe<Cart_Item_Delete_Key_Input>
  _inc?: InputMaybe<Cart_Item_Inc_Input>
  _prepend?: InputMaybe<Cart_Item_Prepend_Input>
  _set?: InputMaybe<Cart_Item_Set_Input>
  where: Cart_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Cart_Item_By_PkArgs = {
  _append?: InputMaybe<Cart_Item_Append_Input>
  _delete_at_path?: InputMaybe<Cart_Item_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Cart_Item_Delete_Elem_Input>
  _delete_key?: InputMaybe<Cart_Item_Delete_Key_Input>
  _inc?: InputMaybe<Cart_Item_Inc_Input>
  _prepend?: InputMaybe<Cart_Item_Prepend_Input>
  _set?: InputMaybe<Cart_Item_Set_Input>
  pk_columns: Cart_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Cart_Item_ManyArgs = {
  updates: Array<Cart_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Cart_ManyArgs = {
  updates: Array<Cart_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Cart_ReplacementArgs = {
  _append?: InputMaybe<Cart_Replacement_Append_Input>
  _delete_at_path?: InputMaybe<Cart_Replacement_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Cart_Replacement_Delete_Elem_Input>
  _delete_key?: InputMaybe<Cart_Replacement_Delete_Key_Input>
  _inc?: InputMaybe<Cart_Replacement_Inc_Input>
  _prepend?: InputMaybe<Cart_Replacement_Prepend_Input>
  _set?: InputMaybe<Cart_Replacement_Set_Input>
  where: Cart_Replacement_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Cart_Replacement_By_PkArgs = {
  _append?: InputMaybe<Cart_Replacement_Append_Input>
  _delete_at_path?: InputMaybe<Cart_Replacement_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Cart_Replacement_Delete_Elem_Input>
  _delete_key?: InputMaybe<Cart_Replacement_Delete_Key_Input>
  _inc?: InputMaybe<Cart_Replacement_Inc_Input>
  _prepend?: InputMaybe<Cart_Replacement_Prepend_Input>
  _set?: InputMaybe<Cart_Replacement_Set_Input>
  pk_columns: Cart_Replacement_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Cart_Replacement_ManyArgs = {
  updates: Array<Cart_Replacement_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_CommunicationArgs = {
  _set?: InputMaybe<Communication_Set_Input>
  where: Communication_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Communication_By_PkArgs = {
  _set?: InputMaybe<Communication_Set_Input>
  pk_columns: Communication_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Communication_ChangelogArgs = {
  _append?: InputMaybe<Communication_Changelog_Append_Input>
  _delete_at_path?: InputMaybe<Communication_Changelog_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Communication_Changelog_Delete_Elem_Input>
  _delete_key?: InputMaybe<Communication_Changelog_Delete_Key_Input>
  _prepend?: InputMaybe<Communication_Changelog_Prepend_Input>
  _set?: InputMaybe<Communication_Changelog_Set_Input>
  where: Communication_Changelog_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Communication_Changelog_By_PkArgs = {
  _append?: InputMaybe<Communication_Changelog_Append_Input>
  _delete_at_path?: InputMaybe<Communication_Changelog_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Communication_Changelog_Delete_Elem_Input>
  _delete_key?: InputMaybe<Communication_Changelog_Delete_Key_Input>
  _prepend?: InputMaybe<Communication_Changelog_Prepend_Input>
  _set?: InputMaybe<Communication_Changelog_Set_Input>
  pk_columns: Communication_Changelog_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Communication_Changelog_ManyArgs = {
  updates: Array<Communication_Changelog_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Communication_CodeArgs = {
  _set?: InputMaybe<Communication_Code_Set_Input>
  where: Communication_Code_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Communication_Code_By_PkArgs = {
  _set?: InputMaybe<Communication_Code_Set_Input>
  pk_columns: Communication_Code_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Communication_Code_ManyArgs = {
  updates: Array<Communication_Code_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Communication_External_MailArgs = {
  _append?: InputMaybe<Communication_External_Mail_Append_Input>
  _delete_at_path?: InputMaybe<Communication_External_Mail_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Communication_External_Mail_Delete_Elem_Input>
  _delete_key?: InputMaybe<Communication_External_Mail_Delete_Key_Input>
  _prepend?: InputMaybe<Communication_External_Mail_Prepend_Input>
  _set?: InputMaybe<Communication_External_Mail_Set_Input>
  where: Communication_External_Mail_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Communication_External_Mail_By_PkArgs = {
  _append?: InputMaybe<Communication_External_Mail_Append_Input>
  _delete_at_path?: InputMaybe<Communication_External_Mail_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Communication_External_Mail_Delete_Elem_Input>
  _delete_key?: InputMaybe<Communication_External_Mail_Delete_Key_Input>
  _prepend?: InputMaybe<Communication_External_Mail_Prepend_Input>
  _set?: InputMaybe<Communication_External_Mail_Set_Input>
  pk_columns: Communication_External_Mail_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Communication_External_Mail_ManyArgs = {
  updates: Array<Communication_External_Mail_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Communication_ListArgs = {
  _append?: InputMaybe<Communication_List_Append_Input>
  _delete_at_path?: InputMaybe<Communication_List_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Communication_List_Delete_Elem_Input>
  _delete_key?: InputMaybe<Communication_List_Delete_Key_Input>
  _prepend?: InputMaybe<Communication_List_Prepend_Input>
  _set?: InputMaybe<Communication_List_Set_Input>
  where: Communication_List_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Communication_List_By_PkArgs = {
  _append?: InputMaybe<Communication_List_Append_Input>
  _delete_at_path?: InputMaybe<Communication_List_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Communication_List_Delete_Elem_Input>
  _delete_key?: InputMaybe<Communication_List_Delete_Key_Input>
  _prepend?: InputMaybe<Communication_List_Prepend_Input>
  _set?: InputMaybe<Communication_List_Set_Input>
  pk_columns: Communication_List_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Communication_List_ManyArgs = {
  updates: Array<Communication_List_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Communication_ManyArgs = {
  updates: Array<Communication_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Communication_SettingsArgs = {
  _set?: InputMaybe<Communication_Settings_Set_Input>
  where: Communication_Settings_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Communication_Settings_By_PkArgs = {
  _set?: InputMaybe<Communication_Settings_Set_Input>
  pk_columns: Communication_Settings_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Communication_Settings_ManyArgs = {
  updates: Array<Communication_Settings_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Country_RegionArgs = {
  _set?: InputMaybe<Country_Region_Set_Input>
  where: Country_Region_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Country_Region_By_PkArgs = {
  _set?: InputMaybe<Country_Region_Set_Input>
  pk_columns: Country_Region_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Country_Region_ManyArgs = {
  updates: Array<Country_Region_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_CountyArgs = {
  _set?: InputMaybe<County_Set_Input>
  where: County_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_County_By_PkArgs = {
  _set?: InputMaybe<County_Set_Input>
  pk_columns: County_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_County_ManyArgs = {
  updates: Array<County_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Credit_MemoArgs = {
  _inc?: InputMaybe<Credit_Memo_Inc_Input>
  _set?: InputMaybe<Credit_Memo_Set_Input>
  where: Credit_Memo_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Credit_Memo_By_PkArgs = {
  _inc?: InputMaybe<Credit_Memo_Inc_Input>
  _set?: InputMaybe<Credit_Memo_Set_Input>
  pk_columns: Credit_Memo_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Credit_Memo_ManyArgs = {
  updates: Array<Credit_Memo_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_CronArgs = {
  _append?: InputMaybe<Cron_Append_Input>
  _delete_at_path?: InputMaybe<Cron_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Cron_Delete_Elem_Input>
  _delete_key?: InputMaybe<Cron_Delete_Key_Input>
  _prepend?: InputMaybe<Cron_Prepend_Input>
  _set?: InputMaybe<Cron_Set_Input>
  where: Cron_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Cron_By_PkArgs = {
  _append?: InputMaybe<Cron_Append_Input>
  _delete_at_path?: InputMaybe<Cron_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Cron_Delete_Elem_Input>
  _delete_key?: InputMaybe<Cron_Delete_Key_Input>
  _prepend?: InputMaybe<Cron_Prepend_Input>
  _set?: InputMaybe<Cron_Set_Input>
  pk_columns: Cron_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Cron_ManyArgs = {
  updates: Array<Cron_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_CustomerArgs = {
  _inc?: InputMaybe<Customer_Inc_Input>
  _set?: InputMaybe<Customer_Set_Input>
  where: Customer_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Customer_Additional_DataArgs = {
  _append?: InputMaybe<Customer_Additional_Data_Append_Input>
  _delete_at_path?: InputMaybe<Customer_Additional_Data_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Customer_Additional_Data_Delete_Elem_Input>
  _delete_key?: InputMaybe<Customer_Additional_Data_Delete_Key_Input>
  _prepend?: InputMaybe<Customer_Additional_Data_Prepend_Input>
  _set?: InputMaybe<Customer_Additional_Data_Set_Input>
  where: Customer_Additional_Data_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Customer_Additional_Data_By_PkArgs = {
  _append?: InputMaybe<Customer_Additional_Data_Append_Input>
  _delete_at_path?: InputMaybe<Customer_Additional_Data_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Customer_Additional_Data_Delete_Elem_Input>
  _delete_key?: InputMaybe<Customer_Additional_Data_Delete_Key_Input>
  _prepend?: InputMaybe<Customer_Additional_Data_Prepend_Input>
  _set?: InputMaybe<Customer_Additional_Data_Set_Input>
  pk_columns: Customer_Additional_Data_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Customer_Additional_Data_ManyArgs = {
  updates: Array<Customer_Additional_Data_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Customer_AddressArgs = {
  _set?: InputMaybe<Customer_Address_Set_Input>
  where: Customer_Address_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Customer_Address_By_PkArgs = {
  _set?: InputMaybe<Customer_Address_Set_Input>
  pk_columns: Customer_Address_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Customer_Address_DropshippingArgs = {
  _inc?: InputMaybe<Customer_Address_Dropshipping_Inc_Input>
  _set?: InputMaybe<Customer_Address_Dropshipping_Set_Input>
  where: Customer_Address_Dropshipping_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Customer_Address_Dropshipping_By_PkArgs = {
  _inc?: InputMaybe<Customer_Address_Dropshipping_Inc_Input>
  _set?: InputMaybe<Customer_Address_Dropshipping_Set_Input>
  pk_columns: Customer_Address_Dropshipping_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Customer_Address_Dropshipping_ManyArgs = {
  updates: Array<Customer_Address_Dropshipping_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Customer_Address_ManyArgs = {
  updates: Array<Customer_Address_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Customer_By_PkArgs = {
  _inc?: InputMaybe<Customer_Inc_Input>
  _set?: InputMaybe<Customer_Set_Input>
  pk_columns: Customer_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Customer_DiscountArgs = {
  _inc?: InputMaybe<Customer_Discount_Inc_Input>
  _set?: InputMaybe<Customer_Discount_Set_Input>
  where: Customer_Discount_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Customer_Discount_By_PkArgs = {
  _inc?: InputMaybe<Customer_Discount_Inc_Input>
  _set?: InputMaybe<Customer_Discount_Set_Input>
  pk_columns: Customer_Discount_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Customer_Discount_ManyArgs = {
  updates: Array<Customer_Discount_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Customer_ManyArgs = {
  updates: Array<Customer_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_DownloadArgs = {
  _inc?: InputMaybe<Download_Inc_Input>
  _set?: InputMaybe<Download_Set_Input>
  where: Download_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Download_By_PkArgs = {
  _inc?: InputMaybe<Download_Inc_Input>
  _set?: InputMaybe<Download_Set_Input>
  pk_columns: Download_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Download_ManyArgs = {
  updates: Array<Download_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Exchange_RateArgs = {
  _inc?: InputMaybe<Exchange_Rate_Inc_Input>
  _set?: InputMaybe<Exchange_Rate_Set_Input>
  where: Exchange_Rate_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Exchange_Rate_By_PkArgs = {
  _inc?: InputMaybe<Exchange_Rate_Inc_Input>
  _set?: InputMaybe<Exchange_Rate_Set_Input>
  pk_columns: Exchange_Rate_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Exchange_Rate_ManyArgs = {
  updates: Array<Exchange_Rate_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_FileArgs = {
  _set?: InputMaybe<File_Set_Input>
  where: File_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_File_By_PkArgs = {
  _set?: InputMaybe<File_Set_Input>
  pk_columns: File_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_File_ManyArgs = {
  updates: Array<File_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_InvoiceArgs = {
  _inc?: InputMaybe<Invoice_Inc_Input>
  _set?: InputMaybe<Invoice_Set_Input>
  where: Invoice_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Invoice_By_PkArgs = {
  _inc?: InputMaybe<Invoice_Inc_Input>
  _set?: InputMaybe<Invoice_Set_Input>
  pk_columns: Invoice_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Invoice_DiscountArgs = {
  _inc?: InputMaybe<Invoice_Discount_Inc_Input>
  _set?: InputMaybe<Invoice_Discount_Set_Input>
  where: Invoice_Discount_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Invoice_Discount_By_PkArgs = {
  _inc?: InputMaybe<Invoice_Discount_Inc_Input>
  _set?: InputMaybe<Invoice_Discount_Set_Input>
  pk_columns: Invoice_Discount_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Invoice_Discount_ManyArgs = {
  updates: Array<Invoice_Discount_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Invoice_ManyArgs = {
  updates: Array<Invoice_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Invoice_OverdueArgs = {
  _inc?: InputMaybe<Invoice_Overdue_Inc_Input>
  _set?: InputMaybe<Invoice_Overdue_Set_Input>
  where: Invoice_Overdue_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Invoice_Overdue_By_PkArgs = {
  _inc?: InputMaybe<Invoice_Overdue_Inc_Input>
  _set?: InputMaybe<Invoice_Overdue_Set_Input>
  pk_columns: Invoice_Overdue_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Invoice_Overdue_ManyArgs = {
  updates: Array<Invoice_Overdue_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_MailArgs = {
  _append?: InputMaybe<Mail_Append_Input>
  _delete_at_path?: InputMaybe<Mail_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Mail_Delete_Elem_Input>
  _delete_key?: InputMaybe<Mail_Delete_Key_Input>
  _prepend?: InputMaybe<Mail_Prepend_Input>
  _set?: InputMaybe<Mail_Set_Input>
  where: Mail_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Mail_By_PkArgs = {
  _append?: InputMaybe<Mail_Append_Input>
  _delete_at_path?: InputMaybe<Mail_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Mail_Delete_Elem_Input>
  _delete_key?: InputMaybe<Mail_Delete_Key_Input>
  _prepend?: InputMaybe<Mail_Prepend_Input>
  _set?: InputMaybe<Mail_Set_Input>
  pk_columns: Mail_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Mail_ManyArgs = {
  updates: Array<Mail_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_OrderArgs = {
  _append?: InputMaybe<Order_Append_Input>
  _delete_at_path?: InputMaybe<Order_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Order_Delete_Elem_Input>
  _delete_key?: InputMaybe<Order_Delete_Key_Input>
  _inc?: InputMaybe<Order_Inc_Input>
  _prepend?: InputMaybe<Order_Prepend_Input>
  _set?: InputMaybe<Order_Set_Input>
  where: Order_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Order_By_EmailArgs = {
  _append?: InputMaybe<Order_By_Email_Append_Input>
  _delete_at_path?: InputMaybe<Order_By_Email_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Order_By_Email_Delete_Elem_Input>
  _delete_key?: InputMaybe<Order_By_Email_Delete_Key_Input>
  _inc?: InputMaybe<Order_By_Email_Inc_Input>
  _prepend?: InputMaybe<Order_By_Email_Prepend_Input>
  _set?: InputMaybe<Order_By_Email_Set_Input>
  where: Order_By_Email_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Order_By_Email_By_PkArgs = {
  _append?: InputMaybe<Order_By_Email_Append_Input>
  _delete_at_path?: InputMaybe<Order_By_Email_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Order_By_Email_Delete_Elem_Input>
  _delete_key?: InputMaybe<Order_By_Email_Delete_Key_Input>
  _inc?: InputMaybe<Order_By_Email_Inc_Input>
  _prepend?: InputMaybe<Order_By_Email_Prepend_Input>
  _set?: InputMaybe<Order_By_Email_Set_Input>
  pk_columns: Order_By_Email_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Order_By_Email_ManyArgs = {
  updates: Array<Order_By_Email_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Order_By_PkArgs = {
  _append?: InputMaybe<Order_Append_Input>
  _delete_at_path?: InputMaybe<Order_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Order_Delete_Elem_Input>
  _delete_key?: InputMaybe<Order_Delete_Key_Input>
  _inc?: InputMaybe<Order_Inc_Input>
  _prepend?: InputMaybe<Order_Prepend_Input>
  _set?: InputMaybe<Order_Set_Input>
  pk_columns: Order_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Order_ConfirmedArgs = {
  _set?: InputMaybe<Order_Confirmed_Set_Input>
  where: Order_Confirmed_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Order_Confirmed_By_PkArgs = {
  _set?: InputMaybe<Order_Confirmed_Set_Input>
  pk_columns: Order_Confirmed_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Order_Confirmed_ManyArgs = {
  updates: Array<Order_Confirmed_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Order_InvoiceArgs = {
  _append?: InputMaybe<Order_Invoice_Append_Input>
  _delete_at_path?: InputMaybe<Order_Invoice_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Order_Invoice_Delete_Elem_Input>
  _delete_key?: InputMaybe<Order_Invoice_Delete_Key_Input>
  _prepend?: InputMaybe<Order_Invoice_Prepend_Input>
  _set?: InputMaybe<Order_Invoice_Set_Input>
  where: Order_Invoice_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Order_Invoice_By_PkArgs = {
  _append?: InputMaybe<Order_Invoice_Append_Input>
  _delete_at_path?: InputMaybe<Order_Invoice_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Order_Invoice_Delete_Elem_Input>
  _delete_key?: InputMaybe<Order_Invoice_Delete_Key_Input>
  _prepend?: InputMaybe<Order_Invoice_Prepend_Input>
  _set?: InputMaybe<Order_Invoice_Set_Input>
  pk_columns: Order_Invoice_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Order_Invoice_ItemArgs = {
  _append?: InputMaybe<Order_Invoice_Item_Append_Input>
  _delete_at_path?: InputMaybe<Order_Invoice_Item_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Order_Invoice_Item_Delete_Elem_Input>
  _delete_key?: InputMaybe<Order_Invoice_Item_Delete_Key_Input>
  _inc?: InputMaybe<Order_Invoice_Item_Inc_Input>
  _prepend?: InputMaybe<Order_Invoice_Item_Prepend_Input>
  _set?: InputMaybe<Order_Invoice_Item_Set_Input>
  where: Order_Invoice_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Order_Invoice_Item_By_PkArgs = {
  _append?: InputMaybe<Order_Invoice_Item_Append_Input>
  _delete_at_path?: InputMaybe<Order_Invoice_Item_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Order_Invoice_Item_Delete_Elem_Input>
  _delete_key?: InputMaybe<Order_Invoice_Item_Delete_Key_Input>
  _inc?: InputMaybe<Order_Invoice_Item_Inc_Input>
  _prepend?: InputMaybe<Order_Invoice_Item_Prepend_Input>
  _set?: InputMaybe<Order_Invoice_Item_Set_Input>
  pk_columns: Order_Invoice_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Order_Invoice_Item_ManyArgs = {
  updates: Array<Order_Invoice_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Order_Invoice_ManyArgs = {
  updates: Array<Order_Invoice_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Order_Item_UnshippedArgs = {
  _inc?: InputMaybe<Order_Item_Unshipped_Inc_Input>
  _set?: InputMaybe<Order_Item_Unshipped_Set_Input>
  where: Order_Item_Unshipped_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Order_Item_Unshipped_By_PkArgs = {
  _inc?: InputMaybe<Order_Item_Unshipped_Inc_Input>
  _set?: InputMaybe<Order_Item_Unshipped_Set_Input>
  pk_columns: Order_Item_Unshipped_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Order_Item_Unshipped_ManyArgs = {
  updates: Array<Order_Item_Unshipped_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Order_ManyArgs = {
  updates: Array<Order_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Order_ProductArgs = {
  _append?: InputMaybe<Order_Product_Append_Input>
  _delete_at_path?: InputMaybe<Order_Product_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Order_Product_Delete_Elem_Input>
  _delete_key?: InputMaybe<Order_Product_Delete_Key_Input>
  _prepend?: InputMaybe<Order_Product_Prepend_Input>
  _set?: InputMaybe<Order_Product_Set_Input>
  where: Order_Product_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Order_Product_By_PkArgs = {
  _append?: InputMaybe<Order_Product_Append_Input>
  _delete_at_path?: InputMaybe<Order_Product_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Order_Product_Delete_Elem_Input>
  _delete_key?: InputMaybe<Order_Product_Delete_Key_Input>
  _prepend?: InputMaybe<Order_Product_Prepend_Input>
  _set?: InputMaybe<Order_Product_Set_Input>
  pk_columns: Order_Product_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Order_Product_ItemArgs = {
  _append?: InputMaybe<Order_Product_Item_Append_Input>
  _delete_at_path?: InputMaybe<Order_Product_Item_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Order_Product_Item_Delete_Elem_Input>
  _delete_key?: InputMaybe<Order_Product_Item_Delete_Key_Input>
  _inc?: InputMaybe<Order_Product_Item_Inc_Input>
  _prepend?: InputMaybe<Order_Product_Item_Prepend_Input>
  _set?: InputMaybe<Order_Product_Item_Set_Input>
  where: Order_Product_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Order_Product_Item_By_PkArgs = {
  _append?: InputMaybe<Order_Product_Item_Append_Input>
  _delete_at_path?: InputMaybe<Order_Product_Item_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Order_Product_Item_Delete_Elem_Input>
  _delete_key?: InputMaybe<Order_Product_Item_Delete_Key_Input>
  _inc?: InputMaybe<Order_Product_Item_Inc_Input>
  _prepend?: InputMaybe<Order_Product_Item_Prepend_Input>
  _set?: InputMaybe<Order_Product_Item_Set_Input>
  pk_columns: Order_Product_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Order_Product_Item_ManyArgs = {
  updates: Array<Order_Product_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Order_Product_ManyArgs = {
  updates: Array<Order_Product_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Order_Product_ReplacementArgs = {
  _append?: InputMaybe<Order_Product_Replacement_Append_Input>
  _delete_at_path?: InputMaybe<Order_Product_Replacement_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Order_Product_Replacement_Delete_Elem_Input>
  _delete_key?: InputMaybe<Order_Product_Replacement_Delete_Key_Input>
  _inc?: InputMaybe<Order_Product_Replacement_Inc_Input>
  _prepend?: InputMaybe<Order_Product_Replacement_Prepend_Input>
  _set?: InputMaybe<Order_Product_Replacement_Set_Input>
  where: Order_Product_Replacement_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Order_Product_Replacement_By_PkArgs = {
  _append?: InputMaybe<Order_Product_Replacement_Append_Input>
  _delete_at_path?: InputMaybe<Order_Product_Replacement_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Order_Product_Replacement_Delete_Elem_Input>
  _delete_key?: InputMaybe<Order_Product_Replacement_Delete_Key_Input>
  _inc?: InputMaybe<Order_Product_Replacement_Inc_Input>
  _prepend?: InputMaybe<Order_Product_Replacement_Prepend_Input>
  _set?: InputMaybe<Order_Product_Replacement_Set_Input>
  pk_columns: Order_Product_Replacement_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Order_Product_Replacement_ManyArgs = {
  updates: Array<Order_Product_Replacement_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Order_TransactionArgs = {
  _set?: InputMaybe<Order_Transaction_Set_Input>
  where: Order_Transaction_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Order_Transaction_By_PkArgs = {
  _set?: InputMaybe<Order_Transaction_Set_Input>
  pk_columns: Order_Transaction_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Order_Transaction_ManyArgs = {
  updates: Array<Order_Transaction_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Payment_ConditionArgs = {
  _set?: InputMaybe<Payment_Condition_Set_Input>
  where: Payment_Condition_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Payment_Condition_By_PkArgs = {
  _set?: InputMaybe<Payment_Condition_Set_Input>
  pk_columns: Payment_Condition_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Payment_Condition_ManyArgs = {
  updates: Array<Payment_Condition_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Payment_MethodArgs = {
  _set?: InputMaybe<Payment_Method_Set_Input>
  where: Payment_Method_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Payment_Method_By_PkArgs = {
  _set?: InputMaybe<Payment_Method_Set_Input>
  pk_columns: Payment_Method_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Payment_Method_ManyArgs = {
  updates: Array<Payment_Method_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Post_CodeArgs = {
  _set?: InputMaybe<Post_Code_Set_Input>
  where: Post_Code_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Post_Code_By_PkArgs = {
  _set?: InputMaybe<Post_Code_Set_Input>
  pk_columns: Post_Code_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Post_Code_ManyArgs = {
  updates: Array<Post_Code_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_ProductArgs = {
  _inc?: InputMaybe<Product_Inc_Input>
  _set?: InputMaybe<Product_Set_Input>
  where: Product_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Product_By_PkArgs = {
  _inc?: InputMaybe<Product_Inc_Input>
  _set?: InputMaybe<Product_Set_Input>
  pk_columns: Product_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Product_Discount_GroupArgs = {
  _set?: InputMaybe<Product_Discount_Group_Set_Input>
  where: Product_Discount_Group_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Product_Discount_Group_By_PkArgs = {
  _set?: InputMaybe<Product_Discount_Group_Set_Input>
  pk_columns: Product_Discount_Group_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Product_Discount_Group_ManyArgs = {
  updates: Array<Product_Discount_Group_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Product_FileArgs = {
  _set?: InputMaybe<Product_File_Set_Input>
  where: Product_File_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Product_File_By_PkArgs = {
  _set?: InputMaybe<Product_File_Set_Input>
  pk_columns: Product_File_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Product_File_ManyArgs = {
  updates: Array<Product_File_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Product_ManyArgs = {
  updates: Array<Product_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Product_PriceArgs = {
  _inc?: InputMaybe<Product_Price_Inc_Input>
  _set?: InputMaybe<Product_Price_Set_Input>
  where: Product_Price_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Product_Price_By_PkArgs = {
  _inc?: InputMaybe<Product_Price_Inc_Input>
  _set?: InputMaybe<Product_Price_Set_Input>
  pk_columns: Product_Price_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Product_Price_ListArgs = {
  _set?: InputMaybe<Product_Price_List_Set_Input>
  where: Product_Price_List_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Product_Price_List_By_PkArgs = {
  _set?: InputMaybe<Product_Price_List_Set_Input>
  pk_columns: Product_Price_List_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Product_Price_List_ManyArgs = {
  updates: Array<Product_Price_List_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Product_Price_ManyArgs = {
  updates: Array<Product_Price_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Product_ReplacementArgs = {
  _inc?: InputMaybe<Product_Replacement_Inc_Input>
  _set?: InputMaybe<Product_Replacement_Set_Input>
  where: Product_Replacement_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Product_Replacement_By_PkArgs = {
  _inc?: InputMaybe<Product_Replacement_Inc_Input>
  _set?: InputMaybe<Product_Replacement_Set_Input>
  pk_columns: Product_Replacement_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Product_Replacement_ManyArgs = {
  updates: Array<Product_Replacement_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Product_Replacement_MapArgs = {
  _inc?: InputMaybe<Product_Replacement_Map_Inc_Input>
  _set?: InputMaybe<Product_Replacement_Map_Set_Input>
  where: Product_Replacement_Map_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Product_Replacement_Map_By_PkArgs = {
  _inc?: InputMaybe<Product_Replacement_Map_Inc_Input>
  _set?: InputMaybe<Product_Replacement_Map_Set_Input>
  pk_columns: Product_Replacement_Map_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Product_Replacement_Map_ManyArgs = {
  updates: Array<Product_Replacement_Map_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_ReplacementArgs = {
  _set?: InputMaybe<Replacement_Set_Input>
  where: Replacement_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Replacement_By_PkArgs = {
  _set?: InputMaybe<Replacement_Set_Input>
  pk_columns: Replacement_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Replacement_ManyArgs = {
  updates: Array<Replacement_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_ReportArgs = {
  _set?: InputMaybe<Report_Set_Input>
  where: Report_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Report_By_PkArgs = {
  _set?: InputMaybe<Report_Set_Input>
  pk_columns: Report_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Report_ManyArgs = {
  updates: Array<Report_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_RoleArgs = {
  _set?: InputMaybe<Role_Set_Input>
  where: Role_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Role_By_PkArgs = {
  _set?: InputMaybe<Role_Set_Input>
  pk_columns: Role_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Role_ManyArgs = {
  updates: Array<Role_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_SettingArgs = {
  _set?: InputMaybe<Setting_Set_Input>
  where: Setting_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Setting_By_PkArgs = {
  _set?: InputMaybe<Setting_Set_Input>
  pk_columns: Setting_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Setting_ManyArgs = {
  updates: Array<Setting_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shipment_DocumentArgs = {
  _set?: InputMaybe<Shipment_Document_Set_Input>
  where: Shipment_Document_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shipment_Document_By_PkArgs = {
  _set?: InputMaybe<Shipment_Document_Set_Input>
  pk_columns: Shipment_Document_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shipment_Document_ManyArgs = {
  updates: Array<Shipment_Document_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shipping_MethodArgs = {
  _set?: InputMaybe<Shipping_Method_Set_Input>
  where: Shipping_Method_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shipping_Method_By_PkArgs = {
  _set?: InputMaybe<Shipping_Method_Set_Input>
  pk_columns: Shipping_Method_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shipping_Method_ManyArgs = {
  updates: Array<Shipping_Method_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shipping_RateArgs = {
  _inc?: InputMaybe<Shipping_Rate_Inc_Input>
  _set?: InputMaybe<Shipping_Rate_Set_Input>
  where: Shipping_Rate_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shipping_Rate_By_PkArgs = {
  _inc?: InputMaybe<Shipping_Rate_Inc_Input>
  _set?: InputMaybe<Shipping_Rate_Set_Input>
  pk_columns: Shipping_Rate_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shipping_Rate_ManyArgs = {
  updates: Array<Shipping_Rate_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_SubcustomerArgs = {
  _set?: InputMaybe<Subcustomer_Set_Input>
  where: Subcustomer_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Subcustomer_By_PkArgs = {
  _set?: InputMaybe<Subcustomer_Set_Input>
  pk_columns: Subcustomer_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Subcustomer_ManyArgs = {
  updates: Array<Subcustomer_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_TerritoryArgs = {
  _set?: InputMaybe<Territory_Set_Input>
  where: Territory_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Territory_By_PkArgs = {
  _set?: InputMaybe<Territory_Set_Input>
  pk_columns: Territory_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Territory_ManyArgs = {
  updates: Array<Territory_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_TransactionArgs = {
  _append?: InputMaybe<Transaction_Append_Input>
  _delete_at_path?: InputMaybe<Transaction_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Transaction_Delete_Elem_Input>
  _delete_key?: InputMaybe<Transaction_Delete_Key_Input>
  _prepend?: InputMaybe<Transaction_Prepend_Input>
  _set?: InputMaybe<Transaction_Set_Input>
  where: Transaction_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Transaction_By_PkArgs = {
  _append?: InputMaybe<Transaction_Append_Input>
  _delete_at_path?: InputMaybe<Transaction_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Transaction_Delete_Elem_Input>
  _delete_key?: InputMaybe<Transaction_Delete_Key_Input>
  _prepend?: InputMaybe<Transaction_Prepend_Input>
  _set?: InputMaybe<Transaction_Set_Input>
  pk_columns: Transaction_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Transaction_ManyArgs = {
  updates: Array<Transaction_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: InputMaybe<User_Set_Input>
  where: User_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: InputMaybe<User_Set_Input>
  pk_columns: User_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>
}

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>
  _gt?: InputMaybe<Scalars['numeric']['input']>
  _gte?: InputMaybe<Scalars['numeric']['input']>
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['numeric']['input']>
  _lte?: InputMaybe<Scalars['numeric']['input']>
  _neq?: InputMaybe<Scalars['numeric']['input']>
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>
}

/** columns and relationships of "order" */
export type Order = {
  __typename?: 'order'
  additional_info?: Maybe<Scalars['jsonb']['output']>
  amount: Scalars['numeric']['output']
  /** An object relationship */
  cart?: Maybe<Cart>
  created_at: Scalars['timestamptz']['output']
  currency: Scalars['String']['output']
  /** An object relationship */
  customer?: Maybe<Customer>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  id: Scalars['uuid']['output']
  increment_id: Scalars['Int']['output']
  increment_id_as_text?: Maybe<Scalars['String']['output']>
  /** An object relationship */
  order_confirmed?: Maybe<Order_Confirmed>
  /** An object relationship */
  order_invoice?: Maybe<Order_Invoice>
  /** An array relationship */
  order_invoices: Array<Order_Invoice>
  /** An aggregate relationship */
  order_invoices_aggregate: Order_Invoice_Aggregate
  /** An object relationship */
  order_product?: Maybe<Order_Product>
  /** An array relationship */
  order_products: Array<Order_Product>
  /** An aggregate relationship */
  order_products_aggregate: Order_Product_Aggregate
  /** An array relationship */
  order_transactions: Array<Order_Transaction>
  /** An aggregate relationship */
  order_transactions_aggregate: Order_Transaction_Aggregate
  status: Scalars['String']['output']
  type?: Maybe<Scalars['String']['output']>
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "order" */
export type OrderAdditional_InfoArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** columns and relationships of "order" */
export type OrderOrder_InvoicesArgs = {
  distinct_on?: InputMaybe<Array<Order_Invoice_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Invoice_Order_By>>
  where?: InputMaybe<Order_Invoice_Bool_Exp>
}

/** columns and relationships of "order" */
export type OrderOrder_Invoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Invoice_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Invoice_Order_By>>
  where?: InputMaybe<Order_Invoice_Bool_Exp>
}

/** columns and relationships of "order" */
export type OrderOrder_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Order_Product_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Product_Order_By>>
  where?: InputMaybe<Order_Product_Bool_Exp>
}

/** columns and relationships of "order" */
export type OrderOrder_Products_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Product_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Product_Order_By>>
  where?: InputMaybe<Order_Product_Bool_Exp>
}

/** columns and relationships of "order" */
export type OrderOrder_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Order_Transaction_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Transaction_Order_By>>
  where?: InputMaybe<Order_Transaction_Bool_Exp>
}

/** columns and relationships of "order" */
export type OrderOrder_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Transaction_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Transaction_Order_By>>
  where?: InputMaybe<Order_Transaction_Bool_Exp>
}

/** aggregated selection of "order" */
export type Order_Aggregate = {
  __typename?: 'order_aggregate'
  aggregate?: Maybe<Order_Aggregate_Fields>
  nodes: Array<Order>
}

export type Order_Aggregate_Bool_Exp = {
  count?: InputMaybe<Order_Aggregate_Bool_Exp_Count>
}

export type Order_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Order_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Order_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "order" */
export type Order_Aggregate_Fields = {
  __typename?: 'order_aggregate_fields'
  avg?: Maybe<Order_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Order_Max_Fields>
  min?: Maybe<Order_Min_Fields>
  stddev?: Maybe<Order_Stddev_Fields>
  stddev_pop?: Maybe<Order_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Order_Stddev_Samp_Fields>
  sum?: Maybe<Order_Sum_Fields>
  var_pop?: Maybe<Order_Var_Pop_Fields>
  var_samp?: Maybe<Order_Var_Samp_Fields>
  variance?: Maybe<Order_Variance_Fields>
}

/** aggregate fields of "order" */
export type Order_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "order" */
export type Order_Aggregate_Order_By = {
  avg?: InputMaybe<Order_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Order_Max_Order_By>
  min?: InputMaybe<Order_Min_Order_By>
  stddev?: InputMaybe<Order_Stddev_Order_By>
  stddev_pop?: InputMaybe<Order_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Order_Stddev_Samp_Order_By>
  sum?: InputMaybe<Order_Sum_Order_By>
  var_pop?: InputMaybe<Order_Var_Pop_Order_By>
  var_samp?: InputMaybe<Order_Var_Samp_Order_By>
  variance?: InputMaybe<Order_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Order_Append_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
}

/** input type for inserting array relation for remote table "order" */
export type Order_Arr_Rel_Insert_Input = {
  data: Array<Order_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Order_On_Conflict>
}

/** aggregate avg on columns */
export type Order_Avg_Fields = {
  __typename?: 'order_avg_fields'
  amount?: Maybe<Scalars['Float']['output']>
  increment_id?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "order" */
export type Order_Avg_Order_By = {
  amount?: InputMaybe<Order_By>
  increment_id?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "order". All fields are combined with a logical 'AND'. */
export type Order_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Bool_Exp>>
  _not?: InputMaybe<Order_Bool_Exp>
  _or?: InputMaybe<Array<Order_Bool_Exp>>
  additional_info?: InputMaybe<Jsonb_Comparison_Exp>
  amount?: InputMaybe<Numeric_Comparison_Exp>
  cart?: InputMaybe<Cart_Bool_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  currency?: InputMaybe<String_Comparison_Exp>
  customer?: InputMaybe<Customer_Bool_Exp>
  customer_erp_id?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  increment_id?: InputMaybe<Int_Comparison_Exp>
  increment_id_as_text?: InputMaybe<String_Comparison_Exp>
  order_confirmed?: InputMaybe<Order_Confirmed_Bool_Exp>
  order_invoice?: InputMaybe<Order_Invoice_Bool_Exp>
  order_invoices?: InputMaybe<Order_Invoice_Bool_Exp>
  order_invoices_aggregate?: InputMaybe<Order_Invoice_Aggregate_Bool_Exp>
  order_product?: InputMaybe<Order_Product_Bool_Exp>
  order_products?: InputMaybe<Order_Product_Bool_Exp>
  order_products_aggregate?: InputMaybe<Order_Product_Aggregate_Bool_Exp>
  order_transactions?: InputMaybe<Order_Transaction_Bool_Exp>
  order_transactions_aggregate?: InputMaybe<Order_Transaction_Aggregate_Bool_Exp>
  status?: InputMaybe<String_Comparison_Exp>
  type?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "order_by_email" */
export type Order_By_Email = {
  __typename?: 'order_by_email'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  /** An object relationship */
  customer?: Maybe<Customer>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  email_from: Scalars['String']['output']
  email_id: Scalars['String']['output']
  email_subject: Scalars['String']['output']
  errors?: Maybe<Scalars['jsonb']['output']>
  id: Scalars['Int']['output']
  /** An object relationship */
  order?: Maybe<Order>
  order_id?: Maybe<Scalars['uuid']['output']>
  read_at: Scalars['timestamptz']['output']
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** columns and relationships of "order_by_email" */
export type Order_By_EmailErrorsArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** aggregated selection of "order_by_email" */
export type Order_By_Email_Aggregate = {
  __typename?: 'order_by_email_aggregate'
  aggregate?: Maybe<Order_By_Email_Aggregate_Fields>
  nodes: Array<Order_By_Email>
}

/** aggregate fields of "order_by_email" */
export type Order_By_Email_Aggregate_Fields = {
  __typename?: 'order_by_email_aggregate_fields'
  avg?: Maybe<Order_By_Email_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Order_By_Email_Max_Fields>
  min?: Maybe<Order_By_Email_Min_Fields>
  stddev?: Maybe<Order_By_Email_Stddev_Fields>
  stddev_pop?: Maybe<Order_By_Email_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Order_By_Email_Stddev_Samp_Fields>
  sum?: Maybe<Order_By_Email_Sum_Fields>
  var_pop?: Maybe<Order_By_Email_Var_Pop_Fields>
  var_samp?: Maybe<Order_By_Email_Var_Samp_Fields>
  variance?: Maybe<Order_By_Email_Variance_Fields>
}

/** aggregate fields of "order_by_email" */
export type Order_By_Email_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_By_Email_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Order_By_Email_Append_Input = {
  errors?: InputMaybe<Scalars['jsonb']['input']>
}

/** aggregate avg on columns */
export type Order_By_Email_Avg_Fields = {
  __typename?: 'order_by_email_avg_fields'
  id?: Maybe<Scalars['Float']['output']>
}

/** Boolean expression to filter rows from the table "order_by_email". All fields are combined with a logical 'AND'. */
export type Order_By_Email_Bool_Exp = {
  _and?: InputMaybe<Array<Order_By_Email_Bool_Exp>>
  _not?: InputMaybe<Order_By_Email_Bool_Exp>
  _or?: InputMaybe<Array<Order_By_Email_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customer?: InputMaybe<Customer_Bool_Exp>
  customer_erp_id?: InputMaybe<String_Comparison_Exp>
  email_from?: InputMaybe<String_Comparison_Exp>
  email_id?: InputMaybe<String_Comparison_Exp>
  email_subject?: InputMaybe<String_Comparison_Exp>
  errors?: InputMaybe<Jsonb_Comparison_Exp>
  id?: InputMaybe<Int_Comparison_Exp>
  order?: InputMaybe<Order_Bool_Exp>
  order_id?: InputMaybe<Uuid_Comparison_Exp>
  read_at?: InputMaybe<Timestamptz_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "order_by_email" */
export enum Order_By_Email_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrderByEmailPkey = 'order_by_email_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Order_By_Email_Delete_At_Path_Input = {
  errors?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Order_By_Email_Delete_Elem_Input = {
  errors?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Order_By_Email_Delete_Key_Input = {
  errors?: InputMaybe<Scalars['String']['input']>
}

/** input type for incrementing numeric columns in table "order_by_email" */
export type Order_By_Email_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>
}

/** input type for inserting data into table "order_by_email" */
export type Order_By_Email_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  email_from?: InputMaybe<Scalars['String']['input']>
  email_id?: InputMaybe<Scalars['String']['input']>
  email_subject?: InputMaybe<Scalars['String']['input']>
  errors?: InputMaybe<Scalars['jsonb']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  order?: InputMaybe<Order_Obj_Rel_Insert_Input>
  order_id?: InputMaybe<Scalars['uuid']['input']>
  read_at?: InputMaybe<Scalars['timestamptz']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Order_By_Email_Max_Fields = {
  __typename?: 'order_by_email_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  email_from?: Maybe<Scalars['String']['output']>
  email_id?: Maybe<Scalars['String']['output']>
  email_subject?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['Int']['output']>
  order_id?: Maybe<Scalars['uuid']['output']>
  read_at?: Maybe<Scalars['timestamptz']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** aggregate min on columns */
export type Order_By_Email_Min_Fields = {
  __typename?: 'order_by_email_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  email_from?: Maybe<Scalars['String']['output']>
  email_id?: Maybe<Scalars['String']['output']>
  email_subject?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['Int']['output']>
  order_id?: Maybe<Scalars['uuid']['output']>
  read_at?: Maybe<Scalars['timestamptz']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** response of any mutation on the table "order_by_email" */
export type Order_By_Email_Mutation_Response = {
  __typename?: 'order_by_email_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Order_By_Email>
}

/** on_conflict condition type for table "order_by_email" */
export type Order_By_Email_On_Conflict = {
  constraint: Order_By_Email_Constraint
  update_columns?: Array<Order_By_Email_Update_Column>
  where?: InputMaybe<Order_By_Email_Bool_Exp>
}

/** Ordering options when selecting data from "order_by_email". */
export type Order_By_Email_Order_By = {
  created_at?: InputMaybe<Order_By>
  customer?: InputMaybe<Customer_Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  email_from?: InputMaybe<Order_By>
  email_id?: InputMaybe<Order_By>
  email_subject?: InputMaybe<Order_By>
  errors?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  order?: InputMaybe<Order_Order_By>
  order_id?: InputMaybe<Order_By>
  read_at?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: order_by_email */
export type Order_By_Email_Pk_Columns_Input = {
  id: Scalars['Int']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Order_By_Email_Prepend_Input = {
  errors?: InputMaybe<Scalars['jsonb']['input']>
}

/** select columns of table "order_by_email" */
export enum Order_By_Email_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  EmailFrom = 'email_from',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  EmailSubject = 'email_subject',
  /** column name */
  Errors = 'errors',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "order_by_email" */
export type Order_By_Email_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  email_from?: InputMaybe<Scalars['String']['input']>
  email_id?: InputMaybe<Scalars['String']['input']>
  email_subject?: InputMaybe<Scalars['String']['input']>
  errors?: InputMaybe<Scalars['jsonb']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  order_id?: InputMaybe<Scalars['uuid']['input']>
  read_at?: InputMaybe<Scalars['timestamptz']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate stddev on columns */
export type Order_By_Email_Stddev_Fields = {
  __typename?: 'order_by_email_stddev_fields'
  id?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_pop on columns */
export type Order_By_Email_Stddev_Pop_Fields = {
  __typename?: 'order_by_email_stddev_pop_fields'
  id?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_samp on columns */
export type Order_By_Email_Stddev_Samp_Fields = {
  __typename?: 'order_by_email_stddev_samp_fields'
  id?: Maybe<Scalars['Float']['output']>
}

/** Streaming cursor of the table "order_by_email" */
export type Order_By_Email_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_By_Email_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Order_By_Email_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  email_from?: InputMaybe<Scalars['String']['input']>
  email_id?: InputMaybe<Scalars['String']['input']>
  email_subject?: InputMaybe<Scalars['String']['input']>
  errors?: InputMaybe<Scalars['jsonb']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  order_id?: InputMaybe<Scalars['uuid']['input']>
  read_at?: InputMaybe<Scalars['timestamptz']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate sum on columns */
export type Order_By_Email_Sum_Fields = {
  __typename?: 'order_by_email_sum_fields'
  id?: Maybe<Scalars['Int']['output']>
}

/** update columns of table "order_by_email" */
export enum Order_By_Email_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  EmailFrom = 'email_from',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  EmailSubject = 'email_subject',
  /** column name */
  Errors = 'errors',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Order_By_Email_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Order_By_Email_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Order_By_Email_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Order_By_Email_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Order_By_Email_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Order_By_Email_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Order_By_Email_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_By_Email_Set_Input>
  /** filter the rows which have to be updated */
  where: Order_By_Email_Bool_Exp
}

/** aggregate var_pop on columns */
export type Order_By_Email_Var_Pop_Fields = {
  __typename?: 'order_by_email_var_pop_fields'
  id?: Maybe<Scalars['Float']['output']>
}

/** aggregate var_samp on columns */
export type Order_By_Email_Var_Samp_Fields = {
  __typename?: 'order_by_email_var_samp_fields'
  id?: Maybe<Scalars['Float']['output']>
}

/** aggregate variance on columns */
export type Order_By_Email_Variance_Fields = {
  __typename?: 'order_by_email_variance_fields'
  id?: Maybe<Scalars['Float']['output']>
}

/** columns and relationships of "order_confirmed" */
export type Order_Confirmed = {
  __typename?: 'order_confirmed'
  created_at: Scalars['timestamptz']['output']
  /** An object relationship */
  customer: Customer
  customer_erp_id: Scalars['String']['output']
  date_order: Scalars['date']['output']
  erp_id: Scalars['String']['output']
  external_id?: Maybe<Scalars['String']['output']>
  id: Scalars['uuid']['output']
  is_invoiced: Scalars['Boolean']['output']
  is_under_processing: Scalars['Boolean']['output']
  /** An object relationship */
  order?: Maybe<Order>
  pdf_created_at?: Maybe<Scalars['timestamptz']['output']>
  pdf_filename?: Maybe<Scalars['String']['output']>
  rev: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** aggregated selection of "order_confirmed" */
export type Order_Confirmed_Aggregate = {
  __typename?: 'order_confirmed_aggregate'
  aggregate?: Maybe<Order_Confirmed_Aggregate_Fields>
  nodes: Array<Order_Confirmed>
}

export type Order_Confirmed_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Order_Confirmed_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<Order_Confirmed_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<Order_Confirmed_Aggregate_Bool_Exp_Count>
}

export type Order_Confirmed_Aggregate_Bool_Exp_Bool_And = {
  arguments: Order_Confirmed_Select_Column_Order_Confirmed_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Order_Confirmed_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Order_Confirmed_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Order_Confirmed_Select_Column_Order_Confirmed_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Order_Confirmed_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Order_Confirmed_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Order_Confirmed_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Order_Confirmed_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "order_confirmed" */
export type Order_Confirmed_Aggregate_Fields = {
  __typename?: 'order_confirmed_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Order_Confirmed_Max_Fields>
  min?: Maybe<Order_Confirmed_Min_Fields>
}

/** aggregate fields of "order_confirmed" */
export type Order_Confirmed_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Confirmed_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "order_confirmed" */
export type Order_Confirmed_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Order_Confirmed_Max_Order_By>
  min?: InputMaybe<Order_Confirmed_Min_Order_By>
}

/** input type for inserting array relation for remote table "order_confirmed" */
export type Order_Confirmed_Arr_Rel_Insert_Input = {
  data: Array<Order_Confirmed_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Order_Confirmed_On_Conflict>
}

/** Boolean expression to filter rows from the table "order_confirmed". All fields are combined with a logical 'AND'. */
export type Order_Confirmed_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Confirmed_Bool_Exp>>
  _not?: InputMaybe<Order_Confirmed_Bool_Exp>
  _or?: InputMaybe<Array<Order_Confirmed_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customer?: InputMaybe<Customer_Bool_Exp>
  customer_erp_id?: InputMaybe<String_Comparison_Exp>
  date_order?: InputMaybe<Date_Comparison_Exp>
  erp_id?: InputMaybe<String_Comparison_Exp>
  external_id?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  is_invoiced?: InputMaybe<Boolean_Comparison_Exp>
  is_under_processing?: InputMaybe<Boolean_Comparison_Exp>
  order?: InputMaybe<Order_Bool_Exp>
  pdf_created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  pdf_filename?: InputMaybe<String_Comparison_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "order_confirmed" */
export enum Order_Confirmed_Constraint {
  /** unique or primary key constraint on columns "erp_id" */
  OrderConfirmedErpIdKey = 'order_confirmed_erp_id_key',
  /** unique or primary key constraint on columns "id" */
  OrderConfirmedPkey = 'order_confirmed_pkey',
}

/** input type for inserting data into table "order_confirmed" */
export type Order_Confirmed_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  date_order?: InputMaybe<Scalars['date']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  external_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  is_invoiced?: InputMaybe<Scalars['Boolean']['input']>
  is_under_processing?: InputMaybe<Scalars['Boolean']['input']>
  order?: InputMaybe<Order_Obj_Rel_Insert_Input>
  pdf_created_at?: InputMaybe<Scalars['timestamptz']['input']>
  pdf_filename?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Order_Confirmed_Max_Fields = {
  __typename?: 'order_confirmed_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  date_order?: Maybe<Scalars['date']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  external_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  pdf_created_at?: Maybe<Scalars['timestamptz']['output']>
  pdf_filename?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "order_confirmed" */
export type Order_Confirmed_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  date_order?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  external_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  pdf_created_at?: InputMaybe<Order_By>
  pdf_filename?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Order_Confirmed_Min_Fields = {
  __typename?: 'order_confirmed_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  date_order?: Maybe<Scalars['date']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  external_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  pdf_created_at?: Maybe<Scalars['timestamptz']['output']>
  pdf_filename?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "order_confirmed" */
export type Order_Confirmed_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  date_order?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  external_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  pdf_created_at?: InputMaybe<Order_By>
  pdf_filename?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "order_confirmed" */
export type Order_Confirmed_Mutation_Response = {
  __typename?: 'order_confirmed_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Order_Confirmed>
}

/** input type for inserting object relation for remote table "order_confirmed" */
export type Order_Confirmed_Obj_Rel_Insert_Input = {
  data: Order_Confirmed_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Order_Confirmed_On_Conflict>
}

/** on_conflict condition type for table "order_confirmed" */
export type Order_Confirmed_On_Conflict = {
  constraint: Order_Confirmed_Constraint
  update_columns?: Array<Order_Confirmed_Update_Column>
  where?: InputMaybe<Order_Confirmed_Bool_Exp>
}

/** Ordering options when selecting data from "order_confirmed". */
export type Order_Confirmed_Order_By = {
  created_at?: InputMaybe<Order_By>
  customer?: InputMaybe<Customer_Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  date_order?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  external_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  is_invoiced?: InputMaybe<Order_By>
  is_under_processing?: InputMaybe<Order_By>
  order?: InputMaybe<Order_Order_By>
  pdf_created_at?: InputMaybe<Order_By>
  pdf_filename?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: order_confirmed */
export type Order_Confirmed_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "order_confirmed" */
export enum Order_Confirmed_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  DateOrder = 'date_order',
  /** column name */
  ErpId = 'erp_id',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsInvoiced = 'is_invoiced',
  /** column name */
  IsUnderProcessing = 'is_under_processing',
  /** column name */
  PdfCreatedAt = 'pdf_created_at',
  /** column name */
  PdfFilename = 'pdf_filename',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "order_confirmed_aggregate_bool_exp_bool_and_arguments_columns" columns of table "order_confirmed" */
export enum Order_Confirmed_Select_Column_Order_Confirmed_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsInvoiced = 'is_invoiced',
  /** column name */
  IsUnderProcessing = 'is_under_processing',
}

/** select "order_confirmed_aggregate_bool_exp_bool_or_arguments_columns" columns of table "order_confirmed" */
export enum Order_Confirmed_Select_Column_Order_Confirmed_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsInvoiced = 'is_invoiced',
  /** column name */
  IsUnderProcessing = 'is_under_processing',
}

/** input type for updating data in table "order_confirmed" */
export type Order_Confirmed_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  date_order?: InputMaybe<Scalars['date']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  external_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  is_invoiced?: InputMaybe<Scalars['Boolean']['input']>
  is_under_processing?: InputMaybe<Scalars['Boolean']['input']>
  pdf_created_at?: InputMaybe<Scalars['timestamptz']['input']>
  pdf_filename?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "order_confirmed" */
export type Order_Confirmed_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Confirmed_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Order_Confirmed_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  date_order?: InputMaybe<Scalars['date']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  external_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  is_invoiced?: InputMaybe<Scalars['Boolean']['input']>
  is_under_processing?: InputMaybe<Scalars['Boolean']['input']>
  pdf_created_at?: InputMaybe<Scalars['timestamptz']['input']>
  pdf_filename?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "order_confirmed" */
export enum Order_Confirmed_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  DateOrder = 'date_order',
  /** column name */
  ErpId = 'erp_id',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsInvoiced = 'is_invoiced',
  /** column name */
  IsUnderProcessing = 'is_under_processing',
  /** column name */
  PdfCreatedAt = 'pdf_created_at',
  /** column name */
  PdfFilename = 'pdf_filename',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Order_Confirmed_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_Confirmed_Set_Input>
  /** filter the rows which have to be updated */
  where: Order_Confirmed_Bool_Exp
}

/** unique or primary key constraints on table "order" */
export enum Order_Constraint {
  /** unique or primary key constraint on columns "increment_id" */
  OrderIncrementIdKey = 'order_increment_id_key',
  /** unique or primary key constraint on columns "id" */
  OrderPkey = 'order_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Order_Delete_At_Path_Input = {
  additional_info?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Order_Delete_Elem_Input = {
  additional_info?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Order_Delete_Key_Input = {
  additional_info?: InputMaybe<Scalars['String']['input']>
}

/** input type for incrementing numeric columns in table "order" */
export type Order_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>
  increment_id?: InputMaybe<Scalars['Int']['input']>
}

/** input type for inserting data into table "order" */
export type Order_Insert_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  amount?: InputMaybe<Scalars['numeric']['input']>
  cart?: InputMaybe<Cart_Obj_Rel_Insert_Input>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  currency?: InputMaybe<Scalars['String']['input']>
  customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  increment_id?: InputMaybe<Scalars['Int']['input']>
  increment_id_as_text?: InputMaybe<Scalars['String']['input']>
  order_confirmed?: InputMaybe<Order_Confirmed_Obj_Rel_Insert_Input>
  order_invoice?: InputMaybe<Order_Invoice_Obj_Rel_Insert_Input>
  order_invoices?: InputMaybe<Order_Invoice_Arr_Rel_Insert_Input>
  order_product?: InputMaybe<Order_Product_Obj_Rel_Insert_Input>
  order_products?: InputMaybe<Order_Product_Arr_Rel_Insert_Input>
  order_transactions?: InputMaybe<Order_Transaction_Arr_Rel_Insert_Input>
  status?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** columns and relationships of "order_invoice" */
export type Order_Invoice = {
  __typename?: 'order_invoice'
  additional_info?: Maybe<Scalars['jsonb']['output']>
  created_at: Scalars['timestamptz']['output']
  id: Scalars['uuid']['output']
  /** An object relationship */
  order?: Maybe<Order>
  order_id?: Maybe<Scalars['uuid']['output']>
  /** An array relationship */
  order_invoice_items: Array<Order_Invoice_Item>
  /** An aggregate relationship */
  order_invoice_items_aggregate: Order_Invoice_Item_Aggregate
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "order_invoice" */
export type Order_InvoiceAdditional_InfoArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** columns and relationships of "order_invoice" */
export type Order_InvoiceOrder_Invoice_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Order_Invoice_Item_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Invoice_Item_Order_By>>
  where?: InputMaybe<Order_Invoice_Item_Bool_Exp>
}

/** columns and relationships of "order_invoice" */
export type Order_InvoiceOrder_Invoice_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Invoice_Item_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Invoice_Item_Order_By>>
  where?: InputMaybe<Order_Invoice_Item_Bool_Exp>
}

/** aggregated selection of "order_invoice" */
export type Order_Invoice_Aggregate = {
  __typename?: 'order_invoice_aggregate'
  aggregate?: Maybe<Order_Invoice_Aggregate_Fields>
  nodes: Array<Order_Invoice>
}

export type Order_Invoice_Aggregate_Bool_Exp = {
  count?: InputMaybe<Order_Invoice_Aggregate_Bool_Exp_Count>
}

export type Order_Invoice_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Order_Invoice_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Order_Invoice_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "order_invoice" */
export type Order_Invoice_Aggregate_Fields = {
  __typename?: 'order_invoice_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Order_Invoice_Max_Fields>
  min?: Maybe<Order_Invoice_Min_Fields>
}

/** aggregate fields of "order_invoice" */
export type Order_Invoice_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Invoice_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "order_invoice" */
export type Order_Invoice_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Order_Invoice_Max_Order_By>
  min?: InputMaybe<Order_Invoice_Min_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Order_Invoice_Append_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
}

/** input type for inserting array relation for remote table "order_invoice" */
export type Order_Invoice_Arr_Rel_Insert_Input = {
  data: Array<Order_Invoice_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Order_Invoice_On_Conflict>
}

/** Boolean expression to filter rows from the table "order_invoice". All fields are combined with a logical 'AND'. */
export type Order_Invoice_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Invoice_Bool_Exp>>
  _not?: InputMaybe<Order_Invoice_Bool_Exp>
  _or?: InputMaybe<Array<Order_Invoice_Bool_Exp>>
  additional_info?: InputMaybe<Jsonb_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  order?: InputMaybe<Order_Bool_Exp>
  order_id?: InputMaybe<Uuid_Comparison_Exp>
  order_invoice_items?: InputMaybe<Order_Invoice_Item_Bool_Exp>
  order_invoice_items_aggregate?: InputMaybe<Order_Invoice_Item_Aggregate_Bool_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "order_invoice" */
export enum Order_Invoice_Constraint {
  /** unique or primary key constraint on columns "order_id" */
  OrderInvoiceOrderIdKey = 'order_invoice_order_id_key',
  /** unique or primary key constraint on columns "id" */
  OrderInvoicePkey = 'order_invoice_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Order_Invoice_Delete_At_Path_Input = {
  additional_info?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Order_Invoice_Delete_Elem_Input = {
  additional_info?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Order_Invoice_Delete_Key_Input = {
  additional_info?: InputMaybe<Scalars['String']['input']>
}

/** input type for inserting data into table "order_invoice" */
export type Order_Invoice_Insert_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  order?: InputMaybe<Order_Obj_Rel_Insert_Input>
  order_id?: InputMaybe<Scalars['uuid']['input']>
  order_invoice_items?: InputMaybe<Order_Invoice_Item_Arr_Rel_Insert_Input>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** columns and relationships of "order_invoice_item" */
export type Order_Invoice_Item = {
  __typename?: 'order_invoice_item'
  additional_info?: Maybe<Scalars['jsonb']['output']>
  amount: Scalars['numeric']['output']
  created_at: Scalars['timestamptz']['output']
  currency: Scalars['String']['output']
  id: Scalars['uuid']['output']
  /** An object relationship */
  invoice?: Maybe<Invoice>
  invoice_erp_id?: Maybe<Scalars['String']['output']>
  /** An object relationship */
  invoice_overdue?: Maybe<Invoice_Overdue>
  invoice_overdue_erp_id: Scalars['String']['output']
  /** An object relationship */
  order_invoice: Order_Invoice
  order_invoice_id: Scalars['uuid']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "order_invoice_item" */
export type Order_Invoice_ItemAdditional_InfoArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** aggregated selection of "order_invoice_item" */
export type Order_Invoice_Item_Aggregate = {
  __typename?: 'order_invoice_item_aggregate'
  aggregate?: Maybe<Order_Invoice_Item_Aggregate_Fields>
  nodes: Array<Order_Invoice_Item>
}

export type Order_Invoice_Item_Aggregate_Bool_Exp = {
  count?: InputMaybe<Order_Invoice_Item_Aggregate_Bool_Exp_Count>
}

export type Order_Invoice_Item_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Order_Invoice_Item_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Order_Invoice_Item_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "order_invoice_item" */
export type Order_Invoice_Item_Aggregate_Fields = {
  __typename?: 'order_invoice_item_aggregate_fields'
  avg?: Maybe<Order_Invoice_Item_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Order_Invoice_Item_Max_Fields>
  min?: Maybe<Order_Invoice_Item_Min_Fields>
  stddev?: Maybe<Order_Invoice_Item_Stddev_Fields>
  stddev_pop?: Maybe<Order_Invoice_Item_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Order_Invoice_Item_Stddev_Samp_Fields>
  sum?: Maybe<Order_Invoice_Item_Sum_Fields>
  var_pop?: Maybe<Order_Invoice_Item_Var_Pop_Fields>
  var_samp?: Maybe<Order_Invoice_Item_Var_Samp_Fields>
  variance?: Maybe<Order_Invoice_Item_Variance_Fields>
}

/** aggregate fields of "order_invoice_item" */
export type Order_Invoice_Item_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Invoice_Item_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "order_invoice_item" */
export type Order_Invoice_Item_Aggregate_Order_By = {
  avg?: InputMaybe<Order_Invoice_Item_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Order_Invoice_Item_Max_Order_By>
  min?: InputMaybe<Order_Invoice_Item_Min_Order_By>
  stddev?: InputMaybe<Order_Invoice_Item_Stddev_Order_By>
  stddev_pop?: InputMaybe<Order_Invoice_Item_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Order_Invoice_Item_Stddev_Samp_Order_By>
  sum?: InputMaybe<Order_Invoice_Item_Sum_Order_By>
  var_pop?: InputMaybe<Order_Invoice_Item_Var_Pop_Order_By>
  var_samp?: InputMaybe<Order_Invoice_Item_Var_Samp_Order_By>
  variance?: InputMaybe<Order_Invoice_Item_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Order_Invoice_Item_Append_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
}

/** input type for inserting array relation for remote table "order_invoice_item" */
export type Order_Invoice_Item_Arr_Rel_Insert_Input = {
  data: Array<Order_Invoice_Item_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Order_Invoice_Item_On_Conflict>
}

/** aggregate avg on columns */
export type Order_Invoice_Item_Avg_Fields = {
  __typename?: 'order_invoice_item_avg_fields'
  amount?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "order_invoice_item" */
export type Order_Invoice_Item_Avg_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "order_invoice_item". All fields are combined with a logical 'AND'. */
export type Order_Invoice_Item_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Invoice_Item_Bool_Exp>>
  _not?: InputMaybe<Order_Invoice_Item_Bool_Exp>
  _or?: InputMaybe<Array<Order_Invoice_Item_Bool_Exp>>
  additional_info?: InputMaybe<Jsonb_Comparison_Exp>
  amount?: InputMaybe<Numeric_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  currency?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  invoice?: InputMaybe<Invoice_Bool_Exp>
  invoice_erp_id?: InputMaybe<String_Comparison_Exp>
  invoice_overdue?: InputMaybe<Invoice_Overdue_Bool_Exp>
  invoice_overdue_erp_id?: InputMaybe<String_Comparison_Exp>
  order_invoice?: InputMaybe<Order_Invoice_Bool_Exp>
  order_invoice_id?: InputMaybe<Uuid_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "order_invoice_item" */
export enum Order_Invoice_Item_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrderInvoiceItemPkey = 'order_invoice_item_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Order_Invoice_Item_Delete_At_Path_Input = {
  additional_info?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Order_Invoice_Item_Delete_Elem_Input = {
  additional_info?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Order_Invoice_Item_Delete_Key_Input = {
  additional_info?: InputMaybe<Scalars['String']['input']>
}

/** input type for incrementing numeric columns in table "order_invoice_item" */
export type Order_Invoice_Item_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>
}

/** input type for inserting data into table "order_invoice_item" */
export type Order_Invoice_Item_Insert_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  amount?: InputMaybe<Scalars['numeric']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  currency?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  invoice?: InputMaybe<Invoice_Obj_Rel_Insert_Input>
  invoice_erp_id?: InputMaybe<Scalars['String']['input']>
  invoice_overdue?: InputMaybe<Invoice_Overdue_Obj_Rel_Insert_Input>
  invoice_overdue_erp_id?: InputMaybe<Scalars['String']['input']>
  order_invoice?: InputMaybe<Order_Invoice_Obj_Rel_Insert_Input>
  order_invoice_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Order_Invoice_Item_Max_Fields = {
  __typename?: 'order_invoice_item_max_fields'
  amount?: Maybe<Scalars['numeric']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  currency?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  invoice_erp_id?: Maybe<Scalars['String']['output']>
  invoice_overdue_erp_id?: Maybe<Scalars['String']['output']>
  order_invoice_id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "order_invoice_item" */
export type Order_Invoice_Item_Max_Order_By = {
  amount?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  currency?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  invoice_erp_id?: InputMaybe<Order_By>
  invoice_overdue_erp_id?: InputMaybe<Order_By>
  order_invoice_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Order_Invoice_Item_Min_Fields = {
  __typename?: 'order_invoice_item_min_fields'
  amount?: Maybe<Scalars['numeric']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  currency?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  invoice_erp_id?: Maybe<Scalars['String']['output']>
  invoice_overdue_erp_id?: Maybe<Scalars['String']['output']>
  order_invoice_id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "order_invoice_item" */
export type Order_Invoice_Item_Min_Order_By = {
  amount?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  currency?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  invoice_erp_id?: InputMaybe<Order_By>
  invoice_overdue_erp_id?: InputMaybe<Order_By>
  order_invoice_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "order_invoice_item" */
export type Order_Invoice_Item_Mutation_Response = {
  __typename?: 'order_invoice_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Order_Invoice_Item>
}

/** input type for inserting object relation for remote table "order_invoice_item" */
export type Order_Invoice_Item_Obj_Rel_Insert_Input = {
  data: Order_Invoice_Item_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Order_Invoice_Item_On_Conflict>
}

/** on_conflict condition type for table "order_invoice_item" */
export type Order_Invoice_Item_On_Conflict = {
  constraint: Order_Invoice_Item_Constraint
  update_columns?: Array<Order_Invoice_Item_Update_Column>
  where?: InputMaybe<Order_Invoice_Item_Bool_Exp>
}

/** Ordering options when selecting data from "order_invoice_item". */
export type Order_Invoice_Item_Order_By = {
  additional_info?: InputMaybe<Order_By>
  amount?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  currency?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  invoice?: InputMaybe<Invoice_Order_By>
  invoice_erp_id?: InputMaybe<Order_By>
  invoice_overdue?: InputMaybe<Invoice_Overdue_Order_By>
  invoice_overdue_erp_id?: InputMaybe<Order_By>
  order_invoice?: InputMaybe<Order_Invoice_Order_By>
  order_invoice_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: order_invoice_item */
export type Order_Invoice_Item_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Order_Invoice_Item_Prepend_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
}

/** select columns of table "order_invoice_item" */
export enum Order_Invoice_Item_Select_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceErpId = 'invoice_erp_id',
  /** column name */
  InvoiceOverdueErpId = 'invoice_overdue_erp_id',
  /** column name */
  OrderInvoiceId = 'order_invoice_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "order_invoice_item" */
export type Order_Invoice_Item_Set_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  amount?: InputMaybe<Scalars['numeric']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  currency?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  invoice_erp_id?: InputMaybe<Scalars['String']['input']>
  invoice_overdue_erp_id?: InputMaybe<Scalars['String']['input']>
  order_invoice_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate stddev on columns */
export type Order_Invoice_Item_Stddev_Fields = {
  __typename?: 'order_invoice_item_stddev_fields'
  amount?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "order_invoice_item" */
export type Order_Invoice_Item_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Order_Invoice_Item_Stddev_Pop_Fields = {
  __typename?: 'order_invoice_item_stddev_pop_fields'
  amount?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "order_invoice_item" */
export type Order_Invoice_Item_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Order_Invoice_Item_Stddev_Samp_Fields = {
  __typename?: 'order_invoice_item_stddev_samp_fields'
  amount?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "order_invoice_item" */
export type Order_Invoice_Item_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "order_invoice_item" */
export type Order_Invoice_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Invoice_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Order_Invoice_Item_Stream_Cursor_Value_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  amount?: InputMaybe<Scalars['numeric']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  currency?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  invoice_erp_id?: InputMaybe<Scalars['String']['input']>
  invoice_overdue_erp_id?: InputMaybe<Scalars['String']['input']>
  order_invoice_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate sum on columns */
export type Order_Invoice_Item_Sum_Fields = {
  __typename?: 'order_invoice_item_sum_fields'
  amount?: Maybe<Scalars['numeric']['output']>
}

/** order by sum() on columns of table "order_invoice_item" */
export type Order_Invoice_Item_Sum_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** update columns of table "order_invoice_item" */
export enum Order_Invoice_Item_Update_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceErpId = 'invoice_erp_id',
  /** column name */
  InvoiceOverdueErpId = 'invoice_overdue_erp_id',
  /** column name */
  OrderInvoiceId = 'order_invoice_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Order_Invoice_Item_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Order_Invoice_Item_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Order_Invoice_Item_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Order_Invoice_Item_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Order_Invoice_Item_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Order_Invoice_Item_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Order_Invoice_Item_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_Invoice_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Order_Invoice_Item_Bool_Exp
}

/** aggregate var_pop on columns */
export type Order_Invoice_Item_Var_Pop_Fields = {
  __typename?: 'order_invoice_item_var_pop_fields'
  amount?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "order_invoice_item" */
export type Order_Invoice_Item_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Order_Invoice_Item_Var_Samp_Fields = {
  __typename?: 'order_invoice_item_var_samp_fields'
  amount?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "order_invoice_item" */
export type Order_Invoice_Item_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Order_Invoice_Item_Variance_Fields = {
  __typename?: 'order_invoice_item_variance_fields'
  amount?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "order_invoice_item" */
export type Order_Invoice_Item_Variance_Order_By = {
  amount?: InputMaybe<Order_By>
}

/** aggregate max on columns */
export type Order_Invoice_Max_Fields = {
  __typename?: 'order_invoice_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  order_id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "order_invoice" */
export type Order_Invoice_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  order_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Order_Invoice_Min_Fields = {
  __typename?: 'order_invoice_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  order_id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "order_invoice" */
export type Order_Invoice_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  order_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "order_invoice" */
export type Order_Invoice_Mutation_Response = {
  __typename?: 'order_invoice_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Order_Invoice>
}

/** input type for inserting object relation for remote table "order_invoice" */
export type Order_Invoice_Obj_Rel_Insert_Input = {
  data: Order_Invoice_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Order_Invoice_On_Conflict>
}

/** on_conflict condition type for table "order_invoice" */
export type Order_Invoice_On_Conflict = {
  constraint: Order_Invoice_Constraint
  update_columns?: Array<Order_Invoice_Update_Column>
  where?: InputMaybe<Order_Invoice_Bool_Exp>
}

/** Ordering options when selecting data from "order_invoice". */
export type Order_Invoice_Order_By = {
  additional_info?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  order?: InputMaybe<Order_Order_By>
  order_id?: InputMaybe<Order_By>
  order_invoice_items_aggregate?: InputMaybe<Order_Invoice_Item_Aggregate_Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: order_invoice */
export type Order_Invoice_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Order_Invoice_Prepend_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
}

/** select columns of table "order_invoice" */
export enum Order_Invoice_Select_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "order_invoice" */
export type Order_Invoice_Set_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  order_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "order_invoice" */
export type Order_Invoice_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Invoice_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Order_Invoice_Stream_Cursor_Value_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  order_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "order_invoice" */
export enum Order_Invoice_Update_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Order_Invoice_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Order_Invoice_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Order_Invoice_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Order_Invoice_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Order_Invoice_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Order_Invoice_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_Invoice_Set_Input>
  /** filter the rows which have to be updated */
  where: Order_Invoice_Bool_Exp
}

/** columns and relationships of "order_item_unshipped" */
export type Order_Item_Unshipped = {
  __typename?: 'order_item_unshipped'
  created_at: Scalars['timestamptz']['output']
  /** An object relationship */
  customer: Customer
  customer_erp_id: Scalars['String']['output']
  description: Scalars['String']['output']
  doc_customer_id: Scalars['String']['output']
  doc_erp_id: Scalars['String']['output']
  erp_key: Scalars['String']['output']
  first_arrival_date?: Maybe<Scalars['date']['output']>
  id: Scalars['uuid']['output']
  /** An object relationship */
  order?: Maybe<Order>
  /** An object relationship */
  order_confirmed?: Maybe<Order_Confirmed>
  original_order_erp_id: Scalars['String']['output']
  product_erp_id: Scalars['String']['output']
  qty?: Maybe<Scalars['Int']['output']>
  rev: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** aggregated selection of "order_item_unshipped" */
export type Order_Item_Unshipped_Aggregate = {
  __typename?: 'order_item_unshipped_aggregate'
  aggregate?: Maybe<Order_Item_Unshipped_Aggregate_Fields>
  nodes: Array<Order_Item_Unshipped>
}

export type Order_Item_Unshipped_Aggregate_Bool_Exp = {
  count?: InputMaybe<Order_Item_Unshipped_Aggregate_Bool_Exp_Count>
}

export type Order_Item_Unshipped_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Order_Item_Unshipped_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Order_Item_Unshipped_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "order_item_unshipped" */
export type Order_Item_Unshipped_Aggregate_Fields = {
  __typename?: 'order_item_unshipped_aggregate_fields'
  avg?: Maybe<Order_Item_Unshipped_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Order_Item_Unshipped_Max_Fields>
  min?: Maybe<Order_Item_Unshipped_Min_Fields>
  stddev?: Maybe<Order_Item_Unshipped_Stddev_Fields>
  stddev_pop?: Maybe<Order_Item_Unshipped_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Order_Item_Unshipped_Stddev_Samp_Fields>
  sum?: Maybe<Order_Item_Unshipped_Sum_Fields>
  var_pop?: Maybe<Order_Item_Unshipped_Var_Pop_Fields>
  var_samp?: Maybe<Order_Item_Unshipped_Var_Samp_Fields>
  variance?: Maybe<Order_Item_Unshipped_Variance_Fields>
}

/** aggregate fields of "order_item_unshipped" */
export type Order_Item_Unshipped_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Item_Unshipped_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "order_item_unshipped" */
export type Order_Item_Unshipped_Aggregate_Order_By = {
  avg?: InputMaybe<Order_Item_Unshipped_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Order_Item_Unshipped_Max_Order_By>
  min?: InputMaybe<Order_Item_Unshipped_Min_Order_By>
  stddev?: InputMaybe<Order_Item_Unshipped_Stddev_Order_By>
  stddev_pop?: InputMaybe<Order_Item_Unshipped_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Order_Item_Unshipped_Stddev_Samp_Order_By>
  sum?: InputMaybe<Order_Item_Unshipped_Sum_Order_By>
  var_pop?: InputMaybe<Order_Item_Unshipped_Var_Pop_Order_By>
  var_samp?: InputMaybe<Order_Item_Unshipped_Var_Samp_Order_By>
  variance?: InputMaybe<Order_Item_Unshipped_Variance_Order_By>
}

/** input type for inserting array relation for remote table "order_item_unshipped" */
export type Order_Item_Unshipped_Arr_Rel_Insert_Input = {
  data: Array<Order_Item_Unshipped_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Order_Item_Unshipped_On_Conflict>
}

/** aggregate avg on columns */
export type Order_Item_Unshipped_Avg_Fields = {
  __typename?: 'order_item_unshipped_avg_fields'
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "order_item_unshipped" */
export type Order_Item_Unshipped_Avg_Order_By = {
  qty?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "order_item_unshipped". All fields are combined with a logical 'AND'. */
export type Order_Item_Unshipped_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Item_Unshipped_Bool_Exp>>
  _not?: InputMaybe<Order_Item_Unshipped_Bool_Exp>
  _or?: InputMaybe<Array<Order_Item_Unshipped_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customer?: InputMaybe<Customer_Bool_Exp>
  customer_erp_id?: InputMaybe<String_Comparison_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  doc_customer_id?: InputMaybe<String_Comparison_Exp>
  doc_erp_id?: InputMaybe<String_Comparison_Exp>
  erp_key?: InputMaybe<String_Comparison_Exp>
  first_arrival_date?: InputMaybe<Date_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  order?: InputMaybe<Order_Bool_Exp>
  order_confirmed?: InputMaybe<Order_Confirmed_Bool_Exp>
  original_order_erp_id?: InputMaybe<String_Comparison_Exp>
  product_erp_id?: InputMaybe<String_Comparison_Exp>
  qty?: InputMaybe<Int_Comparison_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "order_item_unshipped" */
export enum Order_Item_Unshipped_Constraint {
  /** unique or primary key constraint on columns "erp_key" */
  OrderItemUnshippedErpKeyKey = 'order_item_unshipped_erp_key_key',
  /** unique or primary key constraint on columns "id" */
  OrderItemUnshippedPkey = 'order_item_unshipped_pkey',
}

/** input type for incrementing numeric columns in table "order_item_unshipped" */
export type Order_Item_Unshipped_Inc_Input = {
  qty?: InputMaybe<Scalars['Int']['input']>
}

/** input type for inserting data into table "order_item_unshipped" */
export type Order_Item_Unshipped_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  doc_customer_id?: InputMaybe<Scalars['String']['input']>
  doc_erp_id?: InputMaybe<Scalars['String']['input']>
  erp_key?: InputMaybe<Scalars['String']['input']>
  first_arrival_date?: InputMaybe<Scalars['date']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  order?: InputMaybe<Order_Obj_Rel_Insert_Input>
  order_confirmed?: InputMaybe<Order_Confirmed_Obj_Rel_Insert_Input>
  original_order_erp_id?: InputMaybe<Scalars['String']['input']>
  product_erp_id?: InputMaybe<Scalars['String']['input']>
  qty?: InputMaybe<Scalars['Int']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Order_Item_Unshipped_Max_Fields = {
  __typename?: 'order_item_unshipped_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  doc_customer_id?: Maybe<Scalars['String']['output']>
  doc_erp_id?: Maybe<Scalars['String']['output']>
  erp_key?: Maybe<Scalars['String']['output']>
  first_arrival_date?: Maybe<Scalars['date']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  original_order_erp_id?: Maybe<Scalars['String']['output']>
  product_erp_id?: Maybe<Scalars['String']['output']>
  qty?: Maybe<Scalars['Int']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "order_item_unshipped" */
export type Order_Item_Unshipped_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  doc_customer_id?: InputMaybe<Order_By>
  doc_erp_id?: InputMaybe<Order_By>
  erp_key?: InputMaybe<Order_By>
  first_arrival_date?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  original_order_erp_id?: InputMaybe<Order_By>
  product_erp_id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Order_Item_Unshipped_Min_Fields = {
  __typename?: 'order_item_unshipped_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  doc_customer_id?: Maybe<Scalars['String']['output']>
  doc_erp_id?: Maybe<Scalars['String']['output']>
  erp_key?: Maybe<Scalars['String']['output']>
  first_arrival_date?: Maybe<Scalars['date']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  original_order_erp_id?: Maybe<Scalars['String']['output']>
  product_erp_id?: Maybe<Scalars['String']['output']>
  qty?: Maybe<Scalars['Int']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "order_item_unshipped" */
export type Order_Item_Unshipped_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  doc_customer_id?: InputMaybe<Order_By>
  doc_erp_id?: InputMaybe<Order_By>
  erp_key?: InputMaybe<Order_By>
  first_arrival_date?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  original_order_erp_id?: InputMaybe<Order_By>
  product_erp_id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "order_item_unshipped" */
export type Order_Item_Unshipped_Mutation_Response = {
  __typename?: 'order_item_unshipped_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Order_Item_Unshipped>
}

/** on_conflict condition type for table "order_item_unshipped" */
export type Order_Item_Unshipped_On_Conflict = {
  constraint: Order_Item_Unshipped_Constraint
  update_columns?: Array<Order_Item_Unshipped_Update_Column>
  where?: InputMaybe<Order_Item_Unshipped_Bool_Exp>
}

/** Ordering options when selecting data from "order_item_unshipped". */
export type Order_Item_Unshipped_Order_By = {
  created_at?: InputMaybe<Order_By>
  customer?: InputMaybe<Customer_Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  doc_customer_id?: InputMaybe<Order_By>
  doc_erp_id?: InputMaybe<Order_By>
  erp_key?: InputMaybe<Order_By>
  first_arrival_date?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  order?: InputMaybe<Order_Order_By>
  order_confirmed?: InputMaybe<Order_Confirmed_Order_By>
  original_order_erp_id?: InputMaybe<Order_By>
  product_erp_id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: order_item_unshipped */
export type Order_Item_Unshipped_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "order_item_unshipped" */
export enum Order_Item_Unshipped_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Description = 'description',
  /** column name */
  DocCustomerId = 'doc_customer_id',
  /** column name */
  DocErpId = 'doc_erp_id',
  /** column name */
  ErpKey = 'erp_key',
  /** column name */
  FirstArrivalDate = 'first_arrival_date',
  /** column name */
  Id = 'id',
  /** column name */
  OriginalOrderErpId = 'original_order_erp_id',
  /** column name */
  ProductErpId = 'product_erp_id',
  /** column name */
  Qty = 'qty',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "order_item_unshipped" */
export type Order_Item_Unshipped_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  doc_customer_id?: InputMaybe<Scalars['String']['input']>
  doc_erp_id?: InputMaybe<Scalars['String']['input']>
  erp_key?: InputMaybe<Scalars['String']['input']>
  first_arrival_date?: InputMaybe<Scalars['date']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  original_order_erp_id?: InputMaybe<Scalars['String']['input']>
  product_erp_id?: InputMaybe<Scalars['String']['input']>
  qty?: InputMaybe<Scalars['Int']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate stddev on columns */
export type Order_Item_Unshipped_Stddev_Fields = {
  __typename?: 'order_item_unshipped_stddev_fields'
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "order_item_unshipped" */
export type Order_Item_Unshipped_Stddev_Order_By = {
  qty?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Order_Item_Unshipped_Stddev_Pop_Fields = {
  __typename?: 'order_item_unshipped_stddev_pop_fields'
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "order_item_unshipped" */
export type Order_Item_Unshipped_Stddev_Pop_Order_By = {
  qty?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Order_Item_Unshipped_Stddev_Samp_Fields = {
  __typename?: 'order_item_unshipped_stddev_samp_fields'
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "order_item_unshipped" */
export type Order_Item_Unshipped_Stddev_Samp_Order_By = {
  qty?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "order_item_unshipped" */
export type Order_Item_Unshipped_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Item_Unshipped_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Order_Item_Unshipped_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  doc_customer_id?: InputMaybe<Scalars['String']['input']>
  doc_erp_id?: InputMaybe<Scalars['String']['input']>
  erp_key?: InputMaybe<Scalars['String']['input']>
  first_arrival_date?: InputMaybe<Scalars['date']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  original_order_erp_id?: InputMaybe<Scalars['String']['input']>
  product_erp_id?: InputMaybe<Scalars['String']['input']>
  qty?: InputMaybe<Scalars['Int']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate sum on columns */
export type Order_Item_Unshipped_Sum_Fields = {
  __typename?: 'order_item_unshipped_sum_fields'
  qty?: Maybe<Scalars['Int']['output']>
}

/** order by sum() on columns of table "order_item_unshipped" */
export type Order_Item_Unshipped_Sum_Order_By = {
  qty?: InputMaybe<Order_By>
}

/** update columns of table "order_item_unshipped" */
export enum Order_Item_Unshipped_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Description = 'description',
  /** column name */
  DocCustomerId = 'doc_customer_id',
  /** column name */
  DocErpId = 'doc_erp_id',
  /** column name */
  ErpKey = 'erp_key',
  /** column name */
  FirstArrivalDate = 'first_arrival_date',
  /** column name */
  Id = 'id',
  /** column name */
  OriginalOrderErpId = 'original_order_erp_id',
  /** column name */
  ProductErpId = 'product_erp_id',
  /** column name */
  Qty = 'qty',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Order_Item_Unshipped_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Order_Item_Unshipped_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_Item_Unshipped_Set_Input>
  /** filter the rows which have to be updated */
  where: Order_Item_Unshipped_Bool_Exp
}

/** aggregate var_pop on columns */
export type Order_Item_Unshipped_Var_Pop_Fields = {
  __typename?: 'order_item_unshipped_var_pop_fields'
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "order_item_unshipped" */
export type Order_Item_Unshipped_Var_Pop_Order_By = {
  qty?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Order_Item_Unshipped_Var_Samp_Fields = {
  __typename?: 'order_item_unshipped_var_samp_fields'
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "order_item_unshipped" */
export type Order_Item_Unshipped_Var_Samp_Order_By = {
  qty?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Order_Item_Unshipped_Variance_Fields = {
  __typename?: 'order_item_unshipped_variance_fields'
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "order_item_unshipped" */
export type Order_Item_Unshipped_Variance_Order_By = {
  qty?: InputMaybe<Order_By>
}

/** aggregate max on columns */
export type Order_Max_Fields = {
  __typename?: 'order_max_fields'
  amount?: Maybe<Scalars['numeric']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  currency?: Maybe<Scalars['String']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  increment_id?: Maybe<Scalars['Int']['output']>
  increment_id_as_text?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "order" */
export type Order_Max_Order_By = {
  amount?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  currency?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  increment_id?: InputMaybe<Order_By>
  increment_id_as_text?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Order_Min_Fields = {
  __typename?: 'order_min_fields'
  amount?: Maybe<Scalars['numeric']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  currency?: Maybe<Scalars['String']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  increment_id?: Maybe<Scalars['Int']['output']>
  increment_id_as_text?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "order" */
export type Order_Min_Order_By = {
  amount?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  currency?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  increment_id?: InputMaybe<Order_By>
  increment_id_as_text?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "order" */
export type Order_Mutation_Response = {
  __typename?: 'order_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Order>
}

/** input type for inserting object relation for remote table "order" */
export type Order_Obj_Rel_Insert_Input = {
  data: Order_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Order_On_Conflict>
}

/** on_conflict condition type for table "order" */
export type Order_On_Conflict = {
  constraint: Order_Constraint
  update_columns?: Array<Order_Update_Column>
  where?: InputMaybe<Order_Bool_Exp>
}

/** Ordering options when selecting data from "order". */
export type Order_Order_By = {
  additional_info?: InputMaybe<Order_By>
  amount?: InputMaybe<Order_By>
  cart?: InputMaybe<Cart_Order_By>
  created_at?: InputMaybe<Order_By>
  currency?: InputMaybe<Order_By>
  customer?: InputMaybe<Customer_Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  increment_id?: InputMaybe<Order_By>
  increment_id_as_text?: InputMaybe<Order_By>
  order_confirmed?: InputMaybe<Order_Confirmed_Order_By>
  order_invoice?: InputMaybe<Order_Invoice_Order_By>
  order_invoices_aggregate?: InputMaybe<Order_Invoice_Aggregate_Order_By>
  order_product?: InputMaybe<Order_Product_Order_By>
  order_products_aggregate?: InputMaybe<Order_Product_Aggregate_Order_By>
  order_transactions_aggregate?: InputMaybe<Order_Transaction_Aggregate_Order_By>
  status?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: order */
export type Order_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Order_Prepend_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
}

/** columns and relationships of "order_product" */
export type Order_Product = {
  __typename?: 'order_product'
  additional_info?: Maybe<Scalars['jsonb']['output']>
  created_at: Scalars['timestamptz']['output']
  id: Scalars['uuid']['output']
  /** An object relationship */
  order?: Maybe<Order>
  order_id?: Maybe<Scalars['uuid']['output']>
  /** An array relationship */
  order_product_items: Array<Order_Product_Item>
  /** An aggregate relationship */
  order_product_items_aggregate: Order_Product_Item_Aggregate
  /** An array relationship */
  order_product_replacements: Array<Order_Product_Replacement>
  /** An aggregate relationship */
  order_product_replacements_aggregate: Order_Product_Replacement_Aggregate
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "order_product" */
export type Order_ProductAdditional_InfoArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** columns and relationships of "order_product" */
export type Order_ProductOrder_Product_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Order_Product_Item_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Product_Item_Order_By>>
  where?: InputMaybe<Order_Product_Item_Bool_Exp>
}

/** columns and relationships of "order_product" */
export type Order_ProductOrder_Product_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Product_Item_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Product_Item_Order_By>>
  where?: InputMaybe<Order_Product_Item_Bool_Exp>
}

/** columns and relationships of "order_product" */
export type Order_ProductOrder_Product_ReplacementsArgs = {
  distinct_on?: InputMaybe<Array<Order_Product_Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Product_Replacement_Order_By>>
  where?: InputMaybe<Order_Product_Replacement_Bool_Exp>
}

/** columns and relationships of "order_product" */
export type Order_ProductOrder_Product_Replacements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Product_Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Product_Replacement_Order_By>>
  where?: InputMaybe<Order_Product_Replacement_Bool_Exp>
}

/** aggregated selection of "order_product" */
export type Order_Product_Aggregate = {
  __typename?: 'order_product_aggregate'
  aggregate?: Maybe<Order_Product_Aggregate_Fields>
  nodes: Array<Order_Product>
}

export type Order_Product_Aggregate_Bool_Exp = {
  count?: InputMaybe<Order_Product_Aggregate_Bool_Exp_Count>
}

export type Order_Product_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Order_Product_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Order_Product_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "order_product" */
export type Order_Product_Aggregate_Fields = {
  __typename?: 'order_product_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Order_Product_Max_Fields>
  min?: Maybe<Order_Product_Min_Fields>
}

/** aggregate fields of "order_product" */
export type Order_Product_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Product_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "order_product" */
export type Order_Product_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Order_Product_Max_Order_By>
  min?: InputMaybe<Order_Product_Min_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Order_Product_Append_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
}

/** input type for inserting array relation for remote table "order_product" */
export type Order_Product_Arr_Rel_Insert_Input = {
  data: Array<Order_Product_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Order_Product_On_Conflict>
}

/** Boolean expression to filter rows from the table "order_product". All fields are combined with a logical 'AND'. */
export type Order_Product_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Product_Bool_Exp>>
  _not?: InputMaybe<Order_Product_Bool_Exp>
  _or?: InputMaybe<Array<Order_Product_Bool_Exp>>
  additional_info?: InputMaybe<Jsonb_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  order?: InputMaybe<Order_Bool_Exp>
  order_id?: InputMaybe<Uuid_Comparison_Exp>
  order_product_items?: InputMaybe<Order_Product_Item_Bool_Exp>
  order_product_items_aggregate?: InputMaybe<Order_Product_Item_Aggregate_Bool_Exp>
  order_product_replacements?: InputMaybe<Order_Product_Replacement_Bool_Exp>
  order_product_replacements_aggregate?: InputMaybe<Order_Product_Replacement_Aggregate_Bool_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "order_product" */
export enum Order_Product_Constraint {
  /** unique or primary key constraint on columns "order_id" */
  OrderProductOrderIdKey = 'order_product_order_id_key',
  /** unique or primary key constraint on columns "id" */
  OrderProductPkey = 'order_product_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Order_Product_Delete_At_Path_Input = {
  additional_info?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Order_Product_Delete_Elem_Input = {
  additional_info?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Order_Product_Delete_Key_Input = {
  additional_info?: InputMaybe<Scalars['String']['input']>
}

/** input type for inserting data into table "order_product" */
export type Order_Product_Insert_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  order?: InputMaybe<Order_Obj_Rel_Insert_Input>
  order_id?: InputMaybe<Scalars['uuid']['input']>
  order_product_items?: InputMaybe<Order_Product_Item_Arr_Rel_Insert_Input>
  order_product_replacements?: InputMaybe<Order_Product_Replacement_Arr_Rel_Insert_Input>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** columns and relationships of "order_product_item" */
export type Order_Product_Item = {
  __typename?: 'order_product_item'
  additional_info?: Maybe<Scalars['jsonb']['output']>
  amount: Scalars['numeric']['output']
  cart_item_created_at: Scalars['timestamp']['output']
  cart_item_id?: Maybe<Scalars['Int']['output']>
  created_at: Scalars['timestamptz']['output']
  currency: Scalars['String']['output']
  id: Scalars['uuid']['output']
  /** An object relationship */
  order_product?: Maybe<Order_Product>
  order_product_id?: Maybe<Scalars['uuid']['output']>
  /** An object relationship */
  product?: Maybe<Product>
  product_amount: Scalars['numeric']['output']
  product_erp_id: Scalars['String']['output']
  qty: Scalars['Int']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "order_product_item" */
export type Order_Product_ItemAdditional_InfoArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** aggregated selection of "order_product_item" */
export type Order_Product_Item_Aggregate = {
  __typename?: 'order_product_item_aggregate'
  aggregate?: Maybe<Order_Product_Item_Aggregate_Fields>
  nodes: Array<Order_Product_Item>
}

export type Order_Product_Item_Aggregate_Bool_Exp = {
  count?: InputMaybe<Order_Product_Item_Aggregate_Bool_Exp_Count>
}

export type Order_Product_Item_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Order_Product_Item_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Order_Product_Item_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "order_product_item" */
export type Order_Product_Item_Aggregate_Fields = {
  __typename?: 'order_product_item_aggregate_fields'
  avg?: Maybe<Order_Product_Item_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Order_Product_Item_Max_Fields>
  min?: Maybe<Order_Product_Item_Min_Fields>
  stddev?: Maybe<Order_Product_Item_Stddev_Fields>
  stddev_pop?: Maybe<Order_Product_Item_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Order_Product_Item_Stddev_Samp_Fields>
  sum?: Maybe<Order_Product_Item_Sum_Fields>
  var_pop?: Maybe<Order_Product_Item_Var_Pop_Fields>
  var_samp?: Maybe<Order_Product_Item_Var_Samp_Fields>
  variance?: Maybe<Order_Product_Item_Variance_Fields>
}

/** aggregate fields of "order_product_item" */
export type Order_Product_Item_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Product_Item_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "order_product_item" */
export type Order_Product_Item_Aggregate_Order_By = {
  avg?: InputMaybe<Order_Product_Item_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Order_Product_Item_Max_Order_By>
  min?: InputMaybe<Order_Product_Item_Min_Order_By>
  stddev?: InputMaybe<Order_Product_Item_Stddev_Order_By>
  stddev_pop?: InputMaybe<Order_Product_Item_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Order_Product_Item_Stddev_Samp_Order_By>
  sum?: InputMaybe<Order_Product_Item_Sum_Order_By>
  var_pop?: InputMaybe<Order_Product_Item_Var_Pop_Order_By>
  var_samp?: InputMaybe<Order_Product_Item_Var_Samp_Order_By>
  variance?: InputMaybe<Order_Product_Item_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Order_Product_Item_Append_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
}

/** input type for inserting array relation for remote table "order_product_item" */
export type Order_Product_Item_Arr_Rel_Insert_Input = {
  data: Array<Order_Product_Item_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Order_Product_Item_On_Conflict>
}

/** aggregate avg on columns */
export type Order_Product_Item_Avg_Fields = {
  __typename?: 'order_product_item_avg_fields'
  amount?: Maybe<Scalars['Float']['output']>
  cart_item_id?: Maybe<Scalars['Float']['output']>
  product_amount?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "order_product_item" */
export type Order_Product_Item_Avg_Order_By = {
  amount?: InputMaybe<Order_By>
  cart_item_id?: InputMaybe<Order_By>
  product_amount?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "order_product_item". All fields are combined with a logical 'AND'. */
export type Order_Product_Item_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Product_Item_Bool_Exp>>
  _not?: InputMaybe<Order_Product_Item_Bool_Exp>
  _or?: InputMaybe<Array<Order_Product_Item_Bool_Exp>>
  additional_info?: InputMaybe<Jsonb_Comparison_Exp>
  amount?: InputMaybe<Numeric_Comparison_Exp>
  cart_item_created_at?: InputMaybe<Timestamp_Comparison_Exp>
  cart_item_id?: InputMaybe<Int_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  currency?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  order_product?: InputMaybe<Order_Product_Bool_Exp>
  order_product_id?: InputMaybe<Uuid_Comparison_Exp>
  product?: InputMaybe<Product_Bool_Exp>
  product_amount?: InputMaybe<Numeric_Comparison_Exp>
  product_erp_id?: InputMaybe<String_Comparison_Exp>
  qty?: InputMaybe<Int_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "order_product_item" */
export enum Order_Product_Item_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrderProductItemPkey = 'order_product_item_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Order_Product_Item_Delete_At_Path_Input = {
  additional_info?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Order_Product_Item_Delete_Elem_Input = {
  additional_info?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Order_Product_Item_Delete_Key_Input = {
  additional_info?: InputMaybe<Scalars['String']['input']>
}

/** input type for incrementing numeric columns in table "order_product_item" */
export type Order_Product_Item_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>
  cart_item_id?: InputMaybe<Scalars['Int']['input']>
  product_amount?: InputMaybe<Scalars['numeric']['input']>
  qty?: InputMaybe<Scalars['Int']['input']>
}

/** input type for inserting data into table "order_product_item" */
export type Order_Product_Item_Insert_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  amount?: InputMaybe<Scalars['numeric']['input']>
  cart_item_created_at?: InputMaybe<Scalars['timestamp']['input']>
  cart_item_id?: InputMaybe<Scalars['Int']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  currency?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  order_product?: InputMaybe<Order_Product_Obj_Rel_Insert_Input>
  order_product_id?: InputMaybe<Scalars['uuid']['input']>
  product?: InputMaybe<Product_Obj_Rel_Insert_Input>
  product_amount?: InputMaybe<Scalars['numeric']['input']>
  product_erp_id?: InputMaybe<Scalars['String']['input']>
  qty?: InputMaybe<Scalars['Int']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Order_Product_Item_Max_Fields = {
  __typename?: 'order_product_item_max_fields'
  amount?: Maybe<Scalars['numeric']['output']>
  cart_item_created_at?: Maybe<Scalars['timestamp']['output']>
  cart_item_id?: Maybe<Scalars['Int']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  currency?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  order_product_id?: Maybe<Scalars['uuid']['output']>
  product_amount?: Maybe<Scalars['numeric']['output']>
  product_erp_id?: Maybe<Scalars['String']['output']>
  qty?: Maybe<Scalars['Int']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "order_product_item" */
export type Order_Product_Item_Max_Order_By = {
  amount?: InputMaybe<Order_By>
  cart_item_created_at?: InputMaybe<Order_By>
  cart_item_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  currency?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  order_product_id?: InputMaybe<Order_By>
  product_amount?: InputMaybe<Order_By>
  product_erp_id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Order_Product_Item_Min_Fields = {
  __typename?: 'order_product_item_min_fields'
  amount?: Maybe<Scalars['numeric']['output']>
  cart_item_created_at?: Maybe<Scalars['timestamp']['output']>
  cart_item_id?: Maybe<Scalars['Int']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  currency?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  order_product_id?: Maybe<Scalars['uuid']['output']>
  product_amount?: Maybe<Scalars['numeric']['output']>
  product_erp_id?: Maybe<Scalars['String']['output']>
  qty?: Maybe<Scalars['Int']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "order_product_item" */
export type Order_Product_Item_Min_Order_By = {
  amount?: InputMaybe<Order_By>
  cart_item_created_at?: InputMaybe<Order_By>
  cart_item_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  currency?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  order_product_id?: InputMaybe<Order_By>
  product_amount?: InputMaybe<Order_By>
  product_erp_id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "order_product_item" */
export type Order_Product_Item_Mutation_Response = {
  __typename?: 'order_product_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Order_Product_Item>
}

/** on_conflict condition type for table "order_product_item" */
export type Order_Product_Item_On_Conflict = {
  constraint: Order_Product_Item_Constraint
  update_columns?: Array<Order_Product_Item_Update_Column>
  where?: InputMaybe<Order_Product_Item_Bool_Exp>
}

/** Ordering options when selecting data from "order_product_item". */
export type Order_Product_Item_Order_By = {
  additional_info?: InputMaybe<Order_By>
  amount?: InputMaybe<Order_By>
  cart_item_created_at?: InputMaybe<Order_By>
  cart_item_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  currency?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  order_product?: InputMaybe<Order_Product_Order_By>
  order_product_id?: InputMaybe<Order_By>
  product?: InputMaybe<Product_Order_By>
  product_amount?: InputMaybe<Order_By>
  product_erp_id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: order_product_item */
export type Order_Product_Item_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Order_Product_Item_Prepend_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
}

/** select columns of table "order_product_item" */
export enum Order_Product_Item_Select_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  Amount = 'amount',
  /** column name */
  CartItemCreatedAt = 'cart_item_created_at',
  /** column name */
  CartItemId = 'cart_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  OrderProductId = 'order_product_id',
  /** column name */
  ProductAmount = 'product_amount',
  /** column name */
  ProductErpId = 'product_erp_id',
  /** column name */
  Qty = 'qty',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "order_product_item" */
export type Order_Product_Item_Set_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  amount?: InputMaybe<Scalars['numeric']['input']>
  cart_item_created_at?: InputMaybe<Scalars['timestamp']['input']>
  cart_item_id?: InputMaybe<Scalars['Int']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  currency?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  order_product_id?: InputMaybe<Scalars['uuid']['input']>
  product_amount?: InputMaybe<Scalars['numeric']['input']>
  product_erp_id?: InputMaybe<Scalars['String']['input']>
  qty?: InputMaybe<Scalars['Int']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate stddev on columns */
export type Order_Product_Item_Stddev_Fields = {
  __typename?: 'order_product_item_stddev_fields'
  amount?: Maybe<Scalars['Float']['output']>
  cart_item_id?: Maybe<Scalars['Float']['output']>
  product_amount?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "order_product_item" */
export type Order_Product_Item_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>
  cart_item_id?: InputMaybe<Order_By>
  product_amount?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Order_Product_Item_Stddev_Pop_Fields = {
  __typename?: 'order_product_item_stddev_pop_fields'
  amount?: Maybe<Scalars['Float']['output']>
  cart_item_id?: Maybe<Scalars['Float']['output']>
  product_amount?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "order_product_item" */
export type Order_Product_Item_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>
  cart_item_id?: InputMaybe<Order_By>
  product_amount?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Order_Product_Item_Stddev_Samp_Fields = {
  __typename?: 'order_product_item_stddev_samp_fields'
  amount?: Maybe<Scalars['Float']['output']>
  cart_item_id?: Maybe<Scalars['Float']['output']>
  product_amount?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "order_product_item" */
export type Order_Product_Item_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>
  cart_item_id?: InputMaybe<Order_By>
  product_amount?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "order_product_item" */
export type Order_Product_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Product_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Order_Product_Item_Stream_Cursor_Value_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  amount?: InputMaybe<Scalars['numeric']['input']>
  cart_item_created_at?: InputMaybe<Scalars['timestamp']['input']>
  cart_item_id?: InputMaybe<Scalars['Int']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  currency?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  order_product_id?: InputMaybe<Scalars['uuid']['input']>
  product_amount?: InputMaybe<Scalars['numeric']['input']>
  product_erp_id?: InputMaybe<Scalars['String']['input']>
  qty?: InputMaybe<Scalars['Int']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate sum on columns */
export type Order_Product_Item_Sum_Fields = {
  __typename?: 'order_product_item_sum_fields'
  amount?: Maybe<Scalars['numeric']['output']>
  cart_item_id?: Maybe<Scalars['Int']['output']>
  product_amount?: Maybe<Scalars['numeric']['output']>
  qty?: Maybe<Scalars['Int']['output']>
}

/** order by sum() on columns of table "order_product_item" */
export type Order_Product_Item_Sum_Order_By = {
  amount?: InputMaybe<Order_By>
  cart_item_id?: InputMaybe<Order_By>
  product_amount?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** update columns of table "order_product_item" */
export enum Order_Product_Item_Update_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  Amount = 'amount',
  /** column name */
  CartItemCreatedAt = 'cart_item_created_at',
  /** column name */
  CartItemId = 'cart_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  OrderProductId = 'order_product_id',
  /** column name */
  ProductAmount = 'product_amount',
  /** column name */
  ProductErpId = 'product_erp_id',
  /** column name */
  Qty = 'qty',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Order_Product_Item_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Order_Product_Item_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Order_Product_Item_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Order_Product_Item_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Order_Product_Item_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Order_Product_Item_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Order_Product_Item_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_Product_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Order_Product_Item_Bool_Exp
}

/** aggregate var_pop on columns */
export type Order_Product_Item_Var_Pop_Fields = {
  __typename?: 'order_product_item_var_pop_fields'
  amount?: Maybe<Scalars['Float']['output']>
  cart_item_id?: Maybe<Scalars['Float']['output']>
  product_amount?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "order_product_item" */
export type Order_Product_Item_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>
  cart_item_id?: InputMaybe<Order_By>
  product_amount?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Order_Product_Item_Var_Samp_Fields = {
  __typename?: 'order_product_item_var_samp_fields'
  amount?: Maybe<Scalars['Float']['output']>
  cart_item_id?: Maybe<Scalars['Float']['output']>
  product_amount?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "order_product_item" */
export type Order_Product_Item_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>
  cart_item_id?: InputMaybe<Order_By>
  product_amount?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Order_Product_Item_Variance_Fields = {
  __typename?: 'order_product_item_variance_fields'
  amount?: Maybe<Scalars['Float']['output']>
  cart_item_id?: Maybe<Scalars['Float']['output']>
  product_amount?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "order_product_item" */
export type Order_Product_Item_Variance_Order_By = {
  amount?: InputMaybe<Order_By>
  cart_item_id?: InputMaybe<Order_By>
  product_amount?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** aggregate max on columns */
export type Order_Product_Max_Fields = {
  __typename?: 'order_product_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  order_id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "order_product" */
export type Order_Product_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  order_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Order_Product_Min_Fields = {
  __typename?: 'order_product_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  order_id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "order_product" */
export type Order_Product_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  order_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "order_product" */
export type Order_Product_Mutation_Response = {
  __typename?: 'order_product_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Order_Product>
}

/** input type for inserting object relation for remote table "order_product" */
export type Order_Product_Obj_Rel_Insert_Input = {
  data: Order_Product_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Order_Product_On_Conflict>
}

/** on_conflict condition type for table "order_product" */
export type Order_Product_On_Conflict = {
  constraint: Order_Product_Constraint
  update_columns?: Array<Order_Product_Update_Column>
  where?: InputMaybe<Order_Product_Bool_Exp>
}

/** Ordering options when selecting data from "order_product". */
export type Order_Product_Order_By = {
  additional_info?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  order?: InputMaybe<Order_Order_By>
  order_id?: InputMaybe<Order_By>
  order_product_items_aggregate?: InputMaybe<Order_Product_Item_Aggregate_Order_By>
  order_product_replacements_aggregate?: InputMaybe<Order_Product_Replacement_Aggregate_Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: order_product */
export type Order_Product_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Order_Product_Prepend_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
}

/** columns and relationships of "order_product_replacement" */
export type Order_Product_Replacement = {
  __typename?: 'order_product_replacement'
  additional_info?: Maybe<Scalars['jsonb']['output']>
  cart_replacement_created_at: Scalars['timestamp']['output']
  cart_replacement_id?: Maybe<Scalars['Int']['output']>
  created_at: Scalars['timestamptz']['output']
  id: Scalars['uuid']['output']
  /** An object relationship */
  order_product?: Maybe<Order_Product>
  order_product_id: Scalars['uuid']['output']
  qty: Scalars['Int']['output']
  /** An object relationship */
  replacement?: Maybe<Replacement>
  replacement_erp_id: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "order_product_replacement" */
export type Order_Product_ReplacementAdditional_InfoArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** aggregated selection of "order_product_replacement" */
export type Order_Product_Replacement_Aggregate = {
  __typename?: 'order_product_replacement_aggregate'
  aggregate?: Maybe<Order_Product_Replacement_Aggregate_Fields>
  nodes: Array<Order_Product_Replacement>
}

export type Order_Product_Replacement_Aggregate_Bool_Exp = {
  count?: InputMaybe<Order_Product_Replacement_Aggregate_Bool_Exp_Count>
}

export type Order_Product_Replacement_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Order_Product_Replacement_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Order_Product_Replacement_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "order_product_replacement" */
export type Order_Product_Replacement_Aggregate_Fields = {
  __typename?: 'order_product_replacement_aggregate_fields'
  avg?: Maybe<Order_Product_Replacement_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Order_Product_Replacement_Max_Fields>
  min?: Maybe<Order_Product_Replacement_Min_Fields>
  stddev?: Maybe<Order_Product_Replacement_Stddev_Fields>
  stddev_pop?: Maybe<Order_Product_Replacement_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Order_Product_Replacement_Stddev_Samp_Fields>
  sum?: Maybe<Order_Product_Replacement_Sum_Fields>
  var_pop?: Maybe<Order_Product_Replacement_Var_Pop_Fields>
  var_samp?: Maybe<Order_Product_Replacement_Var_Samp_Fields>
  variance?: Maybe<Order_Product_Replacement_Variance_Fields>
}

/** aggregate fields of "order_product_replacement" */
export type Order_Product_Replacement_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Product_Replacement_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "order_product_replacement" */
export type Order_Product_Replacement_Aggregate_Order_By = {
  avg?: InputMaybe<Order_Product_Replacement_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Order_Product_Replacement_Max_Order_By>
  min?: InputMaybe<Order_Product_Replacement_Min_Order_By>
  stddev?: InputMaybe<Order_Product_Replacement_Stddev_Order_By>
  stddev_pop?: InputMaybe<Order_Product_Replacement_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Order_Product_Replacement_Stddev_Samp_Order_By>
  sum?: InputMaybe<Order_Product_Replacement_Sum_Order_By>
  var_pop?: InputMaybe<Order_Product_Replacement_Var_Pop_Order_By>
  var_samp?: InputMaybe<Order_Product_Replacement_Var_Samp_Order_By>
  variance?: InputMaybe<Order_Product_Replacement_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Order_Product_Replacement_Append_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
}

/** input type for inserting array relation for remote table "order_product_replacement" */
export type Order_Product_Replacement_Arr_Rel_Insert_Input = {
  data: Array<Order_Product_Replacement_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Order_Product_Replacement_On_Conflict>
}

/** aggregate avg on columns */
export type Order_Product_Replacement_Avg_Fields = {
  __typename?: 'order_product_replacement_avg_fields'
  cart_replacement_id?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "order_product_replacement" */
export type Order_Product_Replacement_Avg_Order_By = {
  cart_replacement_id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "order_product_replacement". All fields are combined with a logical 'AND'. */
export type Order_Product_Replacement_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Product_Replacement_Bool_Exp>>
  _not?: InputMaybe<Order_Product_Replacement_Bool_Exp>
  _or?: InputMaybe<Array<Order_Product_Replacement_Bool_Exp>>
  additional_info?: InputMaybe<Jsonb_Comparison_Exp>
  cart_replacement_created_at?: InputMaybe<Timestamp_Comparison_Exp>
  cart_replacement_id?: InputMaybe<Int_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  order_product?: InputMaybe<Order_Product_Bool_Exp>
  order_product_id?: InputMaybe<Uuid_Comparison_Exp>
  qty?: InputMaybe<Int_Comparison_Exp>
  replacement?: InputMaybe<Replacement_Bool_Exp>
  replacement_erp_id?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "order_product_replacement" */
export enum Order_Product_Replacement_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrderProductReplacementPkey = 'order_product_replacement_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Order_Product_Replacement_Delete_At_Path_Input = {
  additional_info?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Order_Product_Replacement_Delete_Elem_Input = {
  additional_info?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Order_Product_Replacement_Delete_Key_Input = {
  additional_info?: InputMaybe<Scalars['String']['input']>
}

/** input type for incrementing numeric columns in table "order_product_replacement" */
export type Order_Product_Replacement_Inc_Input = {
  cart_replacement_id?: InputMaybe<Scalars['Int']['input']>
  qty?: InputMaybe<Scalars['Int']['input']>
}

/** input type for inserting data into table "order_product_replacement" */
export type Order_Product_Replacement_Insert_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  cart_replacement_created_at?: InputMaybe<Scalars['timestamp']['input']>
  cart_replacement_id?: InputMaybe<Scalars['Int']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  order_product?: InputMaybe<Order_Product_Obj_Rel_Insert_Input>
  order_product_id?: InputMaybe<Scalars['uuid']['input']>
  qty?: InputMaybe<Scalars['Int']['input']>
  replacement?: InputMaybe<Replacement_Obj_Rel_Insert_Input>
  replacement_erp_id?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Order_Product_Replacement_Max_Fields = {
  __typename?: 'order_product_replacement_max_fields'
  cart_replacement_created_at?: Maybe<Scalars['timestamp']['output']>
  cart_replacement_id?: Maybe<Scalars['Int']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  order_product_id?: Maybe<Scalars['uuid']['output']>
  qty?: Maybe<Scalars['Int']['output']>
  replacement_erp_id?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "order_product_replacement" */
export type Order_Product_Replacement_Max_Order_By = {
  cart_replacement_created_at?: InputMaybe<Order_By>
  cart_replacement_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  order_product_id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
  replacement_erp_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Order_Product_Replacement_Min_Fields = {
  __typename?: 'order_product_replacement_min_fields'
  cart_replacement_created_at?: Maybe<Scalars['timestamp']['output']>
  cart_replacement_id?: Maybe<Scalars['Int']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  order_product_id?: Maybe<Scalars['uuid']['output']>
  qty?: Maybe<Scalars['Int']['output']>
  replacement_erp_id?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "order_product_replacement" */
export type Order_Product_Replacement_Min_Order_By = {
  cart_replacement_created_at?: InputMaybe<Order_By>
  cart_replacement_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  order_product_id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
  replacement_erp_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "order_product_replacement" */
export type Order_Product_Replacement_Mutation_Response = {
  __typename?: 'order_product_replacement_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Order_Product_Replacement>
}

/** on_conflict condition type for table "order_product_replacement" */
export type Order_Product_Replacement_On_Conflict = {
  constraint: Order_Product_Replacement_Constraint
  update_columns?: Array<Order_Product_Replacement_Update_Column>
  where?: InputMaybe<Order_Product_Replacement_Bool_Exp>
}

/** Ordering options when selecting data from "order_product_replacement". */
export type Order_Product_Replacement_Order_By = {
  additional_info?: InputMaybe<Order_By>
  cart_replacement_created_at?: InputMaybe<Order_By>
  cart_replacement_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  order_product?: InputMaybe<Order_Product_Order_By>
  order_product_id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
  replacement?: InputMaybe<Replacement_Order_By>
  replacement_erp_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: order_product_replacement */
export type Order_Product_Replacement_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Order_Product_Replacement_Prepend_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
}

/** select columns of table "order_product_replacement" */
export enum Order_Product_Replacement_Select_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CartReplacementCreatedAt = 'cart_replacement_created_at',
  /** column name */
  CartReplacementId = 'cart_replacement_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderProductId = 'order_product_id',
  /** column name */
  Qty = 'qty',
  /** column name */
  ReplacementErpId = 'replacement_erp_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "order_product_replacement" */
export type Order_Product_Replacement_Set_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  cart_replacement_created_at?: InputMaybe<Scalars['timestamp']['input']>
  cart_replacement_id?: InputMaybe<Scalars['Int']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  order_product_id?: InputMaybe<Scalars['uuid']['input']>
  qty?: InputMaybe<Scalars['Int']['input']>
  replacement_erp_id?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate stddev on columns */
export type Order_Product_Replacement_Stddev_Fields = {
  __typename?: 'order_product_replacement_stddev_fields'
  cart_replacement_id?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "order_product_replacement" */
export type Order_Product_Replacement_Stddev_Order_By = {
  cart_replacement_id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Order_Product_Replacement_Stddev_Pop_Fields = {
  __typename?: 'order_product_replacement_stddev_pop_fields'
  cart_replacement_id?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "order_product_replacement" */
export type Order_Product_Replacement_Stddev_Pop_Order_By = {
  cart_replacement_id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Order_Product_Replacement_Stddev_Samp_Fields = {
  __typename?: 'order_product_replacement_stddev_samp_fields'
  cart_replacement_id?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "order_product_replacement" */
export type Order_Product_Replacement_Stddev_Samp_Order_By = {
  cart_replacement_id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "order_product_replacement" */
export type Order_Product_Replacement_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Product_Replacement_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Order_Product_Replacement_Stream_Cursor_Value_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  cart_replacement_created_at?: InputMaybe<Scalars['timestamp']['input']>
  cart_replacement_id?: InputMaybe<Scalars['Int']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  order_product_id?: InputMaybe<Scalars['uuid']['input']>
  qty?: InputMaybe<Scalars['Int']['input']>
  replacement_erp_id?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate sum on columns */
export type Order_Product_Replacement_Sum_Fields = {
  __typename?: 'order_product_replacement_sum_fields'
  cart_replacement_id?: Maybe<Scalars['Int']['output']>
  qty?: Maybe<Scalars['Int']['output']>
}

/** order by sum() on columns of table "order_product_replacement" */
export type Order_Product_Replacement_Sum_Order_By = {
  cart_replacement_id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** update columns of table "order_product_replacement" */
export enum Order_Product_Replacement_Update_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CartReplacementCreatedAt = 'cart_replacement_created_at',
  /** column name */
  CartReplacementId = 'cart_replacement_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderProductId = 'order_product_id',
  /** column name */
  Qty = 'qty',
  /** column name */
  ReplacementErpId = 'replacement_erp_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Order_Product_Replacement_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Order_Product_Replacement_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Order_Product_Replacement_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Order_Product_Replacement_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Order_Product_Replacement_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Order_Product_Replacement_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Order_Product_Replacement_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_Product_Replacement_Set_Input>
  /** filter the rows which have to be updated */
  where: Order_Product_Replacement_Bool_Exp
}

/** aggregate var_pop on columns */
export type Order_Product_Replacement_Var_Pop_Fields = {
  __typename?: 'order_product_replacement_var_pop_fields'
  cart_replacement_id?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "order_product_replacement" */
export type Order_Product_Replacement_Var_Pop_Order_By = {
  cart_replacement_id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Order_Product_Replacement_Var_Samp_Fields = {
  __typename?: 'order_product_replacement_var_samp_fields'
  cart_replacement_id?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "order_product_replacement" */
export type Order_Product_Replacement_Var_Samp_Order_By = {
  cart_replacement_id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Order_Product_Replacement_Variance_Fields = {
  __typename?: 'order_product_replacement_variance_fields'
  cart_replacement_id?: Maybe<Scalars['Float']['output']>
  qty?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "order_product_replacement" */
export type Order_Product_Replacement_Variance_Order_By = {
  cart_replacement_id?: InputMaybe<Order_By>
  qty?: InputMaybe<Order_By>
}

/** select columns of table "order_product" */
export enum Order_Product_Select_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "order_product" */
export type Order_Product_Set_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  order_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "order_product" */
export type Order_Product_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Product_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Order_Product_Stream_Cursor_Value_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  order_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "order_product" */
export enum Order_Product_Update_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Order_Product_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Order_Product_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Order_Product_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Order_Product_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Order_Product_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Order_Product_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_Product_Set_Input>
  /** filter the rows which have to be updated */
  where: Order_Product_Bool_Exp
}

/** select columns of table "order" */
export enum Order_Select_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  IncrementId = 'increment_id',
  /** column name */
  IncrementIdAsText = 'increment_id_as_text',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "order" */
export type Order_Set_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  amount?: InputMaybe<Scalars['numeric']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  currency?: InputMaybe<Scalars['String']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  increment_id?: InputMaybe<Scalars['Int']['input']>
  increment_id_as_text?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate stddev on columns */
export type Order_Stddev_Fields = {
  __typename?: 'order_stddev_fields'
  amount?: Maybe<Scalars['Float']['output']>
  increment_id?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "order" */
export type Order_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>
  increment_id?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Order_Stddev_Pop_Fields = {
  __typename?: 'order_stddev_pop_fields'
  amount?: Maybe<Scalars['Float']['output']>
  increment_id?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "order" */
export type Order_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>
  increment_id?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Order_Stddev_Samp_Fields = {
  __typename?: 'order_stddev_samp_fields'
  amount?: Maybe<Scalars['Float']['output']>
  increment_id?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "order" */
export type Order_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>
  increment_id?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "order" */
export type Order_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Order_Stream_Cursor_Value_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  amount?: InputMaybe<Scalars['numeric']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  currency?: InputMaybe<Scalars['String']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  increment_id?: InputMaybe<Scalars['Int']['input']>
  increment_id_as_text?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate sum on columns */
export type Order_Sum_Fields = {
  __typename?: 'order_sum_fields'
  amount?: Maybe<Scalars['numeric']['output']>
  increment_id?: Maybe<Scalars['Int']['output']>
}

/** order by sum() on columns of table "order" */
export type Order_Sum_Order_By = {
  amount?: InputMaybe<Order_By>
  increment_id?: InputMaybe<Order_By>
}

/** columns and relationships of "order_transaction" */
export type Order_Transaction = {
  __typename?: 'order_transaction'
  created_at: Scalars['timestamptz']['output']
  id: Scalars['uuid']['output']
  /** An object relationship */
  order?: Maybe<Order>
  order_id?: Maybe<Scalars['uuid']['output']>
  /** An object relationship */
  transaction?: Maybe<Transaction>
  transaction_id?: Maybe<Scalars['uuid']['output']>
  updated_at: Scalars['timestamptz']['output']
}

/** aggregated selection of "order_transaction" */
export type Order_Transaction_Aggregate = {
  __typename?: 'order_transaction_aggregate'
  aggregate?: Maybe<Order_Transaction_Aggregate_Fields>
  nodes: Array<Order_Transaction>
}

export type Order_Transaction_Aggregate_Bool_Exp = {
  count?: InputMaybe<Order_Transaction_Aggregate_Bool_Exp_Count>
}

export type Order_Transaction_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Order_Transaction_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Order_Transaction_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "order_transaction" */
export type Order_Transaction_Aggregate_Fields = {
  __typename?: 'order_transaction_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Order_Transaction_Max_Fields>
  min?: Maybe<Order_Transaction_Min_Fields>
}

/** aggregate fields of "order_transaction" */
export type Order_Transaction_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Transaction_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "order_transaction" */
export type Order_Transaction_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Order_Transaction_Max_Order_By>
  min?: InputMaybe<Order_Transaction_Min_Order_By>
}

/** input type for inserting array relation for remote table "order_transaction" */
export type Order_Transaction_Arr_Rel_Insert_Input = {
  data: Array<Order_Transaction_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Order_Transaction_On_Conflict>
}

/** Boolean expression to filter rows from the table "order_transaction". All fields are combined with a logical 'AND'. */
export type Order_Transaction_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Transaction_Bool_Exp>>
  _not?: InputMaybe<Order_Transaction_Bool_Exp>
  _or?: InputMaybe<Array<Order_Transaction_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  order?: InputMaybe<Order_Bool_Exp>
  order_id?: InputMaybe<Uuid_Comparison_Exp>
  transaction?: InputMaybe<Transaction_Bool_Exp>
  transaction_id?: InputMaybe<Uuid_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "order_transaction" */
export enum Order_Transaction_Constraint {
  /** unique or primary key constraint on columns "transaction_id", "order_id" */
  OrderTransactionOrderIdTransactionIdKey = 'order_transaction_order_id_transaction_id_key',
  /** unique or primary key constraint on columns "id" */
  OrderTransactionPkey = 'order_transaction_pkey',
}

/** input type for inserting data into table "order_transaction" */
export type Order_Transaction_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  order?: InputMaybe<Order_Obj_Rel_Insert_Input>
  order_id?: InputMaybe<Scalars['uuid']['input']>
  transaction?: InputMaybe<Transaction_Obj_Rel_Insert_Input>
  transaction_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Order_Transaction_Max_Fields = {
  __typename?: 'order_transaction_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  order_id?: Maybe<Scalars['uuid']['output']>
  transaction_id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "order_transaction" */
export type Order_Transaction_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  order_id?: InputMaybe<Order_By>
  transaction_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Order_Transaction_Min_Fields = {
  __typename?: 'order_transaction_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  order_id?: Maybe<Scalars['uuid']['output']>
  transaction_id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "order_transaction" */
export type Order_Transaction_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  order_id?: InputMaybe<Order_By>
  transaction_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "order_transaction" */
export type Order_Transaction_Mutation_Response = {
  __typename?: 'order_transaction_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Order_Transaction>
}

/** on_conflict condition type for table "order_transaction" */
export type Order_Transaction_On_Conflict = {
  constraint: Order_Transaction_Constraint
  update_columns?: Array<Order_Transaction_Update_Column>
  where?: InputMaybe<Order_Transaction_Bool_Exp>
}

/** Ordering options when selecting data from "order_transaction". */
export type Order_Transaction_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  order?: InputMaybe<Order_Order_By>
  order_id?: InputMaybe<Order_By>
  transaction?: InputMaybe<Transaction_Order_By>
  transaction_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: order_transaction */
export type Order_Transaction_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "order_transaction" */
export enum Order_Transaction_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "order_transaction" */
export type Order_Transaction_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  order_id?: InputMaybe<Scalars['uuid']['input']>
  transaction_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "order_transaction" */
export type Order_Transaction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Transaction_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Order_Transaction_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  order_id?: InputMaybe<Scalars['uuid']['input']>
  transaction_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "order_transaction" */
export enum Order_Transaction_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Order_Transaction_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_Transaction_Set_Input>
  /** filter the rows which have to be updated */
  where: Order_Transaction_Bool_Exp
}

/** update columns of table "order" */
export enum Order_Update_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  IncrementId = 'increment_id',
  /** column name */
  IncrementIdAsText = 'increment_id_as_text',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Order_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Order_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Order_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Order_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Order_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Order_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Order_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_Set_Input>
  /** filter the rows which have to be updated */
  where: Order_Bool_Exp
}

/** aggregate var_pop on columns */
export type Order_Var_Pop_Fields = {
  __typename?: 'order_var_pop_fields'
  amount?: Maybe<Scalars['Float']['output']>
  increment_id?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "order" */
export type Order_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>
  increment_id?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Order_Var_Samp_Fields = {
  __typename?: 'order_var_samp_fields'
  amount?: Maybe<Scalars['Float']['output']>
  increment_id?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "order" */
export type Order_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>
  increment_id?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Order_Variance_Fields = {
  __typename?: 'order_variance_fields'
  amount?: Maybe<Scalars['Float']['output']>
  increment_id?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "order" */
export type Order_Variance_Order_By = {
  amount?: InputMaybe<Order_By>
  increment_id?: InputMaybe<Order_By>
}

/** columns and relationships of "payment_condition" */
export type Payment_Condition = {
  __typename?: 'payment_condition'
  code: Scalars['String']['output']
  created_at: Scalars['timestamptz']['output']
  /** An array relationship */
  customers: Array<Customer>
  /** An aggregate relationship */
  customers_aggregate: Customer_Aggregate
  description: Scalars['String']['output']
  description_de: Scalars['String']['output']
  description_en: Scalars['String']['output']
  description_es: Scalars['String']['output']
  description_fr: Scalars['String']['output']
  description_it: Scalars['String']['output']
  description_ru: Scalars['String']['output']
  rev: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "payment_condition" */
export type Payment_ConditionCustomersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

/** columns and relationships of "payment_condition" */
export type Payment_ConditionCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

/** aggregated selection of "payment_condition" */
export type Payment_Condition_Aggregate = {
  __typename?: 'payment_condition_aggregate'
  aggregate?: Maybe<Payment_Condition_Aggregate_Fields>
  nodes: Array<Payment_Condition>
}

/** aggregate fields of "payment_condition" */
export type Payment_Condition_Aggregate_Fields = {
  __typename?: 'payment_condition_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Payment_Condition_Max_Fields>
  min?: Maybe<Payment_Condition_Min_Fields>
}

/** aggregate fields of "payment_condition" */
export type Payment_Condition_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Condition_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "payment_condition". All fields are combined with a logical 'AND'. */
export type Payment_Condition_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Condition_Bool_Exp>>
  _not?: InputMaybe<Payment_Condition_Bool_Exp>
  _or?: InputMaybe<Array<Payment_Condition_Bool_Exp>>
  code?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customers?: InputMaybe<Customer_Bool_Exp>
  customers_aggregate?: InputMaybe<Customer_Aggregate_Bool_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  description_de?: InputMaybe<String_Comparison_Exp>
  description_en?: InputMaybe<String_Comparison_Exp>
  description_es?: InputMaybe<String_Comparison_Exp>
  description_fr?: InputMaybe<String_Comparison_Exp>
  description_it?: InputMaybe<String_Comparison_Exp>
  description_ru?: InputMaybe<String_Comparison_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "payment_condition" */
export enum Payment_Condition_Constraint {
  /** unique or primary key constraint on columns "code" */
  PaymentConditionPkey = 'payment_condition_pkey',
}

/** input type for inserting data into table "payment_condition" */
export type Payment_Condition_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customers?: InputMaybe<Customer_Arr_Rel_Insert_Input>
  description?: InputMaybe<Scalars['String']['input']>
  description_de?: InputMaybe<Scalars['String']['input']>
  description_en?: InputMaybe<Scalars['String']['input']>
  description_es?: InputMaybe<Scalars['String']['input']>
  description_fr?: InputMaybe<Scalars['String']['input']>
  description_it?: InputMaybe<Scalars['String']['input']>
  description_ru?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Payment_Condition_Max_Fields = {
  __typename?: 'payment_condition_max_fields'
  code?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  description_de?: Maybe<Scalars['String']['output']>
  description_en?: Maybe<Scalars['String']['output']>
  description_es?: Maybe<Scalars['String']['output']>
  description_fr?: Maybe<Scalars['String']['output']>
  description_it?: Maybe<Scalars['String']['output']>
  description_ru?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** aggregate min on columns */
export type Payment_Condition_Min_Fields = {
  __typename?: 'payment_condition_min_fields'
  code?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  description_de?: Maybe<Scalars['String']['output']>
  description_en?: Maybe<Scalars['String']['output']>
  description_es?: Maybe<Scalars['String']['output']>
  description_fr?: Maybe<Scalars['String']['output']>
  description_it?: Maybe<Scalars['String']['output']>
  description_ru?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** response of any mutation on the table "payment_condition" */
export type Payment_Condition_Mutation_Response = {
  __typename?: 'payment_condition_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Condition>
}

/** input type for inserting object relation for remote table "payment_condition" */
export type Payment_Condition_Obj_Rel_Insert_Input = {
  data: Payment_Condition_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Payment_Condition_On_Conflict>
}

/** on_conflict condition type for table "payment_condition" */
export type Payment_Condition_On_Conflict = {
  constraint: Payment_Condition_Constraint
  update_columns?: Array<Payment_Condition_Update_Column>
  where?: InputMaybe<Payment_Condition_Bool_Exp>
}

/** Ordering options when selecting data from "payment_condition". */
export type Payment_Condition_Order_By = {
  code?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customers_aggregate?: InputMaybe<Customer_Aggregate_Order_By>
  description?: InputMaybe<Order_By>
  description_de?: InputMaybe<Order_By>
  description_en?: InputMaybe<Order_By>
  description_es?: InputMaybe<Order_By>
  description_fr?: InputMaybe<Order_By>
  description_it?: InputMaybe<Order_By>
  description_ru?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: payment_condition */
export type Payment_Condition_Pk_Columns_Input = {
  code: Scalars['String']['input']
}

/** select columns of table "payment_condition" */
export enum Payment_Condition_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionDe = 'description_de',
  /** column name */
  DescriptionEn = 'description_en',
  /** column name */
  DescriptionEs = 'description_es',
  /** column name */
  DescriptionFr = 'description_fr',
  /** column name */
  DescriptionIt = 'description_it',
  /** column name */
  DescriptionRu = 'description_ru',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "payment_condition" */
export type Payment_Condition_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  description_de?: InputMaybe<Scalars['String']['input']>
  description_en?: InputMaybe<Scalars['String']['input']>
  description_es?: InputMaybe<Scalars['String']['input']>
  description_fr?: InputMaybe<Scalars['String']['input']>
  description_it?: InputMaybe<Scalars['String']['input']>
  description_ru?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "payment_condition" */
export type Payment_Condition_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Condition_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Payment_Condition_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  description_de?: InputMaybe<Scalars['String']['input']>
  description_en?: InputMaybe<Scalars['String']['input']>
  description_es?: InputMaybe<Scalars['String']['input']>
  description_fr?: InputMaybe<Scalars['String']['input']>
  description_it?: InputMaybe<Scalars['String']['input']>
  description_ru?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "payment_condition" */
export enum Payment_Condition_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionDe = 'description_de',
  /** column name */
  DescriptionEn = 'description_en',
  /** column name */
  DescriptionEs = 'description_es',
  /** column name */
  DescriptionFr = 'description_fr',
  /** column name */
  DescriptionIt = 'description_it',
  /** column name */
  DescriptionRu = 'description_ru',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Payment_Condition_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Condition_Set_Input>
  /** filter the rows which have to be updated */
  where: Payment_Condition_Bool_Exp
}

/** columns and relationships of "payment_method" */
export type Payment_Method = {
  __typename?: 'payment_method'
  code: Scalars['String']['output']
  created_at: Scalars['timestamptz']['output']
  /** An array relationship */
  customers: Array<Customer>
  /** An aggregate relationship */
  customers_aggregate: Customer_Aggregate
  description: Scalars['String']['output']
  description_de: Scalars['String']['output']
  description_en: Scalars['String']['output']
  description_es: Scalars['String']['output']
  description_fr: Scalars['String']['output']
  description_it: Scalars['String']['output']
  description_ru: Scalars['String']['output']
  rev: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "payment_method" */
export type Payment_MethodCustomersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

/** columns and relationships of "payment_method" */
export type Payment_MethodCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

/** aggregated selection of "payment_method" */
export type Payment_Method_Aggregate = {
  __typename?: 'payment_method_aggregate'
  aggregate?: Maybe<Payment_Method_Aggregate_Fields>
  nodes: Array<Payment_Method>
}

/** aggregate fields of "payment_method" */
export type Payment_Method_Aggregate_Fields = {
  __typename?: 'payment_method_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Payment_Method_Max_Fields>
  min?: Maybe<Payment_Method_Min_Fields>
}

/** aggregate fields of "payment_method" */
export type Payment_Method_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Method_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "payment_method". All fields are combined with a logical 'AND'. */
export type Payment_Method_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Method_Bool_Exp>>
  _not?: InputMaybe<Payment_Method_Bool_Exp>
  _or?: InputMaybe<Array<Payment_Method_Bool_Exp>>
  code?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customers?: InputMaybe<Customer_Bool_Exp>
  customers_aggregate?: InputMaybe<Customer_Aggregate_Bool_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  description_de?: InputMaybe<String_Comparison_Exp>
  description_en?: InputMaybe<String_Comparison_Exp>
  description_es?: InputMaybe<String_Comparison_Exp>
  description_fr?: InputMaybe<String_Comparison_Exp>
  description_it?: InputMaybe<String_Comparison_Exp>
  description_ru?: InputMaybe<String_Comparison_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "payment_method" */
export enum Payment_Method_Constraint {
  /** unique or primary key constraint on columns "code" */
  PaymentMethodPkey = 'payment_method_pkey',
}

/** input type for inserting data into table "payment_method" */
export type Payment_Method_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customers?: InputMaybe<Customer_Arr_Rel_Insert_Input>
  description?: InputMaybe<Scalars['String']['input']>
  description_de?: InputMaybe<Scalars['String']['input']>
  description_en?: InputMaybe<Scalars['String']['input']>
  description_es?: InputMaybe<Scalars['String']['input']>
  description_fr?: InputMaybe<Scalars['String']['input']>
  description_it?: InputMaybe<Scalars['String']['input']>
  description_ru?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Payment_Method_Max_Fields = {
  __typename?: 'payment_method_max_fields'
  code?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  description_de?: Maybe<Scalars['String']['output']>
  description_en?: Maybe<Scalars['String']['output']>
  description_es?: Maybe<Scalars['String']['output']>
  description_fr?: Maybe<Scalars['String']['output']>
  description_it?: Maybe<Scalars['String']['output']>
  description_ru?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** aggregate min on columns */
export type Payment_Method_Min_Fields = {
  __typename?: 'payment_method_min_fields'
  code?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  description_de?: Maybe<Scalars['String']['output']>
  description_en?: Maybe<Scalars['String']['output']>
  description_es?: Maybe<Scalars['String']['output']>
  description_fr?: Maybe<Scalars['String']['output']>
  description_it?: Maybe<Scalars['String']['output']>
  description_ru?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** response of any mutation on the table "payment_method" */
export type Payment_Method_Mutation_Response = {
  __typename?: 'payment_method_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Method>
}

/** input type for inserting object relation for remote table "payment_method" */
export type Payment_Method_Obj_Rel_Insert_Input = {
  data: Payment_Method_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Payment_Method_On_Conflict>
}

/** on_conflict condition type for table "payment_method" */
export type Payment_Method_On_Conflict = {
  constraint: Payment_Method_Constraint
  update_columns?: Array<Payment_Method_Update_Column>
  where?: InputMaybe<Payment_Method_Bool_Exp>
}

/** Ordering options when selecting data from "payment_method". */
export type Payment_Method_Order_By = {
  code?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customers_aggregate?: InputMaybe<Customer_Aggregate_Order_By>
  description?: InputMaybe<Order_By>
  description_de?: InputMaybe<Order_By>
  description_en?: InputMaybe<Order_By>
  description_es?: InputMaybe<Order_By>
  description_fr?: InputMaybe<Order_By>
  description_it?: InputMaybe<Order_By>
  description_ru?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: payment_method */
export type Payment_Method_Pk_Columns_Input = {
  code: Scalars['String']['input']
}

/** select columns of table "payment_method" */
export enum Payment_Method_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionDe = 'description_de',
  /** column name */
  DescriptionEn = 'description_en',
  /** column name */
  DescriptionEs = 'description_es',
  /** column name */
  DescriptionFr = 'description_fr',
  /** column name */
  DescriptionIt = 'description_it',
  /** column name */
  DescriptionRu = 'description_ru',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "payment_method" */
export type Payment_Method_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  description_de?: InputMaybe<Scalars['String']['input']>
  description_en?: InputMaybe<Scalars['String']['input']>
  description_es?: InputMaybe<Scalars['String']['input']>
  description_fr?: InputMaybe<Scalars['String']['input']>
  description_it?: InputMaybe<Scalars['String']['input']>
  description_ru?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "payment_method" */
export type Payment_Method_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Method_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Payment_Method_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  description_de?: InputMaybe<Scalars['String']['input']>
  description_en?: InputMaybe<Scalars['String']['input']>
  description_es?: InputMaybe<Scalars['String']['input']>
  description_fr?: InputMaybe<Scalars['String']['input']>
  description_it?: InputMaybe<Scalars['String']['input']>
  description_ru?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "payment_method" */
export enum Payment_Method_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionDe = 'description_de',
  /** column name */
  DescriptionEn = 'description_en',
  /** column name */
  DescriptionEs = 'description_es',
  /** column name */
  DescriptionFr = 'description_fr',
  /** column name */
  DescriptionIt = 'description_it',
  /** column name */
  DescriptionRu = 'description_ru',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Payment_Method_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Method_Set_Input>
  /** filter the rows which have to be updated */
  where: Payment_Method_Bool_Exp
}

/** columns and relationships of "post_code" */
export type Post_Code = {
  __typename?: 'post_code'
  city: Scalars['String']['output']
  code: Scalars['String']['output']
  /** An object relationship */
  country_region?: Maybe<Country_Region>
  country_region_code: Scalars['String']['output']
  /** An object relationship */
  county?: Maybe<County>
  county_code: Scalars['String']['output']
  created_at: Scalars['timestamptz']['output']
  id: Scalars['uuid']['output']
  rev: Scalars['String']['output']
  /** An object relationship */
  territory?: Maybe<Territory>
  territory_code: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** aggregated selection of "post_code" */
export type Post_Code_Aggregate = {
  __typename?: 'post_code_aggregate'
  aggregate?: Maybe<Post_Code_Aggregate_Fields>
  nodes: Array<Post_Code>
}

export type Post_Code_Aggregate_Bool_Exp = {
  count?: InputMaybe<Post_Code_Aggregate_Bool_Exp_Count>
}

export type Post_Code_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Post_Code_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Post_Code_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "post_code" */
export type Post_Code_Aggregate_Fields = {
  __typename?: 'post_code_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Post_Code_Max_Fields>
  min?: Maybe<Post_Code_Min_Fields>
}

/** aggregate fields of "post_code" */
export type Post_Code_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Post_Code_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "post_code" */
export type Post_Code_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Post_Code_Max_Order_By>
  min?: InputMaybe<Post_Code_Min_Order_By>
}

/** input type for inserting array relation for remote table "post_code" */
export type Post_Code_Arr_Rel_Insert_Input = {
  data: Array<Post_Code_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Post_Code_On_Conflict>
}

/** Boolean expression to filter rows from the table "post_code". All fields are combined with a logical 'AND'. */
export type Post_Code_Bool_Exp = {
  _and?: InputMaybe<Array<Post_Code_Bool_Exp>>
  _not?: InputMaybe<Post_Code_Bool_Exp>
  _or?: InputMaybe<Array<Post_Code_Bool_Exp>>
  city?: InputMaybe<String_Comparison_Exp>
  code?: InputMaybe<String_Comparison_Exp>
  country_region?: InputMaybe<Country_Region_Bool_Exp>
  country_region_code?: InputMaybe<String_Comparison_Exp>
  county?: InputMaybe<County_Bool_Exp>
  county_code?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  territory?: InputMaybe<Territory_Bool_Exp>
  territory_code?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "post_code" */
export enum Post_Code_Constraint {
  /** unique or primary key constraint on columns "city", "code", "country_region_code", "territory_code", "county_code" */
  PostCodeCodeCityCountryRegionCodeCountyCodeTerritoryC = 'post_code_code_city_country_region_code_county_code_territory_c',
  /** unique or primary key constraint on columns "id" */
  PostCodePkey = 'post_code_pkey',
}

/** input type for inserting data into table "post_code" */
export type Post_Code_Insert_Input = {
  city?: InputMaybe<Scalars['String']['input']>
  code?: InputMaybe<Scalars['String']['input']>
  country_region?: InputMaybe<Country_Region_Obj_Rel_Insert_Input>
  country_region_code?: InputMaybe<Scalars['String']['input']>
  county?: InputMaybe<County_Obj_Rel_Insert_Input>
  county_code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  territory?: InputMaybe<Territory_Obj_Rel_Insert_Input>
  territory_code?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Post_Code_Max_Fields = {
  __typename?: 'post_code_max_fields'
  city?: Maybe<Scalars['String']['output']>
  code?: Maybe<Scalars['String']['output']>
  country_region_code?: Maybe<Scalars['String']['output']>
  county_code?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  rev?: Maybe<Scalars['String']['output']>
  territory_code?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "post_code" */
export type Post_Code_Max_Order_By = {
  city?: InputMaybe<Order_By>
  code?: InputMaybe<Order_By>
  country_region_code?: InputMaybe<Order_By>
  county_code?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  territory_code?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Post_Code_Min_Fields = {
  __typename?: 'post_code_min_fields'
  city?: Maybe<Scalars['String']['output']>
  code?: Maybe<Scalars['String']['output']>
  country_region_code?: Maybe<Scalars['String']['output']>
  county_code?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  rev?: Maybe<Scalars['String']['output']>
  territory_code?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "post_code" */
export type Post_Code_Min_Order_By = {
  city?: InputMaybe<Order_By>
  code?: InputMaybe<Order_By>
  country_region_code?: InputMaybe<Order_By>
  county_code?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  territory_code?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "post_code" */
export type Post_Code_Mutation_Response = {
  __typename?: 'post_code_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Post_Code>
}

/** on_conflict condition type for table "post_code" */
export type Post_Code_On_Conflict = {
  constraint: Post_Code_Constraint
  update_columns?: Array<Post_Code_Update_Column>
  where?: InputMaybe<Post_Code_Bool_Exp>
}

/** Ordering options when selecting data from "post_code". */
export type Post_Code_Order_By = {
  city?: InputMaybe<Order_By>
  code?: InputMaybe<Order_By>
  country_region?: InputMaybe<Country_Region_Order_By>
  country_region_code?: InputMaybe<Order_By>
  county?: InputMaybe<County_Order_By>
  county_code?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  territory?: InputMaybe<Territory_Order_By>
  territory_code?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: post_code */
export type Post_Code_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "post_code" */
export enum Post_Code_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  Code = 'code',
  /** column name */
  CountryRegionCode = 'country_region_code',
  /** column name */
  CountyCode = 'county_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Rev = 'rev',
  /** column name */
  TerritoryCode = 'territory_code',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "post_code" */
export type Post_Code_Set_Input = {
  city?: InputMaybe<Scalars['String']['input']>
  code?: InputMaybe<Scalars['String']['input']>
  country_region_code?: InputMaybe<Scalars['String']['input']>
  county_code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  territory_code?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "post_code" */
export type Post_Code_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Post_Code_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Post_Code_Stream_Cursor_Value_Input = {
  city?: InputMaybe<Scalars['String']['input']>
  code?: InputMaybe<Scalars['String']['input']>
  country_region_code?: InputMaybe<Scalars['String']['input']>
  county_code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  territory_code?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "post_code" */
export enum Post_Code_Update_Column {
  /** column name */
  City = 'city',
  /** column name */
  Code = 'code',
  /** column name */
  CountryRegionCode = 'country_region_code',
  /** column name */
  CountyCode = 'county_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Rev = 'rev',
  /** column name */
  TerritoryCode = 'territory_code',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Post_Code_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Post_Code_Set_Input>
  /** filter the rows which have to be updated */
  where: Post_Code_Bool_Exp
}

/** columns and relationships of "product" */
export type Product = {
  __typename?: 'product'
  box_pieces: Scalars['Int']['output']
  created_at: Scalars['timestamptz']['output']
  customer_price?: Maybe<Scalars['String']['output']>
  dimension: Scalars['numeric']['output']
  erp_id: Scalars['String']['output']
  first_arrival_date?: Maybe<Scalars['date']['output']>
  gross_weight: Scalars['numeric']['output']
  id: Scalars['uuid']['output']
  name: Scalars['String']['output']
  /** An array relationship */
  order_product_items: Array<Order_Product_Item>
  /** An aggregate relationship */
  order_product_items_aggregate: Order_Product_Item_Aggregate
  /** An object relationship */
  product_discount_group: Product_Discount_Group
  product_discount_group_code: Scalars['String']['output']
  /** An array relationship */
  product_files: Array<Product_File>
  /** An aggregate relationship */
  product_files_aggregate: Product_File_Aggregate
  /** An array relationship */
  product_prices: Array<Product_Price>
  /** An aggregate relationship */
  product_prices_aggregate: Product_Price_Aggregate
  /** An object relationship */
  product_replacement_map?: Maybe<Product_Replacement_Map>
  /** An array relationship */
  product_replacements: Array<Product_Replacement>
  /** An aggregate relationship */
  product_replacements_aggregate: Product_Replacement_Aggregate
  promo: Scalars['Boolean']['output']
  rev: Scalars['String']['output']
  stock: Scalars['Int']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "product" */
export type ProductOrder_Product_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Order_Product_Item_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Product_Item_Order_By>>
  where?: InputMaybe<Order_Product_Item_Bool_Exp>
}

/** columns and relationships of "product" */
export type ProductOrder_Product_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Product_Item_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Product_Item_Order_By>>
  where?: InputMaybe<Order_Product_Item_Bool_Exp>
}

/** columns and relationships of "product" */
export type ProductProduct_FilesArgs = {
  distinct_on?: InputMaybe<Array<Product_File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_File_Order_By>>
  where?: InputMaybe<Product_File_Bool_Exp>
}

/** columns and relationships of "product" */
export type ProductProduct_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_File_Order_By>>
  where?: InputMaybe<Product_File_Bool_Exp>
}

/** columns and relationships of "product" */
export type ProductProduct_PricesArgs = {
  distinct_on?: InputMaybe<Array<Product_Price_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Price_Order_By>>
  where?: InputMaybe<Product_Price_Bool_Exp>
}

/** columns and relationships of "product" */
export type ProductProduct_Prices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Price_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Price_Order_By>>
  where?: InputMaybe<Product_Price_Bool_Exp>
}

/** columns and relationships of "product" */
export type ProductProduct_ReplacementsArgs = {
  distinct_on?: InputMaybe<Array<Product_Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Replacement_Order_By>>
  where?: InputMaybe<Product_Replacement_Bool_Exp>
}

/** columns and relationships of "product" */
export type ProductProduct_Replacements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Replacement_Order_By>>
  where?: InputMaybe<Product_Replacement_Bool_Exp>
}

/** aggregated selection of "product" */
export type Product_Aggregate = {
  __typename?: 'product_aggregate'
  aggregate?: Maybe<Product_Aggregate_Fields>
  nodes: Array<Product>
}

export type Product_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Product_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<Product_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<Product_Aggregate_Bool_Exp_Count>
}

export type Product_Aggregate_Bool_Exp_Bool_And = {
  arguments: Product_Select_Column_Product_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Product_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Product_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Product_Select_Column_Product_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Product_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Product_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Product_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "product" */
export type Product_Aggregate_Fields = {
  __typename?: 'product_aggregate_fields'
  avg?: Maybe<Product_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Product_Max_Fields>
  min?: Maybe<Product_Min_Fields>
  stddev?: Maybe<Product_Stddev_Fields>
  stddev_pop?: Maybe<Product_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Product_Stddev_Samp_Fields>
  sum?: Maybe<Product_Sum_Fields>
  var_pop?: Maybe<Product_Var_Pop_Fields>
  var_samp?: Maybe<Product_Var_Samp_Fields>
  variance?: Maybe<Product_Variance_Fields>
}

/** aggregate fields of "product" */
export type Product_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "product" */
export type Product_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Product_Max_Order_By>
  min?: InputMaybe<Product_Min_Order_By>
  stddev?: InputMaybe<Product_Stddev_Order_By>
  stddev_pop?: InputMaybe<Product_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Product_Stddev_Samp_Order_By>
  sum?: InputMaybe<Product_Sum_Order_By>
  var_pop?: InputMaybe<Product_Var_Pop_Order_By>
  var_samp?: InputMaybe<Product_Var_Samp_Order_By>
  variance?: InputMaybe<Product_Variance_Order_By>
}

/** input type for inserting array relation for remote table "product" */
export type Product_Arr_Rel_Insert_Input = {
  data: Array<Product_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Product_On_Conflict>
}

/** aggregate avg on columns */
export type Product_Avg_Fields = {
  __typename?: 'product_avg_fields'
  box_pieces?: Maybe<Scalars['Float']['output']>
  dimension?: Maybe<Scalars['Float']['output']>
  gross_weight?: Maybe<Scalars['Float']['output']>
  stock?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "product" */
export type Product_Avg_Order_By = {
  box_pieces?: InputMaybe<Order_By>
  dimension?: InputMaybe<Order_By>
  gross_weight?: InputMaybe<Order_By>
  stock?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "product". All fields are combined with a logical 'AND'. */
export type Product_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Bool_Exp>>
  _not?: InputMaybe<Product_Bool_Exp>
  _or?: InputMaybe<Array<Product_Bool_Exp>>
  box_pieces?: InputMaybe<Int_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customer_price?: InputMaybe<String_Comparison_Exp>
  dimension?: InputMaybe<Numeric_Comparison_Exp>
  erp_id?: InputMaybe<String_Comparison_Exp>
  first_arrival_date?: InputMaybe<Date_Comparison_Exp>
  gross_weight?: InputMaybe<Numeric_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  order_product_items?: InputMaybe<Order_Product_Item_Bool_Exp>
  order_product_items_aggregate?: InputMaybe<Order_Product_Item_Aggregate_Bool_Exp>
  product_discount_group?: InputMaybe<Product_Discount_Group_Bool_Exp>
  product_discount_group_code?: InputMaybe<String_Comparison_Exp>
  product_files?: InputMaybe<Product_File_Bool_Exp>
  product_files_aggregate?: InputMaybe<Product_File_Aggregate_Bool_Exp>
  product_prices?: InputMaybe<Product_Price_Bool_Exp>
  product_prices_aggregate?: InputMaybe<Product_Price_Aggregate_Bool_Exp>
  product_replacement_map?: InputMaybe<Product_Replacement_Map_Bool_Exp>
  product_replacements?: InputMaybe<Product_Replacement_Bool_Exp>
  product_replacements_aggregate?: InputMaybe<Product_Replacement_Aggregate_Bool_Exp>
  promo?: InputMaybe<Boolean_Comparison_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  stock?: InputMaybe<Int_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "product" */
export enum Product_Constraint {
  /** unique or primary key constraint on columns "erp_id" */
  ProductErpIdKey = 'product_erp_id_key',
  /** unique or primary key constraint on columns "id" */
  ProductPkey = 'product_pkey',
}

/** columns and relationships of "product_discount_group" */
export type Product_Discount_Group = {
  __typename?: 'product_discount_group'
  code: Scalars['String']['output']
  /** An array relationship */
  customer_discounts: Array<Customer_Discount>
  /** An aggregate relationship */
  customer_discounts_aggregate: Customer_Discount_Aggregate
  /** An array relationship */
  products: Array<Product>
  /** An aggregate relationship */
  products_aggregate: Product_Aggregate
}

/** columns and relationships of "product_discount_group" */
export type Product_Discount_GroupCustomer_DiscountsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Discount_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Discount_Order_By>>
  where?: InputMaybe<Customer_Discount_Bool_Exp>
}

/** columns and relationships of "product_discount_group" */
export type Product_Discount_GroupCustomer_Discounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Discount_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Discount_Order_By>>
  where?: InputMaybe<Customer_Discount_Bool_Exp>
}

/** columns and relationships of "product_discount_group" */
export type Product_Discount_GroupProductsArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Order_By>>
  where?: InputMaybe<Product_Bool_Exp>
}

/** columns and relationships of "product_discount_group" */
export type Product_Discount_GroupProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Order_By>>
  where?: InputMaybe<Product_Bool_Exp>
}

/** aggregated selection of "product_discount_group" */
export type Product_Discount_Group_Aggregate = {
  __typename?: 'product_discount_group_aggregate'
  aggregate?: Maybe<Product_Discount_Group_Aggregate_Fields>
  nodes: Array<Product_Discount_Group>
}

/** aggregate fields of "product_discount_group" */
export type Product_Discount_Group_Aggregate_Fields = {
  __typename?: 'product_discount_group_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Product_Discount_Group_Max_Fields>
  min?: Maybe<Product_Discount_Group_Min_Fields>
}

/** aggregate fields of "product_discount_group" */
export type Product_Discount_Group_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Discount_Group_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "product_discount_group". All fields are combined with a logical 'AND'. */
export type Product_Discount_Group_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Discount_Group_Bool_Exp>>
  _not?: InputMaybe<Product_Discount_Group_Bool_Exp>
  _or?: InputMaybe<Array<Product_Discount_Group_Bool_Exp>>
  code?: InputMaybe<String_Comparison_Exp>
  customer_discounts?: InputMaybe<Customer_Discount_Bool_Exp>
  customer_discounts_aggregate?: InputMaybe<Customer_Discount_Aggregate_Bool_Exp>
  products?: InputMaybe<Product_Bool_Exp>
  products_aggregate?: InputMaybe<Product_Aggregate_Bool_Exp>
}

/** unique or primary key constraints on table "product_discount_group" */
export enum Product_Discount_Group_Constraint {
  /** unique or primary key constraint on columns "code" */
  ProductDiscountGroupPkey = 'product_discount_group_pkey',
}

/** input type for inserting data into table "product_discount_group" */
export type Product_Discount_Group_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  customer_discounts?: InputMaybe<Customer_Discount_Arr_Rel_Insert_Input>
  products?: InputMaybe<Product_Arr_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Product_Discount_Group_Max_Fields = {
  __typename?: 'product_discount_group_max_fields'
  code?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Product_Discount_Group_Min_Fields = {
  __typename?: 'product_discount_group_min_fields'
  code?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "product_discount_group" */
export type Product_Discount_Group_Mutation_Response = {
  __typename?: 'product_discount_group_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Product_Discount_Group>
}

/** input type for inserting object relation for remote table "product_discount_group" */
export type Product_Discount_Group_Obj_Rel_Insert_Input = {
  data: Product_Discount_Group_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Discount_Group_On_Conflict>
}

/** on_conflict condition type for table "product_discount_group" */
export type Product_Discount_Group_On_Conflict = {
  constraint: Product_Discount_Group_Constraint
  update_columns?: Array<Product_Discount_Group_Update_Column>
  where?: InputMaybe<Product_Discount_Group_Bool_Exp>
}

/** Ordering options when selecting data from "product_discount_group". */
export type Product_Discount_Group_Order_By = {
  code?: InputMaybe<Order_By>
  customer_discounts_aggregate?: InputMaybe<Customer_Discount_Aggregate_Order_By>
  products_aggregate?: InputMaybe<Product_Aggregate_Order_By>
}

/** primary key columns input for table: product_discount_group */
export type Product_Discount_Group_Pk_Columns_Input = {
  code: Scalars['String']['input']
}

/** select columns of table "product_discount_group" */
export enum Product_Discount_Group_Select_Column {
  /** column name */
  Code = 'code',
}

/** input type for updating data in table "product_discount_group" */
export type Product_Discount_Group_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "product_discount_group" */
export type Product_Discount_Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Discount_Group_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Product_Discount_Group_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "product_discount_group" */
export enum Product_Discount_Group_Update_Column {
  /** column name */
  Code = 'code',
}

export type Product_Discount_Group_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Discount_Group_Set_Input>
  /** filter the rows which have to be updated */
  where: Product_Discount_Group_Bool_Exp
}

/** columns and relationships of "product_file" */
export type Product_File = {
  __typename?: 'product_file'
  filename: Scalars['String']['output']
  folder: Scalars['String']['output']
  id: Scalars['uuid']['output']
  /** An object relationship */
  product: Product
  product_erp_id: Scalars['String']['output']
}

/** aggregated selection of "product_file" */
export type Product_File_Aggregate = {
  __typename?: 'product_file_aggregate'
  aggregate?: Maybe<Product_File_Aggregate_Fields>
  nodes: Array<Product_File>
}

export type Product_File_Aggregate_Bool_Exp = {
  count?: InputMaybe<Product_File_Aggregate_Bool_Exp_Count>
}

export type Product_File_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_File_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Product_File_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "product_file" */
export type Product_File_Aggregate_Fields = {
  __typename?: 'product_file_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Product_File_Max_Fields>
  min?: Maybe<Product_File_Min_Fields>
}

/** aggregate fields of "product_file" */
export type Product_File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_File_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "product_file" */
export type Product_File_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Product_File_Max_Order_By>
  min?: InputMaybe<Product_File_Min_Order_By>
}

/** input type for inserting array relation for remote table "product_file" */
export type Product_File_Arr_Rel_Insert_Input = {
  data: Array<Product_File_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Product_File_On_Conflict>
}

/** Boolean expression to filter rows from the table "product_file". All fields are combined with a logical 'AND'. */
export type Product_File_Bool_Exp = {
  _and?: InputMaybe<Array<Product_File_Bool_Exp>>
  _not?: InputMaybe<Product_File_Bool_Exp>
  _or?: InputMaybe<Array<Product_File_Bool_Exp>>
  filename?: InputMaybe<String_Comparison_Exp>
  folder?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  product?: InputMaybe<Product_Bool_Exp>
  product_erp_id?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "product_file" */
export enum Product_File_Constraint {
  /** unique or primary key constraint on columns "folder", "filename" */
  ProductFileFolderFilenameKey = 'product_file_folder_filename_key',
  /** unique or primary key constraint on columns "id" */
  ProductFilePkey = 'product_file_pkey',
}

/** input type for inserting data into table "product_file" */
export type Product_File_Insert_Input = {
  filename?: InputMaybe<Scalars['String']['input']>
  folder?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  product?: InputMaybe<Product_Obj_Rel_Insert_Input>
  product_erp_id?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Product_File_Max_Fields = {
  __typename?: 'product_file_max_fields'
  filename?: Maybe<Scalars['String']['output']>
  folder?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  product_erp_id?: Maybe<Scalars['String']['output']>
}

/** order by max() on columns of table "product_file" */
export type Product_File_Max_Order_By = {
  filename?: InputMaybe<Order_By>
  folder?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  product_erp_id?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Product_File_Min_Fields = {
  __typename?: 'product_file_min_fields'
  filename?: Maybe<Scalars['String']['output']>
  folder?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  product_erp_id?: Maybe<Scalars['String']['output']>
}

/** order by min() on columns of table "product_file" */
export type Product_File_Min_Order_By = {
  filename?: InputMaybe<Order_By>
  folder?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  product_erp_id?: InputMaybe<Order_By>
}

/** response of any mutation on the table "product_file" */
export type Product_File_Mutation_Response = {
  __typename?: 'product_file_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Product_File>
}

/** on_conflict condition type for table "product_file" */
export type Product_File_On_Conflict = {
  constraint: Product_File_Constraint
  update_columns?: Array<Product_File_Update_Column>
  where?: InputMaybe<Product_File_Bool_Exp>
}

/** Ordering options when selecting data from "product_file". */
export type Product_File_Order_By = {
  filename?: InputMaybe<Order_By>
  folder?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  product?: InputMaybe<Product_Order_By>
  product_erp_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: product_file */
export type Product_File_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "product_file" */
export enum Product_File_Select_Column {
  /** column name */
  Filename = 'filename',
  /** column name */
  Folder = 'folder',
  /** column name */
  Id = 'id',
  /** column name */
  ProductErpId = 'product_erp_id',
}

/** input type for updating data in table "product_file" */
export type Product_File_Set_Input = {
  filename?: InputMaybe<Scalars['String']['input']>
  folder?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  product_erp_id?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "product_file" */
export type Product_File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_File_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Product_File_Stream_Cursor_Value_Input = {
  filename?: InputMaybe<Scalars['String']['input']>
  folder?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  product_erp_id?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "product_file" */
export enum Product_File_Update_Column {
  /** column name */
  Filename = 'filename',
  /** column name */
  Folder = 'folder',
  /** column name */
  Id = 'id',
  /** column name */
  ProductErpId = 'product_erp_id',
}

export type Product_File_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_File_Set_Input>
  /** filter the rows which have to be updated */
  where: Product_File_Bool_Exp
}

/** input type for incrementing numeric columns in table "product" */
export type Product_Inc_Input = {
  box_pieces?: InputMaybe<Scalars['Int']['input']>
  dimension?: InputMaybe<Scalars['numeric']['input']>
  gross_weight?: InputMaybe<Scalars['numeric']['input']>
  stock?: InputMaybe<Scalars['Int']['input']>
}

/** input type for inserting data into table "product" */
export type Product_Insert_Input = {
  box_pieces?: InputMaybe<Scalars['Int']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  dimension?: InputMaybe<Scalars['numeric']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  first_arrival_date?: InputMaybe<Scalars['date']['input']>
  gross_weight?: InputMaybe<Scalars['numeric']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  order_product_items?: InputMaybe<Order_Product_Item_Arr_Rel_Insert_Input>
  product_discount_group?: InputMaybe<Product_Discount_Group_Obj_Rel_Insert_Input>
  product_discount_group_code?: InputMaybe<Scalars['String']['input']>
  product_files?: InputMaybe<Product_File_Arr_Rel_Insert_Input>
  product_prices?: InputMaybe<Product_Price_Arr_Rel_Insert_Input>
  product_replacement_map?: InputMaybe<Product_Replacement_Map_Obj_Rel_Insert_Input>
  product_replacements?: InputMaybe<Product_Replacement_Arr_Rel_Insert_Input>
  promo?: InputMaybe<Scalars['Boolean']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  stock?: InputMaybe<Scalars['Int']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Product_Max_Fields = {
  __typename?: 'product_max_fields'
  box_pieces?: Maybe<Scalars['Int']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_price?: Maybe<Scalars['String']['output']>
  dimension?: Maybe<Scalars['numeric']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  first_arrival_date?: Maybe<Scalars['date']['output']>
  gross_weight?: Maybe<Scalars['numeric']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  product_discount_group_code?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  stock?: Maybe<Scalars['Int']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "product" */
export type Product_Max_Order_By = {
  box_pieces?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  dimension?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  first_arrival_date?: InputMaybe<Order_By>
  gross_weight?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  product_discount_group_code?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  stock?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Product_Min_Fields = {
  __typename?: 'product_min_fields'
  box_pieces?: Maybe<Scalars['Int']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_price?: Maybe<Scalars['String']['output']>
  dimension?: Maybe<Scalars['numeric']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  first_arrival_date?: Maybe<Scalars['date']['output']>
  gross_weight?: Maybe<Scalars['numeric']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  product_discount_group_code?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  stock?: Maybe<Scalars['Int']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "product" */
export type Product_Min_Order_By = {
  box_pieces?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  dimension?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  first_arrival_date?: InputMaybe<Order_By>
  gross_weight?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  product_discount_group_code?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  stock?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "product" */
export type Product_Mutation_Response = {
  __typename?: 'product_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Product>
}

/** input type for inserting object relation for remote table "product" */
export type Product_Obj_Rel_Insert_Input = {
  data: Product_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Product_On_Conflict>
}

/** on_conflict condition type for table "product" */
export type Product_On_Conflict = {
  constraint: Product_Constraint
  update_columns?: Array<Product_Update_Column>
  where?: InputMaybe<Product_Bool_Exp>
}

/** Ordering options when selecting data from "product". */
export type Product_Order_By = {
  box_pieces?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customer_price?: InputMaybe<Order_By>
  dimension?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  first_arrival_date?: InputMaybe<Order_By>
  gross_weight?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  order_product_items_aggregate?: InputMaybe<Order_Product_Item_Aggregate_Order_By>
  product_discount_group?: InputMaybe<Product_Discount_Group_Order_By>
  product_discount_group_code?: InputMaybe<Order_By>
  product_files_aggregate?: InputMaybe<Product_File_Aggregate_Order_By>
  product_prices_aggregate?: InputMaybe<Product_Price_Aggregate_Order_By>
  product_replacement_map?: InputMaybe<Product_Replacement_Map_Order_By>
  product_replacements_aggregate?: InputMaybe<Product_Replacement_Aggregate_Order_By>
  promo?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  stock?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: product */
export type Product_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** columns and relationships of "product_price" */
export type Product_Price = {
  __typename?: 'product_price'
  allow_invoice_discount?: Maybe<Scalars['Boolean']['output']>
  allow_line_discount?: Maybe<Scalars['Boolean']['output']>
  created_at: Scalars['timestamptz']['output']
  custom_unique: Scalars['String']['output']
  /** An object relationship */
  customer?: Maybe<Customer>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  /** An array relationship */
  files: Array<Product_File>
  /** An aggregate relationship */
  files_aggregate: Product_File_Aggregate
  id: Scalars['uuid']['output']
  price: Scalars['numeric']['output']
  /** An object relationship */
  product: Product
  product_erp_id: Scalars['String']['output']
  /** An object relationship */
  product_price_list: Product_Price_List
  product_price_list_code: Scalars['String']['output']
  rev: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "product_price" */
export type Product_PriceFilesArgs = {
  distinct_on?: InputMaybe<Array<Product_File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_File_Order_By>>
  where?: InputMaybe<Product_File_Bool_Exp>
}

/** columns and relationships of "product_price" */
export type Product_PriceFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_File_Order_By>>
  where?: InputMaybe<Product_File_Bool_Exp>
}

/** aggregated selection of "product_price" */
export type Product_Price_Aggregate = {
  __typename?: 'product_price_aggregate'
  aggregate?: Maybe<Product_Price_Aggregate_Fields>
  nodes: Array<Product_Price>
}

export type Product_Price_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Product_Price_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<Product_Price_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<Product_Price_Aggregate_Bool_Exp_Count>
}

export type Product_Price_Aggregate_Bool_Exp_Bool_And = {
  arguments: Product_Price_Select_Column_Product_Price_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Product_Price_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Product_Price_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Product_Price_Select_Column_Product_Price_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Product_Price_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Product_Price_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Price_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Product_Price_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "product_price" */
export type Product_Price_Aggregate_Fields = {
  __typename?: 'product_price_aggregate_fields'
  avg?: Maybe<Product_Price_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Product_Price_Max_Fields>
  min?: Maybe<Product_Price_Min_Fields>
  stddev?: Maybe<Product_Price_Stddev_Fields>
  stddev_pop?: Maybe<Product_Price_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Product_Price_Stddev_Samp_Fields>
  sum?: Maybe<Product_Price_Sum_Fields>
  var_pop?: Maybe<Product_Price_Var_Pop_Fields>
  var_samp?: Maybe<Product_Price_Var_Samp_Fields>
  variance?: Maybe<Product_Price_Variance_Fields>
}

/** aggregate fields of "product_price" */
export type Product_Price_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Price_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "product_price" */
export type Product_Price_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Price_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Product_Price_Max_Order_By>
  min?: InputMaybe<Product_Price_Min_Order_By>
  stddev?: InputMaybe<Product_Price_Stddev_Order_By>
  stddev_pop?: InputMaybe<Product_Price_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Product_Price_Stddev_Samp_Order_By>
  sum?: InputMaybe<Product_Price_Sum_Order_By>
  var_pop?: InputMaybe<Product_Price_Var_Pop_Order_By>
  var_samp?: InputMaybe<Product_Price_Var_Samp_Order_By>
  variance?: InputMaybe<Product_Price_Variance_Order_By>
}

/** input type for inserting array relation for remote table "product_price" */
export type Product_Price_Arr_Rel_Insert_Input = {
  data: Array<Product_Price_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Price_On_Conflict>
}

/** aggregate avg on columns */
export type Product_Price_Avg_Fields = {
  __typename?: 'product_price_avg_fields'
  price?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "product_price" */
export type Product_Price_Avg_Order_By = {
  price?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "product_price". All fields are combined with a logical 'AND'. */
export type Product_Price_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Price_Bool_Exp>>
  _not?: InputMaybe<Product_Price_Bool_Exp>
  _or?: InputMaybe<Array<Product_Price_Bool_Exp>>
  allow_invoice_discount?: InputMaybe<Boolean_Comparison_Exp>
  allow_line_discount?: InputMaybe<Boolean_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  custom_unique?: InputMaybe<String_Comparison_Exp>
  customer?: InputMaybe<Customer_Bool_Exp>
  customer_erp_id?: InputMaybe<String_Comparison_Exp>
  files?: InputMaybe<Product_File_Bool_Exp>
  files_aggregate?: InputMaybe<Product_File_Aggregate_Bool_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  price?: InputMaybe<Numeric_Comparison_Exp>
  product?: InputMaybe<Product_Bool_Exp>
  product_erp_id?: InputMaybe<String_Comparison_Exp>
  product_price_list?: InputMaybe<Product_Price_List_Bool_Exp>
  product_price_list_code?: InputMaybe<String_Comparison_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "product_price" */
export enum Product_Price_Constraint {
  /** unique or primary key constraint on columns "custom_unique" */
  ProductPriceCustomUniqueKey = 'product_price_custom_unique_key',
  /** unique or primary key constraint on columns "id" */
  ProductPricePkey = 'product_price_pkey',
  /** unique or primary key constraint on columns "product_erp_id", "product_price_list_code", "customer_erp_id" */
  ProductPriceProductErpIdProductPriceListCodeCustomKey = 'product_price_product_erp_id_product_price_list_code_custom_key',
}

/** input type for incrementing numeric columns in table "product_price" */
export type Product_Price_Inc_Input = {
  price?: InputMaybe<Scalars['numeric']['input']>
}

/** input type for inserting data into table "product_price" */
export type Product_Price_Insert_Input = {
  allow_invoice_discount?: InputMaybe<Scalars['Boolean']['input']>
  allow_line_discount?: InputMaybe<Scalars['Boolean']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  custom_unique?: InputMaybe<Scalars['String']['input']>
  customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  files?: InputMaybe<Product_File_Arr_Rel_Insert_Input>
  id?: InputMaybe<Scalars['uuid']['input']>
  price?: InputMaybe<Scalars['numeric']['input']>
  product?: InputMaybe<Product_Obj_Rel_Insert_Input>
  product_erp_id?: InputMaybe<Scalars['String']['input']>
  product_price_list?: InputMaybe<Product_Price_List_Obj_Rel_Insert_Input>
  product_price_list_code?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** columns and relationships of "product_price_list" */
export type Product_Price_List = {
  __typename?: 'product_price_list'
  code: Scalars['String']['output']
  /** An array relationship */
  customers: Array<Customer>
  /** An aggregate relationship */
  customers_aggregate: Customer_Aggregate
  /** An array relationship */
  product_prices: Array<Product_Price>
  /** An aggregate relationship */
  product_prices_aggregate: Product_Price_Aggregate
}

/** columns and relationships of "product_price_list" */
export type Product_Price_ListCustomersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

/** columns and relationships of "product_price_list" */
export type Product_Price_ListCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

/** columns and relationships of "product_price_list" */
export type Product_Price_ListProduct_PricesArgs = {
  distinct_on?: InputMaybe<Array<Product_Price_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Price_Order_By>>
  where?: InputMaybe<Product_Price_Bool_Exp>
}

/** columns and relationships of "product_price_list" */
export type Product_Price_ListProduct_Prices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Price_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Price_Order_By>>
  where?: InputMaybe<Product_Price_Bool_Exp>
}

/** aggregated selection of "product_price_list" */
export type Product_Price_List_Aggregate = {
  __typename?: 'product_price_list_aggregate'
  aggregate?: Maybe<Product_Price_List_Aggregate_Fields>
  nodes: Array<Product_Price_List>
}

/** aggregate fields of "product_price_list" */
export type Product_Price_List_Aggregate_Fields = {
  __typename?: 'product_price_list_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Product_Price_List_Max_Fields>
  min?: Maybe<Product_Price_List_Min_Fields>
}

/** aggregate fields of "product_price_list" */
export type Product_Price_List_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Price_List_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "product_price_list". All fields are combined with a logical 'AND'. */
export type Product_Price_List_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Price_List_Bool_Exp>>
  _not?: InputMaybe<Product_Price_List_Bool_Exp>
  _or?: InputMaybe<Array<Product_Price_List_Bool_Exp>>
  code?: InputMaybe<String_Comparison_Exp>
  customers?: InputMaybe<Customer_Bool_Exp>
  customers_aggregate?: InputMaybe<Customer_Aggregate_Bool_Exp>
  product_prices?: InputMaybe<Product_Price_Bool_Exp>
  product_prices_aggregate?: InputMaybe<Product_Price_Aggregate_Bool_Exp>
}

/** unique or primary key constraints on table "product_price_list" */
export enum Product_Price_List_Constraint {
  /** unique or primary key constraint on columns "code" */
  ProductPriceListPkey = 'product_price_list_pkey',
}

/** input type for inserting data into table "product_price_list" */
export type Product_Price_List_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  customers?: InputMaybe<Customer_Arr_Rel_Insert_Input>
  product_prices?: InputMaybe<Product_Price_Arr_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Product_Price_List_Max_Fields = {
  __typename?: 'product_price_list_max_fields'
  code?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Product_Price_List_Min_Fields = {
  __typename?: 'product_price_list_min_fields'
  code?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "product_price_list" */
export type Product_Price_List_Mutation_Response = {
  __typename?: 'product_price_list_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Product_Price_List>
}

/** input type for inserting object relation for remote table "product_price_list" */
export type Product_Price_List_Obj_Rel_Insert_Input = {
  data: Product_Price_List_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Price_List_On_Conflict>
}

/** on_conflict condition type for table "product_price_list" */
export type Product_Price_List_On_Conflict = {
  constraint: Product_Price_List_Constraint
  update_columns?: Array<Product_Price_List_Update_Column>
  where?: InputMaybe<Product_Price_List_Bool_Exp>
}

/** Ordering options when selecting data from "product_price_list". */
export type Product_Price_List_Order_By = {
  code?: InputMaybe<Order_By>
  customers_aggregate?: InputMaybe<Customer_Aggregate_Order_By>
  product_prices_aggregate?: InputMaybe<Product_Price_Aggregate_Order_By>
}

/** primary key columns input for table: product_price_list */
export type Product_Price_List_Pk_Columns_Input = {
  code: Scalars['String']['input']
}

/** select columns of table "product_price_list" */
export enum Product_Price_List_Select_Column {
  /** column name */
  Code = 'code',
}

/** input type for updating data in table "product_price_list" */
export type Product_Price_List_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "product_price_list" */
export type Product_Price_List_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Price_List_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Product_Price_List_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "product_price_list" */
export enum Product_Price_List_Update_Column {
  /** column name */
  Code = 'code',
}

export type Product_Price_List_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Price_List_Set_Input>
  /** filter the rows which have to be updated */
  where: Product_Price_List_Bool_Exp
}

/** aggregate max on columns */
export type Product_Price_Max_Fields = {
  __typename?: 'product_price_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  custom_unique?: Maybe<Scalars['String']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  price?: Maybe<Scalars['numeric']['output']>
  product_erp_id?: Maybe<Scalars['String']['output']>
  product_price_list_code?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "product_price" */
export type Product_Price_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  custom_unique?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
  product_erp_id?: InputMaybe<Order_By>
  product_price_list_code?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Product_Price_Min_Fields = {
  __typename?: 'product_price_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  custom_unique?: Maybe<Scalars['String']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  price?: Maybe<Scalars['numeric']['output']>
  product_erp_id?: Maybe<Scalars['String']['output']>
  product_price_list_code?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "product_price" */
export type Product_Price_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  custom_unique?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
  product_erp_id?: InputMaybe<Order_By>
  product_price_list_code?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "product_price" */
export type Product_Price_Mutation_Response = {
  __typename?: 'product_price_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Product_Price>
}

/** on_conflict condition type for table "product_price" */
export type Product_Price_On_Conflict = {
  constraint: Product_Price_Constraint
  update_columns?: Array<Product_Price_Update_Column>
  where?: InputMaybe<Product_Price_Bool_Exp>
}

/** Ordering options when selecting data from "product_price". */
export type Product_Price_Order_By = {
  allow_invoice_discount?: InputMaybe<Order_By>
  allow_line_discount?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  custom_unique?: InputMaybe<Order_By>
  customer?: InputMaybe<Customer_Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  files_aggregate?: InputMaybe<Product_File_Aggregate_Order_By>
  id?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
  product?: InputMaybe<Product_Order_By>
  product_erp_id?: InputMaybe<Order_By>
  product_price_list?: InputMaybe<Product_Price_List_Order_By>
  product_price_list_code?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: product_price */
export type Product_Price_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "product_price" */
export enum Product_Price_Select_Column {
  /** column name */
  AllowInvoiceDiscount = 'allow_invoice_discount',
  /** column name */
  AllowLineDiscount = 'allow_line_discount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomUnique = 'custom_unique',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  ProductErpId = 'product_erp_id',
  /** column name */
  ProductPriceListCode = 'product_price_list_code',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "product_price_aggregate_bool_exp_bool_and_arguments_columns" columns of table "product_price" */
export enum Product_Price_Select_Column_Product_Price_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AllowInvoiceDiscount = 'allow_invoice_discount',
  /** column name */
  AllowLineDiscount = 'allow_line_discount',
}

/** select "product_price_aggregate_bool_exp_bool_or_arguments_columns" columns of table "product_price" */
export enum Product_Price_Select_Column_Product_Price_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AllowInvoiceDiscount = 'allow_invoice_discount',
  /** column name */
  AllowLineDiscount = 'allow_line_discount',
}

/** input type for updating data in table "product_price" */
export type Product_Price_Set_Input = {
  allow_invoice_discount?: InputMaybe<Scalars['Boolean']['input']>
  allow_line_discount?: InputMaybe<Scalars['Boolean']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  custom_unique?: InputMaybe<Scalars['String']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  price?: InputMaybe<Scalars['numeric']['input']>
  product_erp_id?: InputMaybe<Scalars['String']['input']>
  product_price_list_code?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate stddev on columns */
export type Product_Price_Stddev_Fields = {
  __typename?: 'product_price_stddev_fields'
  price?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "product_price" */
export type Product_Price_Stddev_Order_By = {
  price?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Product_Price_Stddev_Pop_Fields = {
  __typename?: 'product_price_stddev_pop_fields'
  price?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "product_price" */
export type Product_Price_Stddev_Pop_Order_By = {
  price?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Product_Price_Stddev_Samp_Fields = {
  __typename?: 'product_price_stddev_samp_fields'
  price?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "product_price" */
export type Product_Price_Stddev_Samp_Order_By = {
  price?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "product_price" */
export type Product_Price_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Price_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Product_Price_Stream_Cursor_Value_Input = {
  allow_invoice_discount?: InputMaybe<Scalars['Boolean']['input']>
  allow_line_discount?: InputMaybe<Scalars['Boolean']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  custom_unique?: InputMaybe<Scalars['String']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  price?: InputMaybe<Scalars['numeric']['input']>
  product_erp_id?: InputMaybe<Scalars['String']['input']>
  product_price_list_code?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate sum on columns */
export type Product_Price_Sum_Fields = {
  __typename?: 'product_price_sum_fields'
  price?: Maybe<Scalars['numeric']['output']>
}

/** order by sum() on columns of table "product_price" */
export type Product_Price_Sum_Order_By = {
  price?: InputMaybe<Order_By>
}

/** update columns of table "product_price" */
export enum Product_Price_Update_Column {
  /** column name */
  AllowInvoiceDiscount = 'allow_invoice_discount',
  /** column name */
  AllowLineDiscount = 'allow_line_discount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomUnique = 'custom_unique',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  ProductErpId = 'product_erp_id',
  /** column name */
  ProductPriceListCode = 'product_price_list_code',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Product_Price_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_Price_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Price_Set_Input>
  /** filter the rows which have to be updated */
  where: Product_Price_Bool_Exp
}

/** aggregate var_pop on columns */
export type Product_Price_Var_Pop_Fields = {
  __typename?: 'product_price_var_pop_fields'
  price?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "product_price" */
export type Product_Price_Var_Pop_Order_By = {
  price?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Product_Price_Var_Samp_Fields = {
  __typename?: 'product_price_var_samp_fields'
  price?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "product_price" */
export type Product_Price_Var_Samp_Order_By = {
  price?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Product_Price_Variance_Fields = {
  __typename?: 'product_price_variance_fields'
  price?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "product_price" */
export type Product_Price_Variance_Order_By = {
  price?: InputMaybe<Order_By>
}

/** columns and relationships of "product_price_view" */
export type Product_Price_View = {
  __typename?: 'product_price_view'
  allow_invoice_discount?: Maybe<Scalars['Boolean']['output']>
  allow_line_discount?: Maybe<Scalars['Boolean']['output']>
  box_pieces?: Maybe<Scalars['Int']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_discount_discount_percent?: Maybe<Scalars['numeric']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  dimension?: Maybe<Scalars['numeric']['output']>
  discount_percent?: Maybe<Scalars['numeric']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  /** An array relationship */
  files: Array<Product_File>
  /** An aggregate relationship */
  files_aggregate: Product_File_Aggregate
  first_arrival_date?: Maybe<Scalars['date']['output']>
  gross_weight?: Maybe<Scalars['numeric']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  price_client?: Maybe<Scalars['numeric']['output']>
  price_client_allow_invoice_discount?: Maybe<Scalars['Boolean']['output']>
  price_client_allow_line_discount?: Maybe<Scalars['Boolean']['output']>
  price_discount?: Maybe<Scalars['numeric']['output']>
  price_end_series?: Maybe<Scalars['numeric']['output']>
  price_end_series_allow_invoice_discount?: Maybe<Scalars['Boolean']['output']>
  price_end_series_allow_line_discount?: Maybe<Scalars['Boolean']['output']>
  price_price_list?: Maybe<Scalars['numeric']['output']>
  price_price_list_allow_invoice_discount?: Maybe<Scalars['Boolean']['output']>
  price_price_list_allow_line_discount?: Maybe<Scalars['Boolean']['output']>
  price_promo?: Maybe<Scalars['numeric']['output']>
  price_public?: Maybe<Scalars['numeric']['output']>
  price_real?: Maybe<Scalars['numeric']['output']>
  price_real_field?: Maybe<Scalars['String']['output']>
  price_real_field_no_discount?: Maybe<Scalars['String']['output']>
  price_real_no_discount?: Maybe<Scalars['numeric']['output']>
  product_discount_group_code?: Maybe<Scalars['String']['output']>
  product_price_list_code?: Maybe<Scalars['String']['output']>
  promo?: Maybe<Scalars['Boolean']['output']>
  promo_discount?: Maybe<Scalars['numeric']['output']>
  promo_enable?: Maybe<Scalars['Boolean']['output']>
  promo_from?: Maybe<Scalars['date']['output']>
  promo_to?: Maybe<Scalars['date']['output']>
  rev?: Maybe<Scalars['String']['output']>
  stock?: Maybe<Scalars['Int']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** columns and relationships of "product_price_view" */
export type Product_Price_ViewFilesArgs = {
  distinct_on?: InputMaybe<Array<Product_File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_File_Order_By>>
  where?: InputMaybe<Product_File_Bool_Exp>
}

/** columns and relationships of "product_price_view" */
export type Product_Price_ViewFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_File_Order_By>>
  where?: InputMaybe<Product_File_Bool_Exp>
}

/** aggregated selection of "product_price_view" */
export type Product_Price_View_Aggregate = {
  __typename?: 'product_price_view_aggregate'
  aggregate?: Maybe<Product_Price_View_Aggregate_Fields>
  nodes: Array<Product_Price_View>
}

/** aggregate fields of "product_price_view" */
export type Product_Price_View_Aggregate_Fields = {
  __typename?: 'product_price_view_aggregate_fields'
  avg?: Maybe<Product_Price_View_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Product_Price_View_Max_Fields>
  min?: Maybe<Product_Price_View_Min_Fields>
  stddev?: Maybe<Product_Price_View_Stddev_Fields>
  stddev_pop?: Maybe<Product_Price_View_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Product_Price_View_Stddev_Samp_Fields>
  sum?: Maybe<Product_Price_View_Sum_Fields>
  var_pop?: Maybe<Product_Price_View_Var_Pop_Fields>
  var_samp?: Maybe<Product_Price_View_Var_Samp_Fields>
  variance?: Maybe<Product_Price_View_Variance_Fields>
}

/** aggregate fields of "product_price_view" */
export type Product_Price_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Price_View_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** aggregate avg on columns */
export type Product_Price_View_Avg_Fields = {
  __typename?: 'product_price_view_avg_fields'
  box_pieces?: Maybe<Scalars['Float']['output']>
  customer_discount_discount_percent?: Maybe<Scalars['Float']['output']>
  dimension?: Maybe<Scalars['Float']['output']>
  discount_percent?: Maybe<Scalars['Float']['output']>
  gross_weight?: Maybe<Scalars['Float']['output']>
  price_client?: Maybe<Scalars['Float']['output']>
  price_discount?: Maybe<Scalars['Float']['output']>
  price_end_series?: Maybe<Scalars['Float']['output']>
  price_price_list?: Maybe<Scalars['Float']['output']>
  price_promo?: Maybe<Scalars['Float']['output']>
  price_public?: Maybe<Scalars['Float']['output']>
  price_real?: Maybe<Scalars['Float']['output']>
  price_real_no_discount?: Maybe<Scalars['Float']['output']>
  promo_discount?: Maybe<Scalars['Float']['output']>
  stock?: Maybe<Scalars['Float']['output']>
}

/** Boolean expression to filter rows from the table "product_price_view". All fields are combined with a logical 'AND'. */
export type Product_Price_View_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Price_View_Bool_Exp>>
  _not?: InputMaybe<Product_Price_View_Bool_Exp>
  _or?: InputMaybe<Array<Product_Price_View_Bool_Exp>>
  allow_invoice_discount?: InputMaybe<Boolean_Comparison_Exp>
  allow_line_discount?: InputMaybe<Boolean_Comparison_Exp>
  box_pieces?: InputMaybe<Int_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customer_discount_discount_percent?: InputMaybe<Numeric_Comparison_Exp>
  customer_erp_id?: InputMaybe<String_Comparison_Exp>
  dimension?: InputMaybe<Numeric_Comparison_Exp>
  discount_percent?: InputMaybe<Numeric_Comparison_Exp>
  erp_id?: InputMaybe<String_Comparison_Exp>
  files?: InputMaybe<Product_File_Bool_Exp>
  files_aggregate?: InputMaybe<Product_File_Aggregate_Bool_Exp>
  first_arrival_date?: InputMaybe<Date_Comparison_Exp>
  gross_weight?: InputMaybe<Numeric_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  price_client?: InputMaybe<Numeric_Comparison_Exp>
  price_client_allow_invoice_discount?: InputMaybe<Boolean_Comparison_Exp>
  price_client_allow_line_discount?: InputMaybe<Boolean_Comparison_Exp>
  price_discount?: InputMaybe<Numeric_Comparison_Exp>
  price_end_series?: InputMaybe<Numeric_Comparison_Exp>
  price_end_series_allow_invoice_discount?: InputMaybe<Boolean_Comparison_Exp>
  price_end_series_allow_line_discount?: InputMaybe<Boolean_Comparison_Exp>
  price_price_list?: InputMaybe<Numeric_Comparison_Exp>
  price_price_list_allow_invoice_discount?: InputMaybe<Boolean_Comparison_Exp>
  price_price_list_allow_line_discount?: InputMaybe<Boolean_Comparison_Exp>
  price_promo?: InputMaybe<Numeric_Comparison_Exp>
  price_public?: InputMaybe<Numeric_Comparison_Exp>
  price_real?: InputMaybe<Numeric_Comparison_Exp>
  price_real_field?: InputMaybe<String_Comparison_Exp>
  price_real_field_no_discount?: InputMaybe<String_Comparison_Exp>
  price_real_no_discount?: InputMaybe<Numeric_Comparison_Exp>
  product_discount_group_code?: InputMaybe<String_Comparison_Exp>
  product_price_list_code?: InputMaybe<String_Comparison_Exp>
  promo?: InputMaybe<Boolean_Comparison_Exp>
  promo_discount?: InputMaybe<Numeric_Comparison_Exp>
  promo_enable?: InputMaybe<Boolean_Comparison_Exp>
  promo_from?: InputMaybe<Date_Comparison_Exp>
  promo_to?: InputMaybe<Date_Comparison_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  stock?: InputMaybe<Int_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** aggregate max on columns */
export type Product_Price_View_Max_Fields = {
  __typename?: 'product_price_view_max_fields'
  box_pieces?: Maybe<Scalars['Int']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_discount_discount_percent?: Maybe<Scalars['numeric']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  dimension?: Maybe<Scalars['numeric']['output']>
  discount_percent?: Maybe<Scalars['numeric']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  first_arrival_date?: Maybe<Scalars['date']['output']>
  gross_weight?: Maybe<Scalars['numeric']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  price_client?: Maybe<Scalars['numeric']['output']>
  price_discount?: Maybe<Scalars['numeric']['output']>
  price_end_series?: Maybe<Scalars['numeric']['output']>
  price_price_list?: Maybe<Scalars['numeric']['output']>
  price_promo?: Maybe<Scalars['numeric']['output']>
  price_public?: Maybe<Scalars['numeric']['output']>
  price_real?: Maybe<Scalars['numeric']['output']>
  price_real_field?: Maybe<Scalars['String']['output']>
  price_real_field_no_discount?: Maybe<Scalars['String']['output']>
  price_real_no_discount?: Maybe<Scalars['numeric']['output']>
  product_discount_group_code?: Maybe<Scalars['String']['output']>
  product_price_list_code?: Maybe<Scalars['String']['output']>
  promo_discount?: Maybe<Scalars['numeric']['output']>
  promo_from?: Maybe<Scalars['date']['output']>
  promo_to?: Maybe<Scalars['date']['output']>
  rev?: Maybe<Scalars['String']['output']>
  stock?: Maybe<Scalars['Int']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** aggregate min on columns */
export type Product_Price_View_Min_Fields = {
  __typename?: 'product_price_view_min_fields'
  box_pieces?: Maybe<Scalars['Int']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_discount_discount_percent?: Maybe<Scalars['numeric']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  dimension?: Maybe<Scalars['numeric']['output']>
  discount_percent?: Maybe<Scalars['numeric']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  first_arrival_date?: Maybe<Scalars['date']['output']>
  gross_weight?: Maybe<Scalars['numeric']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  price_client?: Maybe<Scalars['numeric']['output']>
  price_discount?: Maybe<Scalars['numeric']['output']>
  price_end_series?: Maybe<Scalars['numeric']['output']>
  price_price_list?: Maybe<Scalars['numeric']['output']>
  price_promo?: Maybe<Scalars['numeric']['output']>
  price_public?: Maybe<Scalars['numeric']['output']>
  price_real?: Maybe<Scalars['numeric']['output']>
  price_real_field?: Maybe<Scalars['String']['output']>
  price_real_field_no_discount?: Maybe<Scalars['String']['output']>
  price_real_no_discount?: Maybe<Scalars['numeric']['output']>
  product_discount_group_code?: Maybe<Scalars['String']['output']>
  product_price_list_code?: Maybe<Scalars['String']['output']>
  promo_discount?: Maybe<Scalars['numeric']['output']>
  promo_from?: Maybe<Scalars['date']['output']>
  promo_to?: Maybe<Scalars['date']['output']>
  rev?: Maybe<Scalars['String']['output']>
  stock?: Maybe<Scalars['Int']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** Ordering options when selecting data from "product_price_view". */
export type Product_Price_View_Order_By = {
  allow_invoice_discount?: InputMaybe<Order_By>
  allow_line_discount?: InputMaybe<Order_By>
  box_pieces?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customer_discount_discount_percent?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  dimension?: InputMaybe<Order_By>
  discount_percent?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  files_aggregate?: InputMaybe<Product_File_Aggregate_Order_By>
  first_arrival_date?: InputMaybe<Order_By>
  gross_weight?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  price_client?: InputMaybe<Order_By>
  price_client_allow_invoice_discount?: InputMaybe<Order_By>
  price_client_allow_line_discount?: InputMaybe<Order_By>
  price_discount?: InputMaybe<Order_By>
  price_end_series?: InputMaybe<Order_By>
  price_end_series_allow_invoice_discount?: InputMaybe<Order_By>
  price_end_series_allow_line_discount?: InputMaybe<Order_By>
  price_price_list?: InputMaybe<Order_By>
  price_price_list_allow_invoice_discount?: InputMaybe<Order_By>
  price_price_list_allow_line_discount?: InputMaybe<Order_By>
  price_promo?: InputMaybe<Order_By>
  price_public?: InputMaybe<Order_By>
  price_real?: InputMaybe<Order_By>
  price_real_field?: InputMaybe<Order_By>
  price_real_field_no_discount?: InputMaybe<Order_By>
  price_real_no_discount?: InputMaybe<Order_By>
  product_discount_group_code?: InputMaybe<Order_By>
  product_price_list_code?: InputMaybe<Order_By>
  promo?: InputMaybe<Order_By>
  promo_discount?: InputMaybe<Order_By>
  promo_enable?: InputMaybe<Order_By>
  promo_from?: InputMaybe<Order_By>
  promo_to?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  stock?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** select columns of table "product_price_view" */
export enum Product_Price_View_Select_Column {
  /** column name */
  AllowInvoiceDiscount = 'allow_invoice_discount',
  /** column name */
  AllowLineDiscount = 'allow_line_discount',
  /** column name */
  BoxPieces = 'box_pieces',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerDiscountDiscountPercent = 'customer_discount_discount_percent',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Dimension = 'dimension',
  /** column name */
  DiscountPercent = 'discount_percent',
  /** column name */
  ErpId = 'erp_id',
  /** column name */
  FirstArrivalDate = 'first_arrival_date',
  /** column name */
  GrossWeight = 'gross_weight',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PriceClient = 'price_client',
  /** column name */
  PriceClientAllowInvoiceDiscount = 'price_client_allow_invoice_discount',
  /** column name */
  PriceClientAllowLineDiscount = 'price_client_allow_line_discount',
  /** column name */
  PriceDiscount = 'price_discount',
  /** column name */
  PriceEndSeries = 'price_end_series',
  /** column name */
  PriceEndSeriesAllowInvoiceDiscount = 'price_end_series_allow_invoice_discount',
  /** column name */
  PriceEndSeriesAllowLineDiscount = 'price_end_series_allow_line_discount',
  /** column name */
  PricePriceList = 'price_price_list',
  /** column name */
  PricePriceListAllowInvoiceDiscount = 'price_price_list_allow_invoice_discount',
  /** column name */
  PricePriceListAllowLineDiscount = 'price_price_list_allow_line_discount',
  /** column name */
  PricePromo = 'price_promo',
  /** column name */
  PricePublic = 'price_public',
  /** column name */
  PriceReal = 'price_real',
  /** column name */
  PriceRealField = 'price_real_field',
  /** column name */
  PriceRealFieldNoDiscount = 'price_real_field_no_discount',
  /** column name */
  PriceRealNoDiscount = 'price_real_no_discount',
  /** column name */
  ProductDiscountGroupCode = 'product_discount_group_code',
  /** column name */
  ProductPriceListCode = 'product_price_list_code',
  /** column name */
  Promo = 'promo',
  /** column name */
  PromoDiscount = 'promo_discount',
  /** column name */
  PromoEnable = 'promo_enable',
  /** column name */
  PromoFrom = 'promo_from',
  /** column name */
  PromoTo = 'promo_to',
  /** column name */
  Rev = 'rev',
  /** column name */
  Stock = 'stock',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** aggregate stddev on columns */
export type Product_Price_View_Stddev_Fields = {
  __typename?: 'product_price_view_stddev_fields'
  box_pieces?: Maybe<Scalars['Float']['output']>
  customer_discount_discount_percent?: Maybe<Scalars['Float']['output']>
  dimension?: Maybe<Scalars['Float']['output']>
  discount_percent?: Maybe<Scalars['Float']['output']>
  gross_weight?: Maybe<Scalars['Float']['output']>
  price_client?: Maybe<Scalars['Float']['output']>
  price_discount?: Maybe<Scalars['Float']['output']>
  price_end_series?: Maybe<Scalars['Float']['output']>
  price_price_list?: Maybe<Scalars['Float']['output']>
  price_promo?: Maybe<Scalars['Float']['output']>
  price_public?: Maybe<Scalars['Float']['output']>
  price_real?: Maybe<Scalars['Float']['output']>
  price_real_no_discount?: Maybe<Scalars['Float']['output']>
  promo_discount?: Maybe<Scalars['Float']['output']>
  stock?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_pop on columns */
export type Product_Price_View_Stddev_Pop_Fields = {
  __typename?: 'product_price_view_stddev_pop_fields'
  box_pieces?: Maybe<Scalars['Float']['output']>
  customer_discount_discount_percent?: Maybe<Scalars['Float']['output']>
  dimension?: Maybe<Scalars['Float']['output']>
  discount_percent?: Maybe<Scalars['Float']['output']>
  gross_weight?: Maybe<Scalars['Float']['output']>
  price_client?: Maybe<Scalars['Float']['output']>
  price_discount?: Maybe<Scalars['Float']['output']>
  price_end_series?: Maybe<Scalars['Float']['output']>
  price_price_list?: Maybe<Scalars['Float']['output']>
  price_promo?: Maybe<Scalars['Float']['output']>
  price_public?: Maybe<Scalars['Float']['output']>
  price_real?: Maybe<Scalars['Float']['output']>
  price_real_no_discount?: Maybe<Scalars['Float']['output']>
  promo_discount?: Maybe<Scalars['Float']['output']>
  stock?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_samp on columns */
export type Product_Price_View_Stddev_Samp_Fields = {
  __typename?: 'product_price_view_stddev_samp_fields'
  box_pieces?: Maybe<Scalars['Float']['output']>
  customer_discount_discount_percent?: Maybe<Scalars['Float']['output']>
  dimension?: Maybe<Scalars['Float']['output']>
  discount_percent?: Maybe<Scalars['Float']['output']>
  gross_weight?: Maybe<Scalars['Float']['output']>
  price_client?: Maybe<Scalars['Float']['output']>
  price_discount?: Maybe<Scalars['Float']['output']>
  price_end_series?: Maybe<Scalars['Float']['output']>
  price_price_list?: Maybe<Scalars['Float']['output']>
  price_promo?: Maybe<Scalars['Float']['output']>
  price_public?: Maybe<Scalars['Float']['output']>
  price_real?: Maybe<Scalars['Float']['output']>
  price_real_no_discount?: Maybe<Scalars['Float']['output']>
  promo_discount?: Maybe<Scalars['Float']['output']>
  stock?: Maybe<Scalars['Float']['output']>
}

/** Streaming cursor of the table "product_price_view" */
export type Product_Price_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Price_View_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Product_Price_View_Stream_Cursor_Value_Input = {
  allow_invoice_discount?: InputMaybe<Scalars['Boolean']['input']>
  allow_line_discount?: InputMaybe<Scalars['Boolean']['input']>
  box_pieces?: InputMaybe<Scalars['Int']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_discount_discount_percent?: InputMaybe<Scalars['numeric']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  dimension?: InputMaybe<Scalars['numeric']['input']>
  discount_percent?: InputMaybe<Scalars['numeric']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  first_arrival_date?: InputMaybe<Scalars['date']['input']>
  gross_weight?: InputMaybe<Scalars['numeric']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  price_client?: InputMaybe<Scalars['numeric']['input']>
  price_client_allow_invoice_discount?: InputMaybe<Scalars['Boolean']['input']>
  price_client_allow_line_discount?: InputMaybe<Scalars['Boolean']['input']>
  price_discount?: InputMaybe<Scalars['numeric']['input']>
  price_end_series?: InputMaybe<Scalars['numeric']['input']>
  price_end_series_allow_invoice_discount?: InputMaybe<
    Scalars['Boolean']['input']
  >
  price_end_series_allow_line_discount?: InputMaybe<Scalars['Boolean']['input']>
  price_price_list?: InputMaybe<Scalars['numeric']['input']>
  price_price_list_allow_invoice_discount?: InputMaybe<
    Scalars['Boolean']['input']
  >
  price_price_list_allow_line_discount?: InputMaybe<Scalars['Boolean']['input']>
  price_promo?: InputMaybe<Scalars['numeric']['input']>
  price_public?: InputMaybe<Scalars['numeric']['input']>
  price_real?: InputMaybe<Scalars['numeric']['input']>
  price_real_field?: InputMaybe<Scalars['String']['input']>
  price_real_field_no_discount?: InputMaybe<Scalars['String']['input']>
  price_real_no_discount?: InputMaybe<Scalars['numeric']['input']>
  product_discount_group_code?: InputMaybe<Scalars['String']['input']>
  product_price_list_code?: InputMaybe<Scalars['String']['input']>
  promo?: InputMaybe<Scalars['Boolean']['input']>
  promo_discount?: InputMaybe<Scalars['numeric']['input']>
  promo_enable?: InputMaybe<Scalars['Boolean']['input']>
  promo_from?: InputMaybe<Scalars['date']['input']>
  promo_to?: InputMaybe<Scalars['date']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  stock?: InputMaybe<Scalars['Int']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate sum on columns */
export type Product_Price_View_Sum_Fields = {
  __typename?: 'product_price_view_sum_fields'
  box_pieces?: Maybe<Scalars['Int']['output']>
  customer_discount_discount_percent?: Maybe<Scalars['numeric']['output']>
  dimension?: Maybe<Scalars['numeric']['output']>
  discount_percent?: Maybe<Scalars['numeric']['output']>
  gross_weight?: Maybe<Scalars['numeric']['output']>
  price_client?: Maybe<Scalars['numeric']['output']>
  price_discount?: Maybe<Scalars['numeric']['output']>
  price_end_series?: Maybe<Scalars['numeric']['output']>
  price_price_list?: Maybe<Scalars['numeric']['output']>
  price_promo?: Maybe<Scalars['numeric']['output']>
  price_public?: Maybe<Scalars['numeric']['output']>
  price_real?: Maybe<Scalars['numeric']['output']>
  price_real_no_discount?: Maybe<Scalars['numeric']['output']>
  promo_discount?: Maybe<Scalars['numeric']['output']>
  stock?: Maybe<Scalars['Int']['output']>
}

/** aggregate var_pop on columns */
export type Product_Price_View_Var_Pop_Fields = {
  __typename?: 'product_price_view_var_pop_fields'
  box_pieces?: Maybe<Scalars['Float']['output']>
  customer_discount_discount_percent?: Maybe<Scalars['Float']['output']>
  dimension?: Maybe<Scalars['Float']['output']>
  discount_percent?: Maybe<Scalars['Float']['output']>
  gross_weight?: Maybe<Scalars['Float']['output']>
  price_client?: Maybe<Scalars['Float']['output']>
  price_discount?: Maybe<Scalars['Float']['output']>
  price_end_series?: Maybe<Scalars['Float']['output']>
  price_price_list?: Maybe<Scalars['Float']['output']>
  price_promo?: Maybe<Scalars['Float']['output']>
  price_public?: Maybe<Scalars['Float']['output']>
  price_real?: Maybe<Scalars['Float']['output']>
  price_real_no_discount?: Maybe<Scalars['Float']['output']>
  promo_discount?: Maybe<Scalars['Float']['output']>
  stock?: Maybe<Scalars['Float']['output']>
}

/** aggregate var_samp on columns */
export type Product_Price_View_Var_Samp_Fields = {
  __typename?: 'product_price_view_var_samp_fields'
  box_pieces?: Maybe<Scalars['Float']['output']>
  customer_discount_discount_percent?: Maybe<Scalars['Float']['output']>
  dimension?: Maybe<Scalars['Float']['output']>
  discount_percent?: Maybe<Scalars['Float']['output']>
  gross_weight?: Maybe<Scalars['Float']['output']>
  price_client?: Maybe<Scalars['Float']['output']>
  price_discount?: Maybe<Scalars['Float']['output']>
  price_end_series?: Maybe<Scalars['Float']['output']>
  price_price_list?: Maybe<Scalars['Float']['output']>
  price_promo?: Maybe<Scalars['Float']['output']>
  price_public?: Maybe<Scalars['Float']['output']>
  price_real?: Maybe<Scalars['Float']['output']>
  price_real_no_discount?: Maybe<Scalars['Float']['output']>
  promo_discount?: Maybe<Scalars['Float']['output']>
  stock?: Maybe<Scalars['Float']['output']>
}

/** aggregate variance on columns */
export type Product_Price_View_Variance_Fields = {
  __typename?: 'product_price_view_variance_fields'
  box_pieces?: Maybe<Scalars['Float']['output']>
  customer_discount_discount_percent?: Maybe<Scalars['Float']['output']>
  dimension?: Maybe<Scalars['Float']['output']>
  discount_percent?: Maybe<Scalars['Float']['output']>
  gross_weight?: Maybe<Scalars['Float']['output']>
  price_client?: Maybe<Scalars['Float']['output']>
  price_discount?: Maybe<Scalars['Float']['output']>
  price_end_series?: Maybe<Scalars['Float']['output']>
  price_price_list?: Maybe<Scalars['Float']['output']>
  price_promo?: Maybe<Scalars['Float']['output']>
  price_public?: Maybe<Scalars['Float']['output']>
  price_real?: Maybe<Scalars['Float']['output']>
  price_real_no_discount?: Maybe<Scalars['Float']['output']>
  promo_discount?: Maybe<Scalars['Float']['output']>
  stock?: Maybe<Scalars['Float']['output']>
}

/** columns and relationships of "product_replacement" */
export type Product_Replacement = {
  __typename?: 'product_replacement'
  absolute?: Maybe<Scalars['Boolean']['output']>
  created_at: Scalars['timestamptz']['output']
  id: Scalars['uuid']['output']
  origin?: Maybe<Scalars['String']['output']>
  /** An object relationship */
  product?: Maybe<Product>
  product_erp_id: Scalars['String']['output']
  /** An object relationship */
  replacement?: Maybe<Replacement>
  replacement_erp_id: Scalars['String']['output']
  scale_x?: Maybe<Scalars['numeric']['output']>
  scale_y?: Maybe<Scalars['numeric']['output']>
  updated_at: Scalars['timestamptz']['output']
  x: Scalars['Int']['output']
  y: Scalars['Int']['output']
}

/** aggregated selection of "product_replacement" */
export type Product_Replacement_Aggregate = {
  __typename?: 'product_replacement_aggregate'
  aggregate?: Maybe<Product_Replacement_Aggregate_Fields>
  nodes: Array<Product_Replacement>
}

export type Product_Replacement_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Product_Replacement_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<Product_Replacement_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<Product_Replacement_Aggregate_Bool_Exp_Count>
}

export type Product_Replacement_Aggregate_Bool_Exp_Bool_And = {
  arguments: Product_Replacement_Select_Column_Product_Replacement_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Product_Replacement_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Product_Replacement_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Product_Replacement_Select_Column_Product_Replacement_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Product_Replacement_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Product_Replacement_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Replacement_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Product_Replacement_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "product_replacement" */
export type Product_Replacement_Aggregate_Fields = {
  __typename?: 'product_replacement_aggregate_fields'
  avg?: Maybe<Product_Replacement_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Product_Replacement_Max_Fields>
  min?: Maybe<Product_Replacement_Min_Fields>
  stddev?: Maybe<Product_Replacement_Stddev_Fields>
  stddev_pop?: Maybe<Product_Replacement_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Product_Replacement_Stddev_Samp_Fields>
  sum?: Maybe<Product_Replacement_Sum_Fields>
  var_pop?: Maybe<Product_Replacement_Var_Pop_Fields>
  var_samp?: Maybe<Product_Replacement_Var_Samp_Fields>
  variance?: Maybe<Product_Replacement_Variance_Fields>
}

/** aggregate fields of "product_replacement" */
export type Product_Replacement_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Replacement_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "product_replacement" */
export type Product_Replacement_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Replacement_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Product_Replacement_Max_Order_By>
  min?: InputMaybe<Product_Replacement_Min_Order_By>
  stddev?: InputMaybe<Product_Replacement_Stddev_Order_By>
  stddev_pop?: InputMaybe<Product_Replacement_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Product_Replacement_Stddev_Samp_Order_By>
  sum?: InputMaybe<Product_Replacement_Sum_Order_By>
  var_pop?: InputMaybe<Product_Replacement_Var_Pop_Order_By>
  var_samp?: InputMaybe<Product_Replacement_Var_Samp_Order_By>
  variance?: InputMaybe<Product_Replacement_Variance_Order_By>
}

/** input type for inserting array relation for remote table "product_replacement" */
export type Product_Replacement_Arr_Rel_Insert_Input = {
  data: Array<Product_Replacement_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Replacement_On_Conflict>
}

/** aggregate avg on columns */
export type Product_Replacement_Avg_Fields = {
  __typename?: 'product_replacement_avg_fields'
  scale_x?: Maybe<Scalars['Float']['output']>
  scale_y?: Maybe<Scalars['Float']['output']>
  x?: Maybe<Scalars['Float']['output']>
  y?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "product_replacement" */
export type Product_Replacement_Avg_Order_By = {
  scale_x?: InputMaybe<Order_By>
  scale_y?: InputMaybe<Order_By>
  x?: InputMaybe<Order_By>
  y?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "product_replacement". All fields are combined with a logical 'AND'. */
export type Product_Replacement_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Replacement_Bool_Exp>>
  _not?: InputMaybe<Product_Replacement_Bool_Exp>
  _or?: InputMaybe<Array<Product_Replacement_Bool_Exp>>
  absolute?: InputMaybe<Boolean_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  origin?: InputMaybe<String_Comparison_Exp>
  product?: InputMaybe<Product_Bool_Exp>
  product_erp_id?: InputMaybe<String_Comparison_Exp>
  replacement?: InputMaybe<Replacement_Bool_Exp>
  replacement_erp_id?: InputMaybe<String_Comparison_Exp>
  scale_x?: InputMaybe<Numeric_Comparison_Exp>
  scale_y?: InputMaybe<Numeric_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  x?: InputMaybe<Int_Comparison_Exp>
  y?: InputMaybe<Int_Comparison_Exp>
}

/** unique or primary key constraints on table "product_replacement" */
export enum Product_Replacement_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductReplacementPkey = 'product_replacement_pkey',
  /** unique or primary key constraint on columns "product_erp_id", "replacement_erp_id" */
  ProductReplacementProductErpIdReplacementErpIdKey = 'product_replacement_product_erp_id_replacement_erp_id_key',
}

/** input type for incrementing numeric columns in table "product_replacement" */
export type Product_Replacement_Inc_Input = {
  scale_x?: InputMaybe<Scalars['numeric']['input']>
  scale_y?: InputMaybe<Scalars['numeric']['input']>
  x?: InputMaybe<Scalars['Int']['input']>
  y?: InputMaybe<Scalars['Int']['input']>
}

/** input type for inserting data into table "product_replacement" */
export type Product_Replacement_Insert_Input = {
  absolute?: InputMaybe<Scalars['Boolean']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  origin?: InputMaybe<Scalars['String']['input']>
  product?: InputMaybe<Product_Obj_Rel_Insert_Input>
  product_erp_id?: InputMaybe<Scalars['String']['input']>
  replacement?: InputMaybe<Replacement_Obj_Rel_Insert_Input>
  replacement_erp_id?: InputMaybe<Scalars['String']['input']>
  scale_x?: InputMaybe<Scalars['numeric']['input']>
  scale_y?: InputMaybe<Scalars['numeric']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  x?: InputMaybe<Scalars['Int']['input']>
  y?: InputMaybe<Scalars['Int']['input']>
}

/** columns and relationships of "product_replacement_map" */
export type Product_Replacement_Map = {
  __typename?: 'product_replacement_map'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  has_image?: Maybe<Scalars['Boolean']['output']>
  height?: Maybe<Scalars['Int']['output']>
  id: Scalars['uuid']['output']
  /** An object relationship */
  product?: Maybe<Product>
  product_erp_id: Scalars['String']['output']
  status?: Maybe<Scalars['Boolean']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  width?: Maybe<Scalars['Int']['output']>
}

/** aggregated selection of "product_replacement_map" */
export type Product_Replacement_Map_Aggregate = {
  __typename?: 'product_replacement_map_aggregate'
  aggregate?: Maybe<Product_Replacement_Map_Aggregate_Fields>
  nodes: Array<Product_Replacement_Map>
}

/** aggregate fields of "product_replacement_map" */
export type Product_Replacement_Map_Aggregate_Fields = {
  __typename?: 'product_replacement_map_aggregate_fields'
  avg?: Maybe<Product_Replacement_Map_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Product_Replacement_Map_Max_Fields>
  min?: Maybe<Product_Replacement_Map_Min_Fields>
  stddev?: Maybe<Product_Replacement_Map_Stddev_Fields>
  stddev_pop?: Maybe<Product_Replacement_Map_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Product_Replacement_Map_Stddev_Samp_Fields>
  sum?: Maybe<Product_Replacement_Map_Sum_Fields>
  var_pop?: Maybe<Product_Replacement_Map_Var_Pop_Fields>
  var_samp?: Maybe<Product_Replacement_Map_Var_Samp_Fields>
  variance?: Maybe<Product_Replacement_Map_Variance_Fields>
}

/** aggregate fields of "product_replacement_map" */
export type Product_Replacement_Map_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Replacement_Map_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** aggregate avg on columns */
export type Product_Replacement_Map_Avg_Fields = {
  __typename?: 'product_replacement_map_avg_fields'
  height?: Maybe<Scalars['Float']['output']>
  width?: Maybe<Scalars['Float']['output']>
}

/** Boolean expression to filter rows from the table "product_replacement_map". All fields are combined with a logical 'AND'. */
export type Product_Replacement_Map_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Replacement_Map_Bool_Exp>>
  _not?: InputMaybe<Product_Replacement_Map_Bool_Exp>
  _or?: InputMaybe<Array<Product_Replacement_Map_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  has_image?: InputMaybe<Boolean_Comparison_Exp>
  height?: InputMaybe<Int_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  product?: InputMaybe<Product_Bool_Exp>
  product_erp_id?: InputMaybe<String_Comparison_Exp>
  status?: InputMaybe<Boolean_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  width?: InputMaybe<Int_Comparison_Exp>
}

/** unique or primary key constraints on table "product_replacement_map" */
export enum Product_Replacement_Map_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductReplacementMapPkey = 'product_replacement_map_pkey',
  /** unique or primary key constraint on columns "product_erp_id" */
  ProductReplacementMapProductErpIdKey = 'product_replacement_map_product_erp_id_key',
}

/** input type for incrementing numeric columns in table "product_replacement_map" */
export type Product_Replacement_Map_Inc_Input = {
  height?: InputMaybe<Scalars['Int']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

/** input type for inserting data into table "product_replacement_map" */
export type Product_Replacement_Map_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  has_image?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  product?: InputMaybe<Product_Obj_Rel_Insert_Input>
  product_erp_id?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['Boolean']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

/** aggregate max on columns */
export type Product_Replacement_Map_Max_Fields = {
  __typename?: 'product_replacement_map_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  height?: Maybe<Scalars['Int']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  product_erp_id?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  width?: Maybe<Scalars['Int']['output']>
}

/** aggregate min on columns */
export type Product_Replacement_Map_Min_Fields = {
  __typename?: 'product_replacement_map_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  height?: Maybe<Scalars['Int']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  product_erp_id?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  width?: Maybe<Scalars['Int']['output']>
}

/** response of any mutation on the table "product_replacement_map" */
export type Product_Replacement_Map_Mutation_Response = {
  __typename?: 'product_replacement_map_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Product_Replacement_Map>
}

/** input type for inserting object relation for remote table "product_replacement_map" */
export type Product_Replacement_Map_Obj_Rel_Insert_Input = {
  data: Product_Replacement_Map_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Replacement_Map_On_Conflict>
}

/** on_conflict condition type for table "product_replacement_map" */
export type Product_Replacement_Map_On_Conflict = {
  constraint: Product_Replacement_Map_Constraint
  update_columns?: Array<Product_Replacement_Map_Update_Column>
  where?: InputMaybe<Product_Replacement_Map_Bool_Exp>
}

/** Ordering options when selecting data from "product_replacement_map". */
export type Product_Replacement_Map_Order_By = {
  created_at?: InputMaybe<Order_By>
  has_image?: InputMaybe<Order_By>
  height?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  product?: InputMaybe<Product_Order_By>
  product_erp_id?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  width?: InputMaybe<Order_By>
}

/** primary key columns input for table: product_replacement_map */
export type Product_Replacement_Map_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "product_replacement_map" */
export enum Product_Replacement_Map_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasImage = 'has_image',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  ProductErpId = 'product_erp_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Width = 'width',
}

/** input type for updating data in table "product_replacement_map" */
export type Product_Replacement_Map_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  has_image?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  product_erp_id?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['Boolean']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

/** aggregate stddev on columns */
export type Product_Replacement_Map_Stddev_Fields = {
  __typename?: 'product_replacement_map_stddev_fields'
  height?: Maybe<Scalars['Float']['output']>
  width?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_pop on columns */
export type Product_Replacement_Map_Stddev_Pop_Fields = {
  __typename?: 'product_replacement_map_stddev_pop_fields'
  height?: Maybe<Scalars['Float']['output']>
  width?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_samp on columns */
export type Product_Replacement_Map_Stddev_Samp_Fields = {
  __typename?: 'product_replacement_map_stddev_samp_fields'
  height?: Maybe<Scalars['Float']['output']>
  width?: Maybe<Scalars['Float']['output']>
}

/** Streaming cursor of the table "product_replacement_map" */
export type Product_Replacement_Map_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Replacement_Map_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Product_Replacement_Map_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  has_image?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  product_erp_id?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['Boolean']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

/** aggregate sum on columns */
export type Product_Replacement_Map_Sum_Fields = {
  __typename?: 'product_replacement_map_sum_fields'
  height?: Maybe<Scalars['Int']['output']>
  width?: Maybe<Scalars['Int']['output']>
}

/** update columns of table "product_replacement_map" */
export enum Product_Replacement_Map_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasImage = 'has_image',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  ProductErpId = 'product_erp_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Width = 'width',
}

export type Product_Replacement_Map_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_Replacement_Map_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Replacement_Map_Set_Input>
  /** filter the rows which have to be updated */
  where: Product_Replacement_Map_Bool_Exp
}

/** aggregate var_pop on columns */
export type Product_Replacement_Map_Var_Pop_Fields = {
  __typename?: 'product_replacement_map_var_pop_fields'
  height?: Maybe<Scalars['Float']['output']>
  width?: Maybe<Scalars['Float']['output']>
}

/** aggregate var_samp on columns */
export type Product_Replacement_Map_Var_Samp_Fields = {
  __typename?: 'product_replacement_map_var_samp_fields'
  height?: Maybe<Scalars['Float']['output']>
  width?: Maybe<Scalars['Float']['output']>
}

/** aggregate variance on columns */
export type Product_Replacement_Map_Variance_Fields = {
  __typename?: 'product_replacement_map_variance_fields'
  height?: Maybe<Scalars['Float']['output']>
  width?: Maybe<Scalars['Float']['output']>
}

/** aggregate max on columns */
export type Product_Replacement_Max_Fields = {
  __typename?: 'product_replacement_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  origin?: Maybe<Scalars['String']['output']>
  product_erp_id?: Maybe<Scalars['String']['output']>
  replacement_erp_id?: Maybe<Scalars['String']['output']>
  scale_x?: Maybe<Scalars['numeric']['output']>
  scale_y?: Maybe<Scalars['numeric']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  x?: Maybe<Scalars['Int']['output']>
  y?: Maybe<Scalars['Int']['output']>
}

/** order by max() on columns of table "product_replacement" */
export type Product_Replacement_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  origin?: InputMaybe<Order_By>
  product_erp_id?: InputMaybe<Order_By>
  replacement_erp_id?: InputMaybe<Order_By>
  scale_x?: InputMaybe<Order_By>
  scale_y?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  x?: InputMaybe<Order_By>
  y?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Product_Replacement_Min_Fields = {
  __typename?: 'product_replacement_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  origin?: Maybe<Scalars['String']['output']>
  product_erp_id?: Maybe<Scalars['String']['output']>
  replacement_erp_id?: Maybe<Scalars['String']['output']>
  scale_x?: Maybe<Scalars['numeric']['output']>
  scale_y?: Maybe<Scalars['numeric']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  x?: Maybe<Scalars['Int']['output']>
  y?: Maybe<Scalars['Int']['output']>
}

/** order by min() on columns of table "product_replacement" */
export type Product_Replacement_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  origin?: InputMaybe<Order_By>
  product_erp_id?: InputMaybe<Order_By>
  replacement_erp_id?: InputMaybe<Order_By>
  scale_x?: InputMaybe<Order_By>
  scale_y?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  x?: InputMaybe<Order_By>
  y?: InputMaybe<Order_By>
}

/** response of any mutation on the table "product_replacement" */
export type Product_Replacement_Mutation_Response = {
  __typename?: 'product_replacement_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Product_Replacement>
}

/** on_conflict condition type for table "product_replacement" */
export type Product_Replacement_On_Conflict = {
  constraint: Product_Replacement_Constraint
  update_columns?: Array<Product_Replacement_Update_Column>
  where?: InputMaybe<Product_Replacement_Bool_Exp>
}

/** Ordering options when selecting data from "product_replacement". */
export type Product_Replacement_Order_By = {
  absolute?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  origin?: InputMaybe<Order_By>
  product?: InputMaybe<Product_Order_By>
  product_erp_id?: InputMaybe<Order_By>
  replacement?: InputMaybe<Replacement_Order_By>
  replacement_erp_id?: InputMaybe<Order_By>
  scale_x?: InputMaybe<Order_By>
  scale_y?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  x?: InputMaybe<Order_By>
  y?: InputMaybe<Order_By>
}

/** primary key columns input for table: product_replacement */
export type Product_Replacement_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "product_replacement" */
export enum Product_Replacement_Select_Column {
  /** column name */
  Absolute = 'absolute',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Origin = 'origin',
  /** column name */
  ProductErpId = 'product_erp_id',
  /** column name */
  ReplacementErpId = 'replacement_erp_id',
  /** column name */
  ScaleX = 'scale_x',
  /** column name */
  ScaleY = 'scale_y',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y',
}

/** select "product_replacement_aggregate_bool_exp_bool_and_arguments_columns" columns of table "product_replacement" */
export enum Product_Replacement_Select_Column_Product_Replacement_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Absolute = 'absolute',
}

/** select "product_replacement_aggregate_bool_exp_bool_or_arguments_columns" columns of table "product_replacement" */
export enum Product_Replacement_Select_Column_Product_Replacement_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Absolute = 'absolute',
}

/** input type for updating data in table "product_replacement" */
export type Product_Replacement_Set_Input = {
  absolute?: InputMaybe<Scalars['Boolean']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  origin?: InputMaybe<Scalars['String']['input']>
  product_erp_id?: InputMaybe<Scalars['String']['input']>
  replacement_erp_id?: InputMaybe<Scalars['String']['input']>
  scale_x?: InputMaybe<Scalars['numeric']['input']>
  scale_y?: InputMaybe<Scalars['numeric']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  x?: InputMaybe<Scalars['Int']['input']>
  y?: InputMaybe<Scalars['Int']['input']>
}

/** aggregate stddev on columns */
export type Product_Replacement_Stddev_Fields = {
  __typename?: 'product_replacement_stddev_fields'
  scale_x?: Maybe<Scalars['Float']['output']>
  scale_y?: Maybe<Scalars['Float']['output']>
  x?: Maybe<Scalars['Float']['output']>
  y?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "product_replacement" */
export type Product_Replacement_Stddev_Order_By = {
  scale_x?: InputMaybe<Order_By>
  scale_y?: InputMaybe<Order_By>
  x?: InputMaybe<Order_By>
  y?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Product_Replacement_Stddev_Pop_Fields = {
  __typename?: 'product_replacement_stddev_pop_fields'
  scale_x?: Maybe<Scalars['Float']['output']>
  scale_y?: Maybe<Scalars['Float']['output']>
  x?: Maybe<Scalars['Float']['output']>
  y?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "product_replacement" */
export type Product_Replacement_Stddev_Pop_Order_By = {
  scale_x?: InputMaybe<Order_By>
  scale_y?: InputMaybe<Order_By>
  x?: InputMaybe<Order_By>
  y?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Product_Replacement_Stddev_Samp_Fields = {
  __typename?: 'product_replacement_stddev_samp_fields'
  scale_x?: Maybe<Scalars['Float']['output']>
  scale_y?: Maybe<Scalars['Float']['output']>
  x?: Maybe<Scalars['Float']['output']>
  y?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "product_replacement" */
export type Product_Replacement_Stddev_Samp_Order_By = {
  scale_x?: InputMaybe<Order_By>
  scale_y?: InputMaybe<Order_By>
  x?: InputMaybe<Order_By>
  y?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "product_replacement" */
export type Product_Replacement_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Replacement_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Product_Replacement_Stream_Cursor_Value_Input = {
  absolute?: InputMaybe<Scalars['Boolean']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  origin?: InputMaybe<Scalars['String']['input']>
  product_erp_id?: InputMaybe<Scalars['String']['input']>
  replacement_erp_id?: InputMaybe<Scalars['String']['input']>
  scale_x?: InputMaybe<Scalars['numeric']['input']>
  scale_y?: InputMaybe<Scalars['numeric']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  x?: InputMaybe<Scalars['Int']['input']>
  y?: InputMaybe<Scalars['Int']['input']>
}

/** aggregate sum on columns */
export type Product_Replacement_Sum_Fields = {
  __typename?: 'product_replacement_sum_fields'
  scale_x?: Maybe<Scalars['numeric']['output']>
  scale_y?: Maybe<Scalars['numeric']['output']>
  x?: Maybe<Scalars['Int']['output']>
  y?: Maybe<Scalars['Int']['output']>
}

/** order by sum() on columns of table "product_replacement" */
export type Product_Replacement_Sum_Order_By = {
  scale_x?: InputMaybe<Order_By>
  scale_y?: InputMaybe<Order_By>
  x?: InputMaybe<Order_By>
  y?: InputMaybe<Order_By>
}

/** update columns of table "product_replacement" */
export enum Product_Replacement_Update_Column {
  /** column name */
  Absolute = 'absolute',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Origin = 'origin',
  /** column name */
  ProductErpId = 'product_erp_id',
  /** column name */
  ReplacementErpId = 'replacement_erp_id',
  /** column name */
  ScaleX = 'scale_x',
  /** column name */
  ScaleY = 'scale_y',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y',
}

export type Product_Replacement_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_Replacement_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Replacement_Set_Input>
  /** filter the rows which have to be updated */
  where: Product_Replacement_Bool_Exp
}

/** aggregate var_pop on columns */
export type Product_Replacement_Var_Pop_Fields = {
  __typename?: 'product_replacement_var_pop_fields'
  scale_x?: Maybe<Scalars['Float']['output']>
  scale_y?: Maybe<Scalars['Float']['output']>
  x?: Maybe<Scalars['Float']['output']>
  y?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "product_replacement" */
export type Product_Replacement_Var_Pop_Order_By = {
  scale_x?: InputMaybe<Order_By>
  scale_y?: InputMaybe<Order_By>
  x?: InputMaybe<Order_By>
  y?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Product_Replacement_Var_Samp_Fields = {
  __typename?: 'product_replacement_var_samp_fields'
  scale_x?: Maybe<Scalars['Float']['output']>
  scale_y?: Maybe<Scalars['Float']['output']>
  x?: Maybe<Scalars['Float']['output']>
  y?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "product_replacement" */
export type Product_Replacement_Var_Samp_Order_By = {
  scale_x?: InputMaybe<Order_By>
  scale_y?: InputMaybe<Order_By>
  x?: InputMaybe<Order_By>
  y?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Product_Replacement_Variance_Fields = {
  __typename?: 'product_replacement_variance_fields'
  scale_x?: Maybe<Scalars['Float']['output']>
  scale_y?: Maybe<Scalars['Float']['output']>
  x?: Maybe<Scalars['Float']['output']>
  y?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "product_replacement" */
export type Product_Replacement_Variance_Order_By = {
  scale_x?: InputMaybe<Order_By>
  scale_y?: InputMaybe<Order_By>
  x?: InputMaybe<Order_By>
  y?: InputMaybe<Order_By>
}

/** select columns of table "product" */
export enum Product_Select_Column {
  /** column name */
  BoxPieces = 'box_pieces',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Dimension = 'dimension',
  /** column name */
  ErpId = 'erp_id',
  /** column name */
  FirstArrivalDate = 'first_arrival_date',
  /** column name */
  GrossWeight = 'gross_weight',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProductDiscountGroupCode = 'product_discount_group_code',
  /** column name */
  Promo = 'promo',
  /** column name */
  Rev = 'rev',
  /** column name */
  Stock = 'stock',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "product_aggregate_bool_exp_bool_and_arguments_columns" columns of table "product" */
export enum Product_Select_Column_Product_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Promo = 'promo',
}

/** select "product_aggregate_bool_exp_bool_or_arguments_columns" columns of table "product" */
export enum Product_Select_Column_Product_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Promo = 'promo',
}

/** input type for updating data in table "product" */
export type Product_Set_Input = {
  box_pieces?: InputMaybe<Scalars['Int']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  dimension?: InputMaybe<Scalars['numeric']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  first_arrival_date?: InputMaybe<Scalars['date']['input']>
  gross_weight?: InputMaybe<Scalars['numeric']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  product_discount_group_code?: InputMaybe<Scalars['String']['input']>
  promo?: InputMaybe<Scalars['Boolean']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  stock?: InputMaybe<Scalars['Int']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate stddev on columns */
export type Product_Stddev_Fields = {
  __typename?: 'product_stddev_fields'
  box_pieces?: Maybe<Scalars['Float']['output']>
  dimension?: Maybe<Scalars['Float']['output']>
  gross_weight?: Maybe<Scalars['Float']['output']>
  stock?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "product" */
export type Product_Stddev_Order_By = {
  box_pieces?: InputMaybe<Order_By>
  dimension?: InputMaybe<Order_By>
  gross_weight?: InputMaybe<Order_By>
  stock?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Product_Stddev_Pop_Fields = {
  __typename?: 'product_stddev_pop_fields'
  box_pieces?: Maybe<Scalars['Float']['output']>
  dimension?: Maybe<Scalars['Float']['output']>
  gross_weight?: Maybe<Scalars['Float']['output']>
  stock?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "product" */
export type Product_Stddev_Pop_Order_By = {
  box_pieces?: InputMaybe<Order_By>
  dimension?: InputMaybe<Order_By>
  gross_weight?: InputMaybe<Order_By>
  stock?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Product_Stddev_Samp_Fields = {
  __typename?: 'product_stddev_samp_fields'
  box_pieces?: Maybe<Scalars['Float']['output']>
  dimension?: Maybe<Scalars['Float']['output']>
  gross_weight?: Maybe<Scalars['Float']['output']>
  stock?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "product" */
export type Product_Stddev_Samp_Order_By = {
  box_pieces?: InputMaybe<Order_By>
  dimension?: InputMaybe<Order_By>
  gross_weight?: InputMaybe<Order_By>
  stock?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "product" */
export type Product_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Product_Stream_Cursor_Value_Input = {
  box_pieces?: InputMaybe<Scalars['Int']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  dimension?: InputMaybe<Scalars['numeric']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  first_arrival_date?: InputMaybe<Scalars['date']['input']>
  gross_weight?: InputMaybe<Scalars['numeric']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  product_discount_group_code?: InputMaybe<Scalars['String']['input']>
  promo?: InputMaybe<Scalars['Boolean']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  stock?: InputMaybe<Scalars['Int']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate sum on columns */
export type Product_Sum_Fields = {
  __typename?: 'product_sum_fields'
  box_pieces?: Maybe<Scalars['Int']['output']>
  dimension?: Maybe<Scalars['numeric']['output']>
  gross_weight?: Maybe<Scalars['numeric']['output']>
  stock?: Maybe<Scalars['Int']['output']>
}

/** order by sum() on columns of table "product" */
export type Product_Sum_Order_By = {
  box_pieces?: InputMaybe<Order_By>
  dimension?: InputMaybe<Order_By>
  gross_weight?: InputMaybe<Order_By>
  stock?: InputMaybe<Order_By>
}

/** update columns of table "product" */
export enum Product_Update_Column {
  /** column name */
  BoxPieces = 'box_pieces',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Dimension = 'dimension',
  /** column name */
  ErpId = 'erp_id',
  /** column name */
  FirstArrivalDate = 'first_arrival_date',
  /** column name */
  GrossWeight = 'gross_weight',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProductDiscountGroupCode = 'product_discount_group_code',
  /** column name */
  Promo = 'promo',
  /** column name */
  Rev = 'rev',
  /** column name */
  Stock = 'stock',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Product_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Set_Input>
  /** filter the rows which have to be updated */
  where: Product_Bool_Exp
}

/** aggregate var_pop on columns */
export type Product_Var_Pop_Fields = {
  __typename?: 'product_var_pop_fields'
  box_pieces?: Maybe<Scalars['Float']['output']>
  dimension?: Maybe<Scalars['Float']['output']>
  gross_weight?: Maybe<Scalars['Float']['output']>
  stock?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "product" */
export type Product_Var_Pop_Order_By = {
  box_pieces?: InputMaybe<Order_By>
  dimension?: InputMaybe<Order_By>
  gross_weight?: InputMaybe<Order_By>
  stock?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Product_Var_Samp_Fields = {
  __typename?: 'product_var_samp_fields'
  box_pieces?: Maybe<Scalars['Float']['output']>
  dimension?: Maybe<Scalars['Float']['output']>
  gross_weight?: Maybe<Scalars['Float']['output']>
  stock?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "product" */
export type Product_Var_Samp_Order_By = {
  box_pieces?: InputMaybe<Order_By>
  dimension?: InputMaybe<Order_By>
  gross_weight?: InputMaybe<Order_By>
  stock?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Product_Variance_Fields = {
  __typename?: 'product_variance_fields'
  box_pieces?: Maybe<Scalars['Float']['output']>
  dimension?: Maybe<Scalars['Float']['output']>
  gross_weight?: Maybe<Scalars['Float']['output']>
  stock?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "product" */
export type Product_Variance_Order_By = {
  box_pieces?: InputMaybe<Order_By>
  dimension?: InputMaybe<Order_By>
  gross_weight?: InputMaybe<Order_By>
  stock?: InputMaybe<Order_By>
}

export type Query_Root = {
  __typename?: 'query_root'
  /** fetch data from the table: "agent" */
  agent: Array<Agent>
  /** fetch aggregated fields from the table: "agent" */
  agent_aggregate: Agent_Aggregate
  /** An array relationship */
  agent_bonus: Array<Agent_Bonus>
  /** An aggregate relationship */
  agent_bonus_aggregate: Agent_Bonus_Aggregate
  /** fetch data from the table: "agent_bonus" using primary key columns */
  agent_bonus_by_pk?: Maybe<Agent_Bonus>
  /** fetch data from the table: "agent" using primary key columns */
  agent_by_pk?: Maybe<Agent>
  api_customer_statistics: CustomerStatisticOutput
  api_file_data: FileDataOutput
  api_file_report_data: FileDataOutput
  api_mailup_client_emails: ApiMailupClientEmailsOutput
  api_mailup_client_list: ApiMailupClientListOutput
  api_mailup_client_recipient: ApiMailupClientRecipientOutput
  api_ping: Scalars['String']['output']
  api_sales_manager_amount: ApiSalesManagerAmountOutput
  api_sales_manager_collect_totals: ApiSalesManagerCollectTotalsOutput
  api_test_error: Scalars['String']['output']
  /** fetch data from the table: "business_sector" */
  business_sector: Array<Business_Sector>
  /** fetch aggregated fields from the table: "business_sector" */
  business_sector_aggregate: Business_Sector_Aggregate
  /** fetch data from the table: "business_sector" using primary key columns */
  business_sector_by_pk?: Maybe<Business_Sector>
  /** fetch data from the table: "cart" */
  cart: Array<Cart>
  /** fetch aggregated fields from the table: "cart" */
  cart_aggregate: Cart_Aggregate
  /** fetch data from the table: "cart" using primary key columns */
  cart_by_pk?: Maybe<Cart>
  /** fetch data from the table: "cart_item" */
  cart_item: Array<Cart_Item>
  /** fetch aggregated fields from the table: "cart_item" */
  cart_item_aggregate: Cart_Item_Aggregate
  /** fetch data from the table: "cart_item" using primary key columns */
  cart_item_by_pk?: Maybe<Cart_Item>
  /** fetch data from the table: "cart_replacement" */
  cart_replacement: Array<Cart_Replacement>
  /** fetch aggregated fields from the table: "cart_replacement" */
  cart_replacement_aggregate: Cart_Replacement_Aggregate
  /** fetch data from the table: "cart_replacement" using primary key columns */
  cart_replacement_by_pk?: Maybe<Cart_Replacement>
  /** fetch data from the table: "communication" */
  communication: Array<Communication>
  /** fetch aggregated fields from the table: "communication" */
  communication_aggregate: Communication_Aggregate
  /** fetch data from the table: "communication" using primary key columns */
  communication_by_pk?: Maybe<Communication>
  /** fetch data from the table: "communication_changelog" */
  communication_changelog: Array<Communication_Changelog>
  /** fetch aggregated fields from the table: "communication_changelog" */
  communication_changelog_aggregate: Communication_Changelog_Aggregate
  /** fetch data from the table: "communication_changelog" using primary key columns */
  communication_changelog_by_pk?: Maybe<Communication_Changelog>
  /** fetch data from the table: "communication_code" */
  communication_code: Array<Communication_Code>
  /** fetch aggregated fields from the table: "communication_code" */
  communication_code_aggregate: Communication_Code_Aggregate
  /** fetch data from the table: "communication_code" using primary key columns */
  communication_code_by_pk?: Maybe<Communication_Code>
  /** fetch data from the table: "communication_external_mail" */
  communication_external_mail: Array<Communication_External_Mail>
  /** fetch aggregated fields from the table: "communication_external_mail" */
  communication_external_mail_aggregate: Communication_External_Mail_Aggregate
  /** fetch data from the table: "communication_external_mail" using primary key columns */
  communication_external_mail_by_pk?: Maybe<Communication_External_Mail>
  /** fetch data from the table: "communication_list" */
  communication_list: Array<Communication_List>
  /** fetch aggregated fields from the table: "communication_list" */
  communication_list_aggregate: Communication_List_Aggregate
  /** fetch data from the table: "communication_list" using primary key columns */
  communication_list_by_pk?: Maybe<Communication_List>
  /** execute function "communication_list_missing_customers" which returns "customer" */
  communication_list_missing_customers: Array<Customer>
  /** execute function "communication_list_missing_customers" and query aggregates on result of table type "customer" */
  communication_list_missing_customers_aggregate: Customer_Aggregate
  /** execute function "communication_list_missing_customers_by_code" which returns "customer" */
  communication_list_missing_customers_by_code: Array<Customer>
  /** execute function "communication_list_missing_customers_by_code" and query aggregates on result of table type "customer" */
  communication_list_missing_customers_by_code_aggregate: Customer_Aggregate
  /** execute function "communication_list_orphans" which returns "communication_list" */
  communication_list_orphans: Array<Communication_List>
  /** execute function "communication_list_orphans" and query aggregates on result of table type "communication_list" */
  communication_list_orphans_aggregate: Communication_List_Aggregate
  /** fetch data from the table: "communication_settings" */
  communication_settings: Array<Communication_Settings>
  /** fetch aggregated fields from the table: "communication_settings" */
  communication_settings_aggregate: Communication_Settings_Aggregate
  /** fetch data from the table: "communication_settings" using primary key columns */
  communication_settings_by_pk?: Maybe<Communication_Settings>
  /** execute function "communication_settings_missing_customers" which returns "customer" */
  communication_settings_missing_customers: Array<Customer>
  /** execute function "communication_settings_missing_customers" and query aggregates on result of table type "customer" */
  communication_settings_missing_customers_aggregate: Customer_Aggregate
  /** fetch data from the table: "country_region" */
  country_region: Array<Country_Region>
  /** fetch aggregated fields from the table: "country_region" */
  country_region_aggregate: Country_Region_Aggregate
  /** fetch data from the table: "country_region" using primary key columns */
  country_region_by_pk?: Maybe<Country_Region>
  /** fetch data from the table: "county" */
  county: Array<County>
  /** fetch aggregated fields from the table: "county" */
  county_aggregate: County_Aggregate
  /** fetch data from the table: "county" using primary key columns */
  county_by_pk?: Maybe<County>
  /** fetch data from the table: "credit_memo" */
  credit_memo: Array<Credit_Memo>
  /** fetch aggregated fields from the table: "credit_memo" */
  credit_memo_aggregate: Credit_Memo_Aggregate
  /** fetch data from the table: "credit_memo" using primary key columns */
  credit_memo_by_pk?: Maybe<Credit_Memo>
  /** fetch data from the table: "cron" */
  cron: Array<Cron>
  /** fetch aggregated fields from the table: "cron" */
  cron_aggregate: Cron_Aggregate
  /** fetch data from the table: "cron" using primary key columns */
  cron_by_pk?: Maybe<Cron>
  /** fetch data from the table: "customer" */
  customer: Array<Customer>
  /** fetch data from the table: "customer_additional_data" */
  customer_additional_data: Array<Customer_Additional_Data>
  /** fetch aggregated fields from the table: "customer_additional_data" */
  customer_additional_data_aggregate: Customer_Additional_Data_Aggregate
  /** fetch data from the table: "customer_additional_data" using primary key columns */
  customer_additional_data_by_pk?: Maybe<Customer_Additional_Data>
  /** fetch data from the table: "customer_address" */
  customer_address: Array<Customer_Address>
  /** fetch aggregated fields from the table: "customer_address" */
  customer_address_aggregate: Customer_Address_Aggregate
  /** fetch data from the table: "customer_address" using primary key columns */
  customer_address_by_pk?: Maybe<Customer_Address>
  /** fetch data from the table: "customer_address_dropshipping" */
  customer_address_dropshipping: Array<Customer_Address_Dropshipping>
  /** fetch aggregated fields from the table: "customer_address_dropshipping" */
  customer_address_dropshipping_aggregate: Customer_Address_Dropshipping_Aggregate
  /** fetch data from the table: "customer_address_dropshipping" using primary key columns */
  customer_address_dropshipping_by_pk?: Maybe<Customer_Address_Dropshipping>
  /** fetch aggregated fields from the table: "customer" */
  customer_aggregate: Customer_Aggregate
  /** fetch data from the table: "customer" using primary key columns */
  customer_by_pk?: Maybe<Customer>
  /** fetch data from the table: "customer_discount" */
  customer_discount: Array<Customer_Discount>
  /** fetch aggregated fields from the table: "customer_discount" */
  customer_discount_aggregate: Customer_Discount_Aggregate
  /** fetch data from the table: "customer_discount" using primary key columns */
  customer_discount_by_pk?: Maybe<Customer_Discount>
  /** fetch data from the table: "download" */
  download: Array<Download>
  /** fetch aggregated fields from the table: "download" */
  download_aggregate: Download_Aggregate
  /** fetch data from the table: "download" using primary key columns */
  download_by_pk?: Maybe<Download>
  /** fetch data from the table: "exchange_rate" */
  exchange_rate: Array<Exchange_Rate>
  /** fetch aggregated fields from the table: "exchange_rate" */
  exchange_rate_aggregate: Exchange_Rate_Aggregate
  /** fetch data from the table: "exchange_rate" using primary key columns */
  exchange_rate_by_pk?: Maybe<Exchange_Rate>
  /** fetch data from the table: "file" */
  file: Array<File>
  /** fetch aggregated fields from the table: "file" */
  file_aggregate: File_Aggregate
  /** fetch data from the table: "file" using primary key columns */
  file_by_pk?: Maybe<File>
  /** fetch data from the table: "invoice" */
  invoice: Array<Invoice>
  /** fetch aggregated fields from the table: "invoice" */
  invoice_aggregate: Invoice_Aggregate
  /** fetch data from the table: "invoice" using primary key columns */
  invoice_by_pk?: Maybe<Invoice>
  /** fetch data from the table: "invoice_discount" */
  invoice_discount: Array<Invoice_Discount>
  /** fetch aggregated fields from the table: "invoice_discount" */
  invoice_discount_aggregate: Invoice_Discount_Aggregate
  /** fetch data from the table: "invoice_discount" using primary key columns */
  invoice_discount_by_pk?: Maybe<Invoice_Discount>
  /** fetch data from the table: "invoice_overdue" */
  invoice_overdue: Array<Invoice_Overdue>
  /** fetch aggregated fields from the table: "invoice_overdue" */
  invoice_overdue_aggregate: Invoice_Overdue_Aggregate
  /** fetch data from the table: "invoice_overdue" using primary key columns */
  invoice_overdue_by_pk?: Maybe<Invoice_Overdue>
  /** fetch data from the table: "invoice_view" */
  invoice_view: Array<Invoice_View>
  /** fetch aggregated fields from the table: "invoice_view" */
  invoice_view_aggregate: Invoice_View_Aggregate
  /** fetch data from the table: "mail" */
  mail: Array<Mail>
  /** fetch aggregated fields from the table: "mail" */
  mail_aggregate: Mail_Aggregate
  /** fetch data from the table: "mail" using primary key columns */
  mail_by_pk?: Maybe<Mail>
  /** fetch data from the table: "order" */
  order: Array<Order>
  /** fetch aggregated fields from the table: "order" */
  order_aggregate: Order_Aggregate
  /** fetch data from the table: "order_by_email" */
  order_by_email: Array<Order_By_Email>
  /** fetch aggregated fields from the table: "order_by_email" */
  order_by_email_aggregate: Order_By_Email_Aggregate
  /** fetch data from the table: "order_by_email" using primary key columns */
  order_by_email_by_pk?: Maybe<Order_By_Email>
  /** fetch data from the table: "order" using primary key columns */
  order_by_pk?: Maybe<Order>
  /** fetch data from the table: "order_confirmed" */
  order_confirmed: Array<Order_Confirmed>
  /** fetch aggregated fields from the table: "order_confirmed" */
  order_confirmed_aggregate: Order_Confirmed_Aggregate
  /** fetch data from the table: "order_confirmed" using primary key columns */
  order_confirmed_by_pk?: Maybe<Order_Confirmed>
  /** fetch data from the table: "order_invoice" */
  order_invoice: Array<Order_Invoice>
  /** fetch aggregated fields from the table: "order_invoice" */
  order_invoice_aggregate: Order_Invoice_Aggregate
  /** fetch data from the table: "order_invoice" using primary key columns */
  order_invoice_by_pk?: Maybe<Order_Invoice>
  /** fetch data from the table: "order_invoice_item" */
  order_invoice_item: Array<Order_Invoice_Item>
  /** fetch aggregated fields from the table: "order_invoice_item" */
  order_invoice_item_aggregate: Order_Invoice_Item_Aggregate
  /** fetch data from the table: "order_invoice_item" using primary key columns */
  order_invoice_item_by_pk?: Maybe<Order_Invoice_Item>
  /** fetch data from the table: "order_item_unshipped" */
  order_item_unshipped: Array<Order_Item_Unshipped>
  /** fetch aggregated fields from the table: "order_item_unshipped" */
  order_item_unshipped_aggregate: Order_Item_Unshipped_Aggregate
  /** fetch data from the table: "order_item_unshipped" using primary key columns */
  order_item_unshipped_by_pk?: Maybe<Order_Item_Unshipped>
  /** fetch data from the table: "order_product" */
  order_product: Array<Order_Product>
  /** fetch aggregated fields from the table: "order_product" */
  order_product_aggregate: Order_Product_Aggregate
  /** fetch data from the table: "order_product" using primary key columns */
  order_product_by_pk?: Maybe<Order_Product>
  /** fetch data from the table: "order_product_item" */
  order_product_item: Array<Order_Product_Item>
  /** fetch aggregated fields from the table: "order_product_item" */
  order_product_item_aggregate: Order_Product_Item_Aggregate
  /** fetch data from the table: "order_product_item" using primary key columns */
  order_product_item_by_pk?: Maybe<Order_Product_Item>
  /** fetch data from the table: "order_product_replacement" */
  order_product_replacement: Array<Order_Product_Replacement>
  /** fetch aggregated fields from the table: "order_product_replacement" */
  order_product_replacement_aggregate: Order_Product_Replacement_Aggregate
  /** fetch data from the table: "order_product_replacement" using primary key columns */
  order_product_replacement_by_pk?: Maybe<Order_Product_Replacement>
  /** fetch data from the table: "order_transaction" */
  order_transaction: Array<Order_Transaction>
  /** fetch aggregated fields from the table: "order_transaction" */
  order_transaction_aggregate: Order_Transaction_Aggregate
  /** fetch data from the table: "order_transaction" using primary key columns */
  order_transaction_by_pk?: Maybe<Order_Transaction>
  /** fetch data from the table: "payment_condition" */
  payment_condition: Array<Payment_Condition>
  /** fetch aggregated fields from the table: "payment_condition" */
  payment_condition_aggregate: Payment_Condition_Aggregate
  /** fetch data from the table: "payment_condition" using primary key columns */
  payment_condition_by_pk?: Maybe<Payment_Condition>
  /** fetch data from the table: "payment_method" */
  payment_method: Array<Payment_Method>
  /** fetch aggregated fields from the table: "payment_method" */
  payment_method_aggregate: Payment_Method_Aggregate
  /** fetch data from the table: "payment_method" using primary key columns */
  payment_method_by_pk?: Maybe<Payment_Method>
  /** fetch data from the table: "post_code" */
  post_code: Array<Post_Code>
  /** fetch aggregated fields from the table: "post_code" */
  post_code_aggregate: Post_Code_Aggregate
  /** fetch data from the table: "post_code" using primary key columns */
  post_code_by_pk?: Maybe<Post_Code>
  /** fetch data from the table: "product" */
  product: Array<Product>
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>
  /** fetch data from the table: "product_discount_group" */
  product_discount_group: Array<Product_Discount_Group>
  /** fetch aggregated fields from the table: "product_discount_group" */
  product_discount_group_aggregate: Product_Discount_Group_Aggregate
  /** fetch data from the table: "product_discount_group" using primary key columns */
  product_discount_group_by_pk?: Maybe<Product_Discount_Group>
  /** fetch data from the table: "product_file" */
  product_file: Array<Product_File>
  /** fetch aggregated fields from the table: "product_file" */
  product_file_aggregate: Product_File_Aggregate
  /** fetch data from the table: "product_file" using primary key columns */
  product_file_by_pk?: Maybe<Product_File>
  /** fetch data from the table: "product_price" */
  product_price: Array<Product_Price>
  /** fetch aggregated fields from the table: "product_price" */
  product_price_aggregate: Product_Price_Aggregate
  /** fetch data from the table: "product_price" using primary key columns */
  product_price_by_pk?: Maybe<Product_Price>
  /** fetch data from the table: "product_price_list" */
  product_price_list: Array<Product_Price_List>
  /** fetch aggregated fields from the table: "product_price_list" */
  product_price_list_aggregate: Product_Price_List_Aggregate
  /** fetch data from the table: "product_price_list" using primary key columns */
  product_price_list_by_pk?: Maybe<Product_Price_List>
  /** fetch data from the table: "product_price_view" */
  product_price_view: Array<Product_Price_View>
  /** fetch aggregated fields from the table: "product_price_view" */
  product_price_view_aggregate: Product_Price_View_Aggregate
  /** fetch data from the table: "product_replacement" */
  product_replacement: Array<Product_Replacement>
  /** fetch aggregated fields from the table: "product_replacement" */
  product_replacement_aggregate: Product_Replacement_Aggregate
  /** fetch data from the table: "product_replacement" using primary key columns */
  product_replacement_by_pk?: Maybe<Product_Replacement>
  /** fetch data from the table: "product_replacement_map" */
  product_replacement_map: Array<Product_Replacement_Map>
  /** fetch aggregated fields from the table: "product_replacement_map" */
  product_replacement_map_aggregate: Product_Replacement_Map_Aggregate
  /** fetch data from the table: "product_replacement_map" using primary key columns */
  product_replacement_map_by_pk?: Maybe<Product_Replacement_Map>
  /** fetch data from the table: "replacement" */
  replacement: Array<Replacement>
  /** fetch aggregated fields from the table: "replacement" */
  replacement_aggregate: Replacement_Aggregate
  /** fetch data from the table: "replacement" using primary key columns */
  replacement_by_pk?: Maybe<Replacement>
  /** fetch data from the table: "report" */
  report: Array<Report>
  /** fetch aggregated fields from the table: "report" */
  report_aggregate: Report_Aggregate
  /** fetch data from the table: "report" using primary key columns */
  report_by_pk?: Maybe<Report>
  /** fetch data from the table: "role" */
  role: Array<Role>
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>
  /** fetch data from the table: "setting" */
  setting: Array<Setting>
  /** fetch aggregated fields from the table: "setting" */
  setting_aggregate: Setting_Aggregate
  /** fetch data from the table: "setting" using primary key columns */
  setting_by_pk?: Maybe<Setting>
  /** fetch data from the table: "shipment_document" */
  shipment_document: Array<Shipment_Document>
  /** fetch aggregated fields from the table: "shipment_document" */
  shipment_document_aggregate: Shipment_Document_Aggregate
  /** fetch data from the table: "shipment_document" using primary key columns */
  shipment_document_by_pk?: Maybe<Shipment_Document>
  /** fetch data from the table: "shipping_method" */
  shipping_method: Array<Shipping_Method>
  /** fetch aggregated fields from the table: "shipping_method" */
  shipping_method_aggregate: Shipping_Method_Aggregate
  /** fetch data from the table: "shipping_method" using primary key columns */
  shipping_method_by_pk?: Maybe<Shipping_Method>
  /** fetch data from the table: "shipping_rate" */
  shipping_rate: Array<Shipping_Rate>
  /** fetch aggregated fields from the table: "shipping_rate" */
  shipping_rate_aggregate: Shipping_Rate_Aggregate
  /** fetch data from the table: "shipping_rate" using primary key columns */
  shipping_rate_by_pk?: Maybe<Shipping_Rate>
  /** fetch data from the table: "subcustomer" */
  subcustomer: Array<Subcustomer>
  /** fetch aggregated fields from the table: "subcustomer" */
  subcustomer_aggregate: Subcustomer_Aggregate
  /** fetch data from the table: "subcustomer" using primary key columns */
  subcustomer_by_pk?: Maybe<Subcustomer>
  /** fetch data from the table: "territory" */
  territory: Array<Territory>
  /** fetch aggregated fields from the table: "territory" */
  territory_aggregate: Territory_Aggregate
  /** fetch data from the table: "territory" using primary key columns */
  territory_by_pk?: Maybe<Territory>
  /** fetch data from the table: "transaction" */
  transaction: Array<Transaction>
  /** fetch aggregated fields from the table: "transaction" */
  transaction_aggregate: Transaction_Aggregate
  /** fetch data from the table: "transaction" using primary key columns */
  transaction_by_pk?: Maybe<Transaction>
  /** fetch data from the table: "user" */
  user: Array<User>
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>
}

export type Query_RootAgentArgs = {
  distinct_on?: InputMaybe<Array<Agent_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Agent_Order_By>>
  where?: InputMaybe<Agent_Bool_Exp>
}

export type Query_RootAgent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agent_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Agent_Order_By>>
  where?: InputMaybe<Agent_Bool_Exp>
}

export type Query_RootAgent_BonusArgs = {
  distinct_on?: InputMaybe<Array<Agent_Bonus_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Agent_Bonus_Order_By>>
  where?: InputMaybe<Agent_Bonus_Bool_Exp>
}

export type Query_RootAgent_Bonus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agent_Bonus_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Agent_Bonus_Order_By>>
  where?: InputMaybe<Agent_Bonus_Bool_Exp>
}

export type Query_RootAgent_Bonus_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootAgent_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootApi_File_DataArgs = {
  docType: DocTypes
  fileName: Scalars['String']['input']
  year: Scalars['Int']['input']
}

export type Query_RootApi_File_Report_DataArgs = {
  fileName: Scalars['String']['input']
}

export type Query_RootApi_Mailup_Client_EmailsArgs = {
  idList: Scalars['Int']['input']
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
}

export type Query_RootApi_Mailup_Client_ListArgs = {
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
}

export type Query_RootApi_Mailup_Client_RecipientArgs = {
  Email: Scalars['String']['input']
}

export type Query_RootApi_Sales_Manager_AmountArgs = {
  data: ApiSalesManagerAmountDataInput
}

export type Query_RootApi_Sales_Manager_Collect_TotalsArgs = {
  id?: InputMaybe<Scalars['String']['input']>
}

export type Query_RootBusiness_SectorArgs = {
  distinct_on?: InputMaybe<Array<Business_Sector_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Business_Sector_Order_By>>
  where?: InputMaybe<Business_Sector_Bool_Exp>
}

export type Query_RootBusiness_Sector_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Business_Sector_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Business_Sector_Order_By>>
  where?: InputMaybe<Business_Sector_Bool_Exp>
}

export type Query_RootBusiness_Sector_By_PkArgs = {
  code: Scalars['String']['input']
}

export type Query_RootCartArgs = {
  distinct_on?: InputMaybe<Array<Cart_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Cart_Order_By>>
  where?: InputMaybe<Cart_Bool_Exp>
}

export type Query_RootCart_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cart_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Cart_Order_By>>
  where?: InputMaybe<Cart_Bool_Exp>
}

export type Query_RootCart_By_PkArgs = {
  id: Scalars['bigint']['input']
}

export type Query_RootCart_ItemArgs = {
  distinct_on?: InputMaybe<Array<Cart_Item_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Cart_Item_Order_By>>
  where?: InputMaybe<Cart_Item_Bool_Exp>
}

export type Query_RootCart_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cart_Item_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Cart_Item_Order_By>>
  where?: InputMaybe<Cart_Item_Bool_Exp>
}

export type Query_RootCart_Item_By_PkArgs = {
  id: Scalars['bigint']['input']
}

export type Query_RootCart_ReplacementArgs = {
  distinct_on?: InputMaybe<Array<Cart_Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Cart_Replacement_Order_By>>
  where?: InputMaybe<Cart_Replacement_Bool_Exp>
}

export type Query_RootCart_Replacement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cart_Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Cart_Replacement_Order_By>>
  where?: InputMaybe<Cart_Replacement_Bool_Exp>
}

export type Query_RootCart_Replacement_By_PkArgs = {
  id: Scalars['bigint']['input']
}

export type Query_RootCommunicationArgs = {
  distinct_on?: InputMaybe<Array<Communication_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_Order_By>>
  where?: InputMaybe<Communication_Bool_Exp>
}

export type Query_RootCommunication_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_Order_By>>
  where?: InputMaybe<Communication_Bool_Exp>
}

export type Query_RootCommunication_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootCommunication_ChangelogArgs = {
  distinct_on?: InputMaybe<Array<Communication_Changelog_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_Changelog_Order_By>>
  where?: InputMaybe<Communication_Changelog_Bool_Exp>
}

export type Query_RootCommunication_Changelog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_Changelog_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_Changelog_Order_By>>
  where?: InputMaybe<Communication_Changelog_Bool_Exp>
}

export type Query_RootCommunication_Changelog_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootCommunication_CodeArgs = {
  distinct_on?: InputMaybe<Array<Communication_Code_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_Code_Order_By>>
  where?: InputMaybe<Communication_Code_Bool_Exp>
}

export type Query_RootCommunication_Code_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_Code_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_Code_Order_By>>
  where?: InputMaybe<Communication_Code_Bool_Exp>
}

export type Query_RootCommunication_Code_By_PkArgs = {
  code: Scalars['String']['input']
}

export type Query_RootCommunication_External_MailArgs = {
  distinct_on?: InputMaybe<Array<Communication_External_Mail_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_External_Mail_Order_By>>
  where?: InputMaybe<Communication_External_Mail_Bool_Exp>
}

export type Query_RootCommunication_External_Mail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_External_Mail_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_External_Mail_Order_By>>
  where?: InputMaybe<Communication_External_Mail_Bool_Exp>
}

export type Query_RootCommunication_External_Mail_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootCommunication_ListArgs = {
  distinct_on?: InputMaybe<Array<Communication_List_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_List_Order_By>>
  where?: InputMaybe<Communication_List_Bool_Exp>
}

export type Query_RootCommunication_List_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_List_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_List_Order_By>>
  where?: InputMaybe<Communication_List_Bool_Exp>
}

export type Query_RootCommunication_List_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootCommunication_List_Missing_CustomersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

export type Query_RootCommunication_List_Missing_Customers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

export type Query_RootCommunication_List_Missing_Customers_By_CodeArgs = {
  args: Communication_List_Missing_Customers_By_Code_Args
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

export type Query_RootCommunication_List_Missing_Customers_By_Code_AggregateArgs =
  {
    args: Communication_List_Missing_Customers_By_Code_Args
    distinct_on?: InputMaybe<Array<Customer_Select_Column>>
    limit?: InputMaybe<Scalars['Int']['input']>
    offset?: InputMaybe<Scalars['Int']['input']>
    order_by?: InputMaybe<Array<Customer_Order_By>>
    where?: InputMaybe<Customer_Bool_Exp>
  }

export type Query_RootCommunication_List_OrphansArgs = {
  distinct_on?: InputMaybe<Array<Communication_List_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_List_Order_By>>
  where?: InputMaybe<Communication_List_Bool_Exp>
}

export type Query_RootCommunication_List_Orphans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_List_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_List_Order_By>>
  where?: InputMaybe<Communication_List_Bool_Exp>
}

export type Query_RootCommunication_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Communication_Settings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_Settings_Order_By>>
  where?: InputMaybe<Communication_Settings_Bool_Exp>
}

export type Query_RootCommunication_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_Settings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_Settings_Order_By>>
  where?: InputMaybe<Communication_Settings_Bool_Exp>
}

export type Query_RootCommunication_Settings_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootCommunication_Settings_Missing_CustomersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

export type Query_RootCommunication_Settings_Missing_Customers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

export type Query_RootCountry_RegionArgs = {
  distinct_on?: InputMaybe<Array<Country_Region_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Country_Region_Order_By>>
  where?: InputMaybe<Country_Region_Bool_Exp>
}

export type Query_RootCountry_Region_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Country_Region_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Country_Region_Order_By>>
  where?: InputMaybe<Country_Region_Bool_Exp>
}

export type Query_RootCountry_Region_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootCountyArgs = {
  distinct_on?: InputMaybe<Array<County_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<County_Order_By>>
  where?: InputMaybe<County_Bool_Exp>
}

export type Query_RootCounty_AggregateArgs = {
  distinct_on?: InputMaybe<Array<County_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<County_Order_By>>
  where?: InputMaybe<County_Bool_Exp>
}

export type Query_RootCounty_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootCredit_MemoArgs = {
  distinct_on?: InputMaybe<Array<Credit_Memo_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Credit_Memo_Order_By>>
  where?: InputMaybe<Credit_Memo_Bool_Exp>
}

export type Query_RootCredit_Memo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Memo_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Credit_Memo_Order_By>>
  where?: InputMaybe<Credit_Memo_Bool_Exp>
}

export type Query_RootCredit_Memo_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootCronArgs = {
  distinct_on?: InputMaybe<Array<Cron_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Cron_Order_By>>
  where?: InputMaybe<Cron_Bool_Exp>
}

export type Query_RootCron_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cron_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Cron_Order_By>>
  where?: InputMaybe<Cron_Bool_Exp>
}

export type Query_RootCron_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootCustomerArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

export type Query_RootCustomer_Additional_DataArgs = {
  distinct_on?: InputMaybe<Array<Customer_Additional_Data_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Additional_Data_Order_By>>
  where?: InputMaybe<Customer_Additional_Data_Bool_Exp>
}

export type Query_RootCustomer_Additional_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Additional_Data_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Additional_Data_Order_By>>
  where?: InputMaybe<Customer_Additional_Data_Bool_Exp>
}

export type Query_RootCustomer_Additional_Data_By_PkArgs = {
  customer_erp_id: Scalars['String']['input']
}

export type Query_RootCustomer_AddressArgs = {
  distinct_on?: InputMaybe<Array<Customer_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Address_Order_By>>
  where?: InputMaybe<Customer_Address_Bool_Exp>
}

export type Query_RootCustomer_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Address_Order_By>>
  where?: InputMaybe<Customer_Address_Bool_Exp>
}

export type Query_RootCustomer_Address_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootCustomer_Address_DropshippingArgs = {
  distinct_on?: InputMaybe<Array<Customer_Address_Dropshipping_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Address_Dropshipping_Order_By>>
  where?: InputMaybe<Customer_Address_Dropshipping_Bool_Exp>
}

export type Query_RootCustomer_Address_Dropshipping_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Address_Dropshipping_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Address_Dropshipping_Order_By>>
  where?: InputMaybe<Customer_Address_Dropshipping_Bool_Exp>
}

export type Query_RootCustomer_Address_Dropshipping_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootCustomer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

export type Query_RootCustomer_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootCustomer_DiscountArgs = {
  distinct_on?: InputMaybe<Array<Customer_Discount_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Discount_Order_By>>
  where?: InputMaybe<Customer_Discount_Bool_Exp>
}

export type Query_RootCustomer_Discount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Discount_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Discount_Order_By>>
  where?: InputMaybe<Customer_Discount_Bool_Exp>
}

export type Query_RootCustomer_Discount_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootDownloadArgs = {
  distinct_on?: InputMaybe<Array<Download_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Download_Order_By>>
  where?: InputMaybe<Download_Bool_Exp>
}

export type Query_RootDownload_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Download_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Download_Order_By>>
  where?: InputMaybe<Download_Bool_Exp>
}

export type Query_RootDownload_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootExchange_RateArgs = {
  distinct_on?: InputMaybe<Array<Exchange_Rate_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Exchange_Rate_Order_By>>
  where?: InputMaybe<Exchange_Rate_Bool_Exp>
}

export type Query_RootExchange_Rate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exchange_Rate_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Exchange_Rate_Order_By>>
  where?: InputMaybe<Exchange_Rate_Bool_Exp>
}

export type Query_RootExchange_Rate_By_PkArgs = {
  currency: Scalars['String']['input']
}

export type Query_RootFileArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<File_Order_By>>
  where?: InputMaybe<File_Bool_Exp>
}

export type Query_RootFile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<File_Order_By>>
  where?: InputMaybe<File_Bool_Exp>
}

export type Query_RootFile_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootInvoiceArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Invoice_Order_By>>
  where?: InputMaybe<Invoice_Bool_Exp>
}

export type Query_RootInvoice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Invoice_Order_By>>
  where?: InputMaybe<Invoice_Bool_Exp>
}

export type Query_RootInvoice_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootInvoice_DiscountArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Discount_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Invoice_Discount_Order_By>>
  where?: InputMaybe<Invoice_Discount_Bool_Exp>
}

export type Query_RootInvoice_Discount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Discount_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Invoice_Discount_Order_By>>
  where?: InputMaybe<Invoice_Discount_Bool_Exp>
}

export type Query_RootInvoice_Discount_By_PkArgs = {
  code: Scalars['String']['input']
}

export type Query_RootInvoice_OverdueArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Overdue_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Invoice_Overdue_Order_By>>
  where?: InputMaybe<Invoice_Overdue_Bool_Exp>
}

export type Query_RootInvoice_Overdue_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Overdue_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Invoice_Overdue_Order_By>>
  where?: InputMaybe<Invoice_Overdue_Bool_Exp>
}

export type Query_RootInvoice_Overdue_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootInvoice_ViewArgs = {
  distinct_on?: InputMaybe<Array<Invoice_View_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Invoice_View_Order_By>>
  where?: InputMaybe<Invoice_View_Bool_Exp>
}

export type Query_RootInvoice_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_View_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Invoice_View_Order_By>>
  where?: InputMaybe<Invoice_View_Bool_Exp>
}

export type Query_RootMailArgs = {
  distinct_on?: InputMaybe<Array<Mail_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Mail_Order_By>>
  where?: InputMaybe<Mail_Bool_Exp>
}

export type Query_RootMail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Mail_Order_By>>
  where?: InputMaybe<Mail_Bool_Exp>
}

export type Query_RootMail_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootOrderArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Order_By>>
  where?: InputMaybe<Order_Bool_Exp>
}

export type Query_RootOrder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Order_By>>
  where?: InputMaybe<Order_Bool_Exp>
}

export type Query_RootOrder_By_EmailArgs = {
  distinct_on?: InputMaybe<Array<Order_By_Email_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_By_Email_Order_By>>
  where?: InputMaybe<Order_By_Email_Bool_Exp>
}

export type Query_RootOrder_By_Email_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_By_Email_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_By_Email_Order_By>>
  where?: InputMaybe<Order_By_Email_Bool_Exp>
}

export type Query_RootOrder_By_Email_By_PkArgs = {
  id: Scalars['Int']['input']
}

export type Query_RootOrder_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootOrder_ConfirmedArgs = {
  distinct_on?: InputMaybe<Array<Order_Confirmed_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Confirmed_Order_By>>
  where?: InputMaybe<Order_Confirmed_Bool_Exp>
}

export type Query_RootOrder_Confirmed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Confirmed_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Confirmed_Order_By>>
  where?: InputMaybe<Order_Confirmed_Bool_Exp>
}

export type Query_RootOrder_Confirmed_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootOrder_InvoiceArgs = {
  distinct_on?: InputMaybe<Array<Order_Invoice_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Invoice_Order_By>>
  where?: InputMaybe<Order_Invoice_Bool_Exp>
}

export type Query_RootOrder_Invoice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Invoice_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Invoice_Order_By>>
  where?: InputMaybe<Order_Invoice_Bool_Exp>
}

export type Query_RootOrder_Invoice_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootOrder_Invoice_ItemArgs = {
  distinct_on?: InputMaybe<Array<Order_Invoice_Item_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Invoice_Item_Order_By>>
  where?: InputMaybe<Order_Invoice_Item_Bool_Exp>
}

export type Query_RootOrder_Invoice_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Invoice_Item_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Invoice_Item_Order_By>>
  where?: InputMaybe<Order_Invoice_Item_Bool_Exp>
}

export type Query_RootOrder_Invoice_Item_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootOrder_Item_UnshippedArgs = {
  distinct_on?: InputMaybe<Array<Order_Item_Unshipped_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Item_Unshipped_Order_By>>
  where?: InputMaybe<Order_Item_Unshipped_Bool_Exp>
}

export type Query_RootOrder_Item_Unshipped_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Item_Unshipped_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Item_Unshipped_Order_By>>
  where?: InputMaybe<Order_Item_Unshipped_Bool_Exp>
}

export type Query_RootOrder_Item_Unshipped_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootOrder_ProductArgs = {
  distinct_on?: InputMaybe<Array<Order_Product_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Product_Order_By>>
  where?: InputMaybe<Order_Product_Bool_Exp>
}

export type Query_RootOrder_Product_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Product_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Product_Order_By>>
  where?: InputMaybe<Order_Product_Bool_Exp>
}

export type Query_RootOrder_Product_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootOrder_Product_ItemArgs = {
  distinct_on?: InputMaybe<Array<Order_Product_Item_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Product_Item_Order_By>>
  where?: InputMaybe<Order_Product_Item_Bool_Exp>
}

export type Query_RootOrder_Product_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Product_Item_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Product_Item_Order_By>>
  where?: InputMaybe<Order_Product_Item_Bool_Exp>
}

export type Query_RootOrder_Product_Item_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootOrder_Product_ReplacementArgs = {
  distinct_on?: InputMaybe<Array<Order_Product_Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Product_Replacement_Order_By>>
  where?: InputMaybe<Order_Product_Replacement_Bool_Exp>
}

export type Query_RootOrder_Product_Replacement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Product_Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Product_Replacement_Order_By>>
  where?: InputMaybe<Order_Product_Replacement_Bool_Exp>
}

export type Query_RootOrder_Product_Replacement_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootOrder_TransactionArgs = {
  distinct_on?: InputMaybe<Array<Order_Transaction_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Transaction_Order_By>>
  where?: InputMaybe<Order_Transaction_Bool_Exp>
}

export type Query_RootOrder_Transaction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Transaction_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Transaction_Order_By>>
  where?: InputMaybe<Order_Transaction_Bool_Exp>
}

export type Query_RootOrder_Transaction_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootPayment_ConditionArgs = {
  distinct_on?: InputMaybe<Array<Payment_Condition_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Condition_Order_By>>
  where?: InputMaybe<Payment_Condition_Bool_Exp>
}

export type Query_RootPayment_Condition_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Condition_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Condition_Order_By>>
  where?: InputMaybe<Payment_Condition_Bool_Exp>
}

export type Query_RootPayment_Condition_By_PkArgs = {
  code: Scalars['String']['input']
}

export type Query_RootPayment_MethodArgs = {
  distinct_on?: InputMaybe<Array<Payment_Method_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Method_Order_By>>
  where?: InputMaybe<Payment_Method_Bool_Exp>
}

export type Query_RootPayment_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Method_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Method_Order_By>>
  where?: InputMaybe<Payment_Method_Bool_Exp>
}

export type Query_RootPayment_Method_By_PkArgs = {
  code: Scalars['String']['input']
}

export type Query_RootPost_CodeArgs = {
  distinct_on?: InputMaybe<Array<Post_Code_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Post_Code_Order_By>>
  where?: InputMaybe<Post_Code_Bool_Exp>
}

export type Query_RootPost_Code_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Code_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Post_Code_Order_By>>
  where?: InputMaybe<Post_Code_Bool_Exp>
}

export type Query_RootPost_Code_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootProductArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Order_By>>
  where?: InputMaybe<Product_Bool_Exp>
}

export type Query_RootProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Order_By>>
  where?: InputMaybe<Product_Bool_Exp>
}

export type Query_RootProduct_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootProduct_Discount_GroupArgs = {
  distinct_on?: InputMaybe<Array<Product_Discount_Group_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Discount_Group_Order_By>>
  where?: InputMaybe<Product_Discount_Group_Bool_Exp>
}

export type Query_RootProduct_Discount_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Discount_Group_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Discount_Group_Order_By>>
  where?: InputMaybe<Product_Discount_Group_Bool_Exp>
}

export type Query_RootProduct_Discount_Group_By_PkArgs = {
  code: Scalars['String']['input']
}

export type Query_RootProduct_FileArgs = {
  distinct_on?: InputMaybe<Array<Product_File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_File_Order_By>>
  where?: InputMaybe<Product_File_Bool_Exp>
}

export type Query_RootProduct_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_File_Order_By>>
  where?: InputMaybe<Product_File_Bool_Exp>
}

export type Query_RootProduct_File_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootProduct_PriceArgs = {
  distinct_on?: InputMaybe<Array<Product_Price_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Price_Order_By>>
  where?: InputMaybe<Product_Price_Bool_Exp>
}

export type Query_RootProduct_Price_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Price_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Price_Order_By>>
  where?: InputMaybe<Product_Price_Bool_Exp>
}

export type Query_RootProduct_Price_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootProduct_Price_ListArgs = {
  distinct_on?: InputMaybe<Array<Product_Price_List_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Price_List_Order_By>>
  where?: InputMaybe<Product_Price_List_Bool_Exp>
}

export type Query_RootProduct_Price_List_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Price_List_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Price_List_Order_By>>
  where?: InputMaybe<Product_Price_List_Bool_Exp>
}

export type Query_RootProduct_Price_List_By_PkArgs = {
  code: Scalars['String']['input']
}

export type Query_RootProduct_Price_ViewArgs = {
  distinct_on?: InputMaybe<Array<Product_Price_View_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Price_View_Order_By>>
  where?: InputMaybe<Product_Price_View_Bool_Exp>
}

export type Query_RootProduct_Price_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Price_View_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Price_View_Order_By>>
  where?: InputMaybe<Product_Price_View_Bool_Exp>
}

export type Query_RootProduct_ReplacementArgs = {
  distinct_on?: InputMaybe<Array<Product_Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Replacement_Order_By>>
  where?: InputMaybe<Product_Replacement_Bool_Exp>
}

export type Query_RootProduct_Replacement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Replacement_Order_By>>
  where?: InputMaybe<Product_Replacement_Bool_Exp>
}

export type Query_RootProduct_Replacement_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootProduct_Replacement_MapArgs = {
  distinct_on?: InputMaybe<Array<Product_Replacement_Map_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Replacement_Map_Order_By>>
  where?: InputMaybe<Product_Replacement_Map_Bool_Exp>
}

export type Query_RootProduct_Replacement_Map_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Replacement_Map_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Replacement_Map_Order_By>>
  where?: InputMaybe<Product_Replacement_Map_Bool_Exp>
}

export type Query_RootProduct_Replacement_Map_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootReplacementArgs = {
  distinct_on?: InputMaybe<Array<Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Replacement_Order_By>>
  where?: InputMaybe<Replacement_Bool_Exp>
}

export type Query_RootReplacement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Replacement_Order_By>>
  where?: InputMaybe<Replacement_Bool_Exp>
}

export type Query_RootReplacement_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootReportArgs = {
  distinct_on?: InputMaybe<Array<Report_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Report_Order_By>>
  where?: InputMaybe<Report_Bool_Exp>
}

export type Query_RootReport_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Report_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Report_Order_By>>
  where?: InputMaybe<Report_Bool_Exp>
}

export type Query_RootReport_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootRoleArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Role_Order_By>>
  where?: InputMaybe<Role_Bool_Exp>
}

export type Query_RootRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Role_Order_By>>
  where?: InputMaybe<Role_Bool_Exp>
}

export type Query_RootRole_By_PkArgs = {
  name: Scalars['String']['input']
}

export type Query_RootSettingArgs = {
  distinct_on?: InputMaybe<Array<Setting_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Setting_Order_By>>
  where?: InputMaybe<Setting_Bool_Exp>
}

export type Query_RootSetting_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Setting_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Setting_Order_By>>
  where?: InputMaybe<Setting_Bool_Exp>
}

export type Query_RootSetting_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootShipment_DocumentArgs = {
  distinct_on?: InputMaybe<Array<Shipment_Document_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Shipment_Document_Order_By>>
  where?: InputMaybe<Shipment_Document_Bool_Exp>
}

export type Query_RootShipment_Document_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shipment_Document_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Shipment_Document_Order_By>>
  where?: InputMaybe<Shipment_Document_Bool_Exp>
}

export type Query_RootShipment_Document_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootShipping_MethodArgs = {
  distinct_on?: InputMaybe<Array<Shipping_Method_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Shipping_Method_Order_By>>
  where?: InputMaybe<Shipping_Method_Bool_Exp>
}

export type Query_RootShipping_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shipping_Method_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Shipping_Method_Order_By>>
  where?: InputMaybe<Shipping_Method_Bool_Exp>
}

export type Query_RootShipping_Method_By_PkArgs = {
  code: Scalars['String']['input']
}

export type Query_RootShipping_RateArgs = {
  distinct_on?: InputMaybe<Array<Shipping_Rate_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Shipping_Rate_Order_By>>
  where?: InputMaybe<Shipping_Rate_Bool_Exp>
}

export type Query_RootShipping_Rate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shipping_Rate_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Shipping_Rate_Order_By>>
  where?: InputMaybe<Shipping_Rate_Bool_Exp>
}

export type Query_RootShipping_Rate_By_PkArgs = {
  code: Scalars['String']['input']
}

export type Query_RootSubcustomerArgs = {
  distinct_on?: InputMaybe<Array<Subcustomer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Subcustomer_Order_By>>
  where?: InputMaybe<Subcustomer_Bool_Exp>
}

export type Query_RootSubcustomer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subcustomer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Subcustomer_Order_By>>
  where?: InputMaybe<Subcustomer_Bool_Exp>
}

export type Query_RootSubcustomer_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootTerritoryArgs = {
  distinct_on?: InputMaybe<Array<Territory_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Territory_Order_By>>
  where?: InputMaybe<Territory_Bool_Exp>
}

export type Query_RootTerritory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Territory_Order_By>>
  where?: InputMaybe<Territory_Bool_Exp>
}

export type Query_RootTerritory_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootTransactionArgs = {
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Transaction_Order_By>>
  where?: InputMaybe<Transaction_Bool_Exp>
}

export type Query_RootTransaction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Transaction_Order_By>>
  where?: InputMaybe<Transaction_Bool_Exp>
}

export type Query_RootTransaction_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** columns and relationships of "replacement" */
export type Replacement = {
  __typename?: 'replacement'
  created_at: Scalars['timestamptz']['output']
  description?: Maybe<Scalars['String']['output']>
  erp_id: Scalars['String']['output']
  id: Scalars['uuid']['output']
  name?: Maybe<Scalars['String']['output']>
  /** An array relationship */
  order_product_replacements: Array<Order_Product_Replacement>
  /** An aggregate relationship */
  order_product_replacements_aggregate: Order_Product_Replacement_Aggregate
  /** An array relationship */
  product_replacements: Array<Product_Replacement>
  /** An aggregate relationship */
  product_replacements_aggregate: Product_Replacement_Aggregate
  rev: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "replacement" */
export type ReplacementOrder_Product_ReplacementsArgs = {
  distinct_on?: InputMaybe<Array<Order_Product_Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Product_Replacement_Order_By>>
  where?: InputMaybe<Order_Product_Replacement_Bool_Exp>
}

/** columns and relationships of "replacement" */
export type ReplacementOrder_Product_Replacements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Product_Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Product_Replacement_Order_By>>
  where?: InputMaybe<Order_Product_Replacement_Bool_Exp>
}

/** columns and relationships of "replacement" */
export type ReplacementProduct_ReplacementsArgs = {
  distinct_on?: InputMaybe<Array<Product_Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Replacement_Order_By>>
  where?: InputMaybe<Product_Replacement_Bool_Exp>
}

/** columns and relationships of "replacement" */
export type ReplacementProduct_Replacements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Replacement_Order_By>>
  where?: InputMaybe<Product_Replacement_Bool_Exp>
}

/** aggregated selection of "replacement" */
export type Replacement_Aggregate = {
  __typename?: 'replacement_aggregate'
  aggregate?: Maybe<Replacement_Aggregate_Fields>
  nodes: Array<Replacement>
}

/** aggregate fields of "replacement" */
export type Replacement_Aggregate_Fields = {
  __typename?: 'replacement_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Replacement_Max_Fields>
  min?: Maybe<Replacement_Min_Fields>
}

/** aggregate fields of "replacement" */
export type Replacement_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Replacement_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "replacement". All fields are combined with a logical 'AND'. */
export type Replacement_Bool_Exp = {
  _and?: InputMaybe<Array<Replacement_Bool_Exp>>
  _not?: InputMaybe<Replacement_Bool_Exp>
  _or?: InputMaybe<Array<Replacement_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  erp_id?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  order_product_replacements?: InputMaybe<Order_Product_Replacement_Bool_Exp>
  order_product_replacements_aggregate?: InputMaybe<Order_Product_Replacement_Aggregate_Bool_Exp>
  product_replacements?: InputMaybe<Product_Replacement_Bool_Exp>
  product_replacements_aggregate?: InputMaybe<Product_Replacement_Aggregate_Bool_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "replacement" */
export enum Replacement_Constraint {
  /** unique or primary key constraint on columns "erp_id" */
  ReplacementErpIdKey = 'replacement_erp_id_key',
  /** unique or primary key constraint on columns "id" */
  ReplacementPkey = 'replacement_pkey',
}

/** input type for inserting data into table "replacement" */
export type Replacement_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  order_product_replacements?: InputMaybe<Order_Product_Replacement_Arr_Rel_Insert_Input>
  product_replacements?: InputMaybe<Product_Replacement_Arr_Rel_Insert_Input>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Replacement_Max_Fields = {
  __typename?: 'replacement_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** aggregate min on columns */
export type Replacement_Min_Fields = {
  __typename?: 'replacement_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** response of any mutation on the table "replacement" */
export type Replacement_Mutation_Response = {
  __typename?: 'replacement_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Replacement>
}

/** input type for inserting object relation for remote table "replacement" */
export type Replacement_Obj_Rel_Insert_Input = {
  data: Replacement_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Replacement_On_Conflict>
}

/** on_conflict condition type for table "replacement" */
export type Replacement_On_Conflict = {
  constraint: Replacement_Constraint
  update_columns?: Array<Replacement_Update_Column>
  where?: InputMaybe<Replacement_Bool_Exp>
}

/** Ordering options when selecting data from "replacement". */
export type Replacement_Order_By = {
  created_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  order_product_replacements_aggregate?: InputMaybe<Order_Product_Replacement_Aggregate_Order_By>
  product_replacements_aggregate?: InputMaybe<Product_Replacement_Aggregate_Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: replacement */
export type Replacement_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "replacement" */
export enum Replacement_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  ErpId = 'erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "replacement" */
export type Replacement_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "replacement" */
export type Replacement_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Replacement_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Replacement_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "replacement" */
export enum Replacement_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  ErpId = 'erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Replacement_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Replacement_Set_Input>
  /** filter the rows which have to be updated */
  where: Replacement_Bool_Exp
}

/** columns and relationships of "report" */
export type Report = {
  __typename?: 'report'
  /** An object relationship */
  agent: Agent
  agent_erp_id: Scalars['String']['output']
  created_at: Scalars['timestamptz']['output']
  date_end: Scalars['date']['output']
  date_start: Scalars['date']['output']
  filename: Scalars['String']['output']
  id: Scalars['uuid']['output']
  rev: Scalars['String']['output']
  type: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** aggregated selection of "report" */
export type Report_Aggregate = {
  __typename?: 'report_aggregate'
  aggregate?: Maybe<Report_Aggregate_Fields>
  nodes: Array<Report>
}

export type Report_Aggregate_Bool_Exp = {
  count?: InputMaybe<Report_Aggregate_Bool_Exp_Count>
}

export type Report_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Report_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Report_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "report" */
export type Report_Aggregate_Fields = {
  __typename?: 'report_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Report_Max_Fields>
  min?: Maybe<Report_Min_Fields>
}

/** aggregate fields of "report" */
export type Report_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Report_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "report" */
export type Report_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Report_Max_Order_By>
  min?: InputMaybe<Report_Min_Order_By>
}

/** input type for inserting array relation for remote table "report" */
export type Report_Arr_Rel_Insert_Input = {
  data: Array<Report_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Report_On_Conflict>
}

/** Boolean expression to filter rows from the table "report". All fields are combined with a logical 'AND'. */
export type Report_Bool_Exp = {
  _and?: InputMaybe<Array<Report_Bool_Exp>>
  _not?: InputMaybe<Report_Bool_Exp>
  _or?: InputMaybe<Array<Report_Bool_Exp>>
  agent?: InputMaybe<Agent_Bool_Exp>
  agent_erp_id?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  date_end?: InputMaybe<Date_Comparison_Exp>
  date_start?: InputMaybe<Date_Comparison_Exp>
  filename?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  type?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "report" */
export enum Report_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReportPkey = 'report_pkey',
}

/** input type for inserting data into table "report" */
export type Report_Insert_Input = {
  agent?: InputMaybe<Agent_Obj_Rel_Insert_Input>
  agent_erp_id?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  date_end?: InputMaybe<Scalars['date']['input']>
  date_start?: InputMaybe<Scalars['date']['input']>
  filename?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Report_Max_Fields = {
  __typename?: 'report_max_fields'
  agent_erp_id?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  date_end?: Maybe<Scalars['date']['output']>
  date_start?: Maybe<Scalars['date']['output']>
  filename?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  rev?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "report" */
export type Report_Max_Order_By = {
  agent_erp_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  date_end?: InputMaybe<Order_By>
  date_start?: InputMaybe<Order_By>
  filename?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Report_Min_Fields = {
  __typename?: 'report_min_fields'
  agent_erp_id?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  date_end?: Maybe<Scalars['date']['output']>
  date_start?: Maybe<Scalars['date']['output']>
  filename?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  rev?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "report" */
export type Report_Min_Order_By = {
  agent_erp_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  date_end?: InputMaybe<Order_By>
  date_start?: InputMaybe<Order_By>
  filename?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "report" */
export type Report_Mutation_Response = {
  __typename?: 'report_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Report>
}

/** on_conflict condition type for table "report" */
export type Report_On_Conflict = {
  constraint: Report_Constraint
  update_columns?: Array<Report_Update_Column>
  where?: InputMaybe<Report_Bool_Exp>
}

/** Ordering options when selecting data from "report". */
export type Report_Order_By = {
  agent?: InputMaybe<Agent_Order_By>
  agent_erp_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  date_end?: InputMaybe<Order_By>
  date_start?: InputMaybe<Order_By>
  filename?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: report */
export type Report_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "report" */
export enum Report_Select_Column {
  /** column name */
  AgentErpId = 'agent_erp_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateEnd = 'date_end',
  /** column name */
  DateStart = 'date_start',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Rev = 'rev',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "report" */
export type Report_Set_Input = {
  agent_erp_id?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  date_end?: InputMaybe<Scalars['date']['input']>
  date_start?: InputMaybe<Scalars['date']['input']>
  filename?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "report" */
export type Report_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Report_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Report_Stream_Cursor_Value_Input = {
  agent_erp_id?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  date_end?: InputMaybe<Scalars['date']['input']>
  date_start?: InputMaybe<Scalars['date']['input']>
  filename?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "report" */
export enum Report_Update_Column {
  /** column name */
  AgentErpId = 'agent_erp_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateEnd = 'date_end',
  /** column name */
  DateStart = 'date_start',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Rev = 'rev',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Report_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Report_Set_Input>
  /** filter the rows which have to be updated */
  where: Report_Bool_Exp
}

/** columns and relationships of "role" */
export type Role = {
  __typename?: 'role'
  /** An array relationship */
  downloads: Array<Download>
  /** An aggregate relationship */
  downloads_aggregate: Download_Aggregate
  name: Scalars['String']['output']
  /** An array relationship */
  users: Array<User>
  /** An aggregate relationship */
  users_aggregate: User_Aggregate
}

/** columns and relationships of "role" */
export type RoleDownloadsArgs = {
  distinct_on?: InputMaybe<Array<Download_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Download_Order_By>>
  where?: InputMaybe<Download_Bool_Exp>
}

/** columns and relationships of "role" */
export type RoleDownloads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Download_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Download_Order_By>>
  where?: InputMaybe<Download_Bool_Exp>
}

/** columns and relationships of "role" */
export type RoleUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

/** columns and relationships of "role" */
export type RoleUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

/** aggregated selection of "role" */
export type Role_Aggregate = {
  __typename?: 'role_aggregate'
  aggregate?: Maybe<Role_Aggregate_Fields>
  nodes: Array<Role>
}

/** aggregate fields of "role" */
export type Role_Aggregate_Fields = {
  __typename?: 'role_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Role_Max_Fields>
  min?: Maybe<Role_Min_Fields>
}

/** aggregate fields of "role" */
export type Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "role". All fields are combined with a logical 'AND'. */
export type Role_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Bool_Exp>>
  _not?: InputMaybe<Role_Bool_Exp>
  _or?: InputMaybe<Array<Role_Bool_Exp>>
  downloads?: InputMaybe<Download_Bool_Exp>
  downloads_aggregate?: InputMaybe<Download_Aggregate_Bool_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  users?: InputMaybe<User_Bool_Exp>
  users_aggregate?: InputMaybe<User_Aggregate_Bool_Exp>
}

/** unique or primary key constraints on table "role" */
export enum Role_Constraint {
  /** unique or primary key constraint on columns "name" */
  RolePkey = 'role_pkey',
}

export enum Role_Enum {
  Agent = 'agent',
  BackendAdmin = 'backend_admin',
  BackendSuperAdmin = 'backend_super_admin',
  Customer = 'customer',
  DemoAgent = 'demo_agent',
  DemoCustomer = 'demo_customer',
  DemoGeneric = 'demo_generic',
  DemoStock = 'demo_stock',
  DemoSubCustomer = 'demo_sub_customer',
  SubCustomer = 'sub_customer',
}

/** Boolean expression to compare columns of type "role_enum". All fields are combined with logical 'AND'. */
export type Role_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Role_Enum>
  _in?: InputMaybe<Array<Role_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<Role_Enum>
  _nin?: InputMaybe<Array<Role_Enum>>
}

/** input type for inserting data into table "role" */
export type Role_Insert_Input = {
  downloads?: InputMaybe<Download_Arr_Rel_Insert_Input>
  name?: InputMaybe<Scalars['String']['input']>
  users?: InputMaybe<User_Arr_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Role_Max_Fields = {
  __typename?: 'role_max_fields'
  name?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Role_Min_Fields = {
  __typename?: 'role_min_fields'
  name?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "role" */
export type Role_Mutation_Response = {
  __typename?: 'role_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Role>
}

/** input type for inserting object relation for remote table "role" */
export type Role_Obj_Rel_Insert_Input = {
  data: Role_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Role_On_Conflict>
}

/** on_conflict condition type for table "role" */
export type Role_On_Conflict = {
  constraint: Role_Constraint
  update_columns?: Array<Role_Update_Column>
  where?: InputMaybe<Role_Bool_Exp>
}

/** Ordering options when selecting data from "role". */
export type Role_Order_By = {
  downloads_aggregate?: InputMaybe<Download_Aggregate_Order_By>
  name?: InputMaybe<Order_By>
  users_aggregate?: InputMaybe<User_Aggregate_Order_By>
}

/** primary key columns input for table: role */
export type Role_Pk_Columns_Input = {
  name: Scalars['String']['input']
}

/** select columns of table "role" */
export enum Role_Select_Column {
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "role" */
export type Role_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "role" */
export type Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Role_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "role" */
export enum Role_Update_Column {
  /** column name */
  Name = 'name',
}

export type Role_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Set_Input>
  /** filter the rows which have to be updated */
  where: Role_Bool_Exp
}

/** columns and relationships of "setting" */
export type Setting = {
  __typename?: 'setting'
  created_at: Scalars['timestamptz']['output']
  id: Scalars['uuid']['output']
  key: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
  value?: Maybe<Scalars['String']['output']>
}

/** aggregated selection of "setting" */
export type Setting_Aggregate = {
  __typename?: 'setting_aggregate'
  aggregate?: Maybe<Setting_Aggregate_Fields>
  nodes: Array<Setting>
}

/** aggregate fields of "setting" */
export type Setting_Aggregate_Fields = {
  __typename?: 'setting_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Setting_Max_Fields>
  min?: Maybe<Setting_Min_Fields>
}

/** aggregate fields of "setting" */
export type Setting_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Setting_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "setting". All fields are combined with a logical 'AND'. */
export type Setting_Bool_Exp = {
  _and?: InputMaybe<Array<Setting_Bool_Exp>>
  _not?: InputMaybe<Setting_Bool_Exp>
  _or?: InputMaybe<Array<Setting_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  key?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "setting" */
export enum Setting_Constraint {
  /** unique or primary key constraint on columns "key" */
  SettingKeyKey = 'setting_key_key',
  /** unique or primary key constraint on columns "id" */
  SettingPkey = 'setting_pkey',
}

/** input type for inserting data into table "setting" */
export type Setting_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  key?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Setting_Max_Fields = {
  __typename?: 'setting_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  key?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Setting_Min_Fields = {
  __typename?: 'setting_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  key?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "setting" */
export type Setting_Mutation_Response = {
  __typename?: 'setting_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Setting>
}

/** on_conflict condition type for table "setting" */
export type Setting_On_Conflict = {
  constraint: Setting_Constraint
  update_columns?: Array<Setting_Update_Column>
  where?: InputMaybe<Setting_Bool_Exp>
}

/** Ordering options when selecting data from "setting". */
export type Setting_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  key?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: setting */
export type Setting_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "setting" */
export enum Setting_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "setting" */
export type Setting_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  key?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "setting" */
export type Setting_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Setting_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Setting_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  key?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "setting" */
export enum Setting_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
}

export type Setting_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Setting_Set_Input>
  /** filter the rows which have to be updated */
  where: Setting_Bool_Exp
}

/** columns and relationships of "shipment_document" */
export type Shipment_Document = {
  __typename?: 'shipment_document'
  created_at: Scalars['timestamptz']['output']
  /** An object relationship */
  customer: Customer
  customer_erp_id: Scalars['String']['output']
  date: Scalars['date']['output']
  erp_id: Scalars['String']['output']
  id: Scalars['uuid']['output']
  pdf_created_at?: Maybe<Scalars['timestamptz']['output']>
  pdf_filename?: Maybe<Scalars['String']['output']>
  rev: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** aggregated selection of "shipment_document" */
export type Shipment_Document_Aggregate = {
  __typename?: 'shipment_document_aggregate'
  aggregate?: Maybe<Shipment_Document_Aggregate_Fields>
  nodes: Array<Shipment_Document>
}

export type Shipment_Document_Aggregate_Bool_Exp = {
  count?: InputMaybe<Shipment_Document_Aggregate_Bool_Exp_Count>
}

export type Shipment_Document_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Shipment_Document_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Shipment_Document_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "shipment_document" */
export type Shipment_Document_Aggregate_Fields = {
  __typename?: 'shipment_document_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Shipment_Document_Max_Fields>
  min?: Maybe<Shipment_Document_Min_Fields>
}

/** aggregate fields of "shipment_document" */
export type Shipment_Document_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Shipment_Document_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "shipment_document" */
export type Shipment_Document_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Shipment_Document_Max_Order_By>
  min?: InputMaybe<Shipment_Document_Min_Order_By>
}

/** input type for inserting array relation for remote table "shipment_document" */
export type Shipment_Document_Arr_Rel_Insert_Input = {
  data: Array<Shipment_Document_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Shipment_Document_On_Conflict>
}

/** Boolean expression to filter rows from the table "shipment_document". All fields are combined with a logical 'AND'. */
export type Shipment_Document_Bool_Exp = {
  _and?: InputMaybe<Array<Shipment_Document_Bool_Exp>>
  _not?: InputMaybe<Shipment_Document_Bool_Exp>
  _or?: InputMaybe<Array<Shipment_Document_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customer?: InputMaybe<Customer_Bool_Exp>
  customer_erp_id?: InputMaybe<String_Comparison_Exp>
  date?: InputMaybe<Date_Comparison_Exp>
  erp_id?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  pdf_created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  pdf_filename?: InputMaybe<String_Comparison_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "shipment_document" */
export enum Shipment_Document_Constraint {
  /** unique or primary key constraint on columns "erp_id" */
  ShipmentDocumentErpIdKey = 'shipment_document_erp_id_key',
  /** unique or primary key constraint on columns "id" */
  ShipmentDocumentPkey = 'shipment_document_pkey',
}

/** input type for inserting data into table "shipment_document" */
export type Shipment_Document_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  date?: InputMaybe<Scalars['date']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  pdf_created_at?: InputMaybe<Scalars['timestamptz']['input']>
  pdf_filename?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Shipment_Document_Max_Fields = {
  __typename?: 'shipment_document_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  date?: Maybe<Scalars['date']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  pdf_created_at?: Maybe<Scalars['timestamptz']['output']>
  pdf_filename?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "shipment_document" */
export type Shipment_Document_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  date?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  pdf_created_at?: InputMaybe<Order_By>
  pdf_filename?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Shipment_Document_Min_Fields = {
  __typename?: 'shipment_document_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  date?: Maybe<Scalars['date']['output']>
  erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  pdf_created_at?: Maybe<Scalars['timestamptz']['output']>
  pdf_filename?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "shipment_document" */
export type Shipment_Document_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  date?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  pdf_created_at?: InputMaybe<Order_By>
  pdf_filename?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "shipment_document" */
export type Shipment_Document_Mutation_Response = {
  __typename?: 'shipment_document_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Shipment_Document>
}

/** on_conflict condition type for table "shipment_document" */
export type Shipment_Document_On_Conflict = {
  constraint: Shipment_Document_Constraint
  update_columns?: Array<Shipment_Document_Update_Column>
  where?: InputMaybe<Shipment_Document_Bool_Exp>
}

/** Ordering options when selecting data from "shipment_document". */
export type Shipment_Document_Order_By = {
  created_at?: InputMaybe<Order_By>
  customer?: InputMaybe<Customer_Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  date?: InputMaybe<Order_By>
  erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  pdf_created_at?: InputMaybe<Order_By>
  pdf_filename?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: shipment_document */
export type Shipment_Document_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "shipment_document" */
export enum Shipment_Document_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Date = 'date',
  /** column name */
  ErpId = 'erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  PdfCreatedAt = 'pdf_created_at',
  /** column name */
  PdfFilename = 'pdf_filename',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "shipment_document" */
export type Shipment_Document_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  date?: InputMaybe<Scalars['date']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  pdf_created_at?: InputMaybe<Scalars['timestamptz']['input']>
  pdf_filename?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "shipment_document" */
export type Shipment_Document_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shipment_Document_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shipment_Document_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  date?: InputMaybe<Scalars['date']['input']>
  erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  pdf_created_at?: InputMaybe<Scalars['timestamptz']['input']>
  pdf_filename?: InputMaybe<Scalars['String']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "shipment_document" */
export enum Shipment_Document_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Date = 'date',
  /** column name */
  ErpId = 'erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  PdfCreatedAt = 'pdf_created_at',
  /** column name */
  PdfFilename = 'pdf_filename',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Shipment_Document_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Shipment_Document_Set_Input>
  /** filter the rows which have to be updated */
  where: Shipment_Document_Bool_Exp
}

/** columns and relationships of "shipping_method" */
export type Shipping_Method = {
  __typename?: 'shipping_method'
  code: Scalars['String']['output']
  created_at: Scalars['timestamptz']['output']
  /** An array relationship */
  customer_address_dropshippings: Array<Customer_Address_Dropshipping>
  /** An aggregate relationship */
  customer_address_dropshippings_aggregate: Customer_Address_Dropshipping_Aggregate
  /** An array relationship */
  customer_addresses: Array<Customer_Address>
  /** An aggregate relationship */
  customer_addresses_aggregate: Customer_Address_Aggregate
  /** An array relationship */
  customers: Array<Customer>
  /** An aggregate relationship */
  customers_aggregate: Customer_Aggregate
  description: Scalars['String']['output']
  description_de: Scalars['String']['output']
  description_en: Scalars['String']['output']
  description_es: Scalars['String']['output']
  description_fr: Scalars['String']['output']
  description_it: Scalars['String']['output']
  description_ru: Scalars['String']['output']
  has_shipping_cost: Scalars['Boolean']['output']
  rev: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "shipping_method" */
export type Shipping_MethodCustomer_Address_DropshippingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Address_Dropshipping_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Address_Dropshipping_Order_By>>
  where?: InputMaybe<Customer_Address_Dropshipping_Bool_Exp>
}

/** columns and relationships of "shipping_method" */
export type Shipping_MethodCustomer_Address_Dropshippings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Address_Dropshipping_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Address_Dropshipping_Order_By>>
  where?: InputMaybe<Customer_Address_Dropshipping_Bool_Exp>
}

/** columns and relationships of "shipping_method" */
export type Shipping_MethodCustomer_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Address_Order_By>>
  where?: InputMaybe<Customer_Address_Bool_Exp>
}

/** columns and relationships of "shipping_method" */
export type Shipping_MethodCustomer_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Address_Order_By>>
  where?: InputMaybe<Customer_Address_Bool_Exp>
}

/** columns and relationships of "shipping_method" */
export type Shipping_MethodCustomersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

/** columns and relationships of "shipping_method" */
export type Shipping_MethodCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

/** aggregated selection of "shipping_method" */
export type Shipping_Method_Aggregate = {
  __typename?: 'shipping_method_aggregate'
  aggregate?: Maybe<Shipping_Method_Aggregate_Fields>
  nodes: Array<Shipping_Method>
}

/** aggregate fields of "shipping_method" */
export type Shipping_Method_Aggregate_Fields = {
  __typename?: 'shipping_method_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Shipping_Method_Max_Fields>
  min?: Maybe<Shipping_Method_Min_Fields>
}

/** aggregate fields of "shipping_method" */
export type Shipping_Method_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Shipping_Method_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "shipping_method". All fields are combined with a logical 'AND'. */
export type Shipping_Method_Bool_Exp = {
  _and?: InputMaybe<Array<Shipping_Method_Bool_Exp>>
  _not?: InputMaybe<Shipping_Method_Bool_Exp>
  _or?: InputMaybe<Array<Shipping_Method_Bool_Exp>>
  code?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customer_address_dropshippings?: InputMaybe<Customer_Address_Dropshipping_Bool_Exp>
  customer_address_dropshippings_aggregate?: InputMaybe<Customer_Address_Dropshipping_Aggregate_Bool_Exp>
  customer_addresses?: InputMaybe<Customer_Address_Bool_Exp>
  customer_addresses_aggregate?: InputMaybe<Customer_Address_Aggregate_Bool_Exp>
  customers?: InputMaybe<Customer_Bool_Exp>
  customers_aggregate?: InputMaybe<Customer_Aggregate_Bool_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  description_de?: InputMaybe<String_Comparison_Exp>
  description_en?: InputMaybe<String_Comparison_Exp>
  description_es?: InputMaybe<String_Comparison_Exp>
  description_fr?: InputMaybe<String_Comparison_Exp>
  description_it?: InputMaybe<String_Comparison_Exp>
  description_ru?: InputMaybe<String_Comparison_Exp>
  has_shipping_cost?: InputMaybe<Boolean_Comparison_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "shipping_method" */
export enum Shipping_Method_Constraint {
  /** unique or primary key constraint on columns "code" */
  ShippingMethodPkey = 'shipping_method_pkey',
}

/** input type for inserting data into table "shipping_method" */
export type Shipping_Method_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_address_dropshippings?: InputMaybe<Customer_Address_Dropshipping_Arr_Rel_Insert_Input>
  customer_addresses?: InputMaybe<Customer_Address_Arr_Rel_Insert_Input>
  customers?: InputMaybe<Customer_Arr_Rel_Insert_Input>
  description?: InputMaybe<Scalars['String']['input']>
  description_de?: InputMaybe<Scalars['String']['input']>
  description_en?: InputMaybe<Scalars['String']['input']>
  description_es?: InputMaybe<Scalars['String']['input']>
  description_fr?: InputMaybe<Scalars['String']['input']>
  description_it?: InputMaybe<Scalars['String']['input']>
  description_ru?: InputMaybe<Scalars['String']['input']>
  has_shipping_cost?: InputMaybe<Scalars['Boolean']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Shipping_Method_Max_Fields = {
  __typename?: 'shipping_method_max_fields'
  code?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  description_de?: Maybe<Scalars['String']['output']>
  description_en?: Maybe<Scalars['String']['output']>
  description_es?: Maybe<Scalars['String']['output']>
  description_fr?: Maybe<Scalars['String']['output']>
  description_it?: Maybe<Scalars['String']['output']>
  description_ru?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** aggregate min on columns */
export type Shipping_Method_Min_Fields = {
  __typename?: 'shipping_method_min_fields'
  code?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  description_de?: Maybe<Scalars['String']['output']>
  description_en?: Maybe<Scalars['String']['output']>
  description_es?: Maybe<Scalars['String']['output']>
  description_fr?: Maybe<Scalars['String']['output']>
  description_it?: Maybe<Scalars['String']['output']>
  description_ru?: Maybe<Scalars['String']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** response of any mutation on the table "shipping_method" */
export type Shipping_Method_Mutation_Response = {
  __typename?: 'shipping_method_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Shipping_Method>
}

/** input type for inserting object relation for remote table "shipping_method" */
export type Shipping_Method_Obj_Rel_Insert_Input = {
  data: Shipping_Method_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Shipping_Method_On_Conflict>
}

/** on_conflict condition type for table "shipping_method" */
export type Shipping_Method_On_Conflict = {
  constraint: Shipping_Method_Constraint
  update_columns?: Array<Shipping_Method_Update_Column>
  where?: InputMaybe<Shipping_Method_Bool_Exp>
}

/** Ordering options when selecting data from "shipping_method". */
export type Shipping_Method_Order_By = {
  code?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customer_address_dropshippings_aggregate?: InputMaybe<Customer_Address_Dropshipping_Aggregate_Order_By>
  customer_addresses_aggregate?: InputMaybe<Customer_Address_Aggregate_Order_By>
  customers_aggregate?: InputMaybe<Customer_Aggregate_Order_By>
  description?: InputMaybe<Order_By>
  description_de?: InputMaybe<Order_By>
  description_en?: InputMaybe<Order_By>
  description_es?: InputMaybe<Order_By>
  description_fr?: InputMaybe<Order_By>
  description_it?: InputMaybe<Order_By>
  description_ru?: InputMaybe<Order_By>
  has_shipping_cost?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: shipping_method */
export type Shipping_Method_Pk_Columns_Input = {
  code: Scalars['String']['input']
}

/** select columns of table "shipping_method" */
export enum Shipping_Method_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionDe = 'description_de',
  /** column name */
  DescriptionEn = 'description_en',
  /** column name */
  DescriptionEs = 'description_es',
  /** column name */
  DescriptionFr = 'description_fr',
  /** column name */
  DescriptionIt = 'description_it',
  /** column name */
  DescriptionRu = 'description_ru',
  /** column name */
  HasShippingCost = 'has_shipping_cost',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "shipping_method" */
export type Shipping_Method_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  description_de?: InputMaybe<Scalars['String']['input']>
  description_en?: InputMaybe<Scalars['String']['input']>
  description_es?: InputMaybe<Scalars['String']['input']>
  description_fr?: InputMaybe<Scalars['String']['input']>
  description_it?: InputMaybe<Scalars['String']['input']>
  description_ru?: InputMaybe<Scalars['String']['input']>
  has_shipping_cost?: InputMaybe<Scalars['Boolean']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "shipping_method" */
export type Shipping_Method_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shipping_Method_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shipping_Method_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  description_de?: InputMaybe<Scalars['String']['input']>
  description_en?: InputMaybe<Scalars['String']['input']>
  description_es?: InputMaybe<Scalars['String']['input']>
  description_fr?: InputMaybe<Scalars['String']['input']>
  description_it?: InputMaybe<Scalars['String']['input']>
  description_ru?: InputMaybe<Scalars['String']['input']>
  has_shipping_cost?: InputMaybe<Scalars['Boolean']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "shipping_method" */
export enum Shipping_Method_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionDe = 'description_de',
  /** column name */
  DescriptionEn = 'description_en',
  /** column name */
  DescriptionEs = 'description_es',
  /** column name */
  DescriptionFr = 'description_fr',
  /** column name */
  DescriptionIt = 'description_it',
  /** column name */
  DescriptionRu = 'description_ru',
  /** column name */
  HasShippingCost = 'has_shipping_cost',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Shipping_Method_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Shipping_Method_Set_Input>
  /** filter the rows which have to be updated */
  where: Shipping_Method_Bool_Exp
}

/** columns and relationships of "shipping_rate" */
export type Shipping_Rate = {
  __typename?: 'shipping_rate'
  code: Scalars['String']['output']
  cost: Scalars['numeric']['output']
  created_at: Scalars['timestamptz']['output']
  /** An array relationship */
  customers: Array<Customer>
  /** An aggregate relationship */
  customers_aggregate: Customer_Aggregate
  free_threshold: Scalars['numeric']['output']
  rev: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
  valid_from: Scalars['date']['output']
}

/** columns and relationships of "shipping_rate" */
export type Shipping_RateCustomersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

/** columns and relationships of "shipping_rate" */
export type Shipping_RateCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

/** aggregated selection of "shipping_rate" */
export type Shipping_Rate_Aggregate = {
  __typename?: 'shipping_rate_aggregate'
  aggregate?: Maybe<Shipping_Rate_Aggregate_Fields>
  nodes: Array<Shipping_Rate>
}

/** aggregate fields of "shipping_rate" */
export type Shipping_Rate_Aggregate_Fields = {
  __typename?: 'shipping_rate_aggregate_fields'
  avg?: Maybe<Shipping_Rate_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Shipping_Rate_Max_Fields>
  min?: Maybe<Shipping_Rate_Min_Fields>
  stddev?: Maybe<Shipping_Rate_Stddev_Fields>
  stddev_pop?: Maybe<Shipping_Rate_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Shipping_Rate_Stddev_Samp_Fields>
  sum?: Maybe<Shipping_Rate_Sum_Fields>
  var_pop?: Maybe<Shipping_Rate_Var_Pop_Fields>
  var_samp?: Maybe<Shipping_Rate_Var_Samp_Fields>
  variance?: Maybe<Shipping_Rate_Variance_Fields>
}

/** aggregate fields of "shipping_rate" */
export type Shipping_Rate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Shipping_Rate_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** aggregate avg on columns */
export type Shipping_Rate_Avg_Fields = {
  __typename?: 'shipping_rate_avg_fields'
  cost?: Maybe<Scalars['Float']['output']>
  free_threshold?: Maybe<Scalars['Float']['output']>
}

/** Boolean expression to filter rows from the table "shipping_rate". All fields are combined with a logical 'AND'. */
export type Shipping_Rate_Bool_Exp = {
  _and?: InputMaybe<Array<Shipping_Rate_Bool_Exp>>
  _not?: InputMaybe<Shipping_Rate_Bool_Exp>
  _or?: InputMaybe<Array<Shipping_Rate_Bool_Exp>>
  code?: InputMaybe<String_Comparison_Exp>
  cost?: InputMaybe<Numeric_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customers?: InputMaybe<Customer_Bool_Exp>
  customers_aggregate?: InputMaybe<Customer_Aggregate_Bool_Exp>
  free_threshold?: InputMaybe<Numeric_Comparison_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  valid_from?: InputMaybe<Date_Comparison_Exp>
}

/** unique or primary key constraints on table "shipping_rate" */
export enum Shipping_Rate_Constraint {
  /** unique or primary key constraint on columns "code" */
  ShippingRatePkey = 'shipping_rate_pkey',
}

/** input type for incrementing numeric columns in table "shipping_rate" */
export type Shipping_Rate_Inc_Input = {
  cost?: InputMaybe<Scalars['numeric']['input']>
  free_threshold?: InputMaybe<Scalars['numeric']['input']>
}

/** input type for inserting data into table "shipping_rate" */
export type Shipping_Rate_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  cost?: InputMaybe<Scalars['numeric']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customers?: InputMaybe<Customer_Arr_Rel_Insert_Input>
  free_threshold?: InputMaybe<Scalars['numeric']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  valid_from?: InputMaybe<Scalars['date']['input']>
}

/** aggregate max on columns */
export type Shipping_Rate_Max_Fields = {
  __typename?: 'shipping_rate_max_fields'
  code?: Maybe<Scalars['String']['output']>
  cost?: Maybe<Scalars['numeric']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  free_threshold?: Maybe<Scalars['numeric']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  valid_from?: Maybe<Scalars['date']['output']>
}

/** aggregate min on columns */
export type Shipping_Rate_Min_Fields = {
  __typename?: 'shipping_rate_min_fields'
  code?: Maybe<Scalars['String']['output']>
  cost?: Maybe<Scalars['numeric']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  free_threshold?: Maybe<Scalars['numeric']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  valid_from?: Maybe<Scalars['date']['output']>
}

/** response of any mutation on the table "shipping_rate" */
export type Shipping_Rate_Mutation_Response = {
  __typename?: 'shipping_rate_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Shipping_Rate>
}

/** input type for inserting object relation for remote table "shipping_rate" */
export type Shipping_Rate_Obj_Rel_Insert_Input = {
  data: Shipping_Rate_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Shipping_Rate_On_Conflict>
}

/** on_conflict condition type for table "shipping_rate" */
export type Shipping_Rate_On_Conflict = {
  constraint: Shipping_Rate_Constraint
  update_columns?: Array<Shipping_Rate_Update_Column>
  where?: InputMaybe<Shipping_Rate_Bool_Exp>
}

/** Ordering options when selecting data from "shipping_rate". */
export type Shipping_Rate_Order_By = {
  code?: InputMaybe<Order_By>
  cost?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customers_aggregate?: InputMaybe<Customer_Aggregate_Order_By>
  free_threshold?: InputMaybe<Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  valid_from?: InputMaybe<Order_By>
}

/** primary key columns input for table: shipping_rate */
export type Shipping_Rate_Pk_Columns_Input = {
  code: Scalars['String']['input']
}

/** select columns of table "shipping_rate" */
export enum Shipping_Rate_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FreeThreshold = 'free_threshold',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidFrom = 'valid_from',
}

/** input type for updating data in table "shipping_rate" */
export type Shipping_Rate_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  cost?: InputMaybe<Scalars['numeric']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  free_threshold?: InputMaybe<Scalars['numeric']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  valid_from?: InputMaybe<Scalars['date']['input']>
}

/** aggregate stddev on columns */
export type Shipping_Rate_Stddev_Fields = {
  __typename?: 'shipping_rate_stddev_fields'
  cost?: Maybe<Scalars['Float']['output']>
  free_threshold?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_pop on columns */
export type Shipping_Rate_Stddev_Pop_Fields = {
  __typename?: 'shipping_rate_stddev_pop_fields'
  cost?: Maybe<Scalars['Float']['output']>
  free_threshold?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_samp on columns */
export type Shipping_Rate_Stddev_Samp_Fields = {
  __typename?: 'shipping_rate_stddev_samp_fields'
  cost?: Maybe<Scalars['Float']['output']>
  free_threshold?: Maybe<Scalars['Float']['output']>
}

/** Streaming cursor of the table "shipping_rate" */
export type Shipping_Rate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shipping_Rate_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shipping_Rate_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  cost?: InputMaybe<Scalars['numeric']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  free_threshold?: InputMaybe<Scalars['numeric']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  valid_from?: InputMaybe<Scalars['date']['input']>
}

/** aggregate sum on columns */
export type Shipping_Rate_Sum_Fields = {
  __typename?: 'shipping_rate_sum_fields'
  cost?: Maybe<Scalars['numeric']['output']>
  free_threshold?: Maybe<Scalars['numeric']['output']>
}

/** update columns of table "shipping_rate" */
export enum Shipping_Rate_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FreeThreshold = 'free_threshold',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidFrom = 'valid_from',
}

export type Shipping_Rate_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Shipping_Rate_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Shipping_Rate_Set_Input>
  /** filter the rows which have to be updated */
  where: Shipping_Rate_Bool_Exp
}

/** aggregate var_pop on columns */
export type Shipping_Rate_Var_Pop_Fields = {
  __typename?: 'shipping_rate_var_pop_fields'
  cost?: Maybe<Scalars['Float']['output']>
  free_threshold?: Maybe<Scalars['Float']['output']>
}

/** aggregate var_samp on columns */
export type Shipping_Rate_Var_Samp_Fields = {
  __typename?: 'shipping_rate_var_samp_fields'
  cost?: Maybe<Scalars['Float']['output']>
  free_threshold?: Maybe<Scalars['Float']['output']>
}

/** aggregate variance on columns */
export type Shipping_Rate_Variance_Fields = {
  __typename?: 'shipping_rate_variance_fields'
  cost?: Maybe<Scalars['Float']['output']>
  free_threshold?: Maybe<Scalars['Float']['output']>
}

/** columns and relationships of "subcustomer" */
export type Subcustomer = {
  __typename?: 'subcustomer'
  created_at: Scalars['timestamptz']['output']
  /** An object relationship */
  customer: Customer
  customer_erp_id: Scalars['String']['output']
  id: Scalars['uuid']['output']
  updated_at: Scalars['timestamptz']['output']
  /** An object relationship */
  user: User
  user_id: Scalars['uuid']['output']
}

/** aggregated selection of "subcustomer" */
export type Subcustomer_Aggregate = {
  __typename?: 'subcustomer_aggregate'
  aggregate?: Maybe<Subcustomer_Aggregate_Fields>
  nodes: Array<Subcustomer>
}

export type Subcustomer_Aggregate_Bool_Exp = {
  count?: InputMaybe<Subcustomer_Aggregate_Bool_Exp_Count>
}

export type Subcustomer_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Subcustomer_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Subcustomer_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "subcustomer" */
export type Subcustomer_Aggregate_Fields = {
  __typename?: 'subcustomer_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Subcustomer_Max_Fields>
  min?: Maybe<Subcustomer_Min_Fields>
}

/** aggregate fields of "subcustomer" */
export type Subcustomer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subcustomer_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "subcustomer" */
export type Subcustomer_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Subcustomer_Max_Order_By>
  min?: InputMaybe<Subcustomer_Min_Order_By>
}

/** input type for inserting array relation for remote table "subcustomer" */
export type Subcustomer_Arr_Rel_Insert_Input = {
  data: Array<Subcustomer_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Subcustomer_On_Conflict>
}

/** Boolean expression to filter rows from the table "subcustomer". All fields are combined with a logical 'AND'. */
export type Subcustomer_Bool_Exp = {
  _and?: InputMaybe<Array<Subcustomer_Bool_Exp>>
  _not?: InputMaybe<Subcustomer_Bool_Exp>
  _or?: InputMaybe<Array<Subcustomer_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customer?: InputMaybe<Customer_Bool_Exp>
  customer_erp_id?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user?: InputMaybe<User_Bool_Exp>
  user_id?: InputMaybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "subcustomer" */
export enum Subcustomer_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubcustomerPkey = 'subcustomer_pkey',
  /** unique or primary key constraint on columns "user_id" */
  SubcustomerUserIdKey = 'subcustomer_user_id_key',
}

/** input type for inserting data into table "subcustomer" */
export type Subcustomer_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user?: InputMaybe<User_Obj_Rel_Insert_Input>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate max on columns */
export type Subcustomer_Max_Fields = {
  __typename?: 'subcustomer_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** order by max() on columns of table "subcustomer" */
export type Subcustomer_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Subcustomer_Min_Fields = {
  __typename?: 'subcustomer_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** order by min() on columns of table "subcustomer" */
export type Subcustomer_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** response of any mutation on the table "subcustomer" */
export type Subcustomer_Mutation_Response = {
  __typename?: 'subcustomer_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Subcustomer>
}

/** input type for inserting object relation for remote table "subcustomer" */
export type Subcustomer_Obj_Rel_Insert_Input = {
  data: Subcustomer_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Subcustomer_On_Conflict>
}

/** on_conflict condition type for table "subcustomer" */
export type Subcustomer_On_Conflict = {
  constraint: Subcustomer_Constraint
  update_columns?: Array<Subcustomer_Update_Column>
  where?: InputMaybe<Subcustomer_Bool_Exp>
}

/** Ordering options when selecting data from "subcustomer". */
export type Subcustomer_Order_By = {
  created_at?: InputMaybe<Order_By>
  customer?: InputMaybe<Customer_Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user?: InputMaybe<User_Order_By>
  user_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: subcustomer */
export type Subcustomer_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "subcustomer" */
export enum Subcustomer_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "subcustomer" */
export type Subcustomer_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** Streaming cursor of the table "subcustomer" */
export type Subcustomer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subcustomer_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Subcustomer_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** update columns of table "subcustomer" */
export enum Subcustomer_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Subcustomer_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subcustomer_Set_Input>
  /** filter the rows which have to be updated */
  where: Subcustomer_Bool_Exp
}

export type Subscription_Root = {
  __typename?: 'subscription_root'
  /** fetch data from the table: "agent" */
  agent: Array<Agent>
  /** fetch aggregated fields from the table: "agent" */
  agent_aggregate: Agent_Aggregate
  /** An array relationship */
  agent_bonus: Array<Agent_Bonus>
  /** An aggregate relationship */
  agent_bonus_aggregate: Agent_Bonus_Aggregate
  /** fetch data from the table: "agent_bonus" using primary key columns */
  agent_bonus_by_pk?: Maybe<Agent_Bonus>
  /** fetch data from the table in a streaming manner: "agent_bonus" */
  agent_bonus_stream: Array<Agent_Bonus>
  /** fetch data from the table: "agent" using primary key columns */
  agent_by_pk?: Maybe<Agent>
  /** fetch data from the table in a streaming manner: "agent" */
  agent_stream: Array<Agent>
  /** fetch data from the table: "business_sector" */
  business_sector: Array<Business_Sector>
  /** fetch aggregated fields from the table: "business_sector" */
  business_sector_aggregate: Business_Sector_Aggregate
  /** fetch data from the table: "business_sector" using primary key columns */
  business_sector_by_pk?: Maybe<Business_Sector>
  /** fetch data from the table in a streaming manner: "business_sector" */
  business_sector_stream: Array<Business_Sector>
  /** fetch data from the table: "cart" */
  cart: Array<Cart>
  /** fetch aggregated fields from the table: "cart" */
  cart_aggregate: Cart_Aggregate
  /** fetch data from the table: "cart" using primary key columns */
  cart_by_pk?: Maybe<Cart>
  /** fetch data from the table: "cart_item" */
  cart_item: Array<Cart_Item>
  /** fetch aggregated fields from the table: "cart_item" */
  cart_item_aggregate: Cart_Item_Aggregate
  /** fetch data from the table: "cart_item" using primary key columns */
  cart_item_by_pk?: Maybe<Cart_Item>
  /** fetch data from the table in a streaming manner: "cart_item" */
  cart_item_stream: Array<Cart_Item>
  /** fetch data from the table: "cart_replacement" */
  cart_replacement: Array<Cart_Replacement>
  /** fetch aggregated fields from the table: "cart_replacement" */
  cart_replacement_aggregate: Cart_Replacement_Aggregate
  /** fetch data from the table: "cart_replacement" using primary key columns */
  cart_replacement_by_pk?: Maybe<Cart_Replacement>
  /** fetch data from the table in a streaming manner: "cart_replacement" */
  cart_replacement_stream: Array<Cart_Replacement>
  /** fetch data from the table in a streaming manner: "cart" */
  cart_stream: Array<Cart>
  /** fetch data from the table: "communication" */
  communication: Array<Communication>
  /** fetch aggregated fields from the table: "communication" */
  communication_aggregate: Communication_Aggregate
  /** fetch data from the table: "communication" using primary key columns */
  communication_by_pk?: Maybe<Communication>
  /** fetch data from the table: "communication_changelog" */
  communication_changelog: Array<Communication_Changelog>
  /** fetch aggregated fields from the table: "communication_changelog" */
  communication_changelog_aggregate: Communication_Changelog_Aggregate
  /** fetch data from the table: "communication_changelog" using primary key columns */
  communication_changelog_by_pk?: Maybe<Communication_Changelog>
  /** fetch data from the table in a streaming manner: "communication_changelog" */
  communication_changelog_stream: Array<Communication_Changelog>
  /** fetch data from the table: "communication_code" */
  communication_code: Array<Communication_Code>
  /** fetch aggregated fields from the table: "communication_code" */
  communication_code_aggregate: Communication_Code_Aggregate
  /** fetch data from the table: "communication_code" using primary key columns */
  communication_code_by_pk?: Maybe<Communication_Code>
  /** fetch data from the table in a streaming manner: "communication_code" */
  communication_code_stream: Array<Communication_Code>
  /** fetch data from the table: "communication_external_mail" */
  communication_external_mail: Array<Communication_External_Mail>
  /** fetch aggregated fields from the table: "communication_external_mail" */
  communication_external_mail_aggregate: Communication_External_Mail_Aggregate
  /** fetch data from the table: "communication_external_mail" using primary key columns */
  communication_external_mail_by_pk?: Maybe<Communication_External_Mail>
  /** fetch data from the table in a streaming manner: "communication_external_mail" */
  communication_external_mail_stream: Array<Communication_External_Mail>
  /** fetch data from the table: "communication_list" */
  communication_list: Array<Communication_List>
  /** fetch aggregated fields from the table: "communication_list" */
  communication_list_aggregate: Communication_List_Aggregate
  /** fetch data from the table: "communication_list" using primary key columns */
  communication_list_by_pk?: Maybe<Communication_List>
  /** execute function "communication_list_missing_customers" which returns "customer" */
  communication_list_missing_customers: Array<Customer>
  /** execute function "communication_list_missing_customers" and query aggregates on result of table type "customer" */
  communication_list_missing_customers_aggregate: Customer_Aggregate
  /** execute function "communication_list_missing_customers_by_code" which returns "customer" */
  communication_list_missing_customers_by_code: Array<Customer>
  /** execute function "communication_list_missing_customers_by_code" and query aggregates on result of table type "customer" */
  communication_list_missing_customers_by_code_aggregate: Customer_Aggregate
  /** execute function "communication_list_orphans" which returns "communication_list" */
  communication_list_orphans: Array<Communication_List>
  /** execute function "communication_list_orphans" and query aggregates on result of table type "communication_list" */
  communication_list_orphans_aggregate: Communication_List_Aggregate
  /** fetch data from the table in a streaming manner: "communication_list" */
  communication_list_stream: Array<Communication_List>
  /** fetch data from the table: "communication_settings" */
  communication_settings: Array<Communication_Settings>
  /** fetch aggregated fields from the table: "communication_settings" */
  communication_settings_aggregate: Communication_Settings_Aggregate
  /** fetch data from the table: "communication_settings" using primary key columns */
  communication_settings_by_pk?: Maybe<Communication_Settings>
  /** execute function "communication_settings_missing_customers" which returns "customer" */
  communication_settings_missing_customers: Array<Customer>
  /** execute function "communication_settings_missing_customers" and query aggregates on result of table type "customer" */
  communication_settings_missing_customers_aggregate: Customer_Aggregate
  /** fetch data from the table in a streaming manner: "communication_settings" */
  communication_settings_stream: Array<Communication_Settings>
  /** fetch data from the table in a streaming manner: "communication" */
  communication_stream: Array<Communication>
  /** fetch data from the table: "country_region" */
  country_region: Array<Country_Region>
  /** fetch aggregated fields from the table: "country_region" */
  country_region_aggregate: Country_Region_Aggregate
  /** fetch data from the table: "country_region" using primary key columns */
  country_region_by_pk?: Maybe<Country_Region>
  /** fetch data from the table in a streaming manner: "country_region" */
  country_region_stream: Array<Country_Region>
  /** fetch data from the table: "county" */
  county: Array<County>
  /** fetch aggregated fields from the table: "county" */
  county_aggregate: County_Aggregate
  /** fetch data from the table: "county" using primary key columns */
  county_by_pk?: Maybe<County>
  /** fetch data from the table in a streaming manner: "county" */
  county_stream: Array<County>
  /** fetch data from the table: "credit_memo" */
  credit_memo: Array<Credit_Memo>
  /** fetch aggregated fields from the table: "credit_memo" */
  credit_memo_aggregate: Credit_Memo_Aggregate
  /** fetch data from the table: "credit_memo" using primary key columns */
  credit_memo_by_pk?: Maybe<Credit_Memo>
  /** fetch data from the table in a streaming manner: "credit_memo" */
  credit_memo_stream: Array<Credit_Memo>
  /** fetch data from the table: "cron" */
  cron: Array<Cron>
  /** fetch aggregated fields from the table: "cron" */
  cron_aggregate: Cron_Aggregate
  /** fetch data from the table: "cron" using primary key columns */
  cron_by_pk?: Maybe<Cron>
  /** fetch data from the table in a streaming manner: "cron" */
  cron_stream: Array<Cron>
  /** fetch data from the table: "customer" */
  customer: Array<Customer>
  /** fetch data from the table: "customer_additional_data" */
  customer_additional_data: Array<Customer_Additional_Data>
  /** fetch aggregated fields from the table: "customer_additional_data" */
  customer_additional_data_aggregate: Customer_Additional_Data_Aggregate
  /** fetch data from the table: "customer_additional_data" using primary key columns */
  customer_additional_data_by_pk?: Maybe<Customer_Additional_Data>
  /** fetch data from the table in a streaming manner: "customer_additional_data" */
  customer_additional_data_stream: Array<Customer_Additional_Data>
  /** fetch data from the table: "customer_address" */
  customer_address: Array<Customer_Address>
  /** fetch aggregated fields from the table: "customer_address" */
  customer_address_aggregate: Customer_Address_Aggregate
  /** fetch data from the table: "customer_address" using primary key columns */
  customer_address_by_pk?: Maybe<Customer_Address>
  /** fetch data from the table: "customer_address_dropshipping" */
  customer_address_dropshipping: Array<Customer_Address_Dropshipping>
  /** fetch aggregated fields from the table: "customer_address_dropshipping" */
  customer_address_dropshipping_aggregate: Customer_Address_Dropshipping_Aggregate
  /** fetch data from the table: "customer_address_dropshipping" using primary key columns */
  customer_address_dropshipping_by_pk?: Maybe<Customer_Address_Dropshipping>
  /** fetch data from the table in a streaming manner: "customer_address_dropshipping" */
  customer_address_dropshipping_stream: Array<Customer_Address_Dropshipping>
  /** fetch data from the table in a streaming manner: "customer_address" */
  customer_address_stream: Array<Customer_Address>
  /** fetch aggregated fields from the table: "customer" */
  customer_aggregate: Customer_Aggregate
  /** fetch data from the table: "customer" using primary key columns */
  customer_by_pk?: Maybe<Customer>
  /** fetch data from the table: "customer_discount" */
  customer_discount: Array<Customer_Discount>
  /** fetch aggregated fields from the table: "customer_discount" */
  customer_discount_aggregate: Customer_Discount_Aggregate
  /** fetch data from the table: "customer_discount" using primary key columns */
  customer_discount_by_pk?: Maybe<Customer_Discount>
  /** fetch data from the table in a streaming manner: "customer_discount" */
  customer_discount_stream: Array<Customer_Discount>
  /** fetch data from the table in a streaming manner: "customer" */
  customer_stream: Array<Customer>
  /** fetch data from the table: "download" */
  download: Array<Download>
  /** fetch aggregated fields from the table: "download" */
  download_aggregate: Download_Aggregate
  /** fetch data from the table: "download" using primary key columns */
  download_by_pk?: Maybe<Download>
  /** fetch data from the table in a streaming manner: "download" */
  download_stream: Array<Download>
  /** fetch data from the table: "exchange_rate" */
  exchange_rate: Array<Exchange_Rate>
  /** fetch aggregated fields from the table: "exchange_rate" */
  exchange_rate_aggregate: Exchange_Rate_Aggregate
  /** fetch data from the table: "exchange_rate" using primary key columns */
  exchange_rate_by_pk?: Maybe<Exchange_Rate>
  /** fetch data from the table in a streaming manner: "exchange_rate" */
  exchange_rate_stream: Array<Exchange_Rate>
  /** fetch data from the table: "file" */
  file: Array<File>
  /** fetch aggregated fields from the table: "file" */
  file_aggregate: File_Aggregate
  /** fetch data from the table: "file" using primary key columns */
  file_by_pk?: Maybe<File>
  /** fetch data from the table in a streaming manner: "file" */
  file_stream: Array<File>
  /** fetch data from the table: "invoice" */
  invoice: Array<Invoice>
  /** fetch aggregated fields from the table: "invoice" */
  invoice_aggregate: Invoice_Aggregate
  /** fetch data from the table: "invoice" using primary key columns */
  invoice_by_pk?: Maybe<Invoice>
  /** fetch data from the table: "invoice_discount" */
  invoice_discount: Array<Invoice_Discount>
  /** fetch aggregated fields from the table: "invoice_discount" */
  invoice_discount_aggregate: Invoice_Discount_Aggregate
  /** fetch data from the table: "invoice_discount" using primary key columns */
  invoice_discount_by_pk?: Maybe<Invoice_Discount>
  /** fetch data from the table in a streaming manner: "invoice_discount" */
  invoice_discount_stream: Array<Invoice_Discount>
  /** fetch data from the table: "invoice_overdue" */
  invoice_overdue: Array<Invoice_Overdue>
  /** fetch aggregated fields from the table: "invoice_overdue" */
  invoice_overdue_aggregate: Invoice_Overdue_Aggregate
  /** fetch data from the table: "invoice_overdue" using primary key columns */
  invoice_overdue_by_pk?: Maybe<Invoice_Overdue>
  /** fetch data from the table in a streaming manner: "invoice_overdue" */
  invoice_overdue_stream: Array<Invoice_Overdue>
  /** fetch data from the table in a streaming manner: "invoice" */
  invoice_stream: Array<Invoice>
  /** fetch data from the table: "invoice_view" */
  invoice_view: Array<Invoice_View>
  /** fetch aggregated fields from the table: "invoice_view" */
  invoice_view_aggregate: Invoice_View_Aggregate
  /** fetch data from the table in a streaming manner: "invoice_view" */
  invoice_view_stream: Array<Invoice_View>
  /** fetch data from the table: "mail" */
  mail: Array<Mail>
  /** fetch aggregated fields from the table: "mail" */
  mail_aggregate: Mail_Aggregate
  /** fetch data from the table: "mail" using primary key columns */
  mail_by_pk?: Maybe<Mail>
  /** fetch data from the table in a streaming manner: "mail" */
  mail_stream: Array<Mail>
  /** fetch data from the table: "order" */
  order: Array<Order>
  /** fetch aggregated fields from the table: "order" */
  order_aggregate: Order_Aggregate
  /** fetch data from the table: "order_by_email" */
  order_by_email: Array<Order_By_Email>
  /** fetch aggregated fields from the table: "order_by_email" */
  order_by_email_aggregate: Order_By_Email_Aggregate
  /** fetch data from the table: "order_by_email" using primary key columns */
  order_by_email_by_pk?: Maybe<Order_By_Email>
  /** fetch data from the table in a streaming manner: "order_by_email" */
  order_by_email_stream: Array<Order_By_Email>
  /** fetch data from the table: "order" using primary key columns */
  order_by_pk?: Maybe<Order>
  /** fetch data from the table: "order_confirmed" */
  order_confirmed: Array<Order_Confirmed>
  /** fetch aggregated fields from the table: "order_confirmed" */
  order_confirmed_aggregate: Order_Confirmed_Aggregate
  /** fetch data from the table: "order_confirmed" using primary key columns */
  order_confirmed_by_pk?: Maybe<Order_Confirmed>
  /** fetch data from the table in a streaming manner: "order_confirmed" */
  order_confirmed_stream: Array<Order_Confirmed>
  /** fetch data from the table: "order_invoice" */
  order_invoice: Array<Order_Invoice>
  /** fetch aggregated fields from the table: "order_invoice" */
  order_invoice_aggregate: Order_Invoice_Aggregate
  /** fetch data from the table: "order_invoice" using primary key columns */
  order_invoice_by_pk?: Maybe<Order_Invoice>
  /** fetch data from the table: "order_invoice_item" */
  order_invoice_item: Array<Order_Invoice_Item>
  /** fetch aggregated fields from the table: "order_invoice_item" */
  order_invoice_item_aggregate: Order_Invoice_Item_Aggregate
  /** fetch data from the table: "order_invoice_item" using primary key columns */
  order_invoice_item_by_pk?: Maybe<Order_Invoice_Item>
  /** fetch data from the table in a streaming manner: "order_invoice_item" */
  order_invoice_item_stream: Array<Order_Invoice_Item>
  /** fetch data from the table in a streaming manner: "order_invoice" */
  order_invoice_stream: Array<Order_Invoice>
  /** fetch data from the table: "order_item_unshipped" */
  order_item_unshipped: Array<Order_Item_Unshipped>
  /** fetch aggregated fields from the table: "order_item_unshipped" */
  order_item_unshipped_aggregate: Order_Item_Unshipped_Aggregate
  /** fetch data from the table: "order_item_unshipped" using primary key columns */
  order_item_unshipped_by_pk?: Maybe<Order_Item_Unshipped>
  /** fetch data from the table in a streaming manner: "order_item_unshipped" */
  order_item_unshipped_stream: Array<Order_Item_Unshipped>
  /** fetch data from the table: "order_product" */
  order_product: Array<Order_Product>
  /** fetch aggregated fields from the table: "order_product" */
  order_product_aggregate: Order_Product_Aggregate
  /** fetch data from the table: "order_product" using primary key columns */
  order_product_by_pk?: Maybe<Order_Product>
  /** fetch data from the table: "order_product_item" */
  order_product_item: Array<Order_Product_Item>
  /** fetch aggregated fields from the table: "order_product_item" */
  order_product_item_aggregate: Order_Product_Item_Aggregate
  /** fetch data from the table: "order_product_item" using primary key columns */
  order_product_item_by_pk?: Maybe<Order_Product_Item>
  /** fetch data from the table in a streaming manner: "order_product_item" */
  order_product_item_stream: Array<Order_Product_Item>
  /** fetch data from the table: "order_product_replacement" */
  order_product_replacement: Array<Order_Product_Replacement>
  /** fetch aggregated fields from the table: "order_product_replacement" */
  order_product_replacement_aggregate: Order_Product_Replacement_Aggregate
  /** fetch data from the table: "order_product_replacement" using primary key columns */
  order_product_replacement_by_pk?: Maybe<Order_Product_Replacement>
  /** fetch data from the table in a streaming manner: "order_product_replacement" */
  order_product_replacement_stream: Array<Order_Product_Replacement>
  /** fetch data from the table in a streaming manner: "order_product" */
  order_product_stream: Array<Order_Product>
  /** fetch data from the table in a streaming manner: "order" */
  order_stream: Array<Order>
  /** fetch data from the table: "order_transaction" */
  order_transaction: Array<Order_Transaction>
  /** fetch aggregated fields from the table: "order_transaction" */
  order_transaction_aggregate: Order_Transaction_Aggregate
  /** fetch data from the table: "order_transaction" using primary key columns */
  order_transaction_by_pk?: Maybe<Order_Transaction>
  /** fetch data from the table in a streaming manner: "order_transaction" */
  order_transaction_stream: Array<Order_Transaction>
  /** fetch data from the table: "payment_condition" */
  payment_condition: Array<Payment_Condition>
  /** fetch aggregated fields from the table: "payment_condition" */
  payment_condition_aggregate: Payment_Condition_Aggregate
  /** fetch data from the table: "payment_condition" using primary key columns */
  payment_condition_by_pk?: Maybe<Payment_Condition>
  /** fetch data from the table in a streaming manner: "payment_condition" */
  payment_condition_stream: Array<Payment_Condition>
  /** fetch data from the table: "payment_method" */
  payment_method: Array<Payment_Method>
  /** fetch aggregated fields from the table: "payment_method" */
  payment_method_aggregate: Payment_Method_Aggregate
  /** fetch data from the table: "payment_method" using primary key columns */
  payment_method_by_pk?: Maybe<Payment_Method>
  /** fetch data from the table in a streaming manner: "payment_method" */
  payment_method_stream: Array<Payment_Method>
  /** fetch data from the table: "post_code" */
  post_code: Array<Post_Code>
  /** fetch aggregated fields from the table: "post_code" */
  post_code_aggregate: Post_Code_Aggregate
  /** fetch data from the table: "post_code" using primary key columns */
  post_code_by_pk?: Maybe<Post_Code>
  /** fetch data from the table in a streaming manner: "post_code" */
  post_code_stream: Array<Post_Code>
  /** fetch data from the table: "product" */
  product: Array<Product>
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>
  /** fetch data from the table: "product_discount_group" */
  product_discount_group: Array<Product_Discount_Group>
  /** fetch aggregated fields from the table: "product_discount_group" */
  product_discount_group_aggregate: Product_Discount_Group_Aggregate
  /** fetch data from the table: "product_discount_group" using primary key columns */
  product_discount_group_by_pk?: Maybe<Product_Discount_Group>
  /** fetch data from the table in a streaming manner: "product_discount_group" */
  product_discount_group_stream: Array<Product_Discount_Group>
  /** fetch data from the table: "product_file" */
  product_file: Array<Product_File>
  /** fetch aggregated fields from the table: "product_file" */
  product_file_aggregate: Product_File_Aggregate
  /** fetch data from the table: "product_file" using primary key columns */
  product_file_by_pk?: Maybe<Product_File>
  /** fetch data from the table in a streaming manner: "product_file" */
  product_file_stream: Array<Product_File>
  /** fetch data from the table: "product_price" */
  product_price: Array<Product_Price>
  /** fetch aggregated fields from the table: "product_price" */
  product_price_aggregate: Product_Price_Aggregate
  /** fetch data from the table: "product_price" using primary key columns */
  product_price_by_pk?: Maybe<Product_Price>
  /** fetch data from the table: "product_price_list" */
  product_price_list: Array<Product_Price_List>
  /** fetch aggregated fields from the table: "product_price_list" */
  product_price_list_aggregate: Product_Price_List_Aggregate
  /** fetch data from the table: "product_price_list" using primary key columns */
  product_price_list_by_pk?: Maybe<Product_Price_List>
  /** fetch data from the table in a streaming manner: "product_price_list" */
  product_price_list_stream: Array<Product_Price_List>
  /** fetch data from the table in a streaming manner: "product_price" */
  product_price_stream: Array<Product_Price>
  /** fetch data from the table: "product_price_view" */
  product_price_view: Array<Product_Price_View>
  /** fetch aggregated fields from the table: "product_price_view" */
  product_price_view_aggregate: Product_Price_View_Aggregate
  /** fetch data from the table in a streaming manner: "product_price_view" */
  product_price_view_stream: Array<Product_Price_View>
  /** fetch data from the table: "product_replacement" */
  product_replacement: Array<Product_Replacement>
  /** fetch aggregated fields from the table: "product_replacement" */
  product_replacement_aggregate: Product_Replacement_Aggregate
  /** fetch data from the table: "product_replacement" using primary key columns */
  product_replacement_by_pk?: Maybe<Product_Replacement>
  /** fetch data from the table: "product_replacement_map" */
  product_replacement_map: Array<Product_Replacement_Map>
  /** fetch aggregated fields from the table: "product_replacement_map" */
  product_replacement_map_aggregate: Product_Replacement_Map_Aggregate
  /** fetch data from the table: "product_replacement_map" using primary key columns */
  product_replacement_map_by_pk?: Maybe<Product_Replacement_Map>
  /** fetch data from the table in a streaming manner: "product_replacement_map" */
  product_replacement_map_stream: Array<Product_Replacement_Map>
  /** fetch data from the table in a streaming manner: "product_replacement" */
  product_replacement_stream: Array<Product_Replacement>
  /** fetch data from the table in a streaming manner: "product" */
  product_stream: Array<Product>
  /** fetch data from the table: "replacement" */
  replacement: Array<Replacement>
  /** fetch aggregated fields from the table: "replacement" */
  replacement_aggregate: Replacement_Aggregate
  /** fetch data from the table: "replacement" using primary key columns */
  replacement_by_pk?: Maybe<Replacement>
  /** fetch data from the table in a streaming manner: "replacement" */
  replacement_stream: Array<Replacement>
  /** fetch data from the table: "report" */
  report: Array<Report>
  /** fetch aggregated fields from the table: "report" */
  report_aggregate: Report_Aggregate
  /** fetch data from the table: "report" using primary key columns */
  report_by_pk?: Maybe<Report>
  /** fetch data from the table in a streaming manner: "report" */
  report_stream: Array<Report>
  /** fetch data from the table: "role" */
  role: Array<Role>
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>
  /** fetch data from the table in a streaming manner: "role" */
  role_stream: Array<Role>
  /** fetch data from the table: "setting" */
  setting: Array<Setting>
  /** fetch aggregated fields from the table: "setting" */
  setting_aggregate: Setting_Aggregate
  /** fetch data from the table: "setting" using primary key columns */
  setting_by_pk?: Maybe<Setting>
  /** fetch data from the table in a streaming manner: "setting" */
  setting_stream: Array<Setting>
  /** fetch data from the table: "shipment_document" */
  shipment_document: Array<Shipment_Document>
  /** fetch aggregated fields from the table: "shipment_document" */
  shipment_document_aggregate: Shipment_Document_Aggregate
  /** fetch data from the table: "shipment_document" using primary key columns */
  shipment_document_by_pk?: Maybe<Shipment_Document>
  /** fetch data from the table in a streaming manner: "shipment_document" */
  shipment_document_stream: Array<Shipment_Document>
  /** fetch data from the table: "shipping_method" */
  shipping_method: Array<Shipping_Method>
  /** fetch aggregated fields from the table: "shipping_method" */
  shipping_method_aggregate: Shipping_Method_Aggregate
  /** fetch data from the table: "shipping_method" using primary key columns */
  shipping_method_by_pk?: Maybe<Shipping_Method>
  /** fetch data from the table in a streaming manner: "shipping_method" */
  shipping_method_stream: Array<Shipping_Method>
  /** fetch data from the table: "shipping_rate" */
  shipping_rate: Array<Shipping_Rate>
  /** fetch aggregated fields from the table: "shipping_rate" */
  shipping_rate_aggregate: Shipping_Rate_Aggregate
  /** fetch data from the table: "shipping_rate" using primary key columns */
  shipping_rate_by_pk?: Maybe<Shipping_Rate>
  /** fetch data from the table in a streaming manner: "shipping_rate" */
  shipping_rate_stream: Array<Shipping_Rate>
  /** fetch data from the table: "subcustomer" */
  subcustomer: Array<Subcustomer>
  /** fetch aggregated fields from the table: "subcustomer" */
  subcustomer_aggregate: Subcustomer_Aggregate
  /** fetch data from the table: "subcustomer" using primary key columns */
  subcustomer_by_pk?: Maybe<Subcustomer>
  /** fetch data from the table in a streaming manner: "subcustomer" */
  subcustomer_stream: Array<Subcustomer>
  /** fetch data from the table: "territory" */
  territory: Array<Territory>
  /** fetch aggregated fields from the table: "territory" */
  territory_aggregate: Territory_Aggregate
  /** fetch data from the table: "territory" using primary key columns */
  territory_by_pk?: Maybe<Territory>
  /** fetch data from the table in a streaming manner: "territory" */
  territory_stream: Array<Territory>
  /** fetch data from the table: "transaction" */
  transaction: Array<Transaction>
  /** fetch aggregated fields from the table: "transaction" */
  transaction_aggregate: Transaction_Aggregate
  /** fetch data from the table: "transaction" using primary key columns */
  transaction_by_pk?: Maybe<Transaction>
  /** fetch data from the table in a streaming manner: "transaction" */
  transaction_stream: Array<Transaction>
  /** fetch data from the table: "user" */
  user: Array<User>
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>
}

export type Subscription_RootAgentArgs = {
  distinct_on?: InputMaybe<Array<Agent_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Agent_Order_By>>
  where?: InputMaybe<Agent_Bool_Exp>
}

export type Subscription_RootAgent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agent_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Agent_Order_By>>
  where?: InputMaybe<Agent_Bool_Exp>
}

export type Subscription_RootAgent_BonusArgs = {
  distinct_on?: InputMaybe<Array<Agent_Bonus_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Agent_Bonus_Order_By>>
  where?: InputMaybe<Agent_Bonus_Bool_Exp>
}

export type Subscription_RootAgent_Bonus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agent_Bonus_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Agent_Bonus_Order_By>>
  where?: InputMaybe<Agent_Bonus_Bool_Exp>
}

export type Subscription_RootAgent_Bonus_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootAgent_Bonus_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Agent_Bonus_Stream_Cursor_Input>>
  where?: InputMaybe<Agent_Bonus_Bool_Exp>
}

export type Subscription_RootAgent_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootAgent_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Agent_Stream_Cursor_Input>>
  where?: InputMaybe<Agent_Bool_Exp>
}

export type Subscription_RootBusiness_SectorArgs = {
  distinct_on?: InputMaybe<Array<Business_Sector_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Business_Sector_Order_By>>
  where?: InputMaybe<Business_Sector_Bool_Exp>
}

export type Subscription_RootBusiness_Sector_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Business_Sector_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Business_Sector_Order_By>>
  where?: InputMaybe<Business_Sector_Bool_Exp>
}

export type Subscription_RootBusiness_Sector_By_PkArgs = {
  code: Scalars['String']['input']
}

export type Subscription_RootBusiness_Sector_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Business_Sector_Stream_Cursor_Input>>
  where?: InputMaybe<Business_Sector_Bool_Exp>
}

export type Subscription_RootCartArgs = {
  distinct_on?: InputMaybe<Array<Cart_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Cart_Order_By>>
  where?: InputMaybe<Cart_Bool_Exp>
}

export type Subscription_RootCart_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cart_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Cart_Order_By>>
  where?: InputMaybe<Cart_Bool_Exp>
}

export type Subscription_RootCart_By_PkArgs = {
  id: Scalars['bigint']['input']
}

export type Subscription_RootCart_ItemArgs = {
  distinct_on?: InputMaybe<Array<Cart_Item_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Cart_Item_Order_By>>
  where?: InputMaybe<Cart_Item_Bool_Exp>
}

export type Subscription_RootCart_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cart_Item_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Cart_Item_Order_By>>
  where?: InputMaybe<Cart_Item_Bool_Exp>
}

export type Subscription_RootCart_Item_By_PkArgs = {
  id: Scalars['bigint']['input']
}

export type Subscription_RootCart_Item_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Cart_Item_Stream_Cursor_Input>>
  where?: InputMaybe<Cart_Item_Bool_Exp>
}

export type Subscription_RootCart_ReplacementArgs = {
  distinct_on?: InputMaybe<Array<Cart_Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Cart_Replacement_Order_By>>
  where?: InputMaybe<Cart_Replacement_Bool_Exp>
}

export type Subscription_RootCart_Replacement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cart_Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Cart_Replacement_Order_By>>
  where?: InputMaybe<Cart_Replacement_Bool_Exp>
}

export type Subscription_RootCart_Replacement_By_PkArgs = {
  id: Scalars['bigint']['input']
}

export type Subscription_RootCart_Replacement_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Cart_Replacement_Stream_Cursor_Input>>
  where?: InputMaybe<Cart_Replacement_Bool_Exp>
}

export type Subscription_RootCart_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Cart_Stream_Cursor_Input>>
  where?: InputMaybe<Cart_Bool_Exp>
}

export type Subscription_RootCommunicationArgs = {
  distinct_on?: InputMaybe<Array<Communication_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_Order_By>>
  where?: InputMaybe<Communication_Bool_Exp>
}

export type Subscription_RootCommunication_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_Order_By>>
  where?: InputMaybe<Communication_Bool_Exp>
}

export type Subscription_RootCommunication_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootCommunication_ChangelogArgs = {
  distinct_on?: InputMaybe<Array<Communication_Changelog_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_Changelog_Order_By>>
  where?: InputMaybe<Communication_Changelog_Bool_Exp>
}

export type Subscription_RootCommunication_Changelog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_Changelog_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_Changelog_Order_By>>
  where?: InputMaybe<Communication_Changelog_Bool_Exp>
}

export type Subscription_RootCommunication_Changelog_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootCommunication_Changelog_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Communication_Changelog_Stream_Cursor_Input>>
  where?: InputMaybe<Communication_Changelog_Bool_Exp>
}

export type Subscription_RootCommunication_CodeArgs = {
  distinct_on?: InputMaybe<Array<Communication_Code_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_Code_Order_By>>
  where?: InputMaybe<Communication_Code_Bool_Exp>
}

export type Subscription_RootCommunication_Code_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_Code_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_Code_Order_By>>
  where?: InputMaybe<Communication_Code_Bool_Exp>
}

export type Subscription_RootCommunication_Code_By_PkArgs = {
  code: Scalars['String']['input']
}

export type Subscription_RootCommunication_Code_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Communication_Code_Stream_Cursor_Input>>
  where?: InputMaybe<Communication_Code_Bool_Exp>
}

export type Subscription_RootCommunication_External_MailArgs = {
  distinct_on?: InputMaybe<Array<Communication_External_Mail_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_External_Mail_Order_By>>
  where?: InputMaybe<Communication_External_Mail_Bool_Exp>
}

export type Subscription_RootCommunication_External_Mail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_External_Mail_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_External_Mail_Order_By>>
  where?: InputMaybe<Communication_External_Mail_Bool_Exp>
}

export type Subscription_RootCommunication_External_Mail_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootCommunication_External_Mail_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Communication_External_Mail_Stream_Cursor_Input>>
  where?: InputMaybe<Communication_External_Mail_Bool_Exp>
}

export type Subscription_RootCommunication_ListArgs = {
  distinct_on?: InputMaybe<Array<Communication_List_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_List_Order_By>>
  where?: InputMaybe<Communication_List_Bool_Exp>
}

export type Subscription_RootCommunication_List_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_List_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_List_Order_By>>
  where?: InputMaybe<Communication_List_Bool_Exp>
}

export type Subscription_RootCommunication_List_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootCommunication_List_Missing_CustomersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

export type Subscription_RootCommunication_List_Missing_Customers_AggregateArgs =
  {
    distinct_on?: InputMaybe<Array<Customer_Select_Column>>
    limit?: InputMaybe<Scalars['Int']['input']>
    offset?: InputMaybe<Scalars['Int']['input']>
    order_by?: InputMaybe<Array<Customer_Order_By>>
    where?: InputMaybe<Customer_Bool_Exp>
  }

export type Subscription_RootCommunication_List_Missing_Customers_By_CodeArgs =
  {
    args: Communication_List_Missing_Customers_By_Code_Args
    distinct_on?: InputMaybe<Array<Customer_Select_Column>>
    limit?: InputMaybe<Scalars['Int']['input']>
    offset?: InputMaybe<Scalars['Int']['input']>
    order_by?: InputMaybe<Array<Customer_Order_By>>
    where?: InputMaybe<Customer_Bool_Exp>
  }

export type Subscription_RootCommunication_List_Missing_Customers_By_Code_AggregateArgs =
  {
    args: Communication_List_Missing_Customers_By_Code_Args
    distinct_on?: InputMaybe<Array<Customer_Select_Column>>
    limit?: InputMaybe<Scalars['Int']['input']>
    offset?: InputMaybe<Scalars['Int']['input']>
    order_by?: InputMaybe<Array<Customer_Order_By>>
    where?: InputMaybe<Customer_Bool_Exp>
  }

export type Subscription_RootCommunication_List_OrphansArgs = {
  distinct_on?: InputMaybe<Array<Communication_List_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_List_Order_By>>
  where?: InputMaybe<Communication_List_Bool_Exp>
}

export type Subscription_RootCommunication_List_Orphans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_List_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_List_Order_By>>
  where?: InputMaybe<Communication_List_Bool_Exp>
}

export type Subscription_RootCommunication_List_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Communication_List_Stream_Cursor_Input>>
  where?: InputMaybe<Communication_List_Bool_Exp>
}

export type Subscription_RootCommunication_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Communication_Settings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_Settings_Order_By>>
  where?: InputMaybe<Communication_Settings_Bool_Exp>
}

export type Subscription_RootCommunication_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_Settings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Communication_Settings_Order_By>>
  where?: InputMaybe<Communication_Settings_Bool_Exp>
}

export type Subscription_RootCommunication_Settings_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootCommunication_Settings_Missing_CustomersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

export type Subscription_RootCommunication_Settings_Missing_Customers_AggregateArgs =
  {
    distinct_on?: InputMaybe<Array<Customer_Select_Column>>
    limit?: InputMaybe<Scalars['Int']['input']>
    offset?: InputMaybe<Scalars['Int']['input']>
    order_by?: InputMaybe<Array<Customer_Order_By>>
    where?: InputMaybe<Customer_Bool_Exp>
  }

export type Subscription_RootCommunication_Settings_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Communication_Settings_Stream_Cursor_Input>>
  where?: InputMaybe<Communication_Settings_Bool_Exp>
}

export type Subscription_RootCommunication_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Communication_Stream_Cursor_Input>>
  where?: InputMaybe<Communication_Bool_Exp>
}

export type Subscription_RootCountry_RegionArgs = {
  distinct_on?: InputMaybe<Array<Country_Region_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Country_Region_Order_By>>
  where?: InputMaybe<Country_Region_Bool_Exp>
}

export type Subscription_RootCountry_Region_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Country_Region_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Country_Region_Order_By>>
  where?: InputMaybe<Country_Region_Bool_Exp>
}

export type Subscription_RootCountry_Region_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootCountry_Region_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Country_Region_Stream_Cursor_Input>>
  where?: InputMaybe<Country_Region_Bool_Exp>
}

export type Subscription_RootCountyArgs = {
  distinct_on?: InputMaybe<Array<County_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<County_Order_By>>
  where?: InputMaybe<County_Bool_Exp>
}

export type Subscription_RootCounty_AggregateArgs = {
  distinct_on?: InputMaybe<Array<County_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<County_Order_By>>
  where?: InputMaybe<County_Bool_Exp>
}

export type Subscription_RootCounty_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootCounty_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<County_Stream_Cursor_Input>>
  where?: InputMaybe<County_Bool_Exp>
}

export type Subscription_RootCredit_MemoArgs = {
  distinct_on?: InputMaybe<Array<Credit_Memo_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Credit_Memo_Order_By>>
  where?: InputMaybe<Credit_Memo_Bool_Exp>
}

export type Subscription_RootCredit_Memo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Memo_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Credit_Memo_Order_By>>
  where?: InputMaybe<Credit_Memo_Bool_Exp>
}

export type Subscription_RootCredit_Memo_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootCredit_Memo_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Credit_Memo_Stream_Cursor_Input>>
  where?: InputMaybe<Credit_Memo_Bool_Exp>
}

export type Subscription_RootCronArgs = {
  distinct_on?: InputMaybe<Array<Cron_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Cron_Order_By>>
  where?: InputMaybe<Cron_Bool_Exp>
}

export type Subscription_RootCron_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cron_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Cron_Order_By>>
  where?: InputMaybe<Cron_Bool_Exp>
}

export type Subscription_RootCron_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootCron_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Cron_Stream_Cursor_Input>>
  where?: InputMaybe<Cron_Bool_Exp>
}

export type Subscription_RootCustomerArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

export type Subscription_RootCustomer_Additional_DataArgs = {
  distinct_on?: InputMaybe<Array<Customer_Additional_Data_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Additional_Data_Order_By>>
  where?: InputMaybe<Customer_Additional_Data_Bool_Exp>
}

export type Subscription_RootCustomer_Additional_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Additional_Data_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Additional_Data_Order_By>>
  where?: InputMaybe<Customer_Additional_Data_Bool_Exp>
}

export type Subscription_RootCustomer_Additional_Data_By_PkArgs = {
  customer_erp_id: Scalars['String']['input']
}

export type Subscription_RootCustomer_Additional_Data_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Customer_Additional_Data_Stream_Cursor_Input>>
  where?: InputMaybe<Customer_Additional_Data_Bool_Exp>
}

export type Subscription_RootCustomer_AddressArgs = {
  distinct_on?: InputMaybe<Array<Customer_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Address_Order_By>>
  where?: InputMaybe<Customer_Address_Bool_Exp>
}

export type Subscription_RootCustomer_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Address_Order_By>>
  where?: InputMaybe<Customer_Address_Bool_Exp>
}

export type Subscription_RootCustomer_Address_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootCustomer_Address_DropshippingArgs = {
  distinct_on?: InputMaybe<Array<Customer_Address_Dropshipping_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Address_Dropshipping_Order_By>>
  where?: InputMaybe<Customer_Address_Dropshipping_Bool_Exp>
}

export type Subscription_RootCustomer_Address_Dropshipping_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Address_Dropshipping_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Address_Dropshipping_Order_By>>
  where?: InputMaybe<Customer_Address_Dropshipping_Bool_Exp>
}

export type Subscription_RootCustomer_Address_Dropshipping_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootCustomer_Address_Dropshipping_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Customer_Address_Dropshipping_Stream_Cursor_Input>>
  where?: InputMaybe<Customer_Address_Dropshipping_Bool_Exp>
}

export type Subscription_RootCustomer_Address_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Customer_Address_Stream_Cursor_Input>>
  where?: InputMaybe<Customer_Address_Bool_Exp>
}

export type Subscription_RootCustomer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Order_By>>
  where?: InputMaybe<Customer_Bool_Exp>
}

export type Subscription_RootCustomer_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootCustomer_DiscountArgs = {
  distinct_on?: InputMaybe<Array<Customer_Discount_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Discount_Order_By>>
  where?: InputMaybe<Customer_Discount_Bool_Exp>
}

export type Subscription_RootCustomer_Discount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Discount_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Customer_Discount_Order_By>>
  where?: InputMaybe<Customer_Discount_Bool_Exp>
}

export type Subscription_RootCustomer_Discount_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootCustomer_Discount_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Customer_Discount_Stream_Cursor_Input>>
  where?: InputMaybe<Customer_Discount_Bool_Exp>
}

export type Subscription_RootCustomer_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Customer_Stream_Cursor_Input>>
  where?: InputMaybe<Customer_Bool_Exp>
}

export type Subscription_RootDownloadArgs = {
  distinct_on?: InputMaybe<Array<Download_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Download_Order_By>>
  where?: InputMaybe<Download_Bool_Exp>
}

export type Subscription_RootDownload_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Download_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Download_Order_By>>
  where?: InputMaybe<Download_Bool_Exp>
}

export type Subscription_RootDownload_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootDownload_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Download_Stream_Cursor_Input>>
  where?: InputMaybe<Download_Bool_Exp>
}

export type Subscription_RootExchange_RateArgs = {
  distinct_on?: InputMaybe<Array<Exchange_Rate_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Exchange_Rate_Order_By>>
  where?: InputMaybe<Exchange_Rate_Bool_Exp>
}

export type Subscription_RootExchange_Rate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exchange_Rate_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Exchange_Rate_Order_By>>
  where?: InputMaybe<Exchange_Rate_Bool_Exp>
}

export type Subscription_RootExchange_Rate_By_PkArgs = {
  currency: Scalars['String']['input']
}

export type Subscription_RootExchange_Rate_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Exchange_Rate_Stream_Cursor_Input>>
  where?: InputMaybe<Exchange_Rate_Bool_Exp>
}

export type Subscription_RootFileArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<File_Order_By>>
  where?: InputMaybe<File_Bool_Exp>
}

export type Subscription_RootFile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<File_Order_By>>
  where?: InputMaybe<File_Bool_Exp>
}

export type Subscription_RootFile_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootFile_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<File_Stream_Cursor_Input>>
  where?: InputMaybe<File_Bool_Exp>
}

export type Subscription_RootInvoiceArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Invoice_Order_By>>
  where?: InputMaybe<Invoice_Bool_Exp>
}

export type Subscription_RootInvoice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Invoice_Order_By>>
  where?: InputMaybe<Invoice_Bool_Exp>
}

export type Subscription_RootInvoice_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootInvoice_DiscountArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Discount_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Invoice_Discount_Order_By>>
  where?: InputMaybe<Invoice_Discount_Bool_Exp>
}

export type Subscription_RootInvoice_Discount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Discount_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Invoice_Discount_Order_By>>
  where?: InputMaybe<Invoice_Discount_Bool_Exp>
}

export type Subscription_RootInvoice_Discount_By_PkArgs = {
  code: Scalars['String']['input']
}

export type Subscription_RootInvoice_Discount_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Invoice_Discount_Stream_Cursor_Input>>
  where?: InputMaybe<Invoice_Discount_Bool_Exp>
}

export type Subscription_RootInvoice_OverdueArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Overdue_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Invoice_Overdue_Order_By>>
  where?: InputMaybe<Invoice_Overdue_Bool_Exp>
}

export type Subscription_RootInvoice_Overdue_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Overdue_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Invoice_Overdue_Order_By>>
  where?: InputMaybe<Invoice_Overdue_Bool_Exp>
}

export type Subscription_RootInvoice_Overdue_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootInvoice_Overdue_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Invoice_Overdue_Stream_Cursor_Input>>
  where?: InputMaybe<Invoice_Overdue_Bool_Exp>
}

export type Subscription_RootInvoice_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Invoice_Stream_Cursor_Input>>
  where?: InputMaybe<Invoice_Bool_Exp>
}

export type Subscription_RootInvoice_ViewArgs = {
  distinct_on?: InputMaybe<Array<Invoice_View_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Invoice_View_Order_By>>
  where?: InputMaybe<Invoice_View_Bool_Exp>
}

export type Subscription_RootInvoice_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_View_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Invoice_View_Order_By>>
  where?: InputMaybe<Invoice_View_Bool_Exp>
}

export type Subscription_RootInvoice_View_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Invoice_View_Stream_Cursor_Input>>
  where?: InputMaybe<Invoice_View_Bool_Exp>
}

export type Subscription_RootMailArgs = {
  distinct_on?: InputMaybe<Array<Mail_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Mail_Order_By>>
  where?: InputMaybe<Mail_Bool_Exp>
}

export type Subscription_RootMail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Mail_Order_By>>
  where?: InputMaybe<Mail_Bool_Exp>
}

export type Subscription_RootMail_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootMail_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Mail_Stream_Cursor_Input>>
  where?: InputMaybe<Mail_Bool_Exp>
}

export type Subscription_RootOrderArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Order_By>>
  where?: InputMaybe<Order_Bool_Exp>
}

export type Subscription_RootOrder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Order_By>>
  where?: InputMaybe<Order_Bool_Exp>
}

export type Subscription_RootOrder_By_EmailArgs = {
  distinct_on?: InputMaybe<Array<Order_By_Email_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_By_Email_Order_By>>
  where?: InputMaybe<Order_By_Email_Bool_Exp>
}

export type Subscription_RootOrder_By_Email_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_By_Email_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_By_Email_Order_By>>
  where?: InputMaybe<Order_By_Email_Bool_Exp>
}

export type Subscription_RootOrder_By_Email_By_PkArgs = {
  id: Scalars['Int']['input']
}

export type Subscription_RootOrder_By_Email_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Order_By_Email_Stream_Cursor_Input>>
  where?: InputMaybe<Order_By_Email_Bool_Exp>
}

export type Subscription_RootOrder_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootOrder_ConfirmedArgs = {
  distinct_on?: InputMaybe<Array<Order_Confirmed_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Confirmed_Order_By>>
  where?: InputMaybe<Order_Confirmed_Bool_Exp>
}

export type Subscription_RootOrder_Confirmed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Confirmed_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Confirmed_Order_By>>
  where?: InputMaybe<Order_Confirmed_Bool_Exp>
}

export type Subscription_RootOrder_Confirmed_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootOrder_Confirmed_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Order_Confirmed_Stream_Cursor_Input>>
  where?: InputMaybe<Order_Confirmed_Bool_Exp>
}

export type Subscription_RootOrder_InvoiceArgs = {
  distinct_on?: InputMaybe<Array<Order_Invoice_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Invoice_Order_By>>
  where?: InputMaybe<Order_Invoice_Bool_Exp>
}

export type Subscription_RootOrder_Invoice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Invoice_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Invoice_Order_By>>
  where?: InputMaybe<Order_Invoice_Bool_Exp>
}

export type Subscription_RootOrder_Invoice_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootOrder_Invoice_ItemArgs = {
  distinct_on?: InputMaybe<Array<Order_Invoice_Item_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Invoice_Item_Order_By>>
  where?: InputMaybe<Order_Invoice_Item_Bool_Exp>
}

export type Subscription_RootOrder_Invoice_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Invoice_Item_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Invoice_Item_Order_By>>
  where?: InputMaybe<Order_Invoice_Item_Bool_Exp>
}

export type Subscription_RootOrder_Invoice_Item_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootOrder_Invoice_Item_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Order_Invoice_Item_Stream_Cursor_Input>>
  where?: InputMaybe<Order_Invoice_Item_Bool_Exp>
}

export type Subscription_RootOrder_Invoice_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Order_Invoice_Stream_Cursor_Input>>
  where?: InputMaybe<Order_Invoice_Bool_Exp>
}

export type Subscription_RootOrder_Item_UnshippedArgs = {
  distinct_on?: InputMaybe<Array<Order_Item_Unshipped_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Item_Unshipped_Order_By>>
  where?: InputMaybe<Order_Item_Unshipped_Bool_Exp>
}

export type Subscription_RootOrder_Item_Unshipped_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Item_Unshipped_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Item_Unshipped_Order_By>>
  where?: InputMaybe<Order_Item_Unshipped_Bool_Exp>
}

export type Subscription_RootOrder_Item_Unshipped_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootOrder_Item_Unshipped_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Order_Item_Unshipped_Stream_Cursor_Input>>
  where?: InputMaybe<Order_Item_Unshipped_Bool_Exp>
}

export type Subscription_RootOrder_ProductArgs = {
  distinct_on?: InputMaybe<Array<Order_Product_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Product_Order_By>>
  where?: InputMaybe<Order_Product_Bool_Exp>
}

export type Subscription_RootOrder_Product_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Product_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Product_Order_By>>
  where?: InputMaybe<Order_Product_Bool_Exp>
}

export type Subscription_RootOrder_Product_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootOrder_Product_ItemArgs = {
  distinct_on?: InputMaybe<Array<Order_Product_Item_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Product_Item_Order_By>>
  where?: InputMaybe<Order_Product_Item_Bool_Exp>
}

export type Subscription_RootOrder_Product_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Product_Item_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Product_Item_Order_By>>
  where?: InputMaybe<Order_Product_Item_Bool_Exp>
}

export type Subscription_RootOrder_Product_Item_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootOrder_Product_Item_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Order_Product_Item_Stream_Cursor_Input>>
  where?: InputMaybe<Order_Product_Item_Bool_Exp>
}

export type Subscription_RootOrder_Product_ReplacementArgs = {
  distinct_on?: InputMaybe<Array<Order_Product_Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Product_Replacement_Order_By>>
  where?: InputMaybe<Order_Product_Replacement_Bool_Exp>
}

export type Subscription_RootOrder_Product_Replacement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Product_Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Product_Replacement_Order_By>>
  where?: InputMaybe<Order_Product_Replacement_Bool_Exp>
}

export type Subscription_RootOrder_Product_Replacement_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootOrder_Product_Replacement_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Order_Product_Replacement_Stream_Cursor_Input>>
  where?: InputMaybe<Order_Product_Replacement_Bool_Exp>
}

export type Subscription_RootOrder_Product_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Order_Product_Stream_Cursor_Input>>
  where?: InputMaybe<Order_Product_Bool_Exp>
}

export type Subscription_RootOrder_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Order_Stream_Cursor_Input>>
  where?: InputMaybe<Order_Bool_Exp>
}

export type Subscription_RootOrder_TransactionArgs = {
  distinct_on?: InputMaybe<Array<Order_Transaction_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Transaction_Order_By>>
  where?: InputMaybe<Order_Transaction_Bool_Exp>
}

export type Subscription_RootOrder_Transaction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Transaction_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Transaction_Order_By>>
  where?: InputMaybe<Order_Transaction_Bool_Exp>
}

export type Subscription_RootOrder_Transaction_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootOrder_Transaction_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Order_Transaction_Stream_Cursor_Input>>
  where?: InputMaybe<Order_Transaction_Bool_Exp>
}

export type Subscription_RootPayment_ConditionArgs = {
  distinct_on?: InputMaybe<Array<Payment_Condition_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Condition_Order_By>>
  where?: InputMaybe<Payment_Condition_Bool_Exp>
}

export type Subscription_RootPayment_Condition_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Condition_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Condition_Order_By>>
  where?: InputMaybe<Payment_Condition_Bool_Exp>
}

export type Subscription_RootPayment_Condition_By_PkArgs = {
  code: Scalars['String']['input']
}

export type Subscription_RootPayment_Condition_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Payment_Condition_Stream_Cursor_Input>>
  where?: InputMaybe<Payment_Condition_Bool_Exp>
}

export type Subscription_RootPayment_MethodArgs = {
  distinct_on?: InputMaybe<Array<Payment_Method_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Method_Order_By>>
  where?: InputMaybe<Payment_Method_Bool_Exp>
}

export type Subscription_RootPayment_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Method_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Method_Order_By>>
  where?: InputMaybe<Payment_Method_Bool_Exp>
}

export type Subscription_RootPayment_Method_By_PkArgs = {
  code: Scalars['String']['input']
}

export type Subscription_RootPayment_Method_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Payment_Method_Stream_Cursor_Input>>
  where?: InputMaybe<Payment_Method_Bool_Exp>
}

export type Subscription_RootPost_CodeArgs = {
  distinct_on?: InputMaybe<Array<Post_Code_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Post_Code_Order_By>>
  where?: InputMaybe<Post_Code_Bool_Exp>
}

export type Subscription_RootPost_Code_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Code_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Post_Code_Order_By>>
  where?: InputMaybe<Post_Code_Bool_Exp>
}

export type Subscription_RootPost_Code_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootPost_Code_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Post_Code_Stream_Cursor_Input>>
  where?: InputMaybe<Post_Code_Bool_Exp>
}

export type Subscription_RootProductArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Order_By>>
  where?: InputMaybe<Product_Bool_Exp>
}

export type Subscription_RootProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Order_By>>
  where?: InputMaybe<Product_Bool_Exp>
}

export type Subscription_RootProduct_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootProduct_Discount_GroupArgs = {
  distinct_on?: InputMaybe<Array<Product_Discount_Group_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Discount_Group_Order_By>>
  where?: InputMaybe<Product_Discount_Group_Bool_Exp>
}

export type Subscription_RootProduct_Discount_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Discount_Group_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Discount_Group_Order_By>>
  where?: InputMaybe<Product_Discount_Group_Bool_Exp>
}

export type Subscription_RootProduct_Discount_Group_By_PkArgs = {
  code: Scalars['String']['input']
}

export type Subscription_RootProduct_Discount_Group_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Product_Discount_Group_Stream_Cursor_Input>>
  where?: InputMaybe<Product_Discount_Group_Bool_Exp>
}

export type Subscription_RootProduct_FileArgs = {
  distinct_on?: InputMaybe<Array<Product_File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_File_Order_By>>
  where?: InputMaybe<Product_File_Bool_Exp>
}

export type Subscription_RootProduct_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_File_Order_By>>
  where?: InputMaybe<Product_File_Bool_Exp>
}

export type Subscription_RootProduct_File_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootProduct_File_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Product_File_Stream_Cursor_Input>>
  where?: InputMaybe<Product_File_Bool_Exp>
}

export type Subscription_RootProduct_PriceArgs = {
  distinct_on?: InputMaybe<Array<Product_Price_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Price_Order_By>>
  where?: InputMaybe<Product_Price_Bool_Exp>
}

export type Subscription_RootProduct_Price_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Price_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Price_Order_By>>
  where?: InputMaybe<Product_Price_Bool_Exp>
}

export type Subscription_RootProduct_Price_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootProduct_Price_ListArgs = {
  distinct_on?: InputMaybe<Array<Product_Price_List_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Price_List_Order_By>>
  where?: InputMaybe<Product_Price_List_Bool_Exp>
}

export type Subscription_RootProduct_Price_List_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Price_List_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Price_List_Order_By>>
  where?: InputMaybe<Product_Price_List_Bool_Exp>
}

export type Subscription_RootProduct_Price_List_By_PkArgs = {
  code: Scalars['String']['input']
}

export type Subscription_RootProduct_Price_List_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Product_Price_List_Stream_Cursor_Input>>
  where?: InputMaybe<Product_Price_List_Bool_Exp>
}

export type Subscription_RootProduct_Price_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Product_Price_Stream_Cursor_Input>>
  where?: InputMaybe<Product_Price_Bool_Exp>
}

export type Subscription_RootProduct_Price_ViewArgs = {
  distinct_on?: InputMaybe<Array<Product_Price_View_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Price_View_Order_By>>
  where?: InputMaybe<Product_Price_View_Bool_Exp>
}

export type Subscription_RootProduct_Price_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Price_View_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Price_View_Order_By>>
  where?: InputMaybe<Product_Price_View_Bool_Exp>
}

export type Subscription_RootProduct_Price_View_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Product_Price_View_Stream_Cursor_Input>>
  where?: InputMaybe<Product_Price_View_Bool_Exp>
}

export type Subscription_RootProduct_ReplacementArgs = {
  distinct_on?: InputMaybe<Array<Product_Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Replacement_Order_By>>
  where?: InputMaybe<Product_Replacement_Bool_Exp>
}

export type Subscription_RootProduct_Replacement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Replacement_Order_By>>
  where?: InputMaybe<Product_Replacement_Bool_Exp>
}

export type Subscription_RootProduct_Replacement_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootProduct_Replacement_MapArgs = {
  distinct_on?: InputMaybe<Array<Product_Replacement_Map_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Replacement_Map_Order_By>>
  where?: InputMaybe<Product_Replacement_Map_Bool_Exp>
}

export type Subscription_RootProduct_Replacement_Map_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Replacement_Map_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Product_Replacement_Map_Order_By>>
  where?: InputMaybe<Product_Replacement_Map_Bool_Exp>
}

export type Subscription_RootProduct_Replacement_Map_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootProduct_Replacement_Map_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Product_Replacement_Map_Stream_Cursor_Input>>
  where?: InputMaybe<Product_Replacement_Map_Bool_Exp>
}

export type Subscription_RootProduct_Replacement_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Product_Replacement_Stream_Cursor_Input>>
  where?: InputMaybe<Product_Replacement_Bool_Exp>
}

export type Subscription_RootProduct_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Product_Stream_Cursor_Input>>
  where?: InputMaybe<Product_Bool_Exp>
}

export type Subscription_RootReplacementArgs = {
  distinct_on?: InputMaybe<Array<Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Replacement_Order_By>>
  where?: InputMaybe<Replacement_Bool_Exp>
}

export type Subscription_RootReplacement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Replacement_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Replacement_Order_By>>
  where?: InputMaybe<Replacement_Bool_Exp>
}

export type Subscription_RootReplacement_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootReplacement_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Replacement_Stream_Cursor_Input>>
  where?: InputMaybe<Replacement_Bool_Exp>
}

export type Subscription_RootReportArgs = {
  distinct_on?: InputMaybe<Array<Report_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Report_Order_By>>
  where?: InputMaybe<Report_Bool_Exp>
}

export type Subscription_RootReport_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Report_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Report_Order_By>>
  where?: InputMaybe<Report_Bool_Exp>
}

export type Subscription_RootReport_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootReport_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Report_Stream_Cursor_Input>>
  where?: InputMaybe<Report_Bool_Exp>
}

export type Subscription_RootRoleArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Role_Order_By>>
  where?: InputMaybe<Role_Bool_Exp>
}

export type Subscription_RootRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Role_Order_By>>
  where?: InputMaybe<Role_Bool_Exp>
}

export type Subscription_RootRole_By_PkArgs = {
  name: Scalars['String']['input']
}

export type Subscription_RootRole_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Role_Stream_Cursor_Input>>
  where?: InputMaybe<Role_Bool_Exp>
}

export type Subscription_RootSettingArgs = {
  distinct_on?: InputMaybe<Array<Setting_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Setting_Order_By>>
  where?: InputMaybe<Setting_Bool_Exp>
}

export type Subscription_RootSetting_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Setting_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Setting_Order_By>>
  where?: InputMaybe<Setting_Bool_Exp>
}

export type Subscription_RootSetting_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootSetting_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Setting_Stream_Cursor_Input>>
  where?: InputMaybe<Setting_Bool_Exp>
}

export type Subscription_RootShipment_DocumentArgs = {
  distinct_on?: InputMaybe<Array<Shipment_Document_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Shipment_Document_Order_By>>
  where?: InputMaybe<Shipment_Document_Bool_Exp>
}

export type Subscription_RootShipment_Document_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shipment_Document_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Shipment_Document_Order_By>>
  where?: InputMaybe<Shipment_Document_Bool_Exp>
}

export type Subscription_RootShipment_Document_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootShipment_Document_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Shipment_Document_Stream_Cursor_Input>>
  where?: InputMaybe<Shipment_Document_Bool_Exp>
}

export type Subscription_RootShipping_MethodArgs = {
  distinct_on?: InputMaybe<Array<Shipping_Method_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Shipping_Method_Order_By>>
  where?: InputMaybe<Shipping_Method_Bool_Exp>
}

export type Subscription_RootShipping_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shipping_Method_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Shipping_Method_Order_By>>
  where?: InputMaybe<Shipping_Method_Bool_Exp>
}

export type Subscription_RootShipping_Method_By_PkArgs = {
  code: Scalars['String']['input']
}

export type Subscription_RootShipping_Method_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Shipping_Method_Stream_Cursor_Input>>
  where?: InputMaybe<Shipping_Method_Bool_Exp>
}

export type Subscription_RootShipping_RateArgs = {
  distinct_on?: InputMaybe<Array<Shipping_Rate_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Shipping_Rate_Order_By>>
  where?: InputMaybe<Shipping_Rate_Bool_Exp>
}

export type Subscription_RootShipping_Rate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shipping_Rate_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Shipping_Rate_Order_By>>
  where?: InputMaybe<Shipping_Rate_Bool_Exp>
}

export type Subscription_RootShipping_Rate_By_PkArgs = {
  code: Scalars['String']['input']
}

export type Subscription_RootShipping_Rate_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Shipping_Rate_Stream_Cursor_Input>>
  where?: InputMaybe<Shipping_Rate_Bool_Exp>
}

export type Subscription_RootSubcustomerArgs = {
  distinct_on?: InputMaybe<Array<Subcustomer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Subcustomer_Order_By>>
  where?: InputMaybe<Subcustomer_Bool_Exp>
}

export type Subscription_RootSubcustomer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subcustomer_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Subcustomer_Order_By>>
  where?: InputMaybe<Subcustomer_Bool_Exp>
}

export type Subscription_RootSubcustomer_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootSubcustomer_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Subcustomer_Stream_Cursor_Input>>
  where?: InputMaybe<Subcustomer_Bool_Exp>
}

export type Subscription_RootTerritoryArgs = {
  distinct_on?: InputMaybe<Array<Territory_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Territory_Order_By>>
  where?: InputMaybe<Territory_Bool_Exp>
}

export type Subscription_RootTerritory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Territory_Order_By>>
  where?: InputMaybe<Territory_Bool_Exp>
}

export type Subscription_RootTerritory_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootTerritory_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Territory_Stream_Cursor_Input>>
  where?: InputMaybe<Territory_Bool_Exp>
}

export type Subscription_RootTransactionArgs = {
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Transaction_Order_By>>
  where?: InputMaybe<Transaction_Bool_Exp>
}

export type Subscription_RootTransaction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Transaction_Order_By>>
  where?: InputMaybe<Transaction_Bool_Exp>
}

export type Subscription_RootTransaction_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootTransaction_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Transaction_Stream_Cursor_Input>>
  where?: InputMaybe<Transaction_Bool_Exp>
}

export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>
  where?: InputMaybe<User_Bool_Exp>
}

/** columns and relationships of "territory" */
export type Territory = {
  __typename?: 'territory'
  code: Scalars['String']['output']
  created_at: Scalars['timestamptz']['output']
  description: Scalars['String']['output']
  id: Scalars['uuid']['output']
  /** An array relationship */
  post_codes: Array<Post_Code>
  /** An aggregate relationship */
  post_codes_aggregate: Post_Code_Aggregate
  rev: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "territory" */
export type TerritoryPost_CodesArgs = {
  distinct_on?: InputMaybe<Array<Post_Code_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Post_Code_Order_By>>
  where?: InputMaybe<Post_Code_Bool_Exp>
}

/** columns and relationships of "territory" */
export type TerritoryPost_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Code_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Post_Code_Order_By>>
  where?: InputMaybe<Post_Code_Bool_Exp>
}

/** aggregated selection of "territory" */
export type Territory_Aggregate = {
  __typename?: 'territory_aggregate'
  aggregate?: Maybe<Territory_Aggregate_Fields>
  nodes: Array<Territory>
}

/** aggregate fields of "territory" */
export type Territory_Aggregate_Fields = {
  __typename?: 'territory_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Territory_Max_Fields>
  min?: Maybe<Territory_Min_Fields>
}

/** aggregate fields of "territory" */
export type Territory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Territory_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "territory". All fields are combined with a logical 'AND'. */
export type Territory_Bool_Exp = {
  _and?: InputMaybe<Array<Territory_Bool_Exp>>
  _not?: InputMaybe<Territory_Bool_Exp>
  _or?: InputMaybe<Array<Territory_Bool_Exp>>
  code?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  post_codes?: InputMaybe<Post_Code_Bool_Exp>
  post_codes_aggregate?: InputMaybe<Post_Code_Aggregate_Bool_Exp>
  rev?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "territory" */
export enum Territory_Constraint {
  /** unique or primary key constraint on columns "code" */
  TerritoryCodeKey = 'territory_code_key',
  /** unique or primary key constraint on columns "id" */
  TerritoryPkey = 'territory_pkey',
}

/** input type for inserting data into table "territory" */
export type Territory_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  post_codes?: InputMaybe<Post_Code_Arr_Rel_Insert_Input>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Territory_Max_Fields = {
  __typename?: 'territory_max_fields'
  code?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** aggregate min on columns */
export type Territory_Min_Fields = {
  __typename?: 'territory_min_fields'
  code?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  rev?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** response of any mutation on the table "territory" */
export type Territory_Mutation_Response = {
  __typename?: 'territory_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Territory>
}

/** input type for inserting object relation for remote table "territory" */
export type Territory_Obj_Rel_Insert_Input = {
  data: Territory_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Territory_On_Conflict>
}

/** on_conflict condition type for table "territory" */
export type Territory_On_Conflict = {
  constraint: Territory_Constraint
  update_columns?: Array<Territory_Update_Column>
  where?: InputMaybe<Territory_Bool_Exp>
}

/** Ordering options when selecting data from "territory". */
export type Territory_Order_By = {
  code?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  post_codes_aggregate?: InputMaybe<Post_Code_Aggregate_Order_By>
  rev?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: territory */
export type Territory_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "territory" */
export enum Territory_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "territory" */
export type Territory_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "territory" */
export type Territory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Territory_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Territory_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  rev?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "territory" */
export enum Territory_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Rev = 'rev',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Territory_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Territory_Set_Input>
  /** filter the rows which have to be updated */
  where: Territory_Bool_Exp
}

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']['input']>
  _gt?: InputMaybe<Scalars['timestamp']['input']>
  _gte?: InputMaybe<Scalars['timestamp']['input']>
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['timestamp']['input']>
  _lte?: InputMaybe<Scalars['timestamp']['input']>
  _neq?: InputMaybe<Scalars['timestamp']['input']>
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>
  _gt?: InputMaybe<Scalars['timestamptz']['input']>
  _gte?: InputMaybe<Scalars['timestamptz']['input']>
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['timestamptz']['input']>
  _lte?: InputMaybe<Scalars['timestamptz']['input']>
  _neq?: InputMaybe<Scalars['timestamptz']['input']>
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>
}

/** columns and relationships of "transaction" */
export type Transaction = {
  __typename?: 'transaction'
  additional_info?: Maybe<Scalars['jsonb']['output']>
  created_at: Scalars['timestamptz']['output']
  /** An object relationship */
  customer?: Maybe<Customer>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  id: Scalars['uuid']['output']
  method: Scalars['String']['output']
  /** An array relationship */
  order_transactions: Array<Order_Transaction>
  /** An aggregate relationship */
  order_transactions_aggregate: Order_Transaction_Aggregate
  parent_transaction_id?: Maybe<Scalars['String']['output']>
  provider: Scalars['String']['output']
  status: Scalars['String']['output']
  transaction_id: Scalars['String']['output']
  type: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "transaction" */
export type TransactionAdditional_InfoArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** columns and relationships of "transaction" */
export type TransactionOrder_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Order_Transaction_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Transaction_Order_By>>
  where?: InputMaybe<Order_Transaction_Bool_Exp>
}

/** columns and relationships of "transaction" */
export type TransactionOrder_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Transaction_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Order_Transaction_Order_By>>
  where?: InputMaybe<Order_Transaction_Bool_Exp>
}

/** aggregated selection of "transaction" */
export type Transaction_Aggregate = {
  __typename?: 'transaction_aggregate'
  aggregate?: Maybe<Transaction_Aggregate_Fields>
  nodes: Array<Transaction>
}

export type Transaction_Aggregate_Bool_Exp = {
  count?: InputMaybe<Transaction_Aggregate_Bool_Exp_Count>
}

export type Transaction_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Transaction_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Transaction_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "transaction" */
export type Transaction_Aggregate_Fields = {
  __typename?: 'transaction_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Transaction_Max_Fields>
  min?: Maybe<Transaction_Min_Fields>
}

/** aggregate fields of "transaction" */
export type Transaction_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Transaction_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "transaction" */
export type Transaction_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Transaction_Max_Order_By>
  min?: InputMaybe<Transaction_Min_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Transaction_Append_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
}

/** input type for inserting array relation for remote table "transaction" */
export type Transaction_Arr_Rel_Insert_Input = {
  data: Array<Transaction_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Transaction_On_Conflict>
}

/** Boolean expression to filter rows from the table "transaction". All fields are combined with a logical 'AND'. */
export type Transaction_Bool_Exp = {
  _and?: InputMaybe<Array<Transaction_Bool_Exp>>
  _not?: InputMaybe<Transaction_Bool_Exp>
  _or?: InputMaybe<Array<Transaction_Bool_Exp>>
  additional_info?: InputMaybe<Jsonb_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customer?: InputMaybe<Customer_Bool_Exp>
  customer_erp_id?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  method?: InputMaybe<String_Comparison_Exp>
  order_transactions?: InputMaybe<Order_Transaction_Bool_Exp>
  order_transactions_aggregate?: InputMaybe<Order_Transaction_Aggregate_Bool_Exp>
  parent_transaction_id?: InputMaybe<String_Comparison_Exp>
  provider?: InputMaybe<String_Comparison_Exp>
  status?: InputMaybe<String_Comparison_Exp>
  transaction_id?: InputMaybe<String_Comparison_Exp>
  type?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "transaction" */
export enum Transaction_Constraint {
  /** unique or primary key constraint on columns "id" */
  TransactionPkey = 'transaction_pkey',
  /** unique or primary key constraint on columns "transaction_id" */
  TransactionTransactionIdKey = 'transaction_transaction_id_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Transaction_Delete_At_Path_Input = {
  additional_info?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Transaction_Delete_Elem_Input = {
  additional_info?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Transaction_Delete_Key_Input = {
  additional_info?: InputMaybe<Scalars['String']['input']>
}

/** input type for inserting data into table "transaction" */
export type Transaction_Insert_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  method?: InputMaybe<Scalars['String']['input']>
  order_transactions?: InputMaybe<Order_Transaction_Arr_Rel_Insert_Input>
  parent_transaction_id?: InputMaybe<Scalars['String']['input']>
  provider?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  transaction_id?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Transaction_Max_Fields = {
  __typename?: 'transaction_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  method?: Maybe<Scalars['String']['output']>
  parent_transaction_id?: Maybe<Scalars['String']['output']>
  provider?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
  transaction_id?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "transaction" */
export type Transaction_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  method?: InputMaybe<Order_By>
  parent_transaction_id?: InputMaybe<Order_By>
  provider?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  transaction_id?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Transaction_Min_Fields = {
  __typename?: 'transaction_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  customer_erp_id?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  method?: Maybe<Scalars['String']['output']>
  parent_transaction_id?: Maybe<Scalars['String']['output']>
  provider?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
  transaction_id?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "transaction" */
export type Transaction_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  method?: InputMaybe<Order_By>
  parent_transaction_id?: InputMaybe<Order_By>
  provider?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  transaction_id?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "transaction" */
export type Transaction_Mutation_Response = {
  __typename?: 'transaction_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Transaction>
}

/** input type for inserting object relation for remote table "transaction" */
export type Transaction_Obj_Rel_Insert_Input = {
  data: Transaction_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Transaction_On_Conflict>
}

/** on_conflict condition type for table "transaction" */
export type Transaction_On_Conflict = {
  constraint: Transaction_Constraint
  update_columns?: Array<Transaction_Update_Column>
  where?: InputMaybe<Transaction_Bool_Exp>
}

/** Ordering options when selecting data from "transaction". */
export type Transaction_Order_By = {
  additional_info?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  customer?: InputMaybe<Customer_Order_By>
  customer_erp_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  method?: InputMaybe<Order_By>
  order_transactions_aggregate?: InputMaybe<Order_Transaction_Aggregate_Order_By>
  parent_transaction_id?: InputMaybe<Order_By>
  provider?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  transaction_id?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: transaction */
export type Transaction_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Transaction_Prepend_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
}

/** select columns of table "transaction" */
export enum Transaction_Select_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  Method = 'method',
  /** column name */
  ParentTransactionId = 'parent_transaction_id',
  /** column name */
  Provider = 'provider',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "transaction" */
export type Transaction_Set_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  method?: InputMaybe<Scalars['String']['input']>
  parent_transaction_id?: InputMaybe<Scalars['String']['input']>
  provider?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  transaction_id?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "transaction" */
export type Transaction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Transaction_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Transaction_Stream_Cursor_Value_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer_erp_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  method?: InputMaybe<Scalars['String']['input']>
  parent_transaction_id?: InputMaybe<Scalars['String']['input']>
  provider?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  transaction_id?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "transaction" */
export enum Transaction_Update_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerErpId = 'customer_erp_id',
  /** column name */
  Id = 'id',
  /** column name */
  Method = 'method',
  /** column name */
  ParentTransactionId = 'parent_transaction_id',
  /** column name */
  Provider = 'provider',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Transaction_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Transaction_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Transaction_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Transaction_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Transaction_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Transaction_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Transaction_Set_Input>
  /** filter the rows which have to be updated */
  where: Transaction_Bool_Exp
}

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user'
  /** An object relationship */
  agent?: Maybe<Agent>
  created_at: Scalars['timestamptz']['output']
  /** An object relationship */
  customer?: Maybe<Customer>
  /** An array relationship */
  files: Array<File>
  /** An aggregate relationship */
  files_aggregate: File_Aggregate
  id: Scalars['uuid']['output']
  login: Scalars['String']['output']
  password: Scalars['String']['output']
  role: Role_Enum
  /** An object relationship */
  roleByRole: Role
  /** An object relationship */
  subcustomer?: Maybe<Subcustomer>
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "user" */
export type UserFilesArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<File_Order_By>>
  where?: InputMaybe<File_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<File_Order_By>>
  where?: InputMaybe<File_Bool_Exp>
}

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate'
  aggregate?: Maybe<User_Aggregate_Fields>
  nodes: Array<User>
}

export type User_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Aggregate_Bool_Exp_Count>
}

export type User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<User_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<User_Max_Fields>
  min?: Maybe<User_Min_Fields>
}

/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<User_Max_Order_By>
  min?: InputMaybe<User_Min_Order_By>
}

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>
}

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>
  _not?: InputMaybe<User_Bool_Exp>
  _or?: InputMaybe<Array<User_Bool_Exp>>
  agent?: InputMaybe<Agent_Bool_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  customer?: InputMaybe<Customer_Bool_Exp>
  files?: InputMaybe<File_Bool_Exp>
  files_aggregate?: InputMaybe<File_Aggregate_Bool_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  login?: InputMaybe<String_Comparison_Exp>
  password?: InputMaybe<String_Comparison_Exp>
  role?: InputMaybe<Role_Enum_Comparison_Exp>
  roleByRole?: InputMaybe<Role_Bool_Exp>
  subcustomer?: InputMaybe<Subcustomer_Bool_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "login" */
  UserLoginKey = 'user_login_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey',
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  agent?: InputMaybe<Agent_Obj_Rel_Insert_Input>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>
  files?: InputMaybe<File_Arr_Rel_Insert_Input>
  id?: InputMaybe<Scalars['uuid']['input']>
  login?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<Role_Enum>
  roleByRole?: InputMaybe<Role_Obj_Rel_Insert_Input>
  subcustomer?: InputMaybe<Subcustomer_Obj_Rel_Insert_Input>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  login?: Maybe<Scalars['String']['output']>
  password?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  login?: InputMaybe<Order_By>
  password?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  login?: Maybe<Scalars['String']['output']>
  password?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  login?: InputMaybe<Order_By>
  password?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<User>
}

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>
}

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint
  update_columns?: Array<User_Update_Column>
  where?: InputMaybe<User_Bool_Exp>
}

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  agent?: InputMaybe<Agent_Order_By>
  created_at?: InputMaybe<Order_By>
  customer?: InputMaybe<Customer_Order_By>
  files_aggregate?: InputMaybe<File_Aggregate_Order_By>
  id?: InputMaybe<Order_By>
  login?: InputMaybe<Order_By>
  password?: InputMaybe<Order_By>
  role?: InputMaybe<Order_By>
  roleByRole?: InputMaybe<Role_Order_By>
  subcustomer?: InputMaybe<Subcustomer_Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Login = 'login',
  /** column name */
  Password = 'password',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  login?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<Role_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  login?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<Role_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Login = 'login',
  /** column name */
  Password = 'password',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Bool_Exp
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>
  _gt?: InputMaybe<Scalars['uuid']['input']>
  _gte?: InputMaybe<Scalars['uuid']['input']>
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['uuid']['input']>
  _lte?: InputMaybe<Scalars['uuid']['input']>
  _neq?: InputMaybe<Scalars['uuid']['input']>
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>
}

export type ApiCustomerCreateMutationVariables = Exact<{
  customer: ApiCustomerCreateCustomerInput
}>

export type ApiCustomerCreateMutation = {
  __typename?: 'mutation_root'
  api_customer_create: {
    __typename?: 'ApiCustomerCreateOutput'
    result: boolean
  }
}

export type ApiFileDeleteMutationVariables = Exact<{
  data: FileDeleteDataInput
}>

export type ApiFileDeleteMutation = {
  __typename?: 'mutation_root'
  api_file_delete: { __typename?: 'FileDeleteOutput'; result: boolean }
}

export type ApiFileUploadMutationVariables = Exact<{
  data: FileUploadDataInput
}>

export type ApiFileUploadMutation = {
  __typename?: 'mutation_root'
  api_file_upload: { __typename?: 'FileUploadOutput'; id: string }
}

export type ApiInsertSubcustomerMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>
  login: Scalars['String']['input']
  password: Scalars['String']['input']
  customerErpId: Scalars['String']['input']
}>

export type ApiInsertSubcustomerMutation = {
  __typename?: 'mutation_root'
  api_insert_subcustomer: {
    __typename?: 'InsertSubcustomerOutput'
    id: string
    user_id: string
  }
}

export type ApiInsertUserMutationVariables = Exact<{
  login: Scalars['String']['input']
  password: Scalars['String']['input']
  role: Scalars['String']['input']
}>

export type ApiInsertUserMutation = {
  __typename?: 'mutation_root'
  api_insert_user: { __typename?: 'InsertUserOutput'; id: string }
}

export type ApiLoginMutationVariables = Exact<{
  login: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type ApiLoginMutation = {
  __typename?: 'mutation_root'
  api_login: { __typename?: 'LoginOutput'; accessToken: string }
}

export type ApiMailManagerRetryMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type ApiMailManagerRetryMutation = {
  __typename?: 'mutation_root'
  api_mail_manager_retry: {
    __typename?: 'ApiMailManagerRetryOutput'
    result: boolean
  }
}

export type ApiMailManagerSendMutationVariables = Exact<{
  mail: ApiMailManagerMailInput
}>

export type ApiMailManagerSendMutation = {
  __typename?: 'mutation_root'
  api_mail_manager_send: {
    __typename?: 'ApiMailManagerSendOutput'
    result: boolean
  }
}

export type ApiSalesManagerCancelOrderMutationVariables = Exact<{
  data: ApiSalesManagerCancelOrderDataInput
}>

export type ApiSalesManagerCancelOrderMutation = {
  __typename?: 'mutation_root'
  api_sales_manager_cancel_order: {
    __typename?: 'ApiSalesManagerCancelOrderOutput'
    result: string
  }
}

export type ApiSalesManagerCaptureOrderMutationVariables = Exact<{
  data: ApiSalesManagerCaptureOrderDataInput
}>

export type ApiSalesManagerCaptureOrderMutation = {
  __typename?: 'mutation_root'
  api_sales_manager_capture_order: {
    __typename?: 'ApiSalesManagerCaptureOrderOutput'
    result: boolean
  }
}

export type ApiSalesManagerCartItemRemoveMutationVariables = Exact<{
  data: ApiSalesManagerCartItemRemoveDataInput
}>

export type ApiSalesManagerCartItemRemoveMutation = {
  __typename?: 'mutation_root'
  api_sales_manager_cart_item_remove: {
    __typename?: 'ApiSalesManagerCartItemRemoveOutput'
    result: {
      __typename?: 'ApiSalesManagerCartItemRemoveResult'
      ids: Array<number>
      success: boolean
      payload: string
      cart: string
      totals: string
    }
  }
}

export type ApiSalesManagerCartItemUpdateMutationVariables = Exact<{
  data: ApiSalesManagerCartItemUpdateDataInput
}>

export type ApiSalesManagerCartItemUpdateMutation = {
  __typename?: 'mutation_root'
  api_sales_manager_cart_item_update: {
    __typename?: 'ApiSalesManagerCartItemUpdateOutput'
    result: {
      __typename?: 'ApiSalesManagerCartItemUpdateResult'
      id: number
      item: string
      payload: string
      cart: string
      totals: string
    }
  }
}

export type ApiSalesManagerCartReplacementRemoveMutationVariables = Exact<{
  data: ApiSalesManagerCartReplacementRemoveDataInput
}>

export type ApiSalesManagerCartReplacementRemoveMutation = {
  __typename?: 'mutation_root'
  api_sales_manager_cart_replacement_remove: {
    __typename?: 'ApiSalesManagerCartReplacementRemoveOutput'
    result: {
      __typename?: 'ApiSalesManagerCartReplacementRemoveResult'
      ids: Array<number>
      success: boolean
      payload: string
      cart: string
      totals: string
    }
  }
}

export type ApiSalesManagerCartReplacementUpdateMutationVariables = Exact<{
  data: ApiSalesManagerCartReplacementUpdateDataInput
}>

export type ApiSalesManagerCartReplacementUpdateMutation = {
  __typename?: 'mutation_root'
  api_sales_manager_cart_replacement_update: {
    __typename?: 'ApiSalesManagerCartReplacementUpdateOutput'
    result: {
      __typename?: 'ApiSalesManagerCartReplacementUpdateResult'
      id: number
      item: string
      payload: string
      cart: string
      totals: string
    }
  }
}

export type ApiSalesManagerCartToOrderMutationVariables = Exact<{
  data: ApiSalesManagerCartToOrderDataInput
}>

export type ApiSalesManagerCartToOrderMutation = {
  __typename?: 'mutation_root'
  api_sales_manager_cart_to_order: {
    __typename?: 'ApiSalesManagerCartToOrderOutput'
    result: string
  }
}

export type ApiSalesManagerCartUpdateMutationVariables = Exact<{
  data: ApiSalesManagerCartUpdateDataInput
}>

export type ApiSalesManagerCartUpdateMutation = {
  __typename?: 'mutation_root'
  api_sales_manager_cart_update: {
    __typename?: 'ApiSalesManagerCartUpdateOutput'
    result: {
      __typename?: 'ApiSalesManagerCartUpdateResult'
      id: string
      cart: string
      totals: string
      payload: string
    }
  }
}

export type ApiSalesManagerEnsureCartMutationVariables = Exact<{
  data: ApiSalesManagerCartEnsureDataInput
}>

export type ApiSalesManagerEnsureCartMutation = {
  __typename?: 'mutation_root'
  api_sales_manager_ensure_cart: {
    __typename?: 'ApiSalesManagerEnsureCartOutput'
    result: {
      __typename?: 'ApiSalesManagerCartEnsureResult'
      cart: string
      totals: string
      payload: string
    }
  }
}

export type ApiSalesManagerFormMutationVariables = Exact<{
  data: ApiSalesManagerFormDataInput
}>

export type ApiSalesManagerFormMutation = {
  __typename?: 'mutation_root'
  api_sales_manager_form: {
    __typename?: 'ApiSalesManagerFormOutput'
    result: string
  }
}

export type ApiSalesManagerInvoiceToOrderMutationVariables = Exact<{
  data: ApiSalesManagerInvoiceToOrderDataInput
}>

export type ApiSalesManagerInvoiceToOrderMutation = {
  __typename?: 'mutation_root'
  api_sales_manager_invoice_to_order: {
    __typename?: 'ApiSalesManagerInvoiceToOrderOutput'
    result: string
  }
}

export type ApiSalesManagerPayMutationVariables = Exact<{
  data: ApiSalesManagerPayDataInput
}>

export type ApiSalesManagerPayMutation = {
  __typename?: 'mutation_root'
  api_sales_manager_pay: {
    __typename?: 'ApiSalesManagerPayOutput'
    result: string
  }
}

export type ApiSalesManagerQuoteToEmailMutationVariables = Exact<{
  id: Scalars['String']['input']
  locale: Scalars['String']['input']
  endCustomerMode: Scalars['Boolean']['input']
}>

export type ApiSalesManagerQuoteToEmailMutation = {
  __typename?: 'mutation_root'
  api_sales_manager_quote_to_email: {
    __typename?: 'ApiSalesManagerQuoteToEmailOutput'
    result: boolean
  }
}

export type ApiSalesManagerRegisterMutationVariables = Exact<{
  data: ApiSalesManagerRegisterDataInput
}>

export type ApiSalesManagerRegisterMutation = {
  __typename?: 'mutation_root'
  api_sales_manager_register: {
    __typename?: 'ApiSalesManagerRegisterOutput'
    result: string
  }
}

export type ApiSalesManagerResetOrderMutationVariables = Exact<{
  data: ApiSalesManagerResetOrderDataInput
}>

export type ApiSalesManagerResetOrderMutation = {
  __typename?: 'mutation_root'
  api_sales_manager_reset_order: {
    __typename?: 'ApiSalesManagerResetOrderOutput'
    result: string
  }
}

export type ApiSalesManagerUpdateTransactionMutationVariables = Exact<{
  data: ApiSalesManagerUpdateTransactionDataInput
}>

export type ApiSalesManagerUpdateTransactionMutation = {
  __typename?: 'mutation_root'
  api_sales_manager_update_transaction: {
    __typename?: 'ApiSalesManagerUpdateTransactionOutput'
    result: boolean
  }
}

export type ApiSelectCustomerMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type ApiSelectCustomerMutation = {
  __typename?: 'mutation_root'
  api_select_customer: {
    __typename?: 'SelectCustomerOutput'
    accessToken: string
  }
}

export type ApiUpdateSubcustomerMutationVariables = Exact<{
  id: Scalars['String']['input']
  login: Scalars['String']['input']
  password: Scalars['String']['input']
  customerErpId: Scalars['String']['input']
}>

export type ApiUpdateSubcustomerMutation = {
  __typename?: 'mutation_root'
  api_update_subcustomer: {
    __typename?: 'UpdateSubcustomerOutput'
    id: string
    customer_erp_id: string
  }
}

export type ApiUpdateUserMutationVariables = Exact<{
  id: Scalars['String']['input']
  login: Scalars['String']['input']
  password: Scalars['String']['input']
  role: Scalars['String']['input']
}>

export type ApiUpdateUserMutation = {
  __typename?: 'mutation_root'
  api_update_user: {
    __typename?: 'UpdateUserOutput'
    id: string
    login: string
    role: string
  }
}

export type DeleteCommunicationCodeMutationVariables = Exact<{
  code: Scalars['String']['input']
}>

export type DeleteCommunicationCodeMutation = {
  __typename?: 'mutation_root'
  delete_communication_code_by_pk?: {
    __typename?: 'communication_code'
    code: string
  } | null
}

export type DeleteCommunicationExternalMailMutationVariables = Exact<{
  id: Scalars['uuid']['input']
}>

export type DeleteCommunicationExternalMailMutation = {
  __typename?: 'mutation_root'
  delete_communication_external_mail_by_pk?: {
    __typename?: 'communication_external_mail'
    id: any
  } | null
}

export type DeleteFileByPkMutationVariables = Exact<{
  id: Scalars['uuid']['input']
}>

export type DeleteFileByPkMutation = {
  __typename?: 'mutation_root'
  delete_file_by_pk?: { __typename?: 'file'; id: any } | null
}

export type DeleteProductReplacementMutationVariables = Exact<{
  productErpId: Scalars['String']['input']
  replacementErpId: Scalars['String']['input']
}>

export type DeleteProductReplacementMutation = {
  __typename?: 'mutation_root'
  delete_product_replacement?: {
    __typename?: 'product_replacement_mutation_response'
    affected_rows: number
  } | null
}

export type DeleteProductReplacementByPkMutationVariables = Exact<{
  id: Scalars['uuid']['input']
}>

export type DeleteProductReplacementByPkMutation = {
  __typename?: 'mutation_root'
  delete_product_replacement_by_pk?: {
    __typename?: 'product_replacement'
    id: any
  } | null
}

export type DeleteQuoteByPkMutationVariables = Exact<{
  id: Scalars['bigint']['input']
}>

export type DeleteQuoteByPkMutation = {
  __typename?: 'mutation_root'
  delete_cart_by_pk?: { __typename?: 'cart'; id: any } | null
}

export type DeleteSubcustomerByPkMutationVariables = Exact<{
  id: Scalars['uuid']['input']
}>

export type DeleteSubcustomerByPkMutation = {
  __typename?: 'mutation_root'
  delete_subcustomer_by_pk?: { __typename?: 'subcustomer'; id: any } | null
}

export type DeleteUserByPkMutationVariables = Exact<{
  id: Scalars['uuid']['input']
}>

export type DeleteUserByPkMutation = {
  __typename?: 'mutation_root'
  delete_user_by_pk?: {
    __typename?: 'user'
    id: any
    login: string
    role: Role_Enum
  } | null
}

export type InsertCommunicationCodeMutationVariables = Exact<{
  object: Communication_Code_Insert_Input
}>

export type InsertCommunicationCodeMutation = {
  __typename?: 'mutation_root'
  insert_communication_code_one?: {
    __typename?: 'communication_code'
    code: string
  } | null
}

export type InsertCommunicationExternalMailMutationVariables = Exact<{
  object: Communication_External_Mail_Insert_Input
}>

export type InsertCommunicationExternalMailMutation = {
  __typename?: 'mutation_root'
  insert_communication_external_mail_one?: {
    __typename?: 'communication_external_mail'
    id: any
  } | null
}

export type InsertCommunicationListMutationVariables = Exact<{
  objects:
    | Array<Communication_List_Insert_Input>
    | Communication_List_Insert_Input
}>

export type InsertCommunicationListMutation = {
  __typename?: 'mutation_root'
  insert_communication_list?: {
    __typename?: 'communication_list_mutation_response'
    affected_rows: number
    returning: Array<{ __typename?: 'communication_list'; id: any }>
  } | null
}

export type InsertCommunicationSettingsMutationVariables = Exact<{
  objects:
    | Array<Communication_Settings_Insert_Input>
    | Communication_Settings_Insert_Input
}>

export type InsertCommunicationSettingsMutation = {
  __typename?: 'mutation_root'
  insert_communication_settings?: {
    __typename?: 'communication_settings_mutation_response'
    affected_rows: number
    returning: Array<{
      __typename?: 'communication_settings'
      id: any
      language: string
    }>
  } | null
}

export type InsertCustomerAddressDropshippingMutationVariables = Exact<{
  objects:
    | Array<Customer_Address_Dropshipping_Insert_Input>
    | Customer_Address_Dropshipping_Insert_Input
}>

export type InsertCustomerAddressDropshippingMutation = {
  __typename?: 'mutation_root'
  insert_customer_address_dropshipping?: {
    __typename?: 'customer_address_dropshipping_mutation_response'
    affected_rows: number
    returning: Array<{ __typename?: 'customer_address_dropshipping'; id: any }>
  } | null
}

export type InsertDownloadMutationVariables = Exact<{
  download: Download_Insert_Input
}>

export type InsertDownloadMutation = {
  __typename?: 'mutation_root'
  insert_download_one?: { __typename?: 'download'; id: any } | null
}

export type InsertProductReplacementMutationVariables = Exact<{
  objects:
    | Array<Product_Replacement_Insert_Input>
    | Product_Replacement_Insert_Input
}>

export type InsertProductReplacementMutation = {
  __typename?: 'mutation_root'
  insert_product_replacement?: {
    __typename?: 'product_replacement_mutation_response'
    affected_rows: number
    returning: Array<{
      __typename?: 'product_replacement'
      id: any
      created_at: any
      updated_at: any
      product_erp_id: string
      replacement_erp_id: string
      x: number
      y: number
      scale_x?: any | null
      scale_y?: any | null
      origin?: string | null
      absolute?: boolean | null
    }>
  } | null
}

export type InsertProductReplacementMapMutationVariables = Exact<{
  objects:
    | Array<Product_Replacement_Map_Insert_Input>
    | Product_Replacement_Map_Insert_Input
}>

export type InsertProductReplacementMapMutation = {
  __typename?: 'mutation_root'
  insert_product_replacement_map?: {
    __typename?: 'product_replacement_map_mutation_response'
    affected_rows: number
    returning: Array<{
      __typename?: 'product_replacement_map'
      id: any
      created_at?: any | null
      updated_at?: any | null
      product_erp_id: string
      status?: boolean | null
      has_image?: boolean | null
      width?: number | null
      height?: number | null
    }>
  } | null
}

export type MoveDownloadMutationVariables = Exact<{
  id: Scalars['uuid']['input']
  position: Scalars['Int']['input']
}>

export type MoveDownloadMutation = {
  __typename?: 'mutation_root'
  move_download: Array<{ __typename?: 'download'; id: any }>
}

export type UpdateCommunicationMutationVariables = Exact<{
  ids: Array<Scalars['uuid']['input']> | Scalars['uuid']['input']
  changes: Communication_Set_Input
}>

export type UpdateCommunicationMutation = {
  __typename?: 'mutation_root'
  update_communication?: {
    __typename?: 'communication_mutation_response'
    affected_rows: number
    returning: Array<{ __typename?: 'communication'; id: any }>
  } | null
}

export type UpdateCommunicationCodeMutationVariables = Exact<{
  _set: Communication_Code_Set_Input
  code: Scalars['String']['input']
}>

export type UpdateCommunicationCodeMutation = {
  __typename?: 'mutation_root'
  update_communication_code_by_pk?: {
    __typename?: 'communication_code'
    code: string
  } | null
}

export type UpdateCommunicationExternalMailMutationVariables = Exact<{
  id: Scalars['uuid']['input']
  set: Communication_External_Mail_Set_Input
}>

export type UpdateCommunicationExternalMailMutation = {
  __typename?: 'mutation_root'
  update_communication_external_mail_by_pk?: {
    __typename?: 'communication_external_mail'
    id: any
  } | null
}

export type UpdateDownloadMutationVariables = Exact<{
  id: Scalars['uuid']['input']
  _set: Download_Set_Input
}>

export type UpdateDownloadMutation = {
  __typename?: 'mutation_root'
  update_download_by_pk?: { __typename?: 'download'; id: any } | null
}

export type UpdateProductReplacementMapMutationVariables = Exact<{
  productErpId: Scalars['String']['input']
  changes: Product_Replacement_Map_Set_Input
}>

export type UpdateProductReplacementMapMutation = {
  __typename?: 'mutation_root'
  update_product_replacement_map?: {
    __typename?: 'product_replacement_map_mutation_response'
    affected_rows: number
    returning: Array<{ __typename?: 'product_replacement_map'; id: any }>
  } | null
}

export type UpdatePromoSettingsMutationVariables = Exact<{
  promoEnable: Scalars['String']['input']
  promoDiscount: Scalars['String']['input']
  promoDateFrom: Scalars['String']['input']
  promoDateTo: Scalars['String']['input']
  promoMinQty: Scalars['String']['input']
  promoMaxQty: Scalars['String']['input']
}>

export type UpdatePromoSettingsMutation = {
  __typename?: 'mutation_root'
  insert_setting?: {
    __typename?: 'setting_mutation_response'
    affected_rows: number
  } | null
}

export type UpdateReplacementSettingsMutationVariables = Exact<{
  replacementMaxOrderQty: Scalars['String']['input']
}>

export type UpdateReplacementSettingsMutation = {
  __typename?: 'mutation_root'
  insert_setting?: {
    __typename?: 'setting_mutation_response'
    affected_rows: number
  } | null
}

export type UpsertCustomerAdditionalDataMutationVariables = Exact<{
  customer_erp_id: Scalars['String']['input']
  data: Scalars['jsonb']['input']
}>

export type UpsertCustomerAdditionalDataMutation = {
  __typename?: 'mutation_root'
  insert_customer_additional_data_one?: {
    __typename?: 'customer_additional_data'
    customer_erp_id: string
  } | null
}

export type AgentBonusQueryVariables = Exact<{ [key: string]: never }>

export type AgentBonusQuery = {
  __typename?: 'query_root'
  agent_bonus: Array<{
    __typename?: 'agent_bonus'
    id: any
    erp_id: string
    prize_current_year: any
    prize_current_revenue: any
    prize_target_revenue_1: any
    prize_return_rate_1: any
    prize_target_revenue_2: any
    prize_return_rate_2: any
    prize_target_revenue_3: any
    prize_return_rate_3: any
  }>
}

export type AgentsQueryVariables = Exact<{
  where?: InputMaybe<Agent_Bool_Exp>
}>

export type AgentsQuery = {
  __typename?: 'query_root'
  agent: Array<{
    __typename?: 'agent'
    id: any
    erp_id: string
    name: string
    email: string
  }>
}

export type ApiCustomerStatisticsQueryVariables = Exact<{
  [key: string]: never
}>

export type ApiCustomerStatisticsQuery = {
  __typename?: 'query_root'
  api_customer_statistics: {
    __typename?: 'CustomerStatisticOutput'
    customer_erp_id: string
    revenue_current_year: number
    revenue_last_year: number
    revenue_2_years_ago: number
  }
}

export type ApiFileDataQueryVariables = Exact<{
  filename: Scalars['String']['input']
  docType: DocTypes
  year: Scalars['Int']['input']
}>

export type ApiFileDataQuery = {
  __typename?: 'query_root'
  api_file_data: {
    __typename?: 'FileDataOutput'
    content: string
    mime: string
  }
}

export type ApiFileReportDataQueryVariables = Exact<{
  filename: Scalars['String']['input']
}>

export type ApiFileReportDataQuery = {
  __typename?: 'query_root'
  api_file_report_data: {
    __typename?: 'FileDataOutput'
    content: string
    mime: string
  }
}

export type ApiMailupClientEmailsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  idList: Scalars['Int']['input']
}>

export type ApiMailupClientEmailsQuery = {
  __typename?: 'query_root'
  api_mailup_client_emails: {
    __typename?: 'ApiMailupClientEmailsOutput'
    offset: number
    limit: number
    total: number
    results: Array<{
      __typename?: 'ApiMailupClientEmail'
      Subject: string
      idList: number
      idMessage: number
      Url: string
    }>
  }
}

export type ApiMailupClientListQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type ApiMailupClientListQuery = {
  __typename?: 'query_root'
  api_mailup_client_list: {
    __typename?: 'ApiMailupClientListOutput'
    offset: number
    limit: number
    total: number
    results: Array<{
      __typename?: 'ApiMailupClientList'
      IdList: number
      Name: string
    }>
  }
}

export type ApiMailupClientRecipientQueryVariables = Exact<{
  email: Scalars['String']['input']
}>

export type ApiMailupClientRecipientQuery = {
  __typename?: 'query_root'
  api_mailup_client_recipient: {
    __typename?: 'ApiMailupClientRecipientOutput'
    Name: string
    idRecipient: number
    Email: string
  }
}

export type ApiPingQueryVariables = Exact<{ [key: string]: never }>

export type ApiPingQuery = { __typename?: 'query_root'; api_ping: string }

export type ApiSalesManagerAmountQueryVariables = Exact<{
  data: ApiSalesManagerAmountDataInput
}>

export type ApiSalesManagerAmountQuery = {
  __typename?: 'query_root'
  api_sales_manager_amount: {
    __typename?: 'ApiSalesManagerAmountOutput'
    result: {
      __typename?: 'ApiSalesManagerAmountDataOutput'
      currency: string
      amount: string
      amountInclTax: string
      provider: string
      method: string
      condition: string
    }
  }
}

export type ApiSalesManagerCollectTotalsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>
}>

export type ApiSalesManagerCollectTotalsQuery = {
  __typename?: 'query_root'
  api_sales_manager_collect_totals: {
    __typename?: 'ApiSalesManagerCollectTotalsOutput'
    result: string
  }
}

export type CommunicationsQueryVariables = Exact<{
  where?: InputMaybe<Communication_Bool_Exp>
}>

export type CommunicationsQuery = {
  __typename?: 'query_root'
  communication: Array<{
    __typename?: 'communication'
    id: any
    customer_erp_id: string
    code: string
    is_archived: boolean
    is_deleted: boolean
    mail_id?: any | null
    external_mail_id?: any | null
    created_at: any
    updated_at: any
    communication_code: {
      __typename?: 'communication_code'
      code: string
      provider: string
      is_external: boolean
    }
    communication_external_mail?: {
      __typename?: 'communication_external_mail'
      id: any
      code: string
      identifier: string
      title?: string | null
      created_at: any
      updated_at: any
    } | null
    mail?: {
      __typename?: 'mail'
      id: any
      status: string
      mail: any
      logs?: any | null
      created_at: any
      updated_at: any
    } | null
  }>
}

export type CommunicationCodesQueryVariables = Exact<{ [key: string]: never }>

export type CommunicationCodesQuery = {
  __typename?: 'query_root'
  communication_code: Array<{
    __typename?: 'communication_code'
    code: string
    is_enabled: boolean
    is_external: boolean
    is_list: boolean
    provider: string
    created_at: any
    updated_at: any
  }>
}

export type CommunicationExternalMailQueryVariables = Exact<{
  [key: string]: never
}>

export type CommunicationExternalMailQuery = {
  __typename?: 'query_root'
  communication_external_mail: Array<{
    __typename?: 'communication_external_mail'
    id: any
    code: string
    identifier: string
    updated_at: any
    created_at: any
    title?: string | null
    is_processed: boolean
    search_criteria?: any | null
  }>
}

export type CommunicationListQueryVariables = Exact<{ [key: string]: never }>

export type CommunicationListQuery = {
  __typename?: 'query_root'
  communication_list: Array<{
    __typename?: 'communication_list'
    id: any
    customer_erp_id: string
    code: string
    is_enabled_site: boolean
    is_enabled_email: boolean
    send_to_email: any
    created_at: any
    updated_at: any
  }>
}

export type CommunicationSettingsQueryVariables = Exact<{
  [key: string]: never
}>

export type CommunicationSettingsQuery = {
  __typename?: 'query_root'
  communication_settings: Array<{
    __typename?: 'communication_settings'
    id: any
    language: string
  }>
}

export type CountryRegionQueryVariables = Exact<{ [key: string]: never }>

export type CountryRegionQuery = {
  __typename?: 'query_root'
  country_region: Array<{
    __typename?: 'country_region'
    code: string
    description: string
    post_codes: Array<{
      __typename?: 'post_code'
      code: string
      city: string
      county?: {
        __typename?: 'county'
        code: string
        description: string
      } | null
      territory?: {
        __typename?: 'territory'
        code: string
        description: string
      } | null
    }>
  }>
}

export type CreditMemoQueryVariables = Exact<{
  where?: InputMaybe<Credit_Memo_Bool_Exp>
}>

export type CreditMemoQuery = {
  __typename?: 'query_root'
  credit_memo: Array<{
    __typename?: 'credit_memo'
    id: any
    erp_id: string
    customer_erp_id: string
    date: any
    pdf_filename?: string | null
    pdf_created_at?: any | null
    created_at: any
    updated_at: any
  }>
}

export type CustomerQueryVariables = Exact<{ [key: string]: never }>

export type CustomerQuery = {
  __typename?: 'query_root'
  customer: Array<{
    __typename?: 'customer'
    id: any
    user_id?: any | null
    erp_id: string
    name: string
    address: string
    address_2: string
    zip_code: string
    city: string
    province: string
    country: string
    employee_email: string
    created_at: any
    updated_at: any
  }>
}

export type CustomerAddressQueryVariables = Exact<{
  where?: InputMaybe<Customer_Address_Bool_Exp>
}>

export type CustomerAddressQuery = {
  __typename?: 'query_root'
  customer_address: Array<{
    __typename?: 'customer_address'
    id: any
    code: string
    created_at: any
    updated_at: any
    customer_erp_id?: string | null
    agent_erp_id?: string | null
    shipping_method_code?: string | null
    name: string
    address: string
    address_2: string
    zip_code: string
    city: string
    province: string
    country: string
    phone: string
    employee_email: string
    shipping_method?: {
      __typename?: 'shipping_method'
      code: string
      has_shipping_cost: boolean
    } | null
  }>
}

export type CustomerCurrentQueryVariables = Exact<{
  where?: InputMaybe<Customer_Bool_Exp>
}>

export type CustomerCurrentQuery = {
  __typename?: 'query_root'
  customer: Array<{
    __typename?: 'customer'
    id: any
    erp_id: string
    created_at: any
    updated_at: any
    address: string
    address_2: string
    agent_erp_id: string
    city: string
    country: string
    email: string
    employee_email: string
    fiscal_code: string
    has_dropshipping: boolean
    invoice_discount_code: string
    name: string
    payment_condition_code: string
    payment_method_code: string
    phone: string
    prize_current_revenue: any
    prize_current_year: any
    prize_return_rate_1: any
    prize_return_rate_2: any
    prize_return_rate_3: any
    prize_target_revenue_1: any
    prize_target_revenue_2: any
    prize_target_revenue_3: any
    product_price_list_code: string
    province: string
    shipping_extra_cost: any
    shipping_method_code: string
    shipping_rate_code: string
    tax_rate: any
    user_id?: any | null
    vat_number: string
    zip_code: string
    customer_discounts: Array<{
      __typename?: 'customer_discount'
      product_discount_group_code: string
      discount_percent: any
    }>
    invoice_discount: { __typename?: 'invoice_discount'; discount_percent: any }
    shipping_method: {
      __typename?: 'shipping_method'
      code: string
      has_shipping_cost: boolean
    }
    shipping_rate: {
      __typename?: 'shipping_rate'
      code: string
      free_threshold: any
      cost: any
      valid_from: any
    }
  }>
}

export type CustomerWithAdditionalDataQueryVariables = Exact<{
  [key: string]: never
}>

export type CustomerWithAdditionalDataQuery = {
  __typename?: 'query_root'
  customer: Array<{
    __typename?: 'customer'
    id: any
    user_id?: any | null
    erp_id: string
    name: string
    address: string
    address_2: string
    zip_code: string
    city: string
    province: string
    country: string
    employee_email: string
    created_at: any
    updated_at: any
    additional_data?: {
      __typename?: 'customer_additional_data'
      data: any
    } | null
  }>
}

export type ExchangeRateQueryVariables = Exact<{
  where?: InputMaybe<Exchange_Rate_Bool_Exp>
}>

export type ExchangeRateQuery = {
  __typename?: 'query_root'
  exchange_rate: Array<{
    __typename?: 'exchange_rate'
    currency: string
    rate: any
    last_update_at: any
  }>
}

export type InvoiceQueryVariables = Exact<{
  where?: InputMaybe<Invoice_View_Bool_Exp>
}>

export type InvoiceQuery = {
  __typename?: 'query_root'
  invoice_view: Array<{
    __typename?: 'invoice_view'
    erp_id?: string | null
    date_registered?: any | null
    amount?: any | null
    amount_expired_unpaid?: any | null
    pdf_filename?: string | null
    pdf_created_at?: any | null
  }>
}

export type MailsQueryVariables = Exact<{ [key: string]: never }>

export type MailsQuery = {
  __typename?: 'query_root'
  mail: Array<{
    __typename?: 'mail'
    id: any
    status: string
    mail: any
    logs?: any | null
    created_at: any
    updated_at: any
  }>
}

export type MailQueryVariables = Exact<{
  id: Scalars['uuid']['input']
}>

export type MailQuery = {
  __typename?: 'query_root'
  mail: Array<{
    __typename?: 'mail'
    id: any
    status: string
    mail: any
    logs?: any | null
    created_at: any
    updated_at: any
  }>
}

export type OrderByIncrementIdQueryVariables = Exact<{
  incrementId: Scalars['Int']['input']
}>

export type OrderByIncrementIdQuery = {
  __typename?: 'query_root'
  order: Array<{
    __typename?: 'order'
    id: any
    increment_id: number
    status: string
    type?: string | null
    amount: any
    currency: string
    additional_info?: any | null
    created_at: any
    updated_at: any
    order_transactions: Array<{
      __typename?: 'order_transaction'
      transaction_id?: any | null
      created_at: any
      updated_at: any
      transaction?: {
        __typename?: 'transaction'
        transaction_id: string
        parent_transaction_id?: string | null
        customer_erp_id?: string | null
        status: string
        provider: string
        method: string
        type: string
        additional_info?: any | null
        created_at: any
        updated_at: any
      } | null
    }>
    order_invoices: Array<{
      __typename?: 'order_invoice'
      created_at: any
      updated_at: any
      order_invoice_items: Array<{
        __typename?: 'order_invoice_item'
        invoice_erp_id?: string | null
        invoice_overdue_erp_id: string
        amount: any
        currency: string
        additional_info?: any | null
        created_at: any
        updated_at: any
      }>
    }>
    order_products: Array<{
      __typename?: 'order_product'
      created_at: any
      updated_at: any
      order_product_items: Array<{
        __typename?: 'order_product_item'
        additional_info?: any | null
        cart_item_created_at: any
        cart_item_id?: number | null
        product_erp_id: string
        amount: any
        currency: string
        qty: number
        product_amount: any
        created_at: any
        updated_at: any
        product?: {
          __typename?: 'product'
          id: any
          name: string
          dimension: any
          stock: number
          gross_weight: any
        } | null
      }>
      order_product_replacements: Array<{
        __typename?: 'order_product_replacement'
        additional_info?: any | null
        cart_replacement_created_at: any
        cart_replacement_id?: number | null
        replacement_erp_id: string
        qty: number
        created_at: any
        updated_at: any
        replacement?: {
          __typename?: 'replacement'
          id: any
          name?: string | null
          description?: string | null
        } | null
      }>
    }>
  }>
}

export type OrderConfirmedQueryVariables = Exact<{
  where?: InputMaybe<Order_Confirmed_Bool_Exp>
}>

export type OrderConfirmedQuery = {
  __typename?: 'query_root'
  order_confirmed: Array<{
    __typename?: 'order_confirmed'
    id: any
    erp_id: string
    customer_erp_id: string
    external_id?: string | null
    date_order: any
    is_under_processing: boolean
    is_invoiced: boolean
    pdf_filename?: string | null
    pdf_created_at?: any | null
    created_at: any
    updated_at: any
    order?: { __typename?: 'order'; increment_id: number } | null
  }>
}

export type OrderItemUnshippedQueryVariables = Exact<{
  where?: InputMaybe<Order_Item_Unshipped_Bool_Exp>
}>

export type OrderItemUnshippedQuery = {
  __typename?: 'query_root'
  order_item_unshipped: Array<{
    __typename?: 'order_item_unshipped'
    id: any
    customer_erp_id: string
    doc_erp_id: string
    doc_customer_id: string
    product_erp_id: string
    original_order_erp_id: string
    description: string
    qty?: number | null
    first_arrival_date?: any | null
    created_at: any
    updated_at: any
    order?: {
      __typename?: 'order'
      increment_id_as_text?: string | null
    } | null
    order_confirmed?: { __typename?: 'order_confirmed'; date_order: any } | null
  }>
}

export type OrderListQueryVariables = Exact<{
  where?: InputMaybe<Order_Bool_Exp>
}>

export type OrderListQuery = {
  __typename?: 'query_root'
  order: Array<{
    __typename?: 'order'
    id: any
    increment_id: number
    customer_erp_id?: string | null
    status: string
    type?: string | null
    amount: any
    currency: string
    additional_info?: any | null
    created_at: any
    updated_at: any
  }>
}

export type OrderSubcustomerByIncrementIdQueryVariables = Exact<{
  incrementId: Scalars['Int']['input']
  whereOrder: Order_Bool_Exp
  whereOrderProductItem: Order_Product_Item_Bool_Exp
}>

export type OrderSubcustomerByIncrementIdQuery = {
  __typename?: 'query_root'
  order: Array<{
    __typename?: 'order'
    increment_id: number
    status: string
    created_at: any
    order_products: Array<{
      __typename?: 'order_product'
      order_product_items: Array<{
        __typename?: 'order_product_item'
        additional_info?: any | null
        product_erp_id: string
        qty: number
        cart_item_created_at: any
        cart_item_id?: number | null
        product?: {
          __typename?: 'product'
          id: any
          name: string
          dimension: any
          stock: number
          gross_weight: any
        } | null
      }>
    }>
  }>
}

export type PaymentMethodAndConditionQueryVariables = Exact<{
  whereMethod?: InputMaybe<Payment_Method_Bool_Exp>
  whereCondition?: InputMaybe<Payment_Condition_Bool_Exp>
}>

export type PaymentMethodAndConditionQuery = {
  __typename?: 'query_root'
  payment_method: Array<{
    __typename?: 'payment_method'
    code: string
    description: string
    description_it: string
    description_en: string
    description_fr: string
    description_de: string
    description_es: string
    description_ru: string
  }>
  payment_condition: Array<{
    __typename?: 'payment_condition'
    code: string
    description: string
    description_it: string
    description_en: string
    description_fr: string
    description_de: string
    description_es: string
    description_ru: string
  }>
}

export type LastUpdateQueryVariables = Exact<{ [key: string]: never }>

export type LastUpdateQuery = {
  __typename?: 'query_root'
  product: Array<{ __typename?: 'product'; updated_at: any }>
}

export type ProductFilesQueryVariables = Exact<{ [key: string]: never }>

export type ProductFilesQuery = {
  __typename?: 'query_root'
  product: Array<{
    __typename?: 'product'
    erp_id: string
    name: string
    product_files: Array<{
      __typename?: 'product_file'
      folder: string
      filename: string
    }>
  }>
}

export type ProductPriceViewQueryVariables = Exact<{
  where?: InputMaybe<Product_Price_View_Bool_Exp>
  order_by?: InputMaybe<
    Array<Product_Price_View_Order_By> | Product_Price_View_Order_By
  >
}>

export type ProductPriceViewQuery = {
  __typename?: 'query_root'
  product_price_view: Array<{
    __typename?: 'product_price_view'
    allow_invoice_discount?: boolean | null
    allow_line_discount?: boolean | null
    price_real?: any | null
    price_real_no_discount?: any | null
    price_real_field?: string | null
    price_real_field_no_discount?: string | null
    price_client?: any | null
    price_end_series?: any | null
    price_price_list?: any | null
    price_public?: any | null
    price_promo?: any | null
    price_discount?: any | null
    discount_percent?: any | null
    customer_discount_discount_percent?: any | null
    id?: any | null
    erp_id?: string | null
    name?: string | null
    stock?: number | null
    first_arrival_date?: any | null
    gross_weight?: any | null
    dimension?: any | null
    box_pieces?: number | null
    promo?: boolean | null
    product_discount_group_code?: string | null
    created_at?: any | null
    updated_at?: any | null
    customer_erp_id?: string | null
    product_price_list_code?: string | null
    promo_enable?: boolean | null
    promo_from?: any | null
    promo_to?: any | null
    promo_discount?: any | null
  }>
}

export type ProductReplacementQueryVariables = Exact<{
  productErpId: Scalars['String']['input']
}>

export type ProductReplacementQuery = {
  __typename?: 'query_root'
  product: Array<{
    __typename?: 'product'
    erp_id: string
    name: string
    product_replacement_map?: {
      __typename?: 'product_replacement_map'
      id: any
      status?: boolean | null
      has_image?: boolean | null
      width?: number | null
      height?: number | null
      created_at?: any | null
      updated_at?: any | null
    } | null
    product_replacements_aggregate: {
      __typename?: 'product_replacement_aggregate'
      aggregate?: {
        __typename?: 'product_replacement_aggregate_fields'
        count: number
      } | null
      nodes: Array<{
        __typename?: 'product_replacement'
        id: any
        x: number
        y: number
        scale_x?: any | null
        scale_y?: any | null
        origin?: string | null
        absolute?: boolean | null
        replacement?: {
          __typename?: 'replacement'
          id: any
          erp_id: string
          name?: string | null
          description?: string | null
        } | null
      }>
    }
  }>
}

export type ProductReplacementListQueryVariables = Exact<{
  [key: string]: never
}>

export type ProductReplacementListQuery = {
  __typename?: 'query_root'
  product: Array<{
    __typename?: 'product'
    erp_id: string
    name: string
    product_replacement_map?: {
      __typename?: 'product_replacement_map'
      id: any
      status?: boolean | null
      has_image?: boolean | null
      width?: number | null
      height?: number | null
      created_at?: any | null
      updated_at?: any | null
    } | null
    product_replacements_aggregate: {
      __typename?: 'product_replacement_aggregate'
      aggregate?: {
        __typename?: 'product_replacement_aggregate_fields'
        count: number
      } | null
      nodes: Array<{
        __typename?: 'product_replacement'
        id: any
        x: number
        y: number
        scale_x?: any | null
        scale_y?: any | null
        origin?: string | null
        absolute?: boolean | null
        replacement?: {
          __typename?: 'replacement'
          id: any
          erp_id: string
          name?: string | null
          description?: string | null
        } | null
      }>
    }
  }>
}

export type ProductReplacementListCustomerQueryVariables = Exact<{
  [key: string]: never
}>

export type ProductReplacementListCustomerQuery = {
  __typename?: 'query_root'
  product: Array<{
    __typename?: 'product'
    erp_id: string
    name: string
    product_replacement_map?: {
      __typename?: 'product_replacement_map'
      id: any
      status?: boolean | null
      has_image?: boolean | null
      width?: number | null
      height?: number | null
      created_at?: any | null
      updated_at?: any | null
    } | null
    product_replacements_aggregate: {
      __typename?: 'product_replacement_aggregate'
      aggregate?: {
        __typename?: 'product_replacement_aggregate_fields'
        count: number
      } | null
      nodes: Array<{
        __typename?: 'product_replacement'
        id: any
        x: number
        y: number
        scale_x?: any | null
        scale_y?: any | null
        origin?: string | null
        absolute?: boolean | null
        replacement?: {
          __typename?: 'replacement'
          id: any
          erp_id: string
          name?: string | null
          description?: string | null
        } | null
      }>
    }
  }>
}

export type ReplacementListQueryVariables = Exact<{
  where?: InputMaybe<Replacement_Bool_Exp>
  order_by?: InputMaybe<Array<Replacement_Order_By> | Replacement_Order_By>
}>

export type ReplacementListQuery = {
  __typename?: 'query_root'
  replacement: Array<{
    __typename?: 'replacement'
    id: any
    erp_id: string
    name?: string | null
    description?: string | null
  }>
}

export type ReportQueryVariables = Exact<{ [key: string]: never }>

export type ReportQuery = {
  __typename?: 'query_root'
  report: Array<{
    __typename?: 'report'
    id: any
    filename: string
    created_at: any
    date_start: any
    date_end: any
    type: string
  }>
}

export type ShipmentDocumentQueryVariables = Exact<{
  where?: InputMaybe<Shipment_Document_Bool_Exp>
}>

export type ShipmentDocumentQuery = {
  __typename?: 'query_root'
  shipment_document: Array<{
    __typename?: 'shipment_document'
    id: any
    erp_id: string
    customer_erp_id: string
    date: any
    pdf_filename?: string | null
    pdf_created_at?: any | null
    created_at: any
    updated_at: any
  }>
}

export type ShippingMethodQueryVariables = Exact<{
  where?: InputMaybe<Shipping_Method_Bool_Exp>
}>

export type ShippingMethodQuery = {
  __typename?: 'query_root'
  shipping_method: Array<{
    __typename?: 'shipping_method'
    code: string
    description: string
    description_de: string
    description_en: string
    description_es: string
    description_fr: string
    description_ru: string
  }>
}

export type TransactionQueryVariables = Exact<{
  where?: InputMaybe<Transaction_Bool_Exp>
}>

export type TransactionQuery = {
  __typename?: 'query_root'
  transaction: Array<{
    __typename?: 'transaction'
    transaction_id: string
    status: string
    provider: string
    method: string
    created_at: any
    updated_at: any
    order_transactions: Array<{
      __typename?: 'order_transaction'
      order?: {
        __typename?: 'order'
        increment_id: number
        type?: string | null
      } | null
    }>
  }>
}

export type CartSubscriptionVariables = Exact<{
  where?: InputMaybe<Cart_Bool_Exp>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type CartSubscription = {
  __typename?: 'subscription_root'
  cart: Array<{
    __typename?: 'cart'
    id: any
    type: string
    order_id?: number | null
    status: string
    additional_info?: any | null
    created_at: any
    updated_at: any
    cart_items: Array<{
      __typename?: 'cart_item'
      id: any
      cart_id: any
      product_erp_id: string
      qty: number
      additional_info?: any | null
      created_at: any
      product?: { __typename?: 'product'; name: string; stock: number } | null
    }>
    cart_replacements: Array<{
      __typename?: 'cart_replacement'
      id: any
      cart_id: any
      replacement_erp_id: string
      qty: number
      additional_info?: any | null
      created_at: any
      replacement?: {
        __typename?: 'replacement'
        name?: string | null
        description?: string | null
      } | null
    }>
  }>
}

export type CustomerAddressDropshippingSubscriptionVariables = Exact<{
  where?: InputMaybe<Customer_Address_Dropshipping_Bool_Exp>
}>

export type CustomerAddressDropshippingSubscription = {
  __typename?: 'subscription_root'
  customer_address_dropshipping: Array<{
    __typename?: 'customer_address_dropshipping'
    id: any
    code: number
    name: string
    firstname: string
    lastname: string
    created_at?: any | null
    updated_at?: any | null
    customer_erp_id?: string | null
    agent_erp_id?: string | null
    shipping_method_code?: string | null
    address: string
    address_2: string
    zip_code: string
    city: string
    province: string
    country: string
    phone: string
    email: string
  }>
}

export type DownloadsSubscriptionVariables = Exact<{
  where?: InputMaybe<Download_Bool_Exp>
}>

export type DownloadsSubscription = {
  __typename?: 'subscription_root'
  download: Array<{
    __typename?: 'download'
    id: any
    position: number
    active_de: boolean
    active_en: boolean
    active_es: boolean
    active_fr: boolean
    active_it: boolean
    active_ru: boolean
    role: Role_Enum
    name_de: string
    name_en: string
    name_es: string
    name_fr: string
    name_it: string
    name_ru: string
    created_at: any
    updated_at: any
    file: { __typename?: 'file'; id: any; filename: string; uploaded_at: any }
  }>
}

export type OrderPromoSubscriptionVariables = Exact<{
  customerErpId: Scalars['String']['input']
  additionalInfoContains: Scalars['jsonb']['input']
}>

export type OrderPromoSubscription = {
  __typename?: 'subscription_root'
  order: Array<{
    __typename?: 'order'
    id: any
    increment_id: number
    additional_info?: any | null
  }>
}

export type PendingOrdersSubscriptionVariables = Exact<{ [key: string]: never }>

export type PendingOrdersSubscription = {
  __typename?: 'subscription_root'
  order: Array<{
    __typename?: 'order'
    increment_id: number
    type?: string | null
    status: string
    created_at: any
    amount: any
    additional_info?: any | null
    order_invoice?: {
      __typename?: 'order_invoice'
      order_invoice_items: Array<{
        __typename?: 'order_invoice_item'
        amount: any
        invoice_erp_id?: string | null
      }>
    } | null
  }>
}

export type ProposalSubscriptionVariables = Exact<{
  where?: InputMaybe<Cart_Bool_Exp>
}>

export type ProposalSubscription = {
  __typename?: 'subscription_root'
  cart: Array<{
    __typename?: 'cart'
    id: any
    type: string
    order_id?: number | null
    status: string
    subcustomer_id?: any | null
    additional_info?: any | null
    customer?: {
      __typename?: 'customer'
      name: string
      employee_email: string
      address: string
      address_2: string
      city: string
      zip_code: string
      province: string
      country: string
    } | null
    subcustomer?: {
      __typename?: 'subcustomer'
      user: { __typename?: 'user'; login: string }
    } | null
    cart_items: Array<{
      __typename?: 'cart_item'
      id: any
      cart_id: any
      product_erp_id: string
      qty: number
      additional_info?: any | null
      product?: {
        __typename?: 'product'
        name: string
        stock: number
        gross_weight: any
        dimension: any
      } | null
    }>
  }>
}

export type SettingSubscriptionVariables = Exact<{
  where?: InputMaybe<Setting_Bool_Exp>
}>

export type SettingSubscription = {
  __typename?: 'subscription_root'
  setting: Array<{ __typename?: 'setting'; key: string; value?: string | null }>
}

export type SubcustomerUserSubscriptionVariables = Exact<{
  [key: string]: never
}>

export type SubcustomerUserSubscription = {
  __typename?: 'subscription_root'
  user: Array<{
    __typename?: 'user'
    id: any
    login: string
    created_at: any
    updated_at: any
    subcustomer?: {
      __typename?: 'subcustomer'
      id: any
      customer: { __typename?: 'customer'; erp_id: string; name: string }
    } | null
  }>
}

export type UserSubscriptionVariables = Exact<{
  where?: InputMaybe<User_Bool_Exp>
}>

export type UserSubscription = {
  __typename?: 'subscription_root'
  user: Array<{
    __typename?: 'user'
    id: any
    login: string
    role: Role_Enum
    created_at: any
    updated_at: any
  }>
}

export const ApiCustomerCreateDocument = gql`
  mutation ApiCustomerCreate($customer: ApiCustomerCreateCustomerInput!) {
    api_customer_create(customer: $customer) {
      result
    }
  }
`
export const ApiFileDeleteDocument = gql`
  mutation ApiFileDelete($data: FileDeleteDataInput!) {
    api_file_delete(data: $data) {
      result
    }
  }
`
export const ApiFileUploadDocument = gql`
  mutation ApiFileUpload($data: FileUploadDataInput!) {
    api_file_upload(data: $data) {
      id
    }
  }
`
export const ApiInsertSubcustomerDocument = gql`
  mutation ApiInsertSubcustomer(
    $userId: String
    $login: String!
    $password: String!
    $customerErpId: String!
  ) {
    api_insert_subcustomer(
      user_id: $userId
      login: $login
      password: $password
      customer_erp_id: $customerErpId
    ) {
      id
      user_id
    }
  }
`
export const ApiInsertUserDocument = gql`
  mutation ApiInsertUser($login: String!, $password: String!, $role: String!) {
    api_insert_user(login: $login, password: $password, role: $role) {
      id
    }
  }
`
export const ApiLoginDocument = gql`
  mutation ApiLogin($login: String!, $password: String!) {
    api_login(login: $login, password: $password) {
      accessToken
    }
  }
`
export const ApiMailManagerRetryDocument = gql`
  mutation ApiMailManagerRetry($id: String!) {
    api_mail_manager_retry(id: $id) {
      result
    }
  }
`
export const ApiMailManagerSendDocument = gql`
  mutation ApiMailManagerSend($mail: ApiMailManagerMailInput!) {
    api_mail_manager_send(mail: $mail) {
      result
    }
  }
`
export const ApiSalesManagerCancelOrderDocument = gql`
  mutation ApiSalesManagerCancelOrder(
    $data: ApiSalesManagerCancelOrderDataInput!
  ) {
    api_sales_manager_cancel_order(data: $data) {
      result
    }
  }
`
export const ApiSalesManagerCaptureOrderDocument = gql`
  mutation ApiSalesManagerCaptureOrder(
    $data: ApiSalesManagerCaptureOrderDataInput!
  ) {
    api_sales_manager_capture_order(data: $data) {
      result
    }
  }
`
export const ApiSalesManagerCartItemRemoveDocument = gql`
  mutation ApiSalesManagerCartItemRemove(
    $data: ApiSalesManagerCartItemRemoveDataInput!
  ) {
    api_sales_manager_cart_item_remove(data: $data) {
      result {
        ids
        success
        payload
        cart
        totals
      }
    }
  }
`
export const ApiSalesManagerCartItemUpdateDocument = gql`
  mutation ApiSalesManagerCartItemUpdate(
    $data: ApiSalesManagerCartItemUpdateDataInput!
  ) {
    api_sales_manager_cart_item_update(data: $data) {
      result {
        id
        item
        payload
        cart
        totals
      }
    }
  }
`
export const ApiSalesManagerCartReplacementRemoveDocument = gql`
  mutation ApiSalesManagerCartReplacementRemove(
    $data: ApiSalesManagerCartReplacementRemoveDataInput!
  ) {
    api_sales_manager_cart_replacement_remove(data: $data) {
      result {
        ids
        success
        payload
        cart
        totals
      }
    }
  }
`
export const ApiSalesManagerCartReplacementUpdateDocument = gql`
  mutation ApiSalesManagerCartReplacementUpdate(
    $data: ApiSalesManagerCartReplacementUpdateDataInput!
  ) {
    api_sales_manager_cart_replacement_update(data: $data) {
      result {
        id
        item
        payload
        cart
        totals
      }
    }
  }
`
export const ApiSalesManagerCartToOrderDocument = gql`
  mutation ApiSalesManagerCartToOrder(
    $data: ApiSalesManagerCartToOrderDataInput!
  ) {
    api_sales_manager_cart_to_order(data: $data) {
      result
    }
  }
`
export const ApiSalesManagerCartUpdateDocument = gql`
  mutation ApiSalesManagerCartUpdate(
    $data: ApiSalesManagerCartUpdateDataInput!
  ) {
    api_sales_manager_cart_update(data: $data) {
      result {
        id
        cart
        totals
        payload
      }
    }
  }
`
export const ApiSalesManagerEnsureCartDocument = gql`
  mutation ApiSalesManagerEnsureCart(
    $data: ApiSalesManagerCartEnsureDataInput!
  ) {
    api_sales_manager_ensure_cart(data: $data) {
      result {
        cart
        totals
        payload
      }
    }
  }
`
export const ApiSalesManagerFormDocument = gql`
  mutation ApiSalesManagerForm($data: ApiSalesManagerFormDataInput!) {
    api_sales_manager_form(data: $data) {
      result
    }
  }
`
export const ApiSalesManagerInvoiceToOrderDocument = gql`
  mutation ApiSalesManagerInvoiceToOrder(
    $data: ApiSalesManagerInvoiceToOrderDataInput!
  ) {
    api_sales_manager_invoice_to_order(data: $data) {
      result
    }
  }
`
export const ApiSalesManagerPayDocument = gql`
  mutation ApiSalesManagerPay($data: ApiSalesManagerPayDataInput!) {
    api_sales_manager_pay(data: $data) {
      result
    }
  }
`
export const ApiSalesManagerQuoteToEmailDocument = gql`
  mutation ApiSalesManagerQuoteToEmail(
    $id: String!
    $locale: String!
    $endCustomerMode: Boolean!
  ) {
    api_sales_manager_quote_to_email(
      id: $id
      locale: $locale
      endCustomerMode: $endCustomerMode
    ) {
      result
    }
  }
`
export const ApiSalesManagerRegisterDocument = gql`
  mutation ApiSalesManagerRegister($data: ApiSalesManagerRegisterDataInput!) {
    api_sales_manager_register(data: $data) {
      result
    }
  }
`
export const ApiSalesManagerResetOrderDocument = gql`
  mutation ApiSalesManagerResetOrder(
    $data: ApiSalesManagerResetOrderDataInput!
  ) {
    api_sales_manager_reset_order(data: $data) {
      result
    }
  }
`
export const ApiSalesManagerUpdateTransactionDocument = gql`
  mutation ApiSalesManagerUpdateTransaction(
    $data: ApiSalesManagerUpdateTransactionDataInput!
  ) {
    api_sales_manager_update_transaction(data: $data) {
      result
    }
  }
`
export const ApiSelectCustomerDocument = gql`
  mutation ApiSelectCustomer($id: String!) {
    api_select_customer(id: $id) {
      accessToken
    }
  }
`
export const ApiUpdateSubcustomerDocument = gql`
  mutation ApiUpdateSubcustomer(
    $id: String!
    $login: String!
    $password: String!
    $customerErpId: String!
  ) {
    api_update_subcustomer(
      id: $id
      login: $login
      password: $password
      customer_erp_id: $customerErpId
    ) {
      id
      customer_erp_id
    }
  }
`
export const ApiUpdateUserDocument = gql`
  mutation ApiUpdateUser(
    $id: String!
    $login: String!
    $password: String!
    $role: String!
  ) {
    api_update_user(id: $id, login: $login, password: $password, role: $role) {
      id
      login
      role
    }
  }
`
export const DeleteCommunicationCodeDocument = gql`
  mutation DeleteCommunicationCode($code: String!) {
    delete_communication_code_by_pk(code: $code) {
      code
    }
  }
`
export const DeleteCommunicationExternalMailDocument = gql`
  mutation DeleteCommunicationExternalMail($id: uuid!) {
    delete_communication_external_mail_by_pk(id: $id) {
      id
    }
  }
`
export const DeleteFileByPkDocument = gql`
  mutation DeleteFileByPk($id: uuid!) {
    delete_file_by_pk(id: $id) {
      id
    }
  }
`
export const DeleteProductReplacementDocument = gql`
  mutation DeleteProductReplacement(
    $productErpId: String!
    $replacementErpId: String!
  ) {
    delete_product_replacement(
      where: {
        product_erp_id: { _eq: $productErpId }
        replacement_erp_id: { _eq: $replacementErpId }
      }
    ) {
      affected_rows
    }
  }
`
export const DeleteProductReplacementByPkDocument = gql`
  mutation DeleteProductReplacementByPk($id: uuid!) {
    delete_product_replacement_by_pk(id: $id) {
      id
    }
  }
`
export const DeleteQuoteByPkDocument = gql`
  mutation DeleteQuoteByPk($id: bigint!) {
    delete_cart_by_pk(id: $id) {
      id
    }
  }
`
export const DeleteSubcustomerByPkDocument = gql`
  mutation DeleteSubcustomerByPk($id: uuid!) {
    delete_subcustomer_by_pk(id: $id) {
      id
    }
  }
`
export const DeleteUserByPkDocument = gql`
  mutation DeleteUserByPk($id: uuid!) {
    delete_user_by_pk(id: $id) {
      id
      login
      role
    }
  }
`
export const InsertCommunicationCodeDocument = gql`
  mutation InsertCommunicationCode($object: communication_code_insert_input!) {
    insert_communication_code_one(object: $object) {
      code
    }
  }
`
export const InsertCommunicationExternalMailDocument = gql`
  mutation InsertCommunicationExternalMail(
    $object: communication_external_mail_insert_input!
  ) {
    insert_communication_external_mail_one(object: $object) {
      id
    }
  }
`
export const InsertCommunicationListDocument = gql`
  mutation InsertCommunicationList(
    $objects: [communication_list_insert_input!]!
  ) {
    insert_communication_list(
      objects: $objects
      on_conflict: {
        constraint: communication_list_customer_erp_id_code_key
        update_columns: [is_enabled_site, is_enabled_email, send_to_email]
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`
export const InsertCommunicationSettingsDocument = gql`
  mutation InsertCommunicationSettings(
    $objects: [communication_settings_insert_input!]!
  ) {
    insert_communication_settings(
      objects: $objects
      on_conflict: {
        constraint: communication_settings_customer_erp_id_key
        update_columns: [language]
      }
    ) {
      affected_rows
      returning {
        id
        language
      }
    }
  }
`
export const InsertCustomerAddressDropshippingDocument = gql`
  mutation InsertCustomerAddressDropshipping(
    $objects: [customer_address_dropshipping_insert_input!]!
  ) {
    insert_customer_address_dropshipping(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`
export const InsertDownloadDocument = gql`
  mutation InsertDownload($download: download_insert_input!) {
    insert_download_one(object: $download) {
      id
    }
  }
`
export const InsertProductReplacementDocument = gql`
  mutation InsertProductReplacement(
    $objects: [product_replacement_insert_input!]!
  ) {
    insert_product_replacement(
      objects: $objects
      on_conflict: {
        constraint: product_replacement_product_erp_id_replacement_erp_id_key
        update_columns: [x, y, scale_x, scale_y, origin, absolute]
      }
    ) {
      affected_rows
      returning {
        id
        created_at
        updated_at
        product_erp_id
        replacement_erp_id
        x
        y
        scale_x
        scale_y
        origin
        absolute
      }
    }
  }
`
export const InsertProductReplacementMapDocument = gql`
  mutation InsertProductReplacementMap(
    $objects: [product_replacement_map_insert_input!]!
  ) {
    insert_product_replacement_map(
      objects: $objects
      on_conflict: {
        constraint: product_replacement_map_product_erp_id_key
        update_columns: [status, has_image, width, height]
      }
    ) {
      affected_rows
      returning {
        id
        created_at
        updated_at
        product_erp_id
        status
        has_image
        width
        height
      }
    }
  }
`
export const MoveDownloadDocument = gql`
  mutation MoveDownload($id: uuid!, $position: Int!) {
    move_download(args: { download_id: $id, target_position: $position }) {
      id
    }
  }
`
export const UpdateCommunicationDocument = gql`
  mutation UpdateCommunication(
    $ids: [uuid!]!
    $changes: communication_set_input!
  ) {
    update_communication(where: { id: { _in: $ids } }, _set: $changes) {
      affected_rows
      returning {
        id
      }
    }
  }
`
export const UpdateCommunicationCodeDocument = gql`
  mutation UpdateCommunicationCode(
    $_set: communication_code_set_input!
    $code: String!
  ) {
    update_communication_code_by_pk(_set: $_set, pk_columns: { code: $code }) {
      code
    }
  }
`
export const UpdateCommunicationExternalMailDocument = gql`
  mutation UpdateCommunicationExternalMail(
    $id: uuid!
    $set: communication_external_mail_set_input!
  ) {
    update_communication_external_mail_by_pk(
      pk_columns: { id: $id }
      _set: $set
    ) {
      id
    }
  }
`
export const UpdateDownloadDocument = gql`
  mutation UpdateDownload($id: uuid!, $_set: download_set_input!) {
    update_download_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`
export const UpdateProductReplacementMapDocument = gql`
  mutation UpdateProductReplacementMap(
    $productErpId: String!
    $changes: product_replacement_map_set_input!
  ) {
    update_product_replacement_map(
      where: { product_erp_id: { _eq: $productErpId } }
      _set: $changes
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`
export const UpdatePromoSettingsDocument = gql`
  mutation UpdatePromoSettings(
    $promoEnable: String!
    $promoDiscount: String!
    $promoDateFrom: String!
    $promoDateTo: String!
    $promoMinQty: String!
    $promoMaxQty: String!
  ) {
    insert_setting(
      objects: [
        { key: "promoEnable", value: $promoEnable }
        { key: "promoDiscount", value: $promoDiscount }
        { key: "promoDateFrom", value: $promoDateFrom }
        { key: "promoDateTo", value: $promoDateTo }
        { key: "promoMinQty", value: $promoMinQty }
        { key: "promoMaxQty", value: $promoMaxQty }
      ]
      on_conflict: { constraint: setting_key_key, update_columns: [value] }
    ) {
      affected_rows
    }
  }
`
export const UpdateReplacementSettingsDocument = gql`
  mutation UpdateReplacementSettings($replacementMaxOrderQty: String!) {
    insert_setting(
      objects: [
        { key: "replacementMaxOrderQty", value: $replacementMaxOrderQty }
      ]
      on_conflict: { constraint: setting_key_key, update_columns: [value] }
    ) {
      affected_rows
    }
  }
`
export const UpsertCustomerAdditionalDataDocument = gql`
  mutation UpsertCustomerAdditionalData(
    $customer_erp_id: String!
    $data: jsonb!
  ) {
    insert_customer_additional_data_one(
      object: { customer_erp_id: $customer_erp_id, data: $data }
      on_conflict: {
        constraint: customer_additional_data_cusomer_erp_id_key
        update_columns: data
      }
    ) {
      customer_erp_id
    }
  }
`
export const AgentBonusDocument = gql`
  query AgentBonus {
    agent_bonus(order_by: { prize_current_year: desc }, limit: 1) {
      id
      erp_id
      prize_current_year
      prize_current_revenue
      prize_target_revenue_1
      prize_return_rate_1
      prize_target_revenue_2
      prize_return_rate_2
      prize_target_revenue_3
      prize_return_rate_3
    }
  }
`
export const AgentsDocument = gql`
  query Agents($where: agent_bool_exp = {}) {
    agent(where: $where, order_by: { name: asc }) {
      id
      erp_id
      name
      email
    }
  }
`
export const ApiCustomerStatisticsDocument = gql`
  query ApiCustomerStatistics {
    api_customer_statistics {
      customer_erp_id
      revenue_current_year
      revenue_last_year
      revenue_2_years_ago
    }
  }
`
export const ApiFileDataDocument = gql`
  query ApiFileData($filename: String!, $docType: DocTypes!, $year: Int!) {
    api_file_data(fileName: $filename, docType: $docType, year: $year) {
      content
      mime
    }
  }
`
export const ApiFileReportDataDocument = gql`
  query ApiFileReportData($filename: String!) {
    api_file_report_data(fileName: $filename) {
      content
      mime
    }
  }
`
export const ApiMailupClientEmailsDocument = gql`
  query ApiMailupClientEmails(
    $offset: Int = 0
    $limit: Int = 20
    $idList: Int!
  ) {
    api_mailup_client_emails(offset: $offset, limit: $limit, idList: $idList) {
      results {
        Subject
        idList
        idMessage
        Url
      }
      offset
      limit
      total
    }
  }
`
export const ApiMailupClientListDocument = gql`
  query ApiMailupClientList($offset: Int = 0, $limit: Int = 20) {
    api_mailup_client_list(offset: $offset, limit: $limit) {
      results {
        IdList
        Name
      }
      offset
      limit
      total
    }
  }
`
export const ApiMailupClientRecipientDocument = gql`
  query ApiMailupClientRecipient($email: String!) {
    api_mailup_client_recipient(Email: $email) {
      Name
      idRecipient
      Email
    }
  }
`
export const ApiPingDocument = gql`
  query ApiPing {
    api_ping
  }
`
export const ApiSalesManagerAmountDocument = gql`
  query ApiSalesManagerAmount($data: ApiSalesManagerAmountDataInput!) {
    api_sales_manager_amount(data: $data) {
      result {
        currency
        amount
        amountInclTax
        provider
        method
        condition
      }
    }
  }
`
export const ApiSalesManagerCollectTotalsDocument = gql`
  query ApiSalesManagerCollectTotals($id: String) {
    api_sales_manager_collect_totals(id: $id) {
      result
    }
  }
`
export const CommunicationsDocument = gql`
  query Communications($where: communication_bool_exp) {
    communication(where: $where, order_by: { created_at: desc }, limit: 1000) {
      id
      customer_erp_id
      code
      is_archived
      is_deleted
      mail_id
      external_mail_id
      created_at
      updated_at
      communication_code {
        code
        provider
        is_external
      }
      communication_external_mail {
        id
        code
        identifier
        title
        created_at
        updated_at
      }
      mail {
        id
        status
        mail
        logs
        created_at
        updated_at
      }
    }
  }
`
export const CommunicationCodesDocument = gql`
  query CommunicationCodes {
    communication_code {
      code
      is_enabled
      is_external
      is_list
      provider
      created_at
      updated_at
    }
  }
`
export const CommunicationExternalMailDocument = gql`
  query CommunicationExternalMail {
    communication_external_mail {
      id
      code
      identifier
      updated_at
      created_at
      title
      is_processed
      search_criteria
    }
  }
`
export const CommunicationListDocument = gql`
  query CommunicationList {
    communication_list {
      id
      customer_erp_id
      code
      is_enabled_site
      is_enabled_email
      send_to_email
      created_at
      updated_at
    }
  }
`
export const CommunicationSettingsDocument = gql`
  query CommunicationSettings {
    communication_settings(limit: 1) {
      id
      language
    }
  }
`
export const CountryRegionDocument = gql`
  query CountryRegion {
    country_region {
      code
      description
      post_codes {
        code
        city
        county {
          code
          description
        }
        territory {
          code
          description
        }
      }
    }
  }
`
export const CreditMemoDocument = gql`
  query CreditMemo($where: credit_memo_bool_exp) {
    credit_memo(
      where: $where
      order_by: { pdf_created_at: desc, erp_id: desc }
    ) {
      id
      erp_id
      customer_erp_id
      date
      pdf_filename
      pdf_created_at
      created_at
      updated_at
    }
  }
`
export const CustomerDocument = gql`
  query Customer {
    customer(
      where: { user: { role: { _in: [customer, demo_customer] } } }
      order_by: { name: asc }
    ) {
      id
      user_id
      erp_id
      name
      address
      address_2
      zip_code
      city
      province
      country
      employee_email
      created_at
      updated_at
    }
  }
`
export const CustomerAddressDocument = gql`
  query CustomerAddress($where: customer_address_bool_exp) {
    customer_address(where: $where, order_by: { name: asc }) {
      id
      code
      created_at
      updated_at
      customer_erp_id
      agent_erp_id
      shipping_method_code
      name
      address
      address_2
      zip_code
      city
      province
      country
      phone
      employee_email
      shipping_method {
        code
        has_shipping_cost
      }
    }
  }
`
export const CustomerCurrentDocument = gql`
  query CustomerCurrent($where: customer_bool_exp) {
    customer(where: $where) {
      id
      erp_id
      created_at
      updated_at
      address
      address_2
      agent_erp_id
      city
      country
      email
      employee_email
      fiscal_code
      has_dropshipping
      invoice_discount_code
      name
      payment_condition_code
      payment_method_code
      phone
      prize_current_revenue
      prize_current_year
      prize_return_rate_1
      prize_return_rate_2
      prize_return_rate_3
      prize_target_revenue_1
      prize_target_revenue_2
      prize_target_revenue_3
      product_price_list_code
      province
      shipping_extra_cost
      shipping_method_code
      shipping_rate_code
      tax_rate
      user_id
      vat_number
      zip_code
      customer_discounts {
        product_discount_group_code
        discount_percent
      }
      invoice_discount {
        discount_percent
      }
      shipping_method {
        code
        has_shipping_cost
      }
      shipping_rate {
        code
        free_threshold
        cost
        valid_from
      }
    }
  }
`
export const CustomerWithAdditionalDataDocument = gql`
  query CustomerWithAdditionalData {
    customer(
      where: { user: { role: { _eq: customer } } }
      order_by: { name: asc }
    ) {
      id
      user_id
      erp_id
      name
      address
      address_2
      zip_code
      city
      province
      country
      employee_email
      created_at
      updated_at
      additional_data {
        data
      }
    }
  }
`
export const ExchangeRateDocument = gql`
  query ExchangeRate($where: exchange_rate_bool_exp) {
    exchange_rate(where: $where) {
      currency
      rate
      last_update_at
    }
  }
`
export const InvoiceDocument = gql`
  query Invoice($where: invoice_view_bool_exp) {
    invoice_view(
      where: $where
      order_by: { date_registered: desc, erp_id: desc }
    ) {
      erp_id
      date_registered
      amount
      amount_expired_unpaid
      pdf_filename
      pdf_created_at
    }
  }
`
export const MailsDocument = gql`
  query Mails {
    mail(
      where: { status: { _in: ["error", "notsent", "pending", "sent"] } }
      order_by: { created_at: desc }
      limit: 1000
    ) {
      id
      status
      mail
      logs
      created_at
      updated_at
    }
  }
`
export const MailDocument = gql`
  query Mail($id: uuid!) {
    mail(where: { id: { _eq: $id } }, limit: 1) {
      id
      status
      mail
      logs
      created_at
      updated_at
    }
  }
`
export const OrderByIncrementIdDocument = gql`
  query OrderByIncrementId($incrementId: Int!) {
    order(limit: 1, where: { increment_id: { _eq: $incrementId } }) {
      id
      increment_id
      status
      type
      amount
      currency
      additional_info
      created_at
      updated_at
      order_transactions(order_by: { created_at: asc }) {
        transaction_id
        created_at
        updated_at
        transaction {
          transaction_id
          parent_transaction_id
          customer_erp_id
          status
          provider
          method
          type
          additional_info
          created_at
          updated_at
        }
      }
      order_invoices(order_by: { created_at: asc }) {
        created_at
        updated_at
        order_invoice_items(order_by: { created_at: asc }) {
          invoice_erp_id
          invoice_overdue_erp_id
          amount
          currency
          additional_info
          created_at
          updated_at
        }
      }
      order_products(order_by: { created_at: asc }) {
        created_at
        updated_at
        order_product_items(order_by: { cart_item_created_at: asc }) {
          additional_info
          cart_item_created_at
          cart_item_id
          product_erp_id
          amount
          currency
          qty
          product_amount
          product {
            id
            name
            dimension
            stock
            gross_weight
          }
          created_at
          updated_at
        }
        order_product_replacements(
          order_by: { cart_replacement_created_at: asc }
        ) {
          additional_info
          cart_replacement_created_at
          cart_replacement_id
          replacement_erp_id
          qty
          replacement {
            id
            name
            description
          }
          created_at
          updated_at
        }
      }
    }
  }
`
export const OrderConfirmedDocument = gql`
  query OrderConfirmed($where: order_confirmed_bool_exp) {
    order_confirmed(where: $where, order_by: { date_order: desc }) {
      id
      erp_id
      customer_erp_id
      external_id
      date_order
      is_under_processing
      is_invoiced
      pdf_filename
      pdf_created_at
      order {
        increment_id
      }
      created_at
      updated_at
    }
  }
`
export const OrderItemUnshippedDocument = gql`
  query OrderItemUnshipped($where: order_item_unshipped_bool_exp) {
    order_item_unshipped(where: $where) {
      id
      customer_erp_id
      doc_erp_id
      doc_customer_id
      product_erp_id
      original_order_erp_id
      description
      qty
      first_arrival_date
      created_at
      updated_at
      order {
        increment_id_as_text
      }
      order_confirmed {
        date_order
      }
    }
  }
`
export const OrderListDocument = gql`
  query OrderList($where: order_bool_exp) {
    order(where: $where, order_by: { increment_id: asc }) {
      id
      increment_id
      customer_erp_id
      status
      type
      amount
      currency
      additional_info
      created_at
      updated_at
    }
  }
`
export const OrderSubcustomerByIncrementIdDocument = gql`
  query OrderSubcustomerByIncrementId(
    $incrementId: Int!
    $whereOrder: order_bool_exp!
    $whereOrderProductItem: order_product_item_bool_exp!
  ) {
    order(
      limit: 1
      where: { _and: [{ increment_id: { _eq: $incrementId } }, $whereOrder] }
    ) {
      increment_id
      status
      created_at
      order_products(order_by: { created_at: asc }) {
        order_product_items(
          where: $whereOrderProductItem
          order_by: { cart_item_created_at: asc }
        ) {
          additional_info
          product_erp_id
          qty
          cart_item_created_at
          cart_item_id
          product {
            id
            name
            dimension
            stock
            gross_weight
          }
        }
      }
    }
  }
`
export const PaymentMethodAndConditionDocument = gql`
  query PaymentMethodAndCondition(
    $whereMethod: payment_method_bool_exp
    $whereCondition: payment_condition_bool_exp
  ) {
    payment_method(where: $whereMethod) {
      code
      description
      description_it
      description_en
      description_fr
      description_de
      description_es
      description_ru
    }
    payment_condition(where: $whereCondition) {
      code
      description
      description_it
      description_en
      description_fr
      description_de
      description_es
      description_ru
    }
  }
`
export const LastUpdateDocument = gql`
  query LastUpdate {
    product(limit: 1, order_by: { updated_at: desc_nulls_last }) {
      updated_at
    }
  }
`
export const ProductFilesDocument = gql`
  query ProductFiles {
    product(order_by: { name: asc }) {
      erp_id
      name
      product_files(order_by: { folder: asc }) {
        folder
        filename
      }
    }
  }
`
export const ProductPriceViewDocument = gql`
  query ProductPriceView(
    $where: product_price_view_bool_exp
    $order_by: [product_price_view_order_by!] = {}
  ) {
    product_price_view(where: $where, order_by: $order_by) {
      allow_invoice_discount
      allow_line_discount
      price_real
      price_real_no_discount
      price_real_field
      price_real_field_no_discount
      price_client
      price_end_series
      price_price_list
      price_public
      price_promo
      price_discount
      discount_percent
      customer_discount_discount_percent
      id
      erp_id
      name
      stock
      first_arrival_date
      gross_weight
      dimension
      box_pieces
      promo
      product_discount_group_code
      created_at
      updated_at
      customer_erp_id
      product_price_list_code
      promo_enable
      promo_from
      promo_to
      promo_discount
    }
  }
`
export const ProductReplacementDocument = gql`
  query ProductReplacement($productErpId: String!) {
    product(limit: 1, where: { erp_id: { _eq: $productErpId } }) {
      erp_id
      name
      product_replacement_map {
        id
        status
        has_image
        width
        height
        created_at
        updated_at
      }
      product_replacements_aggregate {
        aggregate {
          count
        }
        nodes {
          id
          x
          y
          scale_x
          scale_y
          origin
          absolute
          replacement {
            id
            erp_id
            name
            description
          }
        }
      }
    }
  }
`
export const ProductReplacementListDocument = gql`
  query ProductReplacementList {
    product {
      erp_id
      name
      product_replacement_map {
        id
        status
        has_image
        width
        height
        created_at
        updated_at
      }
      product_replacements_aggregate {
        aggregate {
          count
        }
        nodes {
          id
          x
          y
          scale_x
          scale_y
          origin
          absolute
          replacement {
            id
            erp_id
            name
            description
          }
        }
      }
    }
  }
`
export const ProductReplacementListCustomerDocument = gql`
  query ProductReplacementListCustomer {
    product(
      where: {
        product_replacement_map: {
          status: { _eq: true }
          has_image: { _eq: true }
        }
        product_replacements_aggregate: {
          count: { arguments: [id], distinct: true, predicate: { _gt: 0 } }
        }
      }
    ) {
      erp_id
      name
      product_replacement_map {
        id
        status
        has_image
        width
        height
        created_at
        updated_at
      }
      product_replacements_aggregate {
        aggregate {
          count
        }
        nodes {
          id
          x
          y
          scale_x
          scale_y
          origin
          absolute
          replacement {
            id
            erp_id
            name
            description
          }
        }
      }
    }
  }
`
export const ReplacementListDocument = gql`
  query ReplacementList(
    $where: replacement_bool_exp
    $order_by: [replacement_order_by!] = {}
  ) {
    replacement(where: $where, order_by: $order_by) {
      id
      erp_id
      name
      description
    }
  }
`
export const ReportDocument = gql`
  query Report {
    report(order_by: { date_end: desc }) {
      id
      filename
      created_at
      date_start
      date_end
      type
    }
  }
`
export const ShipmentDocumentDocument = gql`
  query ShipmentDocument($where: shipment_document_bool_exp) {
    shipment_document(where: $where, order_by: { date: desc, erp_id: desc }) {
      id
      erp_id
      customer_erp_id
      date
      pdf_filename
      pdf_created_at
      created_at
      updated_at
    }
  }
`
export const ShippingMethodDocument = gql`
  query ShippingMethod($where: shipping_method_bool_exp) {
    shipping_method(where: $where) {
      code
      description
      description_de
      description_en
      description_es
      description_fr
      description_ru
    }
  }
`
export const TransactionDocument = gql`
  query Transaction($where: transaction_bool_exp) {
    transaction(where: $where) {
      transaction_id
      status
      provider
      method
      created_at
      updated_at
      order_transactions {
        order {
          increment_id
          type
        }
      }
    }
  }
`
export const CartDocument = gql`
  subscription Cart($where: cart_bool_exp, $limit: Int = 1) {
    cart(where: $where, order_by: { id: desc }, limit: $limit) {
      id
      type
      order_id
      status
      additional_info
      created_at
      updated_at
      cart_items(order_by: { id: asc }) {
        id
        cart_id
        product_erp_id
        qty
        additional_info
        created_at
        product {
          name
          stock
        }
      }
      cart_replacements(order_by: { id: asc }) {
        id
        cart_id
        replacement_erp_id
        qty
        additional_info
        created_at
        replacement {
          name
          description
        }
      }
    }
  }
`
export const CustomerAddressDropshippingDocument = gql`
  subscription CustomerAddressDropshipping(
    $where: customer_address_dropshipping_bool_exp
  ) {
    customer_address_dropshipping(where: $where, order_by: { firstname: asc }) {
      id
      code
      name
      firstname
      lastname
      created_at
      updated_at
      customer_erp_id
      agent_erp_id
      shipping_method_code
      address
      address_2
      zip_code
      city
      province
      country
      phone
      email
    }
  }
`
export const DownloadsDocument = gql`
  subscription Downloads($where: download_bool_exp = {}) {
    download(order_by: { position: asc }, where: $where) {
      id
      position
      active_de
      active_en
      active_es
      active_fr
      active_it
      active_ru
      role
      file {
        id
        filename
        uploaded_at
      }
      name_de
      name_en
      name_es
      name_fr
      name_it
      name_ru
      created_at
      updated_at
    }
  }
`
export const OrderPromoDocument = gql`
  subscription OrderPromo(
    $customerErpId: String!
    $additionalInfoContains: jsonb!
  ) {
    order(
      limit: 1
      where: {
        customer_erp_id: { _eq: $customerErpId }
        type: { _eq: "product" }
        status: { _neq: "canceled" }
        additional_info: { _contains: $additionalInfoContains }
      }
    ) {
      id
      increment_id
      additional_info
    }
  }
`
export const PendingOrdersDocument = gql`
  subscription PendingOrders {
    order(
      order_by: { increment_id: desc }
      where: {
        status: { _in: ["new"] }
        _or: [
          {
            type: { _eq: "invoice" }
            order_invoice: { id: { _is_null: false } }
          }
          {
            type: { _eq: "product" }
            order_product: { id: { _is_null: false } }
          }
        ]
        amount: { _gt: "0" }
      }
    ) {
      increment_id
      type
      status
      created_at
      amount
      additional_info
      order_invoice {
        order_invoice_items(order_by: { invoice_erp_id: asc }) {
          amount
          invoice_erp_id
        }
      }
    }
  }
`
export const ProposalDocument = gql`
  subscription Proposal($where: cart_bool_exp) {
    cart(where: $where) {
      id
      type
      order_id
      status
      customer {
        name
        employee_email
        address
        address_2
        city
        zip_code
        province
        country
      }
      subcustomer_id
      subcustomer {
        user {
          login
        }
      }
      additional_info
      cart_items(order_by: { id: asc }) {
        id
        cart_id
        product_erp_id
        qty
        additional_info
        product {
          name
          stock
          gross_weight
          dimension
        }
      }
    }
  }
`
export const SettingDocument = gql`
  subscription Setting($where: setting_bool_exp) {
    setting(where: $where) {
      key
      value
    }
  }
`
export const SubcustomerUserDocument = gql`
  subscription SubcustomerUser {
    user(where: { role: { _eq: sub_customer } }) {
      id
      login
      created_at
      updated_at
      subcustomer {
        id
        customer {
          erp_id
          name
        }
      }
    }
  }
`
export const UserDocument = gql`
  subscription User($where: user_bool_exp) {
    user(where: $where) {
      id
      login
      role
      created_at
      updated_at
    }
  }
`
